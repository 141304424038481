import {
  Avatar,
  Button,
  CircularProgress,
  InputAdornment,
  TextField,
  Typography,
} from "@mui/material";
import { Box } from "@mui/system";
import { useCallback, useEffect, useRef, useState } from "react";
import { useRecoilState } from "recoil";
import { expandFilesState, ticketDocumentState, ticketState } from "../../../atoms/support";
import miniLogo from "../../../img/logos/logo_white_mini_loading.svg";
import AttachFileIcon from "@mui/icons-material/AttachFile";
import CloudUploadOutlinedIcon from "@mui/icons-material/CloudUploadOutlined";
import MessageTime from "../../../utils/MessageTime";
import axios from "../../../api/axios";
import { toast } from "react-toastify";
import Errors from "../../../utils/Errors";

const SupportDetailChat = () => {
  const [ticket, setTicket] = useRecoilState(ticketState);
  const [ticketDocument, setTicketDocument] = useRecoilState(ticketDocumentState);
  const [, setExpandFiles] = useRecoilState(expandFilesState);

  const [message, setMessage] = useState("");
  const [loading, setLoading] = useState(false);
  const [uploadLoading, setUploadLoading] = useState(false);
  const [selectedFiles, setSelectedFiles] = useState([]);
  const [isSelectAnyFile, setIsSelectAnyFile] = useState(false);

  const messageContainer = useRef(null);

  const handleFileSelect = (e) => {
    const filesArray = Array.from(e.target.files);
    setSelectedFiles(filesArray);

    // Reset file input value
    e.target.value = null;
  };

  const handleSendMessage = useCallback(
    async (e) => {
      e.preventDefault();
      setLoading(true);

      if (!loading) {
        await axios
          .post(`ticket/message/add/${ticket.id}`, {
            message,
          })
          .then((response) => {
            setTicket(response.data.data);
            setMessage("");
            setLoading(false);
          })
          .catch((error) => {
            setLoading(false);
            Errors(error);
          });
      }
    },
    [message, ticket.id, setTicket, loading]
  );

  const uploadSelected = useCallback(async () => {
    setUploadLoading(true);
    let formData = new FormData();
    Array.from(selectedFiles).forEach((file, index) => {
      formData.append(`files[${index}]`, file);
    });
    formData.append("parent_id", ticket.id);
    formData.append("parent_type", "Ticket");

    await axios
      .post(`document/add`, formData)
      .then((response) => {
        setUploadLoading(false);
        toast.success("Dosyalar Başarıyla Yüklendi", { autoClose: 500 });
        setTicketDocument([...ticketDocument, ...response.data.data]);
        setExpandFiles(true);
        setSelectedFiles([]);
      })
      .catch((error) => {
        setUploadLoading(false);
        setSelectedFiles([]);
        Errors(error);
      });
  }, [selectedFiles, ticket.id, setTicketDocument, ticketDocument, setExpandFiles]);

  useEffect(() => {
    if (messageContainer.current) {
      messageContainer.current.scrollIntoView({ behavior: "smooth" });
    }
  }, [ticket.get_ticket_messages]);

  useEffect(() => {
    if (selectedFiles.length !== 0) {
      setIsSelectAnyFile(true);
    } else {
      setIsSelectAnyFile(false);
    }
  }, [selectedFiles.length]);

  return (
    <form
      style={{ position: "relative" }}
      onSubmit={handleSendMessage}
      className="support-detail-chat"
    >
      <Box className="support-detail-chat-messages">
        {ticket.get_ticket_messages.map((message, key) => (
          <Box
            key={key}
            className={message.type === "1" ? "chat-message customer" : "chat-message admin"}
          >
            <Box className="chat-message-time">{MessageTime(message.created_at)}</Box>
            <Box
              className={
                message.type === "1"
                  ? "chat-message-text-wrapper customer-wrapper"
                  : "chat-message-text-wrapper"
              }
            >
              {message.type === "1" ? (
                <Avatar className="chat-message-avatar" />
              ) : (
                <Avatar src={miniLogo} className="chat-message-avatar" />
              )}
              <Typography
                className={
                  message.type === "1" ? "chat-message-text customer" : "chat-message-text admin"
                }
              >
                {message.message}
              </Typography>
            </Box>
          </Box>
        ))}
        <div ref={messageContainer} />
      </Box>
      <Box className="detail-chat-send-message">
        <TextField
          label="Mesajınızı Yazınız"
          value={message}
          onChange={(e) => setMessage(e.target.value)}
          size="small"
          fullWidth
          required
          autoComplete="off"
          autoFocus
          multiline
          InputProps={{
            sx: {
              borderRadius: "10px !important",
            },
            endAdornment: (
              <InputAdornment position="end">
                <Button
                  type="button"
                  variant="contained"
                  component="label"
                  sx={{
                    backgroundColor: "transparent",
                    outline: 0,
                    border: 0,
                    boxShadow: 0,
                    padding: 0,
                    minWidth: 0,
                    ":hover": {
                      backgroundColor: "transparent",
                      outline: 0,
                      border: 0,
                      boxShadow: 0,
                    },
                  }}
                >
                  <AttachFileIcon
                    sx={{
                      color: "var(--priceText)",
                      cursor: "pointer",
                      transition: "all 150ms linear",
                      ":hover": {
                        color: "var(--info)",
                        transition: "all 150ms linear",
                      },
                    }}
                  />
                  <input multiple onChange={handleFileSelect} type="file" hidden />
                </Button>
              </InputAdornment>
            ),
          }}
        />
        {loading ? (
          <Box
            sx={{
              width: "40px",
              height: "40px",
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            <CircularProgress sx={{ width: "24px !important", height: "24px !important" }} />
          </Box>
        ) : (
          <>
            {isSelectAnyFile &&
              (uploadLoading ? (
                <Box
                  sx={{
                    width: "40px",
                    height: "40px",
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                  }}
                >
                  <CircularProgress sx={{ width: "24px !important", height: "24px !important" }} />
                </Box>
              ) : (
                <Button
                  onClick={uploadSelected}
                  type="button"
                  className="detail-chat-send-message-button"
                >
                  <CloudUploadOutlinedIcon sx={{ color: "var(--lighterBg)" }} />
                </Button>
              ))}
            {!isSelectAnyFile && (
              <Button type="submit" className="detail-chat-send-message-button">
                <img src="/images/icons/send_icon.svg" alt="Gönder Görseli" />
              </Button>
            )}
          </>
        )}
      </Box>
    </form>
  );
};

export default SupportDetailChat;
