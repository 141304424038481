import "../../style/globals/PageHeaderTitle.css";
import HelpOutlineOutlinedIcon from "@mui/icons-material/HelpOutlineOutlined";
import ExtensionOutlinedIcon from "@mui/icons-material/ExtensionOutlined";
import WrapperStyle from "../../utils/WrapperStyle";
import { useState } from "react";

const IntegrationTitle = () => {
  const [open, setOpen] = useState(false);
  const handleOpenVideo = () => {
    setOpen(true);
  };

  return (
    <>
      {/* <PageHowToWork
        open={open}
        setOpen={setOpen}
        iframe={}
      /> */}
      <div className="page-header-title" style={WrapperStyle()}>
        <div className="page-header-title-wrapper">
          <div className="page-header-title-headline">
            <ExtensionOutlinedIcon
              sx={{
                width: "24px!important",
                height: "24px!important",
              }}
            />
            <h1>Entegrasyonlarım</h1>
          </div>
          {/* <Button
            sx={{ textTransform: "none" }}
            onClick={handleOpenVideo}
            className="page-header-title-how-link"
          >
            <HelpOutlineOutlinedIcon />
            <span>Bu Sayfa Nasıl Kullanılır?</span>
          </Button> */}
        </div>
      </div>
    </>
  );
};

export default IntegrationTitle;
