import {
  Box,
  Button,
  Checkbox,
  CircularProgress,
  createTheme,
  FormControl,
  FormControlLabel,
  InputLabel,
  MenuItem,
  Select,
  TextField,
  ThemeProvider,
  Typography,
} from "@mui/material";
import { useRecoilState, useRecoilValue, useSetRecoilState } from "recoil";
import { useState } from "react";
import { useCallback } from "react";
import { toast, ToastContainer } from "react-toastify";
import { useEffect } from "react";
import {
  amountNoteState,
  amountState,
  isCardsExist2State,
  isCardsExistState,
  paymentRefNumberState,
  registeredDefaultNotEmptyState,
  walletPaymentStatusState,
} from "../../atoms/myWallet";
import { garantiCardsState, iyzicoCardsState } from "../../atoms/membership";
import maskCardNumber from "../../utils/maskCardNumber";
import axios from "../../api/axios";
import InfoIcon from "@mui/icons-material/Info";
import ContentCopyOutlinedIcon from "@mui/icons-material/ContentCopyOutlined";
import { IconButton, Tooltip } from "@mui/material";
import { Table, TableHead, TableBody, TableRow, TableCell } from "@mui/material";
import { useInView, animated } from "@react-spring/web";
import { useCookies } from "react-cookie";
import { DynamicTitle } from "../../utils/DynamicTitle";
import NotificationBar from "../sidebar/NotificationBar";
import MyWalletNav from "./MyWalletNav";
import MyWalletCard from "./myWalletPayment/MyWalletCard";
import CardImgChange from "../../utils/CardImgChange";
import WrapperStyle from "../../utils/WrapperStyle";
import CCard from "../../utils/CCard";
import LocalPrice from "../../utils/LocalPrice";
import toTurkishUpperCase from "../../utils/toTurkishUpperCase";
import { ibanListArrayState } from "../../atoms/profileSettings";
import SettingsBackupRestoreOutlinedIcon from "@mui/icons-material/SettingsBackupRestoreOutlined";
import Errors from "../../utils/Errors";
import SuccessPaymentNotDialog from "./myWalletPayment/SuccessPaymentNotDialog";
import PaymentTitle from "./title/PaymentTitle";
import { useNavigate } from "react-router-dom";

const theme = createTheme({
  components: {
    MuiModal: {
      styleOverrides: {
        root: {
          margin: "20px",
          overflow: "auto",
        },
      },
    },
    MuiSelect: {
      styleOverrides: {
        outlined: {
          display: "flex",
          alignItems: "center",
          gap: "10px",
        },
      },
    },
    MuiFormControlLabel: {
      styleOverrides: {
        label: {
          fontSize: "14px",
          opacity: 0.8,
        },
      },
    },
    MuiOutlinedInput: {
      styleOverrides: {
        root: {
          borderRadius: "10px",
        },
      },
    },
  },
});

const MyWalletPayment = () => {
  const navigate = useNavigate();

  // Disabled Payment Button
  const member = JSON.parse(localStorage.getItem("user"));
  const memberStatus = member?.user?.user_member?.status;
  const isButtonDisabled = Number(memberStatus) !== 2;

  const walletPaymentStatus = useRecoilValue(walletPaymentStatusState);
  const [amount, setAmount] = useRecoilState(amountState);
  const [amountNote, setAmountNote] = useRecoilState(amountNoteState);
  const [paymentRefNumber, setPaymentRefNumber] = useRecoilState(paymentRefNumberState);

  const [iyzicoCards, setIyzicoCards] = useRecoilState(iyzicoCardsState);
  const [garantiCards, setGarantiCards] = useRecoilState(garantiCardsState);
  const [registeredCreditCard, setRegisteredCreditCard] = useState({});
  const [registeredCreditCardValue, setRegisteredCreditCardValue] = useState("");
  const setRegisteredDefaultNotEmpty = useSetRecoilState(registeredDefaultNotEmptyState);

  //* Ödeme Bildirimi Başarılı Dialog */
  const [paymentNotSuccess, setPaymentNotSuccess] = useState(false);

  //* Kredi Kartı Giriş Bölüm Başlangıç */

  const [, setFullName] = useState("");
  const [creditCard, setCreditCard] = useState("");
  const [, setCreditCardError] = useState(false);
  const [, setCreditCardLessError] = useState(false);
  const [, setCreditCardFalseValue] = useState(false);
  const [, setErrorMessageShow] = useState(false);
  const [month, setMonth] = useState("");
  const [year, setYear] = useState("");
  const [cvc2, setCvc2] = useState("");

  //* Kredi Kartı Giriş Bölüm Son */

  const [paymentDataIsLoad, setPaymentDataIsLoad] = useState(false);
  const [paidPrice, setPaidPrice] = useState("");
  const [message, setMessage] = useState("");
  const [paidStatus, setPaidStatus] = useState(false);
  const [loading, setLoading] = useState(false);
  const [isCardsExist, setIsCardsExist] = useRecoilState(isCardsExistState);
  const [isCardsExist2, setIsCardsExist2] = useRecoilState(isCardsExist2State);
  const [registerCard, setRegisterCard] = useState(false);

  //* Ödeme Sistemleri
  const [url, setUrl] = useState("");

  //* Havale Ad, Soyad, Iban bilgileri */

  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [iban, setIban] = useState("");
  const [manuelIban, setManuelIban] = useState(false);

  const [ibanListArray, setIbanListArray] = useRecoilState(ibanListArrayState);

  const getIbanList = useCallback(() => {
    axios
      .get("member-iban")
      .then((response) => {
        setIbanListArray(response.data.data);
        if (response.data.data.length === 0) {
          setManuelIban(true);
        }
      })
      .catch((error) => Errors(error));
  }, [setIbanListArray]);

  // Iban validation
  const [isIbanValid, setIsIbanValid] = useState(true);

  // const handleIbanChange = (e) => {
  //   const newIban = e.target.value;
  //   setIban(newIban);
  //   setIsIbanValid(validateIBAN(newIban));
  // };

  const validateIBAN = () => {
    const isValid = iban.length < 32;
    if (isValid) {
      setIsIbanValid(false);
    } else {
      setIsIbanValid(true);
    }
  };

  const handleIbanChange = (e) => {
    const value = e.target.value;
    const numbersOnly = value.replace(/[^0-9]/g, "");
    let formatted = numbersOnly.length > 0 ? "TR" : " ";

    for (let i = 0; i < numbersOnly.length; i++) {
      if (i === 2 || i === 6 || i === 10 || i === 14 || i === 18 || i === 22 || i === 26) {
        formatted += " ";
      }
      formatted += numbersOnly[i];
    }

    formatted = formatted.replace(/\s+/g, " ").trim();
    setIban(formatted);
  };

  // Take ref number

  const [refNumbers, setRefNumbers] = useState([]);

  useEffect(() => {
    const getAvailableRefNumbers = async () => {
      try {
        const response = await axios
          .get("money-order/get-available-ref-no", {
            headers: {
              Authorization: `Bearer ${jwt}`,
            },
          })
          .catch((error) => Errors(error));

        // Handle the response data
        const availableRefNumbers = response.data.data;
        setRefNumbers(availableRefNumbers);
      } catch (error) {
        // Handle the error
      }
    };

    getAvailableRefNumbers();
  }, [message, paidStatus]);

  /* Copy Ref Number */

  const [copied, setCopied] = useState(false);
  const referans = refNumbers;

  const handleClick = async () => {
    try {
      await navigator.clipboard.writeText(referans);
      setCopied(true);
      setTimeout(() => setCopied(false), 2000);
    } catch (err) {
      console.error("Kopyalama Hatası: ", err);
    }
  };

  /* Money order */

  const handleSubmitOrder = (e) => {
    if (e) e.preventDefault();

    if (isButtonDisabled) {
      navigate("/profile-settings/general");
    } else {
      if (!paymentRefNumber || !refNumbers || !firstName || !lastName || !iban || !amount) {
        toast.error("Lütfen tüm alanları doldurun.");
        return;
      }
      const requestBody = {
        ref_no: paymentRefNumber ?? refNumbers,
        sender_name: `${firstName} ${lastName}`,
        iban: iban,
        amount: parseFloat(amount),
      };

      try {
        axios
          .post("money-order/add", requestBody)
          .then((response) => {
            if (response.data.status === 200) {
              setPaidStatus(true);
              setMessage(response.data.message);
              toast.success(response.data.message); // Display success toast message
              setTimeout(() => {
                setPaidStatus(false);
              }, 3000);
              setPaymentNotSuccess(true);
            } else {
              setMessage("Ödeme bildirimi başarısız.");
              toast.success(response.data.message); // Display error toast message
            }
          })
          .catch((error) => Errors(error));
      } catch (error) {
        Errors(error);
      }
      fetchMoneyOrders();
    }
  };

  // Money order list

  const [moneyOrders, setMoneyOrders] = useState([]);

  const fetchMoneyOrders = () => {
    const config = {
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${jwt}`,
      },
    };

    axios
      .get("/money-order", config)
      .then((response) => {
        setMoneyOrders(response.data.data);
        setFirstName("");
        setLastName("");
        setIban("");
        setAmount("");
        setPaymentRefNumber("");
      })
      .catch((error) => {
        Errors(error);
      });
  };

  // Money order status

  const getStatusText = (status) => {
    switch (status) {
      case "0":
        return "Onay Bekliyor";
      case "1":
        return "Onaylandı";
      case "2":
        return "Reddedildi";
      default:
        return "";
    }
  };

  const statusColors = {
    0: "status status0",
    1: "status status1",
    2: "status status2",
  };

  useEffect(() => {
    fetchMoneyOrders();
    getStatusText();
  }, [message]);

  DynamicTitle("Bakiye Yükle");

  const [token] = useCookies(["jwt"]);
  const jwt = token.jwt;
  axios.defaults.headers.common["Authorization"] = `Bearer ${jwt}`;

  const [ref, springs] = useInView(
    () => ({
      from: {
        opacity: 0,
        y: 20,
      },
      to: {
        opacity: 1,
        y: 0,
      },
    }),
    {
      rootMargin: "-10% 0%",
    }
  );

  const handleMonthChange = (e) => {
    const value = e.target.value;
    if (value === "" || Number(value) <= 12) {
      setMonth(value);
    }
  };

  const handleMonthBlur = (e) => {
    let value = e.target.value;

    if (value === "0" || value > 12 || value < 1) {
      setMonth("");
    } else if (value.length === 1) {
      value = "0" + value;
      setMonth(value);
    }
  };

  const handleYearChange = (e) => {
    let currentYear = new Date().getFullYear();

    // Yılın son iki hanesini al
    currentYear = currentYear.toString().slice(-2);

    let yearValue = e.target.value;
    if (
      yearValue < 0 ||
      yearValue > 99 ||
      isNaN(yearValue) ||
      (yearValue.length === 2 && yearValue < currentYear)
    ) {
      setYear("");
    } else {
      setYear(yearValue);
    }
  };

  const handleYearBlur = (e) => {
    let value = e.target.value;
    if (value.length < 2) {
      setYear("");
    }
  };

  const handleCvc2Change = (e) => {
    const value = e.target.value;
    if (value.match(/^[0-9]*$/)) {
      setCvc2(value);
    }
  };

  const handleCvc2Blur = (e) => {
    let value = e.target.value;
    if (value.length < 3) {
      setCvc2("");
    }
  };

  const creditCardChanger = (e) => {
    maskCardNumber(e);
    setCreditCard(CCard(e));
  };

  const handleChoiceMoney = useCallback(
    (e) => {
      setAmount(e.target.value);
    },
    [setAmount]
  );
  const FormClear = useCallback(() => {
    setMonth("");
    setYear("");
    setCvc2("");
    setAmountNote("");
    setAmount("");
    setCreditCard("");
    setFirstName("");
    setLastName("");
  }, [setAmount, setAmountNote]);

  const paymentSend = useCallback(() => {
    if (isButtonDisabled) {
      navigate("/profile-settings/general");
    } else {
      if (creditCard.length === 0) {
        setCreditCardError(true);
        setErrorMessageShow(true);
      }

      if (creditCard.length > 0 && creditCard.length < 19) {
        setCreditCardLessError(true);
        setErrorMessageShow(true);
      }

      if (
        creditCard.length === 19 &&
        cvc2.length === 3 &&
        month.length === 2 &&
        year.length === 2
      ) {
        setLoading(true);
        let form = new FormData();

        form.append("balance", amount);
        form.append("holder_name", `${firstName} ${lastName}`);
        form.append("card_number", creditCard.replaceAll(" ", ""));
        form.append("expire_month", month);
        form.append("expire_year", year);
        form.append("cvc", cvc2);
        form.append("register_card", registerCard ? "1" : "0");
        form.append("payment_type", "add_balance");

        axios
          .post("payment/initialize-make-payment", form)
          .then((response) => {
            if (response && response.data.status === 200) {
              setPaymentDataIsLoad(true);
              setUrl(response.data.iframeData.decodeHTML);
            } else if (response && response.data.status === 400) {
              toast.error(response.data.message);
              setCreditCardFalseValue(true);
              setErrorMessageShow(true);
            }
          })
          .catch((error) => {
            setCreditCardFalseValue(true);
            setErrorMessageShow(true);
            Errors(error);
          })
          .finally(() => {
            setLoading(false);
            FormClear();
          });
      }
    }
  }, [
    creditCard,
    firstName,
    lastName,
    cvc2,
    month,
    year,
    registerCard,
    amount,
    isButtonDisabled,
    navigate,
    FormClear,
  ]);

  useEffect(() => {
    setFullName(`${firstName} ${lastName}`);
  }, [firstName, lastName]);

  const registeredPaymentSend = useCallback(async () => {
    if (isButtonDisabled) {
      navigate("/profile-settings/general");
    } else {
      setLoading(true);

      let form = new FormData();
      form.append("balance", amount);
      form.append("card_token", registeredCreditCard.card_token);
      form.append("payment_type", "add_balance");

      await axios
        .post("payment/initialize-make-payment", form)
        .then((response) => {
          if (response && response.data.status === 200) {
            setPaymentDataIsLoad(true);

            //* Banka Ödeme Şekilleri
            setUrl(response.data.iframeData.decodeHTML);
          } else if (response && response.data.status === 400) {
            setLoading(false);
            toast.error(response.data.message);
            setCreditCardFalseValue(true);
            setErrorMessageShow(true);
          } else {
            setLoading(false);
          }
        })
        .catch((error) => {
          setLoading(false);
          setCreditCardFalseValue(true);
          setErrorMessageShow(true);
          Errors(error);
        });
    }
  }, [amount, registeredCreditCard, isButtonDisabled, navigate]);

  const handleAmountChange = (e) => {
    const value = e.target.value.replace(",", ".");
    // Değer boşsa veya sadece rakamlar virgül ve nokta içeriyorsa durumu güncelle
    if (value === "" || /^\d*([.,]\d{0,2})?$/.test(value)) {
      setAmount(value);
    }
  };

  const getCards = useCallback(async () => {
    setLoading(true);

    await axios
      .get(`profile/get-cards`)
      .then((response) => {
        setLoading(false);
        setIyzicoCards(response.data.data?.iyzico?.items);
        setGarantiCards(response.data.data?.garanti?.items);
        if (response.data.data?.iyzico.items) {
          if (response.data.data?.iyzico?.items?.length !== 0) {
            setIsCardsExist(true);
            setRegisteredDefaultNotEmpty(true);
            setIsCardsExist2(true);
            setRegisteredCreditCard(
              response.data.data?.iyzico?.items?.filter((card) => card.default === 1)[0]
            );
            setRegisteredCreditCardValue(
              response.data.data?.iyzico?.items?.filter((card) => card.default === 1)[0]?.card_pan
            );
          }
        }
        if (response.data.data?.garanti.items) {
          if (response.data.data?.garanti?.items?.length !== 0) {
            setIsCardsExist(true);
            setRegisteredDefaultNotEmpty(true);
            setIsCardsExist2(true);
            setRegisteredCreditCard(
              response.data.data?.garanti?.items?.filter((card) => card.default === 1)[0]
            );
            setRegisteredCreditCardValue(
              response.data.data?.garanti?.items?.filter((card) => card.default === 1)[0]?.card_pan
            );
          }
        }
      })
      .catch((error) => {
        setLoading(false);
        Errors(error);
        setIsCardsExist(false);
      })
      .finally(() => {
        setLoading(false);
      });
  }, [
    setIyzicoCards,
    setGarantiCards,
    setIsCardsExist,
    setIsCardsExist2,
    setRegisteredDefaultNotEmpty,
  ]);

  const handleRegisteredIban = (e) => {
    setIban(e.iban);
    setFirstName(e.name);
    setLastName(e.surname);
  };

  useEffect(() => {
    getCards();
  }, [getCards]);

  useEffect(() => {
    setCreditCardFalseValue(false);

    if (creditCard.length !== 0) {
      setCreditCardError(false);
      setErrorMessageShow(false);
    }

    if (creditCard.length === 19) {
      setCreditCardLessError(false);
      setErrorMessageShow(false);
    }
  }, [creditCard]);

  useEffect(() => {
    const handlePaymentMessage = (event) => {
      if (event?.data?.data?.status === 200) {
        setPaidPrice(event?.data?.data?.paidPrice);
        setMessage(event?.data?.data?.message);
        setPaidStatus(true);
        // Etkinlik dinleyicisini kaldırma
        window.removeEventListener("message", handlePaymentMessage);
      }
    };

    // Etkinlik dinleyicisini ekleme
    window.addEventListener("message", handlePaymentMessage);

    // Temizleme işlemi
    return () => {
      window.removeEventListener("message", handlePaymentMessage);
    };
  }, []);

  useEffect(() => {
    getIbanList();
  }, []);

  return (
    <ThemeProvider theme={theme}>
      <section className="my-wallet">
        <NotificationBar />
        <ToastContainer />
        <SuccessPaymentNotDialog open={paymentNotSuccess} setOpen={setPaymentNotSuccess} />
        <PaymentTitle />
        <Box className="my-wallet-wrapper" style={WrapperStyle()}>
          <MyWalletNav />
          <animated.div ref={ref} style={springs}>
            <Box className="my-wallet-container">
              <MyWalletCard />
              <div className="my-wallet-payment">
                <div className="my-wallet-payment-box">
                  {paymentDataIsLoad && !paidStatus && (
                    <iframe
                      title="nizmetSabileOfisi"
                      id="my-iframe"
                      style={{
                        width: "100%",
                        height: "500px",
                      }}
                      srcdoc={url}
                    ></iframe>
                  )}
                  {paidStatus && (
                    <Box
                      sx={{
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                        height: "400px",
                        width: "100%",
                        flexDirection: "column",
                        gap: "var(--gap2x)",
                      }}
                    >
                      <Typography
                        sx={{
                          fontSize: "25px",
                          padding: "20px",
                          border: "1px solid var(--shipmentGreen)",
                          borderRadius: "var(--inputBorderRadius)",
                          boxShadow: "0 0 2px",
                          backgroundColor: "var(--background)",
                          color: "var(--priceText)",
                          fontWeight: 500,
                          display: "flex",
                          flexDirection: "column",
                          alignItems: "center",
                          justifyContent: "center",
                        }}
                      >
                        <span
                          style={{
                            fontSize: "23px",
                            fontWeight: 700,
                          }}
                        >
                          {LocalPrice(paidPrice)} ₺
                        </span>
                        ✅ {message}
                      </Typography>
                      <Button onClick={() => window.location.reload(true)} className="iyzi-button">
                        Tamamla
                      </Button>
                    </Box>
                  )}
                  {!paymentDataIsLoad &&
                    !paidStatus &&
                    (loading ? (
                      <Box
                        sx={{
                          display: "flex",
                          justifyContent: "center",
                          alignItems: "center",
                          height: "600px",
                        }}
                      >
                        <CircularProgress
                          sx={{
                            width: "36px !important",
                            height: "36px !important",
                          }}
                        />
                      </Box>
                    ) : (
                      <>
                        {walletPaymentStatus === 0 ? (
                          <h6 style={{ color: "var(--priceText)" }}>
                            Kredi Kartı ile Bakiye Yükleme
                          </h6>
                        ) : (
                          <h6 style={{ color: "var(--priceText)" }}>Havale ile Bakiye Yükleme</h6>
                        )}

                        <Box
                          sx={{
                            display: "flex",
                            gap: "var(--gap6x)",
                            flexDirection: "column",
                            justifyContent: "space-between",
                          }}
                        >
                          <Box
                            sx={{
                              display: "flex",
                              flexDirection: "column",
                              gap: "var(--gap2x)",
                              maxWidth: "480px",
                              width: "100%",
                              alignContent: "center",
                            }}
                          >
                            <Box>
                              {!paymentDataIsLoad &&
                                walletPaymentStatus === 0 &&
                                (isCardsExist ? (
                                  <Box
                                    sx={{
                                      display: "flex",
                                      flexDirection: "column",
                                      gap: "var(--gap2x)",
                                    }}
                                  >
                                    <FormControl size="small" fullWidth>
                                      <InputLabel sx={{ fontSize: "14px" }} id="credit-cards-label">
                                        Kayıtlı Kartlar
                                      </InputLabel>
                                      <Select
                                        labelId="credit-cards-label"
                                        id="credit-cards"
                                        label="Kayıtlı Kartlar"
                                        sx={{
                                          fontSize: "14px",
                                          display: "flex",
                                          minHeight: "40px",
                                        }}
                                        value={registeredCreditCardValue}
                                        onChange={(e) =>
                                          setRegisteredCreditCardValue(e.target.value)
                                        }
                                      >
                                        <MenuItem
                                          key="0"
                                          value=""
                                          onClick={() => setRegisteredCreditCard({})}
                                        >
                                          <em>Temizle</em>
                                        </MenuItem>
                                        {garantiCards?.map((card, key) => (
                                          <MenuItem
                                            key={key}
                                            value={card?.card_pan}
                                            onClick={() => setRegisteredCreditCard(card)}
                                            sx={{
                                              display: "flex",
                                              alignItems: "center",
                                              gap: "5px",
                                            }}
                                          >
                                            {CardImgChange(card?.card_pan)}{" "}
                                            <span>{card?.card_pan}</span>
                                          </MenuItem>
                                        ))}
                                        {iyzicoCards?.map((card, key) => (
                                          <MenuItem
                                            key={key}
                                            value={card?.card_pan}
                                            onClick={() => setRegisteredCreditCard(card)}
                                            sx={{
                                              display: "flex",
                                              alignItems: "center",
                                              gap: "5px",
                                            }}
                                          >
                                            {CardImgChange(card?.card_pan)} {card?.card_pan}
                                          </MenuItem>
                                        ))}
                                      </Select>
                                    </FormControl>
                                    <Box
                                      sx={{
                                        cursor: "pointer",
                                        color: "var(--priceText)",
                                        transition: "all 150ms linear",
                                        ":hover": { color: "var(--info)" },
                                      }}
                                      onClick={() => setIsCardsExist(false)}
                                      disabled={isButtonDisabled}
                                    >
                                      {isButtonDisabled
                                        ? "Ödeme Yapmak İçin Profilinizi Tamamlayın!"
                                        : "+ Başka Bir Kart ile Ödeme Yap"}
                                    </Box>
                                  </Box>
                                ) : (
                                  <Box>
                                    <Box
                                      sx={{
                                        display: "grid",
                                        gridTemplateColumns: "repeat(1, minmax(240px, 1fr))",
                                        gap: "var(--gap)",
                                        maxWidth: "500px",
                                      }}
                                    >
                                      <TextField
                                        value={firstName}
                                        onChange={(e) =>
                                          setFirstName(toTurkishUpperCase(e.target.value))
                                        }
                                        size="small"
                                        fullWidth
                                        label="İsim"
                                      />
                                      <TextField
                                        value={lastName}
                                        onChange={(e) =>
                                          setLastName(toTurkishUpperCase(e.target.value))
                                        }
                                        size="small"
                                        fullWidth
                                        label="Soyisim"
                                      />
                                      {walletPaymentStatus === 0 && (
                                        <>
                                          <TextField
                                            value={creditCard}
                                            onChange={creditCardChanger}
                                            size="small"
                                            inputMode="numeric"
                                            inputProps={{ maxLength: 19 }}
                                            fullWidth
                                            label="Kart Numarası"
                                            placeholder="1111 0000 2222 4444"
                                          />
                                          <Box
                                            sx={{
                                              display: "grid",
                                              gridTemplateColumns: "repeat(3, minmax(50px, 1fr))",
                                              width: "100%",
                                              gap: "5px",
                                            }}
                                            className="card-info-text-date"
                                          >
                                            <TextField
                                              size="small"
                                              label="Ay"
                                              value={month}
                                              onChange={handleMonthChange}
                                              onBlur={handleMonthBlur}
                                              placeholder="03"
                                              autoComplete="off"
                                              inputMode="numeric"
                                              inputProps={{ maxLength: 2 }}
                                            />
                                            <TextField
                                              size="small"
                                              label="Yıl"
                                              value={year}
                                              onChange={handleYearChange}
                                              onBlur={handleYearBlur}
                                              placeholder="23"
                                              autoComplete="off"
                                              inputMode="numeric"
                                              inputProps={{ maxLength: 2 }}
                                            />
                                            <TextField
                                              size="small"
                                              label="CVC2"
                                              placeholder="CVC2"
                                              onChange={handleCvc2Change}
                                              onBlur={handleCvc2Blur}
                                              value={cvc2}
                                              inputProps={{
                                                maxLength: 3,
                                                pattern: "[0-9]*",
                                              }}
                                              autoComplete="off"
                                              type="password"
                                              inputMode="numeric"
                                            />
                                          </Box>
                                        </>
                                      )}
                                    </Box>
                                    <Box
                                      sx={{
                                        marginTop: "var(--gap)",
                                        display: "flex",
                                        justifyContent: "space-between",
                                        flexWrap: "wrap",
                                        gap: "var(--gap)",
                                      }}
                                    >
                                      {walletPaymentStatus === 0 && (
                                        <FormControlLabel
                                          sx={{
                                            userSelect: "none",
                                          }}
                                          control={
                                            <Checkbox
                                              checked={registerCard}
                                              onChange={(e) => setRegisterCard(e.target.checked)}
                                            />
                                          }
                                          label="Kartımı Kaydet"
                                        />
                                      )}
                                      {isCardsExist2 && (
                                        <Button
                                          sx={{
                                            padding: "7px 16px",
                                            borderRadius: "var(--buttonBorderRadius)",
                                            backgroundColor: "var(--info)",
                                            color: "var(--lighterBg)",
                                            transition: "all 150ms linear",
                                            textTransform: "none",
                                            ":hover": {
                                              backgroundColor: "var(--blueBrand)",
                                            },
                                          }}
                                          onClick={() => setIsCardsExist(true)}
                                          disabled={isButtonDisabled}
                                        >
                                          {isButtonDisabled
                                            ? "Ödeme Yapmak İçin Profilinizi Tamamlayın!"
                                            : "Kayıtlı Kart ile Ödeme Yap"}
                                        </Button>
                                      )}
                                    </Box>
                                  </Box>
                                ))}
                            </Box>
                            {/*  */}
                            {walletPaymentStatus === 1 && (
                              <>
                                <div
                                  style={{
                                    display: "flex",
                                    flexWrap: "wrap",
                                    justifyContent: "space-between",
                                    gap: 10,
                                  }}
                                >
                                  <TextField
                                    sx={{ maxWidth: "220px", width: "100%" }}
                                    size="small"
                                    label="Ad"
                                    value={firstName}
                                    onChange={(e) =>
                                      setFirstName(toTurkishUpperCase(e.target.value))
                                    }
                                  />
                                  <TextField
                                    sx={{ maxWidth: "220px", width: "100%" }}
                                    size="small"
                                    label="Soyad"
                                    value={lastName}
                                    onChange={(e) =>
                                      setLastName(toTurkishUpperCase(e.target.value))
                                    }
                                  />
                                </div>
                                <div>
                                  {!manuelIban && (
                                    <FormControl size="small" fullWidth>
                                      <InputLabel sx={{ fontSize: "14px" }} id="credit-cards-label">
                                        Kayıtlı IBAN
                                      </InputLabel>
                                      <Select
                                        labelId="credit-cards-label"
                                        id="credit-cards"
                                        label="Kayıtlı IBAN"
                                        sx={{
                                          fontSize: "14px",
                                          display: "flex",
                                          minHeight: "40px",
                                        }}
                                        value={iban}
                                      >
                                        <MenuItem onClick={() => setManuelIban(true)}>
                                          Yazacağım
                                        </MenuItem>
                                        {ibanListArray?.map((iban, key) => (
                                          <MenuItem
                                            key={key}
                                            value={iban?.iban}
                                            onClick={() => handleRegisteredIban(iban)}
                                          >
                                            {iban?.iban}
                                          </MenuItem>
                                        ))}
                                      </Select>
                                    </FormControl>
                                  )}
                                  {manuelIban && (
                                    <div style={{ display: "flex", gap: "5px" }}>
                                      {ibanListArray.length > 0 && (
                                        <Tooltip title="Kayıtlı IBAN listesine dön">
                                          <IconButton onClick={() => setManuelIban(false)}>
                                            <SettingsBackupRestoreOutlinedIcon />
                                          </IconButton>
                                        </Tooltip>
                                      )}
                                      <TextField
                                        sx={{
                                          maxWidth: "480px",
                                          width: "100%",
                                        }}
                                        size="small"
                                        label="IBAN (Boşluk Bırakarak Yazınız.)"
                                        value={iban}
                                        onChange={handleIbanChange}
                                        onBlur={validateIBAN}
                                        inputProps={{ maxLength: 32 }}
                                        error={!isIbanValid}
                                        helperText={
                                          !isIbanValid && "Girdiğiniz IBAN numarası geçerli değil."
                                        }
                                      />
                                    </div>
                                  )}
                                </div>
                              </>
                            )}
                            <TextField
                              size="small"
                              label="Ödeme Tutarı"
                              value={amount}
                              onChange={handleAmountChange}
                              placeholder="Tutarı Giriniz"
                              sx={{ maxWidth: "480px", width: "100%" }}
                              autoFocus
                            />
                            <Box
                              sx={{
                                display: "grid",
                                gap: "var(--gap)",
                                gridTemplateColumns: "repeat(auto-fit, minmax(76px, 1fr))",
                              }}
                            >
                              <MenuItem
                                sx={{
                                  border: "1px solid var(--inputBorderColor)",
                                  borderRadius: "var(--buttonBorderRadius)",
                                  display: "flex",
                                  alignItems: "center",
                                  justifyContent: "center",
                                  padding: "5px 0",
                                  transition: "all 150ms linear",
                                  cursor: "pointer",
                                  ":hover": {
                                    border: "1px solid var(--info)",
                                    color: "var(--info)",
                                    backgroundColor: "var(--lighterBg)",
                                  },
                                }}
                                style={
                                  amount === 100
                                    ? {
                                        color: "var(--info)",
                                        border: "1px solid var(--info)",
                                      }
                                    : {
                                        color: "var(--priceText)",
                                        border: "1px solid var(--priceText)",
                                      }
                                }
                                value="100"
                                onClick={(e) => handleChoiceMoney(e)}
                              >
                                100TL
                              </MenuItem>
                              <MenuItem
                                sx={{
                                  border: "1px solid var(--inputBorderColor)",
                                  borderRadius: "var(--buttonBorderRadius)",
                                  display: "flex",
                                  alignItems: "center",
                                  justifyContent: "center",
                                  padding: "5px 0",
                                  transition: "all 150ms linear",
                                  cursor: "pointer",
                                  ":hover": {
                                    border: "1px solid var(--info)",
                                    color: "var(--info)",
                                    backgroundColor: "var(--lighterBg)",
                                  },
                                }}
                                style={
                                  amount === 250
                                    ? {
                                        color: "var(--info)",
                                        border: "1px solid var(--info)",
                                      }
                                    : {
                                        color: "var(--priceText)",
                                        border: "1px solid var(--priceText)",
                                      }
                                }
                                value="250"
                                onClick={(e) => handleChoiceMoney(e)}
                              >
                                250TL
                              </MenuItem>
                              <MenuItem
                                sx={{
                                  border: "1px solid var(--inputBorderColor)",
                                  borderRadius: "var(--buttonBorderRadius)",
                                  display: "flex",
                                  alignItems: "center",
                                  justifyContent: "center",
                                  padding: "5px 0",
                                  transition: "all 150ms linear",
                                  cursor: "pointer",
                                  ":hover": {
                                    border: "1px solid var(--info)",
                                    color: "var(--info)",
                                    backgroundColor: "var(--lighterBg)",
                                  },
                                }}
                                style={
                                  amount === 500
                                    ? {
                                        color: "var(--info)",
                                        border: "1px solid var(--info)",
                                      }
                                    : {
                                        color: "var(--priceText)",
                                        border: "1px solid var(--priceText)",
                                      }
                                }
                                value="500"
                                onClick={(e) => handleChoiceMoney(e)}
                              >
                                500TL
                              </MenuItem>
                              <MenuItem
                                sx={{
                                  border: "1px solid var(--inputBorderColor)",
                                  borderRadius: "var(--buttonBorderRadius)",
                                  display: "flex",
                                  alignItems: "center",
                                  justifyContent: "center",
                                  padding: "5px 0",
                                  transition: "all 150ms linear",
                                  cursor: "pointer",
                                  ":hover": {
                                    border: "1px solid var(--info)",
                                    color: "var(--info)",
                                    backgroundColor: "var(--lighterBg)",
                                  },
                                }}
                                style={
                                  amount === 1000
                                    ? {
                                        color: "var(--info)",
                                        border: "1px solid var(--info)",
                                      }
                                    : {
                                        color: "var(--priceText)",
                                        border: "1px solid var(--priceText)",
                                      }
                                }
                                value="1000"
                                onClick={(e) => handleChoiceMoney(e)}
                              >
                                1000TL
                              </MenuItem>
                              <MenuItem
                                sx={{
                                  border: "1px solid var(--inputBorderColor)",
                                  borderRadius: "var(--buttonBorderRadius)",
                                  display: "flex",
                                  alignItems: "center",
                                  justifyContent: "center",
                                  padding: "5px 0",
                                  transition: "all 150ms linear",
                                  cursor: "pointer",
                                  ":hover": {
                                    border: "1px solid var(--info)",
                                    color: "var(--info)",
                                    backgroundColor: "var(--lighterBg)",
                                  },
                                }}
                                style={
                                  amount === 1500
                                    ? {
                                        color: "var(--info)",
                                        border: "1px solid var(--info)",
                                      }
                                    : {
                                        color: "var(--priceText)",
                                        border: "1px solid var(--priceText)",
                                      }
                                }
                                value="1500"
                                onClick={(e) => handleChoiceMoney(e)}
                              >
                                1500TL
                              </MenuItem>
                            </Box>
                            {walletPaymentStatus === 0 ? (
                              <TextField
                                sx={{ maxWidth: "480px" }}
                                size="small"
                                label="Açıklama"
                                value={amountNote}
                                onChange={(e) => setAmountNote(toTurkishUpperCase(e.target.value))}
                                rows={4}
                                multiline
                              />
                            ) : (
                              <Box>
                                <Box
                                  sx={{
                                    backgroundColor: "var(--pastelBlue)",
                                    borderRadius: "var(--buttonBorderRadius)",
                                    display: "flex",
                                    gap: "3px",
                                    padding: "10px",
                                  }}
                                >
                                  <InfoIcon
                                    sx={{
                                      color: "var(--info)",
                                      width: "18px",
                                      height: "18px",
                                    }}
                                  />
                                  <Box
                                    sx={{
                                      fontSize: "12px",
                                      color: "var(--priceText)",
                                    }}
                                  >
                                    Ödeme referans numaranızı kopyalayın. Bu numara ile ödeme
                                    yapabilirsiniz.
                                  </Box>
                                </Box>
                                <div
                                  style={{
                                    display: "flex",
                                    justifyContent: "center",
                                    alignItems: "center",
                                  }}
                                >
                                  {referans}
                                  <Tooltip title={copied ? "Kopyalandı!" : "Kopyalamak için tıkla"}>
                                    <IconButton
                                      onClick={handleClick}
                                      onMouseLeave={() => setCopied(false)}
                                    >
                                      <ContentCopyOutlinedIcon />
                                    </IconButton>
                                  </Tooltip>
                                </div>
                              </Box>
                            )}
                          </Box>
                          {walletPaymentStatus === 1 && (
                            <Box
                              sx={{
                                width: "100%",
                                display: "flex",
                                flexDirection: "column",
                                gap: "var(--gap)",
                              }}
                            >
                              <TextField
                                value={paymentRefNumber}
                                onChange={(e) => setPaymentRefNumber(e.target.value)}
                                label="Ödeme Referans Numarası"
                                size="small"
                                fullWidth
                                required
                              />
                              <Box
                                sx={{
                                  backgroundColor: "var(--pastelBlue)",
                                  borderRadius: "var(--buttonBorderRadius)",
                                  display: "flex",
                                  gap: "3px",
                                  padding: "10px",
                                }}
                              >
                                <InfoIcon
                                  sx={{
                                    color: "var(--info)",
                                    width: "18px",
                                    height: "18px",
                                  }}
                                />
                                <Box
                                  sx={{
                                    fontSize: "12px",
                                    color: "var(--priceText)",
                                  }}
                                >
                                  Lütfen bu işlemi yapmadan önce;
                                  <br />
                                  Garanti Bankası kurumsal hesabımıza (İYZİ BİLİŞİM ANONİM ŞİRKETİ -{" "}
                                  <strong style={{ fontSize: "12px" }}>
                                    TR98 0006 2001 4750 0006 2963 88
                                  </strong>
                                  ) ödemenizi yapınız.
                                </Box>
                              </Box>
                              {walletPaymentStatus === 0 && (
                                <Box
                                  sx={{
                                    backgroundColor: "var(--pastelBlue)",
                                    borderRadius: "var(--buttonBorderRadius)",
                                    display: "flex",
                                    gap: "3px",
                                    padding: "10px",
                                  }}
                                >
                                  <InfoIcon
                                    sx={{
                                      color: "var(--info)",
                                      width: "18px",
                                      height: "18px",
                                    }}
                                  />
                                  <Box
                                    sx={{
                                      fontSize: "12px",
                                      color: "var(--priceText)",
                                    }}
                                  >
                                    Açıklama kısmına{" "}
                                    <strong style={{ fontSize: "12px" }}>
                                      iyziCüzdan Ödemesi 31000028
                                    </strong>{" "}
                                    yazınız.
                                  </Box>
                                </Box>
                              )}

                              <Box
                                sx={{
                                  backgroundColor: "var(--pastelBlue)",
                                  borderRadius: "var(--buttonBorderRadius)",
                                  display: "flex",
                                  gap: "3px",
                                  padding: "10px",
                                }}
                              >
                                <InfoIcon
                                  sx={{
                                    color: "var(--info)",
                                    width: "18px",
                                    height: "18px",
                                  }}
                                />
                                <Box
                                  sx={{
                                    fontSize: "12px",
                                    color: "var(--priceText)",
                                  }}
                                >
                                  Ödemenizi yaptıktan sonra referans numaranızı ve tutarı yukarıya
                                  girerek formu doldurmanız durumunda, kontrol sonrası ödemeniz
                                  cüzdan bakiyenize tanımlanacaktır.
                                </Box>
                              </Box>
                            </Box>
                          )}
                        </Box>
                        <Box
                          sx={{
                            width: "100%",
                            display: "flex",
                            justifyContent: "space-between",
                            alignItems: "center",
                            gap: "var(--gap2x)",
                          }}
                        >
                          <Box>
                            <Box
                              sx={{
                                color: "var(--priceText)",
                                fontSize: "16px",
                              }}
                            >
                              Eklenecek Tutar;
                            </Box>
                            <Box
                              sx={{
                                color: "var(--info)",
                                fontSize: "30px",
                                fontWeight: 700,
                              }}
                            >
                              {amount === "" ? LocalPrice(0) : LocalPrice(amount)} ₺
                            </Box>
                          </Box>
                          <Box sx={{ display: "flex", gap: "var(--gap)" }}>
                            {isCardsExist && walletPaymentStatus === 0 ? (
                              <Button
                                className="iyzi-button"
                                onClick={registeredPaymentSend}
                                // disabled={isButtonDisabled}
                              >
                                {isButtonDisabled
                                  ? "Bakiye Yüklemek İçin Profilinizi Tamamlayın!"
                                  : "Bakiye Yükle"}
                              </Button>
                            ) : !isCardsExist && walletPaymentStatus === 0 ? (
                              <Button
                                className="iyzi-button"
                                onClick={paymentSend}
                                // disabled={isButtonDisabled}
                              >
                                {isButtonDisabled
                                  ? "Bakiye Yüklemek İçin Profilinizi Tamamlayın!"
                                  : "Bakiye Yükle"}
                              </Button>
                            ) : (
                              <Button
                                className="iyzi-button"
                                onClick={handleSubmitOrder}
                                // disabled={isButtonDisabled}
                              >
                                {isButtonDisabled
                                  ? "Ödeme Bildirimi Göndermek İçin Profilinizi Tamamlayın!"
                                  : "Ödeme Bildirimi Gönder"}
                              </Button>
                            )}
                          </Box>
                        </Box>
                      </>
                    ))}
                </div>
              </div>
            </Box>
            {walletPaymentStatus === 1 && (
              <Box className="my-wallet-container">
                <div className="my-wallet-payment">
                  <Table>
                    <TableHead>
                      <TableRow>
                        <TableCell>Referans Numarası</TableCell>
                        <TableCell>Gönderici Adı</TableCell>
                        <TableCell>IBAN</TableCell>
                        <TableCell>Tutar</TableCell>
                        <TableCell>Durum</TableCell>
                      </TableRow>
                    </TableHead>

                    <TableBody>
                      {moneyOrders
                        .slice()
                        .reverse()
                        .map((order) => (
                          <TableRow key={order.ref_no} className={`status-${order.status}`}>
                            <TableCell>
                              {order.ref_no}
                              {order.response_description === "Onaylandı" ? null : (
                                <div style={{ display: "flex", flexWrap: "wrap" }}>
                                  <strong
                                    style={{
                                      fontSize: "12px",
                                      color: "var(--shipmentRed)",
                                    }}
                                  >
                                    {order.response_description === ""
                                      ? ""
                                      : `İptal Nedeni: ${" "}`}
                                  </strong>{" "}
                                  <p style={{ fontSize: "12px" }}>
                                    {order.response_description === "Onaylandı"
                                      ? ""
                                      : order.response_description}
                                  </p>
                                </div>
                              )}
                            </TableCell>
                            <TableCell>{order.sender_name}</TableCell>
                            <TableCell>{order.iban}</TableCell>
                            <TableCell>{order.amount}</TableCell>
                            <TableCell>
                              <span className={`status-cell ${statusColors[order.status]}`}>
                                {getStatusText(order.status)}
                              </span>
                            </TableCell>
                          </TableRow>
                        ))}
                    </TableBody>
                  </Table>
                </div>
              </Box>
            )}
          </animated.div>
        </Box>
      </section>
    </ThemeProvider>
  );
};

export default MyWalletPayment;
