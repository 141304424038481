import { Box } from "@mui/material";
import { Link } from "react-router-dom";
import "../../style/myWallet/MyWallet.css";

const MyWalletNav = () => {
  const links = [
    {
      page: "İyziBakiyem",
      location: "/my-wallet",
      fontWeight: 400,
    },
    {
      page: "+Bakiye Yükle",
      location: "/my-wallet/add-balance",
      fontWeight: 700,
    },
    {
      page: "Faturalarım",
      location: "/my-wallet/my-bills",
      fontWeight: 400,
    },
    {
      page: "Ekstrem",
      location: "/my-wallet/extract",
      fontWeight: 400,
    },
    {
      page: "Bakiye İade Talepleri",
      location: "/my-wallet/balance-refund-request",
      fontWeight: 400,
    },
    {
      page: "Tazmin İşlemleri",
      location: "/my-wallet/compensation-proceedings",
      fontWeight: 400,
    },
  ];

  return (
    <Box className="my-wallet-nav">
      {links.map((link, key) => (
        <Link
          key={key}
          className={"my-wallet-nav-link"}
          style={{
            color: link.location === window.location.pathname ? "var(--info)" : "var(--priceText)",
            fontSize: "13px",
            fontWeight: link.fontWeight,
          }}
          to={link.location}
        >
          {link.page}
        </Link>
      ))}
    </Box>
  );
};

export default MyWalletNav;
