import { Box } from "@mui/material";
import { useCallback, useEffect, useState } from "react";
import { useRecoilState, useRecoilValue, useSetRecoilState } from "recoil";
import { startCardAnimationState } from "../../../atoms/startCardAnimation";
import AddCard from "./AddCard";
import CreateCard from "./CreateCard";
import miniLogo from "../../../img/logos/logo_white_mini_loading.svg";
import axios from "../../../api/axios";
import { useCookies } from "react-cookie";
import "../../../style/profileSettings/RegisteredCards.css";
import { garantiCardsState, iyzicoCardsState } from "../../../atoms/membership";
import IyzicoCard from "./IyzicoCard";
import Errors from "../../../utils/Errors";
import InfoOutlinedIcon from "@mui/icons-material/InfoOutlined";

const Cards = () => {
  const [token] = useCookies(["jwt"]);
  const jwt = token.jwt;
  axios.defaults.headers.common["Authorization"] = `Bearer ${jwt}`;

  const userInfo = JSON.parse(localStorage.getItem("user"));
  const id = userInfo?.user.id;

  const startCardAnimation = useRecoilValue(startCardAnimationState);

  const [cardLoader, setCardLoader] = useState(true);
  const [iyzicoCards, setIyzicoCards] = useRecoilState(iyzicoCardsState);
  const setGarantiCards = useSetRecoilState(garantiCardsState);

  const getCards = useCallback(async () => {
    setCardLoader(true);

    await axios
      .get(`profile/get-cards`)
      .then((response) => {
        setIyzicoCards(response.data.data?.iyzico?.items);
        setGarantiCards(response.data.data?.garanti?.items);
      })
      .catch((error) => {
        Errors(error);
      })
      .finally(() => setCardLoader(false));
  }, [setIyzicoCards, setGarantiCards]);

  useEffect(() => {
    getCards();
  }, [getCards]);

  return (
    <>
      <p
        style={{
          color: "var(--priceText)",
          display: "flex",
          alignItems: "center",
          marginTop: "var(--gap)",
        }}
      >
        <InfoOutlinedIcon />
        &nbsp;&nbsp;Tüm kayıtlı kredi kartı bilgileriniz, iyzico'nun güvenli altyapısında
        saklanmaktadır.
      </p>
      <>
        {startCardAnimation ? "" : <AddCard />}
        <CreateCard />
      </>
      {iyzicoCards.length === 0 && (
        <div style={{ marginTop: "20px" }}>Kayıtlı kredi kartınız bulunmamaktadır.</div>
      )}
      {!startCardAnimation &&
        (cardLoader ? (
          <div
            style={{
              width: "100%",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              minHeight: "200px",
            }}
          >
            <img className="loading-logo" src={miniLogo} alt="" />
          </div>
        ) : (
          <Box
            sx={{
              display: "flex",
              gap: "var(--gap2x)",
              flexWrap: "wrap",
              marginTop: "var(--gap2x)",
            }}
          >
            {iyzicoCards?.map((card, key) => (
              <div
                key={key}
                className={
                  card?.default === 1
                    ? "card-info-display-wrapper default-card"
                    : "card-info-display-wrapper"
                }
              >
                <IyzicoCard card={card} setGarantiCards={setGarantiCards} cardId={id} />
              </div>
            ))}
          </Box>
        ))}
    </>
  );
};

export default Cards;
