import React, { useState } from "react";
import { NavLink } from "react-router-dom";
import MenuIcon from "@mui/icons-material/Menu";
import CloseIcon from "@mui/icons-material/Close";
import { List, ListItem, ListItemText } from "@mui/material";
import icon1 from "../../img/icons/ic_round-support-agent.svg";
import icon2 from "../../img/icons/email-white.svg";

const Header = () => {
  const [menuOpen, setMenuOpen] = useState(false);

  const toggleMenu = () => {
    setMenuOpen(!menuOpen);
  };

  return (
    <>
      <header className="iyzi-header">
        <div className="top-bg">
          <div className="top-bg-container">
            <div className="top-bg-content">
              <div className="top-bg-item">
                <img src={icon1} alt={"icon"} width={24} />
                <a
                  href="tel:08505324994"
                  style={{
                    color: "white",
                    textDecoration: "none",
                  }}
                >
                  0850 532 49 94
                </a>
              </div>
              <div className="top-bg-item">
                <img src={icon2} alt={"icon"} width={20} />
                <a
                  href="mailto:info@iyziship.com"
                  style={{
                    color: "white",
                    textDecoration: "none",
                  }}
                >
                  info@iyziship.com
                </a>
              </div>
            </div>
           
          </div>
        </div>
        <nav className="iyzi-header-links-wrapper">
          <div className="iyzi-header-links">
            <NavLink to={"/"}>
              <img
                width={144}
                src="/images/home/logo.svg"
                alt="IyziShip Logo"
              />
            </NavLink>
            <div className="header-link-group">
              <NavLink className={"header-link"} to="/nasil-calisir">
                Nasıl Çalışır?
              </NavLink>

              <NavLink className={"header-link"} to="/entegrasyonlar">
                Entegrasyonlar
              </NavLink>
              <NavLink className={"header-link"} to="/fiyatlandirma">
                Fiyatlandırma
              </NavLink>
              <NavLink className={"header-link"} to="/sss">
                S.S.S.
              </NavLink>
              <NavLink
                className={"header-link"}
                target="_blank"
                to="https://blog.iyziship.com"
              >
                Blog
              </NavLink>
              <NavLink className={"header-link"} to="/iletisim">
                İletişim
              </NavLink>
            </div>
            <div className="header-link-group sign">
              <NavLink className={"header-link"} to="/login">
                Giriş Yap
              </NavLink>
              <NavLink
                style={{
                  textDecoration: "none",
                  background: "var(--btnBgBlue)",
                  padding: "7px 12px",
                  color: "var(--lighterBg)",
                  borderRadius: "var(--wrapperBorderRadius)",
                  fontSize: "16px",
                }}
                to="/register"
              >
                Kayıt Ol
              </NavLink>
            </div>
            <div className="menu-icon" onClick={toggleMenu}>
              {menuOpen ? <CloseIcon /> : <MenuIcon />}
            </div>
          </div>
          {menuOpen && (
            <div className="mobile-menu">
              <List>
                <ListItem onClick={toggleMenu}>
                  <NavLink
                    style={{
                      textDecoration: "none",
                      color: "black",
                      marginBottom: "5px",
                    }}
                    to="/nasil-calisir"
                    className="nav-link"
                  >
                    Nasıl Çalışır?
                  </NavLink>
                </ListItem>
                <ListItem onClick={toggleMenu}>
                  <NavLink
                    style={{
                      textDecoration: "none",
                      color: "black",
                    }}
                    to="/entegrasyonlar"
                    className="nav-link"
                  >
                    <ListItemText primary="Entegrasyonlar" />
                  </NavLink>
                </ListItem>
                <ListItem onClick={toggleMenu}>
                  <NavLink
                    style={{
                      textDecoration: "none",
                      color: "black",
                    }}
                    to="/fiyatlandirma"
                    className="nav-link"
                  >
                    <ListItemText primary="Fiyatlandırma" />
                  </NavLink>
                </ListItem>
                <ListItem onClick={toggleMenu}>
                  <NavLink
                    style={{
                      textDecoration: "none",
                      color: "black",
                    }}
                    to="/sss"
                    className="nav-link"
                  >
                    <ListItemText primary="S.S.S." />
                  </NavLink>
                </ListItem>
                <ListItem onClick={toggleMenu}>
                  <NavLink
                    style={{
                      textDecoration: "none",
                      color: "black",
                    }}
                    to="https://blog.iyziship.com"
                    className="nav-link"
                    target="_blank"
                  >
                    <ListItemText primary="Blog" />
                  </NavLink>
                </ListItem>
                <ListItem onClick={toggleMenu}>
                  <NavLink
                    style={{
                      textDecoration: "none",
                      color: "black",
                    }}
                    to="/iletisim"
                    className="nav-link"
                  >
                    <ListItemText primary="İletişim" />
                  </NavLink>
                </ListItem>
              </List>
              <List
                sx={{
                  display: "flex",
                  justifyContent: "space-between",
                  padding: "0px 10px",
                  gap: "30px",
                }}
              >
                <a
                  style={{
                    padding: "10px 30px",
                    width: "100%",
                    fontWeight: 700,
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    textDecoration: "none",
                    height: " 49.927px",
                    gap: "10px",
                    fontSize: "14px",
                    lineHeight: "18px",
                    cursor: "pointer",
                  }}
                  href="/login"
                  className="iyzi-button-bg"
                >
                  Giriş Yap
                </a>
                <a
                  style={{
                    padding: " 9px 14px",
                    width: "100%",
                    fontWeight: 700,
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    textDecoration: "none",
                    height: " 49.927px",
                    gap: "10px",
                    fontSize: "14px",
                    lineHeight: "18px",
                    cursor: "pointer",
                  }}
                  href="/register"
                  className="iyzi-button-blue"
                >
                  Kayıt Ol
                </a>
              </List>
            </div>
          )}
        </nav>
      </header>
    </>
  );
};

export default Header;
