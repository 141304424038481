import { styled } from "@mui/material/styles";
import CloseIcon from "@mui/icons-material/Close";
import {
  Button,
  Checkbox,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControlLabel,
  FormGroup,
  IconButton,
  Tooltip,
  Typography,
} from "@mui/material";
import { useRecoilState } from "recoil";
import { pageFormatState } from "../../../atoms/orders";
import { useCallback } from "react";
import InfoOutlinedIcon from "@mui/icons-material/InfoOutlined";

const BootstrapDialog = styled(Dialog)(({ theme }) => ({
  "& .MuiDialogContent-root": {
    padding: theme.spacing(2),
  },
  "& .MuiDialogActions-root": {
    padding: theme.spacing(1),
  },
}));

export default function OrderAreYouSureDialog(props) {
  const { title, text, handleSubmit, handleClose, open, submitText, loading } = props;

  const [pageFormat, setPageFormat] = useRecoilState(pageFormatState);

  const handleCheckChange = useCallback(
    (e) => {
      setPageFormat(e);
    },
    [setPageFormat]
  );

  return (
    <div>
      <BootstrapDialog onClose={handleClose} aria-labelledby="customized-dialog-title" open={open}>
        <DialogTitle sx={{ m: 0, p: 2 }} id="customized-dialog-title">
          {title}
        </DialogTitle>
        <IconButton
          aria-label="close"
          onClick={handleClose}
          sx={{
            position: "absolute",
            right: 8,
            top: 8,
            color: (theme) => theme.palette.grey[500],
          }}
        >
          <CloseIcon />
        </IconButton>
        <DialogContent dividers>
          <FormGroup sx={{ flexDirection: "row", gap: "var(--gap)" }}>
            <FormControlLabel
              control={
                <Checkbox checked={pageFormat === "A4"} onChange={() => handleCheckChange("A4")} />
              }
              label="A4"
            />
            <div style={{ display: "flex", gap: "var(--gap)", alignItems: "center" }}>
              <FormControlLabel
                control={
                  <Checkbox
                    checked={pageFormat === "TW-2037"}
                    onChange={() => handleCheckChange("TW-2037")}
                  />
                }
                label="TW-2037"
              />
              <Tooltip title={"Yapışkanlı A4 Lazer Etiket Formatı"}>
                <InfoOutlinedIcon sx={{ color: "var(--btnBgBlue)" }} />
              </Tooltip>
            </div>
          </FormGroup>
          <Typography gutterBottom>{text}</Typography>
        </DialogContent>
        <DialogActions>
          {loading ? (
            <div
              style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                width: "96px",
                height: "35px",
              }}
            >
              <CircularProgress sx={{ width: "21px!important", height: "21px!important" }} />
            </div>
          ) : (
            <Button className="iyzi-button-blue" onClick={handleSubmit}>
              {submitText}
            </Button>
          )}
          <Button className="iyzi-button-blue" onClick={handleClose}>
            İptal
          </Button>
        </DialogActions>
      </BootstrapDialog>
    </div>
  );
}
