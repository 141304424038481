import { Button, Modal } from "@mui/material";
import CheckCircleOutlineIcon from "@mui/icons-material/CheckCircleOutline";
import { NavLink } from "react-router-dom";
import { useRecoilState } from "recoil";
import { orderSuccessModalState } from "../../../atoms/orders";

const OrderSuccess = (props) => {
  const { supplier } = props;

  const [orderSuccessModal, setOrderSuccessModal] = useRecoilState(orderSuccessModalState);

  const handleClose = () => {
    setOrderSuccessModal(false);
  };

  const localeCargoMessage = () => {
    switch (supplier?.supplier) {
      case "Aras":
        return { message: "ARAS KARGO", code_message: "Aras Kargo Kodu", code: 2206354550828 };
      case "Sürat":
        return { message: "SÜRAT KARGO", code_message: "Sürat Kargo Kodu", code: 1316232654 };
      case "Mng":
        return { message: "MNG KARGO", code_message: "Mng Kargo Kodu", code: 841699904 };
      case "iyziKurye":
        return {
          message: `iyziKurye ${(
            <span style={{ fontSize: "inherit", color: "var(--text)" }}>
              ile toplama kaydı oluşturmadıysanız, Kurye Kaydı sekmesinden kayıt oluşturunuz.
            </span>
          )}`,
          code_message: null,
          code: null,
        };
      default:
        return {
          message: null,
          code_message: null,
          code: null,
        };
    }
  };

  return (
    <Modal
      open={orderSuccessModal}
      onClose={handleClose}
      sx={{
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        padding: "20px",
        maxWidth: "726px",
        margin: "auto",
        width: "100%",
        outline: "none",
      }}
    >
      <div
        style={{
          width: "100%",
          backgroundColor: "var(--lighterBg)",
          padding: "20px",
          borderRadius: "var(--wrapperBorderRadius)",
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          gap: "var(--gap)",
          color: "var(--priceText)",
          maxHeight: "90vh",
          overflowY: "auto",
          outline: "none",
        }}
      >
        <CheckCircleOutlineIcon
          sx={{ color: "var(--shipmentGreen)", width: "120px", height: "120px" }}
        />
        <h2
          style={{
            fontSize: "20px",
            textAlign: "center",
            lineHeight: "30px",
            color: "var(--text)",
          }}
        >
          Gönderiniz Başarıyla Oluşturulmuştur
        </h2>
        <p
          style={{
            maxWidth: "600px",
            width: "100%",
            textAlign: "center",
            fontSize: "16px",
            color: "var(--text)",
          }}
        >
          Etiketi oluşturulmuş gönderilerinizi{" "}
          <NavLink style={{ fontSize: "16px", color: "var(--btnBgBlue)" }} to={"/my-shipment"}>
            Gönderilerim
          </NavLink>{" "}
          sayfasından takip edebilirsiniz
        </p>
        {localeCargoMessage().message ? (
          <p
            style={{
              maxWidth: "600px",
              width: "100%",
              textAlign: "center",
              fontSize: "16px",
              color: "var(--text)",
            }}
          >
            Lütfen çıkarmış olduğunuz etiketi veya etiketleri gönderinin üzerine yapıştırarak seçmiş
            olduğunuz{" "}
            <span style={{ color: "var(--btnBgBlue)", fontSize: "inherit", fontWeight: 600 }}>
              {localeCargoMessage().message}
            </span>{" "}
            ile iyziMerkez’e gönderiniz.
          </p>
        ) : (
          <p
            style={{
              maxWidth: "600px",
              width: "100%",
              textAlign: "center",
              fontSize: "16px",
              color: "var(--text)",
            }}
          >
            Lütfen çıkarmış olduğunuz etiketi veya etiketleri gönderinin üzerine yapıştırarak seçmiş
            olduğunuz yöntem ile iyziMerkez’e gönderiniz.
          </p>
        )}
        <p
          style={{
            maxWidth: "600px",
            width: "100%",
            textAlign: "center",
            fontSize: "16px",
            color: "var(--text)",
          }}
        >
          {localeCargoMessage().code ? (
            <div style={{ fontSize: "16px", color: "var(--text)" }}>
              {localeCargoMessage().code_message}:{" "}
              <span style={{ fontWeight: 600, color: "var(--btnBgBlue)", fontSize: "inherit" }}>
                {localeCargoMessage().code}
              </span>
            </div>
          ) : (
            ""
          )}
        </p>
        <Button sx={{ padding: "10px 50px" }} className="iyzi-button-blue" onClick={handleClose}>
          Tamam
        </Button>
      </div>
    </Modal>
  );
};

export default OrderSuccess;
