import { Button, TableCell, TableRow } from "@mui/material";
import CurrencySymbol from "../../../utils/CurrencySymbol";
import IyziBalanceDescription from "../../../utils/IyziBalanceDescription";
import { extractDetailDataState, extractDetailModalOpenState } from "../../../atoms/myWallet";
import { useCallback } from "react";
import { useSetRecoilState } from "recoil";
import axios from "../../../api/axios";
import LocalPrice from "../../../utils/LocalPrice";
import FormatDateHour from "../../../utils/FormatDateHour";
import Errors from "../../../utils/Errors";
import NavigateParcel from "../../../utils/NavigateParcel";

const MyWalletExtractTableBody = ({ item }) => {
  const setExtractDetailModalOpen = useSetRecoilState(extractDetailModalOpenState);
  const setExtractDetailData = useSetRecoilState(extractDetailDataState);

  const handleOpen = useCallback(() => {
    axios
      .get(`wallet/extrem-detail`, {
        params: {
          limit: 500,
          page: 1,
          order_direction: "DESC",
          payment_id: item.payment_id,
        },
      })
      .then((res) => {
        setExtractDetailModalOpen(true);
        setExtractDetailData(res.data.data);
      })
      .catch((error) => Errors(error));
  }, [setExtractDetailModalOpen, setExtractDetailData, item]);

  return (
    <TableRow>
      <TableCell>{FormatDateHour(item.created_at)}</TableCell>
      <TableCell>
          {Number(item.description_code) === 25 && item.company_invoice_no !== null
              ? item.company_invoice_no : IyziBalanceDescription(Number(item.description_code))}
          {Number(item.description_code) === 23 || Number(item.description_code) === 24
              ? item.compensation_invoice_no !== null
                  ? " / " + item.compensation_invoice_no
                  : " / Fatura Bekleniyor"
              : ""}
      </TableCell>
      <TableCell>
        {item.get_parcel?.parcel_ref_no ? (
          <NavigateParcel parcel={item.get_parcel?.parcel_ref_no} fontSize={13} />
        ) : (
          "---"
        )}
      </TableCell>
      <TableCell
        sx={{
          color: Number(item.debt_balance) === 0 ? "initial" : "var(--shipmentGreen)",
        }}
      >
        {CurrencySymbol(item.currency)}
        {LocalPrice(item.debt_balance)}
      </TableCell>
      <TableCell
        sx={{
          color: Number(item.receivable_balance) === 0 ? "initial" : "var(--shipmentRed)",
        }}
      >
        {CurrencySymbol(item.currency)}
        {LocalPrice(item.receivable_balance)}
      </TableCell>
      {/* <TableCell
        sx={{
          color: "var(--info)",
        }}
      >
        {CurrencySymbol(item.currency)}
        {LocalPrice(item.calculated_total_balance)}
      </TableCell> */}
      <TableCell>
        <Button
          disabled={item?.payment_id === "" || item?.payment_id === null ? true : false}
          onClick={handleOpen}
          className="iyzi-button"
          sx={{ ":disabled": { opacity: 0.3 } }}
        >
          Detay
        </Button>
      </TableCell>
    </TableRow>
  );
};

export default MyWalletExtractTableBody;
