import { Box, Button, Modal } from "@mui/material";
import { useRecoilState } from "recoil";
import {
  orderDeleteModalState,
  orderListCheckedListState,
  orderListState,
} from "../../../atoms/orders";
import { useCallback } from "react";
import axios from "../../../api/axios";
import Errors from "../../../utils/Errors";
import { toast } from "react-toastify";

const OrderDelete = () => {
  const [orderDeleteModal, setOrderDeleteModal] = useRecoilState(orderDeleteModalState);

  const item = orderDeleteModal.data;
  const status = orderDeleteModal.status;

  const [orderList, setOrderList] = useRecoilState(orderListState);
  const [orderListCheckedList, setOrderListCheckedList] = useRecoilState(orderListCheckedListState);

  const handleClose = useCallback(() => {
    setOrderDeleteModal({ status: false, data: {} });
  }, [setOrderDeleteModal]);

  const handleDelete = useCallback(() => {
    axios
      .post(`parcel/delete/${item?.id}`)
      .then((response) => {
        toast.success(response.data.message);
        setOrderList(orderList.filter((e) => e.id !== item?.id));
        if (orderListCheckedList.find((e) => e.id === item?.id)) {
          setOrderListCheckedList(orderListCheckedList.filter((e) => e.id !== item?.id));
        }
        setOrderDeleteModal({ status: false, data: {} });
      })
      .catch((error) => Errors(error));
  }, [
    item,
    orderList,
    orderListCheckedList,
    setOrderList,
    setOrderDeleteModal,
    setOrderListCheckedList,
  ]);

  return (
    <Modal
      sx={{ display: "flex", justifyContent: "center", alignItems: "center", padding: "20px" }}
      open={status}
      onClose={handleClose}
    >
      <Box
        sx={{
          width: "100%",
          maxWidth: "500px",
          backgroundColor: "var(--lighterBg)",
          padding: "20px",
          borderRadius: "var(--wrapperBorderRadius)",
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
        }}
        style={{
          maxHeight: "90vh",
          overflowY: "auto",
        }}
      >
        <img
          style={{ width: "100px", height: "100px" }}
          src="images/order/important.png"
          alt="Önemli Uyarı"
        />
        <h2 style={{ color: "var(--shipmentRed)", fontSize: "30px" }}>Dikkat!</h2>
        <p
          style={{
            fontSize: "13px",
            textAlign: "center",
            color: "var(--priceText)",
            marginTop: "10px",
          }}
        >
          Henüz Etiket oluşturup göndermediğiniz gönderi tüm data yapısından geri döndürülemeyecek
          şekilde silinecektir. Onaylıyor musunuz?
        </p>
        <div
          style={{
            marginTop: "10px",
            display: "flex",
            gap: "var(--gap)",
          }}
        >
          <Button onClick={handleClose} className="iyzi-button">
            İptal
          </Button>
          <Button onClick={handleDelete} className="iyzi-button-red">
            Onaylıyorum
          </Button>
        </div>
      </Box>
    </Modal>
  );
};

export default OrderDelete;
