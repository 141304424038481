import "../style/fastShipment/FastShipment.css";
import { Box } from "@mui/material";
import { useInView, animated } from "@react-spring/web";
import { useCookies } from "react-cookie";
import { ToastContainer } from "react-toastify";
import axios from "../api/axios";
import NotificationBar from "../components/sidebar/NotificationBar";
import { DynamicTitle } from "../utils/DynamicTitle";
import WrapperStyle from "../utils/WrapperStyle";
import FastShipmentList from "../components/fastShipment/FastShipmentList";
// import PageTransition from "../utils/PageTransition";
import FastShipmentTitle from "../components/fastShipment/FastShipmentTitle";
import EditShipmentAddress from "../components/myShipments/modals/EditShipmentAddress";
import EditShipmentAddressIntegrated from "../components/myShipments/modals/EditShipmentAddressIntegrated";
import MyShipmentProformaUpdate from "../components/myShipments/modals/MyShipmentProformaUpdate";

const FastShipment = () => {
  DynamicTitle("Hızlı Gönderi | iyziShip");

  const [token] = useCookies(["jwt"]);
  const jwt = token.jwt;
  axios.defaults.headers.common["Authorization"] = `Bearer ${jwt}`;

  const [ref, springs] = useInView(
    () => ({
      from: {
        opacity: 0,
        y: 20,
      },
      to: {
        opacity: 1,
        y: 0,
      },
    }),
    {
      rootMargin: "-10% 0%",
    }
  );

  return (
    <section className="fast-shipment">
      <NotificationBar />
      <ToastContainer />
      <MyShipmentProformaUpdate />
      <Box className="fast-shipment-wrapper" style={WrapperStyle()}>
        {/* <PageTransition> */}
        {/* <FastShipmentTitle /> */}
        <Box className="fast-shipment-container">
          <FastShipmentList />
        </Box>
        {/* </PageTransition> */}
      </Box>
    </section>
  );
};

export default FastShipment;
