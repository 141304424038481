import {
  Box,
  Button,
  CircularProgress,
  createTheme,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  ThemeProvider,
  Tooltip,
} from "@mui/material";
import EditOutlinedIcon from "@mui/icons-material/EditOutlined";
import SimCardDownloadOutlinedIcon from "@mui/icons-material/SimCardDownloadOutlined";
import DeleteOutlineOutlinedIcon from "@mui/icons-material/DeleteOutlineOutlined";
import TrendingUpOutlinedIcon from "@mui/icons-material/TrendingUpOutlined";
import NoteAddOutlinedIcon from "@mui/icons-material/NoteAddOutlined";
import BackupOutlinedIcon from "@mui/icons-material/BackupOutlined";
import TravelExploreIcon from "@mui/icons-material/TravelExplore";
import ParcelReturnIcon from "@mui/icons-material/AssignmentReturn"; //TODO: Icon bulunacak
import TaskAltIcon from '@mui/icons-material/TaskAlt';
import LocalShippingOutlinedIcon from "@mui/icons-material/LocalShippingOutlined";
import {
  addNoteOpenState,
  parcelReturnDemandOpenState,
  editReceiverAddressOpenState,
  isReturnShipmentState,
  myShipmentListState,
  selectedShipmentForMyShipmentsState,
  showFinancialDetailsOpenState,
  uploadShipmentDocumentOpenState,
  barcodeSingleState,
  myShipmentDomesticState,
} from "../../atoms/myShipments";
import { createRegisterModalState } from "../../atoms/courierRegistration";
import { useRecoilState, useRecoilValue, useSetRecoilState } from "recoil";
import axios from "../../api/axios";
import { toast } from "react-toastify";
import { useCallback, useState } from "react";
import { useCookies } from "react-cookie";
import "dayjs/locale/tr";
import Errors from "../../utils/Errors";
import { basketItemsState, basketTotalItemsState } from "../../atoms/basket";
import { useNavigate } from "react-router-dom";
import OpenBase64InNewTab from "../../utils/OpenBase64InNewTab";
import SystemUpdateAltIcon from "@mui/icons-material/SystemUpdateAlt";
import WarningDialog from "./modals/WarningDialog";
import AreYourSureDialog from "./modals/AreYouSureDialog";

const theme = createTheme({
  components: {
    MuiBackdrop: {
      styleOverrides: {
        root: {
          backgroundColor: "rgba(0, 0, 0, 0.1);",
        },
      },
    },
    MuiInputBase: {
      styleOverrides: {
        root: {
          textAlign: "start",
        },
      },
    },
  },
});

const ListItemLinks = ({ shipment }) => {
  const [token] = useCookies(["jwt"]);
  const jwt = token.jwt;
  const navigate = useNavigate();

  const [deleteDialog, setDeleteDialog] = useState(false);
  const [destroyDialog, setDestroyDialog] = useState(false);
  const [editWarning, setEditWarning] = useState(false);
  const [activeCourierDialogOpen, setActiveCourierDialogOpen] = useState(false);
  const [areYouSure, setAreYouSure] = useState(false);

  const hasTrackNumber = shipment?.tracking_awb ? true : false;
  const isBooking = Number(shipment.is_booking) !== 0;

  const handleCancelDialogOpen = () => {
    setDeleteDialog(true);
  };

  const handleCancelDialogClose = () => {
    setDeleteDialog(false);
  };

  const handleDestroyDialogOpen = () => {
    setDestroyDialog(true);
  };

  const handleDestroyDialogClose = () => {
    setDestroyDialog(false);
  };

  const [myShipmentList, setMyShipmentList] = useRecoilState(myShipmentListState);
  const [basketItems, setBasketItems] = useRecoilState(basketItemsState);
  const setMyShipmentDomestic = useSetRecoilState(myShipmentDomesticState);
  const setSelectedShipmentForMyShipments = useSetRecoilState(selectedShipmentForMyShipmentsState);
  const setEditReceiverAddressOpen = useSetRecoilState(editReceiverAddressOpenState);
  const setAddNoteOpen = useSetRecoilState(addNoteOpenState);
  const setParcelReturnDemandOpen = useSetRecoilState(parcelReturnDemandOpenState);
  const setUploadShipmentDocumentOpen = useSetRecoilState(uploadShipmentDocumentOpenState);
  const setShowFinancialDetailsOpen = useSetRecoilState(showFinancialDetailsOpenState);
  const setBarcodeSingle = useSetRecoilState(barcodeSingleState);
  const setBasketTotalItems = useSetRecoilState(basketTotalItemsState);
  const setCreateRegisterModal = useSetRecoilState(createRegisterModalState);

  const isReturnShipment = useRecoilValue(isReturnShipmentState);

  const [consignmentLoading, setConsignmentLoading] = useState(false);
  const [deleteLoading, setDeleteLoading] = useState(false);
  const [trackLoading, setTrackLoading] = useState(false);
  const [updateEtsyNo, setUpdateEtsyNo] = useState();
  const [etsyTrackLoading, setEtsyTrackLoading] = useState(false);
  const [etsyUpdateWarning, setEtsyUpdateWarning] = useState(false);

  const handleConsignmentDownload = () => {
    setConsignmentLoading(true);
    axios
      .get(`parcel/get-consignment/${shipment.id}`)
      .then((response) => {
        OpenBase64InNewTab(response.data.base64, "application/pdf");
        toast.success(response.data.message);
      })
      .catch((error) => Errors(error))
      .finally(() => setConsignmentLoading(false));
  };

  const handleEditAddressWarningClose = () => {
    setEditWarning(false);
    handleEditReceiverAddressModal();
  };

  const handleNavigateCourier = () => {
    navigate(`/courier-registration`);

    if (!isBooking) {
      setCreateRegisterModal(true);
    }
  };

  const handleNavigateCourierBooking = () => {
    navigate(`/courier-registration`);
  };

  const handleEditReceiverAddressModal = () => {
    setSelectedShipmentForMyShipments(shipment);
    setEditReceiverAddressOpen(true);
  };

  const handleAddNoteModal = () => {
    setSelectedShipmentForMyShipments(shipment);
    setAddNoteOpen(true);
  };

  const handleBarcodeModal = () => {
    setBarcodeSingle({ status: true, item: shipment });
  };

  const handleParcelReturnDemandModal = () => {
    setSelectedShipmentForMyShipments(shipment);
    setParcelReturnDemandOpen(true);
  };

  const handleUploadModal = () => {
    setUploadShipmentDocumentOpen(true);
    setSelectedShipmentForMyShipments(shipment);
  };

  const handleFinancialDetailsOpen = () => {
    setSelectedShipmentForMyShipments(shipment);
    setShowFinancialDetailsOpen(true);
  };

  const handleCancel = () => {
    if (Number(shipment.status) < 2) {
      setDeleteLoading(true);
      axios
        .post(`parcel/cancel-parcel/${shipment.id}`)
        .then((response) => {
          if (response.data.status === 200) {
            toast.success(response.data.message);
            window.location.reload(true);
          }
          if (response.data.status === 400) {
            toast.error(response.data.message);
          }
        })
        .catch((error) => {
          Errors(error);
        })
        .finally(() => setDeleteLoading(false));
    }
    handleCancelDialogClose();
  };

  const handleDestroy = () => {
    if (Number(shipment.status) > 1 || Number(shipment.paid) === 1) {
      // Do nothing if the shipment is already in transit or already paid
      return;
    }
    axios
      .post(`parcel/delete/${shipment.id}`, null, {
        headers: {
          Authorization: `Bearer ${jwt}`,
        },
      })
      .then((response) => {
        if (response.data.status === 200) {
          toast.success(response.data.message);
          setMyShipmentList(myShipmentList.filter((item) => item.id !== shipment.id));
          setBasketItems(basketItems?.filter((e) => e.get_parcel?.id !== shipment.id));
          setBasketTotalItems((preValue) => preValue - 1);
        } else if (response.data.status === 400) {
          toast.error(response.data.message);
        }
      })
      .catch((error) => Errors(error))
      .finally(() => {
        setDestroyDialog(false);
        setDeleteLoading(false);
      });
  };

  const handleAskTrackingNo = useCallback(async () => {
    setTrackLoading(true);

    await axios
      .get(`parcel/create-label-api/${shipment?.id}`)
      .then((response) => {
        response.data?.data?.error_count === 0 && toast.success(response.data.message);
        if (response.data.data?.error_count > 0) {
          if (response.data.data?.error_messages?.length !== 0) {
            response.data.data?.error_messages.map((e) => toast.error(e));
          } else {
            toast.error("İzleme numarası tedarikçiden alınamıyor.");
          }
        } else {
          setTimeout(() => {
            window.location.reload(true);
          }, 2000);
        }
      })
      .catch((error) => Errors(error))
      .finally(() => setTrackLoading(false));
  }, [shipment]);

  const handleUpdateEtsyTrackingNumber = () => {
    setEtsyTrackLoading(true);
    axios
      .post(`general/update-track?parcel_id=${shipment?.id}`)
      .then((response) => {
        setUpdateEtsyNo(response.data.items_response);
      })
      .catch((error) => Errors(error))
      .finally(() => setEtsyTrackLoading(false));
  };

  const handleUpdateEtsyTrackingNumberAction = () => {
    if (parseFloat(shipment?.get_integration_parcel?.updated_tracking) === 1) {
      setEtsyUpdateWarning(true);
    } else {
      setAreYouSure(true);
    }
  };

  const handleNothing = () => { };

  const courierRegisterIsDisabled = () => {
    if (isBooking) {
      if (
        Number(shipment.status) === 0 ||
        Number(shipment.status) === 0.01 ||
        Number(shipment.status) === 0.03
      ) {
        return false;
      } else return true;
    } else {
      if (
        Number(shipment.status) === 0 ||
        Number(shipment.status) === 0.02 ||
        Number(shipment.status) === 0.04
      ) {
        return false;
      } else return true;
    }
  };

  const handleActiveCourierDialogClose = () => {
    setActiveCourierDialogOpen(false);
  };

  const handleAddDomesticInfo = () => {
    setMyShipmentDomestic({ status: true, data: shipment });
  };

  return (
    <ThemeProvider theme={theme}>
      <Box className="list-links">
        <WarningDialog
          open={etsyUpdateWarning}
          setOpen={setEtsyUpdateWarning}
          handleRequest={handleUpdateEtsyTrackingNumber}
        />
        <AreYourSureDialog
          open={areYouSure}
          setOpen={setAreYouSure}
          handleRequest={handleUpdateEtsyTrackingNumber}
        />
        <Dialog
          open={editWarning}
          onClose={handleEditAddressWarningClose}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
        >
          <DialogTitle id="alert-dialog-title">{"Gönderi Düzenleme İsteği"}</DialogTitle>
          <DialogContent>
            <DialogContentText
              sx={{ fontWeight: 500, fontSize: "16px" }}
              id="alert-dialog-description"
            >
              Eğer düzenleme yaparsanız izleme takip numarası aldığınız için takip numaranız
              silinecektir, tekrar izleme takip numarası almalısınız. Sadece görüntüleyecekseniz
              herhangi bir aksiyon almanıza gerek yoktur.
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button className="iyzi-button" onClick={handleEditAddressWarningClose}>
              Anladım
            </Button>
          </DialogActions>
        </Dialog>
        <Dialog
          open={deleteDialog}
          onClose={handleCancelDialogClose}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
          style={{ overflowY: "auto", maxHeight: "800px" }}
        >
          <DialogTitle
            style={{
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              gap: "var(--gap2x)",
            }}
            id="alert-dialog-title"
          >
            <svg
              width="150"
              height="150"
              viewBox="0 0 150 150"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M48 112.5L75 85.5L102 112.5L112.5 102L85.5 75L112.5 48L102 37.5L75 64.5L48 37.5L37.5 48L64.5 75L37.5 102L48 112.5ZM75 150C64.625 150 54.875 148.03 45.75 144.09C36.625 140.15 28.6875 134.808 21.9375 128.062C15.1875 121.313 9.845 113.375 5.91 104.25C1.975 95.125 0.005 85.375 0 75C0 64.625 1.97 54.875 5.91 45.75C9.85 36.625 15.1925 28.6875 21.9375 21.9375C28.6875 15.1875 36.625 9.845 45.75 5.91C54.875 1.975 64.625 0.005 75 0C85.375 0 95.125 1.97 104.25 5.91C113.375 9.85 121.313 15.1925 128.062 21.9375C134.812 28.6875 140.158 36.625 144.097 45.75C148.037 54.875 150.005 64.625 150 75C150 85.375 148.03 95.125 144.09 104.25C140.15 113.375 134.808 121.313 128.062 128.062C121.313 134.812 113.375 140.158 104.25 144.097C95.125 148.037 85.375 150.005 75 150ZM75 135C91.75 135 105.937 129.188 117.562 117.562C129.188 105.937 135 91.75 135 75C135 58.25 129.188 44.0625 117.562 32.4375C105.937 20.8125 91.75 15 75 15C58.25 15 44.0625 20.8125 32.4375 32.4375C20.8125 44.0625 15 58.25 15 75C15 91.75 20.8125 105.937 32.4375 117.562C44.0625 129.188 58.25 135 75 135Z"
                fill="#E53238"
              />
            </svg>
            Gönderi İptal İsteği
          </DialogTitle>
          <DialogContent>
            <DialogContentText
              sx={{ fontWeight: 500, fontSize: "16px" }}
              id="alert-dialog-description"
            >
              Ödemiş olduğunuz navlun ücreti Bakiyeniz’e iade edilecektir ve gönderiniz iptal
              edilecektir onaylıyor musunuz ?
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button className="iyzi-button-gray" onClick={handleCancelDialogClose}>
              Hayır
            </Button>
            <Button className="iyzi-button-blue" onClick={handleCancel}>
              Onaylıyorum
            </Button>
          </DialogActions>
        </Dialog>
        <Dialog
          open={destroyDialog}
          onClose={handleDestroyDialogClose}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
        >
          <DialogTitle id="alert-dialog-title">{"Gönderi Silme İsteği"}</DialogTitle>
          <DialogContent>
            <DialogContentText
              sx={{ fontWeight: 500, fontSize: "16px" }}
              id="alert-dialog-description"
            >
              Gönderiyi silmek etmek istediğinizden emin misiniz?
            </DialogContentText>
            <br />
            {isBooking && (
              <DialogContentText
                sx={{ fontWeight: 500, fontSize: "16px" }}
                id="alert-dialog-description"
              >
                Silmek istediğiniz gönderinin kurye kaydı bulunmaktadır. Gönderiyi sildiğinizde,
                gönderinin kurye kaydı da silinecektir.
              </DialogContentText>
            )}
          </DialogContent>
          <DialogActions>
            <Button className="iyzi-button" onClick={handleDestroyDialogClose}>
              Hayır
            </Button>
            <Button className="iyzi-button" onClick={handleDestroy}>
              Evet
            </Button>
          </DialogActions>
        </Dialog>
        {isReturnShipment ? (
          ""
        ) : (
          <Button
            className={Number(shipment.status) > 1 ? "passive-link" : ""}
            disabled={Number(shipment.status) === 0.05 ? true : false}
            onClick={
              Number(shipment.status) > 1
                ? () => handleNothing()
                : hasTrackNumber
                  ? () => setEditWarning(true)
                  : handleEditReceiverAddressModal
            }
            sx={{
              textTransform: "none",
              display: "flex",
              alignItems: "center",
              justifyContent: "flex-start",
              padding: 0,
              color: "var(--priceText)",
              fontSize: "12px",
              fontWeight: 400,
              lineHeight: "16px",
              ":hover": {
                backgroundColor: "transparent",
                color: "var(--info)!important",
                transition: "all 150ms linear",
              },
              ":disabled": {
                opacity: 0.3,
              },
            }}
          >
            <EditOutlinedIcon sx={{ height: "13px" }} /> Adres Düzenle
          </Button>
        )}
        {consignmentLoading ? (
          <div
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              height: "15px",
            }}
          >
            <CircularProgress sx={{ height: "15px !important", width: "15px !important" }} />
          </div>
        ) : (
          <div onClick={handleConsignmentDownload}>
            <SimCardDownloadOutlinedIcon sx={{ height: "13px" }} /> Konşimento Görüntüle
          </div>
        )}
        {deleteLoading ? (
          <div
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              height: "15px",
            }}
          >
            <CircularProgress sx={{ height: "15px !important", width: "15px !important" }} />
          </div>
        ) : (
          <Tooltip
            title={
              Number(shipment.status) < 1 &&
                Number(shipment.status) !== 0.05 &&
                Number(shipment.paid) === 1
                ? ""
                : ""
            }
          >
            <span>
              <Button
                className={
                  Number(shipment.status) < 1 &&
                    Number(shipment.status) !== 0.05 &&
                    Number(shipment.paid) === 1
                    ? ""
                    : "passive-link"
                }
                disabled={
                  Number(shipment.status) < 1 &&
                    Number(shipment.status) !== 0.05 &&
                    Number(shipment.paid) === 1
                    ? false
                    : true
                }
                onClick={handleCancelDialogOpen}
                sx={{
                  textTransform: "none",
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "flex-start",
                  padding: 0,
                  color: "var(--priceText)",
                  ":hover": {
                    backgroundColor: "transparent",
                    color: "var(--info)!important",
                    transition: "all 150ms linear",
                  },
                  ":disabled": {
                    opacity: 0.3,
                  },
                }}
              >
                <DeleteOutlineOutlinedIcon sx={{ height: "13px" }} /> İptal
              </Button>
            </span>
          </Tooltip>
        )}
        <Tooltip title={Number(shipment.status) < 1 && Number(shipment.paid) === 0 ? "" : ""}>
          <span>
            <Button
              className={
                Number(shipment.status) < 1 && Number(shipment.paid) === 0 ? "" : "passive-link"
              }
              disabled={Number(shipment.status) < 1 && Number(shipment.paid) === 0 ? false : true}
              onClick={handleDestroyDialogOpen}
              sx={{
                textTransform: "none",
                display: "flex",
                alignItems: "center",
                justifyContent: "flex-start",
                padding: 0,
                color: "var(--priceText)",
                fontSize: "12px",
                fontWeight: 400,
                lineHeight: "16px",
                ":hover": {
                  backgroundColor: "transparent",
                  color: "var(--info)!important",
                  transition: "all 150ms linear",
                },
                ":disabled": {
                  opacity: 0.3,
                },
              }}
            >
              <DeleteOutlineOutlinedIcon sx={{ height: "13px" }} /> Sil
            </Button>
          </span>
        </Tooltip>
        <Button
          onClick={handleNavigateCourier}
          disabled={courierRegisterIsDisabled()}
          sx={{
            textTransform: "none",
            display: "flex",
            alignItems: "center",
            justifyContent: "flex-start",
            padding: 0,
            color: "var(--priceText)",
            fontSize: "12px",
            fontWeight: 400,
            lineHeight: "16px",
            textAlign: "start",
            ":hover": {
              backgroundColor: "transparent",
              color: "var(--info)!important",
              transition: "all 150ms linear",
            },
            ":disabled": {
              opacity: 0.3,
            },
          }}
        >
          <NoteAddOutlinedIcon sx={{ height: "13px" }} />{" "}
          {isBooking ? "Kurye Kaydını İptal Et" : "Kurye Kaydı"}
        </Button>
        <div onClick={handleFinancialDetailsOpen}>
          <TrendingUpOutlinedIcon sx={{ height: "13px" }} /> Ödeme Detayları
        </div>
        <div onClick={handleAddNoteModal}>
          <NoteAddOutlinedIcon sx={{ height: "13px" }} />{" "}
          {(shipment.get_parcel_notes.length !== 0 &&
            shipment.get_parcel_notes.filter((e) => e.type === "1")) === false
            ? "Not Ekle"
            : "Notu Düzenle"}
        </div>
        <Button
          onClick={handleBarcodeModal}
          disabled={
            Number(shipment.status) < 1 &&
              Number(shipment.status) !== 0.05 &&
              Number(shipment.status) !== 0.06
              ? false
              : true
          }
          sx={{
            textTransform: "none",
            display: "flex",
            alignItems: "center",
            justifyContent: "flex-start",
            padding: 0,
            color: "var(--priceText)",
            fontSize: "12px",
            fontWeight: 400,
            lineHeight: "16px",
            textAlign: "start",
            ":hover": {
              backgroundColor: "transparent",
              color: "var(--info)!important",
              transition: "all 150ms linear",
            },
            ":disabled": {
              opacity: 0.3,
            },
          }}
        >
          <SimCardDownloadOutlinedIcon sx={{ height: "13px" }} /> Barkod İndir
        </Button>
        <div onClick={handleUploadModal}>
          <BackupOutlinedIcon sx={{ height: "13px" }} /> Dosya Yükle{" "}
          {shipment?.get_parcel_documents?.length > 0 && "/ Göster"}
        </div>
        {trackLoading ? (
          <div
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              height: "15px",
            }}
          >
            <CircularProgress sx={{ height: "15px !important", width: "15px !important" }} />
          </div>
        ) : (
          <Button
            sx={{
              textTransform: "none",
              display: "flex",
              alignItems: "center",
              justifyContent: "flex-start",
              padding: 0,
              color: "var(--priceText)",
              textAlign: "start",
              fontSize: "12px",
              lineHeight: "16px",
              fontWeight: 400,
              ":hover": {
                backgroundColor: "transparent",
                color: "var(--info)!important",
                transition: "all 150ms linear",
              },
              ":disabled": {
                opacity: 0.3,
              },
            }}
            onClick={handleAskTrackingNo}
            disabled={shipment?.tracking_awb?.length > 0}
            color="primary"
          >
            <TravelExploreIcon sx={{ height: "13px" }} /> İzleme No Al
          </Button>
        )}
        <Tooltip
          title={
            Number(shipment?.status) > 2
              ? "Gönderi merkeze geldikten sonra domestik bilgiyi güncelleyemezsiniz."
              : ""
          }
        >
          <span>
            <Button
              onClick={handleAddDomesticInfo}
              disabled={Number(shipment?.status) !== 0.00 && Number(shipment?.status) !== 2.00 && Number(shipment?.status) !== 4.00}
              sx={{
                textTransform: "none",
                display: "flex",
                alignItems: "center",
                justifyContent: "flex-start",
                padding: 0,
                fontSize: "12px",
                lineHeight: "16px",
                textAlign: "start",
                fontWeight: 400,
                color: "var(--priceText)",
                ":hover": {
                  backgroundColor: "transparent",
                  color: "var(--info)!important",
                  transition: "all 150ms linear",
                },
                ":disabled": {
                  opacity: 0.3,
                },
              }}
            >
              <LocalShippingOutlinedIcon sx={{ height: "13px" }} />{" "}
              {shipment?.domestic_awb !== null ? "Domestik No Düzenle" : "Domestik No Ekle"}
            </Button>
          </span>
        </Tooltip>
        {shipment?.get_integration_parcel?.platform === "ETSY" &&
          (etsyTrackLoading ? (
            <div
              style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                height: "15px",
              }}
            >
              <CircularProgress sx={{ height: "15px !important", width: "15px !important" }} />
            </div>
          ) : (
            <Button
              onClick={handleUpdateEtsyTrackingNumberAction}
              sx={{
                textTransform: "none",
                display: "flex",
                alignItems: "center",
                justifyContent: "flex-start",
                padding: 0,
                fontSize: "12px",
                lineHeight: "16px",
                textAlign: "start",
                fontWeight: 400,
                color: "var(--priceText)",
                ":hover": {
                  backgroundColor: "transparent",
                  color: "var(--info)!important",
                  transition: "all 150ms linear",
                },
                ":disabled": {
                  opacity: 0.3,
                },
              }}
            >
              <SystemUpdateAltIcon sx={{ height: "13px" }} /> Etsy Takip No Güncelle {updateEtsyNo?.name === shipment?.receiver_name || shipment?.get_integration_parcel?.updated_tracking === "1" && (
                <TaskAltIcon sx={{ height: "15px", color: "green" }} />
              )}
            </Button>
          ))}
        {Number(shipment?.status) >= 1 &&
          Number(shipment?.status) !== 2.19 &&
          Number(shipment?.status) !== 2.2 &&
          Number(shipment?.status) !== 2.21 &&
          Number(shipment?.status) !== 1.02 &&
          Number(shipment?.status) < 3 && (
            <div onClick={handleParcelReturnDemandModal}>
              <ParcelReturnIcon sx={{ height: "13px" }} /> İade Talebi Oluştur{" "}
            </div>
          )}
      </Box>
      <Dialog open={activeCourierDialogOpen} onClose={handleActiveCourierDialogClose}>
        <DialogTitle>Aktif Kurye Kaydı</DialogTitle>
        <DialogContent>
          <p>
            {shipment.is_active_courier
              ? "Aktif olan bir kurye kaydınız bulunmaktadır."
              : "Domestik bilgi ekleyebilmeniz için kurye kaydınızı iptal etmeniz gerekmektedir."}
          </p>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleNavigateCourierBooking}>Kurye Kaydı</Button>
          {!shipment.is_active_courier && (
            <Button
              onClick={() => {
                // Handle the action to cancel the active courier registration
                // This is where you would implement the logic to cancel the active registration
                // For now, we just close the dialog.
                handleActiveCourierDialogClose();
              }}
            >
              İptal
            </Button>
          )}
        </DialogActions>
      </Dialog>
    </ThemeProvider>
  );
};

export default ListItemLinks;
