import "../style/login/Login.css";
import LoginWPSign from "../components/sign/LoginWPSign";
import Header from "../components/beforeLogin/Header";
import Footer from "../components/beforeLogin/Footer";
import { useNavigate } from "react-router-dom";
import { useEffect } from "react";
import { useCookies } from "react-cookie";
// import PageTransition from "../utils/PageTransition";

const LoginWP = () => {
  const navigate = useNavigate();

  const [token] = useCookies(["jwt"]);
  const jwt = token?.jwt;

  useEffect(() => {
    if (jwt !== undefined && jwt !== null && jwt.length !== 0) {
      navigate("/dashboard");
    }
  }, [jwt, navigate]);

  return (
    <>
      {/* <PageTransition> */}
        <Header />
        <section className="login">
          <div className="login-sign">
            <LoginWPSign />
          </div>
        </section>
        <Footer />
      {/* </PageTransition> */}
    </>
  );
};

export default LoginWP;
