import "../../style/createShipment/NewAddress.css";
import { useRecoilValue, useSetRecoilState } from "recoil";
import {
  activeStepState,
  arrivalCountryCodeState,
  confirmationCodeState,
  ddpState,
  documentMaxGrossState,
  insuranceState,
  lucidState,
  priceIdState,
  receiverAddressCurrentPageState,
  receiverAddressTotalPagesState,
  receiverAddressesState,
  selectedReceiverAddressState,
  selectedSenderAddressState,
  senderAddressesState,
  shipmentDocumentState,
  shipmentHideState,
  shipmentZipCodeState,
  startShipmentReceiverAnimationState,
  startShipmentSenderAnimationState,
  supplierIdState,
} from "../../atoms/createShipment";
import { Box, Button, CircularProgress } from "@mui/material";
import axios from "../../api/axios";
import { useCookies } from "react-cookie";
import ShipmentSenderAddress from "./shipmentAddress/ShipmentSenderAddress";
import ShipmentReceiverAddress from "./shipmentAddress/ShipmentReceiverAddress";
import AddSenderAddress from "./shipmentAddress/AddSenderAddress";
import AddReceiverAddress from "./shipmentAddress/AddReceiverAddress";
import CreateShipmentReceiverAddress from "./shipmentAddress/CreateShipmentReceiverAddress";
import CreateShipmentSenderAddress from "./shipmentAddress/CreateShipmentSenderAddress";
import { useCallback, useState, useEffect } from "react";
import InputIcon from "@mui/icons-material/Input";
import OutputIcon from "@mui/icons-material/Output";
import { ToastContainer, toast } from "react-toastify";
import Errors from "../../utils/Errors";
import ShipmentAddressSearch from "./shipmentAddress/ShipmentAddressSearch";

const ShipmentAddress = () => {
  const [loading, setLoading] = useState(false);

  const [token] = useCookies(["jwt"]);
  const jwt = token.jwt;
  axios.defaults.headers.common["Authorization"] = `Bearer ${jwt}`;

  const startShipmentSenderAnimation = useRecoilValue(startShipmentSenderAnimationState);
  const startShipmentReceiverAnimation = useRecoilValue(startShipmentReceiverAnimationState);
  const shipmentHide = useRecoilValue(shipmentHideState);
  const arrivalCountryCode = useRecoilValue(arrivalCountryCodeState);
  const selectedSenderAddress = useRecoilValue(selectedSenderAddressState);
  const selectedReceiverAddress = useRecoilValue(selectedReceiverAddressState);
  const shipmentDocument = useRecoilValue(shipmentDocumentState);
  const shipmentZipCode = useRecoilValue(shipmentZipCodeState);
  const confirmationCode = useRecoilValue(confirmationCodeState);
  const priceId = useRecoilValue(priceIdState);
  const insurance = useRecoilValue(insuranceState);
  const ddp = useRecoilValue(ddpState);
  const lucid = useRecoilValue(lucidState);
  const supplierId = useRecoilValue(supplierIdState);
  const documentMaxGross = useRecoilValue(documentMaxGrossState);

  const setReceiverAddresses = useSetRecoilState(receiverAddressesState);
  const setSenderAddresses = useSetRecoilState(senderAddressesState);
  const setReceiverAddressCurrentPage = useSetRecoilState(receiverAddressCurrentPageState);
  const setReceiverAddressTotalPages = useSetRecoilState(receiverAddressTotalPagesState);
  const setActiveStep = useSetRecoilState(activeStepState);

  const getAddressList = useCallback(async () => {
    setLoading(true);
    await axios
      .post("member-address/list", {
        address_type: 4,
        country: "TR",
      })
      .then((response) => {
        setSenderAddresses(response.data.data);
      })
      .catch((error) => Errors(error));
    await axios
      .get("member-address", {
        params: {
          address_type: 5,
          country: arrivalCountryCode,
          postal_code: shipmentZipCode,
          detailed: 1,
          limit: 10,
        },
      })
      .then((response) => {
        setReceiverAddresses(response.data.data);
        setReceiverAddressCurrentPage(response.data.currentPage);
        setReceiverAddressTotalPages(response.data.totalPages);
        setLoading(false);
      })
      .catch((error) => Errors(error))
      .finally(() => setLoading(false));
  }, [
    arrivalCountryCode,
    shipmentZipCode,
    setSenderAddresses,
    setReceiverAddresses,
    setReceiverAddressCurrentPage,
    setReceiverAddressTotalPages,
  ]);

  const nextStep = () => {
    if (selectedSenderAddress?.id && selectedReceiverAddress?.id) {
      setActiveStep((prevActiveStep) => prevActiveStep + 1);
    }
  };

  const createShipment = useCallback(async () => {
    const data = {
      price_details: {
        contract_code: confirmationCode,
        price_id: Number(priceId),
      },
      sender_address_id: selectedSenderAddress?.id,
      receiver_address_id: selectedReceiverAddress?.id,
      parcel_type: 1,
      parcel_source: 1,
      doc_type: "D",
      // incoterm: yurtdışı gümrükleme işaretliyse DDP, işaretli değilse DDU
      incoterm:
        ddp === 0 && (supplierId === "337" || supplierId === "338")
          ? "DDP"
          : ddp === 1
          ? "DDP"
          : "DDU",
      sender_name: selectedSenderAddress?.contact_name,
      receiver_name: selectedReceiverAddress?.contact_name,
      sender_country: "TR",
      receiver_country: arrivalCountryCode,
      gw: Number(documentMaxGross),
      cw: Number(documentMaxGross),
      vw: 0,
      insurance,
      ddp,
      lucid,
      qty: 1,
    };
    if (
      selectedSenderAddress?.id === null ||
      selectedSenderAddress?.id === "" ||
      selectedSenderAddress?.id === undefined ||
      selectedReceiverAddress?.id === null ||
      selectedReceiverAddress?.id === "" ||
      selectedReceiverAddress?.id === undefined
    ) {
      return; // Disabled elle kaldırılırsa diye ek önlem.
    } else {
      setLoading(true);
      await axios
        .post("parcel/add", data)
        .then(() => {
          toast.success("Gönderi Oluşturuldu", { autoClose: 3000 });
          setTimeout(
            setActiveStep((prevActiveStep) => prevActiveStep + 1),
            1000
          );
        })
        .catch((error) => Errors(error))
        .finally(() => setLoading(false));
    }
  }, [
    lucid,
    arrivalCountryCode,
    confirmationCode,
    ddp,
    documentMaxGross,
    insurance,
    priceId,
    selectedReceiverAddress,
    selectedSenderAddress,
    setActiveStep,
    supplierId,
  ]);

  useEffect(() => {
    getAddressList();
  }, [getAddressList]);

  return loading ? (
    <div style={{ width: "100%", display: "flex" }}>
      <CircularProgress sx={{ margin: "20vh auto" }} />
    </div>
  ) : (
    <Box className="shipmentAddress">
      <ToastContainer />
      {shipmentHide === false && (
        <Box className="shipmentAddress-list">
          <div className="shipmentAddress-list-board">
            <h6
              style={{
                color: "var(--priceText)",
                display: "flex",
                gap: "var(--gap)",
              }}
            >
              <OutputIcon />
              Gönderici Adresleri
            </h6>
            <AddSenderAddress />
            <ShipmentSenderAddress />
          </div>
          <div className="shipmentAddress-list-board">
            <h6
              style={{
                color: "var(--priceText)",
                display: "flex",
                gap: "var(--gap)",
              }}
            >
              <InputIcon />
              Alıcı Adresleri&nbsp;
              <span>
                (İlk ekranda girilen posta koduna göre kayıtlı adreslerinizden listelenmektedir.)
              </span>
            </h6>
            <AddReceiverAddress />
            <ShipmentAddressSearch setLoading={setLoading} />
            <ShipmentReceiverAddress />
          </div>
          <Button
            className="iyzi-button-blue"
            disabled={
              selectedSenderAddress?.id === null ||
              selectedSenderAddress?.id === "" ||
              selectedSenderAddress?.id === undefined ||
              selectedReceiverAddress?.id === null ||
              selectedReceiverAddress?.id === "" ||
              selectedReceiverAddress?.id === undefined
                ? true
                : false
            }
            onClick={shipmentDocument === "D" ? createShipment : nextStep}
            sx={{ ":disabled": { opacity: 0.4 } }}
          >
            İlerle
          </Button>
        </Box>
      )}
      {startShipmentReceiverAnimation === false && <CreateShipmentSenderAddress />}
      {startShipmentSenderAnimation === false && <CreateShipmentReceiverAddress />}
    </Box>
  );
};

export default ShipmentAddress;
