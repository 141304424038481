import { Box, Button, CircularProgress, TableCell, TableRow } from "@mui/material";
import { useRecoilState, useSetRecoilState } from "recoil";
import {
  isRatingState,
  openSupportDetailState,
  openSupportListState,
  reteSupportState,
  ticketDimensionsState,
  ticketDocumentState,
  ticketState,
} from "../../atoms/support";
import WarningAmberRoundedIcon from "@mui/icons-material/WarningAmberRounded";
import FlagRoundedIcon from "@mui/icons-material/FlagRounded";
import ThumbUpAltRoundedIcon from "@mui/icons-material/ThumbUpAltRounded";
import AddAlertIcon from "@mui/icons-material/AddAlert";
import { useCallback, useEffect, useState } from "react";
import axios from "../../api/axios";
import NavigateParcel from "../../utils/NavigateParcel";
import Errors from "../../utils/Errors";

const SupportListTableBody = ({ item }) => {
  const setRateSupport = useSetRecoilState(reteSupportState);
  // const [, setRateCommentOpen] = useRecoilState(rateCommentOpenState);
  const setIsRating = useSetRecoilState(isRatingState);
  const [, setOpenSupportDetail] = useRecoilState(openSupportDetailState);
  const [, setOpenSupportList] = useRecoilState(openSupportListState);
  const [, setTicket] = useRecoilState(ticketState);
  const [, setTicketDocument] = useRecoilState(ticketDocumentState);
  const [, setTicketDimensions] = useRecoilState(ticketDimensionsState);

  const [loading, setLoading] = useState(false);

  const handleOpenSupportDetail = useCallback(async () => {
    setLoading(true);
    await axios
      .get(`ticket/${item.id}`)
      .then((response) => {
        setLoading(false);
        setOpenSupportDetail(true);
        setOpenSupportList(false);
        setTicket(response.data.data);
        setTicketDocument(response.data.data.get_ticket_documents);
        setTicketDimensions(response.data.data?.get_parcel?.get_parcel_dimensions);
      })
      .catch((error) => {
        setLoading(false);
        Errors(error);
      });
  }, [
    setOpenSupportDetail,
    setOpenSupportList,
    setTicket,
    item.id,
    setTicketDocument,
    setTicketDimensions,
  ]);

  // const handleRateSupport = useCallback(
  //   (newValue) => {
  //     setRateSupport(newValue);
  //     if (newValue > 0) {
  //       setRateCommentOpen(true);
  //       setIsRating(true);
  //     }
  //   },
  //   [setRateSupport, setRateCommentOpen, setIsRating]
  // );

  useEffect(() => {
    if (Number(item.rating) > 0) {
      setIsRating(true);
      setRateSupport(Number(item.rating));
    } else {
      setIsRating(false);
    }
  }, [setIsRating, setRateSupport, item]);
  return (
    <TableRow className="support-list-table-body">
      <TableCell size="small">{item?.ticket_code}</TableCell>
      <TableCell size="small">
        {item?.parcel_ref_no?.length === 0 || !item.parcel_ref_no ? (
          "---"
        ) : (
          <NavigateParcel
            parcel={item?.get_parcel?.parcel_ref_no}
            status={item?.get_parcel?.status}
          />
        )}
      </TableCell>
      <TableCell size="small">{item?.subject_name}</TableCell>
      <TableCell size="small">
        {Number(item.status) === 3 ? (
          <>
            <Box className="status closed">
              <Box className="status-container">
                <ThumbUpAltRoundedIcon className="status-logo closed" />
                İşlem Tamamlandı
              </Box>
            </Box>
            {/* <Box
              sx={{
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
                justifyContent: "center",
                marginTop: "3px",
              }}
            >
              <Rating
                name="simple-controlled"
                precision={0.5}
                value={rateSupport}
                onChange={(e, newValue) => {
                  handleRateSupport(newValue);
                }}
                disabled={isRating}
              />
            </Box> */}
          </>
        ) : (
          <Box
            className={
              Number(item.status) === 0
                ? "status new"
                : Number(item.status) === 1
                ? "status active"
                : Number(item.status) === 2
                ? "status on-hold"
                : ""
            }
          >
            {Number(item.status) === 0 && (
              <Box className="status-container">
                <AddAlertIcon className="status-logo new" />
                Yeni Talep
              </Box>
            )}
            {Number(item.status) === 1 && (
              <Box className="status-container">
                <FlagRoundedIcon className="status-logo active" />
                Cevabınız Bekleniyor
              </Box>
            )}
            {Number(item.status) === 2 && (
              <Box className="status-container">
                <WarningAmberRoundedIcon className="status-logo continues" />
                iyziTeam'den Cevap Bekleniyor
              </Box>
            )}
            {Number(item.status) === 3 && (
              <Box className="status-container">
                <WarningAmberRoundedIcon className="status-logo continues" />
                Destek Talebi Kapandı
              </Box>
            )}
          </Box>
        )}
      </TableCell>
      <TableCell size="small">
        {item.created_at.slice(8, 10)}.{item.created_at.slice(5, 7)}.{item.created_at.slice(0, 4)}
        <br />
        {item.created_at.slice(11, 19)}
      </TableCell>
      <TableCell size="small">
        {item.updated_at.slice(8, 10)}.{item.updated_at.slice(5, 7)}.{item.updated_at.slice(0, 4)}
        <br />
        {item.updated_at.slice(11, 19)}
      </TableCell>
      <TableCell size="small">
        {loading ? (
          <Box
            sx={{
              width: "71px",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <CircularProgress
              sx={{
                width: "24px !important",
                height: "24px !important",
              }}
            />
          </Box>
        ) : (
          <Button onClick={handleOpenSupportDetail} className="iyzi-button-blue">
            Göster
          </Button>
        )}
      </TableCell>
    </TableRow>
  );
};

export default SupportListTableBody;
