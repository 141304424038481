import { Box, CircularProgress, TableCell, TableRow } from "@mui/material";
import VisibilityIcon from "@mui/icons-material/Visibility";
import { useCallback, useState } from "react";
import axios from "../../../api/axios";
import {
  billsFileDataState,
  billsFileModalOpenState,
  billsKonsimentoDataState,
  billsKonsimentoIdState,
  billsKonsimentoModalOpenState,
} from "../../../atoms/myWallet";
import { useRecoilState } from "recoil";
import InsertDriveFileOutlinedIcon from "@mui/icons-material/InsertDriveFileOutlined";
import Errors from "../../../utils/Errors";
import FormatDateTR from "../../../utils/FormatDateTR";
import LocalPrice from "../../../utils/LocalPrice";
import Currency from "../../../utils/CurrencySymbol";

const BillsTableBody = ({ row }) => {
  const [loading, setLoading] = useState(false);
  const [fileLoading, setFileLoading] = useState(false);

  const [, setBillsKonsimentoModalOpen] = useRecoilState(billsKonsimentoModalOpenState);
  const [, setBillsKonsimentoData] = useRecoilState(billsKonsimentoDataState);
  const [, setBillsKonsimentoId] = useRecoilState(billsKonsimentoIdState);
  const [, setBillsFileModalOpen] = useRecoilState(billsFileModalOpenState);
  const [, setBillsFileData] = useRecoilState(billsFileDataState);

  const seeBillDetails = useCallback(() => {
    setLoading(true);
    axios
      .get(`invoice/get-items/${row.id}`, {
        params: {
          page: 1,
          limit: 1000,
          order_direction: "ASC",
        },
      })
      .then((resp) => {
        setBillsKonsimentoData(resp.data.data);
        setBillsKonsimentoId(row.id);
        setBillsKonsimentoModalOpen(true);
      })
      .catch((error) => {
        Errors(error);
      })
      .finally(() => setLoading(false));
  }, [row.id, setBillsKonsimentoModalOpen, setBillsKonsimentoData, setBillsKonsimentoId]);

  const handleFile = () => {
    setFileLoading(true);
    axios
      .get(`invoice/${row.id}`)
      .then((resp) => {
        setBillsFileData(resp.data);
        setBillsFileModalOpen(true);
      })
      .catch((error) => {
        Errors(error);
      })
      .finally(() => setFileLoading(false));
  };

  return (
      <TableRow
          sx={{
              transition: "all 150ms linear",
              ":hover": {
                  transition: "all 150ms linear",
                  backgroundColor: "var(--pastelBlue)",
              },
          }}
      >
          <TableCell sx={{display: "flex", justifyContent: "center", alignItems: "center"}}>
              {row.invoice_no}
              {row.invoice_file !== null && (fileLoading ? (
                  <CircularProgress sx={{width: "21px!important", height: "21px!important"}}/>) : (
                  <InsertDriveFileOutlinedIcon
                      sx={{cursor: "pointer", color: "var(--info)"}}
                      onClick={handleFile}
                  />))}
          </TableCell>
          <TableCell align="center">{FormatDateTR(row.invoice_date)}</TableCell>
          <TableCell align="center">{row.description}</TableCell>
          <TableCell align="center">
              <Box
                  sx={{
                      display: "flex",
                      alignItems: "center",
                      gap: "5px",
                      justifyContent: "center",
                  }}
              >
                  {row.get_invoice_items_count}{" "}
                  {loading ? (
                      <CircularProgress sx={{width: "20px!important", height: "20px!important"}}/>
                  ) : (
                      <VisibilityIcon
                          onClick={seeBillDetails}
                          sx={{
                              width: "20px!important",
                              height: "20px!important",
                              cursor: "pointer",
                              color: "var(--btnBgBlue)",
                          }}
                      />
                  )}
              </Box>
          </TableCell>
          <TableCell align="center">{Currency("TRY")} {LocalPrice(row.invoice_amount)}</TableCell>
      </TableRow>
  );
};

export default BillsTableBody;
