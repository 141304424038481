import "../../style/profileSettings/ProfileNavLinksWrapper.css";
import { useNavigate } from "react-router-dom";
import WorkOutlineOutlinedIcon from "@mui/icons-material/WorkOutlineOutlined";
import BusinessIcon from "@mui/icons-material/Business";
import CreditCardIcon from "@mui/icons-material/CreditCard";
import SquareFootIcon from "@mui/icons-material/SquareFoot";
import WrapperStyle from "../../utils/WrapperStyle";
import { Button } from "@mui/material";

const ProfileNavBar = () => {
  const navigate = useNavigate();
  // const user = JSON.parse(localStorage.getItem("user"));
  // const statusActive = Number(user?.user?.user_member?.status) === 2;
  const statusActive = true;

  const handleNavigate = (location) => {
    navigate(location);
  };

  return (
    <div className="profile-nav-links-wrapper" style={WrapperStyle()}>
      {[
        {
          page: "Genel Bilgi",
          location: "/profile-settings/general",
          disabled: false,
        },
        {
          page: "Adres Bilgileri",
          location: "/profile-settings/address-info",
          disabled: false,
        },
        {
          page: "Kayıtlı Kartlar",
          location: "/profile-settings/registered-cards",
          disabled: !statusActive,
        },
        {
          page: "Banka Hesapları",
          location: "/profile-settings/registered-ibans",
          disabled: !statusActive,
        },
        {
          page: "Kayıtlı Ölçüler",
          location: "/profile-settings/registered-dimensions",
          disabled: !statusActive,
        },
        {
          page: "IOSS/VAT Numaralarım",
          location: "/profile-settings/ioss-numbers",
          disabled: !statusActive,
        },
      ].map((text, key) => (
        <Button
          onClick={() => handleNavigate(text.location)}
          disabled={text.disabled}
          sx={{ ":disabled": { opacity: 0.3 }, ":hover": { backgroundColor: "transparent" } }}
          key={key}
          className={window.location.pathname === text.location ? "nav-link active" : "nav-link"}
        >
          {key === 0 ? (
            <WorkOutlineOutlinedIcon style={{ width: "24px", height: "24px" }} />
          ) : key === 1 ? (
            <BusinessIcon style={{ width: "24px", height: "24px" }} />
          ) : key === 2 ? (
            <CreditCardIcon style={{ width: "24px", height: "24px" }} />
          ) : key === 3 ? (
            <img height={"24px"} src="/images/icons/iban.svg" alt="Kayıtlı Hesaplar" />
          ) : key === 4 ? (
            <SquareFootIcon style={{ width: "24px", height: "24px" }} />
          ) : key === 5 ? (
            <img height={"24px"} src="/images/icons/ioss.svg" alt="IOSS Numaralarım" />
          ) : (
            ""
          )}
          {text.page}
        </Button>
      ))}
    </div>
  );
};

export default ProfileNavBar;
