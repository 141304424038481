import "../../style/fastShipment/FastShipmentSearch.css";
import { Button, CardContent, Collapse, InputAdornment, TextField } from "@mui/material";
import { useCallback, useState } from "react";
import SearchIcon from "@mui/icons-material/Search";
import ClearIcon from "@mui/icons-material/Clear";
import axios from "../../api/axios";
import {
  fastLimitState,
  fastListState,
  fastShipmentCurrentPageState,
  fastShipmentTotalPagesState,
  portalState,
} from "../../atoms/fastShipment";
import { useRecoilValue, useSetRecoilState } from "recoil";
import Errors from "../../utils/Errors";
import FastShipmentsDetailedSearch from "./fastShipmentSearch/FastShipmentsDetailedSearch";

const FastShipmentSearch = ({ setLoading }) => {
  const portal = useRecoilValue(portalState);
  const fastLimit = useRecoilValue(fastLimitState);

  const setFastList = useSetRecoilState(fastListState);
  const setFastShipmentTotalPages = useSetRecoilState(fastShipmentTotalPagesState);
  const setFastShipmentCurrentPage = useSetRecoilState(fastShipmentCurrentPageState);

  const [basicSearch, setBasicSearch] = useState("");
  const [detailedSearchExpanded, setDetailedSearchExpanded] = useState(false);

  const handleBasicSearch = useCallback(
    async (e) => {
      e?.preventDefault();
      setLoading(true);

      await axios
        .get("general/integration-parcel", {
          params: {
            limit: fastLimit,
            search: basicSearch,
          },
        })
        .then((response) => {
          setFastList(response.data.data);
          setFastShipmentTotalPages(response.data.totalPages);
          setFastShipmentCurrentPage(response.data.currentPage);
        })
        .catch((error) => Errors(error))
        .finally(() => setLoading(false));
    },
    [
      fastLimit,
      portal,
      basicSearch,
      setLoading,
      setFastList,
      setFastShipmentTotalPages,
      setFastShipmentCurrentPage,
    ]
  );

  const handleClearBasicSearch = useCallback(async () => {
    setLoading(true);
    setBasicSearch("");

    await axios
      .get("general/integration-parcel", {
        params: {
          limit: fastLimit,
        },
      })
      .then((response) => {
        setFastList(response.data.data);
        setFastShipmentTotalPages(response.data.totalPages);
        setFastShipmentCurrentPage(response.data.currentPage);
      })
      .catch((error) => Errors(error))
      .finally(() => setLoading(false));
  }, [
    fastLimit,
    portal,
    setLoading,
    setFastList,
    setFastShipmentTotalPages,
    setFastShipmentCurrentPage,
  ]);

  const handleDetailedSearchExpand = () => {
    setDetailedSearchExpanded(!detailedSearchExpanded);
  };

  return (
    <div className="fast-shipment-search">
      <form onSubmit={handleBasicSearch} className="fast-shipment-basic-search">
        <TextField
          label="Basit Arama"
          size="small"
          value={basicSearch}
          onChange={(e) => setBasicSearch(e.target.value)}
          placeholder="Sipariş No, Alıcı Adı"
          autoComplete="off"
          fullWidth
          InputProps={{
            startAdornment: (
              <InputAdornment position="start">
                <SearchIcon sx={{ ":hover": { cursor: "pointer" } }} onClick={handleBasicSearch} />
              </InputAdornment>
            ),
            endAdornment: (
              <InputAdornment position="start">
                {basicSearch !== "" && (
                  <ClearIcon
                    sx={{ ":hover": { cursor: "pointer" } }}
                    onClick={handleClearBasicSearch}
                  />
                )}
              </InputAdornment>
            ),
          }}
        />
        <Button className="iyzi-button-blue" type="submit">
          Ara
        </Button>
      </form>
      <Button className="iyzi-button-blue" onClick={handleDetailedSearchExpand}>
        {detailedSearchExpanded ? "Detaylı Aramayı Kapat" : "Detaylı Aramayı Aç"}
      </Button>
      <Collapse
        in={detailedSearchExpanded}
        timeout="auto"
        unmountOnExit
        sx={{ boxShadow: "var(--shadow)", borderRadius: "var(--wrapperBorderRadius)" }}
      >
        <CardContent>
          <FastShipmentsDetailedSearch setLoading={setLoading} />
        </CardContent>
      </Collapse>
    </div>
  );
};

export default FastShipmentSearch;
