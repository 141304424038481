import { atom } from "recoil";

const userInfo = JSON.parse(localStorage.getItem("user"));

const status = userInfo?.user?.user_member?.status;
const photo = userInfo?.user?.photo;
const companyType = userInfo?.user?.user_member?.company_type;
const memberType = userInfo?.user?.user_member?.member_type;
const phoneVerified = userInfo?.user?.phone_verified;

export const isCompanyState = atom({
  key: "isCompany",
  default: false,
});

export const personalCompanyState = atom({
  key: "personalCompany",
  default: false,
});

export const nameState = atom({
  key: "name",
  default: userInfo?.user?.name,
});

export const surnameState = atom({
  key: "surname",
  default: userInfo?.user?.surname,
});

export const emailState = atom({
  key: "email",
  default: userInfo?.user?.email,
});

export const bDayState = atom({
  key: "bDay",
  default: "",
});

export const tckState = atom({
  key: "tck",
  default: "",
});

export const tckTypeState = atom({
  key: "tckType",
  default: "TR",
});

export const smsPermissionState = atom({
  key: "smsPermission",
  default: true,
});

export const mailPermissionState = atom({
  key: "mailPermission",
  default: true,
});

export const areaCodeState = atom({
  key: "areaCode",
  default: "",
});

export const phoneState = atom({
  key: "phone",
  default: "",
});

export const phoneVerifiedState = atom({
  key: "phoneVerified",
  default: phoneVerified,
});

export const profileCompanyTypeState = atom({
  key: "profileCompanyType",
  default: companyType,
});

export const profileMemberTypeState = atom({
  key: "profileMemberType",
  default: memberType,
});

export const taxOfficeState = atom({
  key: "taxOffice",
  default: "",
});

export const taxOfficeCityState = atom({
  key: "taxOfficeCity",
  default: "",
});

export const taxNoState = atom({
  key: "taxNo",
  default: "",
});

export const timerSmsModalState = atom({
  key: "timerSmsModal",
  default: false,
});

export const invoiceOptionState = atom({
  key: "invoiceOption",
  default: "vkn",
});

export const verifiedPhoneState = atom({
  key: "verifiedPhone",
  default: "",
});

export const addressIDState = atom({
  key: "addressID",
  default: [],
});

export const addressLoadingState = atom({
  key: "addressLoading",
  default: false,
});

export const profileSenderAddressState = atom({
  key: "profileSenderAddress",
  default: [],
});

export const profileReceiverAddressState = atom({
  key: "profileReceiverAddress",
  default: [],
});

export const profileBookingAddressState = atom({
  key: "profileBookingAddress",
  default: [],
});

export const senderCurrPageState = atom({
  key: "senderCurrPage",
  default: [],
});

export const receiverCurrPageState = atom({
  key: "receiverCurrPage",
  default: [],
});

export const senderTotalPageState = atom({
  key: "senderTotalPage",
  default: [],
});

export const receiverTotalPageState = atom({
  key: "receiverTotalPage",
  default: [],
});

export const companyNameState = atom({
  key: "companyName",
  default: "",
});

export const kgDiffPaymentPermState = atom({
  key: "kgDiffPaymentPerm",
  default: 0,
});

export const tckApproveState = atom({
  key: "tckApprove",
  default: "",
});

export const newIossNumberAnimationState = atom({
  key: "newIossNumberAnimation",
  default: false,
});

export const iossFastState = atom({
  key: "iossFast",
  default: "",
});

export const integrationsState = atom({
  key: "integrations",
  default: [],
});

export const fastShipmentInfoModalState = atom({
  key: "fastShipmentInfoModal",
  default: false,
});

export const iossPageState = atom({
  key: "iossPage",
  default: 0,
});

export const iossTotalPageState = atom({
  key: "iossTotalPage",
  default: 0,
});

export const iyzicoCardsState = atom({
  key: "iyzicoCards",
  default: [],
});

export const garantiCardsState = atom({
  key: "garantiCards",
  default: [],
});

export const selectedFileState = atom({
  key: "selectedFile",
  default: [],
});

export const verifiedEmailState = atom({
  key: "verifiedEmail",
  default: null,
});

export const checkedSenderAddressIdState = atom({
  key: "checkedSenderAddressId",
  default: null,
});

export const checkedReceiverAddressIdState = atom({
  key: "checkedReceiverAddressId",
  default: null,
});

export const checkedBookingAddressIdState = atom({
  key: "checkedBookingAddressId",
  default: null,
});

export const memberStatusState = atom({
  key: "memberStatus",
  default: status,
});

export const memberStatusModalState = atom({
  key: "memberStatusModal",
  default: false,
});

export const emailHandState = atom({
  key: "emailHand",
  default: "",
});

export const tckNoHandState = atom({
  key: "tckNoHand",
  default: "",
});

export const membershipDocumentsState = atom({
  key: "membershipDocuments",
  default: [],
});

export const photoSelectModalState = atom({
  key: "photoSelectModal",
  default: false,
});

export const resetPasswordModalState = atom({
  key: "resetPasswordModal",
  default: false,
});

export const memberPhotoState = atom({
  key: "memberPhoto",
  default: photo,
});
