import { Box, Checkbox, Typography } from "@mui/material";
import { useCallback } from "react";

import { useRecoilState, useRecoilValue } from "recoil";
import {
  totalBalanceState,
  walletPaymentStatusState,
} from "../../../atoms/myWallet";
import RadioButtonUnchecked from "@mui/icons-material/RadioButtonUnchecked";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import LocalPrice from "../../../utils/LocalPrice";

const MyWalletCard = () => {
  const totalBalance = useRecoilValue(totalBalanceState);

  const [walletPaymentStatus, setWalletPaymentStatus] = useRecoilState(
    walletPaymentStatusState
  );

  const handleCardEft = useCallback(() => {
    setWalletPaymentStatus(1);
  }, [setWalletPaymentStatus]);

  const handleCreditCard = useCallback(() => {
    setWalletPaymentStatus(0);
  }, [setWalletPaymentStatus]);

  return (
    <Box
      sx={{ flexDirection: "column", alignItems: "center" }}
      className="my-wallet-card"
    >
      <Box className="card-credit-balance">
        <img
          loading="lazy"
          src="/images/myWallet/iyzi-bakiyem.jpg"
          alt="IyziCüzdan Görseli"
        />
        <Box className="card-credit-balance-amount">
          <div>Bakiye</div>
          <div>{LocalPrice(totalBalance)} ₺</div>
        </Box>
      </Box>
      <h6 style={{ color: "var(--priceText)" }}>Ödeme Yöntemi</h6>
      <Box className="card-credit-choice">
        <div
          onClick={handleCardEft}
          className={`card-credit-choice-item${
            walletPaymentStatus === 0 ? " passive" : ""
          }`}
        >
          <img
            style={{ maxHeight: "98px", width: "100%" }}
            src="/images/myWallet/eft.svg"
            alt="Havale / EFT Görseli"
          />
          <Typography
            color={"var(--priceText)"}
            textAlign={"center"}
            fontSize={16}
          >
            Havale / EFT
          </Typography>
          <Checkbox
            className="card-credit-choice-item-check"
            checkedIcon={<CheckCircleIcon />}
            icon={<RadioButtonUnchecked />}
            onChange={handleCardEft}
            checked={walletPaymentStatus === 1}
          />
        </div>
        <div
          onClick={handleCreditCard}
          className={`card-credit-choice-item${
            walletPaymentStatus === 1 ? " passive" : ""
          }`}
        >
          <img
            style={{ maxHeight: "98px", width: "100%" }}
            src="/images/myWallet/ccard.svg"
            alt="Kredi Kartı Görseli"
          />
          <Typography
            color={"var(--priceText)"}
            textAlign={"center"}
            fontSize={16}
          >
            Kredi / Debit Kart
          </Typography>
          <Checkbox
            className="card-credit-choice-item-check"
            checkedIcon={<CheckCircleIcon />}
            icon={<RadioButtonUnchecked />}
            checked={walletPaymentStatus === 0}
          />
        </div>
      </Box>
    </Box>
  );
};

export default MyWalletCard;
