import React, { useEffect, useState } from "react";
import InfoOutlinedIcon from "@mui/icons-material/InfoOutlined";
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Button,
  CircularProgress,
  IconButton,
  ThemeProvider,
  Tooltip,
  Typography,
  createTheme,
} from "@mui/material";
import ExpandMoreOutlinedIcon from "@mui/icons-material/ExpandMoreOutlined";
import RadioButtonUncheckedIcon from "@mui/icons-material/RadioButtonUnchecked";
import { Link, useNavigate } from "react-router-dom";
import axios from "../../api/axios";
import Errors from "../../utils/Errors";
import { notificationOpenState } from "../../atoms/viewPadding";
import { useSetRecoilState } from "recoil";

const theme = createTheme({
  components: {
    MuiAccordion: {
      styleOverrides: {
        root: {
          margin: "0 !important",
          borderRadius: "10px !important",
          "::before": {
            height: "0!important",
          },
        },
      },
    },
    MuiAccordionSummary: {
      styleOverrides: {
        content: {
          margin: "0 !important",
          display: "flex",
          alignItems: "center",
          gap: "20px",
        },
        root: {
          minHeight: "48px !important",
        },
      },
    },
    MuiAccordionDetails: {
      styleOverrides: {
        root: {
          padding: "8px",
        },
      },
    },
  },
});

const NotificationList = ({ notification, not, setNot }) => {
  const navigate = useNavigate();
  const setNotificationOpen = useSetRecoilState(notificationOpenState);

  const [days, setDays] = useState(0);
  const unread = Number(notification?.status) === 0;
  const [loading, setLoading] = useState(false);

  const ratingColor =
    Number(notification.rating) < 2
      ? "var(--shipmentGreen)"
      : Number(notification.rating) < 4
      ? "var(--shipmentYellow)"
      : Number(notification.rating) >= 4 || Number(notification.rating) < 99
      ? "var(--info)"
      : "var(--shipmentRed)";

  const handleMarkRead = () => {
    if (unread) {
      setLoading(true);
      axios
        .post(`notification/mark-read/${notification?.id}`)
        .then(() => setNot(not.filter((e) => e.id !== notification?.id)))
        .catch((error) => Errors(error))
        .finally(() => setLoading(false));
    }
  };

  const handleNavigate = (location) => {
    navigate(`/${location}`);
    setNotificationOpen(false);
  };

  useEffect(() => {
    const startDate = new Date(notification.start_date);
    const endDate = new Date(notification.end_date);
    const differenceInTime = endDate.getTime() - startDate.getTime();
    const differenceInDays = differenceInTime / (1000 * 3600 * 24);
    setDays(differenceInDays);
  }, [notification]);

  return (
    <ThemeProvider theme={theme}>
      {notification.type === "validation_profile" ||
      notification.type === "validation_bill_address" ? (
        <Button
          sx={{
            backgroundColor: "var(--shipmentRed)",
            color: "var(--lighterBg)",
            borderRadius: "10px",
            padding: "4px 8px 4px 16px",
            display: "flex",
            alignItems: "center",
            justifyContent: "space-between",
            width: "194px",
            gap: "5px",
            textTransform: "none",
            ":hover": {
              backgroundColor: "var(--notRed)",
            },
          }}
          onClick={() => handleNavigate(notification.location)}
        >
          <Typography
            sx={{
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
            }}
          >
            <InfoOutlinedIcon sx={{ color: "inherit" }} />
            {days > 0 && (
              <span style={{ color: "inherit", fontSize: "13px" }}>{`${days} gün`}</span>
            )}
          </Typography>
          <Typography
            style={{
              whiteSpace: "initial",
              color: "var(--lighterBg)",
              fontSize: "13px",
            }}
          >
            {notification.text}
          </Typography>
        </Button>
      ) : (
        <Accordion
          sx={{
            backgroundColor: ratingColor,
            color: "var(--lighterBg)",
          }}
        >
          <AccordionSummary
            expandIcon={<ExpandMoreOutlinedIcon sx={{ color: "var(--lighterBg)" }} />}
            aria-controls={`panel${notification.id}a-content`}
            id={`panel${notification.id}a-header`}
          >
            <Typography
              sx={{
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
              }}
            >
              <InfoOutlinedIcon sx={{ color: "inherit" }} />
              {days > 0 && (
                <span style={{ color: "inherit", fontSize: "13px" }}>{`${days} gün`}</span>
              )}
            </Typography>
            <Typography>
              {Number(notification.rating) < 2
                ? "Bilgilendirme"
                : Number(notification.rating) < 4
                ? "İşlem"
                : "Önemli"}
            </Typography>
          </AccordionSummary>
          <AccordionDetails sx={{ display: "flex", alignItems: "center", gap: "5px" }}>
            {loading ? (
              <div
                style={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  minWidth: "37px",
                  width: "37px",
                  height: "37px",
                }}
              >
                <CircularProgress
                  sx={{
                    width: "21px!important",
                    height: "21px!important",
                    color: "var(--lighterBg)",
                  }}
                />
              </div>
            ) : (
              <Tooltip title="Okundu olarak işaretle">
                <IconButton sx={{ color: "var(--lighterBg)" }} onClick={handleMarkRead}>
                  <RadioButtonUncheckedIcon sx={{ color: "var(--lighterBg)" }} />
                </IconButton>
              </Tooltip>
            )}
            &nbsp;
            {notification?.location ? (
              <Link
                to={`/${notification?.location}`}
                style={{
                  whiteSpace: "initial",
                  color: "var(--lighterBg)",
                  fontSize: "13px",
                }}
              >
                {notification.text}
              </Link>
            ) : (
              <div
                style={{
                  whiteSpace: "initial",
                  color: "var(--lighterBg)",
                  fontSize: "13px",
                  userSelect: "none",
                }}
              >
                {notification.text}
              </div>
            )}
          </AccordionDetails>
        </Accordion>
      )}
    </ThemeProvider>
  );
};

export default NotificationList;
