import { TextField } from "@mui/material";
import { surnameState } from "../../../../atoms/membership";
import { useRecoilState } from "recoil";

const ProfileSurname = ({ disabled }) => {
  const [surname, setSurname] = useRecoilState(surnameState);

  const handleChange = (e) => {
    const value = e.target.value;
    setSurname(value);
  };

  return (
    <TextField
      required
      disabled={disabled()}
      label="Soyadınız"
      size="small"
      value={surname}
      onChange={handleChange}
    />
  );
};

export default ProfileSurname;
