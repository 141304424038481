import {
  Box,
  Button,
  Checkbox,
  CircularProgress,
  createTheme,
  FormControl,
  FormControlLabel,
  InputLabel,
  MenuItem,
  Modal,
  Select,
  TextField,
  ThemeProvider,
  Typography,
} from "@mui/material";
import { useRecoilState, useRecoilValue } from "recoil";
import { selectedPaymentShipmentState, shipmentPaymentOpenState } from "../../../atoms/myShipments";
import CancelOutlinedIcon from "@mui/icons-material/CancelOutlined";
import PaymentOutlinedIcon from "@mui/icons-material/PaymentOutlined";
import { useState } from "react";
import axios from "../../../api/axios";
import { useCallback } from "react";
import { toast } from "react-toastify";
import maskCardNumber from "../../../utils/maskCardNumber";
import LocalPrice from "../../../utils/LocalPrice";
import { useEffect } from "react";
import { garantiCardsState, iyzicoCardsState } from "../../../atoms/membership";
import CCard from "../../../utils/CCard";
import CardImgChange from "../../../utils/CardImgChange";
import { totalBalanceState } from "../../../atoms/myWallet";
import toTurkishUpperCase from "../../../utils/toTurkishUpperCase";
import Errors from "../../../utils/Errors";
import ArrowForwardIcon from "@mui/icons-material/ArrowForward";
import { useNavigate } from "react-router-dom";

const theme = createTheme({
  components: {
    MuiModal: {
      styleOverrides: {
        root: {
          margin: "20px",
          overflow: "auto",
        },
      },
    },
    MuiSelect: {
      styleOverrides: {
        outlined: {
          display: "flex",
          alignItems: "center",
          gap: "10px",
        },
      },
    },
    MuiFormControlLabel: {
      styleOverrides: {
        label: {
          fontSize: "14px",
          opacity: 0.8,
        },
      },
    },
  },
});

const MyShipmentPayment = () => {
  const navigate = useNavigate();
  const [shipmentPaymentOpen, setShipmentPaymentOpen] = useRecoilState(shipmentPaymentOpenState);
  const [selectedPaymentShipment, setSelectedPaymentShipment] = useRecoilState(
    selectedPaymentShipmentState
  );
  const [iyzicoCards, setIyzicoCards] = useRecoilState(iyzicoCardsState);
  const [garantiCards, setGarantiCards] = useRecoilState(garantiCardsState);

  const [registeredCreditCard, setRegisteredCreditCard] = useState({});
  const [registeredCreditCardValue, setRegisteredCreditCardValue] = useState("");
  const [url, setUrl] = useState([]);

  const totalBalance = useRecoilValue(totalBalanceState);

  const remainingPaymentTry = Number(selectedPaymentShipment?.remaining_payment_try);

  /* Kredi Kartı Giriş Bölüm Başlangıç */

  const [, setFullName] = useState("");
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [creditCard, setCreditCard] = useState("");
  const [, setCreditCardError] = useState(false);
  const [, setCreditCardLessError] = useState(false);
  const [, setCreditCardFalseValue] = useState(false);
  const [, setErrorMessageShow] = useState(false);
  const [month, setMonth] = useState("");
  const [year, setYear] = useState("");
  const [cvc2, setCvc2] = useState("");

  /* Kredi Kartı Giriş Bölüm Son */

  const [paymentDataIsLoad, setPaymentDataIsLoad] = useState(false);
  const [paidPrice, setPaidPrice] = useState("");
  const [message, setMessage] = useState("");
  const [paidStatus, setPaidStatus] = useState(false);
  const [isCardsExist, setIsCardsExist] = useState(false);
  const [isCardsExist2, setIsCardsExist2] = useState(false);
  const [registerCard, setRegisterCard] = useState(false);

  /* Loaing */
  const [loading, setLoading] = useState(false);
  const [walletLoading, setWalletLoading] = useState(false);

  const creditCardChanger = (e) => {
    maskCardNumber(e);
    setCreditCard(CCard(e));
  };

  const handleMonthChange = (e) => {
    const value = e.target.value;
    if (value === "" || Number(value) <= 12) {
      setMonth(value);
    }
  };

  const handleMonthBlur = (e) => {
    let value = e.target.value;

    if (value === "0" || value > 12 || value < 1) {
      setMonth("");
    } else if (value.length === 1) {
      value = "0" + value;
      setMonth(value);
    }
  };

  const handleYearChange = (e) => {
    let currentYear = new Date().getFullYear();

    // Yılın son iki hanesini al
    currentYear = currentYear.toString().slice(-2);

    let yearValue = e.target.value;
    if (
      yearValue < 0 ||
      yearValue > 99 ||
      isNaN(yearValue) ||
      (yearValue.length === 2 && yearValue < currentYear)
    ) {
      setYear("");
    } else {
      setYear(yearValue);
    }
  };

  const handleYearBlur = (e) => {
    let value = e.target.value;
    if (value.length < 2) {
      setYear("");
    }
  };

  const handleCvc2Change = (e) => {
    const value = e.target.value;
    if (value.match(/^[0-9]*$/)) {
      setCvc2(value);
    }
  };

  const handleCvc2Blur = (e) => {
    let value = e.target.value;
    if (value.length < 3) {
      setCvc2("");
    }
  };

  const paymentSend = useCallback(() => {
    if (creditCard.length === 0) {
      setCreditCardError(true);
      setErrorMessageShow(true);
    }

    if (creditCard.length > 0 && creditCard.length < 19) {
      setCreditCardLessError(true);
      setErrorMessageShow(true);
    }

    if (creditCard.length === 19 && cvc2.length === 3 && month.length === 2 && year.length === 2) {
      setLoading(true);
      let form = new FormData();

      form.append("parcel_id", Number(selectedPaymentShipment.id));
      form.append("holder_name", `${firstName} ${lastName}`);
      form.append("card_number", creditCard.replaceAll(" ", ""));
      form.append("expire_month", month);
      form.append("expire_year", year);
      form.append("cvc", cvc2);
      form.append("register_card", registerCard ? "1" : "0");
      form.append("payment_type", "parcel_payment");
      form.append("price", Number(selectedPaymentShipment.selling_price).toFixed(2));

      axios
        .post("payment/initialize-make-payment", form)
        .then((response) => {
          if (response && response.data.status === 200) {
            setPaymentDataIsLoad(true);

            //* Banka Ödeme Şekilleri
            setUrl(response.data.iframeData.decodeHTML);
          } else if (response && response.data.status === 400) {
            setLoading(false);
            toast.error(response.data.message);
            setCreditCardFalseValue(true);
            setErrorMessageShow(true);
          } else {
            setLoading(false);
          }
        })
        .catch((error) => {
          Errors(error);
          setLoading(false);
          setCreditCardFalseValue(true);
          setErrorMessageShow(true);
        });
    }
  }, [creditCard, firstName, lastName, cvc2, month, year, selectedPaymentShipment, registerCard]);

  useEffect(() => {
    setFullName(`${firstName} ${lastName}`);
  }, [firstName, lastName]);

  const registeredPaymentSend = useCallback(async () => {
    setLoading(true);

    let form = new FormData();
    form.append("parcel_id", Number(selectedPaymentShipment.id));
    form.append("card_token", registeredCreditCard.card_token);
    form.append("payment_type", "parcel_payment");
    form.append("price", Number(selectedPaymentShipment.selling_price).toFixed(2));

    await axios
      .post("payment/initialize-make-payment", form)
      .then((response) => {
        if (response && response.data.status === 200) {
          setPaymentDataIsLoad(true);

          //* Banka Ödeme Şekilleri
          setUrl(response.data.iframeData.decodeHTML);
        } else if (response && response.data.status === 400) {
          setLoading(false);
          toast.error(response.data.message);
          //setCreditCardFalseValue(true);
          //setErrorMessageShow(true);
        } else {
          setLoading(false);
        }
      })
      .catch((error) => {
        setLoading(false);
        Errors(error);
        toast.error(error.response.data.message);
      });
  }, [selectedPaymentShipment, registeredCreditCard]);

  const handleShipmentPaymentClose = useCallback(() => {
    if (paidStatus) {
      window.location.reload(true);
    } else {
      setShipmentPaymentOpen(false);
      setPaymentDataIsLoad(false);
      setUrl("");
      setSelectedPaymentShipment({});
      setLoading(false);
      setIsCardsExist(false);
      setIsCardsExist2(false);
      setRegisteredCreditCard({});
      setRegisteredCreditCardValue("");
      setFirstName("");
      setLastName("");
      setCreditCard("");
      setMonth("");
      setCvc2("");
      setLastName("");
      setYear("");
    }
  }, [paidStatus, setShipmentPaymentOpen, setSelectedPaymentShipment]);

  const walletPaymentSend = useCallback(async () => {
    setWalletLoading(true);

    await axios
      .post(`payment/make-parcel-payment`, {
        payment_type: "WALLET",
        ids: [Number(selectedPaymentShipment.id)],
      })
      .then((response) => {
        toast.success(response.data.message);
        setTimeout(() => {
          window.location.reload(true);
        }, 1000);
      })
      .catch((error) => Errors(error))
      .finally(() => {
        setWalletLoading(false);
        setShipmentPaymentOpen(false);
        setSelectedPaymentShipment({});
      });
  }, [selectedPaymentShipment, setSelectedPaymentShipment, setShipmentPaymentOpen]);

  const getCards = useCallback(async () => {
    setLoading(true);

    await axios
      .get(`profile/get-cards`)
      .then((response) => {
        setIyzicoCards(response.data.data?.iyzico?.items);
        setGarantiCards(response.data.data?.garanti?.items);
        if (response.data.data?.iyzico.items) {
          if (response.data.data?.iyzico?.items?.length !== 0) {
            setIsCardsExist(true);
            setIsCardsExist2(true);
            setRegisteredCreditCard(
              response.data.data?.iyzico?.items?.filter((card) => card.default === 1)[0]
            );
            setRegisteredCreditCardValue(
              response.data.data?.iyzico?.items?.filter((card) => card.default === 1)[0].card_pan
            );
          }
        }
        if (response.data.data?.garanti.items) {
          if (response.data.data?.garanti?.items?.length !== 0) {
            setIsCardsExist(true);
            setIsCardsExist2(true);
            setRegisteredCreditCard(
              response.data.data?.garanti?.items?.filter((card) => card.default === 1)[0]
            );
            setRegisteredCreditCardValue(
              response.data.data?.garanti?.items?.filter((card) => card.default === 1)[0].card_pan
            );
          }
        }
        setLoading(false);
      })
      .catch((error) => {
        setLoading(false);
        setIsCardsExist(false);
        Errors(error);
      });
  }, [setIyzicoCards, setGarantiCards]);

  useEffect(() => {
    if (shipmentPaymentOpen) {
      getCards();
    }
  }, [shipmentPaymentOpen, getCards]);

  useEffect(() => {
    setCreditCardFalseValue(false);

    if (creditCard.length !== 0) {
      setCreditCardError(false);
      setErrorMessageShow(false);
    }

    if (creditCard.length === 19) {
      setCreditCardLessError(false);
      setErrorMessageShow(false);
    }
  }, [creditCard]);

  useEffect(() => {
    const handlePaymentMessage = (event) => {
      if (event?.data?.data?.status === 200) {
        setPaidPrice(event?.data?.data?.paidPrice);
        setMessage(event?.data?.data?.message);
        setPaidStatus(true);
        // Etkinlik dinleyicisini kaldırma
        window.removeEventListener("message", handlePaymentMessage);
      }
    };

    // Etkinlik dinleyicisini ekleme
    window.addEventListener("message", handlePaymentMessage);

    // Temizleme işlemi
    return () => {
      window.removeEventListener("message", handlePaymentMessage);
    };
  }, []);

  // Disabled Payment Button
  const member = JSON.parse(localStorage.getItem("user"));
  const memberStatus = member?.user?.user_member?.status;
  const isButtonDisabled = Number(memberStatus) !== 2;

  return (
    <ThemeProvider theme={theme}>
      <Modal open={shipmentPaymentOpen} onClose={handleShipmentPaymentClose}>
        <Box
          sx={{
            maxWidth: "1100px",
            width: "100%",
            backgroundColor: "var(--lighterBg)",
            borderRadius: "var(--inputBorderRadius)",
            outline: 0,
            padding: "20px",
            position: "relative",
            margin: "auto",
            height: "800px",
            overflowY: "auto",
          }}
          style={{
            maxHeight: "90vh",
            overflowY: "auto",
          }}
        >
          <CancelOutlinedIcon
            sx={{
              position: "absolute",
              right: 1,
              top: 1,
              color: "var(--priceText)",
              cursor: "pointer",
              transition: "all 80ms linear",
              ":hover": {
                color: "var(--notRed)",
                transition: "all 80ms linear",
              },
            }}
            onClick={handleShipmentPaymentClose}
          />
          <h6
            style={{
              color: "var(--priceText)",
              display: "flex",
              alignItems: "center",
              gap: "5px",
              marginBottom: "20px",
            }}
          >
            <PaymentOutlinedIcon />
            Ödeme Ekranı
          </h6>
          <Box
            sx={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            {paymentDataIsLoad && !paidStatus && (
              <iframe
                title="nizmetSabileOfisi"
                id="my-iframe"
                style={{
                  width: "100%",
                  height: "500px",
                }}
                srcdoc={url}
              ></iframe>
            )}
            {paidStatus && (
              <Box
                sx={{
                  display: "flex",
                  flexDirection: "column",
                  justifyContent: "center",
                  alignItems: "center",
                  height: "400px",
                  width: "100%",
                  gap: "20px",
                }}
              >
                <Typography
                  sx={{
                    fontSize: "25px",
                    padding: "20px",
                    border: "1px solid var(--shipmentGreen)",
                    borderRadius: "var(--inputBorderRadius)",
                    boxShadow: "0 0 2px",
                    backgroundColor: "var(--background)",
                    color: "var(--priceText)",
                    fontWeight: 500,
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "center",
                    justifyContent: "center",
                  }}
                >
                  <span
                    style={{
                      fontSize: "23px",
                      fontWeight: 700,
                    }}
                  >
                    {LocalPrice(paidPrice)} ₺
                  </span>
                  ✅ {message}
                </Typography>
                <Button onClick={() => window.location.reload(true)} className="iyzi-button-blue">
                  Tamamla
                </Button>
              </Box>
            )}
            {!paymentDataIsLoad &&
              (loading ? (
                <div
                  style={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    height: "500px",
                    width: "100%",
                  }}
                >
                  <CircularProgress />
                </div>
              ) : isCardsExist ? (
                <Box
                  sx={{
                    display: "grid",
                    justifyContent: "flex-start",
                    margin: "30px 0 0 30px",
                    gap: "var(--gap2x)",
                  }}
                >
                  <Box
                    sx={{
                      display: "flex",
                      flexWrap: "wrap",
                      gap: "var(--gap)",
                    }}
                  >
                    <FormControl size="small" sx={{ minWidth: 200 }}>
                      <InputLabel sx={{ fontSize: "14px" }} id="credit-cards-label">
                        Kayıtlı Kartlar
                      </InputLabel>
                      <Select
                        labelId="credit-cards-label"
                        id="credit-cards"
                        label="Kayıtlı Kartlar"
                        sx={{
                          fontSize: "14px",
                          display: "flex",
                          minHeight: "40px",
                        }}
                        value={registeredCreditCardValue}
                        onChange={(e) => setRegisteredCreditCardValue(e.target.value)}
                      >
                        <MenuItem key="0" value="" onClick={() => setRegisteredCreditCard({})}>
                          <em>Temizle</em>
                        </MenuItem>
                        {garantiCards?.map((card) => (
                          <MenuItem
                            key={card.id}
                            value={card.card_pan}
                            onClick={() => setRegisteredCreditCard(card)}
                            sx={{
                              display: "flex",
                              alignItems: "center",
                              gap: "5px",
                            }}
                          >
                            {CardImgChange(card.card_pan)} <span>{card.card_pan}</span>
                          </MenuItem>
                        ))}
                        {iyzicoCards?.map((card) => (
                          <MenuItem
                            key={card.id}
                            value={card.card_pan}
                            onClick={() => setRegisteredCreditCard(card)}
                            sx={{
                              display: "flex",
                              alignItems: "center",
                              gap: "5px",
                            }}
                          >
                            {CardImgChange(card.card_pan)} {card.card_pan}
                          </MenuItem>
                        ))}
                      </Select>
                    </FormControl>
                    {registeredCreditCardValue !== "" && (
                      <Button
                        className="iyzi-button-blue"
                        onClick={registeredPaymentSend}
                        disabled={isButtonDisabled}
                        sx={{ ":disabled": { opacity: 0.3 } }}
                      >
                        {isButtonDisabled
                          ? "Ödeme Yapmak İçin Profilinizi Tamamlayın!"
                          : "Ödeme Yap"}
                      </Button>
                    )}
                    {walletLoading ? (
                      <div
                        style={{
                          display: "flex",
                          justifyContent: "center",
                          alignItems: "center",
                          width: "110px",
                        }}
                      >
                        <CircularProgress
                          sx={{
                            width: "21px!important",
                            height: "21px!important",
                          }}
                        />
                      </div>
                    ) : (
                      <Button
                        className="iyzi-button-blue"
                        onClick={walletPaymentSend}
                        disabled={Number(totalBalance) < remainingPaymentTry}
                        sx={{ ":disabled": { opacity: 0.3 } }}
                      >
                        iyziBakiye ile Öde
                      </Button>
                    )}
                  </Box>
                  {Number(totalBalance) < remainingPaymentTry && (
                    <Box
                      sx={{
                        backgroundColor: "var(--pastelRed)",
                        display: "flex",
                        alignItems: "center",
                        gap: "5px",
                        borderRadius: "9999px",
                        justifyContent: "space-between",
                        width: "fit-content",
                        padding: "8px",
                        margin: "auto",
                      }}
                    >
                      <span style={{ color: "var(--shipmentRed)", fontWeight: 700 }}>
                        iyziBakiyeniz Yetersiz
                      </span>
                      <Button
                        onClick={() => navigate("/my-wallet/add-balance")}
                        className="iyzi-button-red"
                        sx={{
                          fontWeight: 500,
                          textDecoration: "none",
                          borderRadius: "9999px!important",
                          padding: "7px",
                          display: "flex",
                          alignItems: "center",
                          gap: "5px",
                        }}
                        to={"/my-wallet/add-balance"}
                      >
                        Bakiye Yüklemek için Tıklayın <ArrowForwardIcon />
                      </Button>
                    </Box>
                  )}
                  <Box
                    sx={{
                      cursor: "pointer",
                      color: "var(--priceText)",
                      transition: "all 150ms linear",
                      ":hover": { color: "var(--info)" },
                      opacity: isButtonDisabled ? 0.4 : 1,
                    }}
                    onClick={() => setIsCardsExist(false)}
                    disabled={isButtonDisabled}
                  >
                    {isButtonDisabled
                      ? "Ödeme Yapmak İçin Profilinizi Tamamlayın!"
                      : "+ Başka Bir Kart ile Ödeme Yap"}
                  </Box>
                </Box>
              ) : (
                <Box>
                  <Box
                    sx={{
                      display: "grid",
                      gridTemplateColumns: "repeat(1, minmax(240px, 1fr))",
                      gap: "var(--gap)",
                      maxWidth: "500px",
                    }}
                  >
                    <TextField
                      value={firstName}
                      onChange={(e) => setFirstName(toTurkishUpperCase(e.target.value))}
                      size="small"
                      fullWidth
                      autoComplete="off"
                      label="İsim"
                    />
                    <TextField
                      value={lastName}
                      onChange={(e) => setLastName(toTurkishUpperCase(e.target.value))}
                      size="small"
                      fullWidth
                      autoComplete="off"
                      label="Soyisim"
                    />
                    <TextField
                      value={creditCard}
                      onChange={creditCardChanger}
                      size="small"
                      inputMode="numeric"
                      inputProps={{ maxLength: 19 }}
                      fullWidth
                      label="Kart Numarası"
                      placeholder="1111 0000 2222 4444"
                      autoComplete="off"
                      // error={errorMessageShow}
                      // helperText={
                      //   errorMessageShow === true && creditCardError === true
                      //     ? "Kredi Kartı Numaranızı Giriniz."
                      //     : errorMessageShow === true &&
                      //       creditCardLessError === true
                      //     ? "Kredi Kartı Numaranızı Eksik Girdiniz."
                      //     : errorMessageShow === true &&
                      //       creditCardFalseValue === true
                      //     ? "Hatalı Kart Numarası Girdiniz."
                      //     : ""
                      // }
                    />
                    <Box
                      sx={{
                        display: "grid",
                        gridTemplateColumns: "repeat(3, minmax(50px, 1fr))",
                        width: "100%",
                        gap: "5px",
                      }}
                      className="card-info-text-date"
                    >
                      <TextField
                        size="small"
                        label="Ay"
                        value={month}
                        onChange={handleMonthChange}
                        onBlur={handleMonthBlur}
                        placeholder="03"
                        autoComplete="off"
                        inputMode="numeric"
                        inputProps={{ maxLength: 2 }}
                      />
                      <TextField
                        size="small"
                        label="Yıl"
                        value={year}
                        onChange={handleYearChange}
                        onBlur={handleYearBlur}
                        placeholder="23"
                        autoComplete="off"
                        inputMode="numeric"
                        inputProps={{ maxLength: 2 }}
                      />
                      <TextField
                        size="small"
                        label="CVC2"
                        placeholder="CVC2"
                        onChange={handleCvc2Change}
                        onBlur={handleCvc2Blur}
                        value={cvc2}
                        inputProps={{ maxLength: 3, pattern: "[0-9]*" }}
                        autoComplete="off"
                        type="password"
                        inputMode="numeric"
                      />
                    </Box>
                  </Box>
                  <Box
                    sx={{
                      marginTop: "var(--gap)",
                      display: "flex",
                      flexDirection: "column",
                      gap: "var(--gap)",
                    }}
                  >
                    <FormControlLabel
                      sx={{
                        userSelect: "none",
                      }}
                      control={
                        <Checkbox
                          checked={registerCard}
                          onChange={(e) => setRegisterCard(e.target.checked)}
                        />
                      }
                      label="Kartımı Kaydet"
                    />
                    <Box
                      sx={{
                        display: "grid",
                        gap: "var(--gap)",
                      }}
                    >
                      {walletLoading ? (
                        <div
                          style={{
                            display: "flex",
                            justifyContent: "center",
                            alignItems: "center",
                            width: "110px",
                          }}
                        >
                          <CircularProgress
                            sx={{
                              width: "21px!important",
                              height: "21px!important",
                            }}
                          />
                        </div>
                      ) : (
                        <Button
                          className="iyzi-button-blue"
                          onClick={walletPaymentSend}
                          sx={{ ":disabled": { opacity: 0.3 } }}
                          disabled={Number(totalBalance) < remainingPaymentTry}
                        >
                          {Number(totalBalance) < remainingPaymentTry
                            ? "iyziBakiyeniz Yetersiz!"
                            : "iyziBakiye ile Öde"}
                        </Button>
                      )}
                      {isCardsExist2 && (
                        <Button className="iyzi-button-blue" onClick={() => setIsCardsExist(true)}>
                          Kayıtlı Kart ile Öde
                        </Button>
                      )}
                      <Button
                        className="iyzi-button-blue"
                        onClick={paymentSend}
                        disabled={isButtonDisabled}
                        sx={{ ":disabled": { opacity: 0.3 } }}
                      >
                        {isButtonDisabled
                          ? "Ödeme Yapmak İçin Profilinizi Tamamlayın!"
                          : "Ödeme Yap"}
                      </Button>
                      <Button className="iyzi-button-blue" onClick={handleShipmentPaymentClose}>
                        Ödeme Ekranından Çık
                      </Button>
                    </Box>
                  </Box>
                </Box>
              ))}
          </Box>
        </Box>
      </Modal>
    </ThemeProvider>
  );
};

export default MyShipmentPayment;
