const IntegrationEMarket = () => {
  const images = [
    {
      src: "/images/integrations/shopify-wb.png",
      alt: "Shopify",
    },
    {
      src: "/images/integrations/woocommerce-wb.png",
      alt: "Woo Commerce",
    },
    {
      src: "/images/integrations/prestaShop-wb.png",
      alt: "Presta Shop",
    },
  ];

  return (
    <section className="main-integration">
      <div className="integration-wrapper">
        <h2 style={{ marginBottom: "var(--gap3x)" }}>
          E-ticaret Entegrasyonları
        </h2>
        <div className="integration-container">
          {images.map((image) => (
            <img src={image.src} alt={image.alt} />
          ))}
        </div>
      </div>
    </section>
  );
};

export default IntegrationEMarket;
