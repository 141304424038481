import { useCallback, useEffect } from "react";
import axios from "../../api/axios";
import { useRecoilState } from "recoil";
import { totalBalanceState } from "../../atoms/myWallet";
import WalletOutlinedIcon from "@mui/icons-material/WalletOutlined";
import { Button, Tooltip } from "@mui/material";
import { useNavigate } from "react-router-dom";
import LocalPrice from "../../utils/LocalPrice";
import RefreshToken from "../../utils/RefreshToken";
import { useCookies } from "react-cookie";

const WalletInfo = () => {
  const navigate = useNavigate();
  const [, setCookies] = useCookies(["jwt"]);
  const [token] = useCookies(["jwt"]);
  const jwt = token.jwt;

  const [totalBalance, setTotalBalance] = useRecoilState(totalBalanceState);

  // const user = JSON.parse(localStorage.getItem("user"));
  // const activeStatus = Number(user?.user?.user_member?.status) === 2;
  const activeStatus = true;

  const walletData = useCallback(() => {
    axios
      .get("wallet")
      .then((response) => {
        setTotalBalance(response.data.data.total_balance);
      })
      .catch((error) => {
        if (error.response.status === 401) {
          RefreshToken(jwt, navigate, setCookies);
        }
      });
  }, [setTotalBalance, jwt, navigate, setCookies]);

  const handleNavigate = useCallback(() => {
    if (activeStatus) {
      navigate("/my-wallet");
    }
  }, [activeStatus, navigate]);

  useEffect(() => {
    walletData();
  }, [walletData]);

  return (
    <Tooltip title={"Bakiyem"} placement="bottom">
      <Button
        sx={{
          display: "flex",
          gap: "var(--gap)",
          alignItems: "center",
          border: "1px solid var(--inputBorderColor)",
          padding: "0 10px",
          borderRadius: "var(--inputBorderRadius)",
          height: "30px",
          alignSelf: "center",
          color: "rgba(0, 0, 0, 0.54)",
          position: "relative",
          zIndex: 1201,
          minWidth: "90px",
          ":disabled": {
            opacity: 0.3,
          },
        }}
        disabled={!activeStatus}
        onClick={handleNavigate}
      >
        <WalletOutlinedIcon />
        <span style={{ color: "var(--btnBgBlue)" }}>{LocalPrice(totalBalance)} ₺</span>
      </Button>
    </Tooltip>
  );
};

export default WalletInfo;
