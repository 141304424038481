import { useRecoilState, useRecoilValue } from "recoil";
import {
  basketItemsCurrentPageState,
  basketItemsState,
  basketItemsTotalPageState,
  basketTotalPriceTryState,
  freshListState,
} from "../../atoms/basket";
import BasketDetailedItemsChild from "./BasketDetailedItemsChild";
import LocalPrice from "../../utils/LocalPrice";
import { Divider, Pagination } from "@mui/material";
import axios from "../../api/axios";
import { useCallback } from "react";
import Errors from "../../utils/Errors";
import InfoIcon from "@mui/icons-material/Info";

const BasketDetailedItems = ({ setLoading, totalItems, setTotalItems }) => {
  const basketTotalPriceTry = useRecoilValue(basketTotalPriceTryState);
  const [basketItems, setBasketItems] = useRecoilState(basketItemsState);
  const [basketItemsCurrentPage, setBasketItemsCurrentPage] = useRecoilState(
    basketItemsCurrentPageState
  );
  const [basketItemsTotalPage, setBasketItemsTotalPage] = useRecoilState(basketItemsTotalPageState);
  const [freshList, setFreshList] = useRecoilState(freshListState);

  const handlePageChange = useCallback(
    (e, pageValue) => {
      setLoading(true);
      axios
        .get("basket", {
          params: {
            detailed: 1,
            limit: 5,
            page: pageValue,
            status: freshList ? "2.17" : ""
           },
        })
        .then((response) => {
          setLoading(false);
          setBasketItems(response.data.data);
          setBasketItemsCurrentPage(pageValue);
          setBasketItemsTotalPage(response.data.totalPages);
          setTotalItems(response.data.totalItems);
        })
        .catch((error) => Errors(error))
        .finally(() => setLoading(false));
    },
    [setLoading, setTotalItems, setBasketItems, setBasketItemsCurrentPage, setBasketItemsTotalPage]
  );

  return (
    <div className="basket-detailed-items-wrapper">
      <div className="basket-detailed-items-container">
        <div className="basket-detailed-items">
          <div className="items-price">
            <span>Sepet Toplamı:</span> {LocalPrice(basketTotalPriceTry)} ₺
          </div>
          <Divider className="items-price-divider" />
        </div>
      </div>
      <div className="basket-detailed-list">
        {basketItems?.map((basket, key) => (
          <BasketDetailedItemsChild key={key} basket={basket} />
        ))}
      </div>
      <Pagination
        showFirstButton
        showLastButton
        size="small"
        count={basketItemsTotalPage}
        page={basketItemsCurrentPage}
        onChange={handlePageChange}
      />
      {basketItems?.length > 9 && (
        <div className="basket-detailed-items-container">
          <div className="basket-detailed-items">
            <div className="items-price">
              <b>Sepet Toplamı:</b> {LocalPrice(basketTotalPriceTry)} ₺
            </div>
            <Divider className="items-price-divider" />
          </div>
        </div>
      )}
    </div>
  );
};

export default BasketDetailedItems;
