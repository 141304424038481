import "../../../style/createShipment/NewAddress.css";
import Box from "@mui/material/Box";
import { useRecoilValue } from "recoil";
import { newIossNumberAnimationState } from "../../../atoms/membership";
import AddIossNumber from "./AddIossNumber";
import IossNumberList from "./IossNumberList";
import CreateIossNumber from "./CreateIossNumber";

const NewIossNumbers = () => {
  const newIossNumberAnimation = useRecoilValue(newIossNumberAnimationState);

  return (
    <Box className="new-address">
      <>
        {newIossNumberAnimation === false && (
          <>
            <AddIossNumber />
            <IossNumberList />
          </>
        )}
        <CreateIossNumber />
      </>
    </Box>
  );
};

export default NewIossNumbers;
