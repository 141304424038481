import { atom } from "recoil";

export const openSupportRequestState = atom({
  key: "openSupportRequest",
  default: false,
});

export const openSupportListState = atom({
  key: "openSupportList",
  default: false,
});

export const openSupportDetailState = atom({
  key: "openSupportDetail",
  default: false,
});

export const reteSupportState = atom({
  key: "reteSupport",
  default: 0,
});

export const supportListState = atom({
  key: "supportList",
  default: [],
});

export const rateCommentOpenState = atom({
  key: "rateCommentOpen",
  default: false,
});

export const rateCommentState = atom({
  key: "rateComment",
  default: "",
});

export const isRatingState = atom({
  key: "isRating",
  default: false,
});

export const supportTotalPageState = atom({
  key: "supportTotalPage",
  default: 0,
});

export const supportCurrentPageState = atom({
  key: "supportCurrentPage",
  default: 1,
});

export const caseHeaderState = atom({
  key: "caseHeader",
  default: false,
});

export const awbHeaderState = atom({
  key: "awbHeader",
  default: false,
});

export const subjectHeaderState = atom({
  key: "subjectHeader",
  default: false,
});

export const statusHeaderState = atom({
  key: "statusHeader",
  default: false,
});

export const dateHeaderState = atom({
  key: "dateHeader",
  default: false,
});

export const lastActionHeaderState = atom({
  key: "lastActionHeader",
  default: false,
});

export const supportOrderDirectionState = atom({
  key: "supportOrderDirection",
  default: "DESC",
});

export const supportOrderByState = atom({
  key: "supportOrderBy",
  default: "id",
});

export const supportListLoadingState = atom({
  key: "supportListLoading",
  default: false,
});

export const ticketState = atom({
  key: "ticket",
  default: {},
});

export const supportPageLimitState = atom({
  key: "supportPageLimit",
  default: 10,
});

export const ticketDocumentState = atom({
  key: "ticketDocument",
  default: [],
});

export const parcelDocumentState = atom({
  key: "parcelDocument",
  default: [],
});

export const expandFilesState = atom({
  key: "expandFiles",
  default: false,
});

export const caseProblemState = atom({
  key: "caseProblem",
  default: 0,
});

export const caseTextState = atom({
  key: "caseText",
  default: 0,
});

export const caseExplainState = atom({
  key: "caseExplain",
  default: "",
});

export const openNewCaseState = atom({
  key: "openNewCase",
  default: false,
});

export const caseListState = atom({
  key: "caseList",
  default: [],
});

export const expandIyziNoteState = atom({
  key: "expandIyziNote",
  default: false,
});

export const teamNoteListState = atom({
  key: "teamNoteList",
  default: [],
});

export const ticketMessagesState = atom({
  key: "ticketMessages",
  default: [],
});

export const appointeeState = atom({
  key: "appointee",
  default: [],
});

export const appointeeListState = atom({
  key: "appointeeList",
  default: [],
});

export const supportTanzimModalOpenState = atom({
  key: "supportTanzimModalOpen",
  default: false,
});

export const supportCaseModalState = atom({
  key: "supportCaseModal",
  default: false,
});

export const supportCaseMessagesState = atom({
  key: "supportCaseMessages",
  default: [],
});

export const ticketDimensionsState = atom({
  key: "ticketDimensions",
  default: [],
});
