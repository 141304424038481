import { Select, MenuItem, FormControl, InputLabel } from "@mui/material";
import { useState, useEffect } from "react";
import axios from "../../../api/axios";

const Suppliers = ({ supplier, setSupplier }) => {
  const [suppliers, setSuppliers] = useState([]);

  const getSuppliers = () => {
    axios.get("supplier").then((response) => {
      setSuppliers(response.data.data);
    });
  };

  useEffect(() => {
    getSuppliers();
  }, []);
  return (
    <FormControl fullWidth size="small">
      <InputLabel sx={{ fontSize: "14px" }} id="supplier-select-label">
        Tedarikçi
      </InputLabel>
      <Select
        labelId="supplier-select-label"
        id="supplier-select"
        label="Tedarikçi"
        value={supplier}
        onChange={(e) => setSupplier(e.target.value)}
        sx={{ fontSize: "14px", display: "flex", minHeight: "40px" }}
      >
        <MenuItem value="">
          <em>Hepsi</em>
        </MenuItem>
        {suppliers?.map((sup, key) => (
          <MenuItem key={key} value={sup.id}>
            {sup.supplier_name}
          </MenuItem>
        ))}
      </Select>
    </FormControl>
  );
};

export default Suppliers;
