import { Select, MenuItem, FormControl, InputLabel } from "@mui/material";

const ParcelType = ({ parcelType, setParcelType }) => {
  return (
    <FormControl fullWidth size="small">
      <InputLabel sx={{ fontSize: "14px" }} id="supplier-select-label">
        Gönderi Tipi
      </InputLabel>
      <Select
        label="Gönderi Tipi"
        value={parcelType}
        onChange={(e) => setParcelType(e.target.value)}
        // sx={{ fontSize: "14px", height: "35px" }}
      >
        <MenuItem value="">
          <em>Temizle</em>
        </MenuItem>
        <MenuItem value="1">
          <em>Normal Gönderi</em>
        </MenuItem>
        <MenuItem value="3">
          <em>ETGB'li Gönderi</em>
        </MenuItem>
      </Select>
    </FormControl>
  );
};

export default ParcelType;
