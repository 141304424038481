import { Box, Button, Modal, Typography } from "@mui/material";
import { useCallback } from "react";
import { useRecoilState } from "recoil";
import { errorModalState } from "../atoms/error";

const ErrorModal = () => {
  const [errorModal, setErrorModal] = useRecoilState(errorModalState);

  const handleClose = useCallback(() => {
    setErrorModal({ status: false, page: "", text: "" });
  }, [setErrorModal]);

  return (
    <Modal
      sx={{
        maxWidth: "600px",
        width: "100%",
        margin: "0 auto",
        maxHeight: "600px",
        height: "100%",
        display: "flex",
        alignItems: "center",
      }}
      open={errorModal.status}
      onClose={handleClose}
    >
      <Box
        sx={{
          padding: "20px",
          backgroundColor: "var(--lighterBg)",
          borderRadius: "var(--inputBorderRadius)",
        }}
        style={{
          maxHeight: "90vh",
          overflowY: "auto",
        }}
      >
        <Typography>{errorModal.text}</Typography>
        <Box
          sx={{
            width: "100%",
            display: "flex",
            justifyContent: "flex-end",
            marginTop: "10px",
          }}
        >
          <Button onClick={handleClose} className="iyzi-button">
            Anladım
          </Button>
        </Box>
      </Box>
    </Modal>
  );
};

export default ErrorModal;
