import { TextField } from "@mui/material";
import { useRecoilState } from "recoil";
import { emailState } from "../../../../atoms/membership";

const ProfileMail = ({ disabled }) => {
  const [email, setEmail] = useRecoilState(emailState);

  const handleChange = (e) => {
    const value = e.target.value;
    setEmail(value);
  };

  return (
    <TextField
      disabled={disabled()}
      label="E-Mail"
      required
      size="small"
      value={email}
      onChange={handleChange}
    />
  );
};

export default ProfileMail;
