import "react-phone-input-2/lib/style.css";
import TextField from "@mui/material/TextField";
import { useState } from "react";
import { useEffect } from "react";
import axios from "../../api/axios";
import { toast, ToastContainer } from "react-toastify";
import { Button, CircularProgress } from "@mui/material";
import Errors from "../../utils/Errors";

const ResetPassword = () => {
  const [email, setEmail] = useState("");
  const [errorTextShow, setErrorTextShow] = useState(false);
  const [correctEmail, setCorrectEmail] = useState(false);
  const [errorText, setErrorText] = useState("");
  const [errorTextS, setErrorTextS] = useState(false);
  const [loading, setLoading] = useState(false);

  const forgotPasswordGetMail = (e) => {
    e.preventDefault();

    if (email.length === 0) {
      setErrorTextShow(true);
    }

    if (!email.includes("@")) {
      setCorrectEmail(true);
    }

    if (email.length === 0 || !email.includes("@")) {
      return;
    } else {
      setLoading(true);
      axios
        .post("forgot-password", {
          email,
        })
        .then((response) => {
          if (response.data.status === 200) {
            toast.success(response.data.message);
          }
        })
        .catch((error) => {
          Errors(error);
          setErrorText(error.response.data.message);
          setErrorTextS(true);
        })
        .finally(() => setLoading(false));
    }
  };

  useEffect(() => {
    if (email.length !== 0) {
      setErrorTextShow(false);
      setErrorTextS(false);
    }

    if (email.includes("@")) {
      setCorrectEmail(false);
    }
  }, [email]);

  return (
    <form onSubmit={forgotPasswordGetMail} className="forgot-reset">
      <TextField
        fullWidth
        size="small"
        inputMode="email"
        id="outlined-basic"
        label="E-Posta Adresiniz"
        value={email}
        onChange={(e) => setEmail(e.target.value)}
        variant="outlined"
        placeholder="mail@mail.com"
        error={errorTextShow || correctEmail || errorTextS}
        helperText={
          errorTextShow
            ? "Email Adresinizi Giriniz"
            : correctEmail
            ? "Email Adresinizi Doğru Giriniz"
            : errorTextS
            ? errorText
            : ""
        }
      />
      {/* <div className="others-divider">
        <div className="others-divider-line"></div>
        <div className="others-divider-text">veya Kayıtlı Telefon Numaranız</div>
        <div className="others-divider-line"></div>
      </div>
      <PhoneInput
        country={"tr"}
        isValid={(value, country) => {
          if (value.match(/12345/)) {
            return "Invalid value: " + value + ", " + country.name;
          } else if (value.match(/1234/)) {
            return false;
          } else {
            return true;
          }
        }}
        inputProps={{
          name: "phone",
          required: true,
          autoFocus: true,
          id: "cor-phone-input",
          style: {
            height: "100%",
            width: "100%",
            minHeight: "40px",
            maxHeight: "40px",
          },
        }}
      /> */}
      {loading ? (
        <div style={{ display: "flex", width: "100%", justifyContent: "center" }}>
          <CircularProgress sx={{ height: "40px !important" }} />
        </div>
      ) : (
        <Button type="submit" className="iyzi-button-sea">
          Şifremi Sıfırla
        </Button>
      )}
      <ToastContainer />
    </form>
  );
};

export default ResetPassword;
