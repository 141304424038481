import Footer from "../components/beforeLogin/Footer";
import Header from "../components/beforeLogin/Header";
import FrequentQuestions from "../components/home/FrequentQuestions";
import GetFinish from "../components/home/GetFinish";
import IntegrationEMarket from "../components/home/IntegrationEMarket";
import IntegrationGetStart from "../components/home/IntegrationGetStart";
import IntegrationMarketplace from "../components/home/IntegrationMarketplace";
import { DynamicTitle } from "../utils/DynamicTitle";
// import PageTransition from "../utils/PageTransition";
import { Helmet } from "react-helmet";

const HomeIntegrations = () => {
  DynamicTitle("Ücretsiz E-Ticaret ve Pazaryeri Entegrasyonları | iyziShip");

  return (
    <>
      <Helmet>
        <meta
          name="title"
          content="Ücretsiz E-Ticaret ve Pazaryeri Entegrasyonları | iyziShip"
        />
        <meta
          name="description"
          content="Ücretsiz e-ticaret ve pazaryeri entegrasyonlarımızdan yararlanın, bu sayede tüm e-ihracat sürecinizi tek panelden yönetin."
        />
        <link rel="canonical" href="https://www.iyziship.com/entegrasyonlar"/>
      </Helmet>
      {/* <PageTransition> */}
        <Header />
        <main className="iyzi-main">
          <IntegrationGetStart />
          <IntegrationEMarket />
          <IntegrationMarketplace />
          <GetFinish />
        </main>

        <Footer />
      {/* </PageTransition> */}
    </>
  );
};

export default HomeIntegrations;
