import React from "react";

const HowInfoWorks = () => {
  return (
    <React.Fragment>
      <section className="main-info">
        <div className="info-container">
          <div className="info">
            <article>
              <h2>Satış kanalınızı entegre edin</h2>
              <p>
                Etsy, Amazon, eBay gibi yurt dışı pazaryerlerinde bulunan
                mağazanızı ya da Magento, Woocommerce, Shopify e-ticaret
                altyapınızı iyziShip paneline tek tıkla entegre edin. Bu sayede
                tüm lojistik sürecinizi tek panel üzerinden yönetin.
              </p>
            </article>
          </div>
          <div className="info-image">
            <img
              src="/images/home/how/3-solutions-with-integration.svg"
              alt="Satış kanalınızı entegre edin."
              loading="lazy"
            />
          </div>
        </div>
        <div className="info-container">
          <div className="info-image">
            <img
              src="/images/home/how/how-works-3.svg"
              alt="Gönderinizin mal bedelini girin."
              loading="lazy"
            />
          </div>
          <div className="info-image">
            <img
              src="/images/home/how/how-works-2.svg"
              alt="Gönderinizin adres bilgilerini girin."
              loading="lazy"
            />
          </div>
          <div className="info-image">
            <img
              src="/images/home/how/how-works-1.svg"
              alt="Gönderi tipinizi seçin."
              loading="lazy"
            />
          </div>
        </div>
        <div className="info-container">
          <div className="info-image last">
            <img
              src="/images/home/how/how-works-4.svg"
              alt="Gönderinizin ölçülerini ve ağırlığını girin."
              loading="lazy"
              
            />
          </div>
        </div>
        <div className="info-container">
          <div className="info">
            <article>
              <h2>Teklifleri karşılaştırın</h2>
              <p>
                Dünyanın önde gelen uluslararası kargo firmalarının yurt dışı
                gönderim tekliflerini karşılaştırın. Bütçenize en uygun seçeneği
                belirleyerek yurt dışı kargo talebinizi oluşturun.
              </p>
            </article>
          </div>
          <div className="info-image">
            <img
              src="/images/home/how/2-cargo-difference.svg"
              alt="Teklifleri karşılaştırın, zamandan tasarruf edin"
              loading="lazy"
            />
          </div>
        </div>
        <div className="info-container">
          <div>
            <h2>Kargonuzu iyziMerkez’e nasıl ulaştıracağınızı seçin</h2>
          </div>
        </div>
        <div className="info-container">
          <div className="info-image">
            <img
              src="/images/home/how/master-cargos.svg"
              alt="Online ödemenizi yapın"
              loading="lazy"
            />
          </div>
          <div className="info-image">
            <img
              src="/images/home/how/baby-cargos.svg"
              alt="Online ödemenizi yapın"
              loading="lazy"
            />
          </div>
        </div>
        <div className="info-container">
          <div className="step-header">
            <h2>1. ADIM</h2>
            <p>Paketleme tipini seçin.</p>
          </div>
        </div>
        <div className="info-container">
          <div className="info-image domestic-select">
            <img
              src="/images/home/how/cargo-style.svg"
              alt="Online ödemenizi yapın"
              loading="lazy"
            />
          </div>
        </div>
        <div className="info-container">
          <div className="step-header">
            <h2>2. ADIM</h2>
            <p>Gönderi tipinizi seçin.</p>
          </div>
        </div>
        <div className="info-container-brief">
          <div className="info-container">
            <div className="info-image-small">
              <img
                src="/images/home/how/deliver-myself.svg"
                alt="Online ödemenizi yapın"
                loading="lazy"
              />
            </div>
            <article>
              <p>
                <span>Kendim Getireceğim:</span> Gönderinizi ekonomik ve pratik
                bir şekilde iyziMerkez’e ulaştırmak istiyorsanız, "iyziMerkez’e
                Kendim Getireceğim" seçeneği tam size göre. Bu seçenekle, taşıma
                maliyetinden tasarruf ederek cüzdanınızı rahatlatırsınız.
                Gönderinizi bizzat getirerek hem sürece dahil olursunuz, hem de
                taşıma ücreti olarak ödeyeceğiniz tutar, ödül puan olarak
                bakiyenize iade edilir. Hızlı ve hesaplı bir başlangıç için
                ideal!
              </p>
            </article>
          </div>
        </div>
        <div className="info-container-brief">
          <div className="info-container">
            <div className="info-image-small">
              <img
                src="/images/home/how/catch-courier.svg"
                alt="Online ödemenizi yapın"
                loading="lazy"
              />
            </div>
            <article>
              <p>
                <span>iyziKurye Toplasın:</span> İstanbul'da konforunuzdan ödün
                vermeden gönderi yapmak istiyorsanız, "iyziKurye Toplasın"
                seçeneğini tercih edebilirsiniz. iyziKurye hizmet alanı içinde
                bulunan bölgelerde, ekibimiz adresinize gelerek gönderilerinizi
                bizzat toplar. Bu hizmet sayesinde, gönderinizi bizim için hazır
                hale getirmeniz yeterli; gerisini biz hallediyoruz. Hızlı,
                güvenilir ve kapınıza kadar gelen iyziKuryeler ile, İstanbul
                içindeki gönderileriniz artık çok daha konforlu ve stressiz.
              </p>
            </article>
          </div>
        </div>
        <div className="info-container-brief">
          <div className="info-container">
            <div className="info-image-small">
              <img
                src="/images/home/how/personal-deliver.svg"
                alt="Online ödemenizi yapın"
                loading="lazy"
              />
            </div>
            <article>
              <p>
                <span>Kendim Göndereceğim:</span> Gönderinizin nasıl ve hangi
                firma ile yola çıkacağına kendiniz karar vermek istiyorsanız,
                "Kendim Göndereceğim" seçeneği sizin için biçilmiş kaftan. Bu
                yöntem ile, gönderinizi tercih ettiğiniz bir taşıyıcı firma
                üzerinden bize iletme özgürlüğüne sahipsiniz. Üstelik, taşıma
                ücreti olarak ödeyeceğiniz tutarı, ödül puan olarak bakiyenize
                iade alırsınız. Kendi tercihlerinizle şekillenen bir gönderi
                deneyimi için mükemmel bir seçenek!
              </p>
            </article>
          </div>
        </div>
        <div className="info-container-brief">
          <div className="info-container">
            <div className="info-image-small">
              <img
                src="/images/home/how/other-companies-work-us.svg"
                alt="Online ödemenizi yapın"
                loading="lazy"
              />
            </div>
            <article>
              <p>
                <span>iyziShip Anlaşmalı Firmalar ile Gönder:</span>{" "}
                Gönderinizin iyziMerkez’e ulaşması için ekstra bir çaba sarf
                etmenize gerek yok. Anlaşmalı olduğumuz taşıyıcı firmalar ile,
                gönderilerinizi güven içinde ve hiçbir ek ücret ödemeden bize
                iletebilirsiniz. Rahatlık ve kolaylık arayanlar için birebir!
              </p>
            </article>
          </div>
        </div>
        <div className="info-container">
          <div className="info-image">
            <img
              src="/images/home/how/5-online-payment.svg"
              alt="Online ödemenizi yapın"
              loading="lazy"
            />
          </div>
          <div className="info">
            <article>
              <h2>Online ödemenizi yapın</h2>
              <p>
                Gönderi gereksinimlerinize en uygun teklifi seçtikten sonra,
                online ödemenizi kolayca gerçekleştirin. Dilerseniz ödemenizi
                iyziCüzdan bakiyenizle yapıp zamandan tasarruf edin.
              </p>
            </article>
          </div>
        </div>
        <div className="info-container">
          <div className="info-image">
            <img
              src="/images/home/how/6-tracking-shipment.png"
              alt="Gönderinizi takip edin"
              loading="lazy"
            />
          </div>
          <div className="info">
            <article>
              <h2>Gönderinizi takip edin</h2>
              <p>
                Yurt dışı kargonuzun konumunu ve durumunu anlık olarak takip
                edin; gönderilerinizle ilgili ayrıntılı raporlar elde edin.
              </p>
            </article>
          </div>
        </div>
      </section>
    </React.Fragment>
  );
};

export default HowInfoWorks;
