import { TableCell, TableHead, TableRow } from "@mui/material";

const SenderAddressTableHead = () => {
  return (
    <TableHead>
      <TableRow>
        {
          window.location.href.includes("create-fast-shipment") && (
            <TableCell sx={{ fontWeight: 700 }}>Seç</TableCell>
          )}
        <TableCell sx={{ fontWeight: 700 }}>Başlık</TableCell>
        <TableCell sx={{ fontWeight: 700 }}>Kontak</TableCell>
        <TableCell sx={{ fontWeight: 700 }}>Posta Kodu</TableCell>
        <TableCell sx={{ fontWeight: 700 }}>Telefon</TableCell>
        <TableCell sx={{ fontWeight: 700 }}>E-Posta</TableCell>
        <TableCell sx={{ fontWeight: 700 }}>Semt</TableCell>
        <TableCell sx={{ fontWeight: 700 }}>İlçe</TableCell>
        <TableCell sx={{ fontWeight: 700 }}>Şehir</TableCell>
        <TableCell sx={{ fontWeight: 700 }}>Ülke</TableCell>
        <TableCell sx={{ fontWeight: 700 }}>Adres Satırı</TableCell>
      </TableRow>
    </TableHead>
  );
};

export default SenderAddressTableHead;
