import { Divider, Modal, Button } from "@mui/material";
import PlagiarismOutlinedIcon from "@mui/icons-material/PlagiarismOutlined";
import { useRecoilState } from "recoil";
import { shipmentDetailModalState } from "../../../atoms/myShipments";
import CancelOutlinedIcon from "@mui/icons-material/CancelOutlined";
import FmdGoodIcon from "@mui/icons-material/FmdGood";
import MessageTime from "../../../utils/MessageTime";
import IsoToLatLong from "country-iso-to-coordinates";
import { ComposableMap, Geographies, Geography, Graticule, Line } from "react-simple-maps";
import features from "../../../json/features.json";

const ShipmentDetail = () => {
  const [shipmentDetailModal, setShipmentDetailModal] = useRecoilState(shipmentDetailModalState);

  const handleShipmentDetailClose = () => {
    setShipmentDetailModal({ status: false, data: [], shipment: [] });
  };

  const senderCountryCoordinates = IsoToLatLong[shipmentDetailModal.shipment?.sender_country]; // Kalkış Ülke kodu
  const receiverCountryCoordinates = IsoToLatLong[shipmentDetailModal.shipment?.receiver_country]; // Varış Ülke kodu

  const trackingCargo = shipmentDetailModal?.shipment?.get_parcel_supplier?.supplier_name;

  const cargoSites = [
    {
      name: "FEDEX",
      keyword: "FX",
      link: "https://www.fedex.com/fedextrack/?trknbr=",
    },
    {
      name: "HERMES",
      keyword: "",
      link: "https://www.myhermes.de/empfangen/sendungsverfolgung/?trackingId=",
    },
    {
      name: "FEDEX PAK",
      keyword: "FX",
      link: "https://www.fedex.com/fedextrack/?trknbr=",
    },
    {
      name: "UPS",
      keyword: "1Z",
      link: "https://www.ups.com/track?loc=tr_TR&tracknum=",
    },
    {
      name: "GLS",
      keyword: "500",
      link: "https://www.gls-pakete.de/en/parcel-tracking?trackingNumber=",
    },
    {
      name: "UPS DIRECT",
      keyword: "1Z1",
      link: "https://www.ups.com/track?loc=tr_TR&tracknum=",
    },
    {
      name: "UPS ECO",
      keyword: "1Z1",
      link: "https://www.ups.com/track?loc=tr_TR&tracknum=",
    },
    {
      name: "UPS EXPRESS",
      keyword: "1Z",
      link: "https://www.ups.com/track?loc=tr_TR&tracknum=",
    },
    {
      name: "UPS Saver",
      keyword: "1Z",
      link: "https://www.ups.com/track?loc=tr_TR&tracknum=",
    },
    {
      name: "DPD",
      keyword: "0150",
      link: "https://tracking.dpd.de/status/en_NL/parcel/",
    },
    {
      name: "ROYAL MAIL",
      keyword: "",
      link: "https://www.royalmail.com/track-your-item#/tracking-results/",
    },
    {
      name: "ROYAL MAIL-ORTA BOY KOLİ",
      keyword: "",
      link: "https://www.royalmail.com/track-your-item#/tracking-results/",
    },
    {
      name: "ROYAL MAIL-BÜYÜK BOY KOLİ",
      keyword: "",
      link: "https://www.royalmail.com/track-your-item#/tracking-results/",
    },
    {
      name: "ROYAL MAIL-KÜÇÜK BOY KOLİ",
      keyword: "",
      link: "https://www.royalmail.com/track-your-item#/tracking-results/",
    },
    {
      name: "ROYAL MAIL-ZARF",
      keyword: "",
      link: "https://www.royalmail.com/track-your-item#/tracking-results/",
    },
    {
      name: "DHL",
      keyword: "",
      link: "https://www.dhl.com/tr-tr/home/tracking.html?tracking-id=",
    },
    {
      name: "DHL ECO",
      keyword: "",
      link: "https://www.dhl.com/tr-tr/home/tracking/tracking-ecommerce.html?submit=1&tracking-id=",
    },
    {
      name: "USPS",
      keyword: "9400",
      link: "https://tools.usps.com/go/TrackConfirmAction?qtc_tLabels1=",
    },
    {
      name: "TNT",
      keyword: "9740",
      link: "https://www.tnt.com/express/tr_tr/site/shipping-tools/tracking.html?searchType=con&cons=",
    },
  ];

  const getTrackingLinkByCargo = (cargoName, trackingNumber) => {
    const selectedCargo = cargoSites.find((cargo) => cargo.name === cargoName);
    if (selectedCargo) {
      const trackingLink = selectedCargo.link + trackingNumber;
      window.open(trackingLink, "_blank");
    } else {
      alert("Belirtilen kargo firması için uygun bir takip linki bulunamadı.");
    }
  };

  return (
    <Modal open={shipmentDetailModal.status} onClose={handleShipmentDetailClose}>
      <div
        style={{
          maxWidth: "1100px",
          width: "100%",
          backgroundColor: "var(--lighterBg)",
          borderRadius: "var(--inputBorderRadius)",
          margin: "20px auto",
          outline: 0,
          padding: "20px",
          position: "relative",

          maxHeight: "90vh",
          overFlowY: "auto",
        }}
      >
        <CancelOutlinedIcon
          sx={{
            position: "absolute",
            right: 1,
            top: 1,
            color: "var(--priceText)",
            cursor: "pointer",
            transition: "all 80ms linear",
            ":hover": {
              color: "var(--notRed)",
              transition: "all 80ms linear",
            },
          }}
          onClick={handleShipmentDetailClose}
        />
        <h6
          style={{
            color: "var(--priceText)",
            display: "flex",
            alignItems: "center",
            gap: "5px",
            marginBottom: "20px",
          }}
        >
          <PlagiarismOutlinedIcon />
          Gönderi Takip
        </h6>
        <div
          style={{
            display: "flex",
            gap: "var(--gap)",
          }}
        >
          <div
            style={{
              width: "100%",
              display: "flex",
              justifyContent: "center",
              padding: "10px",
            }}
          >
            <div
              style={{
                border: "2px solid var(--info)",
                minHeight: "200px",
                maxWidth: "392px",
                width: "100%",
                borderRadius: "var(--inputBorderRadius)",
                padding: "20px",
              }}
            >
              <div style={{ color: "var(--shipmentLight)", fontSize: "14px" }}>
                Gönderi Numarası
              </div>
              <div
                style={{
                  color: "var(--priceText)",
                  fontWeight: 700,
                  fontSize: "18px",
                  margin: "5px 0",
                }}
              >
                {shipmentDetailModal.shipment?.parcel_ref_no}
              </div>
              <br />
              <div style={{ fontSize: "14px", marginBottom: "5px" }}>
                İzleme No; (Gönderinizi takip etmek için lütfen aşağıdaki takip numarasına
                tıklayınız.)
              </div>
              <Button
                className="iyzi-button-blue"
                onClick={() =>
                  getTrackingLinkByCargo(
                    trackingCargo,
                    shipmentDetailModal.shipment?.tracking_awb === null
                      ? "İzleme numarası bulunmamaktadır."
                      : shipmentDetailModal.shipment?.tracking_awb
                  )
                }
              >
                {shipmentDetailModal.shipment?.tracking_awb === null
                  ? "İzleme numarası bulunmamaktadır."
                  : shipmentDetailModal.shipment?.tracking_awb}
              </Button>
              <Divider
                sx={{
                  height: "1px",
                  backgroundColor: "var(--textPrice)",
                  width: "100%",
                  margin: "var(--gap) 0",
                }}
              />
              {shipmentDetailModal.data?.map((item, key) => (
                <div key={key}>
                  {key === 0 ? (
                    <div
                      style={{
                        display: "flex",
                        alignItems: "center",
                        gap: "var(--gap)",
                        marginBottom: "var(--gap)",
                      }}
                    >
                      <div
                        id="current-shipment-status"
                        style={{
                          backgroundColor: "#E8F9EE",
                          width: "38px",
                          height: "38px",
                          borderRadius: "50%",
                          display: "flex",
                          alignItems: "center",
                          justifyContent: "center",
                          minWidth: "38px",
                          maxWidth: "38px",
                        }}
                      >
                        <div
                          style={{
                            backgroundColor: "#0EBC93",
                            width: "14px",
                            height: "14px",
                            borderRadius: "50%",
                          }}
                        />
                      </div>
                      <p>
                        {MessageTime(item.date)} - {item.description}
                      </p>
                    </div>
                  ) : (
                    <div
                      style={{
                        display: "flex",
                        alignItems: "center",
                        gap: "var(--gap)",
                        marginBottom: "var(--gap)",
                      }}
                    >
                      <div
                        id="before-shipment-status"
                        style={{
                          background: "rgba(133, 185, 237, 0.2)",
                          width: "38px",
                          height: "38px",
                          minWidth: "38px",
                          maxWidth: "38px",
                          borderRadius: "50%",
                          display: "flex",
                          alignItems: "center",
                          justifyContent: "center",
                        }}
                      >
                        <FmdGoodIcon sx={{ color: "var(--info)" }} />
                      </div>
                      <p>
                        {MessageTime(item.date)} - {item.description}
                      </p>
                    </div>
                  )}
                </div>
              ))}
            </div>
          </div>
          <Divider
            sx={{
              width: "1px",
              backgroundColor: "var(--priceText)",
              opacity: 0.1,
            }}
          />
          <div style={{ width: "100%", padding: "10px" }}>
            <ComposableMap
              projection="geoEqualEarth"
              projectionConfig={{
                scale: 300,
                center: [0, 20],
              }}
            >
              <Graticule stroke="#DDD" />
              <Geographies geography={features} fill="#D6D6DA" stroke="#FFFFFF" strokeWidth={0.2}>
                {({ geographies }) =>
                  geographies.map((geo) => <Geography key={geo.rsmKey} geography={geo} />)
                }
              </Geographies>
              <Line
                from={[
                  Number(senderCountryCoordinates?.coordinate[1]),
                  Number(senderCountryCoordinates?.coordinate[0]),
                ]}
                to={[
                  Number(receiverCountryCoordinates?.coordinate[1]),
                  Number(receiverCountryCoordinates?.coordinate[0]),
                ]}
                stroke="#FF5533"
                strokeWidth={2}
                strokeLinecap="round"
              />
            </ComposableMap>
          </div>
        </div>
      </div>
    </Modal>
  );
};

export default ShipmentDetail;
