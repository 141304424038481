import { Checkbox, FormControlLabel, FormGroup, Tooltip } from "@mui/material";
import InfoOutlinedIcon from "@mui/icons-material/InfoOutlined";
import { mailPermissionState, smsPermissionState } from "../../../../atoms/membership";
import { useRecoilState } from "recoil";

const ProfileOptions = ({ disabled }) => {
  const user = JSON.parse(localStorage.getItem("user"));
  const memberID = user?.user?.user_member?.id;

  const [smsPermission, setSmsPermission] = useRecoilState(smsPermissionState);
  const [mailPermission, setMailPermission] = useRecoilState(mailPermissionState);

  const handleSmsPermChange = () => {
    setSmsPermission(!smsPermission);
  };

  const handleMailPermChange = () => {
    setMailPermission(!mailPermission);
  };

  return (
    <div className="profile-options">
      <div className="profile-id">
        <svg
          width="55"
          height="47"
          viewBox="0 0 55 47"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <g clipPath="url(#clip0_6161_21971)">
            <path
              d="M27.2676 46.7903C40.1738 46.7903 50.6364 36.3629 50.6364 23.5001C50.6364 10.6373 40.1738 0.209961 27.2676 0.209961C14.3615 0.209961 3.89893 10.6373 3.89893 23.5001C3.89893 36.3629 14.3615 46.7903 27.2676 46.7903Z"
              fill="#251C79"
            />
            <path
              d="M27.2677 0.419643C40.0581 0.419643 50.4259 10.7526 50.4259 23.5C50.4259 36.2474 40.0581 46.5804 27.2677 46.5804C14.4774 46.5804 4.10953 36.2474 4.10953 23.5C4.10953 10.7526 14.4788 0.419643 27.2677 0.419643ZM27.2677 0C20.9701 0 15.0486 2.44512 10.5952 6.88354C6.14184 11.322 3.68848 17.2235 3.68848 23.5C3.68848 29.7765 6.14184 35.678 10.5952 40.1165C15.0486 44.5549 20.9701 47 27.2677 47C33.5653 47 39.4868 44.5549 43.9402 40.1165C48.3936 35.678 50.847 29.7765 50.847 23.5C50.847 17.2235 48.3936 11.322 43.9402 6.88354C39.4882 2.44512 33.5668 0 27.2677 0Z"
              fill="black"
            />
            <path
              d="M27.2819 41.3517C37.0449 41.3517 44.9593 33.4639 44.9593 23.7337C44.9593 14.0036 37.0449 6.11572 27.2819 6.11572C17.5189 6.11572 9.60449 14.0036 9.60449 23.7337C9.60449 33.4639 17.5189 41.3517 27.2819 41.3517Z"
              fill="#F5AF02"
            />
            <path
              d="M27.282 6.32394C36.9284 6.32394 44.7489 14.1181 44.7489 23.7321C44.7489 33.3461 36.9284 41.1403 27.282 41.1403C17.6356 41.1403 9.8151 33.3461 9.8151 23.7321C9.8151 14.1181 17.6356 6.32394 27.282 6.32394ZM27.282 5.9043C22.5044 5.9043 18.0117 7.75912 14.6334 11.1261C11.2551 14.493 9.39404 18.9706 9.39404 23.7321C9.39404 28.4937 11.2551 32.9713 14.6334 36.3382C18.0117 39.7051 22.5044 41.56 27.282 41.56C32.0596 41.56 36.5523 39.7051 39.9306 36.3382C43.3089 32.9713 45.1699 28.4937 45.1699 23.7321C45.1699 18.9706 43.3089 14.493 39.9306 11.1261C36.5523 7.75912 32.0596 5.9043 27.282 5.9043Z"
              fill="black"
            />
            <path
              d="M27.2815 39.8161C36.2593 39.8161 43.5372 32.5626 43.5372 23.6151C43.5372 14.6675 36.2593 7.41406 27.2815 7.41406C18.3038 7.41406 11.0259 14.6675 11.0259 23.6151C11.0259 32.5626 18.3038 39.8161 27.2815 39.8161Z"
              fill="white"
            />
            <path
              d="M27.2816 7.62228C36.1435 7.62228 43.3267 14.7814 43.3267 23.6135C43.3267 32.4456 36.1435 39.6047 27.2816 39.6047C18.4197 39.6047 11.2365 32.4456 11.2365 23.6135C11.2365 14.7814 18.4197 7.62228 27.2816 7.62228ZM27.2816 7.20264C22.883 7.20264 18.7482 8.90918 15.6379 12.0089C12.5277 15.1087 10.8154 19.2296 10.8154 23.6135C10.8154 27.9973 12.5277 32.1182 15.6379 35.218C18.7482 38.3178 22.883 40.0243 27.2816 40.0243C31.6803 40.0243 35.815 38.3178 38.9253 35.218C42.0355 32.1182 43.7478 27.9973 43.7478 23.6135C43.7478 19.2296 42.0355 15.1087 38.9253 12.0089C35.815 8.90918 31.6803 7.20264 27.2816 7.20264Z"
              fill="black"
            />
            <path
              d="M0.210449 33.0373L3.8105 12.7588H11.5552L7.95511 33.0373H0.210449Z"
              fill="#E63262"
              stroke="black"
              strokeWidth="0.5"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
            <path
              d="M4.27063 8.46989C4.47133 13.6511 13.2462 12.2145 13.0623 7.30188C12.7872 2.01159 4.08115 3.57266 4.27063 8.46989Z"
              fill="#E63262"
              stroke="black"
              strokeWidth="0.5"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
            <path
              d="M22.9928 41.2564C20.958 42.777 18.3481 43.5735 15.2175 43.6112H14.9838C11.4786 43.6112 8.87945 42.6933 7.40875 41.0189C6.16926 39.6071 5.67831 37.6461 5.99381 35.1518H13.0263C13.0038 35.8537 13.1753 36.4403 13.5739 36.8625L13.574 36.8626C14.0998 37.4191 14.9733 37.6543 16.1543 37.6543C17.2716 37.6543 18.1665 37.3493 18.8392 36.6749C19.5047 36.0079 19.9226 35.0093 20.1563 33.6839C20.1563 33.6838 20.1563 33.6836 20.1564 33.6835L20.5661 31.4043L20.7233 30.5295L20.1331 31.194C19.3231 32.1059 18.5137 32.65 17.6708 32.9688C16.8252 33.2886 15.931 33.3873 14.9417 33.3873C12.1774 33.3873 10.1708 32.613 8.92216 31.1331L8.92211 31.133C7.4728 29.4162 7.01384 26.758 7.63971 23.1687C7.63974 23.1685 7.63977 23.1684 7.6398 23.1682L9.46473 12.9541H16.8623L15.0859 23.0048C15.0859 23.005 15.0858 23.0053 15.0858 23.0055C14.9793 23.5885 14.9008 24.2843 14.9477 24.9553C14.9944 25.6234 15.1674 26.2955 15.5907 26.801L15.5907 26.801C16.0705 27.3738 16.7987 27.643 17.7052 27.643C19.1113 27.643 20.0494 26.9144 20.6612 25.9959C21.2668 25.0867 21.5623 23.9809 21.7072 23.1706L21.7072 23.1706L23.5323 12.9555H31.0125L27.3511 33.5642L27.351 33.5648C26.7562 36.9658 25.2845 39.5448 22.9929 41.2563L22.9928 41.2564Z"
              fill="white"
              stroke="black"
              strokeWidth="0.5"
            />
            <path
              d="M31.1442 12.8441L27.4599 33.5815C26.2037 40.7574 21.1384 43.7215 14.984 43.7215C8.82949 43.7215 5.10453 40.9672 5.89893 35.0433H13.1425C13.0162 37.1303 14.5657 37.5457 16.1559 37.5457C18.584 37.5457 19.631 36.0448 20.0493 33.6654L20.5517 30.8692C18.9194 32.9143 17.245 33.499 14.9418 33.499C8.36773 33.499 6.4842 29.1599 7.53123 23.1506L9.37266 12.8441H16.9924L15.1931 23.0247C14.7748 25.3201 15.1089 27.5317 17.7054 27.5317C20.3019 27.5317 21.2633 25.0278 21.5988 23.1506L23.4402 12.8441H31.1442ZM31.1442 12.5644H23.4402C23.3041 12.5644 23.1876 12.6623 23.1637 12.7952L21.3223 23.1016C20.984 24.9956 20.0816 27.2519 17.7054 27.2519C16.8913 27.2519 16.2794 27.0155 15.8892 26.5497C15.1734 25.695 15.2661 24.1885 15.4682 23.075L17.2689 12.8917C17.2829 12.8106 17.2605 12.7266 17.2071 12.6637C17.1538 12.6008 17.0752 12.5644 16.9924 12.5644H9.37266C9.23651 12.5644 9.12002 12.6623 9.09616 12.7952L7.25473 23.1016C6.61613 26.7665 7.07649 29.5529 8.62317 31.3854C9.96354 32.973 12.0885 33.7773 14.9404 33.7773C17.1608 33.7773 18.7201 33.194 20.097 31.8022L19.7714 33.6151C19.3265 36.1399 18.2107 37.2646 16.1531 37.2646C15.0331 37.2646 14.2822 37.045 13.8569 36.5945C13.5243 36.242 13.3811 35.7441 13.419 35.0712C13.419 35.0614 13.4204 35.0503 13.4204 35.0405C13.4204 34.8866 13.2955 34.7607 13.1397 34.7607H5.89472C5.75436 34.7607 5.63507 34.8642 5.61682 35.0027C5.26453 37.6325 5.767 39.7419 7.11157 41.2736C8.67651 43.0556 11.3979 43.9984 14.9797 43.9984C18.2865 43.9984 21.0598 43.1787 23.2227 41.5645C25.6044 39.7852 27.1217 37.1149 27.7322 33.6277L31.4136 12.9057C31.4179 12.8847 31.4207 12.8637 31.4207 12.8428C31.4207 12.6889 31.2957 12.563 31.14 12.563L31.1442 12.5644Z"
              fill="black"
            />
            <path
              d="M35.8026 18.8242H28.7846L29.8344 12.9526H46.9021L46.47 15.3801L36.8092 26.5594L36.4519 26.9729H36.9984H44.8108L43.6764 33.3467H25.4368L25.8276 31.1237L35.9926 19.2367L36.3453 18.8242H35.8026Z"
              fill="#F5AF02"
              stroke="black"
              strokeWidth="0.5"
            />
            <path
              d="M47.0365 12.8441L46.5761 15.4305L36.6967 26.863H44.9452L43.7719 33.4556H25.3099L25.7281 31.0762L36.1114 18.9346H28.6587L29.7478 12.8428H47.0379M47.0379 12.563H29.7478C29.6117 12.563 29.4952 12.6595 29.4713 12.7938L28.3822 18.8856C28.3682 18.9667 28.3906 19.0507 28.444 19.115C28.4973 19.178 28.5759 19.2157 28.6587 19.2157H35.5037L25.5148 30.8958C25.4825 30.9336 25.4601 30.9797 25.4516 31.0287L25.0334 33.408C25.0194 33.4892 25.0418 33.5731 25.0951 33.6361C25.1485 33.699 25.2271 33.7354 25.3099 33.7354H43.7719C43.908 33.7354 44.0245 33.6375 44.0484 33.5046L45.2189 26.926C45.2231 26.905 45.2259 26.884 45.2259 26.863C45.2259 26.7091 45.101 26.5833 44.9452 26.5833H37.3086L46.7895 15.6124C46.8217 15.5746 46.8442 15.5285 46.8526 15.4781L47.313 12.8917C47.327 12.8106 47.3046 12.7266 47.2512 12.6637C47.1979 12.6008 47.1193 12.563 47.0365 12.563H47.0379Z"
              fill="black"
            />
            <path
              d="M41.936 33.5379L45.6244 12.7586L53.2652 12.7782L49.6792 33.5379H41.9346H41.936ZM45.9964 8.21109C45.9964 10.3387 47.755 11.4661 49.7213 11.4661C52.1087 11.4661 54.7881 9.9638 54.7881 7.04309C54.7881 4.87353 53.0294 3.74609 51.1038 3.74609C48.6757 3.74609 45.9964 5.29038 45.9964 8.21109Z"
              fill="#86B817"
              stroke="black"
              strokeWidth="0.5"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
            <path
              d="M26.9589 42.395L27.3912 43.2679L28.3569 43.4078L27.6579 44.0862L27.8221 45.0458L26.9589 44.5926L26.0944 45.0458L26.26 44.0862L25.561 43.4078L26.5267 43.2679L26.9589 42.395Z"
              fill="white"
            />
            <path
              d="M36.8564 40.3223L37.2887 41.1937L38.2543 41.3336L37.5554 42.0134L37.721 42.9716L36.8564 42.5198L35.9918 42.9716L36.1575 42.0134L35.4585 41.3336L36.4241 41.1937L36.8564 40.3223Z"
              fill="white"
            />
            <path
              d="M43.3266 34.7383L43.7589 35.6111L44.7245 35.751L44.0256 36.4294L44.1912 37.389L43.3266 36.9358L42.4635 37.389L42.6277 36.4294L41.9287 35.751L42.8943 35.6111L43.3266 34.7383Z"
              fill="white"
            />
          </g>
          <defs>
            <clipPath id="clip0_6161_21971">
              <rect width="55" height="47" fill="white" />
            </clipPath>
          </defs>
        </svg>
        <span>
          <strong>ID: </strong>
          {memberID}
        </span>
        <Tooltip title="iyziDünyasında geçerli üyelik numaranız">
          <InfoOutlinedIcon />
        </Tooltip>
      </div>
      <FormGroup>
        <FormControlLabel
          disabled={disabled()}
          control={<Checkbox checked={smsPermission} onChange={handleSmsPermChange} />}
          label={
            <div>
              iyziShip’den <strong>Sms</strong> almak istiyorum
            </div>
          }
        />
        <FormControlLabel
          disabled={disabled()}
          control={<Checkbox checked={mailPermission} onChange={handleMailPermChange} />}
          label={
            <div>
              iyziShip’den <strong>Mail</strong> almak istiyorum
            </div>
          }
        />
      </FormGroup>
    </div>
  );
};

export default ProfileOptions;
