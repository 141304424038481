import "../../../style/profileSettings/CompanyInfoWrapper.css";
import { Box } from "@mui/material";
import CorporateDropzone from "./CorporateDropzone";
import InfoIcon from "@mui/icons-material/Info";
import { useRecoilValue } from "recoil";
import {
  membershipDocumentsState,
  profileCompanyTypeState,
} from "../../../atoms/membership";
import CheckCircleOutlineOutlinedIcon from "@mui/icons-material/CheckCircleOutlineOutlined";
import PendingOutlinedIcon from "@mui/icons-material/PendingOutlined";
import CancelOutlinedIcon from "@mui/icons-material/CancelOutlined";
import GetFile from "../../../utils/GetFile";

const CorporateUploader = () => {
  const profileCompanyType = useRecoilValue(profileCompanyTypeState);
  const membershipDocuments = useRecoilValue(membershipDocumentsState);

  return (
    <Box className="company-info-wrapper">
      <Box className="company-info-dropzone">
        <InfoIcon
          sx={{ width: "16px", height: "16px", color: "var(--btnBgBlue)" }}
        />
        <div className="info-bold">Yüklenmesi gereken evraklar: </div>
        {profileCompanyType === "Individual" ? (
          <div className="info-text">Vergi Levhası, Kimlik Görüntüsü</div>
        ) : (
          <div className="info-text">
            Vergi Levhası, İmza Sirküleri, Sicil Gazetesi, Kimlik Görüntüsü,
            Faaliyet Belgesi
          </div>
        )}
      </Box>
      <CorporateDropzone />
      {membershipDocuments?.length !== 0 && (
        <h6 style={{ fontSize: "16px", color: "var(--priceText)" }}>
          Yüklenen Dosyalar - {membershipDocuments?.length} Adet
        </h6>
      )}
      {membershipDocuments?.map((document, key) => (
        <div
          style={{
            display: "flex",
            alignItems: "center",
            gap: "var(--gap)",
            boxShadow: "var(--shadow)",
            padding: "10px",
            borderRadius: "var(--inputBorderRadius)",
          }}
          key={key}
        >
          <div
            style={{
              display: "flex",
              alignItems: "center",
              gap: "4px",
              minWidth: "125px",
            }}
          >
            {Number(document.status) === 0 && (
              <>
                <CancelOutlinedIcon sx={{ color: "var(--shipmentRed)" }} />
                <span style={{ fontSize: "13px", color: "var(--shipmentRed)" }}>
                  Reddedildi
                </span>
              </>
            )}
            {Number(document.status) === 1 && (
              <>
                <PendingOutlinedIcon sx={{ color: "var(--shipmentYellow)" }} />
                <span
                  style={{ fontSize: "13px", color: "var(--shipmentYellow)" }}
                >
                  Onay Bekleniyor
                </span>
              </>
            )}
            {Number(document.status) === 2 && (
              <>
                <CheckCircleOutlineOutlinedIcon
                  sx={{ color: "var(--shipmentGreen)" }}
                />
                <span
                  style={{ fontSize: "13px", color: "var(--shipmentGreen)" }}
                >
                  Onaylandı
                </span>
              </>
            )}
          </div>
          <Box
            sx={{
              borderRadius: "var(--wrapperBorderRadius)",
              fontSize: "13px",
              cursor: "pointer",
              color: "var(--priceText)",
              transition: "all 150ms linear",
              ":hover": {
                backgroundColor: "var(--pastelBlue)",
                transition: "all 150ms linear",
              },
            }}
            key={document.id}
            onClick={() => GetFile(document)}
          >
            <span
              style={{
                fontSize: "13px",
                color: "var(--priceText)",
                fontWeight: "700",
              }}
            >
              {key + 1}
            </span>{" "}
            - {document.name} /{" "}
            {document.created_at.slice(0, 10).replaceAll("-", "/")} -{" "}
            {document.created_at.slice(11, 19)}
          </Box>
          -{" "}
          {document?.type === "tax_plate"
            ? "Vergi Levhası"
            : document?.type === "identity"
            ? "Kimlik Görüntüsü"
            : document?.type === "signature_circulars"
            ? "İmza Sirküleri"
            : document?.type === "trade_registry_gazette"
            ? "Sicil Gazetesi"
            : document?.type === "operating_certificate"
            ? "Faaliyet Belgesi"
            : ""}
        </div>
      ))}
    </Box>
  );
};

export default CorporateUploader;
