import {
  Button,
  CircularProgress,
  createTheme,
  FormControl,
  FormHelperText,
  InputLabel,
  MenuItem,
  Modal,
  Select,
  TextField,
  ThemeProvider,
} from "@mui/material";
import { useEffect, useRef, useState } from "react";
import { toast } from "react-toastify";
import { useRecoilState, useRecoilValue, useSetRecoilState } from "recoil";
import axios from "../../../api/axios";
import { integrationsState, newIossNumberAnimationState } from "../../../atoms/membership";
import SpringIn from "../../profilesettings/addressInfo/SpringIn";
import { arrivalCountryCodeState, proformaModalState } from "../../../atoms/createShipment";
import Errors from "../../../utils/Errors";

const theme = createTheme({
  components: {
    MuiSelect: {
      styleOverrides: {
        select: {
          display: "flex",
        },
      },
    },
  },
});

const CreateFastIOSSNumber = () => {
  const [newIossNumberAnimation, setNewIossNumberAnimation] = useRecoilState(
    newIossNumberAnimationState
  );
  const setProformaModal = useSetRecoilState(proformaModalState);
  const [integrations, setIntegrations] = useRecoilState(integrationsState);
  const arrivalCountryCode = useRecoilValue(arrivalCountryCodeState);

  const [marketplace, setMarketplace] = useState("");
  const [iossNumber, setIossNumber] = useState("");
  const [marketplaceIsNotSelected, setMarketplaceIsNotSelected] = useState(false);
  const [iossNumberIsNotSelected, setIossNumberIsNotSelected] = useState(false);
  const [loading, setLoading] = useState(false);

  const iossNumberRef = useRef();
  const marketplaceRef = useRef();

  const iossNumberRegisterHandler = (e) => {
    e?.preventDefault();

    if (arrivalCountryCode === "GB") {
      if (iossNumber.length !== 9) {
        setIossNumberIsNotSelected(true);
        return;
      }
    } else {
      if (iossNumber.length !== 12) {
        setIossNumberIsNotSelected(true);
        return;
      }
    }

    iossNumber.length !== 0 ? setIossNumberIsNotSelected(false) : setIossNumberIsNotSelected(true);

    marketplace.length !== 0
      ? setMarketplaceIsNotSelected(false)
      : setMarketplaceIsNotSelected(true);

    if (iossNumber.length !== 0 && marketplace.length !== 0) {
      setLoading(true);
      axios
        .post("member-ioss-number/add", {
          market_place: marketplace,
          ioss_number: iossNumber,
        })
        .then((response) => {
          toast.success("IOSS Numarası Başarıyla Eklendi", { autoClose: 500 });
          setIntegrations([response.data.data, ...integrations]);
        })
        .catch((error) => Errors(error))
        .finally(() => {
          setLoading(false);
          handleClose();
        });
    }
  };

  const handleClose = () => {
    setMarketplace("");
    setIossNumber("");
    setMarketplaceIsNotSelected(false);
    setIossNumberIsNotSelected(false);
    setNewIossNumberAnimation(false);
    setProformaModal(false);
  };

  useEffect(() => {
    if (iossNumber.length !== 0) {
      setIossNumberIsNotSelected(false);
    }

    if (marketplace.length !== 0) {
      setMarketplaceIsNotSelected(false);
    }
  }, [iossNumber, marketplace]);

  return (
    <Modal
      sx={{ display: "flex", alignItems: "center", justifyContent: "center" }}
      open={newIossNumberAnimation}
      onClose={handleClose}
    >
      <div
        style={{
          backgroundColor: "var(--lighterBg)",
          borderRadius: "var(--wrapperBorderRadius)",
          outline: "none",
          maxWidth: "600px",
          width: "100%",
          padding: "20px",
          maxHeight: "90vh",
          overFlowY: "auto",
        }}
      >
        {newIossNumberAnimation && (
          <ThemeProvider theme={theme}>
            <SpringIn>
              <form
                className="create-ioss-number"
                style={{
                  display: "flex",
                  flexDirection: "column",
                  gap: "var(--gap2x)",
                  marginTop: "var(--gap2x)",
                }}
                onSubmit={iossNumberRegisterHandler}
              >
                <div
                  style={{
                    display: "grid",
                    gap: "var(--gap2x)",
                    gridTemplateColumns: "repeat(auto-fit, minmax(150px , 1fr))",
                  }}
                >
                  <FormControl fullWidth required size="small" error={marketplaceIsNotSelected}>
                    <InputLabel id="select-marketplace">Platform</InputLabel>
                    <Select
                      value={marketplace}
                      label="Platform"
                      ref={marketplaceRef}
                      labelId="select-marketplace"
                      autoFocus
                      onChange={(e) => setMarketplace(e.target.value)}
                    >
                      <MenuItem value="">
                        <em>Temizle</em>
                      </MenuItem>
                      {arrivalCountryCode === "GB" ? (
                        <MenuItem value={"etsy_gb"}>
                          <div
                            style={{
                              width: "50px",
                              display: "flex",
                              justifyContent: "center",
                              marginRight: "5px",
                            }}
                          >
                            <img
                              style={{
                                height: "20px",
                              }}
                              src="/images/integrations/etsy_gb.svg"
                              alt="Etsy Marka Görseli"
                            />
                          </div>{" "}
                          Etsy (UK)
                        </MenuItem>
                      ) : (
                        <MenuItem value={"etsy"}>
                          <div
                            style={{
                              width: "50px",
                              display: "flex",
                              justifyContent: "center",
                              marginRight: "5px",
                            }}
                          >
                            <img
                              style={{
                                height: "20px",
                              }}
                              src="/images/integrations/etsy.svg"
                              alt="Etsy Marka Görseli"
                            />
                          </div>{" "}
                          Etsy (EU)
                        </MenuItem>
                      )}

                      <MenuItem value={"amazon"}>
                        <div
                          style={{
                            width: "50px",
                            display: "flex",
                            justifyContent: "center",
                            marginRight: "5px",
                          }}
                        >
                          <img
                            style={{
                              height: "20px",
                            }}
                            src="/images/integrations/amazon.svg"
                            alt="Amazon Marka Görseli"
                          />
                        </div>{" "}
                        Amazon
                      </MenuItem>
                      <MenuItem value={"aliexpress"}>
                        <div
                          style={{
                            width: "50px",
                            display: "flex",
                            justifyContent: "center",
                            marginRight: "5px",
                          }}
                        >
                          <img
                            style={{
                              height: "20px",
                            }}
                            src="/images/integrations/aliexpress.svg"
                            alt="AliExpress Marka Görseli"
                          />
                        </div>{" "}
                        AliExpress
                      </MenuItem>
                      <MenuItem value={"wish"}>
                        <div
                          style={{
                            width: "50px",
                            display: "flex",
                            justifyContent: "center",
                            marginRight: "5px",
                          }}
                        >
                          <img
                            style={{
                              height: "20px",
                            }}
                            src="/images/integrations/wish.svg"
                            alt="Wish Marka Görseli"
                          />
                        </div>{" "}
                        Wish
                      </MenuItem>
                    </Select>
                    {marketplaceIsNotSelected ? (
                      <FormHelperText>Marketplace seçmeniz gerekmektedir.</FormHelperText>
                    ) : (
                      ""
                    )}
                  </FormControl>
                  <TextField
                    fullWidth
                    required
                    size="small"
                    autoComplete="off"
                    label="IOSS Numarası"
                    ref={iossNumberRef}
                    error={iossNumberIsNotSelected}
                    helperText={
                      iossNumberIsNotSelected
                        ? arrivalCountryCode === "GB" && marketplace === "etsy_gb"
                          ? "IOSS numarası 9 hanelidir."
                          : "IOSS numarası 12 hanelidir."
                        : ""
                    }
                    value={iossNumber}
                    inputProps={{ maxLength: marketplace === "etsy_gb" ? 9 : 12 }}
                    onChange={(e) =>
                      setIossNumber(e.target.value.replace(/\s+/g, "").toUpperCase())
                    }
                  />
                </div>
                <div
                  style={{
                    gap: "var(--gap)",
                  }}
                  className="new-address-register"
                >
                  {loading ? (
                    <div
                      style={{
                        width: "108px",
                        display: "flex",
                        justifyContent: "center",
                      }}
                    >
                      <CircularProgress
                        sx={{
                          width: "33px !important",
                          height: "33px !important",
                        }}
                      />
                    </div>
                  ) : (
                    <Button sx={{ height: "35px" }} type="submit" className="iyzi-button-blue">
                      Kaydet
                    </Button>
                  )}
                  <Button
                    type="button"
                    sx={{ height: "35px" }}
                    className="iyzi-button-blue"
                    onClick={handleClose}
                  >
                    İptal
                  </Button>
                </div>
              </form>
            </SpringIn>
          </ThemeProvider>
        )}
      </div>
    </Modal>
  );
};

export default CreateFastIOSSNumber;
