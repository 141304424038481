export const FormatReturnInteger = (value) => {
  // Rakamlardan başka bir şey içerip içermediğini kontrol edin
  const isNumeric = /^[0-9]+$/.test(value);

  // Sadece sayısal değerler içeriyorsa, inputu döndür
  if (isNumeric) {
    return value;
  }

  // Sayısal olmayan bir değer içeriyorsa, boş string veya istediğiniz bir değeri döndürün
  return "";
};

export const FormatReturnFieldWithTwoDecimals = (val) => {
  let value = val.replace(/,/g, ".");

  // Sayısal olmayan karakterleri ve fazladan başlangıç sıfırlarını kaldır
  value = value.replace(/[^0-9.]|(?<=\..*)\.|^0+(?=[.])/g, "");

  // Ondalık noktadan sonra en fazla iki basamağa izin ver
  const parts = value.split(".");
  if (parts.length > 1) {
    parts[1] = parts[1].substring(0, 2); // Ondalık kısmı iki basamağa kısıtla
    value = parts.join(".");
  }

  return value;
};

export const FormatReturnFieldWithTwoDecimalsWithMaxNumber = (val, maxNumber) => {
  let value = val.replace(/,/g, ".");

  // Sayısal olmayan karakterleri ve fazladan başlangıç sıfırlarını kaldır
  value = value.replace(/[^0-9.]|(?<=\..*)\.|^0+(?=[.])/g, "");

  // Ondalık noktadan sonra en fazla iki basamağa izin ver
  const parts = value.split(".");
  if (parts.length > 1) {
    parts[1] = parts[1].substring(0, 2); // Ondalık kısmı iki basamağa kısıtla
    value = parts.join(".");
  }

  if (Number(value) > maxNumber) {
    return maxNumber;
  } else return value;
};
