import {
  Autocomplete,
  Box,
  Button,
  Divider,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  TextField,
} from "@mui/material";
import { useRecoilState, useSetRecoilState } from "recoil";
import {
  activeStepState,
  arrivalCountryCodeState,
  arrivalCountryState,
  dimensionArrayState,
  docOrPackModalState,
  documentMaxGrossState,
  overPayDesignState,
  productCurrencyState,
  productValueState,
  senderCreateCountryCodeState,
  senderCreateCountryState,
  senderZipCodeState,
  shipmentDocumentState,
  shipmentPriceArrayState,
  shipmentReceiverCityState,
  shipmentReceiverDistrictState,
  shipmentReceiverStateCodeState,
  shipmentReceiverStateState,
  shipmentSenderCityState,
  shipmentSenderDistrictState,
  shipmentSenderStateCodeState,
  shipmentSenderStateState,
  shipmentZipCodeState,
  isAbove150EURCheckState,
  checkedCustomsState
} from "../../atoms/createShipment";
import ErrorOutlineIcon from "@mui/icons-material/ErrorOutline";
import React, { useCallback, useState } from "react";
import axios from "../../api/axios";
import Inventory2OutlinedIcon from "@mui/icons-material/Inventory2Outlined";
import SummarizeOutlinedIcon from "@mui/icons-material/SummarizeOutlined";
import { useEffect } from "react";
import InfoOutlinedIcon from "@mui/icons-material/InfoOutlined";
import CountryRestrictInfo from "./shipmentInfo/CountryRestrictInfo";
import CountriesTurkishSorted from "../../utils/CountriesTurkishSorted";
import { FormatFieldWithTwoDecimals } from "../../utils/FormatField";
import { EngCharUpperCase } from "../../utils/UpperCase";

const ShipmentInfo = () => {
  const [countryRestrictInfo, setCountryRestrictInfo] = useState(false);
  const [productValueError, setProductValueError] = useState(false);
  const [productValueHelperText, setProductValueHelperText] = useState("");

  const [overPayDesign, setOverPayDesign] = useRecoilState(overPayDesignState);
  const [shipmentDocument, setShipmentDocument] = useRecoilState(shipmentDocumentState);
  const [arrivalCountry, setArrivalCountry] = useRecoilState(arrivalCountryState);
  const [senderCreateCountry, setSenderCreateCountry] = useRecoilState(senderCreateCountryState);
  const [senderCreateCountryCode, setSenderCreateCountryCode] = useRecoilState(
    senderCreateCountryCodeState
  );
  const [senderZipCode, setSenderZipCode] = useRecoilState(senderZipCodeState);
  const [arrivalCountryCode, setArrivalCountryCode] = useRecoilState(arrivalCountryCodeState);
  const [productValue, setProductValue] = useRecoilState(productValueState);
  const [productCurrency, setProductCurrency] = useRecoilState(productCurrencyState);
  const [documentMaxGross, setDocumentMaxGross] = useRecoilState(documentMaxGrossState);
  const [shipmentZipCode, setShipmentZipCode] = useRecoilState(shipmentZipCodeState);

  const setDimensionArray = useSetRecoilState(dimensionArrayState);
  const setShipmentPriceArray = useSetRecoilState(shipmentPriceArrayState);
  const setActiveStep = useSetRecoilState(activeStepState);
  const setShipmentReceiverState = useSetRecoilState(shipmentReceiverStateState);
  const setShipmentReceiverDistrict = useSetRecoilState(shipmentReceiverDistrictState);
  const setShipmentReceiverCity = useSetRecoilState(shipmentReceiverCityState);
  const setShipmentReceiverStateCode = useSetRecoilState(shipmentReceiverStateCodeState);
  const setShipmentSenderState = useSetRecoilState(shipmentSenderStateState);
  const setShipmentSenderDistrict = useSetRecoilState(shipmentSenderDistrictState);
  const setShipmentSenderCity = useSetRecoilState(shipmentSenderCityState);
  const setShipmentSenderStateCode = useSetRecoilState(shipmentSenderStateCodeState);
  const setDocOrPackModal = useSetRecoilState(docOrPackModalState);
  const setIsAbove150EURCheck = useSetRecoilState(isAbove150EURCheckState);
  const setCheckedCustoms = useSetRecoilState(checkedCustomsState);

  const handleChangeZipCode = (e) => {
    const value = EngCharUpperCase(e.target.value);
    setShipmentZipCode(value);
  };

  const handleDocOrPack = useCallback(() => {
    setDocOrPackModal(true);
  }, [setDocOrPackModal]);

  const handleSelectCountry = useCallback(
    (e) => {
      setArrivalCountry(e.country_name_tr);
      setArrivalCountryCode(e.iso_code);
      setShipmentZipCode("");
    },
    [setArrivalCountry, setArrivalCountryCode, setShipmentZipCode]
  );

  const handleSenderCountry = useCallback(
    (e) => {
      setSenderCreateCountry(e.country_name_tr);
      setSenderCreateCountryCode(e.iso_code);
    },
    [setSenderCreateCountry, setSenderCreateCountryCode]
  );

  const handleDocument = useCallback(
    (e) => {
      setShipmentDocument(e.target.value);
      if (e.target.value === "D") {
        setDimensionArray([]);
        setShipmentPriceArray([]);
        setProductValue("");
      }
    },
    [setDimensionArray, setShipmentDocument, setShipmentPriceArray, setProductValue]
  );

  const handleProductCostChange = (e) => {
    setProductValueError(false);
    setProductValueHelperText(false);
    FormatFieldWithTwoDecimals(e, setProductValue);
  };

  const handleProductCostBlur = () => {
    const value = parseFloat(productValue);
    if (value < 1) {
      setProductValueError(true);
      setProductValueHelperText("Bedel olarak en az 1 değerini girebilirsiniz.");
    } else {
      setProductValueError(false);
      setProductValueHelperText("");
    }
  };

  const zipCodeHandler = useCallback(async () => {
    await axios
      .post("get-geo-details", {
        postalcode: shipmentZipCode,
        country: arrivalCountryCode,
      })
      .then((res) => {
        if (res.data.data.postalcodes.length === 0) {
          setShipmentReceiverState("");
          setShipmentReceiverDistrict("");
          setShipmentReceiverCity("");
          setShipmentReceiverStateCode("");
        } else if (res.data.data.postalcodes.length > 1) {
          setShipmentReceiverState("");
          setShipmentReceiverDistrict("");
          setShipmentReceiverCity("");
          setShipmentReceiverStateCode("");
        } else {
          setShipmentReceiverState(res.data.data.postalcodes[0].placeName);
          // setShipmentReceiverDistrict(res.data.data.postalcodes[0].adminName2);
          setShipmentReceiverDistrict(
            res.data.data.postalcodes[0].adminName2
              ? res.data.data.postalcodes[0].adminName2
              : res.data.data.postalcodes[0].placeName
          );
          setShipmentReceiverCity(res.data.data.postalcodes[0].adminName1);
          setShipmentReceiverStateCode(res.data.data.postalcodes[0].adminCode1);
        }
      });
  }, [
    setShipmentReceiverState,
    setShipmentReceiverDistrict,
    setShipmentReceiverCity,
    setShipmentReceiverStateCode,
    arrivalCountryCode,
    shipmentZipCode,
  ]);

  const zipSenderCodeHandler = useCallback(async () => {
    await axios
      .post("get-geo-details", {
        postalcode: senderZipCode,
        country: senderCreateCountryCode,
      })
      .then((res) => {
        if (res.data.data.postalcodes.length === 0) {
          setShipmentSenderState("");
          setShipmentSenderDistrict("");
          setShipmentSenderCity("");
          setShipmentSenderStateCode("");
        } else if (res.data.data.postalcodes.length > 1) {
          setShipmentSenderState("");
          setShipmentSenderDistrict("");
          setShipmentSenderCity("");
          setShipmentSenderStateCode("");
        } else {
          setShipmentSenderState(res.data.data.postalcodes[0].placeName);
          // setShipmentReceiverDistrict(res.data.data.postalcodes[0].adminName2);
          setShipmentSenderDistrict(
            res.data.data.postalcodes[0].adminName2
              ? res.data.data.postalcodes[0].adminName2
              : res.data.data.postalcodes[0].placeName
          );
          setShipmentSenderCity(res.data.data.postalcodes[0].adminName1);
          setShipmentSenderStateCode(res.data.data.postalcodes[0].adminCode1);
        }
      });
  }, [
    setShipmentSenderState,
    setShipmentSenderDistrict,
    setShipmentSenderCity,
    setShipmentSenderStateCode,
    senderCreateCountryCode,
    senderZipCode,
  ]);

  const isDisabled = useCallback(() => {
    if (shipmentDocument === "D") {
      if (
        arrivalCountryCode === "" ||
        documentMaxGross === "" ||
        shipmentZipCode === "" ||
        Number(documentMaxGross) > 2.5 ||
        Number(documentMaxGross) === 0
      ) {
        return true;
      } else return false;
    } else if (shipmentDocument !== "D") {
      if (Number(productValue) < 1) {
        setProductValueError(true);
        setProductValueHelperText("Bedel olarak en az 1 değerini girebilirsiniz.");
        return true;
      } else {
        if (
          arrivalCountryCode === "" ||
          productValue === "" ||
          productCurrency === "" ||
          shipmentZipCode === "" ||
          productValueError
        ) {
          return true;
        } else return false;
      }
    }
  }, [
    arrivalCountryCode,
    productValueError,
    documentMaxGross,
    productValue,
    productCurrency,
    shipmentDocument,
    shipmentZipCode,
  ]);

  const shipmentInfoCompleteHandler = (e) => {
    if (e) e.preventDefault();

    if (isDisabled()) {
      return;
    } else {
      setOverPayDesign(false);
      setActiveStep((prevActiveStep) => prevActiveStep + 1);

      zipCodeHandler();
      zipSenderCodeHandler();
    }
  };

  const handleCountryInfo = () => {
    setCountryRestrictInfo(true);
  };

  useEffect(() => {
    if (shipmentDocument === "") {
      setShipmentDocument("N");
    }
  }, [shipmentDocument, setShipmentDocument]);

  useEffect(() => {
    setIsAbove150EURCheck(false);
    setCheckedCustoms([])
  }, []);

  return (
    <Box className="shipmentInfo">
      <CountryRestrictInfo open={countryRestrictInfo} setOpen={setCountryRestrictInfo} />
      <form onSubmit={shipmentInfoCompleteHandler} className="shipmentInfo-form-wrapper">
        <FormControl required fullWidth sx={{ maxWidth: 380 }} size="small">
          <InputLabel id="select-document">Gönderi Tipi</InputLabel>
          <Select
            labelId="select-document"
            id="select-simple-document"
            value={shipmentDocument}
            onChange={handleDocument}
            label="Gönderi Tipi"
            required
          >
            <MenuItem value={""}>
              <em>Temizle</em>
            </MenuItem>
            <MenuItem value={"D"}>
              <div
                style={{
                  display: "flex",
                  alignItems: "center",
                  gap: "5px",
                  color: "var(--info)",
                  fontWeight: 600,
                }}
              >
                <SummarizeOutlinedIcon
                  sx={{ color: "var(--info)", width: "21px", height: "21px" }}
                />{" "}
                Döküman
              </div>
            </MenuItem>
            <MenuItem value={"N"}>
              <div
                style={{
                  display: "flex",
                  alignItems: "center",
                  gap: "5px",
                  color: "var(--info)",
                  fontWeight: 600,
                }}
              >
                <Inventory2OutlinedIcon
                  sx={{ color: "var(--info)", width: "21px", height: "21px" }}
                />{" "}
                Paket
              </div>
            </MenuItem>
          </Select>
          {shipmentDocument === "" && (
            <div className="form-document-info">
              <ErrorOutlineIcon />{" "}
              <div>
                <span className="form-document-info-text">Döküman mı, Paket mi Seçmeliyim?</span>{" "}
                <span onClick={handleDocOrPack} className="form-document-info-detail">
                  Tıkla ve Öğren
                </span>
              </div>
            </div>
          )}
        </FormControl>
        <Divider />
        <div className="shipmentInfo-form">
          <Autocomplete
            disabled
            id="country-typing-select"
            fullWidth
            size="small"
            sx={{ maxWidth: 380, ":disabled": { opacity: 0.3 } }}
            options={CountriesTurkishSorted()}
            value={
              CountriesTurkishSorted().find(
                (option) => option.iso_code === senderCreateCountryCode
              ) || null
            }
            onChange={(e, val) => {
              if (val) {
                handleSenderCountry(val);
              } else {
                setSenderCreateCountry("");
                setSenderCreateCountryCode("");
              }
            }}
            autoHighlight
            openOnFocus={false}
            getOptionLabel={(option) => `${option.country_name_tr}, ${option.country_name_en}`}
            renderOption={(props, option) => (
              <Box component="li" sx={{ "& > img": { mr: 2, flexShrink: 0 } }} {...props}>
                <img
                  loading="lazy"
                  width="20"
                  src={`https://flagcdn.com/w20/${option.iso_code.toLowerCase()}.png`}
                  srcSet={`https://flagcdn.com/w40/${option.iso_code.toLowerCase()}.png 2x`}
                  alt=""
                />
                {option.country_name_tr}, {option.country_name_en}
              </Box>
            )}
            renderInput={(params) => (
              <TextField
                {...params}
                label="Gönderici Ülke Seçiniz"
                required
                name="unique-country-field"
                value={senderCreateCountry}
                inputProps={{
                  ...params.inputProps,
                  autoComplete: "off", // disable autocomplete and autofill
                }}
              />
            )}
          />
          <TextField
            required
            value={senderZipCode}
            onChange={(e) => setSenderZipCode(e.target.value)}
            placeholder="06010"
            fullWidth
            size="small"
            autoComplete="off"
            label="Gönderici Posta Kodu"
            sx={{ maxWidth: 200, visibility: "hidden" }}
          />
        </div>
        <Divider />
        <div className="shipmentInfo-form">
          <Autocomplete
            id="country-typing-select"
            fullWidth
            size="small"
            sx={{ maxWidth: 380 }}
            options={CountriesTurkishSorted()}
            value={
              CountriesTurkishSorted().find((option) => option.iso_code === arrivalCountryCode) ||
              null
            }
            onChange={(e, val) => {
              if (val) {
                handleSelectCountry(val);
              } else {
                setArrivalCountry("");
                setArrivalCountryCode("");
              }
            }}
            autoHighlight
            openOnFocus={false}
            getOptionLabel={(option) => `${option.country_name_tr}, ${option.country_name_en}`}
            renderOption={(props, option) => (
              <Box component="li" sx={{ "& > img": { mr: 2, flexShrink: 0 } }} {...props}>
                <img
                  loading="lazy"
                  width="20"
                  src={`https://flagcdn.com/w20/${option.iso_code.toLowerCase()}.png`}
                  srcSet={`https://flagcdn.com/w40/${option.iso_code.toLowerCase()}.png 2x`}
                  alt=""
                />
                {option.country_name_tr}, {option.country_name_en}
              </Box>
            )}
            renderInput={(params) => (
              <TextField
                {...params}
                label="Alıcı Ülke Seçiniz"
                required
                name="unique-country-field"
                value={arrivalCountry}
                inputProps={{
                  ...params.inputProps,
                  autoComplete: "off", // disable autocomplete and autofill
                }}
              />
            )}
          />
          <TextField
            required
            value={shipmentZipCode}
            onChange={handleChangeZipCode}
            placeholder="06010"
            fullWidth
            size="small"
            autoComplete="off"
            label="Alıcı Posta Kodu"
            sx={{ maxWidth: 200 }}
          />
        </div>
        <Divider />
        {shipmentDocument === "D" && (
          <div className="shipmentInfo-form">
            <TextField
              label="Kg"
              size="small"
              required
              autoComplete="off"
              name="d-kg"
              value={documentMaxGross}
              onChange={(e) => FormatFieldWithTwoDecimals(e, setDocumentMaxGross)}
              sx={{ maxWidth: 100 }}
              helperText={
                Number(documentMaxGross) > 2.5 ? (
                  <span style={{ color: "red", fontSize: "10px" }}>
                    Maksimum 2.5 kg limiti aşıldı.
                  </span>
                ) : Number(documentMaxGross) === 0 ? (
                  <span style={{ color: "red", fontSize: "10px" }}>
                    Ağırlık değeri 0 kg olamaz.
                  </span>
                ) : (
                  ""
                )
              }
              error={Number(documentMaxGross) > 2.5 || Number(documentMaxGross) === 0}
            />
          </div>
        )}
        {shipmentDocument === "N" && (
          <>
            <div className="shipmentInfo-form">
              <TextField
                label="Mal Bedeli"
                size="small"
                name="product-cost"
                autoFocus={overPayDesign}
                required
                autoComplete="off"
                value={productValue}
                onChange={handleProductCostChange}
                onBlur={handleProductCostBlur}
                error={productValueError}
                helperText={productValueHelperText}
                sx={{
                  width: 200,
                  WebkitAppearance: "none",
                }}
              />
              <FormControl required fullWidth sx={{ maxWidth: 90, minWidth: 60 }} size="small">
                <InputLabel id="select-currency">Döviz</InputLabel>
                <Select
                  labelId="select-currency"
                  id="select-simple-currency"
                  value={productCurrency}
                  onChange={(e) => setProductCurrency(e.target.value)}
                  label="Döviz"
                >
                  <MenuItem value={""}>
                    <em>Temizle</em>
                  </MenuItem>
                  <MenuItem value={"EUR"}>€</MenuItem>
                  <MenuItem value={"USD"}>$</MenuItem>
                  <MenuItem value={"GBP"}>£</MenuItem>
                </Select>
              </FormControl>
            </div>
            <Button
              onClick={handleCountryInfo}
              className="iyzi-button"
              sx={{
                width: "200px",
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                gap: "5px",
              }}
            >
              <InfoOutlinedIcon />{" "}
              <span style={{ fontSize: "12px" }}>Gümrük Muafiyet Limitleri</span>
            </Button>
          </>
        )}
        <Button
          sx={{ height: 40, ":disabled": { opacity: 0.4 } }}
          type="submit"
          className="iyzi-button-blue"
        >
          İlerle
        </Button>
      </form>
    </Box>
  );
};

export default ShipmentInfo;
