import { Box, Modal } from "@mui/material";
import { useRecoilState } from "recoil";
import { orderTicketModalState } from "../../../atoms/orders";
import { useCallback } from "react";

const OrderTicket = () => {
  const [orderTicketModal, setOrderTicketModal] = useRecoilState(orderTicketModalState);

  const handleClose = useCallback(() => {
    setOrderTicketModal(false);
  }, [setOrderTicketModal]);

  return (
    <Modal
      open={orderTicketModal}
      onClose={handleClose}
      sx={{ display: "flex", justifyContent: "center", alignItems: "center", padding: "20px" }}
    >
      <Box
        sx={{
          width: "100%",
          maxWidth: "500px",
          backgroundColor: "var(--lighterBg)",
          padding: "20px",
          borderRadius: "var(--wrapperBorderRadius)",
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          gap: "var(--gap)",
          color: "var(--priceText)",
        }}
        style={{
          maxHeight: "90vh",
          overflowY: "auto",
        }}
      ></Box>
    </Modal>
  );
};

export default OrderTicket;
