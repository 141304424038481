import React, { useState } from "react";
import { Link } from "react-router-dom";
import combinedShape from "../../img/icons/nasılCalisir.svg";
import { Button, Modal, Box, Typography } from "@mui/material";
import CancelOutlinedIcon from "@mui/icons-material/CancelOutlined";
const IsHowItWorkFrame = () => {
  const [modalOpen, setModalOpen] = useState(false);

  const handleClick = () => {
    setModalOpen(true);
  };

  const handleClose = () => {
    setModalOpen(false);
  };
  const boxes = [
    {
      title: "Satış Kanalınızı Entegre Edin",
      content:
        "Etsy, Amazon, eBay gibi yurt dışı pazaryerlerinde bulunan mağazanızı ya da Magento, Woocommerce, Shopify e-ticaret altyapınızı iyziShip paneline tek tıkla entegre edin.",
      src: "/images/home/how/image-1.svg",
    },
    {
      title: "Teklifleri Kolayca Karşılaştırın",
      content:
        "Dünyanın önde gelen uluslararası kargo firmalarının yurt dışı gönderim tekliflerini karşılaştırın.",
      src: "/images/home/how/image-2.svg",
    },
    {
      title: "Online Ödemenizi Yapın",
      content:
        "Gönderi gereksinimlerinize en uygun teklifi seçtikten sonra, online ödemenizi kolayca gerçekleştirin.",
      src: "/images/home/how/image-3.svg",
    },
    {
      title: "Gönderinizi Takip Edin",
      content:
        "Yurt dışı kargonuzun konumunu ve durumunu anlık olarak takip edin; gönderilerinizle ilgili ayrıntılı raporlar elde edin.",
      src: "/images/home/how/image-4.svg",
    },
  ];

  return (
    <section className="main-ishowitwork-frame">
      <div className="ishowitwork-frame">
        <article>
          <h2>Nasıl Çalışır?</h2>
          <div style={{ display: "flex", justifyContent: "center" }}>
            {" "}
            <p>
              Uluslararası e-ticaret arenası, iyziShip ile artık daha
              erişilebilir! Yurt dışına satış gerçekleştiren işletmeler ve
              bireyler için, gönderi süreçlerini kolaylaştırıyoruz.
            </p>{" "}
          </div>
          <div className="grid-container-wrapper">
            <div className="background"></div>
            <div className="grid-container">
              {boxes.map((box, index) => (
                <div className="grid-item" key={index}>
                  <img src={box.src} alt="Howitwork" />
                  <div className="grid-item-bottom">
                    <h3>{box.title}</h3>
                    <p>{box.content}</p>
                  </div>
                </div>
              ))}
            </div>
          </div>
          <div className="button-container">
            <Link
              style={{
                padding: " 9px 14px",
                minWidth: "200px",
                fontWeight: 700,
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                textDecoration: "none",
                height: " 49.927px",
                gap: "10px",
                fontSize: "14px",
                lineHeight: "18px",
                cursor: "pointer",
              }}
              to={"/nasil-calisir"}
              className="left-btn"
            >
              Detaylı Bilgi
            </Link>
            <Link
              style={{
                padding: "10px 22px",
                minWidth: "200px",
                fontWeight: 700,
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                textDecoration: "none",
                height: " 49.927px",
                gap: "18px",
                fontSize: "14px",
                lineHeight: "18px",
                cursor: "pointer",
              }}
              to={"/"}
              className="righ-btn"
              onClick={handleClick}
            >
              <img
                src={combinedShape}
                alt="combinedShape"
                style={{ height: "32px", width: " 32px" }}
              />{" "}
              Bir Gönderi Hikayesi
            </Link>
          </div>
        </article>
      </div>
      <Modal
        open={modalOpen}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box
          sx={{
            position: "absolute",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            width: "70%",
            height: "70%",
            bgcolor: "background.paper",
            boxShadow: 24,
            p: 4,
            borderRadius: 5,
          }}
        >
          <Typography
            sx={{
              display: "flex",
              justifyContent: "flex-end",
              alignItems: "center",
              lineHeight: "1.5",
            }}
            id="modal-modal-title"
            variant="h6"
            component="h2"
          >
            <CancelOutlinedIcon
              sx={{
                right: 1,
                top: 1,
                cursor: "pointer",
                color: "var(--priceText)",
                transition: "all 80ms linear",
                ":hover": {
                  color: "var(--notRed)",
                  transition: "all 80ms linear",
                },
              }}
              onClick={handleClose}
            />
            <br /> <br />
          </Typography>
          <iframe
            width="100%"
            height="85%"
            src="https://www.youtube.com/embed/ClY9lrjy_gA"
            title="iyziShip Nasıl Çalışır? | Bir Gönderi Hikayesi"
            frameborder="0"
            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
            allowfullscreen
          ></iframe>
        </Box>
      </Modal>
    </section>
  );
};

export default IsHowItWorkFrame;
