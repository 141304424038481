import { Box } from '@mui/material'
import ControlPointOutlinedIcon from '@mui/icons-material/ControlPointOutlined';
import { useRecoilState } from 'recoil';
import { startCardAnimationState } from '../../../atoms/startCardAnimation';

const AddCard = () => {
  const [, setStartCardAnimation] = useRecoilState(startCardAnimationState);

  return (
    <Box className='card-adder-wrapper'>
      <button onClick={() => setStartCardAnimation(true)} className="card-adder">
        <ControlPointOutlinedIcon />
        Yeni Kart Ekle
      </button>
    </Box>
  )
}

export default AddCard