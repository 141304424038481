import { useCallback, useEffect, useState } from "react";
import { useRecoilState, useSetRecoilState } from "recoil";
import {
  checkedFastCustomsState,
  checkedFastInsurancesState,
  checkedFastLucidsState,
  fastPricesState,
  fastListState
} from "../../../atoms/fastShipment";
import SupplierServicesType from "../../../utils/SupplierServicesType";
import Currency from "../../../utils/CurrencySymbol";
import { Button, Checkbox, Divider, FormControlLabel, Tooltip } from "@mui/material";
import LocalPrice from "../../../utils/LocalPrice";
import { insuranceSlideWarningState } from "../../../atoms/createShipment";
import InfoOutlinedIcon from "@mui/icons-material/InfoOutlined";
import DisplayOverCharges from "../../createShipment/shipmentPrice/DisplayOverCharges";
import iossCountries from "../../../json/iossCountries.json";

const FastShipmentPrices = ({
  item,
  items,
  setSelectedPriceObject,
  handleClose,
  dataID,
  setPriceID,
  setPriceIDValue,
  setInsuranceFast,
  setDdpFast,
  ddpFast,
  setLucidFast,
  isAbove150EURCheck,
  setIsAbove150EURCheck,
  selectedFastShipmentProduct
}) => {
  const arrivalCountry = item?.country;
  const europeanCountriesNames = iossCountries.map((country) => country.country_name_tr);
  const [price, setPrice] = useState(null);
  const [isEuropeanCountry, setIsEuropeanCountry] = useState(false);


  const [checkedFastCustoms, setCheckedFastCustoms] = useState([]);
  const testFast = false;
  const [fastList, setFastList] = useRecoilState(fastListState);
  const [checkedFastInsurances, setCheckedFastInsurances] = useRecoilState(checkedFastInsurancesState);
  const [checkedFastLucids, setCheckedFastLucids] = useRecoilState(checkedFastLucidsState);
  const [fastPrices, setFastPrices] = useRecoilState(fastPricesState);
  const setInsuranceSlideWarning = useSetRecoilState(insuranceSlideWarningState);

  // Handle Insurance selection
  const handleInsuranceOnChange = useCallback(() => {
    if (checkedFastInsurances?.price_id === item?.price_id) {
      setCheckedFastInsurances({});
      setInsuranceFast(0);
      setPrice((prevPrice) => prevPrice - parseFloat(item?.insurance?.insurance_price));
    } else {
      setInsuranceSlideWarning({ status: true, data: item });
      setCheckedFastInsurances(item);
      setInsuranceFast(1);
      setPrice((prevPrice) => prevPrice + parseFloat(item?.insurance?.insurance_price));
    }
  }, [
    setInsuranceSlideWarning,
    setCheckedFastInsurances,
    checkedFastInsurances,
    setInsuranceFast,
    item,
    setPrice
  ]);

  // Handle Customs selection
  const handleCustomsOnChange = useCallback(() => {
    // If the supplier is not 338 or 302, allow manual selection

    if (checkedFastCustoms?.price_id === item?.price_id) {
      // Remove the customs if already selected
      setCheckedFastCustoms({});
      setDdpFast(0);
      setPrice((prevPrice) => prevPrice - parseFloat(item?.ddp?.ex_custom_price));
    } else {
      // Add customs if not selected
      setCheckedFastCustoms(item);
      setDdpFast(1);
      setPrice((prevPrice) => prevPrice + parseFloat(item?.ddp?.ex_custom_price));
    }

  }, [checkedFastCustoms, price, item, setCheckedFastCustoms, setDdpFast]);

  // Handle Lucid selection
  const handleLucidOnChange = useCallback(() => {
    if (checkedFastLucids?.price_id === item?.price_id) {
      setCheckedFastLucids({});
      setLucidFast(0);
      setPrice((prevPrice) => prevPrice - parseFloat(item?.lucid_price_detail?.lucid_price));
    } else {
      setCheckedFastLucids(item);
      setLucidFast(1);
      setPrice((prevPrice) => prevPrice + parseFloat(item?.lucid_price_detail?.lucid_price));
    }
  }, [checkedFastLucids, price, item, setLucidFast, setCheckedFastLucids]);

  const isButtonDisabled = () => {
    // ioss kontrolünü sadece supplier 302 ve 338 için yapıyoruz
    const isIossInvalid =
      (item.supplier_id === 302 || item.supplier_id === 338) &&
      (!items?.get_commoduties[0]?.ioss || items?.get_commoduties[0]?.ioss?.length < 5);

    const isPriceIdDifferent = checkedFastCustoms?.price_id !== item?.price_id;


    const isSupplier302Or338 = (item.supplier_id === 302 || item.supplier_id === 338) && !isAbove150EURCheck;

    const isParcelType3AndSupplier = items?.get_commoduties[0]?.parcel_type === "3" && (
      item.supplier_logo === "347.svg" ||
      item.supplier_id === 701 ||
      item.supplier_id === 801 ||
      item.supplier_id === 901
    );

    const isParcelTypeNot3AndSupplier336 = items?.get_commoduties[0]?.parcel_type !== "3" && item.supplier_id === 336;

    if (isPriceIdDifferent && isSupplier302Or338 && isIossInvalid) {
      return true;
    }

    if (!isPriceIdDifferent && isSupplier302Or338 && !isIossInvalid) {
      return false;
    }

    if (isParcelType3AndSupplier) {
      return true;
    }

    if (isParcelTypeNot3AndSupplier336) {
      return true;
    }

    return false;
  };

  // Automatically check customs and update price if isAbove150EURCheck is true or supplier is 338/302
  // useEffect(() => {
  //   let initialPrice = parseFloat(item?.selling_price);

  //   // Eğer yurtdışı gümrükleme otomatik olarak seçilecekse
  //   if ((item.supplier_id === 338 || item.supplier_id === 302) && isAbove150EURCheck) {
  //     setCheckedFastCustoms(item);  // Gümrükleme otomatik olarak işaretlenir
  //     setDdpFast(1);  // DDP durumu aktif edilir
  //     initialPrice += parseFloat(item?.ddp?.ex_custom_price); // Burada fiyat eklenir
  //   }

  //   // Sigorta fiyatını ekleyelim
  //   if (checkedFastInsurances?.price_id === item?.price_id) {
  //     initialPrice += parseFloat(item?.insurance?.insurance_price);
  //   }

  //   setPrice(initialPrice);
  // }, [item, checkedFastCustoms, checkedFastInsurances, setPrice, setDdpFast, isAbove150EURCheck]);

  useEffect(() => {
    let initialPrice = parseFloat(item?.selling_price);

    // Eğer yurtdışı gümrükleme otomatik olarak seçilecekse
    if (checkedFastCustoms?.price_id === item?.price_id || (item.supplier_id === 338 || item.supplier_id === 302) && isAbove150EURCheck) {
      setCheckedFastCustoms(item);  // Gümrükleme otomatik olarak işaretlenir
      setDdpFast(1);  // DDP durumu aktif edilir
      initialPrice += parseFloat(item?.ddp?.ex_custom_price);
    }

    // Sigorta fiyatını ekleyelim
    if (checkedFastInsurances?.price_id === item?.price_id) {
      initialPrice += parseFloat(item?.insurance?.insurance_price);
    }

    setPrice(initialPrice);
  }, [item, checkedFastCustoms, checkedFastInsurances, setPrice, setDdpFast]);


  useEffect(() => {
    setIsEuropeanCountry(europeanCountriesNames.includes(arrivalCountry));
  }, [arrivalCountry, europeanCountriesNames]);

  // Handle price changes when the item or any other state changes
  const handleChangePrice = useCallback(() => {
    const fastPrice = fastPrices.find((e) => e.id === dataID);
    setPriceID(item?.price_id);
    setPriceIDValue(item?.price_id);
    setFastList((prevList) => {
      const updatedList = prevList.map((fast) => {
        if (fast.id === dataID) {
          return {
            ...fast,
            price_id: item?.price_id,
          };
        }
        return fast;
      });
      return updatedList;
    });
    setSelectedPriceObject(item);
    if (fastPrice) {
      setFastPrices([
        ...fastPrices?.filter((e) => e.id !== dataID),
        {
          id: dataID,
          obj: item,
        },
      ]);
    } else {
      setFastPrices([
        ...fastPrices,
        {
          id: dataID,
          obj: item,
        },
      ]);
    }
    handleClose();
  }, [item, fastPrices, setFastPrices, setSelectedPriceObject, handleClose, setPriceID, dataID]);

  const isEmptyValue = (value) => {
    if (Array.isArray(value)) {
      return value.length === 0; // Eğer bir dizi ise ve uzunluğu 0 ise boş kabul ediyoruz.
    } else if (typeof value === "object") {
      return Object.keys(value).length === 0; // Eğer bir obje ise ve anahtar sayısı 0 ise boş kabul ediyoruz.
    }
    return true; // Diğer durumlar için boş kabul ediyoruz.
  };

  const nonEmptyEntries = Object.entries(item?.extra_information).filter(
    ([key, value]) => !isEmptyValue(value)
  );

  const nonEmptyObject = Object.fromEntries(nonEmptyEntries);

  const isEmpty = Number(item.extra_information.total_over_charge_price) === 0;

  return (
    <div className="get-card">
      <div className="get-card-header">
        <div className="get-card-header-headline">
          {item?.supplier_note_tr && (
            <Tooltip title={item?.supplier_note_tr}>
              <InfoOutlinedIcon sx={{ color: "var(--info)" }} className="logo-warning" />
            </Tooltip>
          )}
          <img
            src={`/images/${item?.supplier_logo}`}
            alt={`${item?.supplier_name} Logo Görseli`}
            style={{ marginLeft: item?.supplier_note_tr ? 0 : "31px", height: "80px", width: "80px" }}
          />
          {SupplierServicesType(item?.service_type)}
          {item.supplier_logo === "347.svg" && <span>(ETGB'siz)</span>}
          {item.supplier_id === 336 && <span>(ETGB'li)</span>}
          {item.supplier_id === 701 && <span>(Vergiler Dahil)</span>}
          {item.supplier_id === 801 && <span>(Vergiler Dahil)</span>}
        </div>
        <div className="get-card-header-subtext">
          Teslimat Süresi:&nbsp;<span>{item?.transit}</span>&nbsp;iş günü
        </div>
      </div>
      <Divider />
      <div className="get-card-main">
        <div className="get-card-main-price-wrapper">
          <div className="get-card-main-price">
            <span className="main-price-out">{Currency(item?.currency)}{LocalPrice(price)}</span>
            <span className="main-price-in">({LocalPrice(Number(price) * Number(item?.exchange_rate))} ₺)</span>
          </div>
          <div style={{ display: "flex", alignItems: "center", gap: "5px" }}>
            İade Masrafı: {Currency(item?.currency)}{LocalPrice(item.return_price_detail.return_price)}
            {item.return_price_detail.return_price_txt?.length !== 0 && (
              <Tooltip title={item.return_price_detail.return_price_txt}>
                <InfoOutlinedIcon sx={{ width: "21px!important", height: "21px!important", color: "var(--btnBgBlue)" }} />
              </Tooltip>
            )}
          </div>
          {!isEmpty && <DisplayOverCharges item={item} data={nonEmptyObject} currency={item?.currency} />}
        </div>
        <div className="shipmentPrice-get-insurance">
          {Number(item?.lucid_price_detail.lucid) === 1 && (
            <FormControlLabel
              label={`${Currency(item?.lucid_price_detail.currency)}${LocalPrice(item?.lucid_price_detail.lucid_price)} - Lucid`}
              control={<Checkbox checked={checkedFastLucids.price_id === item?.price_id} onChange={handleLucidOnChange} />}
            />
          )}
          {Number(item?.insurance.insurance_available) === 1 && (
            <FormControlLabel
              label={`${Currency(item?.currency)}${LocalPrice(item?.insurance?.insurance_price)} - Sigorta`}
              control={<Checkbox checked={checkedFastInsurances.price_id === item?.price_id} onChange={handleInsuranceOnChange} />}
            />
          )}
          {Number(item?.ddp?.ddp) === 1 && (
            <FormControlLabel
              label={`${Currency(item?.ddp.currency)}${LocalPrice(item?.ddp.ex_custom_price)} - Yurtdışı Gümrükleme`}
              control={
                <Checkbox
                  checked={(item.supplier_id === 338 || item.supplier_id === 302) && isAbove150EURCheck || checkedFastCustoms?.price_id === item?.price_id}
                  onChange={handleCustomsOnChange}
                  disabled={(item.supplier_id === 338 || item.supplier_id === 302) && isAbove150EURCheck} // Disabled for 338 and 302
                />
              }
            />
          )}
        </div>
      </div>
      <Tooltip
        title={
          items?.get_commoduties[0]?.parcel_type === "3" && item.supplier_logo === "347.svg"
            ? "Seçebilmek için proformadaki Gönderi Açıklamasının, Normal Gönderi veya Numune ya da Hediye şeklinde seçilmesi gerekmektedir."
            : items?.get_commoduties[0]?.parcel_type === "3" && item.supplier_id === 701
              ? "Seçebilmek için proformadaki Gönderi Açıklamasının, Normal Gönderi veya Numune ya da Hediye şeklinde seçilmesi gerekmektedir."
              : items?.get_commoduties[0]?.parcel_type === "3" && item.supplier_id === 801
                ? "Seçebilmek için proformadaki Gönderi Açıklamasının, Normal Gönderi veya Numune ya da Hediye şeklinde seçilmesi gerekmektedir."
                : item.supplier_id === 302 && !isAbove150EURCheck && items?.get_commoduties[0]?.ioss?.length < 10
                  ? "Seçebilmek için proformadaki IOSS alanının dolu olması ve Yurtdışı Gümrükleme seçeneğinin seçili olması gerekmektedir."
                  : item.supplier_id === 338 && !isAbove150EURCheck && items?.get_commoduties[0]?.ioss?.length < 10
                    ? "Seçebilmek için proformadaki IOSS alanının dolu olması ve Yurtdışı Gümrükleme seçeneğinin seçili olması gerekmektedir."
                    : item.supplier_id === 302 && !isAbove150EURCheck && items?.get_commoduties[0]?.ioss?.length > 5 && checkedFastCustoms?.price_id !== item?.price_id
                      ? "Seçebilmek için proformadaki IOSS alanının dolu olması ve Yurtdışı Gümrükleme seçeneğinin seçili olması gerekmektedir."
                      : item.supplier_id === 338 && !isAbove150EURCheck && items?.get_commoduties[0]?.ioss?.length > 5 && checkedFastCustoms?.price_id !== item?.price_id
                        ? "Seçebilmek için proformadaki IOSS alanının dolu olması ve Yurtdışı Gümrükleme seçeneğinin seçili olması gerekmektedir."
                        : items?.get_commoduties[0]?.parcel_type !== "3" && item.supplier_id === 336
                          ? "Seçebilmek için proformadaki Gönderi Açıklamasının, Mikro İhracat (ETGB'li) E-ihracat şeklinde seçilmesi gerekmektedir."
                          : "" // Eğer hiçbir koşul sağlanmıyorsa boş bir string döner
        }
        arrow>
        <span>
          <Button
            sx={{ '&:disabled': { opacity: 0.2, cursor: 'auto' }, width: "100%" }}
            disabled={isButtonDisabled()}
            className="iyzi-button-blue"
            onClick={handleChangePrice}>
            Fiyat Seç
          </Button>
        </span>
      </Tooltip>
    </div >
  );
};

export default FastShipmentPrices;
