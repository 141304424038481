const IyziTeamHeros = () => {
  return (
    <div className="iyzi-team-heros">
      <div className="iyzi-team-hero">
        <article>
          <h6>Takım Lideri</h6>
          <p>
            E-ticaret evreninin zorluklarıyla başa çıkarken, takım liderimiz her
            zaman doğru yolu gösterir. Pelerininin altında, sadece cesaret ve
            kararlılık değil, aynı zamanda vizyon ve strateji de saklıdır.
            E-ihracatın hızla değişen dünyasında, her zaman bir adım önde
            olmanın sırrı onun elindeki çantada. Ekibiyle birlikte, sadece
            hedeflere ulaşmakla kalmaz, aynı zamanda yeni ufuklara yelken açar.
            Onun liderliğinde, her gün bir öncekinden daha parlak bir yarın için
            çalışıyoruz.
          </p>
        </article>
        <img src="/images/heros/team_leader.png" alt="iyziTeam Takım Lideri" />
      </div>
      <div className="iyzi-team-hero">
        <img
          src="/images/heros/finance_hero.png"
          alt="iyziTeam Finans Kahramanı"
        />
        <article>
          <h6>Finans Kahramanları</h6>
          <p>
            E-ticaret evreninin zorluklarıyla başa çıkarken, takım liderimiz her
            Finans galaksisinin derinliklerinde, her rakamın ve hesabın
            arkasında duran bu kahramanlar, müşterilerin mali hedeflerine
            ulaşmalarını garantiler. Süper güçleri sayesinde, müşterilerin
            finansal hedeflerini korumak ve büyütmek için olası tüm riskleri
            önceden belirler ve önler. Ekibin her bir üyesi; piyasayı dikkatlice
            analiz eder, müşteriler için en uygun finansal stratejileri
            oluşturur ve uygular. E-ticaret evreninde karşılaşılan her türlü
            mali zorluk, bu kahramanların bilgisi ve becerisiyle aşılır.
            Sayıların ve rakamların diliyle konuşan bu süper kahramanlar, her
            zaman müşterilerin mali güvencesini ve başarısını sağlar.
          </p>
        </article>
      </div>
      <div className="iyzi-team-hero">
        <article>
          <h6>Teknoloji Kahramanları</h6>
          <p>
            Teknolojinin kalbi ve kodların efendileri karşınızda! Elindeki
            ışıltılı kodların ardında, kompleks sorunları saniyeler içerisinde
            çözen bir zeka barınıyor. Dijital dünyanın labirentlerinde, her bir
            byte'ın ve bit'in diliyle konuşurlar. Onların sayesinde sistemler
            kusursuz çalışır, uygulamalar mükemmel bir deneyim sunar. Gecesi
            gündüzüne karışsa da, onlar için önemli olan tek şey teknolojik
            mükemmeliyeti yakalamaktır. Her bir satır koduyla, dijital evreni
            şekillendirir ve inovasyonun sınırlarını genişletirler. Teknoloji
            kahramanlarıyla tanışın; yazılımcıların gururu, kodların şairleri!
          </p>
        </article>
        <img
          src="/images/heros/tech_hero.png"
          alt="iyziTeam Teknoloji Kahramanı"
        />
      </div>
      <div className="iyzi-team-hero">
        <img
          src="/images/heros/operation_hero.png"
          alt="iyziTeam Operasyon Kahramanı"
        />
        <article>
          <h6>Operasyon Kahramanları</h6>
          <p>
            Operasyonların belkemiği, her detayı titizlikle planlayan süper
            kahramanlarımız karşınızda! Ekibin tüm üyeleri; her görevde
            tecrübesini ve uzmanlığını ortaya koyar, en karmaşık işleri bile
            basit ve düzenli bir şekilde yönetir. Ürünlerinizi özenle korur,
            onlara zarar gelmesini engeller. Daima hazırlıklı, daima planlı.
            Onların süper güçleri sayesinde, operasyonlar adeta bir senfoniye
            dönüşür. İşlerin akışında hiçbir engel onlara dur diyemez!
          </p>
        </article>
      </div>
      <div className="iyzi-team-hero">
        <article>
          <h6>Müşteri Hizmetleri Kahramanları</h6>
          <p>
            Kulakları her daim sizin sesiniz için açık, gözleri çözüm
            arayışında; işte karşınızda müşteri hizmetlerinin dur durak bilmeyen
            süper kahramanları! Sorunlarınızı anında çözüme kavuşturmak için her
            zaman hazır olan süper kahramanlarımız, müşteri memnuniyetinin
            ötesinde bir bağ kurmayı hedefler. Sorularınızı cevapsız bırakmamak,
            ihtiyaçlarınızı karşılamak için daima buradalar. Her bir telefonun
            diğer ucunda, her bir mesajın ardında sizin için bekleyen
            kahramanlarımız, size sadece bir hizmet değil, aynı zamanda bir
            deneyim sunar.
          </p>
        </article>
        <img
          src="/images/heros/call_center_hero.png"
          alt="Müşteri Hizmetleri Kahramanı"
        />
      </div>
      <div className="iyzi-team-hero">
        <img src="/images/heros/contact_hero.png" alt="İletişim" />
        <article>
          <h6>İletişim Kahramanları</h6>
          <p>
            Karşınızda iletişim dünyasının efendileri! Süper güçleri sayesinde,
            iletişimin tüm kanallarını kontrol altında tutarlar. Sadece bir
            mesajı iletmez, aynı zamanda sizin sesiniz olurlar. İster sosyal
            medya, ister telefon, isterse e-posta olsun; onlar her zaman
            yanınızda. İletişim kahramanlarımız, bilgi akışının sürekli ve
            kesintisiz olmasını sağlar, böylece tüm gelişmelerden ve
            yeniliklerden hemen haberiniz olur. Bu iletişim ustalarıyla, her
            kelime, her cümle kusursuz bir şekilde yerine ulaşır.
          </p>
        </article>
      </div>
    </div>
  );
};

export default IyziTeamHeros;
