import {
  Button,
  CircularProgress,
  createTheme,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  FormControl,
  FormHelperText,
  InputAdornment,
  Modal,
  TextField,
  ThemeProvider,
} from "@mui/material";
import { useEffect, useState, useCallback } from "react";
import { toast } from "react-toastify";
import { useRecoilState, useRecoilValue } from "recoil";
import axios from "../../../api/axios";
import {
  editReceiverAddressOpenState,
  receiverAddressDialogOpenState,
  selectedShipmentForMyShipmentsState,
} from "../../../atoms/myShipments";
import CancelOutlinedIcon from "@mui/icons-material/CancelOutlined";
import CloseOutlinedIcon from "@mui/icons-material/CloseOutlined";
import InfoOutlinedIcon from "@mui/icons-material/InfoOutlined";
import DoneOutlinedIcon from "@mui/icons-material/DoneOutlined";
import PhoneInput from "react-phone-input-2";
import Warring from "../../../utils/Warring";
import { validateEmail } from "../../../utils/emailValidation";
import Errors from "../../../utils/Errors";

const theme = createTheme({
  components: {
    MuiModal: {
      styleOverrides: {
        root: {
          padding: "20px",
        },
      },
    },
    MuiOutlinedInput: {
      styleOverrides: {
        root: {
          borderRadius: "8px",
        },
      },
    },
  },
});

const EditShipmentAddress = () => {
  const [editReceiverAddressOpen, setEditReceiverAddressOpen] = useRecoilState(
    editReceiverAddressOpenState
  );
  const [receiverAddressDialogOpen, setReceiverAddressDialogOpen] = useRecoilState(
    receiverAddressDialogOpenState
  );

  const selectedShipmentForMyShipments = useRecoilValue(selectedShipmentForMyShipmentsState);

  const [contactName, setContactName] = useState();
  const [companyName, setCompanyName] = useState();
  const [address1, setAddress1] = useState();
  const [address2, setAddress2] = useState();
  const [address3, setAddress3] = useState();
  const [postalCode, setPostalCode] = useState();
  const [state, setState] = useState();
  const [town, setTown] = useState();
  const [city, setCity] = useState();
  const [phone, setPhone] = useState();
  const [email, setEmail] = useState();
  const [stateCode, setStateCode] = useState("");

  const [showModal, setShowModal] = useState(false);
  const invalidKeywords = ["PO BOX", "POBOX", "P.O BOX", "P.O BOX", "P.O. BOX"];

  const [zipLoading, setZipLoading] = useState(false);
  const [zipIsDone, setZipIsDone] = useState(null);
  const handleReceiverAddress = async () => {
    updateReceiverAddress(receiverAddressDialogOpen.data);
  };
  //* Error */

  const [zipCodeError, setZipCodeError] = useState(false);
  const [phoneIsNotValid, setPhoneIsNotValid] = useState(false);

  const zipCodeHandler = useCallback(async () => {
    if (postalCode.length === 0) {
      setZipIsDone(null);
    } else {
      setZipLoading(true);
      setZipIsDone(null);
      await axios
        .post("get-geo-details", {
          postalcode: postalCode,
          country: selectedShipmentForMyShipments?.get_parcel_receiver_address?.country,
        })
        .then((res) => {
          setZipLoading(false);
          if (res.data.data.postalcodes.length === 0) {
            setZipIsDone(false);
            setZipCodeError(false);
            setStateCode("");
          } else if (res.data.data.postalcodes.length > 1) {
            setPostalCode("");
            setStateCode("");
            setZipCodeError(true);
            setZipIsDone(false);
          } else {
            setZipIsDone(true);
            setState(res.data.data.postalcodes[0].placeName);
            setTown(
              res.data.data.postalcodes[0].adminName2
                ? res.data.data.postalcodes[0].adminName2
                : res.data.data.postalcodes[0].placeName
            );
            setCity(res.data.data.postalcodes[0].adminName1);
            setStateCode(res.data.data.postalcodes[0].adminCode1);
            setZipCodeError(false);
          }
        })
        .catch((error) => {
          Errors(error);
          setZipLoading(false);
          setZipIsDone(false);
        });
    }
  }, [postalCode, selectedShipmentForMyShipments]);

  const handleSetValues = useCallback(() => {
    setContactName(selectedShipmentForMyShipments?.get_parcel_receiver_address?.contact_name);
    setCompanyName(selectedShipmentForMyShipments?.get_parcel_receiver_address?.company_name);
    setAddress1(selectedShipmentForMyShipments?.get_parcel_receiver_address?.address1);
    setAddress2(selectedShipmentForMyShipments?.get_parcel_receiver_address?.address2);
    setAddress3(selectedShipmentForMyShipments?.get_parcel_receiver_address?.address3);
    setPostalCode(selectedShipmentForMyShipments?.get_parcel_receiver_address?.postal_code);
    setState(selectedShipmentForMyShipments?.get_parcel_receiver_address?.state);
    setTown(selectedShipmentForMyShipments?.get_parcel_receiver_address?.town);
    setCity(selectedShipmentForMyShipments?.get_parcel_receiver_address?.city);
    setPhone(selectedShipmentForMyShipments?.get_parcel_receiver_address?.phone);
    setEmail(selectedShipmentForMyShipments?.get_parcel_receiver_address?.email);
    setStateCode(selectedShipmentForMyShipments?.get_parcel_receiver_address?.state_code);
  }, [selectedShipmentForMyShipments]);

  const handleClose = useCallback(() => {
    setContactName("");
    setCompanyName("");
    setAddress1("");
    setAddress2("");
    setAddress3("");
    setPostalCode("");
    setState("");
    setTown("");
    setCity("");
    setPhone("");
    setEmail("");
    setStateCode("");
    setEditReceiverAddressOpen(false);
    setReceiverAddressDialogOpen({ status: false, data: {} });
  }, [setEditReceiverAddressOpen, setReceiverAddressDialogOpen]);

  useEffect(() => {
    if (editReceiverAddressOpen) {
      handleSetValues();
    }
  }, [handleSetValues, editReceiverAddressOpen]);

  const updateReceiverAddress = async (data) => {
    if (data.phone.length < 6) {
      setPhoneIsNotValid(true);
      return;
    } else {
      await axios
        .post(`parcel/update-receiver-address/${data.parcel_id}`, {
          contact_name: data.contact_name,
          company_name: data.company_name,
          address1: data.address1,
          address2: data.address2,
          address3: data.address3,
          postal_code: data.postal_code,
          state: data.state,
          state_code: data.state_code,
          town: data.town,
          city: data.city,
          phone: data.phone,
          email: data.email,
          country: data.country,
          address_type: data.address_type,
          address_description: data.address_description,
          force_address: 1,
        })
        .then((response) => {
          if (response.data.status === 200) {
            toast.success(response.data.message);
          }
          if (response.data.status === 304) {
            setReceiverAddressDialogOpen({
              status: true,
              data: response.data.data,
            });
          }
          if (response.data.status === 400) {
            toast.error(response.data.message);
          }
          setEditReceiverAddressOpen(false);
          setReceiverAddressDialogOpen({ status: false, data: {} });
          setTimeout(() => {
            window.location.reload(true);
          }, 1000);
        })
        .catch((error) => {
          Errors(error);
          setEditReceiverAddressOpen(false);
          setReceiverAddressDialogOpen({ status: false, data: {} });
        })
        .finally(() => {
          setPhoneIsNotValid(false);
        });
    }
  };
  const closeModal = () => {
    setShowModal(false);
  };
  const updateAddress = async (e) => {
    e.preventDefault();
    const fullAddress = `${address1} ${address2} ${address3}`;
    // Geçersiz kelimeleri içerip içermediğini kontrol edilir
    const hasInvalidKeywords = invalidKeywords.some((keyword) =>
      fullAddress.toUpperCase().includes(keyword)
    );

    if (phone.length < 6) {
      setPhoneIsNotValid(true);
      return;
    } else if (hasInvalidKeywords) {
      setShowModal(hasInvalidKeywords);
      return;
    } else {
      await axios
        .post(`parcel/update-receiver-address/${selectedShipmentForMyShipments.id}`, {
          contact_name: contactName,
          company_name: companyName,
          address1: address1,
          address2: address2,
          address3: address3,
          postal_code: postalCode,
          state: state,
          state_code: stateCode,
          town: town,
          city: city,
          phone: phone,
          email: email,
          country: selectedShipmentForMyShipments?.get_parcel_receiver_address.country,
          address_type: selectedShipmentForMyShipments?.get_parcel_receiver_address?.address_type,
          address_description:
            selectedShipmentForMyShipments?.get_parcel_receiver_address?.address_description,
        })
        .then((response) => {
          if (response.data.status === 200) {
            toast.success(response.data.message, {
              autoClose: 500,
            });
          }
          if (response.data.status === 304) {
            setReceiverAddressDialogOpen({
              status: true,
              data: response.data.data,
            });
          }
          if (response.data.status === 400) {
            toast.error(response.data.message, {
              autoClose: 500,
            });
          }
          setEditReceiverAddressOpen(false);
          if (response.data.status !== 304) {
            setTimeout(() => {
              window.location.reload(true);
            }, 1000);
          }
        })
        .catch((error) => {
          Errors(error);
          setEditReceiverAddressOpen(false);
        })
        .finally(() => {
          setPhoneIsNotValid(false);
        });
    }
  };

  const [emailError, setEmailError] = useState(false);
  const [isEmailValid, setIsEmailValid] = useState(true);

  const handleEmailChange = (e) => {
    const newEmail = e.target.value;

    setEmail(newEmail);

    if (!newEmail) {
      setIsEmailValid(false);
    } else {
      const isValidEmail = validateEmail(newEmail);
      setIsEmailValid(isValidEmail);
    }
  };

  useEffect(() => {
    setEmailError(!isEmailValid);
  }, [isEmailValid]);

  return (
    <ThemeProvider theme={theme}>
      <Modal open={editReceiverAddressOpen} onClose={handleClose}>
        <form
          style={{
            backgroundColor: "var(--lighterBg)",
            borderRadius: "var(--inputBorderRadius)",
            padding: "20px",
            outline: 0,
            display: "grid",
            gap: "var(--gap)",
            width: "100%",
            maxWidth: "1100px",
            margin: "auto",
            position: "relative",

            maxHeight: "90vh",
            overFlowY: "auto",
          }}
          onSubmit={updateAddress}
        >
          <CancelOutlinedIcon
            sx={{
              position: "absolute",
              right: 1,
              top: 1,
              color: "var(--priceText)",
              cursor: "pointer",
              transition: "all 80ms linear",
              ":hover": {
                color: "var(--notRed)",
                transition: "all 80ms linear",
              },
            }}
            onClick={handleClose}
          />
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              gap: "var(--gap2x)",
            }}
          >
            <div
              style={{
                display: "grid",
                gridTemplateColumns: "repeat(auto-fit, minmax(150px, 1fr))",
                gap: "var(--gap2x)",
              }}
            >
              <TextField
                value={contactName}
                onChange={(e) => setContactName(e.target.value)}
                size="small"
                label="Kontak"
                autoFocus
                onFocus={(e) => {
                  let descValue = e.target.value;
                  e.target.value = "";
                  e.target.value = descValue;
                }}
              />

              <TextField
                value={email}
                onChange={handleEmailChange}
                error={emailError}
                helperText={emailError && "Geçerli bir email giriniz."}
                size="small"
                label="E-Posta"
                type={"email"}
                autoComplete="off"
              />
            </div>
            <TextField
              value={companyName}
              onChange={(e) => setCompanyName(e.target.value)}
              size="small"
              label="Firma Adı"
            />
            <TextField
              fullWidth
              value={address1}
              onChange={(e) => setAddress1(e.target.value)}
              size="small"
              label="Adres Satırı"
            />
            {address2 && (
              <TextField
                fullWidth
                value={address2}
                onChange={(e) => setAddress2(e.target.value)}
                size="small"
                label="2.Adres Satırı"
              />
            )}
            {address3 && (
              <TextField
                fullWidth
                value={address3}
                onChange={(e) => setAddress3(e.target.value)}
                size="small"
                label="Adres Satırı 3"
              />
            )}
            <div
              style={{
                display: "grid",
                gap: "var(--gap2x)",
                gridTemplateColumns: "repeat(auto-fit, minmax(150px, 1fr))",
              }}
            >
              <TextField
                required
                value={postalCode}
                onChange={(e) => {
                  const newPostalCode = e.target.value;
                  setPostalCode(newPostalCode);
                  if (postalCode !== newPostalCode) {
                    setCity("");
                    setTown("");
                    setState("");
                    setStateCode("");
                  }
                }}
                placeholder="06010"
                onBlur={zipCodeHandler}
                fullWidth
                size="small"
                autoComplete="off"
                label="Posta Kodu"
                disabled
                error={zipCodeError}
                helperText={
                  <div
                    style={{
                      fontSize: "inherit",
                      color: "var(--info)",
                      backgroundColor: "var(--pastelBlue)",
                      display: "flex",
                      gap: "5px",
                      padding: "5px",
                      borderRadius: "var(--wrapperBorderRadius)",
                      alignItems: "center",
                    }}
                  >
                    <InfoOutlinedIcon />
                    <div style={{ fontSize: "inherit", color: "var(--info)" }}>
                      Eğer posta kodunun yanında{" "}
                      <CloseOutlinedIcon style={{ color: "red", fontSize: "11px" }} /> görseli varsa
                      bu seçeneklerde bulunmadığı anlamına gelir. Semt, İlçe ve Şehir kısmını
                      doldurarak devam etmenizde bir sakınca yoktur.
                    </div>
                  </div>
                }
                InputProps={
                  zipIsDone === true
                    ? {
                        endAdornment: (
                          <InputAdornment position="end">
                            <DoneOutlinedIcon style={{ color: "green" }} />
                          </InputAdornment>
                        ),
                      }
                    : zipIsDone === false
                    ? {
                        endAdornment: (
                          <InputAdornment position="end">
                            <CloseOutlinedIcon style={{ color: "red" }} />
                          </InputAdornment>
                        ),
                      }
                    : zipIsDone === null && zipLoading === true
                    ? {
                        endAdornment: (
                          <InputAdornment position="end">
                            <CircularProgress style={{ width: "20px", height: "20px" }} />
                          </InputAdornment>
                        ),
                      }
                    : {}
                }
              />
              <FormControl size="small" fullWidth>
                <PhoneInput
                  country={selectedShipmentForMyShipments?.get_parcel_receiver_address?.country.toLowerCase()}
                  value={phone}
                  onChange={(e) => setPhone(e)}
                  autoComplete="off"
                  isValid={(value, country) => {
                    if (value.match(/12345/)) {
                      return "Invalid value: " + value + ", " + country.name;
                    } else if (value.match(/1234/)) {
                      return false;
                    } else {
                      return true;
                    }
                  }}
                  inputProps={{
                    name: "phone",
                    autoFocus: true,
                    required: true,
                    id: "ind-phone-input",
                    style: {
                      height: "100%",
                      width: "100%",
                      minHeight: "40px",
                      maxHeight: "40px",
                      borderColor: phoneIsNotValid ? "var(--error)" : "var(--inputBorderColor)",
                    },
                  }}
                />
                {phoneIsNotValid && (
                  <FormHelperText sx={{ color: "var(--error)" }}>
                    Telefon numaranızı giriniz.
                  </FormHelperText>
                )}
              </FormControl>
            </div>
            <div style={{ display: "flex", gap: "var(--gap)" }}>
              <TextField
                value={city}
                fullWidth
                onChange={(e) => setCity(e.target.value)}
                size="small"
                label="Province / Eyalet"
                disabled
              />
              <TextField
                value={town}
                fullWidth
                onChange={(e) => setTown(e.target.value)}
                size="small"
                label="Kent"
                disabled
              />
              <TextField
                value={state}
                fullWidth
                onChange={(e) => setState(e.target.value)}
                size="small"
                label="İlçe"
                disabled
              />
              <TextField
                value={stateCode}
                fullWidth
                onChange={(e) => setStateCode(e.target.value)}
                size="small"
                label="Eyalet Kodu"
                disabled
              />
            </div>
          </div>
          <div
            style={{
              display: "flex",
              justifyContent: "flex-end",
              gap: "var(--gap)",
            }}
          >
            <Button type="submit" className="iyzi-button-blue">
              Düzenle
            </Button>
            <Warring isOpen={showModal} onClose={closeModal} />
            <Button type="button" className="iyzi-button-blue" onClick={handleClose}>
              İptal
            </Button>
          </div>
        </form>
      </Modal>
      <Dialog
        open={receiverAddressDialogOpen.status}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            Değişiklik yapmak üzeresiniz eğer 3 ve 3 kereden fazla değişiklik yaparsanız etsy
            numaranız bloklanacaktır. Devam etmek istiyor musunuz?
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button className="iyzi-button" onClick={handleClose}>
            Hayır
          </Button>
          <Button className="iyzi-button" onClick={handleReceiverAddress} autoFocus>
            Evet
          </Button>
        </DialogActions>
      </Dialog>
    </ThemeProvider>
  );
};

export default EditShipmentAddress;
