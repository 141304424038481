import { Box, TableCell, TableHead, TableRow } from "@mui/material";
import CaseHeader from "./supportListTableHead/CaseHeader";
import AwbHeader from "./supportListTableHead/AwbHeader";
import SubjectHeader from "./supportListTableHead/SubjectHeader";
import DateHeader from "./supportListTableHead/DateHeader";
import LastActionHeader from "./supportListTableHead/LastActionHeader";
import StatusHeader from "./supportListTableHead/StatusHeader";

const SupportListTableHead = () => {
  return (
    <TableHead>
      <TableRow className="support-list-table-head">
        <TableCell size="small">
          <CaseHeader />
        </TableCell>
        <TableCell size="small">
          <AwbHeader />
        </TableCell>
        <TableCell size="small">
          <SubjectHeader />
        </TableCell>
        <TableCell size="small">
          <StatusHeader />
        </TableCell>
        <TableCell size="small">
          <DateHeader />
        </TableCell>
        <TableCell size="small">
          <LastActionHeader />
        </TableCell>
        <TableCell size="small">
          <Box className="th-container">Seç</Box>
        </TableCell>
      </TableRow>
    </TableHead>
  );
};

export default SupportListTableHead;
