import { Box } from "@mui/material";
import { useRecoilValue } from "recoil";
import { startIbanAnimationState } from "../../../atoms/profileSettings";
import AddIbans from "./AddIbans.jsx";
import CreateIban from "./CreateIban";
import IbanList from "./IbanList";

const Ibans = () => {
  const startIbansAnimation = useRecoilValue(startIbanAnimationState);

  return (
    <>
      {startIbansAnimation ? (
        ""
      ) : (
        <Box className="ibans-list">
          <AddIbans />
          <IbanList />
        </Box>
      )}
      <CreateIban/>
    </>
  );
};
export default Ibans;