import { useRecoilValue } from "recoil";
import IntegrationListItem from "./IntegrationListItem";
import { currentIngtegrationsState } from "../../atoms/integrations";

const IntegrationList = () => {
  const currentIngtegrations = useRecoilValue(currentIngtegrationsState);

  const integrationList = [
    {
      src: "/images/integrations/etsy-white.png",
      background: "#EB6D20",
      content: "Etsy’i Bağla",
      alt: "Etsy Entegrasyonu Bağla",
      title: "etsy",
      active: currentIngtegrations?.find((e) => e?.portal === "ETSY") ? true : false,
      integration: currentIngtegrations?.filter((e) => e?.portal === "ETSY"),
    },
    {
      src: "/images/integrations/wooCommerce-white.png",
      background: "#7F54B3",
      content: "WooCommerce’i Bağla",
      alt: "WooCommerce Entegrasyonu Bağla",
      title: "",
      active: currentIngtegrations?.find((e) => e.portal === "WooCommerce") ? true : false,
      integration: currentIngtegrations?.filter((e) => e.portal === "WooCommerce"),
    },
    {
      src: "/images/integrations/shopify-white.png",
      background: "#4A9C14",
      content: "Shopify’ı Bağla",
      alt: "Shopify Entegrasyonu Bağla",
      title: "shopify",
      active: currentIngtegrations?.find((e) => e.portal === "Shopify") ? true : false,
      integration: currentIngtegrations?.filter((e) => e.portal === "Shopify"),
    },
    {
      src: "/images/integrations/amazon-white.png",
      background: "#FF9900",
      content: "Amazon’u Bağla",
      alt: "Amazon Entegrasyonu Bağla",
      title: "",
      active: currentIngtegrations?.find((e) => e.portal === "AMAZON") ? true : false,
      integration: currentIngtegrations?.filter((e) => e.portal === "AMAZON"),
    },
    {
      src: "/images/integrations/wish-white.png",
      background: "#2FB7EC",
      content: "Wish’i Bağla",
      alt: "Wish Entegrasyonu Bağla",
      title: "",
      active: currentIngtegrations?.find((e) => e.portal === "WISH") ? true : false,
      integration: currentIngtegrations?.filter((e) => e.portal === "WISH"),
    },
    {
      src: "/images/integrations/aliExpress-white.png",
      background: "#E43225",
      content: "AliExpress’i Bağla",
      alt: "AliExpress Entegrasyonu Bağla",
      title: "",
      active: currentIngtegrations?.find((e) => e.portal === "ALIEXPRESS") ? true : false,
      integration: currentIngtegrations?.filter((e) => e.portal === "ALIEXPRESS"),
    },
  ];

  return (
    <div
      style={{
        display: "grid",
        gap: "var(--gap2x)",
        justifyItems: "center",
      }}
      className="integration-list-item"
    >
      {integrationList?.map((item, key) => (
        <IntegrationListItem key={key} item={item} />
      ))}
    </div>
  );
};

export default IntegrationList;
