import { Button, Dialog, DialogActions, DialogContent, DialogContentText } from "@mui/material";

const AllSendDialog = ({ open, setOpen, handleSubmit }) => {
  const handleClose = () => {
    setOpen(false);
  };

  const handleApprove = () => {
    handleSubmit();
    handleClose();
  };

  return (
    <Dialog
      open={open}
      onClose={handleClose}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
    >
      <DialogContent>
        <DialogContentText id="alert-dialog-description">
          Seçtiğiniz gönderileri toplu olarak oluşturuyoruz. Bu işleme devam etmek istiyor musunuz?
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button className="iyzi-button-gray" onClick={handleClose}>
          Hayır
        </Button>
        <Button className="iyzi-button-blue" onClick={handleApprove} autoFocus>
          Evet
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default AllSendDialog;
