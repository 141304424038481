import { TableCell, TableRow } from "@mui/material";
import CurrencySymbol from "../../../utils/CurrencySymbol";
import IyziBalanceDescription from "../../../utils/IyziBalanceDescription";
import LocalPrice from "../../../utils/LocalPrice";
import FormatDateHour from "../../../utils/FormatDateHour";
import NavigateParcel from "../../../utils/NavigateParcel";

const MyWalletiyziBalanceTableBody = ({ item, total }) => {
  return (
    <TableRow
      sx={{
        ":hover": {
          backgroundColor: "var(--pastelBlue)",
          transition: "all 150ms linear",
        },
      }}
    >
      <TableCell>
        {item?.get_parcel?.parcel_ref_no ? (
          <NavigateParcel
            parcel={item?.get_parcel?.parcel_ref_no}
            status={item?.get_parcel?.status}
          />
        ) : (
          "---"
        )}
      </TableCell>
      <TableCell>{FormatDateHour(item.created_at)}</TableCell>
      <TableCell>{IyziBalanceDescription(Number(item.description_code))}</TableCell>
      <TableCell
        sx={{
          color:
            Number(item.receivable_balance) < 0
              ? "var(--shipmentRed)"
              : Number(item.receivable_balance) === 0 ||
                (item.description_code !== "11.00" &&
                  item.description_code !== "9.00" &&
                  item.description_code !== "15.00")
              ? "rgba(0, 0, 0, 0.87) !important"
              : item.description_code === "11.00" || item.description_code === "9.00"
              ? "var(--shipmentRed)"
              : "var(--shipmentRed)",
        }}
      >
        {CurrencySymbol(item.currency)}
        {Number(item.receivable_balance) < 0 ||
        item.description_code === "11.00" ||
        item.description_code === "9.00" ||
        item.description_code === "15.00"
          ? `${LocalPrice(item.receivable_balance)}`
          : LocalPrice(0)}{" "}
      </TableCell>
      <TableCell
        sx={{
          color:
            Number(item.debt_balance) > 0 ||
            (Number(item.debt_balance) !== 0 &&
              item.description_code !== "10.10" &&
              item.description_code !== "10.10" &&
              item.description_code !== "10.20" &&
              item.description_code !== "11.00")
              ? "#86B817"
              : "rgba(0, 0, 0, 0.87)",
        }}
      >
        {CurrencySymbol(item.currency)}
        {Number(item.debt_balance) > 0 ||
        (item.description_code !== "10.10" &&
          item.description_code !== "10.10" &&
          item.description_code !== "10.20" &&
          item.description_code !== "11.00")
          ? `${LocalPrice(item.debt_balance)}`
          : LocalPrice(0)}
      </TableCell>
      {/* <TableCell
        sx={{
          color: Number(item.calculated_total_balance) < 0 ? "var(--shipmentRed)" : "var(--info)",
        }}
      >
        {LocalPrice(item.calculated_total_balance)} {CurrencySymbol(item.currency)}
      </TableCell> */}
    </TableRow>
  );
};

export default MyWalletiyziBalanceTableBody;
