import { Avatar, Button, ButtonBase } from "@mui/material";
import { useCallback } from "react";
import EditIcon from "@mui/icons-material/Edit";
import PhotoSelect from "./PhotoSelect";
import {
  memberPhotoState,
  photoSelectModalState,
  resetPasswordModalState,
} from "../../../atoms/membership";
import { useRecoilValue, useSetRecoilState } from "recoil";
import ResetPassword from "./ResetPassword";
import KeyIcon from "@mui/icons-material/Key";
import PhotoCameraOutlinedIcon from "@mui/icons-material/PhotoCameraOutlined";

const PhotoUpload = () => {
  const memberPhoto = useRecoilValue(memberPhotoState);

  const setPhotoSelectModal = useSetRecoilState(photoSelectModalState);
  const setResetPasswordModal = useSetRecoilState(resetPasswordModalState);

  // const langChangeHandler = async (e) => {
  //   if (e) e.preventDefault();

  //   const data = {
  //     lang: lang,
  //   };

  //   await axios
  //     .post("profile/change-lang", data, config)
  //     .then((response) => {
  //       toast.success(response.data.message);
  //     })
  //     .catch((error) => Errors(error))
  // };

  const handleEditClick = useCallback(() => {
    setPhotoSelectModal(true);
  }, [setPhotoSelectModal]);

  const handleResetPassword = useCallback(() => {
    setResetPasswordModal(true);
  }, [setResetPasswordModal]);

  return (
    <div className="photo-upload-wrapper">
      <PhotoSelect />
      <ResetPassword />
      <div className="photo-upload">
        <ButtonBase className="photo-upload-buttonbase" color="primary" onClick={handleEditClick}>
          <Avatar src={`data:image/png;base64,${memberPhoto}`} className="photo-upload-avatar" />
          <div className="photo-upload-icon-wrapper">
            <EditIcon className="photo-upload-icon" />
          </div>
          <div className="photo-edit-icon-wrapper">
            <PhotoCameraOutlinedIcon className="photo-edit-icon" />
          </div>
        </ButtonBase>
      </div>
      <div className="photo-upload-buttons">
        <Button className="iyzi-button-blue" onClick={handleResetPassword}>
          <KeyIcon /> Şifremi Değiştir
        </Button>
      </div>
    </div>
  );
};

export default PhotoUpload;
