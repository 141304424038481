import React, { useState, useEffect } from 'react';
import { useRecoilState } from 'recoil';
import { selectedFastShipmentProductState, selectedFilesIntState } from '../../../atoms/fastShipment';
import { Modal, Box, Button, ThemeProvider, createTheme } from '@mui/material';
import CancelOutlinedIcon from '@mui/icons-material/CancelOutlined';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import { useDropzone } from 'react-dropzone';
import PackageListItem from './PackageListItem';
import PackageListItemInfo from './PackageListItemInfo';
import DowloadPdfView from '../../dowloadPdfView/DowloadPdfView';
import uploadIcon from '../../../img/icons/upload_icon.svg';
import '../../../style/fastShipment/PackageList.css';

const theme = createTheme({
  components: {
    MuiModal: {
      styleOverrides: {
        root: {
          margin: '20px',
          overflow: 'auto',
        },
      },
    },
    MuiSelect: {
      styleOverrides: {
        outlined: {
          display: 'flex',
          alignItems: 'center',
          gap: '10px',
        },
      },
    },
    MuiFormControlLabel: {
      styleOverrides: {
        label: {
          fontSize: '14px',
          opacity: 0.8,
        },
      },
    },
    MuiOutlinedInput: {
      styleOverrides: {
        root: {
          borderRadius: '10px',
        },
      },
    },
  },
});

const PackageListModal = ({
  openPackageList,
  setOpenPackageList,
  handleCheckPrice,
  priceIsDisabled,
  setSelectedPriceObject,
  setUpdatedCommoduty,
  setOpenPriceUpdate,
  setOpenPriceUpdateConfirmation,
  setPriceID,
  setPriceIDValue,
  itemID,
  setProforma,
  selectedCountryForIoss,
  bulkPrice,
  setBulkPrice,
  setTotalPriceCalc
}) => {
  const [selectedFastShipmentProduct, setSelectedFastShipmentProduct] = useRecoilState(
    selectedFastShipmentProductState
  );
  const [sendFiles, setSendFiles] = useRecoilState(selectedFilesIntState);
  const [selectedFiles, setSelectedFiles] = useState([]);
  const [isEditable, setIsEditable] = useState(false);
  const [initialTotalPrice, setInitialTotalPrice] = useState(0);
  const [totalPriceAll, setTotalPriceAll] = useState(0);

  useEffect(() => {
    const totalPrice = selectedFastShipmentProduct
      .filter((item) => item.price)
      .map((item) => parseFloat(item.price))
      .reduce((total, price) => total + price, 0);
    setInitialTotalPrice(totalPrice);
  }, [selectedFastShipmentProduct]);

  const handleClose = () => {
    setIsEditable(false);
    setOpenPackageList(false);
    setSelectedFastShipmentProduct([]);
    setSendFiles([]);
  };

  const handleDrop = (acceptedFiles) => {
    const newFiles = acceptedFiles.map((file) => ({
      file,
      id: Math.random().toString(),
    }));
    setSendFiles((prevFiles) => [...prevFiles, ...newFiles]);
    setSelectedFiles((prevFiles) => [...prevFiles, ...newFiles]);
  };

  const { getRootProps, getInputProps } = useDropzone({
    onDrop: handleDrop,
    multiple: true,
    accept: {
      'application/pdf': ['.pdf'],
    },
  });

  const handleSetState = (id) => {
    setSendFiles((prevFiles) => prevFiles.filter((file) => file.id !== id));
    setSelectedFiles((prevFiles) => prevFiles.filter((file) => file.id !== id));
  };

  const totalQuantityAll = selectedFastShipmentProduct
    .filter((item) => item.quantity)
    .map((item) => parseFloat(item.quantity))
    .reduce((total, quantity) => total + quantity, 0);

  useEffect(() => {
    const totalPriceAll = selectedFastShipmentProduct
      .filter((item) => item.price)
      .map((item) => parseFloat(item.price))
      .reduce((total, price) => total + price, 0);

    setTotalPriceAll(totalPriceAll);
  }, [selectedFastShipmentProduct]);

  const allCurrenciesSame = selectedFastShipmentProduct?.every(
    (product) => product?.currency === selectedFastShipmentProduct[0]?.currency
  );

  return (
    <ThemeProvider theme={theme}>
      <Modal open={openPackageList} onClose={handleClose}>
        <div className="package-list">
          <PackageListItemInfo />
          <CancelOutlinedIcon className="iyzi-icon-close" onClick={handleClose} />
          <Box sx={{ fontSize: '20px', fontWeight: '500', paddingLeft: '5px' }}>
            Proforma Detayları
          </Box>
          {selectedFastShipmentProduct?.map((product, key) => (
            <PackageListItem
              selectedCountryForIoss={selectedCountryForIoss}
              key={key}
              index={key}
              itemID={itemID}
              product={product}
              initialTotalPrice={initialTotalPrice}
              setInitialTotalPrice={setInitialTotalPrice}
              totalPriceAll={totalPriceAll}
              totalQuantityAll={totalQuantityAll}
              selectedFastShipmentProduct={selectedFastShipmentProduct}
              setSelectedFastShipmentProduct={setSelectedFastShipmentProduct}
              setUpdatedCommoduty={setUpdatedCommoduty}
              isEditable={isEditable}
              handleClose={handleClose}
              setOpenPriceUpdate={setOpenPriceUpdate}
              setOpenPriceUpdateConfirmation={setOpenPriceUpdateConfirmation}
              setPriceID={setPriceID}
              setPriceIDValue={setPriceIDValue}
              setSelectedPriceObject={setSelectedPriceObject}
              openPackageList={openPackageList}
              setOpenPackageList={setOpenPackageList}
              handleCheckPrice={handleCheckPrice}
              priceIsDisabled={priceIsDisabled}
              allCurrenciesSame={allCurrenciesSame}
              setProforma={setProforma}
              isLastItem={key === selectedFastShipmentProduct.length - 1}
              isFirstItem={key === 0}
              bulkPrice={bulkPrice}
              setBulkPrice={setBulkPrice}
              setTotalPriceCalc={setTotalPriceCalc}
            />
          ))}
          {Number(selectedFastShipmentProduct[0]?.parcel_type) === 3 && (
            <div
              style={{
                boxShadow: 'var(--shadow)',
                padding: '10px',
                display: 'flex',
                flexDirection: 'column',
                gap: 'var(--gap2x)',
                borderRadius: 'var(--inputBorderRadius)',
              }}
            >
              <div
                style={{
                  backgroundColor: 'var(--pastelBlue)',
                  padding: '10px 20px',
                  borderRadius: 'var(--wrapperBorderRadius)',
                  display: 'flex',
                  alignItems: 'center',
                  gap: '5px',
                  color: 'var(--btnBgBlue)',
                }}
              >
                <InfoOutlinedIcon sx={{ color: 'inherit', fontWeight: 600 }} />
                <div style={{ color: 'inherit' }}>
                  <span style={{ fontWeight: 600 }}>
                    Talep edilen evraklar;&nbsp;&nbsp;&nbsp;&nbsp;
                  </span>
                  <span style={{ fontWeight: 300, color: "red" }}>Türkçe E-arşiv fatura (ZORUNLU)</span>
                </div>
              </div>
              <Box className="dropzone-wrapper">
                <Box className="dropzone">
                  <Button className="dropzone-area" {...getRootProps()}>
                    <input {...getInputProps()} />
                    <div className="dropzone-area-text">
                      <img src={uploadIcon} alt="Upload Icon" />
                      <span className="dropzone-area-text-headline">
                        Dosyayı sürükleyip bırakın veya{' '}
                        <span style={{ color: 'var(--btnBgBlue)' }}>Gözat</span>
                      </span>
                      <span className="dropzone-area-text-sub">
                        Desteklenen Formatlar: PDF
                      </span>
                    </div>
                  </Button>
                </Box>
                <DowloadPdfView sendFiles={sendFiles} handleSetState={handleSetState} />
              </Box>
            </div>
          )}
        </div>
      </Modal>
    </ThemeProvider>
  );
};

export default PackageListModal;
