import "../../../style/globals/PageHeaderTitle.css";
import HelpOutlineOutlinedIcon from "@mui/icons-material/HelpOutlineOutlined";
import WrapperStyle from "../../../utils/WrapperStyle";
import LowPriorityIcon from "@mui/icons-material/LowPriority";

const ArrangementTitle = () => {
  return (
    <div className="page-header-title" style={WrapperStyle()}>
      <div className="page-header-title-wrapper">
        <div className="page-header-title-headline">
          <LowPriorityIcon
            sx={{
              width: "24px!important",
              height: "24px!important",
            }}
          />
          <h1>Tazmin İşlemleri</h1>
        </div>
        <div className="page-header-title-how-link">
          <HelpOutlineOutlinedIcon />
          <span>Bu Sayfa Nasıl Kullanılır?</span>
        </div>
      </div>
    </div>
  );
};

export default ArrangementTitle;
