import { CircularProgress, Divider } from "@mui/material";
import { useState } from "react";
import DeleteOutlineOutlinedIcon from "@mui/icons-material/DeleteOutlineOutlined";
import axios from "../../../api/axios";
import { toast } from "react-toastify";
import { useRecoilState } from "recoil";
import { integrationsState } from "../../../atoms/membership";
import { useCallback } from "react";
import Errors from "../../../utils/Errors";
import IOSSDeleteDialog from "./IOSSDeleteDialog";

const IossNumberListItem = ({ integration }) => {
  const [deleteLoading, setDeleteLoading] = useState(false);
  const [IOSSDelete, setIOSSDelete] = useState(false);

  const [integrations, setIntegrations] = useRecoilState(integrationsState);

  const handleDeleteIossListItem = useCallback(() => {
    setDeleteLoading(true);

    axios
      .post(`member-ioss-number/delete/${integration.id}`)
      .then(() => {
        toast.success("IOSS/VAT Numaranız Sistemden Silindi", { autoClose: 500 });
        setIntegrations(integrations.filter((i) => i.id !== integration.id));
      })
      .catch((error) => Errors(error));
  }, [integration, integrations, setIntegrations]);

  const handleDeleteDialog = () => {
    setIOSSDelete(true);
  };

  return (
    <>
      <IOSSDeleteDialog
        handleDelete={handleDeleteIossListItem}
        IOSSDelete={IOSSDelete}
        setIOSSDelete={setIOSSDelete}
      />
      <div
        style={
          integration.status === "1"
            ? {
                padding: "10px",
                display: "flex",
                alignItems: "center",
                justifyContent: "space-between",
                minHeight: "33px",
              }
            : {
                padding: "10px",
                display: "flex",
                alignItems: "center",
                justifyContent: "space-between",
                minHeight: "33px",
                backgroundColor: "var(--pastelBlue)",
              }
        }
      >
        <img
          style={{ height: "30px" }}
          loading="lazy"
          src={`/images/integrations/${integration.market_place}.svg`}
          alt={integration.market_place}
        />
        {integration.status === "1" ? (
          deleteLoading ? (
            <div
              style={{
                height: "20px",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <CircularProgress sx={{ height: "20px !important", width: "20px !important" }} />
            </div>
          ) : (
            <DeleteOutlineOutlinedIcon
              onClick={handleDeleteDialog}
              sx={{
                color: "var(--redBrand)",
                transition: "all 150ms linear",
                cursor: "pointer",
                ":hover": { color: "var(--notRed)" },
              }}
            />
          )
        ) : (
          ""
        )}
      </div>
      <Divider
        sx={{
          height: "1px",
          width: "100%",
          backgroundColor: "var(--inputBorderColor)",
        }}
      />
      <div
        style={{
          padding: "10px",
          display: "flex",
          flexWrap: "wrap",
          alignItems: "center",
          minHeight: "58px",
          gap: "5px",
        }}
      >
        <span style={{ fontSize: "14px", color: "var(--priceText)" }}>IOSS/VAT Numarası:</span>
        <span style={{ fontSize: "14px", color: "var(--priceText)" }}>
          {integration.ioss_number}
        </span>
      </div>
    </>
  );
};

export default IossNumberListItem;
