import AccountBalanceOutlinedIcon from "@mui/icons-material/AccountBalanceOutlined";
import Iframe from "react-iframe";

const AddressInfo = () => {
  return (
    <div className="info-item">
      <h2>
        <div
          style={{
            fontSize: "inherit",
            color: "inherit",
            display: "flex",
            flexWrap: "wrap",
            gap: "5px",
            alignItems: "center",
          }}
        >
          <AccountBalanceOutlinedIcon /> Adresimiz
        </div>
      </h2>
      <div className="info-address">
        <div className="info-address-text">
          <span>İYZİ BİLİŞİM A.Ş</span>
          <span>
            Güneşli, 15 Temmuz Mh. Koçman Cd. Demirkol Plaza, No:54E, 34212
            Bağcılar/İstanbul
          </span>
        </div>
        <Iframe
          className="info-address-iframe"
          src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3009.88933452746!2d28.809857011877217!3d41.027677018110225!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x14caa364aff9889d%3A0x471b6f9f92eb91f3!2siyziShip!5e0!3m2!1str!2str!4v1684687682460!5m2!1str!2str"
          style={{ border: 0 }}
          allowfullscreen=""
          loading="lazy"
          referrerPolicy="no-referrer-when-downgrade"
          title="iyziShip"
        />
      </div>
    </div>
  );
};

export default AddressInfo;
