import Box from "@mui/material/Box";
import {
  TextField,
  InputAdornment,
  Autocomplete,
  Button,
  FormControl,
  FormHelperText,
} from "@mui/material";
import { useCallback, useState, useEffect } from "react";
import { toast } from "react-toastify";
import SpringIn from "./SpringIn";
import { useRecoilState, useRecoilValue, useSetRecoilState } from "recoil";
import { startAnimationState } from "../../../atoms/startAnimation";
import axios from "../../../api/axios";
import DoneOutlinedIcon from "@mui/icons-material/DoneOutlined";
import CloseOutlinedIcon from "@mui/icons-material/CloseOutlined";
import CircularProgress from "@mui/material/CircularProgress";
import { useCookies } from "react-cookie";
import ErrorIcon from "@mui/icons-material/Error";
import { addressTypeState } from "../../../atoms/profileSettings";
import {
  profileReceiverAddressState,
  profileSenderAddressState,
  profileBookingAddressState,
} from "../../../atoms/membership";
import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/style.css";
import { validateEmail } from "../../../utils/emailValidation";
import Errors from "../../../utils/Errors";
import CountriesTurkishSorted from "../../../utils/CountriesTurkishSorted";

const CreateAddress = ({ memberHasBookingAddress, setMemberHasBookingAddress }) => {
  const userInfo = JSON.parse(localStorage.getItem("user"));

  const [startAnimation, setStartAnimation] = useRecoilState(startAnimationState);

  const setProfileSenderAddress = useSetRecoilState(profileSenderAddressState);
  const setProfileReceiverAddress = useSetRecoilState(profileReceiverAddressState);
  const setProfileBookingAddress = useSetRecoilState(profileBookingAddressState);
  const setAddressType = useSetRecoilState(addressTypeState);

  const [token] = useCookies(["jwt"]);
  const jwt = token?.jwt;

  const addressType = useRecoilValue(addressTypeState);

  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");

  const [header, setHeader] = useState("");
  const [address, setAddress] = useState("");
  const [address2, setAddress2] = useState("");
  const [address3, setAddress3] = useState("");
  const [phone, setPhone] = useState("");
  const [email, setEmail] = useState("");
  const [zipCode, setZipCode] = useState("");
  const [state, setState] = useState("");
  const [district, setDistrict] = useState("");
  const [city, setCity] = useState("");
  const [selectedCountry, setSelectedCountry] = useState("TR");
  const [stateCode, setStateCode] = useState("");

  const [zipLoading, setZipLoading] = useState(false);
  const [zipIsDone, setZipIsDone] = useState(null);
  const [requestLoading, setRequestLoading] = useState(false);

  //* Error */

  const [address1Errors, setAddress1Errors] = useState([]);
  const [cityErrors, setCityErrors] = useState([]);
  const [stateErrors, setStateErrors] = useState([]);
  const [townErrors, setTownErrors] = useState([]);
  const [zipCodeError, setZipCodeError] = useState(false);
  const [phoneIsNotValid, setPhoneIsNotValid] = useState(false);

  //* */

  useEffect(() => {
    if (startAnimation) {
      if (userInfo?.user?.user_member?.member_type === "Individual" && Number(addressType) === 1) {
        setFirstName(userInfo?.user.name);
        setLastName(userInfo?.user.surname);
        setEmail(userInfo?.user.email);
      } else if (
        userInfo?.user?.user_member?.member_type === "Corporate" &&
        Number(addressType) === 1
      ) {
        setFirstName(userInfo?.user?.user_member?.name.split(" ")[0]);
        setLastName(userInfo?.user?.user_member?.name.split(" ")[1]);
      }

      if (Number(addressType) === 5) {
        setFirstName("");
        setLastName("");
        setEmail("");
      } else if (Number(addressType) === 4) {
        setFirstName(userInfo?.user?.name);
        setLastName(userInfo?.user?.surname);
        setEmail(userInfo?.user?.email);
      }
    } else return;
  }, [startAnimation]);

  const addressRegisterHandler = async (e) => {
    if (e) e.preventDefault();

    const data = {
      //Todo first_name ve last_name gelince parametrelerde contact_name kaldırılacak yerine first_name ve last_name gelecek.
      contact_name: `${firstName} ${lastName}`,
      email: email,
      address_description: header,
      address1: address,
      address2: address2,
      address3: address3,
      postal_code: zipCode,
      phone: phone,
      town: district,
      city: city,
      state: state,
      country: selectedCountry,
      state_code: stateCode,
      address_type: Number(addressType).toFixed(0),
    };

    if (phone.length < 6) {
      setPhoneIsNotValid(true);
    } else {
      setPhoneIsNotValid(false);
      setRequestLoading(true);
      await axios
        .post("member-address/add", data, {
          headers: {
            Authorization: `Bearer ${jwt}`,
          },
        })
        .then((res) => {
          toast.success(res.data.message);
          axios
            .get("member-address")
            .then((response) => {
              setProfileSenderAddress(response.data.data.filter((e) => e.address_type === "4"));
              setProfileReceiverAddress(response.data.data.filter((e) => e.address_type === "5"));
              setProfileBookingAddress(response.data.data.filter((e) => e.address_type === "2"));
              setRequestLoading(false);
              setStartAnimation(false);

              if (memberHasBookingAddress === false) {
                setMemberHasBookingAddress(true);
              }
            })
            .catch((error) => {
              Errors(error);
            });
        })
        .catch((error) => {
          setRequestLoading(false);
          Errors(error);

          if (error.response.data.status === 400) {
            const errorMessages = error.response.data.errors;
            if (errorMessages?.address1.length !== 0) {
              setAddress1Errors(errorMessages.address1);
            }
            if (errorMessages?.city.length !== 0) {
              setCityErrors(errorMessages.city);
            }
            if (errorMessages?.state.length !== 0) {
              setStateErrors(errorMessages.state);
            }
            if (errorMessages?.town.length !== 0) {
              setTownErrors(errorMessages.town);
            }
          }
        })
        .finally(() => {
          setHeader("");
          setFirstName("");
          setLastName("");
          setEmail("");
          setAddress("");
          setAddress2("");
          setAddress3("");
          setSelectedCountry("TR");
          setZipCode("");
          setPhone("");
          setState("");
          setDistrict("");
          setCity("");
          setStateCode("");
        });
    }
  };

  const zipCodeHandler = useCallback(
    async (value) => {
      if (zipCode.length === 0 && selectedCountry.length !== 0) {
        setZipIsDone(null);
      } else if (zipCode.length !== 0) {
        setZipLoading(true);
        setZipIsDone(null);
        await axios
          .post("get-geo-details", {
            postalcode: zipCode,
            country: selectedCountry,
          })
          .then((res) => {
            setZipLoading(false);
            if (res.data.data.postalcodes.length === 0) {
              setState("");
              setDistrict("");
              setCity("");
              setStateCode("");
              setZipIsDone(false);
              setZipCodeError(false);
            } else {
              setZipIsDone(true);
              setState(res.data.data.postalcodes[0].placeName);
              setDistrict(
                res.data.data.postalcodes[0].adminName2
                  ? res.data.data.postalcodes[0].adminName2
                  : res.data.data.postalcodes[0].placeName
              );
              setCity(res.data.data.postalcodes[0].adminName1);
              setStateCode(res.data.data.postalcodes[0].adminCode1);
              setZipCodeError(false);
            }
          })
          .catch((error) => {
            Errors(error);
            setZipLoading(false);
            setZipIsDone(false);
          });
      } else {
        setZipIsDone(null);
      }
    },
    [selectedCountry, zipCode]
  );

  const handleClose = useCallback(() => {
    setStartAnimation(false);

    setHeader("");
    setFirstName("");
    setLastName("");
    setEmail("");
    setAddress("");
    setAddress2("");
    setSelectedCountry("TR");
    setZipCode("");
    setPhone("");
    setState("");
    setDistrict("");
    setCity("");
    setStateCode("");
    setAddressType("");
  }, [setStartAnimation, setAddressType]);

  const handlePhoneChange = (e) => {
    setPhoneIsNotValid(false);
    setPhone(e);
  };

  useEffect(() => {
    if (Number(addressType) === 2) {
      setZipCode("34");
    }
  }, [addressType]);

  const [emailError, setEmailError] = useState(false);
  const [isEmailValid, setIsEmailValid] = useState(true);

  const handleEmailChange = (e) => {
    const newEmail = e.target.value;

    setEmail(newEmail);

    if (!newEmail) {
      setIsEmailValid(false);
    } else {
      const isValidEmail = validateEmail(newEmail);
      setIsEmailValid(isValidEmail);
    }
  };

  useEffect(() => {
    setEmailError(!isEmailValid);
  }, [isEmailValid]);

  return (
    <>
      {startAnimation && (
        <SpringIn>
          <form
            onSubmit={addressRegisterHandler}
            style={{
              display: "grid",
              gap: "var(--gap2x)",
              marginTop: "var(--gap2x)",
            }}
          >
            <Box
              sx={{
                display: "flex",
                justifyContent: "space-between",
                flexWrap: "wrap",
                gap: "var(--gap2x)",
              }}
            >
              <TextField
                value={header}
                onChange={(e) => setHeader(e.target.value)}
                className="new-address-header"
                placeholder="Ev"
                size="small"
                required
                label="Başlık"
              />
            </Box>
            {addressType === "1" ? (
              <Box
                style={{
                  width: "100%",
                  color: "var(--info)",
                  border: "1px solid var(--info)",
                  padding: "10px",
                  display: "flex",
                  alignItems: "center",
                  borderRadius: "var(--buttonBorderRadius)",
                  gap: "5px",
                  position: "relative",
                  boxShadow: "0 0 4px",
                }}
              >
                <ErrorIcon />
                Fatura adresi ekliyorsunuz. Yalnızca bir (1) fatura adresiniz olabilir. Değiştirmek
                isterseniz sistem onayından sonra mali bilgileriniz değiştirilecektir!
              </Box>
            ) : addressType === "2" ? (
              <Box
                style={{
                  width: "100%",
                  color: "var(--info)",
                  border: "1px solid var(--info)",
                  padding: "10px",
                  display: "flex",
                  alignItems: "center",
                  borderRadius: "var(--buttonBorderRadius)",
                  gap: "5px",
                  position: "relative",
                  boxShadow: "0 0 4px",
                }}
              >
                <ErrorIcon />
                Toplama adresi ekliyorsunuz. Yalnızca bir (1) toplama adresiniz olabilir.
                Değiştirmek isterseniz talebiniz sistem onayına düşecek ve onaylandıktan sonra
                değiştirilecektir.
              </Box>
            ) : (
              ""
            )}
            <Box className="new-address-modal-wrapper">
              <TextField
                required
                value={firstName}
                onChange={(e) => setFirstName(e.target.value)}
                fullWidth
                size="small"
                label="İsim"
              />
              <TextField
                required
                value={lastName}
                onChange={(e) => setLastName(e.target.value)}
                fullWidth
                size="small"
                label="Soyisim"
              />

              <TextField
                value={email}
                required
                onChange={handleEmailChange}
                error={emailError}
                helperText={emailError && "Geçerli bir email giriniz."}
                size="small"
                label="E-Posta"
                type="email"
                autoComplete="off"
              />
            </Box>
            <TextField
              required
              value={address}
              onChange={(e) => setAddress(e.target.value)}
              error={address1Errors?.length !== 0}
              helperText={address1Errors?.map((e) => e)}
              maxRows={4}
              inputProps={{ maxLength: 191 }}
              fullWidth
              size="medium"
              label="Adres Satırı"
            />
            <TextField
              value={address2}
              onChange={(e) => setAddress2(e.target.value)}
              maxRows={4}
              inputProps={{ maxLength: 191 }}
              fullWidth
              size="medium"
              label="2.Adres Satırı"
            />
            <Box className="new-address-modal-wrapper">
              <Autocomplete
                id="country-typing-select"
                fullWidth
                size="small"
                disabled={Number(addressType) === 2}
                sx={{ ":disabled": { opacity: 0.3 } }}
                options={CountriesTurkishSorted()}
                value={
                  CountriesTurkishSorted().find((option) => option.iso_code === selectedCountry) ||
                  null
                }
                onChange={(e, val) => {
                  if (val) {
                    setSelectedCountry(val.iso_code);
                    setZipCode("");
                    setCity("");
                    setDistrict("");
                    setState("");
                    setStateCode("");
                  } else {
                    setSelectedCountry("");
                  }
                }}
                autoHighlight
                openOnFocus={false}
                getOptionLabel={(option) => `${option.country_name_tr}, ${option.country_name_en}`}
                renderOption={(props, option) => (
                  <Box component="li" sx={{ "& > img": { mr: 2, flexShrink: 0 } }} {...props}>
                    <img
                      loading="lazy"
                      width="20"
                      src={`https://flagcdn.com/w20/${option.iso_code.toLowerCase()}.png`}
                      srcSet={`https://flagcdn.com/w40/${option.iso_code.toLowerCase()}.png 2x`}
                      alt=""
                    />
                    {option.country_name_tr}, {option.country_name_en}
                  </Box>
                )}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    label="Ülke Seçiniz"
                    required
                    name="unique-country-field"
                    value={selectedCountry}
                    inputProps={{
                      ...params.inputProps,
                      autoComplete: "off", // disable autocomplete and autofill
                    }}
                  />
                )}
              />
              <TextField
                required
                value={zipCode}
                onChange={(e) => setZipCode(e.target.value)}
                placeholder="06010"
                onBlur={zipCodeHandler}
                fullWidth
                size="small"
                label="Posta Kodu"
                error={zipCodeError}
                helperText={
                  zipCodeError && selectedCountry.length !== 0
                    ? "Seçili ülkede bu posta kodu geçerli değil."
                    : zipCodeError && selectedCountry.length === 0
                    ? "Bu posta kodu için ülke seçiniz."
                    : ""
                }
                InputProps={
                  zipIsDone === true
                    ? {
                        endAdornment: (
                          <InputAdornment position="end">
                            <DoneOutlinedIcon style={{ cozipCodeErrorlor: "green" }} />
                          </InputAdornment>
                        ),
                      }
                    : zipIsDone === false
                    ? {
                        endAdornment: (
                          <InputAdornment position="end">
                            <CloseOutlinedIcon style={{ color: "red" }} />
                          </InputAdornment>
                        ),
                      }
                    : zipIsDone === null && zipLoading === true
                    ? {
                        endAdornment: (
                          <InputAdornment position="end">
                            <CircularProgress style={{ width: "20px", height: "20px" }} />
                          </InputAdornment>
                        ),
                      }
                    : {}
                }
              />
              <FormControl fullWidth size="small">
                <PhoneInput
                  country={selectedCountry.toLowerCase()}
                  value={phone}
                  onChange={handlePhoneChange}
                  onEnterKeyPress={addressRegisterHandler}
                  isValid={(value, country) => {
                    if (value.match(/12345/)) {
                      return "Invalid value: " + value + ", " + country.name;
                    } else if (value.match(/1234/)) {
                      return false;
                    } else {
                      return true;
                    }
                  }}
                  inputProps={{
                    name: "phone",
                    required: true,
                    autoFocus: true,
                    id: "ind-phone-input",
                    style: {
                      height: "100%",
                      width: "100%",
                      minHeight: "40px",
                      maxHeight: "40px",
                      borderColor: phoneIsNotValid ? "var(--error)" : "var(--inputBorderColor)",
                    },
                  }}
                />
                {phoneIsNotValid && (
                  <FormHelperText sx={{ color: "var(--error)" }}>
                    Telefon numaranızı giriniz.
                  </FormHelperText>
                )}
              </FormControl>
            </Box>
            <Box className="new-address-modal-wrapper">
              <TextField
                required
                value={state}
                onChange={(e) => setState(e.target.value)}
                error={stateErrors?.length !== 0}
                helperText={stateErrors?.map((e) => e)}
                fullWidth
                size="small"
                label="Semt / Eyalet"
              />
              <TextField
                value={district}
                onChange={(e) => setDistrict(e.target.value)}
                error={townErrors?.length !== 0}
                helperText={townErrors?.map((e) => e)}
                fullWidth
                required
                size="small"
                label="İlçe"
              />
              <TextField
                required
                value={city}
                onChange={(e) => setCity(e.target.value)}
                error={cityErrors?.length !== 0}
                helperText={cityErrors?.map((e) => e)}
                fullWidth
                size="small"
                label="Şehir"
              />
              <TextField
                required
                value={stateCode}
                onChange={(e) => setStateCode(e.target.value)}
                fullWidth
                size="small"
                autoComplete="off"
                label="Eyalet Kodu"
              />
            </Box>
            <Box className="new-address-register" style={{ gap: "var(--gap)" }}>
              {requestLoading ? (
                <Box
                  sx={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    width: "108px",
                  }}
                >
                  <CircularProgress sx={{ width: "20px!important", height: "20px!important" }} />
                </Box>
              ) : (
                <Button className="iyzi-button-blue" type="submit">
                  Kaydet
                </Button>
              )}
              <Button className="iyzi-button-blue" type="button" onClick={handleClose}>
                İptal
              </Button>
            </Box>
          </form>
        </SpringIn>
      )}
    </>
  );
};

export default CreateAddress;
