import { TextField } from "@mui/material";

const ReceiverName = ({ receiverName, setReceiverName }) => {
  return (
    <TextField
      size="small"
      label="Müşteri Ad Soyad"
      value={receiverName}
      autoComplete="off"
      onChange={(e) => setReceiverName(e.target.value)}
    />
  );
};

export default ReceiverName;
