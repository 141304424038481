const SupplierServicesType = (type) => {
  if (type === "E") {
    return "Economy";
  } else if (type === "X") {
    return "Express";
  } else if (type === "P") {
    return "Postal";
  }
};

export default SupplierServicesType;
