import { Link } from "react-router-dom";
import ArrowRightAltIcon from "@mui/icons-material/ArrowRightAlt";

const Blog = () => {
  return (
    <section className="main-blog">
      <h2>Blog</h2>
      <p>
        E-ihracat, pazaryeri ve lojistik gibi konularda en güncel yazılar ve
        ipuçları iyziBlog’da.
      </p>
      <div className="blog-container">
        <div className="blog">
          <img src="/images/home/blog/black_friday.png" alt="Blog Görseli" />
          <div className="blog-text">
            <blockquote>E-ihracat</blockquote>
            <p>Black Friday İndirimleri ile satışlarınızı artırmanızı...</p>
            <Link
              to={
                "https://blog.iyziship.com/black-friday-indirimleri-ile-satislarinizi-artirmaniz-icin-6-ipucu/"
              }
              target="_blank"
            >
              Okumaya devam et <ArrowRightAltIcon />
            </Link>
          </div>
        </div>
        <div className="blog">
          <img src="/images/home/blog/search_cargo.png" alt="Blog Görseli" />
          <div className="blog-text">
            <blockquote>Lojistik</blockquote>
            <p>GTİP (HS Kodu) Nedir, GTİP Sorgulama nasıl yapılır?</p>
            <Link
              to={
                "https://blog.iyziship.com/gtip-nedir-gtip-sorgulama-nasil-yapilir/"
              }
              target="_blank"
            >
              Okumaya devam et <ArrowRightAltIcon />
            </Link>
          </div>
        </div>
        <div className="blog">
          <img src="/images/home/blog/usa_cargo.png" alt="Blog Görseli" />
          <div className="blog-text">
            <blockquote>Lojistik</blockquote>
            <p>Amerika’ya Kargo Nasıl Gönderilir?</p>
            <Link
              to={
                "https://blog.iyziship.com/amerikaya-kargo-nasil-gonderilir-yurt-disi-kargo/"
              }
              target="_blank"
            >
              Okumaya devam et <ArrowRightAltIcon />
            </Link>
          </div>
        </div>
      </div>
    </section>
  );
};

export default Blog;
