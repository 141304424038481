import CreateAddress from "../../profilesettings/addressInfo/CreateAddress";

const DomesticAddress = ({ memberHasBookingAddress, setMemberHasBookingAddress }) => {
  return (
    <div
      style={{
        maxWidth: "1400px",
        width: "100%",

        padding: "20px",
        backgroundColor: "var(--lighterBg)",
        borderRadius: "var(--wrapperBorderRadius)",
        boxShadow: "var(--shadow)",
        margin: "auto",
      }}
    >
      <CreateAddress
        memberHasBookingAddress={memberHasBookingAddress}
        setMemberHasBookingAddress={setMemberHasBookingAddress}
      />
    </div>
  );
};

export default DomesticAddress;
