import React from "react";

const ResetHeadline = () => {
  return (
    <div className="forgot-headline">
      <h1>Şifremi Yenile</h1>
      <p>
        Parolanızı yenilememiz için gereken bilgileri kayıtlı olan e-posta
        adresinize gönderebiliriz.
      </p>
    </div>
  );
};

export default ResetHeadline;
