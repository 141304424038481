import { useInView, animated } from "@react-spring/web";
import NotificationBar from "../components/sidebar/NotificationBar";
import { Box } from "@mui/system";
import { DynamicTitle } from "../utils/DynamicTitle";
import WrapperStyle from "../utils/WrapperStyle";
import YurtIciInfo from "../components/dashInfo/YurtIciInfo";
import BankInfo from "../components/dashInfo/BankInfo";
import AddressInfo from "../components/dashInfo/AddressInfo";
// import PageTransition from "../utils/PageTransition";

const DashInfo = () => {
  DynamicTitle("Bilgiler | iyziShip");

  const [ref, springs] = useInView(
    () => ({
      from: {
        opacity: 0,
        y: 20,
      },
      to: {
        opacity: 1,
        y: 0,
      },
    }),
    {
      rootMargin: "-10% 0%",
    }
  );

  return (
    <section className="dashboard">
      <NotificationBar />
      <Box style={WrapperStyle()} className="dashboard-wrapper">
        <animated.div ref={ref} style={springs}>
          {/* <PageTransition> */}
            <div className="info">
              <YurtIciInfo />
              <BankInfo />
              <AddressInfo />
            </div>
          {/* </PageTransition> */}
        </animated.div>
      </Box>
    </section>
  );
};

export default DashInfo;
