import { Box, Button, createTheme, Step, StepLabel, Stepper, ThemeProvider } from "@mui/material";
import { useInView, animated } from "@react-spring/web";
import { useEffect } from "react";
import { ToastContainer } from "react-toastify";
import { useRecoilState, useRecoilValue } from "recoil";
import NotificationBar from "../components/sidebar/NotificationBar";
import { DynamicTitle } from "../utils/DynamicTitle";
import "../style/createShipment/CreateShipment.css";
import { useCookies } from "react-cookie";
import axios from "../api/axios";
import Inventory2OutlinedIcon from "@mui/icons-material/Inventory2Outlined";
import StraightenOutlinedIcon from "@mui/icons-material/StraightenOutlined";
import LocalOfferOutlinedIcon from "@mui/icons-material/LocalOfferOutlined";
import BusinessOutlinedIcon from "@mui/icons-material/BusinessOutlined";
import ArticleOutlinedIcon from "@mui/icons-material/ArticleOutlined";
import PaymentOutlinedIcon from "@mui/icons-material/PaymentOutlined";
import styled from "@emotion/styled";
import PropTypes from "prop-types";
import {
  activeStepState,
  isShipmentPriceCalculationState,
  shipmentDocumentState,
} from "../atoms/createShipment";
import { isDefaultBillAddressState } from "../atoms/profileSettings";
import ShipmentInfo from "../components/createShipment/ShipmentInfo";
import ShipmentDimension from "../components/createShipment/ShipmentDimension";
import ShipmentPrice from "../components/createShipment/ShipmentPrice";
import WrapperStyle from "../utils/WrapperStyle";
// import PageTransition from "../utils/PageTransition";
import PriceCalculationTitle from "../components/priceCalculation/PriceCalculationTitle";

const theme = createTheme({
  components: {
    MuiStepIcon: {
      styleOverrides: {
        root: {
          "&$completed": {
            color: "green",
          },
        },
      },
    },
  },
});

const ColorlibStepIconRoot = styled("div")(({ theme, ownerState }) => ({
  backgroundColor: theme.palette.mode === "dark" ? theme.palette.grey[700] : "#ccc",
  zIndex: 1,
  color: "#fff",
  width: 35,
  height: 35,
  display: "flex",
  borderRadius: "50%",
  justifyContent: "center",
  alignItems: "center",
  ...(ownerState.active && {
    backgroundColor: "#2485DA",
    boxShadow: "0 4px 10px 0 rgba(0,0,0,.25)",
    transition: "all 150ms linear",
  }),

  ...(ownerState.completed && {
    backgroundColor: "var(--success)",
    transition: "all 150ms linear",
  }),
}));

const ColorlibStepIcon = (props) => {
  const { active, completed, className } = props;

  const icons = {
    1: <Inventory2OutlinedIcon />,
    2: <StraightenOutlinedIcon />,
    3: <LocalOfferOutlinedIcon />,
    4: <BusinessOutlinedIcon />,
    5: <ArticleOutlinedIcon />,
    6: <PaymentOutlinedIcon />,
  };

  return (
    <ColorlibStepIconRoot ownerState={{ completed, active }} className={className}>
      {icons[String(props.icon)]}
    </ColorlibStepIconRoot>
  );
};

ColorlibStepIcon.propTypes = {
  /**
   * Whether this step is active.
   * @default false
   */
  active: PropTypes.bool,
  className: PropTypes.string,
  /**
   * Mark the step as completed. Is passed to child components.
   * @default false
   */
  completed: PropTypes.bool,
  /**
   * The label displayed in the step icon.
   */
  icon: PropTypes.node,
};

const PriceCalculation = () => {
  DynamicTitle("Yurt Dışı Kargo Fiyat Hesaplama| iyziShip");

  const [token] = useCookies(["jwt"]);
  const jwt = token.jwt;
  axios.defaults.headers.common["Authorization"] = `Bearer ${jwt}`;

  const shipmentDocument = useRecoilValue(shipmentDocumentState);

  const [, setIsDefaultBillAddress] = useRecoilState(isDefaultBillAddressState);
  const [, setIsShipmentPriceCalculation] = useRecoilState(isShipmentPriceCalculationState);

  useEffect(() => {
    axios.get("member-address").then((response) => {
      setIsDefaultBillAddress(response.data.data);
    });
  }, [setIsDefaultBillAddress]);

  useEffect(() => {
    setIsShipmentPriceCalculation(true);
  }, [setIsShipmentPriceCalculation]);

  const [ref, springs] = useInView(
    () => ({
      from: {
        opacity: 0,
        y: 20,
      },
      to: {
        opacity: 1,
        y: 0,
      },
    }),
    {
      rootMargin: "-10% 0%",
    }
  );

  const [activeStep, setActiveStep] = useRecoilState(activeStepState);

  const handleBack = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
  };

  const handleReset = () => {
    window.location.reload(true);
  };

  const steps =
    shipmentDocument === "D"
      ? ["Gönderi Bilgileri", "Fiyat"]
      : ["Gönderi Bilgileri", "Ölçü", "Fiyat"];

  return (
    <ThemeProvider theme={theme}>
      <section className="createShipment">
        <NotificationBar />
        {/* <PageTransition> */}
          <ToastContainer />
          <PriceCalculationTitle />
          <Box className="createShipment-wrapper" style={WrapperStyle()}>
            <animated.div ref={ref} style={springs}>
              <Stepper activeStep={activeStep} className="createShipment-stepper">
                {steps.map((label, index) => {
                  const stepProps = {};
                  const labelProps = {};

                  return (
                    <Step
                      sx={{
                        "& .MuiStepLabel-root .Mui-completed": {
                          color: "var(--success)", // circle color (COMPLETED)
                        },
                      }}
                      key={index}
                      {...stepProps}
                    >
                      <StepLabel StepIconComponent={ColorlibStepIcon} {...labelProps}>
                        {label}
                      </StepLabel>
                    </Step>
                  );
                })}
              </Stepper>
              <div className="create-shipment-stepper-buttons">
                {activeStep === steps.length - 1 ? (
                  <div
                    className="create-shipment-stepper-buttons-container"
                    style={{ display: "flex", justifyContent: "space-between", gap: "var(--gap)" }}
                  >
                    <Button
                      className="iyzi-button"
                      disabled={activeStep === 0}
                      onClick={handleBack}
                    >
                      Önceki Adıma Dön
                    </Button>
                    <Button onClick={handleReset} className="iyzi-button">
                      Başka Seçenekler ile Yeniden Fiyat Al
                    </Button>
                  </div>
                ) : (
                  <div className="create-shipment-stepper-buttons-container">
                    <Button
                      className="iyzi-button"
                      disabled={activeStep === 0}
                      onClick={handleBack}
                    >
                      Önceki Adıma Dön
                    </Button>
                  </div>
                )}
              </div>
              {shipmentDocument === "D" &&
                (activeStep === 0 ? <ShipmentInfo /> : activeStep === 1 ? <ShipmentPrice /> : "")}
              {shipmentDocument !== "D" &&
                (activeStep === 0 ? (
                  <ShipmentInfo />
                ) : activeStep === 1 ? (
                  <ShipmentDimension />
                ) : activeStep === 2 ? (
                  <ShipmentPrice />
                ) : (
                  ""
                ))}
            </animated.div>
          </Box>
        {/* </PageTransition> */}
      </section>
    </ThemeProvider>
  );
};

export default PriceCalculation;
