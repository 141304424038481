import "../../style/profileSettings/RegisteredCards.css";
import { Box, ThemeProvider, createTheme } from "@mui/material";
import AddCardOutlinedIcon from "@mui/icons-material/AddCardOutlined";
import { useInView, animated } from "@react-spring/web";
import { DynamicTitle } from "../../utils/DynamicTitle";
import { useSetRecoilState } from "recoil";
import ProfileNavBar from "./ProfileNavBar";
import NotificationBar from "../sidebar/NotificationBar";
import { useCallback, useEffect } from "react";
import { ToastContainer } from "react-toastify";
import { useCookies } from "react-cookie";
import axios from "../../api/axios";
import WrapperStyle from "../../utils/WrapperStyle";
import ProfileSettingsStyle from "../../style/styleJs/ProfileSettingsStyle";
import { ibanListArrayState } from "../../atoms/profileSettings";
import Ibans from "./registeredIbans/Ibans";
import Errors from "../../utils/Errors";
import ProfileSettingsTitle from "./ProfileSettingsTitle";

const RegisteredIbans = () => {
  DynamicTitle("Banka Hesapları");

  const [token] = useCookies(["jwt"]);
  const jwt = token.jwt;
  axios.defaults.headers.common["Authorization"] = `Bearer ${jwt}`;

  const setIbanListArray = useSetRecoilState(ibanListArrayState);

  const theme = createTheme({
    components: {
      MuiCardContent: {
        styleOverrides: {
          root: {
            padding: "0 0 24px 0",
          },
        },
      },
    },
  });

  const [ref, springs] = useInView(
    () => ({
      from: {
        opacity: 0,
        y: 20,
      },
      to: {
        opacity: 1,
        y: 0,
      },
    }),
    {
      rootMargin: "-10% 0%",
    }
  );

  const fetchIbans = useCallback(() => {
    axios
      .get("/member-iban")
      .then((response) => {
        setIbanListArray(response.data.data);
      })
      .catch((error) => Errors(error));
  }, [setIbanListArray]);

  useEffect(() => {
    fetchIbans();
  }, [fetchIbans]);

  return (
    <ThemeProvider theme={theme}>
      <Box className="registered-cards-wrapper">
        <NotificationBar />
        <ProfileSettingsTitle />
        <ProfileNavBar />
        <ToastContainer />
        <div style={WrapperStyle()}>
          <animated.div ref={ref} style={springs}>
            <div style={ProfileSettingsStyle()} className="registered-cards">
              <div className="profile-card-wrapper">
                <div className="profile-card-header">
                  <AddCardOutlinedIcon
                    sx={{
                      width: "20px",
                      height: "20px",
                      color: "var(--priceText)",
                    }}
                  />
                  <span style={{ color: "var(--priceText)", fontWeight: 700 }}>
                    Banka Hesapları
                  </span>
                </div>
              </div>
              <Ibans />
            </div>
          </animated.div>
        </div>
      </Box>
    </ThemeProvider>
  );
};

export default RegisteredIbans;
