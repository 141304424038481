import { FormControl, InputLabel, MenuItem, Select } from "@mui/material";

const OrderParcelType = ({ orderParcelType, setOrderParcelType }) => {
  const handleChange = (e) => {
    setOrderParcelType(e.target.value);
  };

  return (
    <FormControl size="small">
      <InputLabel id="parcel-type-select">Gönderi Tipi</InputLabel>
      <Select
        labelId="parcel-type-select"
        id="parcel-type-field"
        name="parcel-type-field"
        value={orderParcelType}
        label="Gönderi Tipi"
        onChange={handleChange}
      >
        <MenuItem value={""}>Hepsi</MenuItem>
        <MenuItem value={"1"}>Normal Gönderi veya Numune</MenuItem>
        <MenuItem value={"2"}>Hediye</MenuItem>
        <MenuItem value={"3"}>Mikro (ETGB)</MenuItem>
      </Select>
    </FormControl>
  );
};

export default OrderParcelType;
