import { Avatar, Button, CircularProgress, Divider, IconButton, Modal } from "@mui/material";
import { useCallback, useRef, useState } from "react";
import { useRecoilState } from "recoil";
import { memberPhotoState, photoSelectModalState } from "../../../atoms/membership";
import heroPhoto from "../../../json/heroPhoto.json";
import AddIcon from "@mui/icons-material/Add";
import ConvertURLToFile from "../../../utils/ConverURLToFile";
import axios from "../../../api/axios";
import { toast } from "react-toastify";
import Errors from "../../../utils/Errors";

const PhotoSelect = () => {
  const [photoSelectModal, setPhotoSelectModal] = useRecoilState(photoSelectModalState);
  const [, setMemberPhoto] = useRecoilState(memberPhotoState);

  const fileInputRef = useRef(null);

  const [selectedFile, setSelectedFile] = useState(null);
  const [uploadedFileURL, setUploadedFileURL] = useState(null);
  const [loading, setLoading] = useState(false);

  const handleClose = useCallback(() => {
    setPhotoSelectModal(false);
    setSelectedFile(null);
    setUploadedFileURL(null);
  }, [setPhotoSelectModal]);

  const handleButtonClick = () => {
    fileInputRef.current.click();
  };

  const handleFileChange = (event) => {
    const file = event.target.files[0];
    setSelectedFile(event.target.files[0]);
    if (file) {
      const fileURL = URL.createObjectURL(file);
      setUploadedFileURL(fileURL);
    }
  };

  const handleDefaultFile = async (hero) => {
    const file = await ConvertURLToFile(hero.src, hero.name, "image/png");
    setSelectedFile(file);
  };

  const isContain = heroPhoto.find((e) => e.name === selectedFile?.name) ? true : false;

  const handleUploadPhoto = async () => {
    setLoading(true);
    const formData = new FormData();

    if (selectedFile) {
      formData.append("files[0]", selectedFile);
    }

    await axios
      .post("profile/update-photo", formData)
      .then((response) => {
        setMemberPhoto(response.data.data);
        toast.success(response.data.message);
        // LocalStorage'daki 'SS' verisini getir
        let user = JSON.parse(localStorage.getItem("user"));

        // 'user' objesinin 'photo' özelliğini yeni değeri ile güncelle
        if (user && user.user) {
          user.user.photo = response.data.data?.photo; // Buraya yeni fotoğraf URL'sini koyun

          // 'SS' verisini LocalStorage'a geri kaydet
          localStorage.setItem("user", JSON.stringify(user));
        }
      })
      .catch((error) => Errors(error))
      .finally(() => {
        setLoading(false);
        handleClose();
      });
  };

  return (
    <Modal
      sx={{ display: "flex", justifyContent: "center", alignItems: "center" }}
      open={photoSelectModal}
      onClose={handleClose}
    >
      <div
        style={{
          backgroundColor: "var(--background)",
          display: "flex",
          flexDirection: "column",
          gap: "var(--gap)",
          padding: "20px",
          margin: "20px",
          borderRadius: "var(--inputBorderRadius)",

          maxHeight: "90vh",
          overFlowY: "auto",
        }}
      >
        <div style={{ display: "flex", gap: "var(--gap)", alignItems: "center" }}>
          {heroPhoto?.map((hero) => (
            <Avatar
              sx={{
                width: "60px",
                height: "60px",
                border:
                  selectedFile?.name === hero.name
                    ? "2px solid var(--info)"
                    : "2px solid transparent",
                cursor: "pointer",
                transition: "all 150ms linear",
                ":hover": {
                  opacity: 0.8,
                  transition: "all 150ms linear",
                },
              }}
              key={hero.id}
              src={hero.src}
              onClick={() => handleDefaultFile(hero)}
            />
          ))}
          {!isContain && selectedFile && (
            <Avatar
              sx={{
                width: "60px",
                height: "60px",
                border: "2px solid var(--info)",
                objectFit: "contain",
                objectPosition: "center",
                cursor: "pointer",
              }}
              src={uploadedFileURL}
            />
          )}
          <div>
            <IconButton
              color="primary"
              sx={{
                width: "60px",
                height: "60px",
                backgroundColor: "var(--lighterBg)",
                transition: "all 150ms linear",
                ":hover": {
                  backgroundColor: "rgba(0, 0, 0, 0.1)",
                  transition: "all 150ms linear",
                },
              }}
              onClick={handleButtonClick}
            >
              <AddIcon />
            </IconButton>
            <input
              type="file"
              ref={fileInputRef}
              style={{ display: "none" }}
              accept=".png, .jpg, .jpeg"
              onChange={handleFileChange}
            />
          </div>
        </div>
        <Divider />
        {loading ? (
          <CircularProgress
            sx={{
              width: "34px!important",
              height: "34px!important",
              margin: "auto",
            }}
          />
        ) : (
          <Button
            disabled={!selectedFile}
            sx={{ ":disabled": { opacity: 0.3 } }}
            className="iyzi-button"
            onClick={handleUploadPhoto}
          >
            Profil Fotoğrafını Güncelle
          </Button>
        )}
      </div>
    </Modal>
  );
};

export default PhotoSelect;
