import { FormControl, InputLabel, MenuItem, Select } from "@mui/material";

const OrderTypeSearch = ({ orderType, setOrderType }) => {
  const handleChange = (e) => {
    setOrderType(e.target.value);
  };

  return (
    <FormControl size="small">
      <InputLabel id="order-type-select">Döküman Tipi</InputLabel>
      <Select
        labelId="order-type-select"
        id="order-type-field"
        name="order-type-field"
        value={orderType}
        label="Döküman Tipi"
        onChange={handleChange}
      >
        <MenuItem value={""}>Hepsi</MenuItem>
        <MenuItem value={"N"}>Paket</MenuItem>
        <MenuItem value={"D"}>Döküman</MenuItem>
      </Select>
    </FormControl>
  );
};

export default OrderTypeSearch;
