import { Dialog, DialogContentText, DialogTitle } from "@mui/material";
import { useRecoilState } from "recoil";
import { fastShipmentInfoModalState } from "../../../atoms/membership";
import WarningAmberIcon from "@mui/icons-material/WarningAmber";

const PackageListItemInfo = () => {
  const [fastShipmentInfoModal, setFastShipmentInfoModal] = useRecoilState(
    fastShipmentInfoModalState
  );

  const handleClose = () => {
    setFastShipmentInfoModal(false);
  };

  return (
    <Dialog open={fastShipmentInfoModal} onClose={handleClose}>
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          gap: "var(--gap2x)",
          padding: "10px 20px",
          backgroundColor: "var(--pastelRed)",
        }}
      >
        <DialogTitle
          style={{
            padding: 0,
            fontWeight: 700,
            display: "flex",
            alignItems: "center",
            color: "var(--shipmentRed)",
          }}
        >
          <WarningAmberIcon style={{ color: "var(--shipmentRed)" }} /> Türk Lirası ile Proforma
          Oluşturulamamaktadır!
        </DialogTitle>
        <DialogContentText style={{ padding: 0, fontWeight: 600, color: "var(--shipmentRed)" }}>
          Türk lirası cinsinden proforma oluşturulamamaktadır. Lütfen ürün tutarınızı ve döviz
          cinsini dolar, euro ya da pound olarak güncelleyiniz.
        </DialogContentText>
      </div>
    </Dialog>
  );
};

export default PackageListItemInfo;
