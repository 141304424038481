import { TableCell, TableRow } from "@mui/material";
import FormatDate from "../../../utils/FormatDate";
import IyziBalanceDescription from "../../../utils/IyziBalanceDescription";
import CurrencySymbol from "../../../utils/CurrencySymbol";
import LocalPrice from "../../../utils/LocalPrice";
import NavigateParcel from "../../../utils/NavigateParcel";

const descriptionCodeArray = [17, 19, 21, 22, 25];
const MyWalletExtractDetailTableBody = ({ row }) => {
  return (
    <TableRow>
      <TableCell>
        {row.created_at === null ? "---" : FormatDate(row.created_at)}{" "}
      </TableCell>
      <TableCell>
        {row.get_parcel === null ? (
          "---"
        ) : (
          <NavigateParcel
            parcel={row?.get_parcel?.parcel_ref_no}
            status={row?.get_parcel?.status}
          />
        )}
      </TableCell>
      <TableCell>
        {row.payment_type === null ? "---" : row?.payment_type}
      </TableCell>
      <TableCell>{row.payment_id === null ? "---" : row?.payment_id}</TableCell>
      <TableCell>
        {row.description_code === null
          ? "---"
          : IyziBalanceDescription(Number(row?.description_code))}
      </TableCell>
      <TableCell>
          {row.total_balance === null ? "---" :
              descriptionCodeArray.includes(Number(row.description_code))
                  ? `${LocalPrice(row?.debt_balance)} ${row.debt_balance !== null && CurrencySymbol(row.currency)}`
                  : `${LocalPrice(row?.total_balance)} ${row.total_balance !== null && CurrencySymbol(row.currency)}`}

      </TableCell>
      <TableCell>---</TableCell>
    </TableRow>
    /*<TableRow>
      <TableCell>
        {row.get_parcel === null
          ? "---"
          : FormatDate(
              row.get_parcel?.get_parcel_price_informations[0]?.get_details[0]
                ?.payment_date
            )}
      </TableCell>
      <TableCell>
        {row.get_parcel === null ? "---" : row.get_parcel?.parcel_ref_no}
      </TableCell>
      <TableCell>---</TableCell>
      <TableCell>
        {row.get_parcel === null
          ? "---"
          : row.get_parcel?.get_parcel_price_informations[0]?.get_details[0]
              ?.payment_id}
      </TableCell>
      <TableCell>{row.description_code}</TableCell>
      <TableCell>
        {row.get_parcel === null
          ? "---"
          : row.get_parcel?.get_parcel_price_informations[0]
              ?.paid_price_try}{" "}
        ₺
      </TableCell>
      <TableCell>---</TableCell>
    </TableRow>*/
  );
};

export default MyWalletExtractDetailTableBody;
