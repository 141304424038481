import { Table, TableBody, TableContainer } from "@mui/material";
import MyWalletArrangementTableHead from "./MyWalletArrangementTableHead";
import MyWalletArrangementTableBody from "./MyWalletArrangementTableBody";
import { useCallback, useEffect } from "react";
import axios from "../../../api/axios";
import { useRecoilState } from "recoil";
import {
  walletArrangementCurrentPageState,
  walletArrangementDataState,
  walletArrangementTotalPageState,
} from "../../../atoms/myWallet";
import Errors from "../../../utils/Errors";

const MyWalletArrangementTable = () => {
  const [walletArrangementData, setWalletArrangementData] = useRecoilState(
    walletArrangementDataState
  );
  const [, setWalletArrangementCurrentPage] = useRecoilState(walletArrangementCurrentPageState);
  const [, setWalletArrangementTotalPage] = useRecoilState(walletArrangementTotalPageState);

  const getData = useCallback(() => {
    axios
      .get(`refund-compensation`)
      .then((response) => {
        setWalletArrangementData(response.data.data);
        setWalletArrangementCurrentPage(response.data.currentPage);
        setWalletArrangementTotalPage(response.data.totalPages);
      })
      .catch((error) => Errors(error));
  }, [setWalletArrangementData, setWalletArrangementCurrentPage, setWalletArrangementTotalPage]);

  useEffect(() => {
    getData();
  }, [getData]);

  return (
    <TableContainer
      sx={{
        backgroundColor: "var(--lighterBg)",
        borderRadius: "var(--inputBorderRadius)",
        boxShadow: "0px 2px 10px rgba(0, 0, 0, 0.1)",
      }}
    >
      <Table className="my-wallet-arrangement-table">
        <MyWalletArrangementTableHead />
        <TableBody>
          {walletArrangementData.map((item, key) => (
            <MyWalletArrangementTableBody key={key} item={item} />
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
};

export default MyWalletArrangementTable;
