import React, { useState, useEffect, useCallback } from "react";
import axios from "../../../api/axios";
import { useRecoilState } from "recoil";
import { ibanListArrayState } from "../../../atoms/profileSettings";
import { toast } from "react-toastify";
import { Box } from "@mui/material";
import Autocomplete from "@mui/material/Autocomplete";
import banks from "../../../json/banks.json";
import { createTheme, TextField, ThemeProvider, Button, Modal, Typography } from "@mui/material";
import ModeEditOutlineOutlinedIcon from "@mui/icons-material/ModeEditOutlineOutlined";
import DeleteOutlineOutlinedIcon from "@mui/icons-material/DeleteOutlineOutlined";
import toTurkishUpperCase from "../../../utils/toTurkishUpperCase";
import Errors from "../../../utils/Errors";
import DeleteDialog from "./DeleteDialog";

const theme = createTheme({
  components: {
    MuiSelect: {
      styleOverrides: {
        outlined: {
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
        },
      },
    },
    MuiModal: {
      styleOverrides: {
        root: {
          outline: 0,
        },
      },
    },
    MuiOutlinedInput: {
      styleOverrides: {
        root: {
          borderRadius: "10px",
        },
      },
    },
    MuiPaper: {
      styleOverrides: {
        root: {
          maxHeight: "300px !important",
        },
      },
    },
    MuiInputBase: {
      styleOverrides: {
        input: {
          display: "flex",
          alignItems: "center",
          gap: "var(--gap)",
        },
      },
    },
  },
});

const IbanListItem = ({ iban }) => {
  const [ibanListArray, setIbanListArray] = useRecoilState(ibanListArrayState);
  const [ibans, setIbans] = useState([]);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [deleteDialog, setDeleteDialog] = useState(false);

  const [editingIbanId, setEditingIbanId] = useState(null);
  const [handIban, setHandIban] = useState("");
  const [ibanForm, setIbanForm] = useState({
    name: "",
    surname: "",
    iban: handIban,
    bank_name: "",
  });

  const [updateIbanForm, setUpdateIbanForm] = useState({
    name: "",
    surname: "",
    iban: "",
    bank_name: "",
  });

  const updatedIbanForm = {
    name: updateIbanForm.name,
    surname: updateIbanForm.surname,
    iban: updateIbanForm.iban,
    bank_name: updateIbanForm.bank_name,
  };

  const getFormattedFullName = (name, surname) => {
    const maxLength = 25;

    const formattedName = name
      .split(" ")
      .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
      .join(" ");

    if (formattedName.length <= maxLength && surname.length <= maxLength) {
      return `${formattedName} ${surname.toUpperCase()}`;
    }

    const shortenedName =
      formattedName.length <= maxLength
        ? formattedName
        : formattedName
            .split(" ")
            .map((n) => n.charAt(0).toUpperCase())
            .join(". ");

    return `${shortenedName} ${surname.toUpperCase()}`;
  };

  const listIbans = () => {
    return axios.get("/member-iban");
  };

  const updateIban = (id, data) => {
    return axios.post(`/member-iban/update/${id}`, data);
  };

  const fetchIbans = useCallback(() => {
    listIbans()
      .then((response) => {
        const ibansData = response.data.data;
        setIbans(ibansData);
        setIbanListArray(ibansData);
      })
      .catch((error) => Errors(error));
  }, [setIbanListArray]);

  const deleteIban = (id, token) => {
    return axios.post(`/member-iban/delete/${id}`);
  };

  const handleUpdateIban = (e) => {
    e.preventDefault();
    if (!editingIbanId) return;
    if (updatedIbanForm.iban.length < 32) {
      toast.error("Lütfen İban bilginizi tamamlayınız.");
      return;
    }
    updateIban(editingIbanId, updatedIbanForm)
      .then((response) => {
        toast.success(response.data.message, { autoClose: 500 });
        closeModal();
        fetchIbans();
        setIbanForm({ name: "", surname: "", iban: "", bank_name: "" });
      })
      .catch((error) => Errors(error));
  };

  const handleDeleteIban = () => {
    deleteIban(iban.id)
      .then((response) => {
        setIbanListArray(ibanListArray?.filter((item) => item !== iban));
        toast.success(response.data.message, { autoClose: 500 });
        fetchIbans();
      })
      .catch((error) => Errors(error));
  };

  const handleDeleteDialog = () => {
    setDeleteDialog(true);
  };

  const openModal = useCallback(
    (id) => {
      const ibanToEdit = ibans.find((item) => item.id === id);
      setEditingIbanId(id);
      setIbanForm({ ...ibanToEdit });
      setIsModalOpen(true);
    },
    [ibans]
  );

  const closeModal = () => {
    setIsModalOpen(false);
    setUpdateIbanForm({
      name: "",
      surname: "",
      iban: "",
      bank_name: "",
    });
    setEditingIbanId(null);
  };

  const handleEdit = useCallback(() => {
    const selectedIban = ibans?.find((e) => e.id === iban?.id);
    setUpdateIbanForm({
      name: selectedIban.name,
      surname: selectedIban.surname,
      iban: selectedIban.iban,
      bank_name: selectedIban.bank_name,
    });
    openModal(iban?.id);
  }, [openModal, ibans, iban]);

  const handleUpdateIbanForm = (input) => {
    const numbersOnly = input.replace(/[^0-9]/g, "");
    let formatted = numbersOnly.length > 0 ? "TR" : "";
    let notFormatted = numbersOnly.length > 0 ? "TR" : "";

    for (let i = 0; i < numbersOnly.length; i++) {
      if (i === 2 || i === 6 || i === 10 || i === 14 || i === 18 || i === 22 || i === 26) {
        formatted += " ";
        notFormatted += "";
      }
      formatted += numbersOnly[i];
      notFormatted += numbersOnly[i];
    }

    formatted = formatted.replace(/\s+/g, " ").trim();
    notFormatted = notFormatted.replace(/\s+/g, "");
    setUpdateIbanForm((prevState) => ({ ...prevState, iban: formatted }));
    setHandIban(notFormatted);
  };

  useEffect(() => {
    fetchIbans();
  }, [fetchIbans]);

  return (
    <ThemeProvider theme={theme}>
      <div className="ibans-display" style={{ width: "300px" }}>
        <DeleteDialog
          deleteDialog={deleteDialog}
          setDeleteDialog={setDeleteDialog}
          handleDeleteIban={handleDeleteIban}
        />
        <div
          className="display-header"
          style={{
            display: "flex",
            justifyContent: "space-between",
            fontWeight: 700,
            background: "var(--pastelBlue)",
            borderTopLeftRadius: "10px",
            borderTopRightRadius: "10px",
          }}
        ></div>
        <div
          className="display-content"
          style={{
            display: " flex",
            justifyContent: " space-between",
            alignItems: "center",
            border: "1px solid var(--inputBorderColor)",
            borderRadius: "var(--inputBorderRadius)",
            padding: "var(--gap)",
            margin: "10px 0",
          }}
        >
          <div style={{ color: "var(--priceText)" }}>
            <div>{getFormattedFullName(iban.name, iban.surname)}</div>
            <div>{iban.iban}</div>
            <div style={{ textTransform: "capitalize" }}>{iban.bank_name}</div>
          </div>
          <div>
            <Box
              sx={{
                display: "flex",
                gap: "var(--gap2x)",
                flexDirection: "column",
              }}
            >
              <ModeEditOutlineOutlinedIcon
                style={{
                  height: "17px",
                  display: "flex",
                  alignItems: "center",
                  cursor: "pointer",
                }}
                onClick={handleEdit}
              />
              <DeleteOutlineOutlinedIcon
                onClick={handleDeleteDialog}
                sx={{
                  color: "var(--shipmentRed)",
                  cursor: "pointer",
                  ":hover": {
                    color: "var(--notRed)",
                    transition: "all 150ms linear",
                  },
                }}
              />
            </Box>
            <Modal open={isModalOpen} onClose={closeModal}>
              <Box
                sx={{
                  width: "50%",
                  margin: "40px auto",
                  backgroundColor: "#fff",
                  padding: "23px",
                  borderRadius: "var(--inputBorderRadius)",
                  outline: 0,
                }}
                style={{
                  maxHeight: "90vh",
                  overflowY: "auto",
                }}
              >
                <Typography variant="h6">IBAN Düzenle</Typography>
                <form onSubmit={handleUpdateIban}>
                  <TextField
                    label="Ad"
                    variant="outlined"
                    size="small"
                    fullWidth
                    margin="normal"
                    value={updateIbanForm.name.toUpperCase()}
                    onChange={(e) =>
                      setUpdateIbanForm((prevState) => ({
                        ...prevState,
                        name: toTurkishUpperCase(e.target.value),
                      }))
                    }
                    required
                    inputProps={{ minLength: 3 }}
                    sx={{
                      width: "49%",
                      marginRight: "1%",
                    }}
                  />

                  <TextField
                    label="Soyad"
                    variant="outlined"
                    fullWidth
                    margin="normal"
                    size="small"
                    value={updateIbanForm.surname.toUpperCase()}
                    onChange={(e) =>
                      setUpdateIbanForm((prevState) => ({
                        ...prevState,
                        surname: toTurkishUpperCase(e.target.value),
                      }))
                    }
                    required
                    inputProps={{ minLength: 2 }}
                    sx={{
                      width: "49%",
                    }}
                  />
                  <TextField
                    label="IBAN"
                    variant="outlined"
                    size="small"
                    fullWidth
                    margin="normal"
                    value={updateIbanForm.iban}
                    onChange={(e) => handleUpdateIbanForm(e.target.value)}
                    required
                    inputProps={{
                      maxLength: 32,
                      pattern:
                        "^[A-Z]{2}[ '+\\\\'-]?[0-9]{2}(?=(?:[ '+\\\\'-]?[A-Z0-9]){9,30}$)((?:[ '+\\\\'-]?[A-Z0-9]{3,5}){2,7})([ '+\\\\'-]?[A-Z0-9]{1,3})?$",
                    }}
                  />
                  <Autocomplete
                    id="bank-typing-select"
                    fullWidth
                    sx={{ marginTop: "10px" }}
                    size="small"
                    options={banks}
                    value={banks.find((option) => option.name === updateIbanForm.bank_name) || null}
                    onChange={(e, val) => {
                      if (val) {
                        setUpdateIbanForm({
                          ...updateIbanForm,
                          bank_name: val.name,
                        });
                      } else return;
                    }}
                    autoHighlight
                    openOnFocus={false}
                    getOptionLabel={(option) => option.name}
                    renderOption={(props, option) => (
                      <Box component="li" sx={{ "& > img": { mr: 2, flexShrink: 0 } }} {...props}>
                        {option.name}
                      </Box>
                    )}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        label="Banka Seçiniz"
                        required
                        name="unique-bank-field"
                        value={ibanForm.bank_name}
                        inputProps={{
                          ...params.inputProps,
                          autoComplete: "off",
                        }}
                        sx={{
                          marginTop: "10px",
                        }}
                      />
                    )}
                  />
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "flex-end",
                      marginTop: "5px",
                      gap: "var(--gap)",
                    }}
                  >
                    <Button type="submit" variant="contained" className="iyzi-button">
                      Kaydet
                    </Button>
                    <Button
                      variant="contained"
                      color="error"
                      className="iyzi-button"
                      onClick={closeModal}
                    >
                      Kapat
                    </Button>
                  </div>
                </form>
              </Box>
            </Modal>
          </div>
        </div>
      </div>
    </ThemeProvider>
  );
};

export default IbanListItem;
