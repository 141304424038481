import { useEffect, useCallback } from "react";
import { Box, Menu, MenuItem } from "@mui/material";
import ControlPointOutlinedIcon from "@mui/icons-material/ControlPointOutlined";
import { useRecoilState, useRecoilValue, useSetRecoilState } from "recoil";
import { startAnimationState } from "../../../atoms/startAnimation";
import {
  addressTypeState,
  isDefaultBillAddressState,
  isDefaultCollectionAddressState,
} from "../../../atoms/profileSettings";

const Addresses = ({ anchorEl, setAnchorEl }) => {
  const setStartAnimation = useSetRecoilState(startAnimationState);
  const [addressType, setAddressType] = useRecoilState(addressTypeState);

  const isDefaultBillAddress = useRecoilValue(isDefaultBillAddressState);
  const isDefaultCollectionAddress = useRecoilValue(
    isDefaultCollectionAddressState
  );

  const isBillExist = isDefaultBillAddress ? true : false;
  const isCollectionExist = isDefaultCollectionAddress ? true : false;

  const open = Boolean(anchorEl);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleBillClose = useCallback(() => {
    setAnchorEl(null);
    setAddressType("1");
    setStartAnimation(true);
  }, [setStartAnimation, setAddressType, setAnchorEl]);

  const handleSenderClose = useCallback(() => {
    setAnchorEl(null);
    setAddressType("4");
    setStartAnimation(true);
  }, [setStartAnimation, setAddressType, setAnchorEl]);

  const handleReceiverClose = useCallback(() => {
    setAnchorEl(null);
    setAddressType("5");
    setStartAnimation(true);
  }, [setStartAnimation, setAddressType, setAnchorEl]);

  const handleBookingClose = useCallback(() => {
    setAnchorEl(null);
    setAddressType("2");
    setStartAnimation(true);
  }, [setStartAnimation, setAddressType, setAnchorEl]);

  useEffect(() => {
    if (addressType === "2") {
      setAddressType("");
    }
  }, [addressType, setAddressType]);

  return (
    <Box className="new-address-adder-wrapper">
      <button
        id="basic-button"
        aria-controls={open ? "basic-menu" : undefined}
        aria-haspopup="true"
        aria-expanded={open ? "true" : undefined}
        onClick={handleClick}
        className="new-address-adder"
      >
        <ControlPointOutlinedIcon />
        Yeni Adres Ekle
      </button>
      <Menu
        id="basic-menu"
        className="basic-menu-list"
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        MenuListProps={{
          "aria-labelledby": "basic-button",
        }}
      >
        <MenuItem onClick={handleBillClose}>
          Fatura Adresi {isBillExist ? "Değiştir" : "Ekle"}
        </MenuItem>
        <MenuItem onClick={handleBookingClose}>
          Toplama Adresi {isCollectionExist ? "Değiştir" : "Ekle"}
        </MenuItem>
        <MenuItem onClick={handleSenderClose}>Gönderici Adresi Ekle</MenuItem>
        <MenuItem onClick={handleReceiverClose}>Alıcı Adresi Ekle</MenuItem>
      </Menu>
    </Box>
  );
};

export default Addresses;
