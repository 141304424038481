import { Box, Button, CircularProgress, Pagination, Tooltip, Table, TableBody, TableHead, Modal, FormControl, InputLabel, Select, MenuItem, TextField } from "@mui/material";
import React, { memo, useCallback, useEffect, useState } from "react";
import { useRecoilState, useRecoilValue, useSetRecoilState } from "recoil";
import axios from "../../api/axios";
import {
  fastCheckedState,
  fastDimensionState,
  fastLimitState,
  fastListState,
  fastPricesState,
  fastSenderAddressesState,
  fastShipmentCurrentPageState,
  fastShipmentTotalPagesState,
  portalState,
  openPackageListSaveState,
  shopIDState,
  shopNameState,
  countryState,
  suppliersState,
  receiptIDState,
  buyerEmailState,
  receiverNameState,
  formattedAddressState,
  statusState,
  parcelRefNoState,
  receiptDateStartState,
  receiptDateEndState,
  createDateStartState,
  createDateEndState,
  receiptDateState,
  createdDateState,
  dateTypeState,
  parcelTypeState,
  selectedFilesIntState,
} from "../../atoms/fastShipment";
import {
  exchangeRateState
} from "../../atoms/createShipment";
import {
  isDefaultBillAddressState,
  isDefaultCollectionAddressState,
} from "../../atoms/profileSettings";
import {
  addressLoadingState,
  checkedReceiverAddressIdState,
  checkedSenderAddressIdState,
  profileBookingAddressState,
  profileReceiverAddressState,
  profileSenderAddressState,
  receiverCurrPageState,
  receiverTotalPageState,
  senderCurrPageState,
  senderTotalPageState,
} from "../../atoms/membership";
import {
  FormatFieldWithInteger,
  FormatFieldWithTwoDecimals,
  FormatFieldWithTwoDecimalsWithMaxNumber,
} from "../../utils/FormatField";
import FastShipmentListItem from "./FastShipmentListItem";
import iyziWeightRoundSystem from "../../utils/iyziWeightRoundSystem";
import AddCircleOutlineIcon from "@mui/icons-material/AddCircleOutline";
import AddCircleIcon from '@mui/icons-material/AddCircle';
import FastDimension from "./fastShipmentListItem/FastDimension";
import miniLogo from "../../img/logos/logo_white_mini_loading.svg";
import { toast } from "react-toastify";
import InfoOutlinedIcon from "@mui/icons-material/InfoOutlined";
import CancelOutlinedIcon from "@mui/icons-material/CancelOutlined";
import Errors from "../../utils/Errors";
import FastShipmentSearch from "./FastShipmentSearch";
import { integrationsState } from "../../atoms/membership";
import AllSendDialog from "./AllSendDialog";
import CreateFastIOSSNumber from "./fastShipmentListItem/CreateFastIOSSNumber";
import DeleteIcon from '@mui/icons-material/Delete';
import { NavLink } from "react-router-dom";
import InsuranceSlideWarning from "../createShipment/shipmentPrice/InsuranceSlideWarning";
import KeyboardArrowDownOutlinedIcon from "@mui/icons-material/KeyboardArrowDownOutlined";
import FastShipmentListHead from "./fastShipmentSearch/FastShipmentListHead";
import ParcelType from "./fastShipmentSearch/ParcelType";
import { currentIngtegrationsState } from "../../atoms/integrations";
import uploadIcon from '../../img/icons/upload_icon.svg';
import { useDropzone } from 'react-dropzone';
import DowloadPdfView from "../dowloadPdfView/DowloadPdfView";

const FastShipmentList = () => {
  const portal = useRecoilValue(portalState);
  const fastLimit = useRecoilValue(fastLimitState);
  const openPackageListSave = useRecoilValue(openPackageListSaveState);

  const [fastChecked, setFastChecked] = useRecoilState(fastCheckedState);
  const [allChecked, setAllChecked] = useState(false);
  const [fastList, setFastList] = useRecoilState(fastListState);
  const [uploadFileModal, setUploadFileModal] = useState(false);
  const [uploadFileExcelModal, setUploadFileExcelModal] = useState(false);
  const [sendFiles, setSendFiles] = useRecoilState(selectedFilesIntState);
  const [selectedFiles, setSelectedFiles] = useState([]);
  const dataID = fastList?.map((item) => item.id);
  const [fastShipmentTotalPages, setFastShipmentTotalPages] = useRecoilState(
    fastShipmentTotalPagesState
  );
  const [fastShipmentCurrentPage, setFastShipmentCurrentPage] = useRecoilState(
    fastShipmentCurrentPageState
  );

  const setFastSenderAddresses = useSetRecoilState(fastSenderAddressesState);
  const setFastDimension = useSetRecoilState(fastDimensionState);
  const setIntegrations = useSetRecoilState(integrationsState);
  const setFastPrices = useSetRecoilState(fastPricesState);
  const setExchangeRate = useSetRecoilState(exchangeRateState);

  const [dataRendered, setDataRendered] = useState(false);
  const [loading, setLoading] = useState(false);
  const [isDimensionExist, setIsDimensionExist] = useState(true);
  const [allSendDialog, setAllSendDialog] = useState(false);
  const [fastShipmentDimensionDialog, setFastShipmentDimensionDialog] = useState(false);
  const [fastShipmentProformaDialog, setFastShipmentProformaDialog] = useState(false);
  const [fastShipmentVatDialog, setFastShipmentVatDialog] = useState(false);
  const [bulkLoading, setBulkLoading] = useState(false);
  const [errorModalOpen, setErrorModalOpen] = useState(false);
  const [errorModalList, setErrorModalList] = useState([]);
  const [errorModalMessage, setErrorModalMessage] = useState("");
  const [shopID, setShopID] = useRecoilState(shopIDState);
  const [shopName, setShopName] = useRecoilState(shopNameState);
  const [country, setCountry] = useRecoilState(countryState);
  const [supplier, setSupplier] = useRecoilState(suppliersState);
  const [receiptID, setReceiptID] = useRecoilState(receiptIDState);
  const [buyerEmail, setBuyerEmail] = useRecoilState(buyerEmailState);
  const [receiverName, setReceiverName] = useRecoilState(receiverNameState);
  const [formattedAddress, setFormattedAddress] = useRecoilState(formattedAddressState);
  const [status, setStatus] = useRecoilState(statusState);
  const [parcelRefNo, setParcelRefNo] = useRecoilState(parcelRefNoState);
  const [receiptDateStart, setReceiptDateStart] = useRecoilState(receiptDateStartState);
  const [receiptDateEnd, setReceiptDateEnd] = useRecoilState(receiptDateEndState);
  const [createDateStart, setCreateDateStart] = useRecoilState(createDateStartState);
  const [createDateEnd, setCreateDateEnd] = useRecoilState(createDateEndState);
  const [receiptDate, setReceiptDate] = useRecoilState(receiptDateState);
  const [createdDate, setCreatedDate] = useRecoilState(createdDateState);
  const [dateType, setDateType] = useRecoilState(dateTypeState);
  const [parcelType, setParcelType] = useRecoilState(parcelTypeState);
  const [addressLoading, setAddressLoading] = useRecoilState(addressLoadingState);

  const setIsDefaultBillAddress = useSetRecoilState(isDefaultBillAddressState);
  const setIsDefaultCollectionAddress = useSetRecoilState(isDefaultCollectionAddressState);

  const [profileSenderAddress, setProfileSenderAddress] = useState([]);
  const setProfileReceiverAddress = useSetRecoilState(profileReceiverAddressState);
  const setProfileBookingAddress = useSetRecoilState(profileBookingAddressState);

  const setCheckedSenderAddressId = useSetRecoilState(checkedSenderAddressIdState);
  const setCheckedReceiverAddressId = useSetRecoilState(checkedReceiverAddressIdState);

  const setSenderCurrPage = useSetRecoilState(senderCurrPageState);
  const setReceiverCurrPage = useSetRecoilState(receiverCurrPageState);
  const setSenderTotalPage = useSetRecoilState(senderTotalPageState);
  const setReceiverTotalPage = useSetRecoilState(receiverTotalPageState);

  const [currentIngtegrations, setCurrentIntegrations] = useRecoilState(
    currentIngtegrationsState
  );
  const [integrationListed, setIntegrationListed] = useState(false);

  const [columnType, setColumnType] = useState("");
  const [orderDirection, setOrderDirection] = useState("");

  const [activeSort, setActiveSort] = useState([
    { column: "platform", direction: null },
    { column: "shop_name", direction: null },
    { column: "receipt_id", direction: null },
    { column: "country", direction: null },
    { column: "contact_name", direction: null },
    { column: "status", direction: null },
    { column: "parcel_ref_no", direction: null },
    { column: "supplier_id", direction: null },
    { column: "receipt_date", direction: null },
  ]);

  const handleErrorModal = () => {
    setErrorModalOpen(false);
    setErrorModalList([]);
    setErrorModalMessage();
    window.location.reload();
  };

  const fastShipmentList = useCallback(async () => {
    setLoading(true);
    await axios
      .get("general/integration-parcel", {
        params: {
          limit: 20,
          order_by: "receipt_date",
          detailed: 1,
          is_excel: window.location.href.endsWith("create-fast-all-shipment") ? 1 : 0
        },
      })
      .then((response) => {
        // Gelen datanın her bir öğesine isPriceTake: '0' ekle
        const modifiedData = response.data.data.map(item => ({
          ...item,
          isPriceTake: '0',
        }));

        setFastList(modifiedData);
        setDataRendered(true);
        setFastShipmentTotalPages(response.data.totalPages);
        setFastShipmentCurrentPage(response.data.currentPage);
      })
      .catch((error) => Errors(error))
      .finally(() => setLoading(false));
  }, [portal, fastLimit, setFastList, setFastShipmentTotalPages, setFastShipmentCurrentPage]);

  const fastSenderAddressList = useCallback(() => {
    axios
      .post(`member-address/list`, {
        address_type: 4,
        country: "TR",
      })
      .then((response) => {
        setFastSenderAddresses(response.data.data);
      })
      .catch((error) => Errors(error));
  }, [setFastSenderAddresses]);

  const dimensionList = useCallback(() => {
    axios
      .get("member-dimension")
      .then((response) => {
        setFastDimension(response.data.data);
        if (response.data.data.length === 0) {
          setIsDimensionExist(false);
        } else {
          setIsDimensionExist(true);
        }
      })
      .catch((error) => Errors(error));
  }, [setFastDimension]);

  const getIossList = useCallback(async () => {
    axios.get(`member-ioss-number?limit=999`).then((response) => {
      setIntegrations(response.data.data);
    });
  }, [setIntegrations]);

  const handlePageChange = (
    async (column, pageValue) => {
      setLoading(true);
      setFastShipmentCurrentPage(pageValue);
      const updatedSort = activeSort.map((sort) => {
        if (sort.column === column) {
          return {
            ...sort,
            direction: sort.direction === "ASC" ? "DESC" : "ASC",
          };
        }
        return {
          ...sort,
          direction: null, // Diğer kolonların sıralama yönünü sıfırla
        };
      });

      // Kolon sıralama durumunu kontrol et
      let sortExists = updatedSort.find((sort) => sort.column === column);

      // Eğer yoksa, default direction ile ekle
      if (!sortExists) {
        sortExists = { column: column, direction: "ASC" };
        updatedSort.push(sortExists);
      }

      setActiveSort(updatedSort);

      const currentDirection = sortExists.direction;
      setOrderDirection(currentDirection);
      setLoading(true); // Request öncesi loading state'ini ayarla

      try {
        const response = await axios.get("general/integration-parcel", {
          params: {
            ...(portal ? { portal } : {}), // Portal boş değilse ekle
            limit: 20,
            page: pageValue,
            order_direction: currentDirection,
            order_by: column,
            detailed: 1,
            is_excel: window.location.href.endsWith("create-fast-all-shipment") ? 1 : 0,
            ...(shopID ? { platform: shopID } : {}), // Add shop_id if it's not empty
            ...(shopName ? { shop_name: shopName } : {}), // Add shop_id if it's not empty
            ...(country ? { country: country } : {}), // Add shop_id if it's not empty
            ...(supplier ? { supplier_id: supplier } : {}), // Add shop_id if it's not empty
            ...(receiptID ? { receipt_id: receiptID } : {}), // Add shop_id if it's not empty
            ...(buyerEmail ? { buyer_email: buyerEmail } : {}), // Add buyer_email if it's not empty
            ...(receiverName ? { contact_name: receiverName } : {}), // Add contact_name if it's not empty
            ...(formattedAddress ? { formatted_address: formattedAddress } : {}), // Add formatted_address if it's not empty
            ...(status ? { status: status } : {}), // Add formatted_address if it's not empty
            ...(parcelRefNo ? { parcel_ref_no: parcelRefNo } : {}), // Add formatted_address if it's not empty
            ...(dateType ? { date_type: dateType } : {}), // Add formatted_address if it's not empt
            ...(receiptDateStart ? { start_date: receiptDateStart } : {}),
            ...(receiptDateEnd ? { end_date: receiptDateEnd } : {}),
            ...(createDateStart ? { start_date: createDateStart } : {}),
            ...(createDateEnd ? { end_date: createDateEnd } : {}),
            ...(createDateEnd ? { end_date: createDateEnd } : {}),
          },
        });
        setColumnType(column);
        setFastList(response.data.data);
        setDataRendered(true);
        setFastShipmentTotalPages(response.data.totalPages);
        setFastShipmentCurrentPage(response.data.currentPage);
      } catch (error) {
        Errors(error);
      } finally {
        setLoading(false);
      }
    }
  );

  const handlePageChangeManuel = (
    async (column, pageValue) => {
      setLoading(true);
      setFastShipmentCurrentPage(pageValue);
      setFastChecked([]);
      const updatedSort = activeSort.map((sort) => {
        if (sort.column === column) {
          return {
            ...sort,
            direction: sort.direction === "ASC" ? "DESC" : "ASC",
          };
        }
        return {
          ...sort,
          direction: null, // Diğer kolonların sıralama yönünü sıfırla
        };
      });

      // Kolon sıralama durumunu kontrol et
      let sortExists = updatedSort.find((sort) => sort.column === column);

      // Eğer yoksa, default direction ile ekle
      if (!sortExists) {
        sortExists = { column: column, direction: "ASC" };
        updatedSort.push(sortExists);
      }

      setActiveSort(updatedSort);
      setLoading(true); // Request öncesi loading state'ini ayarla

      try {
        const response = await axios.get("general/integration-parcel", {
          params: {
            detailed: 1,
            limit: 20,
            page: pageValue,
            order_direction: orderDirection,
            order_by: columnType ? columnType : "receipt_date",
            is_excel: window.location.href.endsWith("create-fast-all-shipment") ? 1 : 0,
            ...(portal ? { portal } : {}), // Portal boş değilse ekle
            ...(shopID ? { platform: shopID } : {}), // Add shop_id if it's not empty
            ...(shopName ? { shop_name: shopName } : {}), // Add shop_id if it's not empty
            ...(country ? { country: country } : {}), // Add shop_id if it's not empty
            ...(supplier ? { supplier_id: supplier } : {}), // Add shop_id if it's not empty
            ...(receiptID ? { receipt_id: receiptID } : {}), // Add shop_id if it's not empty
            ...(buyerEmail ? { buyer_email: buyerEmail } : {}), // Add buyer_email if it's not empty
            ...(receiverName ? { contact_name: receiverName } : {}), // Add contact_name if it's not empty
            ...(formattedAddress ? { formatted_address: formattedAddress } : {}), // Add formatted_address if it's not empty
            ...(status ? { status: status } : {}), // Add formatted_address if it's not empty
            ...(parcelRefNo ? { parcel_ref_no: parcelRefNo } : {}), // Add formatted_address if it's not empty
            ...(dateType ? { date_type: dateType } : {}), // Add formatted_address if it's not empt
            ...(receiptDateStart ? { start_date: receiptDateStart } : {}),
            ...(receiptDateEnd ? { end_date: receiptDateEnd } : {}),
            ...(createDateStart ? { start_date: createDateStart } : {}),
            ...(createDateEnd ? { end_date: createDateEnd } : {}),
            ...(parcelType ? { parcel_type: parcelType } : {}),
          },
        });

        setFastList(response.data.data);
        setDataRendered(true);
        setFastShipmentTotalPages(response.data.totalPages);
        setFastShipmentCurrentPage(pageValue);
      } catch (error) {
        Errors(error);
      } finally {
        setLoading(false);
      }
    }
  );

  const allFastShipmentSend = () => {
    if (fastChecked.length === 0) {
      return;
    } else {
      setBulkLoading(true);

      const formData = new FormData();

      fastChecked.forEach((item, index) => {
        Object.keys(item).forEach((key) => {
          if (key === "commoduties") {
            // commoduties dizisi için
            item[key].forEach((commodity, commodityIndex) => {
              Object.entries(commodity).forEach(([commodityKey, commodityValue]) => {
                formData.append(
                  `items[${index}][${key}][${commodityIndex}][${commodityKey}]`,
                  commodityValue
                );
              });
            });
          } else if (key === "files") {
            // Dosyaları işle ve FormData'ya ekle
            item[key].forEach((file, fileIndex) => {
              if (file instanceof File) {
                formData.append(`items[${index}][files][${fileIndex}]`, file, file.name);
              }
            });
          } else if (typeof item[key] === "object" && item[key] !== null) {
            // Diğer iç içe objeler veya diziler için
            Object.entries(item[key]).forEach(([subKey, subValue]) => {
              if (typeof subValue === "object" && subValue !== null) {
                // Daha derin iç içe objeler veya diziler için
                Object.entries(subValue).forEach(([subSubKey, subSubValue]) => {
                  formData.append(`items[${index}][${key}][${subSubKey}]`, subSubValue);
                });
              } else {
                // Basit değerler için
                formData.append(`items[${index}][${key}][${subKey}]`, subValue);
              }
            });
          } else {
            // Basit anahtar-değer çiftleri için
            formData.append(`items[${index}][${key}]`, item[key]);
          }
        });
      });

      axios
        .post("general/create-parcel", formData, {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        })
        .then((response) => {
          const createdParcels = response.data.data.map((parcel) => ({
            id: parcel.id,
            parcelRefNo: parcel.get_parcel.parcel_ref_no,
            priceStatus: 1,
            additionalText: "Oluşturuldu",
          }));

          setFastList((prevList) => {
            const updatedList = prevList.map((fast) => {
              const createdParcel = createdParcels.find((parcel) => parcel.id === fast.id);
              if (createdParcel) {
                return {
                  ...fast,
                  ...createdParcel,
                };
              }
              return fast;
            });
            return updatedList;
          });
          setFastChecked([]);
          setAllChecked(false);
          setFastPrices((prevPrices) => {
            const updatedPrices = prevPrices.map((price) => {
              const createdParcel = createdParcels.find((parcel) => parcel.id === price.id);
              if (createdParcel) {
                return {
                  ...price,
                  priceStatus: 1,
                };
              }
              return price;
            });
            return updatedPrices;
          });

          toast.success(response.data.message);
        })
        .catch(error => {
          Errors(error); // Hata yönetimi fonksiyonunu çağır
          if (error.response.data.status === 400) {
            setErrorModalOpen(true);
            setErrorModalList(error.response.data.data)
            setErrorModalMessage(error.response.data.message)
          }
        })
        .finally(() => setBulkLoading(false));
    }
  };

  const allFastShipmentDialog = () => {
    setAllSendDialog(true);
  };
  const handleUploadFileModal = () => {
    setUploadFileModal(true);
  };
  const handleUploadFileExcelModal = () => {
    setUploadFileExcelModal(true);
  };
  const handleDownloadExcelCopy = () => {
    const link = document.createElement('a');
    link.href = '/images/home/testexcel.xlsx';
    link.download = 'testexcel.xlsx'; // You can specify the name of the file to be saved
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  };
  const handleUploadFileExcelModalClose = () => {
    setUploadFileExcelModal(false);
  };
  const allFastShipmentDimensionDialog = () => {
    setFastShipmentDimensionDialog(true);
  };
  const allFastShipmentProformaDialog = () => {
    setFastShipmentProformaDialog(true);
  };
  const allFastShipmentVatDialog = () => {
    setFastShipmentVatDialog(true);
  };

  const handleEtsyIntegration = () => {
    setLoading(true);
    axios
      .post("member/update-integration-shops", {
        portal: "ETSY",
      })
      .then((response) => {
        if (response.status === 200) {
          fastShipmentList();
          toast.success("Etsy siparişleriniz güncellendi!");
          setLoading(false);
        }
      })
      .catch((error) => Errors(error));
  };

  const handleShopifyIntegration = () => {
    setLoading(true);
    axios
      .post("member/update-integration-shops", {
        portal: "Shopify",
      })
      .then((response) => {
        if (response.status === 200) {
          fastShipmentList();
          toast.success("Shopify siparişleriniz güncellendi!");
          setLoading(true);
        }
      })
      .catch((error) => Errors(error));
  };

  const handleWooCommerceIntegration = () => {
    setLoading(true);
    axios
      .post("member/update-integration-shops", {
        portal: "WooCommerce",
      })
      .then((response) => {
        if (response.status === 200) {
          fastShipmentList();
          toast.success("WooCommerce siparişleriniz güncellendi!");
          setLoading(true);
        }
      })
      .catch((error) => Errors(error));
  };

  const getAddresses = async () => {
    setAddressLoading(true);

    const [senderResponse] = await Promise.all([
      axios.get("member-address", { params: { address_type: 4, limit: 20 } }),
    ]).finally(() => {
      setAddressLoading(false);
    });

    setProfileSenderAddress(senderResponse.data.data);
    setCheckedSenderAddressId(senderResponse.data.data.find((e) => e.is_default === "1")?.id);
    setSenderCurrPage(senderResponse.data.currentPage);
    setSenderTotalPage(senderResponse.data.totalPages);
  }

  useEffect(() => {
    fastShipmentList();
    fastSenderAddressList();
    dimensionList();
    getIossList();
    getAddresses();
  }, [fastShipmentList, fastSenderAddressList, dimensionList, getIossList]);

  useEffect(() => {
    if (openPackageListSave) {
      fastShipmentList();
    }
  }, [openPackageListSave, fastShipmentList]);

  const getData = useCallback(() => {
    axios
      .get(`general/integration-list`)
      .then((response) => {
        setCurrentIntegrations(response.data.data);
        setIntegrationListed(true);
      })
      .catch((error) => Errors(error));
  }, [setCurrentIntegrations]);

  useEffect(() => {
    getData();
  }, [getData]);

  const handUploadFileModal = () => {
    setUploadFileModal(false);
    setSendFiles([]);
    setSelectedFiles([]);
  }

  const handleSetState = (id) => {
    setSendFiles((prevFiles) => prevFiles.filter((file) => file.id !== id));
    setSelectedFiles((prevFiles) => prevFiles.filter((file) => file.id !== id));
  };

  const handleDrop = (acceptedFiles) => {
    if (acceptedFiles.length > 0) {
      const file = acceptedFiles[0];
      setSelectedFiles({
        file,
        id: Math.random().toString(),
      })
    }
  };

  const { getRootProps: getRootPropsCSV, getInputProps: getInputPropsCSV } = useDropzone({
    onDrop: handleDrop,
    multiple: false,
    accept: {
      'application/pdf': ['.csv'],
    },
  });

  const { getRootProps: getRootPropsExcel, getInputProps: getInputPropsExcel } = useDropzone({
    onDrop: handleDrop,
    multiple: false,
    accept: {
      'application/pdf': ['.xlsx'],
    },
  });

  const handleUploadModalExcel = async () => {
    setLoading(true);
    try {
      const formData = new FormData();
      if (selectedFiles) {
        formData.append('excel_file', selectedFiles.file);
      }

      const response = await axios.post('general/add-order-to-excel', formData, {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      });
      toast.success("Dosya yükleme başarılı.", { autoClose: 2000 });
      setTimeout(() => {
        window.location.reload(true);
      }, 1000);
      handUploadFileModal();
      setLoading(false);
    } catch (error) {
      toast.error("Dosya yükleme başarısız.", { autoClose: 2000 });
      setLoading(false);
    }
  };

  const handleUploadModal = async () => {
    setLoading(true);
    try {
      const formData = new FormData();
      if (selectedFiles) {
        formData.append('csv_file', selectedFiles.file);
      }

      const response = await axios.post('/etsy/read-csv-orders', formData, {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      });
      toast.success("Dosya yükleme başarılı.", { autoClose: 2000 });
      setTimeout(() => {
        window.location.reload(true);
      }, 1000);
      handUploadFileModal();
      setLoading(false);
    } catch (error) {
      toast.error("Dosya yükleme başarısız.", { autoClose: 2000 });
      setLoading(false);
    }
  };


  const getCurrency = () => {
    axios
      .get(`exchange-rate`)
      .then((response) => {
        setExchangeRate(response.data.data);
      })
      .catch((error) => {
        Errors(error);
      });
  };

  useEffect(() => {
    getCurrency();
  }, []);

  return (
    <Box className="fast-shipment-list">
      <InsuranceSlideWarning />
      <CreateFastIOSSNumber />
      <Modal open={errorModalOpen} onClose={handleErrorModal} className="fast-dimension-modal">
        <Box sx={{
          backgroundColor: "none"
        }} className="fast-dimension-modal">
          <Box sx={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            gap: "20px",
            backgroundColor: "white",
            padding: "30px", // Optional: Add some padding for better appearance
            borderRadius: "8px", // Optional: Add rounded corners for a nicer look
            boxShadow: "0px 4px 10px rgba(0, 0, 0, 0.1)" // Optional: Add a shadow for a floating effect
          }}>
            <p>
              Seçilen gönderilerin bazılarında sorun meydana geldi. {errorModalMessage}
            </p>
            <p>
              İlgili gönderi sipariş numaraları:
            </p>
            <p>
              {errorModalList.map((item) => item.receipt_id)}
            </p>
            <Box sx={{
              display: "flex",
              gap: "20px"
            }}>
              <Button className="iyzi-button-blue" onClick={handleErrorModal}>
                Anladım
              </Button>
            </Box>
          </Box>
        </Box>
      </Modal>
      <Modal open={uploadFileModal} onClose={handUploadFileModal} className="fast-dimension-modal">
        <Box sx={{
          backgroundColor: "none"
        }} className="fast-dimension-modal">
          <Box sx={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            gap: "20px",
            backgroundColor: "white",
            padding: "30px", // Optional: Add some padding for better appearance
            borderRadius: "8px", // Optional: Add rounded corners for a nicer look
            boxShadow: "0px 4px 10px rgba(0, 0, 0, 0.1)" // Optional: Add a shadow for a floating effect
          }}>
            <div
              style={{
                boxShadow: 'var(--shadow)',
                padding: '10px',
                display: 'flex',
                flexDirection: 'column',
                gap: 'var(--gap2x)',
                borderRadius: 'var(--inputBorderRadius)',
              }}
            >
              <div
                style={{
                  backgroundColor: 'var(--pastelBlue)',
                  padding: '10px 20px',
                  borderRadius: 'var(--wrapperBorderRadius)',
                  display: 'flex',
                  alignItems: 'center',
                  gap: '5px',
                  color: 'var(--btnBgBlue)',
                }}
              >
                <InfoOutlinedIcon sx={{ color: 'inherit', fontWeight: 600 }} />
                <div style={{ color: 'inherit' }}>
                  <span style={{ fontWeight: 300 }}>CSV uzantılı dosya</span>
                </div>
              </div>
              <Box className="dropzone-wrapper">
                <Box className="dropzone">
                  <Button className="dropzone-area" {...getRootPropsCSV()}>
                    <input {...getInputPropsCSV()} />
                    <div className="dropzone-area-text">
                      <img src={uploadIcon} alt="Upload Icon" />
                      <span className="dropzone-area-text-headline">
                        Dosyayı sürükleyip bırakın veya{' '}
                        <span style={{ color: 'var(--btnBgBlue)' }}>Gözat</span>
                      </span>
                      <span className="dropzone-area-text-sub">
                        Desteklenen Formatlar: CSV
                      </span>
                    </div>
                  </Button>
                </Box>
                <DowloadPdfView sendFiles={sendFiles} handleSetState={handleSetState} />
                {
                  selectedFiles && (
                    <>
                      <Box>
                        Seçilen Dosya
                      </Box>
                      <Box>
                        {selectedFiles?.file?.path}
                      </Box>
                    </>
                  )
                }
              </Box>
            </div>
            <Box sx={{
              display: "flex",
              gap: "20px"
            }}>
              {loading ? (
                <Box>
                  <CircularProgress />
                </Box>
              ) : (
                <Button className="iyzi-button-blue" onClick={handleUploadModal}>
                  Gönder
                </Button>
              )
              }
            </Box>
          </Box>
        </Box>
      </Modal>
      <Modal open={uploadFileExcelModal} onClose={handleUploadFileExcelModalClose} className="fast-dimension-modal">
        <Box sx={{
          backgroundColor: "none"
        }} className="fast-dimension-modal">
          <Box sx={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            gap: "20px",
            backgroundColor: "white",
            padding: "30px", // Optional: Add some padding for better appearance
            borderRadius: "8px", // Optional: Add rounded corners for a nicer look
            boxShadow: "0px 4px 10px rgba(0, 0, 0, 0.1)" // Optional: Add a shadow for a floating effect
          }}>
            <div
              style={{
                boxShadow: 'var(--shadow)',
                padding: '10px',
                display: 'flex',
                flexDirection: 'column',
                gap: 'var(--gap2x)',
                borderRadius: 'var(--inputBorderRadius)',
              }}
            >
              <div
                style={{
                  backgroundColor: 'var(--pastelBlue)',
                  padding: '10px 20px',
                  borderRadius: 'var(--wrapperBorderRadius)',
                  display: 'flex',
                  alignItems: 'center',
                  gap: '5px',
                  color: 'var(--btnBgBlue)',
                }}
              >
                <InfoOutlinedIcon sx={{ color: 'inherit', fontWeight: 600 }} />
                <div style={{ color: 'inherit' }}>
                  <span style={{ fontWeight: 300 }}>Excel uzantılı dosya</span>
                </div>
              </div>
              <Box className="dropzone-wrapper">
                <Box className="dropzone">
                  <Button className="dropzone-area" {...getRootPropsExcel()}>
                    <input {...getInputPropsExcel()} />
                    <div className="dropzone-area-text">
                      <img src={uploadIcon} alt="Upload Icon" />
                      <span className="dropzone-area-text-headline">
                        Dosyayı sürükleyip bırakın veya{' '}
                        <span style={{ color: 'var(--btnBgBlue)' }}>Gözat</span>
                      </span>
                      <span className="dropzone-area-text-sub">
                        Desteklenen Formatlar: xlsx
                      </span>
                    </div>
                  </Button>
                </Box>
                <DowloadPdfView sendFiles={sendFiles} handleSetState={handleSetState} />
                {
                  selectedFiles && (
                    <>
                      <Box>
                        Seçilen Dosya
                      </Box>
                      <Box>
                        {selectedFiles?.file?.path}
                      </Box>
                    </>
                  )
                }
              </Box>
            </div>
            <Box sx={{
              display: "flex",
              gap: "20px"
            }}>
              {loading ? (
                <Box>
                  <CircularProgress />
                </Box>
              ) : (
                <Button className="iyzi-button-blue" onClick={handleUploadModalExcel}>
                  Gönder
                </Button>
              )
              }
            </Box>
          </Box>
        </Box>
      </Modal>
      <AllSendDialog
        open={allSendDialog}
        setOpen={setAllSendDialog}
        handleSubmit={allFastShipmentSend}
      />
      <FastDimension />
      {!isDimensionExist && (
        <div
          style={{
            backgroundColor: "var(--lighterBg)",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            padding: "20px",
            boxShadow: "var(--shadow)",
            borderRadius: "var(--wrapperBorderRadius)",
            gap: "var(--gap)",
            fontSize: "16px",
          }}
        >
          <InfoOutlinedIcon sx={{ color: "var(--info)", width: "25px", height: "25px" }} />
          <p
            style={{
              fontSize: "16px",
            }}
          >
            Fiyat almak için{" "}
            <NavLink style={{ fontSize: "inherit" }} to={"/profile-settings/registered-dimensions"}>
              buradan
            </NavLink>{" "}
            kayıtlı ölçü oluşturun.
          </p>
        </div>
      )}
      {
        window.location.href.endsWith("create-fast-shipment") && (
          <div className="fast-shipment-list-buttons">
            <Button sx={{
              backgroundColor: "#EB6D20 !important",
              borderColor: "#EB6D20 !important"
            }} className="iyzi-button-blue" onClick={handleEtsyIntegration}>
              Etsy Siparişlerimi Güncelle
            </Button>
            <Button sx={
              {
                backgroundColor: "#4A9C14 !important",
                borderColor: "#4A9C14 !important"
              }
            } className="iyzi-button-blue" onClick={handleShopifyIntegration}>
              Shopify Siparişlerimi Güncelle
            </Button>
            <Button sx={
              {
                backgroundColor: "#7F54B3 !important",
                borderColor: "#7F54B3 !important"
              }
            } className="iyzi-button-blue" onClick={handleWooCommerceIntegration}>
              WooCommerce Siparişlerimi Güncelle
            </Button>
          </div>
        )
      }
      {fastList?.length === 0 && dataRendered && window.location.href.endsWith("create-fast-shipment") && (
        <div
          style={{
            backgroundColor: "var(--lighterBg)",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            padding: "20px",
            boxShadow: "var(--shadow)",
            borderRadius: "var(--wrapperBorderRadius)",
            gap: "var(--gap)",
            fontSize: "20px",
          }}
        >
          <InfoOutlinedIcon sx={{ color: "var(--info)", width: "25px", height: "25px" }} />{" "}
          <span style={{ fontSize: "16px" }}>
            Entegre gönderiniz bulunmamaktadır. Mağazanızın bağlı olup olmadığını{" "}
            <NavLink
              style={{ color: "var(--btnBgBlue)", fontSize: "inherit" }}
              to={"/integrations"}
            >
              buradan
            </NavLink>{" "}
            kontrol edebilirsiniz.
          </span>
        </div>
      )}
      <FastShipmentSearch setLoading={setLoading} />
      {loading ? (
        <div
          style={{
            width: "100%",
            display: "flex",
            flexDirection: "column",
            gap: "var(--gap)",
            justifyContent: "center",
            alignItems: "center",
            minHeight: "500px",
          }}
        >
          <img className="loading-logo" src={miniLogo} alt="" />
          <div>Tüm gönderileri çekmek biraz zamanımızı alabilir...</div>
        </div>
      ) : (
        <>
          <Box sx={
            {
              display: "flex",
              gap: "20px",
              justifyContent: "space-between",
              '@media (max-width: 768px)': {
                flexDirection: 'column',
                gap: '10px',
              },
            }

          } className="fast-shipment-pagination">
            <Pagination
              showFirstButton
              showLastButton
              size="small"
              count={fastShipmentTotalPages}
              page={fastShipmentCurrentPage}
              onChange={handlePageChangeManuel}
            />
            <Box sx={{
              display: "flex",
              flexDirection: "column",
              gap: "20px",
              width: "70%"
            }}>
              <Box sx={{
                display: "flex",
                width: "100%",
                gap: "20px"
              }}>
                {
                  window.location.href.endsWith("create-fast-shipment") && (
                    <Button
                      sx={{
                        width: "100%", ":disabled": { opacity: 0.3 },
                        '@media (max-width: 768px)': {
                          width: "84%"
                        },
                      }}
                      className="iyzi-button-blue"
                      onClick={handleUploadFileModal}
                    >
                      İçeri Aktar
                    </Button>
                  )}
                {
                  window.location.href.endsWith("create-fast-all-shipment") && (
                    <><Button
                      sx={{
                        width: "100%", ":disabled": { opacity: 0.3 },
                        '@media (max-width: 768px)': {
                          width: "84%"
                        },
                      }}
                      className="iyzi-button-blue"
                      onClick={handleUploadFileExcelModal}
                    >
                      Excel Yükle
                    </Button><Button
                      sx={{
                        width: "100%", ":disabled": { opacity: 0.3 },
                        '@media (max-width: 768px)': {
                          width: "84%"
                        },
                      }}
                      className="iyzi-button-blue"
                      onClick={handleDownloadExcelCopy}
                    >
                        Örnek Excel İndir
                      </Button></>
                  )}
                {bulkLoading ? (
                  <div style={{ display: "flex", alignItems: "center", justifyContent: "center" }}>
                    <CircularProgress sx={{ width: "21px!important", height: "21px!important" }} />
                  </div>
                ) : (
                  <Tooltip
                    title={
                      fastChecked.length === 0
                        ? "Toplu gönderi oluşturabilmeniz için ürün seçmeniz gerekmektedir."
                        : ""
                    }
                  >
                    <div style={{ width: "100%" }}>
                      <Button
                        sx={{
                          width: "100%", ":disabled": { opacity: 0.3 },
                          '@media (max-width: 768px)': {
                            width: "84%"
                          },
                        }}
                        disabled={fastChecked.length === 0}
                        className="iyzi-button-blue"
                        onClick={allFastShipmentDialog}
                      >
                        Toplu Gönderi Oluştur
                      </Button>
                    </div>
                  </Tooltip>
                )}
              </Box>
              <Box sx={{
                display: "flex",
                gap: "20px",
              }}>
                <Box sx={{
                  display: "flex",
                  alignItems: "center",
                  gap: "10px"
                }}>
                  <Box className="iyzi-button-greenBold" sx={{
                    width: "15px",
                    height: "15px",
                  }}>
                  </Box>
                  <p>Tamamlandı</p>
                </Box>
                <Box sx={{
                  display: "flex",
                  alignItems: "center",
                  gap: "10px"
                }}>
                  <Box className="iyzi-button-yellow" sx={{
                    width: "15px",
                    height: "15px",
                  }}>
                  </Box>
                  <p>İsteğe Bağlı Değiştirilebilir</p>
                </Box>
                <Box sx={{
                  display: "flex",
                  alignItems: "center",
                  gap: "10px"
                }}>
                  <Box className="iyzi-button-red" sx={{
                    width: "15px",
                    height: "15px",
                  }}>
                  </Box>
                  <p>Zorunlu</p>
                </Box>
              </Box>

            </Box>
          </Box>
          <FastShipmentListHead profileSenderAddress={profileSenderAddress} allChecked={allChecked} setAllChecked={setAllChecked} />
          {fastList.length > 9 && (
            <>
              <Pagination
                showFirstButton
                showLastButton
                size="small"
                count={fastShipmentTotalPages}
                page={fastShipmentCurrentPage}
                onChange={handlePageChangeManuel}
                className="fast-shipment-pagination"
              />
              {bulkLoading ? (
                <div style={{ display: "flex", alignItems: "center", justifyContent: "center" }}>
                  <CircularProgress sx={{ width: "21px!important", height: "21px!important" }} />
                </div>
              ) : (
                <Tooltip
                  className="fast-shipment-pagination"
                  title={
                    fastChecked.length === 0
                      ? "Toplu gönderi oluşturabilmeniz için ürün seçmeniz gerekmektedir."
                      : ""
                  }
                >
                  <div style={{ width: "100%" }}>
                    <Button
                      sx={{ ":disabled": { opacity: 0.3 }, marginBottom: "10px" }}
                      disabled={fastChecked.length === 0}
                      className="iyzi-button-blue"
                      onClick={allFastShipmentDialog}
                    >
                      Toplu Gönderi Oluştur
                    </Button>
                  </div>
                </Tooltip>
              )}
            </>
          )}

        </>
      )}
    </Box>
  );
};

export default memo(FastShipmentList);
