import Avatar from "@mui/material/Avatar";
import { useRecoilValue } from "recoil";
import { drawerOpenState } from "../../atoms/viewPadding";
import { nameState, surnameState, emailState, memberPhotoState } from "../../atoms/membership";
import { createTheme, Tooltip } from "@mui/material";
import { Link } from "react-router-dom";
import { ThemeProvider } from "@emotion/react";

const ProfileDetails = () => {
  const memberPhoto = useRecoilValue(memberPhotoState);

  const drawerOpen = useRecoilValue(drawerOpenState);
  const name = useRecoilValue(nameState);
  const surname = useRecoilValue(surnameState);
  const email = useRecoilValue(emailState);

  const member = JSON.parse(localStorage.getItem("user"));
  const memberId = member?.user?.user_member?.id;
  const memberPp = member?.user?.photo;

  const pp =
    !memberPp || memberPp === "" ? memberPhoto : memberPhoto !== memberPp ? memberPhoto : memberPp;

  const profileHeadlineHeader = drawerOpen
    ? {
        visibility: "visible",
        whiteSpace: "nowrap",
        transition: "all 150ms linear",
        opacity: "1",
      }
    : {
        visibility: "hidden",
        opacity: "0",
        whiteSpace: "nowrap",
        transition: "all 150ms linear",
        width: "0",
      };

  const profileHeadlineText = drawerOpen
    ? {
        opacity: "1",
        visibility: "visible",
        whiteSpace: "nowrap",
        transition: "all 150ms linear",
      }
    : {
        opacity: "0",
        visibility: "hidden",
        whiteSpace: "nowrap",
        transition: "all 150ms linear",
        width: "0",
      };

  const theme = createTheme(
    drawerOpen
      ? {
          components: {
            MuiAccordionSummary: {
              styleOverrides: {
                content: {
                  margin: "0 10px !important",
                  transition: "all 150ms linear",
                  backgroundColor: "var(--background)",
                  borderRadius: "var(--wrapperBorderRadius)",
                },
              },
            },
            MuiAccordionDetails: {
              styleOverrides: {
                root: {
                  margin: "0 10px",
                  padding: "16px 0 0 !important",
                  transition: "all 150ms linear",
                  backgroundColor: "var(--background)",
                  borderRadius: "var(--wrapperBorderRadius)",
                },
              },
            },
            MuiDrawer: {
              styleOverrides: {
                paper: {
                  overflow: "hidden",
                },
              },
            },
          },
        }
      : {
          components: {
            MuiAccordionSummary: {
              styleOverrides: {
                content: {
                  margin: "0 !important",
                  transition: "all 150ms linear",
                  backgroundColor: "transparent !important",
                },
              },
            },
            MuiAccordionDetails: {
              styleOverrides: {
                root: {
                  margin: "0",
                  padding: "16px 0 0 !important",
                  transition: "all 150ms linear",
                },
              },
            },
          },
        }
  );

  const accordion = {
    width: drawerOpen ? "264px" : "64px",
    whiteSpace: "nowrap",
    transition: "width 150ms linear",
    textDecoration: "none",
    color: "inherit",
  };

  return (
    <ThemeProvider theme={theme}>
      <Link
        className="profile-details-accordion"
        style={accordion}
        to={"/profile-settings/general"}
      >
        <div
          className="profile-details-accordion-summary"
          style={{
            display: "flex",
            alignItems: "center",
            gap: "15px",
            backgroundColor: "var(--pastelBlue)",
          }}
        >
          <Tooltip title={drawerOpen ? "" : "Profil Bilgilerim"} placement="right">
            <Avatar
              sx={
                drawerOpen
                  ? {
                      bgcolor: "var(--blueBrand)",
                      width: "32px",
                      height: "32px",
                      marginLeft: "20px",
                      transition: "all 150ms linear",
                    }
                  : {
                      bgcolor: "var(--blueBrand)",
                      width: "32px",
                      height: "32px",
                      marginLeft: "16px",
                      transition: "all 150ms linear",
                    }
              }
              aria-label="recipe"
            >
              {pp ? (
                <Avatar
                  src={`data:image/png;base64,${memberPhoto}`}
                  sx={{ width: "100%", height: "100%" }}
                />
              ) : (
                <span style={{ fontSize: "14px" }}>
                  {name?.charAt(0).toUpperCase()}
                  {surname?.charAt(0).toUpperCase()}
                </span>
              )}
            </Avatar>
          </Tooltip>
          <div
            className="profile-headline-container"
            style={{ display: "flex", justifyContent: "flex-start" }}
          >
            <div className="profile-headline-wrapper">
              <div className="profile-headline-header" style={profileHeadlineHeader}>
                {name?.length + surname?.length > 20
                  ? `${name?.charAt(0).toUpperCase()}.`
                  : name?.charAt(0).toUpperCase() + name?.slice(1)}{" "}
                {surname?.toUpperCase()}
              </div>
              <div className="profile-headline-text" style={profileHeadlineText}>
                {email}
              </div>
              <div className="profile-headline-text" style={profileHeadlineText}>
                Müşteri Numarası: {memberId}
              </div>
            </div>
          </div>
        </div>
      </Link>
    </ThemeProvider>
  );
};

export default ProfileDetails;
