const user = JSON.parse(localStorage.getItem("user"));

export const saveUser = (response) => {
  // LocalStorage'dan 'user' verisini al
  const userStr = localStorage.getItem("user");

  // Eğer 'user' verisi varsa, onu bir nesneye dönüştür
  if (userStr) {
    const userObj = JSON.parse(userStr);

    // 'user' objesini güncelle
    userObj.user = response.data.data;

    // Güncellenmiş nesneyi tekrar LocalStorage'a kaydet
    localStorage.setItem("user", JSON.stringify(userObj));
  }
};

export const saveUserItem = (key, value) => {
  // LocalStorage'dan 'user' verisini al
  const userStr = localStorage.getItem("user");

  // Eğer 'user' verisi varsa, onu bir nesneye dönüştür
  if (userStr) {
    const userObj = JSON.parse(userStr);
    // 'user' objesini güncelle
    userObj.user[key] = value;
    // Güncellenmiş nesneyi tekrar LocalStorage'a kaydet
    localStorage.setItem("user", JSON.stringify(userObj));
  }
};

export const isMainUser = user?.user?.is_main_user;
export const isPhoneRegistered = user?.user?.phone !== null;
