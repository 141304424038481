import { Button, Dialog, DialogActions, DialogContent, DialogTitle } from "@mui/material";
import PriorityHighOutlinedIcon from "@mui/icons-material/PriorityHighOutlined";
import { useRecoilState, useRecoilValue } from "recoil";
import {
  activeStepState,
  priceIdState
} from "../../../atoms/createShipment";

const ProformaIossError = ({ open, setOpen }) => {

  const [activeStep, setActiveStep] = useRecoilState(activeStepState);
  const [priceId, setPriceId] = useRecoilState(priceIdState);

  const handleClose = () => {
    setOpen(false);
  };

  const handleCreate = () => {
    setPriceId("")
    setActiveStep((prevActiveStep) => prevActiveStep - 2);
  };

  return (
    <Dialog open={open} onClose={handleClose}>
      <DialogTitle sx={{ width: "100%", display: "flex", justifyContent: "center" }}>
        <PriorityHighOutlinedIcon
          sx={{
            width: "60px",
            height: "60px",
            color: "var(--btnBgBlue)",
          }}
        />
      </DialogTitle>
      <DialogContent>
        TOPLAM ÜRÜN BEDELİNİZ 150 EURO ALTINDA OLDUĞU İÇİN "IOSS NUMARASI" GİRİLMESİ ZORUNLUDUR. <br /> <br />
        IOSS NUMARANIZ VAR İSE DÜZENLE TUŞUNA BASARAK İLGİLİ ALANI DOLDURUNUZ. <br /> <br />
        IOSS NUMARANIZ YOK İSE FİYAT KARTINA GİT BUTONUNA BASARAK SEÇTİĞİNİZ SERVİSTEKİ YURTDIŞI GÜMRÜK VERGİSİ KUTUCUĞUNU SEÇEREK İLERLEYİNİZ.
      </DialogContent>
      <DialogActions>
        <Button onClick={handleCreate} className="iyzi-button-blue">
          Fiyat Kartına Git
        </Button>
        <Button onClick={handleClose} className="iyzi-button-blue">
          Düzenle
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default ProformaIossError;
