import { atom } from "recoil";

export const orderListState = atom({
  key: "orderList",
  default: [],
});

export const orderListCheckedListState = atom({
  key: "orderListCheckedList",
  default: [],
});

export const orderDeleteModalState = atom({
  key: "orderDeleteModal",
  default: { status: false, data: {} },
});

export const orderCompleteModalState = atom({
  key: "orderCompleteModal",
  default: false,
});

export const orderSuccessModalState = atom({
  key: "orderSuccessModal",
  default: false,
});

export const orderTicketModalState = atom({
  key: "orderTicketModal",
  default: false,
});

export const pageFormatState = atom({
  key: "pageFormat",
  default: "A4",
});

export const orderBarcodeLabelState = atom({
  key: "orderBarcodeLabel",
  default: "",
});

export const orderBarcodeTopLabelState = atom({
  key: "orderBarcodeTopLabel",
  default: "",
});
