import "../../../style/myShipments/ShipmentDimensionDetail.css";
import { Modal } from "@mui/material";
import { useRecoilState } from "recoil";
import { shipmentDimensionDetailState } from "../../../atoms/myShipments";
import { useCallback } from "react";

const ShipmentDimensionDetail = () => {
  const [shipmentDimensionDetail, setShipmentDimensionDetail] = useRecoilState(
    shipmentDimensionDetailState
  );

  const handleClose = useCallback(() => {
    setShipmentDimensionDetail({ status: false, data: {} });
  }, [setShipmentDimensionDetail]);

  return (
    <Modal
      className="shipment-dimension-detail-modal"
      open={shipmentDimensionDetail.status}
      onClose={handleClose}
    >
      <div
        className="shipment-dimension-detail-wrapper"
        style={{
          maxHeight: "90vh",
          overflowY: "auto",
        }}
      >
        <div className="shipment-dimension-detail">
          <div className="dimension-detail-per-side-wrapper">
            <div className="per-side-headline customer">Ölçüleriniz</div>
            {shipmentDimensionDetail.data?.get_parcel_dimensions
              ?.filter((e) => Number(e.status) === 1)
              .map((dim, key) => (
                <div key={key} className="dimension-detail-per-side">
                  <div className="detail-per-side">
                    <div className="per-side-subline">Kap Başına (Kap Sayısı: {dim?.qty});</div>
                    <div className="per-side-content">Ücret Ağırlığı: {dim?.cw}</div>
                    <div className="per-side-content">Brüt Ağırlık: {dim?.gw}</div>
                    <div className="per-side-content">Hacim / Desi: {dim?.vw}</div>
                    <div className="per-side-content">Ağırlık: {dim?.gross} kg</div>
                    <div className="per-side-content">En: {dim?.length} cm</div>
                    <div className="per-side-content">Boy: {dim?.width} cm</div>
                    <div className="per-side-content">Yükselik: {dim?.height} cm</div>
                  </div>
                  <div></div>
                </div>
              ))}
          </div>
          <div className="dimension-detail-per-side-wrapper">
            <div className="per-side-headline iyzi">iyziTeam Ölçüleri</div>
            {shipmentDimensionDetail.data?.get_parcel_dimensions
              ?.filter((e) => Number(e.status) === 2)
              ?.map((dim, key) => (
                <div key={key} className="dimension-detail-per-side">
                  <div className="detail-per-side">
                    <div className="per-side-subline">Kap Başına (Kap Sayısı: {dim?.qty});</div>
                    <div className="per-side-content">Ücret Ağırlığı: {dim?.cw}</div>
                    <div className="per-side-content">Brüt Ağırlık: {dim?.gw}</div>
                    <div className="per-side-content">Hacim / Desi: {dim?.vw}</div>
                    <div className="per-side-content">Ağırlık: {dim?.gross} kg</div>
                    <div className="per-side-content">En: {dim?.length} cm</div>
                    <div className="per-side-content">Boy: {dim?.width} cm</div>
                    <div className="per-side-content">Yükselik: {dim?.height} cm</div>
                  </div>
                </div>
              ))}
            {shipmentDimensionDetail.data?.get_parcel_dimensions?.filter(
              (e) => Number(e.status) === 2
            )?.length === 0 &&
              "Ekibimiz ölçü girişini gerçekleştirdiğinde detayları burada görebileceksiniz."}
          </div>
        </div>
      </div>
    </Modal>
  );
};

export default ShipmentDimensionDetail;
