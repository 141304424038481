import "../style/myShipments/MyShipments.css";
import { Box } from "@mui/material";
import axios from "../api/axios";
import { ToastContainer } from "react-toastify";
import { useCookies } from "react-cookie";
import NotificationBar from "../components/sidebar/NotificationBar";
import { DynamicTitle } from "../utils/DynamicTitle";
import WrapperStyle from "../utils/WrapperStyle";
// import PageTransition from "../utils/PageTransition";
import ParcelReturnDemand from "../components/myShipments/modals/ParcelReturnDemand";

const ReturnInformationAdd = () => {
  DynamicTitle("İade Bilgisi Ekle | iyziShip");

  const [token] = useCookies(["jwt"]);
  const jwt = token.jwt;
  axios.defaults.headers.common["Authorization"] = `Bearer ${jwt}`;

  return (
    <section className="myShipments">
      <NotificationBar />
      {/* <PageTransition> */}
        <ToastContainer />
        <Box className="myShipments-wrapper" style={WrapperStyle()}>
          <ParcelReturnDemand />
        </Box>
      {/* </PageTransition> */}
    </section>
  );
};

export default ReturnInformationAdd;
