import { useRecoilValue } from "recoil";
import { drawerOpenState, notificationOpenState } from "../atoms/viewPadding";
import { useEffect, useState } from "react";

const WrapperStyle = () => {
  const drawerOpen = useRecoilValue(drawerOpenState);
  const notificationOpen = useRecoilValue(notificationOpenState);
  const [mobile, setMobile] = useState(false);

  const isDashboard = window.location.href.includes("/dashboard");

  useEffect(() => {
    function handleResize() {
      if (window.innerWidth <= 768) {
        setMobile(true);
      } else {
        setMobile(false);
      }
    }
    window.addEventListener("resize", handleResize);

    // initial check
    handleResize();
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  if (mobile) {
    return {
      paddingRight: "20px",
      paddingLeft: "20px",
      transition: "all 150ms linear",
    };
  } else {
    return drawerOpen && notificationOpen
      ? {
          paddingRight: "20px",
          paddingLeft: "285px",
          transition: "all 150ms linear",
        }
      : isDashboard && drawerOpen
      ? {
          paddingRight: "20px",
          paddingLeft: "285px",
          transition: "all 150ms linear",
        }
      
      : drawerOpen && !notificationOpen
      ? {
          paddingRight: "20px",
          paddingLeft: "285px",
          transition: "all 150ms linear",
        }
      : !drawerOpen && notificationOpen
      ? {
          paddingRight: "20px",
          paddingLeft: "20px",
          transition: "all 150ms linear",
        }
      : !drawerOpen && notificationOpen
      ? {
          paddingRight: "20px",
          paddingLeft: "20px",
          transition: "all 150ms linear",
        }
      : !drawerOpen && notificationOpen
      ? {
          paddingRight: "20px",
          paddingLeft: "20px",
          transition: "all 150ms linear",
        }
      : {
          paddingRight: "20px",
          paddingLeft: "20px",
          transition: "all 150ms linear",
        };
  }
};

export default WrapperStyle;
