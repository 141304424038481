import "../../../style/globals/PageHeaderTitle.css";
import HelpOutlineOutlinedIcon from "@mui/icons-material/HelpOutlineOutlined";
import WrapperStyle from "../../../utils/WrapperStyle";

const RefundTitle = () => {
  return (
    <div className="page-header-title" style={WrapperStyle()}>
      <div className="page-header-title-wrapper">
        <div className="page-header-title-headline">
          <svg
            width="24"
            height="24"
            viewBox="0 0 24 24"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <mask
              id="mask0_6114_16820"
              style={{ maskType: "alpha" }}
              maskUnits="userSpaceOnUse"
              x="0"
              y="0"
              width="24"
              height="24"
            >
              <rect y="0.5" width="24" height="24" fill="#D9D9D9" />
            </mask>
            <g mask="url(#mask0_6114_16820)">
              <path
                d="M12 21.5C10.75 21.5 9.57917 21.2625 8.4875 20.7875C7.39583 20.3125 6.44583 19.6708 5.6375 18.8625C4.82917 18.0542 4.1875 17.1042 3.7125 16.0125C3.2375 14.9208 3 13.75 3 12.5C3 11.25 3.2375 10.0792 3.7125 8.9875C4.1875 7.89583 4.82917 6.94583 5.6375 6.1375C6.44583 5.32917 7.39583 4.6875 8.4875 4.2125C9.57917 3.7375 10.75 3.5 12 3.5C13.3667 3.5 14.6625 3.79167 15.8875 4.375C17.1125 4.95833 18.15 5.78333 19 6.85V4.5H21V10.5H15V8.5H17.75C17.0667 7.56667 16.225 6.83333 15.225 6.3C14.225 5.76667 13.15 5.5 12 5.5C10.05 5.5 8.39583 6.17917 7.0375 7.5375C5.67917 8.89583 5 10.55 5 12.5C5 14.45 5.67917 16.1042 7.0375 17.4625C8.39583 18.8208 10.05 19.5 12 19.5C13.75 19.5 15.2792 18.9333 16.5875 17.8C17.8958 16.6667 18.6667 15.2333 18.9 13.5H20.95C20.7 15.7833 19.7208 17.6875 18.0125 19.2125C16.3042 20.7375 14.3 21.5 12 21.5Z"
                fill="currentColor"
              />
            </g>
          </svg>
          <h1>Bakiye İade Talebi</h1>
        </div>
        <div className="page-header-title-how-link">
          <HelpOutlineOutlinedIcon />
          <span>Bu Sayfa Nasıl Kullanılır?</span>
        </div>
      </div>
    </div>
  );
};

export default RefundTitle;
