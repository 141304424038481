import "../style/etsy/Etsy.css"
import { useState } from "react";
import Footer from "../components/beforeLogin/Footer";
import Header from "../components/beforeLogin/Header";
import HomePriceForm from "../components/home/HomePriceForm";
import { DynamicTitle } from "../utils/DynamicTitle";
import ShowPrice from "../components/home/ShowPrice";
import { useRef, useEffect } from "react";
import { Button, Modal, Box, Typography } from "@mui/material";
import { useNavigate } from "react-router-dom";
import { Helmet } from "react-helmet";
import { Link } from "react-router-dom";
import CancelOutlinedIcon from "@mui/icons-material/CancelOutlined";
import combinedShape from "../img/icons/nasılCalisir.svg";


const Etsy = () => {
    DynamicTitle("Etsy | iyziShip");

    const navigate = useNavigate();

    const [showPrice, setShowPrice] = useState(false);
    const [price, setPrice] = useState([]);
    const mainPriceCardRef = useRef(null);

    const [modalOpen, setModalOpen] = useState(false);
        
        const handleClick = () => {
            setModalOpen(true);
        };
        
        const handleClose = () => {
            setModalOpen(false);
        };

    useEffect(() => {
        if (showPrice && mainPriceCardRef.current) {
        const position = mainPriceCardRef.current.offsetTop - 250; // 50px yukarı kaydır
        window.scrollTo({ top: position, behavior: "smooth" });
        }
    }, [showPrice]);

    const toggleFAQ = (event) => {
        const question = event.currentTarget;
        const answer = question.nextElementSibling;
        const toggle = question.querySelector('.toggle');

        if (answer.style.display === 'none' || answer.style.display === '') {
            answer.style.display = 'block';
            answer.style.fontSize = '20px';
            question.classList.add('active');
            toggle.textContent = '-';
        } else {
            answer.style.display = 'none';
            question.classList.remove('active');
            toggle.textContent = '+';
        }
    };

    

    return (
        <>
        <Helmet>
            <meta
            name="title"
            content="Etsy kargo sürecinizi iyziShip ile basitleştirin! | iyziShip"
            />
            <meta
            name="description"
            content="Etsy kargo sürecinizi iyziShip ile kolaylaştırın, zaman ve maliyetten tasarruf edin. Hemen kaydolun ve ücretsiz Etsy entegrasyonu fırsatını kaçırmayın!"
            />
            <link rel="canonical" href="https://www.iyziship.com/etsy"/>
        </Helmet>
        {/* <PageTransition> */}
            <Header />
            <main className="iyzi-main" id="etsy">
                <section class="main-header get-start">
                    <div class="field">
                        <div class="main-header-text get-start-text">
                            <article>
                                <h1>
                                    Etsy Satışlarınız İçin Hızlı ve Güvenilir<br/>Kargo Gönderimi
                                </h1>
                                <p>iyziShip ile Etsy kargo gönderiminde sınırları ortadan kaldırın. Etsy yurt dışı kargo çözümlerimizle, ürünlerinizi dünyanın dört bir yanındaki müşterilerinize hızlı, güvenilir ve uygun maliyetlerle ulaştırıyoruz. Kolay entegrasyon ve her adımda yanınızda olan lojistik danışmanlarımızla global satışlarınızı artırın. Hemen kaydolun ve avantajlarımızdan yararlanmaya başlayın!</p>
                            </article>
                            <div class="main-header-text-buttons">
                                <a class="iyzi-button-blue" href="/register">
                                    Ücretsiz Deneyin
                                </a>
                                <a class="iyzi-button-bg" href="#info-price">
                                    Hemen Fiyat Al
                                </a>
                            </div>
                        </div>
                    </div>
                </section>  
                <section class="main-info" style={{maxWidth:"1800px"}}>
                    <div class="info-container">
                        <div class="info-image" style={{maxWidth:"600px"}}>
                            <img src="/images/etsy/header.svg" alt="Header" loading="lazy"/>
                        </div>
                        <div class="info" style={{maxWidth:"1200px"}}>
                            <article>
                                <h2 style={{textAlign:"center"}}>Ücretsiz Etsy Entegrasyonu ile Tüm Lojistik Sürecinizi Tek Panelden Yönetin</h2>
                                <p>iyziShip, ücretsiz Etsy entegrasyonu ile lojistik süreçlerinizi basitleştiriyor ve tüm gönderim işlemlerinizi tek bir panelden yönetmenizi sağlıyor. Etsy kargo entegrasyonu ile siparişlerinizi kolayca takip edebilir, gönderimlerinizi zahmetsizce planlayabilirsiniz. iyziShip ile zaman ve emekten tasarruf edin, kargo süreçlerinizi optimize edin ve Etsy mağazanızın verimliliğini en üst seviyeye çıkarın.</p>
                            </article>
                            <div class="main-header-text-buttons">
                                <a class="iyzi-button-blue" href="/register">
                                    Mağazanı Ücretsiz Bağla
                                </a>
                                <a class="iyzi-button-bg" href="/iletisim">
                                    <img src="/images/etsy/ic_round-support-agent-2.svg" alt="" width="30px"/>
                                    Bilgi Al
                                </a>
                            </div>
                        </div>
                    </div>
                </section>
                <section class="main-ishowitwork-frame">
                    <div class="ishowitwork-frame">
                        <article>
                            <h2>iyziShip ile Etsy Kargolarınızı Nasıl Gönderebilirsiniz?</h2>
                            <div class="grid-container-wrapper">
                                <div class="grid-container">
                                    <div class="grid-item">
                                        <img src="/images/etsy/frame-1.svg" alt="Etsy Mağazanızı Entegre Edin"/>
                                        <div class="grid-item-bottom">
                                            <h3>Etsy Mağazanızı Entegre Edin</h3>
                                            <p>Etsy mağazanızı ücretsiz olarak iyziShip paneline tek tıkla entegre edin.</p>
                                        </div>
                                    </div>
                                    <div class="grid-item">
                                        <img src="/images/etsy/frame-2.svg" alt="Teklifleri Kolayca Karşılaştırın"/>
                                        <div class="grid-item-bottom">
                                            <h3>Teklifleri Kolayca Karşılaştırın</h3>
                                            <p>Dünyanın önde gelen uluslararası kargo firmalarının yurt dışı gönderim tekliflerini karşılaştırın.</p>
                                        </div>
                                    </div>
                                    <div class="grid-item">
                                        <img src="/images/etsy/frame-3.svg" alt="Online Ödemenizi Yapın"/>
                                        <div class="grid-item-bottom">
                                            <h3>Online Ödemenizi Yapın</h3>
                                            <p>Gönderi gereksinimlerinize en uygun teklifi seçtikten sonra, online ödemenizi kolayca gerçekleştirin.</p>
                                        </div>
                                    </div>
                                    <div class="grid-item">
                                        <img src="/images/etsy/frame-4.svg" alt="Gönderinizi Takip Edin"/>
                                        <div class="grid-item-bottom">
                                            <h3>Gönderinizi Takip Edin</h3>
                                            <p>Yurt dışı kargonuzun konumunu ve durumunu anlık olarak takip edin; gönderilerinizle ilgili ayrıntılı raporlar elde edin.</p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="button-container">
                                <a class="left-btn" href="/nasil-calisir">Detaylı Bilgi</a>
                                <Link
                                style={{
                                    padding: "10px 22px",
                                    minWidth: "200px",
                                    fontWeight: 700,
                                    display: "flex",
                                    justifyContent: "center",
                                    alignItems: "center",
                                    textDecoration: "none",
                                    height: " 49.927px",
                                    gap: "18px",
                                    fontSize: "14px",
                                    lineHeight: "18px",
                                    cursor: "pointer",
                                }}
                                to={"/etsy"}
                                className="righ-btn"
                                onClick={handleClick}
                                >
                                <img
                                    src= {combinedShape}
                                    alt="combined-Shape"
                                    style={{ height: "32px", width: " 32px" }}
                                />{" "}
                                Nasıl Çalışır?
                                </Link>
                                <Modal
                                    open={modalOpen}
                                    onClose={handleClose}
                                    aria-labelledby="modal-modal-title"
                                    aria-describedby="modal-modal-description"
                                >
                                    <Box
                                    sx={{
                                        position: "absolute",
                                        top: "50%",
                                        left: "50%",
                                        transform: "translate(-50%, -50%)",
                                        width: "70%",
                                        height: "70%",
                                        bgcolor: "background.paper",
                                        boxShadow: 24,
                                        p: 4,
                                        borderRadius: 5,
                                    }}
                                    >
                                    <Typography
                                        sx={{
                                        display: "flex",
                                        justifyContent: "flex-end",
                                        alignItems: "center",
                                        lineHeight: "1.5",
                                        }}
                                        id="modal-modal-title"
                                        variant="h6"
                                        component="h2"
                                    >
                                        <CancelOutlinedIcon
                                        sx={{
                                            right: 1,
                                            top: 1,
                                            cursor: "pointer",
                                            color: "var(--priceText)",
                                            transition: "all 80ms linear",
                                            ":hover": {
                                            color: "var(--notRed)",
                                            transition: "all 80ms linear",
                                            },
                                        }}
                                        onClick={handleClose}
                                        />
                                        <br /> <br />
                                    </Typography>
                                    <iframe
                                        width="100%"
                                        height="85%"
                                        src="https://www.youtube.com/embed/ClY9lrjy_gA"
                                        title="iyziShip Nasıl Çalışır? | Bir Gönderi Hikayesi"
                                        frameborder="0"
                                        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                                        allowfullscreen
                                    ></iframe>
                                    </Box>
                                </Modal>
                            </div>
                        </article>
                    </div>
                </section>
                <section class="main-info" id="main-info">
                    <div class="info-container">

                    </div>
                    <div class="info-container">
                        <div class="info-image">
                            <img src="/images/etsy/info-1.svg" alt="Etsy Kargolarınızı %80'e Varan İndirimlerle Gönderin" loading="lazy"/>
                        </div>

                        <div class="info">
                            <article>
                                <h2>Etsy Kargolarınızı %80'e Varan İndirimlerle Gönderin</h2>
                                <p>Etsy kargo ücretleri, uluslararası satış yapan Etsy satıcıları için önemli bir maliyet kalemidir. iyziShip olarak, Etsy kargo gönderimlerinizde %80'e varan indirimlerle uygun fiyatlı çözümler sunuyor, lojistik giderlerinizi minimuma indiriyoruz. iyziShip ile kargo maliyetlerinizi düşürün ve Etsy mağazanızın karlılığını artırın.</p>
                            </article>
                        </div>
                    </div>
                    <div class="info-container">
                        <div class="info-image">
                            <img src="/images/etsy/info-2.svg" alt="iyziShip ile Etsy Kargolarınız Güvende" loading="lazy"/>
                        </div>
                        <div class="info">
                            <article>
                                <h2>iyziShip ile Etsy Kargolarınız Güvende</h2>
                                <p>Geniş lojistik ağımız ve deneyimli kadromuz sayesinde, Etsy kargo süreçlerinizde en yüksek güvenlik standartlarını sağlıyoruz. Her gönderinizin güvenli ve sorunsuz bir şekilde müşterilerinize ulaşması için titizlikle hareket ediyoruz. iyziShip ile ürünlerinizin güvenliğinden emin olun, kargo süreçlerinizi kolaylaştırın ve Etsy mağazanızın başarısını yeni zirvelere taşıyın.</p>
                            </article>
                        </div>
                    </div>
                    <div class="info-container">
                        <div class="info-price" id="info-price">
                            <div class="info-content">
                                <div class="info-text">
                                    <h2>Etsy Kargo Ücreti Nasıl Hesaplanır?</h2>
                                    <p>Etsy kargo ücreti hesaplama aracımız ile maliyetlerinizi kolayca öğrenin. Boyut, ağırlık ve teslimat adresine göre otomatik hesaplamalar yaparak, her gönderi için en uygun ücreti belirleyin. iyziShip ile sürpriz masraflardan kaçının ve gönderim maliyetlerinizi önceden planlayın.</p>
                                </div>
                                <div class="price-form-container">
                                    <div class="price-form">
                                        <HomePriceForm setShowPrice={setShowPrice} setPrice={setPrice}/>
                                    </div>
                                </div>
                            </div>
                            <div
                            class="main-price-get-card"
                            ref={mainPriceCardRef}
                            style={{
                                boxShadow: showPrice ? "var(--blueShadow)" : "",
                                padding: showPrice ? "40px 20px " : "",
                            }}
                        >
                            {showPrice &&
                            price.map((price, index) => (
                                <ShowPrice price={price} key={index} />
                            ))}
                        </div>
                        {showPrice && (
                            <Button
                                className="iyzi-button-blue"
                                onClick={() => navigate("/register")}
                            >
                                Hemen Gönderi Oluştur
                            </Button>
                        )}
                        </div>
                        
                    </div>
            
                    <div class="info-container">
                        <div class="info-image">
                            <img src="/images/etsy/info-3.svg" alt="Lojistik Danışmanlarımız Tüm Satış Sonrası Süreçlerde Yanınızda" loading="lazy"/>
                        </div>
                        <div class="info">
                            <article>
                                <h2>Lojistik Danışmanlarımız Tüm Satış Sonrası Süreçlerde Yanınızda</h2>
                                <p>Etsy kargo gönderimi sürecinde karşılaştığınız her türlü sorunu çözmek ve size en iyi desteği sağlamak için uzman lojistik danışmanlarımız her zaman hizmetinizde. Satış sonrası süreçlerinizi profesyonel rehberlik ile yöneterek, paketlerinizin güvenli ve sorunsuz bir şekilde müşterilerinize ulaşmasını sağlıyoruz.</p>
                            </article>
                        </div>
                    </div>
                    <div class="info-container">
                        <div class="info-image">
                            <img src="/images/etsy/info-4.svg" alt="Etsy Kargolarınızı Anlık Olarak Takip Edin" loading="lazy"/>
                        </div>
                        <div class="info">
                            <article>
                                <h2>Etsy Kargolarınızı Anlık Olarak Takip Edin</h2>
                                <p>iyziShip ile Etsy kargo takibi artık çok daha kolay ve güvenilir. Gönderilerinizin nerede olduğunu her an kontrol edebilir, olası gecikmeler veya sorunlar hakkında anında bilgi sahibi olabilirsiniz. iyziShip ile kargo süreçlerinizi şeffaf ve güvenli hale getirin, müşteri memnuniyetini artırın!</p>
                            </article>
                        </div>
                    </div>
                </section>
                <section class="main-faq">
                    <h2>Etsy Kargo Gönderimi Hakkında Sıkça Sorulan Sorular</h2>
                    <div class="faq-item">
                        <div class="question" onClick={toggleFAQ}>
                            Etsy Nedir? <span class="toggle">+</span>
                        </div>
                        <div class="answer">
                            <p style={{fontSize:"20px"}}>
                                Etsy, dünya çapında el yapımı ürünler, sanat eserleri ve vintage eşyalar için özel bir pazar yeridir. 2005 yılında kurulan Etsy, benzersiz ve yaratıcı ürünler arayan alıcılar ile bağımsız üreticileri ve sanatçıları bir araya getirmektedir. Etsy platformu, satıcıların kendi çevrimiçi dükkanlarını açmalarını ve geniş bir alıcı kitlesine ulaşmalarını sağlayarak, küçük işletmelerin ve bireysel sanatçıların büyümesine olanak tanır. Etsy üzerinde, özelleştirilmiş takılar, el yapımı ev dekorasyon ürünleri, sanatsal baskılar, giysiler ve daha fazlası gibi çok çeşitli kategorilerde ürünler bulunmaktadır.
                            </p>
                        </div>
                    </div>
                    <div class="faq-item">
                        <div class="question" onClick={toggleFAQ}>
                            Etsy Türkiye'de Kullanılabilir mi? <span class="toggle">+</span>
                        </div>
                        <div class="answer" >
                            <p style={{fontSize:"20px"}}>
                                Evet, Etsy platformu Türkiye'deki kullanıcılar tarafından erişilebilir durumdadır ve Türkiye'den birçok satıcı, ürünlerini dünya çapında milyonlarca alıcıya sunmaktadır. Türkiye'deki satıcılar için Etsy, global pazara erişim imkanı sunarak yerel üreticilere dünya çapında bir vitrin sağlamaktadır. Etsy, çoklu dil desteği ve çeşitli para birimleri ile Türk satıcıların da kolaylıkla kullanabileceği global bir platformdur.
                            </p>
                        </div>
                    </div>
                    <div class="faq-item">
                        <div class="question" onClick={toggleFAQ}>
                            Etsy'de Satış Yaparken Karşılaşılacak Komisyon ve Ücretler Nelerdir? <span class="toggle">+</span>
                        </div>
                        <div class="answer">
                            <p style={{fontSize:"20px"}}>
                                Etsy, satıcılarından her satış işlemi için bir komisyon ücreti ve küçük bir işlem ücreti alır. Komisyon oranları genellikle satış fiyatının bir yüzdesi olarak belirlenir ve bu oran satış yapılan ülkeye göre değişiklik gösterebilir. İşlem ücretleri, satışın yapıldığı para birimine ve ödeme yöntemine bağlı olarak değişkenlik gösterir. Etsy ayrıca, satıcıların ürünlerini daha geniş bir kitleye tanıtmalarına yardımcı olmak için çeşitli reklam ve promosyon seçenekleri sunar, bu hizmetler ek ücrete tabidir.
                            </p>
                        </div>
                    </div>
                    <div class="faq-item">
                        <div class="question" onClick={toggleFAQ}>
                            Etsy'de mağaza açmak için neler gereklidir? <span class="toggle">+</span>
                        </div>
                        <div class="answer">
                            <p style={{fontSize:"20px"}}>
                                Etsy'de mağaza açmak için öncelikle bir Etsy hesabı oluşturmanız gerekmektedir. Hesap oluşturduktan sonra, mağazanız için bir isim seçmelisiniz ki bu isim benzersiz ve akılda kalıcı olmalıdır. Daha sonra ürün listelemelerinizi oluşturarak, ürünlerinizin fotoğraflarını yükleyip, açıklamalarını, fiyatlarını ve kargo bilgilerini eklemelisiniz. Etsy ayrıca mağazanızı kişiselleştirebileceğiniz çeşitli araçlar ve mağaza politikalarınızı belirlemenizi sağlayan seçenekler sunar.
                            </p>
                        </div>
                    </div>
                    <div class="faq-item">
                        <div class="question" onClick={toggleFAQ}>
                            iyziShip, Etsy kargo ücretini nasıl hesaplar? <span class="toggle">+</span>
                        </div>
                        <div class="answer">
                            <p style={{fontSize:"20px"}}>
                                iyziShip, Etsy satıcıları için kargo ücreti hesaplama işlemini otomatize ederek hem zaman hem de maliyetten tasarruf sağlar. Ürünün boyutu, ağırlığı ve alıcının bulunduğu yer gibi önemli faktörleri dikkate alarak en uygun kargo ücretini belirler. Etsy satıcıları, iyziShip platformunda aşağıdaki adımları takip ederek kargo ücretini kolayca hesaplayabilir:
                            </p>
                            <ol>
                                <li style={{fontSize:"20px"}}><strong style={{fontSize:"20px"}}>Ürün Bilgileri Girme: </strong>Satıcı, kargolanacak ürünün ağırlık ve boyut bilgilerini sisteme girer. Bu bilgiler, ücret hesaplamasında temel alınır.</li>
                                <li style={{fontSize:"20px"}}><strong style={{fontSize:"20px"}}>Teslimat Adresi Belirleme: </strong>Alıcının adresi girilir ve bu adres, uluslararası veya yerel teslimat maliyetlerinin hesaplanmasında kullanılır.</li>
                                <li style={{fontSize:"20px"}}><strong style={{fontSize:"20px"}}>Otomatik Hesaplama: </strong>iyziShip'in gelişmiş algoritması, girilen bilgilere göre en uygun kargo ücretini hesaplar. Bu hesaplama, anlaşmalı olduğumuz kargo firmalarının güncel tarifeleri dikkate alınarak yapılır.</li>
                                <li style={{fontSize:"20px"}}><strong style={{fontSize:"20px"}}>Ek Hizmetler: </strong>Satıcılar, sigortalı gönderim veya hızlı teslimat gibi ek hizmetleri seçerek kargo planlarını kişiselleştirebilir. Bu hizmetler, genel kargo ücretine yansıtılır ve toplam maliyeti etkiler.</li>
                            </ol>
                            <p style={{fontSize:"20px"}}>
                                iyziShip, bu süreçlerle Etsy satıcılarının kargo yönetimini kolaylaştırır, kargo takibini anında yapmalarını ve böylece müşterilerine her zaman güncel ve doğru bilgi vermelerini sağlar. Bu da müşteri memnuniyetini artırarak, satışların ve mağaza itibarının yükselmesine katkıda bulunur.
                            </p>
                        </div>
                    </div>
                </section>
                <section class="main-get-finish">
                    <div class="insider">
                        <div class="get-finish-headline">
                            <article>
                                <h6>Yurt dışına kargo göndermek artık bir tık uzağınızda!</h6>
                                <p>Her bütçeye uygun, hızlı ve güvenilir çözümlerimizle yurt dışı kargo sürecinizi kolaylaştırıyoruz. Hemen kayıt olun ve iyziShip farkını her gönderinizde hissedin!</p>
                            </article>
                            <div class="headline-links">
                                <a href="/register" class="iyzi-button-blue" >Ücretsiz Deneyin</a>
                                <a href="/fiyatlandirma" class="iyzi-button-bg">Hemen Fiyat Alın</a>
                            </div>
                        </div>
                        <div class="get-finish-mockup">
                            <img alt="iyziShip" src="/images/etsy/finish_laptop.svg" class="get-finish-laptop"/>
                        </div>
                    </div>
                </section>
            </main>
        <Footer />
        </>
    );
};

export default Etsy;