import { TableCell, TableHead, TableRow } from "@mui/material";
import { useCallback, useState } from "react";
import { useRecoilState } from "recoil";
import {
  balanceCurrentPageState,
  balanceTotalPageState,
  iyziBalanceDataState,
} from "../../../atoms/myWallet";
import axios from "../../../api/axios";
import KeyboardArrowDownOutlinedIcon from "@mui/icons-material/KeyboardArrowDownOutlined";
import Errors from "../../../utils/Errors";

const MyWalletiyziBalanceTableHead = () => {
  const [, setIyziBalanceData] = useRecoilState(iyziBalanceDataState);
  const [, setBalanceTotalPage] = useRecoilState(balanceTotalPageState);
  const [balanceCurrentPage, setBalanceCurrentPage] = useRecoilState(balanceCurrentPageState);

  const [activeSort, setActiveSort] = useState([
    { column: "created_at", direction: null },
    { column: "description_code", direction: null },
    { column: "total_balance", direction: null },
  ]);

  const handlePageChange = useCallback(
    async (orderBy) => {
      const updatedSort = activeSort.map((sort) => {
        if (sort.column === orderBy) {
          return {
            ...sort,
            direction: sort.direction === "ASC" ? "DESC" : "ASC",
          };
        }
        return {
          ...sort,
          direction: null, // Diğer sütunların yönünü varsayılan duruma sıfırlayın
        };
      });

      setActiveSort(updatedSort);

      const currentDirection = updatedSort.find((sort) => sort.column === orderBy).direction;
      axios
        .get(`wallet/list`, {
          params: {
            limit: 500,
            page: balanceCurrentPage,
            order_by: orderBy,
            order_direction: currentDirection,
          },
        })
        .then((res) => {
          setIyziBalanceData(res.data.data);
          setBalanceTotalPage(res.data.totalPage);
          setBalanceCurrentPage(res.data.currentPage);
        })
        .catch((error) => Errors(error));
    },
    [setIyziBalanceData, setBalanceTotalPage, setBalanceCurrentPage, activeSort, balanceCurrentPage]
  );

  return (
    <TableHead>
      <TableRow>
        <TableCell sx={{ fontWeight: 700 }}>Konşimento</TableCell>
        <TableCell
          sx={{ fontWeight: 700, cursor: "pointer" }}
          onClick={() => handlePageChange("created_at")}
        >
          <div style={{ fontSize: "inherit", display: "flex", gap: "5px" }}>
            Tarih{" "}
            <KeyboardArrowDownOutlinedIcon
              sx={{
                transform:
                  activeSort.find((sort) => sort.column === "created_at")?.direction === "ASC"
                    ? "rotate(180deg)"
                    : "rotate(0deg)",
                transition: "transform 0.3s",
              }}
            />
          </div>
        </TableCell>
        <TableCell
          onClick={() => handlePageChange("description_code")}
          sx={{ fontWeight: 700, cursor: "pointer" }}
        >
          <div style={{ fontSize: "inherit", display: "flex", gap: "5px" }}>
            Açıklama{" "}
            <KeyboardArrowDownOutlinedIcon
              sx={{
                transform:
                  activeSort.find((sort) => sort.column === "description_code")?.direction === "ASC"
                    ? "rotate(180deg)"
                    : "rotate(0deg)",
                transition: "transform 0.3s",
              }}
            />
          </div>
        </TableCell>
        <TableCell sx={{ fontWeight: 700 }}>iyziBakiyem Çıkışı</TableCell>
        <TableCell onClick={() => handlePageChange("created_at")} sx={{ fontWeight: 700 }}>
          iyziBakiyem Girişi
        </TableCell>
        {/* <TableCell
          onClick={() => handlePageChange("total_balance")}
          sx={{ fontWeight: 700, cursor: "pointer" }}
        >
          <div style={{ fontSize: "inherit", display: "flex", gap: "5px" }}>
            Toplam iyziBakiyem{" "}
            <KeyboardArrowDownOutlinedIcon
              sx={{
                transform:
                  activeSort.find((sort) => sort.column === "total_balance")?.direction === "ASC"
                    ? "rotate(180deg)"
                    : "rotate(0deg)",
                transition: "transform 0.3s",
              }}
            />
          </div>
        </TableCell> */}
      </TableRow>
    </TableHead>
  );
};

export default MyWalletiyziBalanceTableHead;
