import { TextField } from "@mui/material";
import { useEffect } from "react";

const CreatedDate = ({ setCreateDateStart, setCreateDateEnd, createDateStart, createDateEnd, dateType }) => {

  useEffect(() => {
    setCreateDateStart("");
    setCreateDateEnd("");
  }, [dateType]);

  return (
    <>
      <TextField
        size="small"
        label="Tarih Başlangıç"
        type="date"
        value={createDateStart}
        onChange={(e) => setCreateDateStart(e.target.value)}
        InputLabelProps={{
          shrink: true,
        }}
        InputProps={{
          placeholder: '',
        }}
        autoComplete="off" />
      <TextField
        size="small"
        label="Tarih Bitiş"
        type="date"
        value={createDateEnd}
        onChange={(e) => setCreateDateEnd(e.target.value)}
        InputLabelProps={{
          shrink: true,
        }}
        InputProps={{
          placeholder: '',
        }}
        autoComplete="off" />
    </>
  );
};

export default CreatedDate;
