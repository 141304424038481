import { Box, createTheme, ThemeProvider } from "@mui/material";
import { useEffect, useRef, useState, useCallback } from "react";
import { useRecoilValue, useRecoilState } from "recoil";
import {
  expandFilesState,
  ticketState,
  openSupportDetailState,
  openSupportListState,
} from "../../atoms/support";
import Currency from "../../utils/CurrencySymbol";
import SupportDetailFiles from "./supportDetail/SupportDetailFiles";
import SupportDetailNotes from "./supportDetail/SupportDetailNotes";
import SupportDetailFinance from "./supportDetail/SupportDetailFinance";
import SupportDetailStepper from "./supportDetail/SupportDetailStepper";
import SupportDetailChat from "./supportDetail/SupportDetailChat";
import SupportDetailStepperCountries from "./supportDetail/SupportDetailStepperCountries";
import SupportDetailStepperInfo from "./supportDetail/SupportDetailStepperInfo";
import LocalPrice from "../../utils/LocalPrice";

const theme = createTheme({
  components: {
    MuiCard: {
      styleOverrides: {
        root: {
          boxShadow: "0px 2px 10px rgb(0 0 0 / 10%)",
          borderRadius: "10px",
        },
      },
    },
    MuiTypography: {
      styleOverrides: {
        h5: {
          color: "#6a707e",
        },
      },
    },
    MuiInputBase: {
      styleOverrides: {
        root: {
          borderRadius: "9999px !important",
        },
      },
    },
  },
});

const SupportDetail = () => {
  const [ticket, setTicket] = useRecoilState(ticketState);
  const expandFiles = useRecoilValue(expandFilesState);
  const [, setOpenSupportDetail] = useRecoilState(openSupportDetailState);
  const [, setOpenSupportList] = useRecoilState(openSupportListState);
  const [rows, setRows] = useState([]);
  const cardRef = useRef(null);

  const createData = (date, hour, type, price, exchangeRate, fullName, paid) => {
    return { date, hour, type, price, exchangeRate, fullName, paid };
  };

  useEffect(() => {
    if (ticket.get_parcel !== null) {
      setRows(
        ticket.get_parcel?.get_parcel_price_informations[0]?.get_details?.map((detail) =>
          createData(
            `${detail.created_at.slice(0, 10)}`,
            `${detail.created_at.slice(11, 19)}`,
            `${detail.type}`,
            `${Currency(ticket.get_parcel?.get_parcel_price_informations[0])}${LocalPrice(
              detail.selling_price
            )} / ${(Number(detail.selling_price) * Number(detail.exchange_rate)).toFixed(2)} ₺`,
            `${detail.exchange_rate}`,
            `${detail.get_created_by.name} ${detail.get_created_by.surname}`,
            `${detail.paid}`
          )
        )
      );
    }
  }, [ticket]);

  useEffect(() => {
    if (expandFiles) {
      if (cardRef.current) {
        cardRef.current.scrollIntoView({ behavior: "smooth" });
      }
    }
  }, [expandFiles]);

  const handleCloseSupportDetail = useCallback(() => {
    setOpenSupportDetail(false);
    setOpenSupportList(true);
    setTicket({});
  }, [setOpenSupportDetail, setOpenSupportList, setTicket]);

  return (
    <ThemeProvider theme={theme}>
      <Box className="support-detail">
        <h6>Destek Talebi</h6>
        <Box className="support-detail-container">
          <Box className="support-detail-info">
            <Box className="detail-info-text">
              <Box
                sx={{
                  cursor: "pointer",
                  color: "var(--shipmentBlue)",
                  fontSize: "15px",
                }}
                onClick={handleCloseSupportDetail}
              >
                Geri Dön
              </Box>
            </Box>
            <Box className="detail-info-text">Vaka Numarası: {ticket?.ticket_code}</Box>
            <Box className="detail-info-text">Müşteri ID: {ticket?.member_id}</Box>
            <Box className="detail-info-text">
              Oluşturan: {ticket?.get_created_by.name} {ticket?.get_created_by.surname}
            </Box>
            <Box className="detail-info-text">Konu: {ticket?.subject_name}</Box>
          </Box>
          {ticket.get_parcel !== null && (
            <Box className="stepper">
              <Box className="stepper-wrapper">
                <SupportDetailStepperCountries />
                <SupportDetailStepper />
              </Box>
              <SupportDetailStepperInfo />
            </Box>
          )}
          <SupportDetailChat />
          <Box ref={cardRef}>
            <SupportDetailFiles />
          </Box>
          <SupportDetailNotes />
          <SupportDetailFinance rows={rows} />
        </Box>
      </Box>
    </ThemeProvider>
  );
};

export default SupportDetail;
