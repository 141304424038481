import "../../style/sidebar/PaymentBasket.css";
import { Badge, Box, Button, Menu, Tooltip } from "@mui/material";
import { useCallback, useEffect, useState } from "react";
import { useRecoilValue, useSetRecoilState } from "recoil";
import { basketItemsState, basketTotalItemsState } from "../../atoms/basket";
import BasketItems from "./basket/BasketItems";
import { useRecoilState } from "recoil";
import { useCookies } from "react-cookie";
import axios from "../../api/axios";
import BasketPayment from "./basket/BasketPayment";
import { NavLink } from "react-router-dom";
import ShoppingCartOutlinedIcon from "@mui/icons-material/ShoppingCartOutlined";

const Basket = () => {
  const [token] = useCookies(["jwt"]);
  const jwt = token.jwt;
  axios.defaults.headers.common["Authorization"] = `Bearer ${jwt}`;

  // const user = JSON.parse(localStorage.getItem("user"));
  // const activeStatus = Number(user?.user?.user_member?.status) === 2;
  const activeStatus = true;

  const setBasketItems = useSetRecoilState(basketItemsState);
  const [basketTotalItems, setBasketTotalItems] = useRecoilState(basketTotalItemsState);

  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);

  const [isBasketEmpty, setIsBasketEmpty] = useState(false);

  const basketItems = useRecoilValue(basketItemsState);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const getBasketItems = useCallback(() => {
    axios.get("basket", {
      params: {
        detailed: 1,
        limit: 5,
        page: 1,
      },
    }).then((response) => {
      setBasketItems(response.data.data);
      setBasketTotalItems(response.data.totalItems);
    });
  }, [setBasketItems, setBasketTotalItems]);

  useEffect(() => {
    if (basketItems?.length === 0) {
      setIsBasketEmpty(true);
    } else {
      setIsBasketEmpty(false);
    }
  }, [basketItems, setIsBasketEmpty]);

  useEffect(() => {
    getBasketItems();
  }, [getBasketItems]);

  return (
    <Tooltip title={"Sepetim"} placement="bottom">
      <div className="payment-basket">
        <BasketPayment />
        <Button
          id="basic-button"
          aria-controls={open ? "basic-menu" : undefined}
          aria-haspopup="true"
          aria-expanded={open ? "true" : undefined}
          onClick={handleClick}
          sx={{
            textTransform: "none",
            minWidth: "40px",
            padding: 0,
            ":hover": { backgroundColor: "rgba(0, 0, 0, 0.04)" },
            ":disabled": { opacity: 0.3 },
          }}
          disabled={!activeStatus}
        >
          <Badge badgeContent={basketTotalItems} overlap="circular" color="primary">
            <ShoppingCartOutlinedIcon
              sx={{
                width: "21px",
                height: "21px",
                color: "var(--priceText)",
              }}
            />
          </Badge>
        </Button>
        <Menu
          id="basic-menu"
          anchorEl={anchorEl}
          open={open}
          onClose={handleClose}
          MenuListProps={{
            "aria-labelledby": "basic-button",
            disablePadding: true,
          }}
        >
          {isBasketEmpty ? (
            <Box>
              <Box
                sx={{
                  padding: "20px",
                  textAlign: "center",
                  color: "var(--priceText)",
                  fontWeight: 700,
                }}
              >
                Sepetiniz Boş
              </Box>
              <Button
                fullWidth
                sx={{
                  textTransform: "none",
                  borderTopLeftRadius: 0,
                  borderTopRightRadius: 0,
                  backgroundColor: "var(--info)",
                  color: "var(--lighterBg)",
                  transition: "all 150ms linear",
                  ":hover": {
                    backgroundColor: "var(--blueBrand)",
                  },
                }}
                onClick={handleClose}
              >
                Sepeti Kapat
              </Button>
            </Box>
          ) : (
            <Box>
              <Box>
                {basketItems?.map((basket, key) => (
                  <BasketItems key={key} basket={basket} />
                ))}
              </Box>
              <Box
                sx={{
                  padding: "10px 20px",
                  display: "flex",
                  justifyContent: "flex-end",
                  gap: "var(--gap)",
                }}
              >
                <Button
                  sx={{
                    textTransform: "none",
                    backgroundColor: "var(--phoneDigitBg)",
                    color: "var(--priceText)",
                    transition: "all 150ms linear",
                    ":hover": {
                      backgroundColor: "var(--priceText)",
                      color: "var(--lighterBg)",
                    },
                  }}
                  onClick={handleClose}
                >
                  Vazgeç
                </Button>
                <NavLink className="to-basket-link" to={"/basket"}>
                  Sepetime Git
                </NavLink>
              </Box>
            </Box>
          )}
        </Menu>
      </div>
    </Tooltip>
  );
};

export default Basket;
