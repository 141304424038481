import { TextField } from "@mui/material";
import { useRecoilState } from "recoil";
import { tckState } from "../../../../atoms/membership";

const ProfileIdendityNumber = ({ disabled }) => {
  const [tck, setTck] = useRecoilState(tckState);

  const handleChange = (e) => {
    const value = e.target.value;
    setTck(value);
  };

  return (
    <TextField
      required
      disabled={disabled()}
      label="TC"
      size="small"
      value={tck}
      onChange={handleChange}
    />
  );
};

export default ProfileIdendityNumber;
