import "../../../style/createShipment/ShipmentDimensionModal.css";
import { useCookies } from "react-cookie";
import axios from "../../../api/axios";
import { useCallback, useEffect, useState } from "react";
import { toast } from "react-toastify";
import {
  Button,
  CircularProgress,
  Modal,
  TextField,
  ThemeProvider,
  createTheme,
} from "@mui/material";
import { useRecoilState } from "recoil";
import { dimensionListArrayState } from "../../../atoms/profileSettings";
import { dimensionModalState, registeredDimensionsState } from "../../../atoms/createShipment";
import Errors from "../../../utils/Errors";

const theme = createTheme({
  components: {
    MuiOutlinedInput: {
      styleOverrides: {
        root: {
          borderRadius: "10px",
        },
      },
    },
    MuiPaper: {
      styleOverrides: {
        root: {
          maxHeight: "300px !important",
        },
      },
    },
    MuiInputBase: {
      styleOverrides: {
        input: {
          display: "flex",
          alignItems: "center",
          gap: "var(--gap)",
        },
      },
    },
  },
});

const ShipmentDimensionModal = () => {
  const [token] = useCookies(["jwt"]);
  const jwt = token.jwt;
  axios.defaults.headers.common["Authorization"] = `Bearer ${jwt}`;

  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(false);

  const [en, setEn] = useState("");
  const [boy, setBoy] = useState("");
  const [yuk, setYuk] = useState("");
  const [kg, setKg] = useState("");
  const [name, setName] = useState("");
  const [supId, setSupId] = useState(null);

  const qty = 1;

  const [dimensionListArray, setDimensionListArray] = useRecoilState(dimensionListArrayState);
  const [registeredDimensions, setRegisteredDimensions] = useRecoilState(registeredDimensionsState);
  const [dimensionModal, setDimensionModal] = useRecoilState(dimensionModalState);

  const handleDecimalChange = (e) => {
    let value = e.target.value.replace(/,/g, ".");
    const name = e.target.name;

    if (name === "qty") {
      value = value.replace(/[^0-9]|^0{1,}/g, "");
    } else {
      value = value.replace(/[^0-9.]|(?<=\..*)\.|^0{2,}(?=[.])/g, "");

      if (name === "gross") {
        value = value.replace(/^0{2,}/, "0");
      }

      const regex = /^\d+(\.\d{0,2})?$/;

      if (
        value.startsWith(".") ||
        (!regex.test(value) && value !== "") ||
        (name !== "gross" && value.startsWith("0.") && value.length === 1)
      ) {
        return;
      } else {
        if (name === "gross") {
          if (Number(value) > 300) {
            setKg(300);
          } else {
            setKg(value);
          }
        } else {
          if (name === "en") {
            setEn(value);
          } else if (name === "boy") {
            setBoy(value);
          } else if (name === "yuk") {
            setYuk(value);
          }
        }
      }
    }
  };

  const handleBlur = (e) => {
    if (e.target.name === "gross" && e.target.value === "0") {
      setKg("");
    }
  };

  const addDimensionHandler = async (e) => {
    e.preventDefault();
    setLoading(true);

    const data =
      supId === null || supId === ""
        ? { length: en, width: boy, height: yuk, gross: kg, qty, name }
        : {
            length: en,
            width: boy,
            height: yuk,
            gross: kg,
            qty,
            name,
            supplier_id: supId,
          };

    await axios
      .post("member-dimension/add", data)
      .then((response) => {
        setLoading(false);
        toast.success(response.data.message, { autoClose: 2000 });
        setDimensionListArray([response.data.data, ...dimensionListArray]);
        setRegisteredDimensions([response.data.data, ...registeredDimensions]);
        handleClose();
      })
      .catch((error) => Errors(error))
      .finally(() => setLoading(false));
  };

  const isDisabled = useCallback(() => {
    if (en === "" || boy === "" || yuk === "" || kg === "" || qty === "") {
      return true;
    } else return false;
  }, [en, boy, yuk, kg, qty]);

  const handleClose = useCallback(() => {
    setDimensionModal((prevState) => ({ ...prevState, status: false }));
    setEn("");
    setBoy("");
    setYuk("");
    setKg("");
    setName("");
    setSupId("");
  }, [setDimensionModal]);

  useEffect(() => {
    if (dimensionModal.status) {
      if (en !== "" && boy !== "" && yuk !== "" && Number(en) * Number(boy) * Number(yuk) < 6) {
        setError(true);
      } else {
        setError(false);
      }
    }
  }, [en, boy, yuk, dimensionModal.status]);

  return (
    <ThemeProvider theme={theme}>
      <Modal
        className="shipment-dimension-modal"
        open={dimensionModal.status}
        onClose={handleClose}
      >
        <form
          className="shipment-dimension-modal-form"
          onSubmit={addDimensionHandler}
          style={{
            maxHeight: "90vh",
            overflowY: "auto",
          }}
        >
          <div className="shipment-dimension-modal-form-headline">
            <TextField
              size="small"
              label="Başlık"
              required
              fullWidth
              value={name}
              onChange={(e) => setName(e.target.value)}
              autoComplete="off"
            />
          </div>
          <div className="shipment-dimension-modal-form-inputs">
            <TextField
              label="En (cm)"
              size="small"
              name="en"
              value={en}
              error={error}
              helperText={error && "En, Boy ve Yükseklik çarpımı en az 6 cm olmak zorundadır."}
              autoComplete="off"
              onChange={handleDecimalChange}
            />
            <TextField
              label="Boy (cm)"
              size="small"
              name="boy"
              value={boy}
              error={error}
              helperText={error && "En, Boy ve Yükseklik çarpımı en az 6 cm olmak zorundadır."}
              autoComplete="off"
              onChange={handleDecimalChange}
            />
            <TextField
              label="Yükseklik (cm)"
              size="small"
              name="yuk"
              value={yuk}
              error={error}
              helperText={error && "En, Boy ve Yükseklik çarpımı en az 6 cm olmak zorundadır."}
              autoComplete="off"
              onChange={handleDecimalChange}
            />
            <TextField
              label="Kg"
              size="small"
              name="gross"
              value={kg}
              autoComplete="off"
              onChange={handleDecimalChange}
              onBlur={handleBlur}
            />
          </div>
          <div className="shipment-dimension-modal-form-buttons" style={{ gap: "var(--gap)" }}>
            <Button onClick={handleClose} type="button" className="iyzi-button">
              Vazgeç
            </Button>
            {loading ? (
              <div
                style={{
                  width: "64px",
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                <CircularProgress sx={{ width: "21px !important", height: "21px !important" }} />
              </div>
            ) : (
              <Button
                disabled={isDisabled()}
                className="iyzi-button"
                type="submit"
                sx={{
                  ":disabled": {
                    opacity: 0.4,
                  },
                }}
              >
                Kaydet
              </Button>
            )}
          </div>
        </form>
      </Modal>
    </ThemeProvider>
  );
};

export default ShipmentDimensionModal;
