import "../../style/myWallet/MyWallet.css";
import { Box, Pagination, ThemeProvider, createTheme } from "@mui/material";
import { useInView, animated } from "@react-spring/web";
import { useCallback } from "react";
import { useRecoilState } from "recoil";
import NotificationBar from "../../components/sidebar/NotificationBar";
import { DynamicTitle } from "../../utils/DynamicTitle";
import { ToastContainer } from "react-toastify";
import axios from "../../api/axios";
import { useCookies } from "react-cookie";
import MyWalletNav from "../../components/myWallet/MyWalletNav";
import MyWalletArrangementTable from "./myWalletArrangement/MyWalletArrangementTable";
import MyWalletArrangementDocModal from "./myWalletArrangement/MyWalletArrangementDocModal";
import MyWalletArrangementCompanyBillModal from "./myWalletArrangement/MyWalletArrangementCompanyBillModal";
import {
  walletArrangementCurrentPageState,
  walletArrangementDataState,
  walletArrangementTotalPageState,
} from "../../atoms/myWallet";
import WrapperStyle from "../../utils/WrapperStyle";
import Errors from "../../utils/Errors";
import ArrangementTitle from "./title/ArrangementTitle";

const theme = createTheme({
  components: {
    MuiOutlinedInput: {
      styleOverrides: {
        input: {
          borderRadius: "8px",
        },
      },
    },
  },
});

const MyWalletArrangement = () => {
  DynamicTitle("Tazmin İşlemleri");

  const [token] = useCookies(["jwt"]);
  const jwt = token.jwt;
  axios.defaults.headers.common["Authorization"] = `Bearer ${jwt}`;

  const [, setWalletArrangementData] = useRecoilState(walletArrangementDataState);
  const [walletArrangementCurrentPage, setWalletArrangementCurrentPage] = useRecoilState(
    walletArrangementCurrentPageState
  );
  const [walletArrangementTotalPage, setWalletArrangementTotalPage] = useRecoilState(
    walletArrangementTotalPageState
  );

  const handlePageChange = useCallback(
    (e, page) => {
      axios
        .get(`refund-compensation`, {
          params: {
            page: page,
            limit: 100,
            order_direction: "DESC",
          },
        })
        .then((resp) => {
          setWalletArrangementData(resp.data.data);
          setWalletArrangementCurrentPage(resp.data.currentPage);
          setWalletArrangementTotalPage(resp.data.totalPages);
        })
        .catch((error) => Errors(error));
    },
    [setWalletArrangementData, setWalletArrangementCurrentPage, setWalletArrangementTotalPage]
  );

  const [ref, springs] = useInView(
    () => ({
      from: {
        opacity: 0,
        y: 20,
      },
      to: {
        opacity: 1,
        y: 0,
      },
    }),
    {
      rootMargin: "-10% 0%",
    }
  );

  return (
    <ThemeProvider theme={theme}>
      <section className="my-wallet">
        <NotificationBar />
        <ToastContainer />
        <MyWalletArrangementDocModal />
        <MyWalletArrangementCompanyBillModal />
        <ArrangementTitle />
        <Box className="my-wallet-wrapper" style={WrapperStyle()}>
          <MyWalletNav />
          <animated.div ref={ref} style={springs}>
            <Box className="my-wallet-container">
              <div className="my-wallet-arrangement">
                {walletArrangementTotalPage > 1 && (
                  <Pagination
                    showFirstButton
                    showLastButton
                    size="small"
                    count={walletArrangementTotalPage}
                    page={walletArrangementCurrentPage}
                    onChange={handlePageChange}
                  />
                )}
                <MyWalletArrangementTable />
                {walletArrangementTotalPage > 1 && (
                  <Pagination
                    showFirstButton
                    showLastButton
                    size="small"
                    count={walletArrangementTotalPage}
                    page={walletArrangementCurrentPage}
                    onChange={handlePageChange}
                  />
                )}
              </div>
            </Box>
          </animated.div>
        </Box>
      </section>
    </ThemeProvider>
  );
};

export default MyWalletArrangement;
