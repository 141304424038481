import { atom } from "recoil";

export const activeStepState = atom({
  key: "activeStep",
  default: 0,
});

export const shipmentDocumentState = atom({
  key: "shipmentDocument",
  default: "",
});

export const getShipmentDimensionFastState = atom({
  key: "getShipmentDimensionFast",
  default: "",
});

export const arrivalCountryState = atom({
  key: "arrivalCountry",
  default: "",
});

export const arrivalCountryCodeState = atom({
  key: "arrivalCountryCode",
  default: "",
});

export const productValueState = atom({
  key: "productValue",
  default: "",
});

export const productCurrencyState = atom({
  key: "productCurrency",
  default: "USD",
});

export const shipmentReceiverStateState = atom({
  key: "shipmentReceiverState",
  default: "",
});

export const shipmentReceiverDistrictState = atom({
  key: "shipmentReceiverDistrict",
  default: "",
});

export const shipmentReceiverCityState = atom({
  key: "shipmentReceiverCity",
  default: "",
});

export const shipmentReceiverStateCodeState = atom({
  key: "shipmentReceiverStateCode",
  default: "",
});

export const shipmentSenderStateState = atom({
  key: "shipmentSenderState",
  default: "",
});

export const shipmentSenderDistrictState = atom({
  key: "shipmentSenderDistrict",
  default: "",
});

export const shipmentSenderCityState = atom({
  key: "shipmentSenderCity",
  default: "",
});

export const shipmentSenderStateCodeState = atom({
  key: "shipmentSenderStateCode",
  default: "",
});

export const documentMaxGrossState = atom({
  key: "documentMaxGross",
  default: "",
});

export const totalCwState = atom({
  key: "totalCw",
  default: 0,
});

export const totalGwState = atom({
  key: "totalGw",
  default: 0,
});

export const totalVwState = atom({
  key: "totalVw",
  default: 0,
});

export const totalGrossState = atom({
  key: "totalGross",
  default: 0,
});

export const supplierIdState = atom({
  key: "supplierId",
  default: 0,
});

export const shipmentPriceArrayState = atom({
  key: "shipmentPriceArray",
  default: [],
});

export const exchangeRateState = atom({
  key: "exchangeRate",
  default: [],
});

export const dimensionArrayState = atom({
  key: "dimensionArray",
  default: [],
});

export const registeredDimensionsState = atom({
  key: "registeredDimensions",
  default: [],
});

export const contractIdsState = atom({
  key: "contractIds",
  default: "",
});

export const confirmationCodeState = atom({
  key: "confirmationCode",
  default: "",
});

export const priceIdState = atom({
  key: "priceId",
  default: "",
});

export const startShipmentSenderAnimationState = atom({
  key: "startShipmentSenderAnimation",
  default: false,
});

export const startShipmentReceiverAnimationState = atom({
  key: "startShipmentReceiverAnimation",
  default: false,
});

export const shipmentHideState = atom({
  key: "shipmentHide",
  default: false,
});

export const senderAddressesState = atom({
  key: "senderAddresses",
  default: [],
});

export const receiverAddressesState = atom({
  key: "receiverAddresses",
  default: [],
});

export const receiverAddressTotalPagesState = atom({
  key: "receiverAddressTotalPages",
  default: 1,
});

export const receiverAddressCurrentPageState = atom({
  key: "receiverAddressCurrentPage",
  default: 1,
});

export const selectedSenderAddressIdState = atom({
  key: "selectedSenderAddressId",
  default: null,
});

export const selectedReceiverAddressIdState = atom({
  key: "selectedReceiverAddressId",
  default: null,
});

export const selectedSenderAddressState = atom({
  key: "selectedSenderAddress",
  default: [],
});

export const selectedReceiverAddressState = atom({
  key: "selectedReceiverAddress",
  default: [],
});

export const selectedPriceListState = atom({
  key: "selectedPriceList",
  default: [],
});

export const madeInState = atom({
  key: "madeIn",
  default: "",
});

export const iossNumberState = atom({
  key: "iossNumber",
  default: "",
});

export const productDescriptionState = atom({
  key: "productDescription",
  default: "",
});

export const shipmentDescriptionState = atom({
  key: "shipmentDescription",
  default: "",
});

export const setOpenWarningModalState = atom({
  key: "setOpenWarningModal",
  default: false,
});

export const hsCodeState = atom({
  key: "hsCode",
  default: "",
});

export const skuState = atom({
  key: "sku",
  default: "",
});

export const totalQtyState = atom({
  key: "totalQty",
  default: "",
});

export const costPerProductState = atom({
  key: "costPerProduct",
  default: "",
});

export const insuranceState = atom({
  key: "insurance",
  default: 0,
});

export const ddpState = atom({
  key: "ddp",
  default: 0,
});

export const lucidState = atom({
  key: "lucid",
  default: 0,
});

export const insuranceSlideWarningState = atom({
  key: "insuranceSlideWarning",
  default: { status: false, data: {} },
});

export const checkedInsurancesState = atom({
  key: "checkedInsurances",
  default: {},
});

export const checkedCustomsState = atom({
  key: "checkedCustoms",
  default: [],
});

export const checkedLucidsState = atom({
  key: "checkedLucids",
  default: {},
});

export const isEuropeanCountryState = atom({
  key: "isEuropeanCountry",
  default: false,
});

export const isAbove150EURCheckState = atom({
  key: "isAbove150EURCheck",
  default: false,
});

export const shipmentPriceArrayLengthState = atom({
  key: "shipmentPriceArrayLength",
  default: [],
});

export const isShipmentPriceCalculationState = atom({
  key: "isShipmentPriceCalculation",
  default: false,
});

export const dimensionTotalQtyState = atom({
  key: "dimensionTotalQty",
  default: 0,
});

export const dimensionModalState = atom({
  key: "dimensionModal",
  default: { status: false, data: [] },
});

export const proformaModalState = atom({
  key: "proformaModal",
  default: false,
});

export const overPayOpenState = atom({
  key: "overPayOpen",
  default: false,
});

export const overPayState = atom({
  key: "overPay",
  default: false,
});

export const overPayDesignState = atom({
  key: "overPayDesign",
  default: false,
});

export const shipmentZipCodeState = atom({
  key: "shipmentZipCode",
  default: "",
});

export const docOrPackModalState = atom({
  key: "docOrPackModal",
  default: false,
});

export const senderCreateCountryState = atom({
  key: "senderCreateCountry",
  default: "Türkiye",
});

export const senderCreateCountryCodeState = atom({
  key: "senderCreateCountryCode",
  default: "TR",
});

export const senderZipCodeState = atom({
  key: "senderZipCode",
  default: "34510",
});
