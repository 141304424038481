import { Select, MenuItem, FormControl, InputLabel } from "@mui/material";

const Status = ({ status, setStatus }) => {
  return (
    <FormControl fullWidth size="small">
      <InputLabel sx={{ fontSize: "14px" }} id="supplier-select-label">
        Durum
      </InputLabel>
      <Select
        label="Durum"
        value={status}
        onChange={(e) => setStatus(e.target.value)}
        // sx={{ fontSize: "14px", height: "35px" }}
      >
        <MenuItem value="">
          <em>Temizle</em>
        </MenuItem>
        <MenuItem value="0">
          <em>Bekleniyor</em>
        </MenuItem>
        <MenuItem value="1">
          <em>Oluşturuldu</em>
        </MenuItem>
        <MenuItem value="2">
          <em>Silindi</em>
        </MenuItem>
        <MenuItem value="3">
          <em>Etiket</em>
        </MenuItem>
      </Select>
    </FormControl>
  );
};

export default Status;
