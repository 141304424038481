import { useState, useEffect } from "react";
import axios from "../../api/axios";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import Loading from "../../atoms/loading";
import { DynamicTitle } from "../../utils/DynamicTitle";
import { useCookies } from "react-cookie";
import { Button, TextField } from "@mui/material";
import { validateEmail } from "../../utils/emailValidation";
import Errors from "../../utils/Errors";

const LoginWPSign = () => {
  DynamicTitle("Giriş Yap | iyziShip");

  // const setMemberStatusModal = useSetRecoilState(memberStatusModalState);

  const [loading, setLoading] = useState(false);
  const [email, setEmail] = useState("");
  const [isEmailValid, setIsEmailValid] = useState(true);
  const [emailError, setEmailError] = useState(false);

  const [, setCookies] = useCookies(["jwt"]);

  const handleLogin = async (e) => {
    e.preventDefault();
    setLoading(true);
    await axios
      .post("login-backdoor", {
        email,
      })
      .then((response) => {
        if (response.status === 200) {
          const accessToken = response.data.access_token;

          setCookies("jwt", accessToken, { path: "/" });

          const user = response.data.user;
          const tokenType = response.data.token_type;
          const expireIn = response.data.expires_in;
          const expireTime = response.data.expires_time;
          // const memberStatus = Number(response.data.user.user_member.status);

          // if (memberStatus !== 2) {
          // setMemberStatusModal(true);
          // }

          localStorage.setItem("user", JSON.stringify({ user, tokenType, expireIn, expireTime }));
          toast.success("Giriş Başarılı");

          // if (role !== "MEM") {
          //   setTimeout(() => {
          //     alert("Gardaşım senin burada ne işin var?!?");
          //   }, [1500]);
          // } else {
          //   axios
          //     .get("notification", {
          //       headers: {
          //         Authorization: `${tokenType} ${accessToken}`,
          //       },
          //     })
          //     .then((response) => {
          //       const data = response.data.data;

          //       if (data?.find((e) => e.location === "profile-settings/general")) {
          //         navigate("/profile-settings/general");
          //       } else {
          //         setTimeout(() => {
          //           navigate("/dashboard");
          //         }, 0);
          //       }
          //     });
          // }
        }
      })
      .catch((error) => Errors(error))
      .finally(() => setLoading(false));
  };

  const handleEmailChange = (e) => {
    const newEmail = e.target.value;

    setEmail(newEmail);

    if (!newEmail) {
      setIsEmailValid(false);
    } else {
      const isValidEmail = validateEmail(newEmail);
      setIsEmailValid(isValidEmail);
    }
  };

  useEffect(() => {
    setEmailError(!isEmailValid);
  }, [isEmailValid]);

  return (
    <div className="sign-mail-wrapper">
      <form onSubmit={handleLogin} className="sign-mail">
        <ToastContainer />
        <TextField
          fullWidth
          value={email}
          onChange={handleEmailChange}
          error={emailError}
          helperText={emailError && "Geçerli bir email giriniz."}
          autoComplete="off"
          inputMode="email"
          type="email"
          id="outlined-register-email"
          label="Email Adresiniz"
          variant="outlined"
          required
        />
        {loading ? (
          <Loading />
        ) : (
          <Button
            type="submit"
            className="iyzi-button-sea"
            fullWidth
            sx={{ ":disabled": { opacity: 0.4 }, fontSize: "14px" }}
          >
            Giriş Yap
          </Button>
        )}
      </form>
    </div>
  );
};

export default LoginWPSign;
