// import Iframe from "react-iframe";
import { NavLink } from "react-router-dom";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Typography,
} from "@mui/material";

const FrequentAccordions = ({ accordionRefs }) => {
  return (
    <div className="sss-acciordions">
      <Accordion>
        <AccordionSummary expandIcon={<ExpandMoreIcon />}>
          <Typography ref={(el) => (accordionRefs.current[0] = el)}>
            Nasıl bireysel üye olabilirim?
          </Typography>
        </AccordionSummary>
        <AccordionDetails>
          <div className="frame-wrapper">
            <p>
              Eğer bir şirket sahibi değilseniz, kimlik bilgileriniz ile
              bireysel üyelik oluşturabilirsiniz.
            </p>
          </div>
        </AccordionDetails>
      </Accordion>
      <Accordion>
        <AccordionSummary expandIcon={<ExpandMoreIcon />}>
          <Typography ref={(el) => (accordionRefs.current[1] = el)}>
            Kurumsal üyelik nasıl yapılır?
          </Typography>
        </AccordionSummary>
        <AccordionDetails>
          <div className="frame-wrapper">
            <p>
              Şahıs, limited veya anonim şirket türlerinden birine sahipseniz;
              şirketinize ait bilgilerle kurumsal üyelik oluşturarak, iyziShip
              tarafından kesilecek tüm faturaları şirketinizde gider olarak
              gösterebilirsiniz.
            </p>
          </div>
        </AccordionDetails>
      </Accordion>
      <Accordion>
        <AccordionSummary expandIcon={<ExpandMoreIcon />}>
          <Typography ref={(el) => (accordionRefs.current[2] = el)}>
            Üyelik aktivasyon işlemi nasıl gerçekleşiyor?
          </Typography>
        </AccordionSummary>
        <AccordionDetails>
          <div className="frame-wrapper">
            <p>
              Bireysel veya kurumsal üyelik seçeneklerinden size uygun olanı
              seçip, işlemlerinizi tamamladıktan sonra, e-posta kutunuza
              gönderilecek aktivasyon linkiyle hesabınızı aktif hale
              getirebilirsiniz. Ardından ihtiyacınıza uygun çözümlerimiz ve en
              avantajlı fiyatlarla kargolarınızı tüm dünyaya kolayca
              gönderebilirsiniz.
            </p>
          </div>
        </AccordionDetails>
      </Accordion>
      <Accordion>
        <AccordionSummary expandIcon={<ExpandMoreIcon />}>
          <Typography ref={(el) => (accordionRefs.current[3] = el)}>
            Fiyat listesi veya fiyat nasıl alınır?
          </Typography>
        </AccordionSummary>
        <AccordionDetails>
          <div className="frame-wrapper">
            <p>
              iyziShip platformundaki entegrasyonlar ile, tüm sağlayıcılar için
              güncel fiyatlara ulaşabilirsiniz. Maliyet hesabı yaparken herhangi
              bir hataya sebep olmamak adına, matbu fiyat listesi vermemekteyiz.
              Global pazarda değişen enerji fiyatları, döviz kurlarındaki
              değişimler vb. dış etkenler sebebi ile verilen matbu fiyatlar
              güncelliğini zaman içinde yitirebilmektedir. Bu nedenle, sayfamıza
              girdiğiniz anki güncel fiyatları görebilir ve gönderi
              yapabilirsiniz. Daha detaylı bilgi için dilediğiniz zaman uzman
              ekibimize ulaşarak, aklınızdaki tüm sorulara cevap bulabilirsiniz.
            </p>
          </div>
        </AccordionDetails>
      </Accordion>
      <Accordion>
        <AccordionSummary expandIcon={<ExpandMoreIcon />}>
          <Typography ref={(el) => (accordionRefs.current[4] = el)}>
            Hangi global pazaryeri veya e-ticaret altyapıları için
            entegrasyonlarınız var?
          </Typography>
        </AccordionSummary>
        <AccordionDetails>
          <div className="frame-wrapper">
            <p>
              iyziShip’te bulunan entegrasyonlarımızın yanında, yeni
              entegrasyonlar üzerinde de geliştirme çalışmalarımız daima aktif
              şekilde devam ediyor. Ana menüde yer alan
              <NavLink to={"/entegrasyonlar"} target="_blank">
                Entegrasyonlar
              </NavLink>
              sayfasından, güncel tüm entegrasyonları detaylı şekilde
              inceleyebilirsiniz.
            </p>
          </div>
        </AccordionDetails>
      </Accordion>
      <Accordion>
        <AccordionSummary expandIcon={<ExpandMoreIcon />}>
          <Typography ref={(el) => (accordionRefs.current[5] = el)}>
            Gönderimin ödemesini yaptım takip numarasını nasıl alabilirim?
          </Typography>
        </AccordionSummary>
        <AccordionDetails>
          <div className="frame-wrapper">
            <p>
              Navlun ücretinizi iyziBakiyem hesabınızdan ödemeniz halinde;
              express kurye taşımacılığı yapan firmalardan, takip numaranızı
              istediğiniz her an panelden kendi kendinize oluşturabilirsiniz.{" "}
              <br /> <br />
              Ancak ödemeyi iyziBakiyem ile yapmadıysanız veya takip numarasını
              online olarak vermeyen bir taşıma firması seçtiyseniz, gönderiniz
              iyziMerkez’e ulaştığında takip numaranız sisteme ekibimiz
              tarafından işlenecektir.
              <br />
              <br />
              Economy gönderileriniz için oluşturulacak takip numaranıza,
              gönderinizin iyziMerkez’e ulaşmasına müteakip 24 saat içinde
              iyziShip panelinizden ulaşabilirsiniz.
              <br />
              <br />
            </p>
          </div>
        </AccordionDetails>
      </Accordion>
      <Accordion>
        <AccordionSummary expandIcon={<ExpandMoreIcon />}>
          <Typography ref={(el) => (accordionRefs.current[6] = el)}>
            Yurt dışı gönderilerinde gönderici ve alıcı sorumlulukları nelerdir?
          </Typography>
        </AccordionSummary>
        <AccordionDetails>
          <div className="frame-wrapper">
            <p>Göndericinin Sorumlulukları;</p>
            <br />
            <br />
            <p>
              - Gönderilecek yükle alakalı istenen özellik ve değerleri,
              eksiksiz ve doğru beyan etmek
            </p>
            <p>Alıcıya ait adresi ve telefon numarasını doğru vermek,</p>
            <p>
              Yükü hasar almayacak şekilde dikkatli ve özenli bir şekilde
              paketlemek,
            </p>
            <p>Taşınması yasak veya sakıncalı ürünler göndermemek,</p>
            <p>
              Gönderi teslim edilene kadar, takip numarası ile gönderiyi takip
              etmek ve herhangi bir değişiklik ya da olumsuzlukta taşıyıca bilgi
              vermek.
            </p>
            <br />
            <br />
            <p>Alıcının Sorumlulukları;</p>
            <br />
            <br />
            <p>
              Kendisine ulaşacak gönderiyi takip ederek, dağıtım günü teslimat
              adresinde bulunmak,
            </p>
            <p>Gönderiyi kontrol ederek teslim almak,</p>
            <p>
              Teslimat esnasında belirtilen teslim adresinde bulunmayacak ise,
              bu bilgiyi taşıyıcı firma ile paylaşmak,
            </p>
            <p>
              Bulunduğu ülke gümrükleri tarafından istenen belge ve bilgileri
              eksiksiz sağlamak,
            </p>
            <p>
              Varış gümrüğünde oluşabilecek vergi ve sorumlulukları yerine
              getirmek.
            </p>
          </div>
        </AccordionDetails>
      </Accordion>
      <Accordion>
        <AccordionSummary expandIcon={<ExpandMoreIcon />}>
          <Typography ref={(el) => (accordionRefs.current[7] = el)}>
            Kargomu iyziMerkez’e nasıl ulaştıracağım?
          </Typography>
        </AccordionSummary>
        <AccordionDetails>
          <div className="frame-wrapper">
            <p>
              Ödemenizi yapıp gönderinizi oluşturduktan sonra, kargonuzu
              iyziMerkez’e gönderebilmeniz için 4 yöntem mevcuttur;
            </p>
            <p>
              <strong>Kendim Getireceğim:</strong> Gönderinizi ekonomik ve
              pratik bir şekilde iyziMerkez’e ulaştırmak istiyorsanız,
              "iyziMerkez’e Kendim Getireceğim" seçeneği tam size göre. Bu
              seçenekle, taşıma maliyetinden tasarruf ederek cüzdanınızı
              rahatlatırsınız. Gönderinizi bizzat getirerek hem sürece dahil
              olursunuz, hem de taşıma ücreti olarak ödeyeceğiniz tutar, ödül
              puan olarak bakiyenize iade edilir. Hızlı ve hesaplı bir başlangıç
              için ideal!
              <br />
              <br />
              <strong>iyziKurye Toplasın:</strong> İstanbul'da konforunuzdan
              ödün vermeden gönderi yapmak istiyorsanız, "iyziKurye Toplasın"
              seçeneğini tercih edebilirsiniz. iyziKurye hizmet alanı içinde
              bulunan bölgelerde, ekibimiz adresinize gelerek gönderilerinizi
              bizzat toplar. Bu hizmet sayesinde, gönderinizi bizim için hazır
              hale getirmeniz yeterli; gerisini biz hallediyoruz. Hızlı,
              güvenilir ve kapınıza kadar gelen iyziKuryeler ile, İstanbul
              içindeki gönderileriniz artık çok daha konforlu ve stressiz.
              <br />
              <br />
              <strong>Kendim Göndereceğim:</strong> Gönderinizin nasıl ve hangi
              firma ile yola çıkacağına kendiniz karar vermek istiyorsanız,
              "Kendim Göndereceğim" seçeneği sizin için biçilmiş kaftan. Bu
              yöntem ile, gönderinizi tercih ettiğiniz bir taşıyıcı firma
              üzerinden bize iletme özgürlüğüne sahipsiniz. Üstelik, taşıma
              ücreti olarak ödeyeceğiniz tutarı, ödül puan olarak bakiyenize
              iade alırsınız. Kendi tercihlerinizle şekillenen bir gönderi
              deneyimi için mükemmel bir seçenek!
              <br />
              <br />
              <strong>iyziShip Anlaşmalı Firmalar ile Gönder:</strong>{" "}
              Gönderinizin iyziMerkez’e ulaşması için ekstra bir çaba sarf
              etmenize gerek yok. Anlaşmalı olduğumuz taşıyıcı firmalar ile,
              gönderilerinizi güven içinde ve hiçbir ek ücret ödemeden bize
              iletebilirsiniz. Rahatlık ve kolaylık arayanlar için birebir!
            </p>
          </div>
        </AccordionDetails>
      </Accordion>
      <Accordion>
        <AccordionSummary expandIcon={<ExpandMoreIcon />}>
          <Typography ref={(el) => (accordionRefs.current[8] = el)}>
            GTIP kodu nedir? GTIP kodunu nereden bulabilirim?
          </Typography>
        </AccordionSummary>
        <AccordionDetails>
          <div className="frame-wrapper">
            <p>
              GTİP, Gümrük Tarife İstatistik Pozisyonu’nun kısaltmasıdır. İhraç
              edilecek ürünlerin kontrollerinin sağlanması, ödenecek gümrük ve
              nakliye vergilerinin tespit edilmesi, menşe kurallarının
              belirlenmesi gibi kritik konularda kullanılan, ürünle alakalı
              detaylı bilgiler içeren sistematik bir numaralandırma sistemidir.
              İngilizce karşılığı ise; HS Code, yani Harmonized Commodity
              Description and Coding System olarak bilinmektedir.
              <br />
              <br />
              12 haneli kodun ilk 6 hanesi Dünya Gümrük Örgütü’ne üye ülkelerde
              standarttır ve ilgili numara, her üye ülkede aynı özellikli
              ürünleri temsil etmektedir. Bu 6 hanenin ilk 4 rakamı, eşyanın
              pozisyon numarasını verirken; 7 ve 8. haneler ise AB üyesi ülkeler
              tarafından kullanılan kombine nomanklatür kodudur. 9 ve 10.
              haneler ülkelerdeki farklı vergi uygulamaları sebebiyle açılan
              pozisyonları gösterir, 11 ve 12. haneler ise Gümrük Tarife
              İstatistik kodlarını temsil etmektedir.
              <br />
              <br />
              GTİP sorgulamasını pek çok resmi internet sitesinde bulabilir ve
              sorgulayabilirsiniz.
              <br />
              <br />
              <NavLink to={"https://www.gumruk.com.tr/gtip/"} target="_blank">
                https://www.gumruk.com.tr/gtip
              </NavLink>
              <NavLink
                to={
                  "https://www.mevzuat.net/fayda/gtip-nedir-nasil-tespit-edilir.aspx"
                }
                target="_blank"
              >
                https://www.mevzuat.net/fayda/gtip-nedir-nasil-tespit-edilir.aspx
              </NavLink>
              <NavLink
                to={"https://www.oaib.org.tr/tr/gtip-arama.html"}
                target="_blank"
              >
                https://www.oaib.org.tr/tr/gtip-arama.html
              </NavLink>
              <br />
              <br />
              Ayrıca GTİP kodu ile alakalı detaylı bilgiler bulabileceğiniz blog
              yazımıza{" "}
              <NavLink
                to={
                  "https://blog.iyziship.com/gtip-nedir-gtip-sorgulama-nasil-yapilir"
                }
                target="_blank"
              >
                https://blog.iyziship.com/gtip-nedir-gtip-sorgulama-nasil-yapilir
              </NavLink>
              göz atmayı unutmayın.
            </p>
          </div>
        </AccordionDetails>
      </Accordion>
      <Accordion>
        <AccordionSummary expandIcon={<ExpandMoreIcon />}>
          <Typography ref={(el) => (accordionRefs.current[9] = el)}>
            Proforma Fatura (Proforma Invoice) nedir?
          </Typography>
        </AccordionSummary>
        <AccordionDetails>
          <div className="frame-wrapper">
            <p>
              Proforma fatura; sadece ticari olmayan ürünlerin içeriğini,
              kıymetini, miktarını, göndereci, alıcı ve taşıyıcı bilgilerini
              içeren bir beyan veya ön faturadır. Resmi bir satış faturası
              yerine geçmez ve genellikle numune gönderimlerinde kullanılır.
              Gönderici tarafından Word, Excel veya PDF formatlarında
              hazırlanır.
            </p>
          </div>
        </AccordionDetails>
      </Accordion>
      <Accordion>
        <AccordionSummary expandIcon={<ExpandMoreIcon />}>
          <Typography ref={(el) => (accordionRefs.current[10] = el)}>
            Ticari Fatura (Commercial Invoice veya Invoice) nedir?
          </Typography>
        </AccordionSummary>
        <AccordionDetails>
          <div className="frame-wrapper">
            <p>
              Satışı gerçekleşmiş ürün veya ürünler için hazırlanan resmi
              faturadır. İhracatçı ve ithalatçı bilgilerinin yer aldığı fatura,
              içeriğin tanımı, değeri, adeti, GTIP No (HS Kodu), taşıyıcı
              bilgileri ve satış/ödeme şekillerini gösterir. Ticari faturalar,
              uluslararası ticarette önemli bir belgedir ve gümrük işlemleri
              için gereklidir.
            </p>
          </div>
        </AccordionDetails>
      </Accordion>
      <Accordion>
        <AccordionSummary expandIcon={<ExpandMoreIcon />}>
          <Typography ref={(el) => (accordionRefs.current[11] = el)}>
            Proforma Fatura ile Ticari Fatura arasındaki fark nedir?
          </Typography>
        </AccordionSummary>
        <AccordionDetails>
          <div className="frame-wrapper">
            <p>
              İki fatura arasındaki en önemli fark; proforma faturanın bir
              numune için hazırlanması ve maddi veya ticari bir değerinin
              olmamasıdır. Ticari fatura ise satışı yapılmış ürün veya ürünler
              için hazırlanır.
            </p>
          </div>
        </AccordionDetails>
      </Accordion>
      <Accordion>
        <AccordionSummary expandIcon={<ExpandMoreIcon />}>
          <Typography ref={(el) => (accordionRefs.current[12] = el)}>
            Hava yolu konşimentosu (AWB) nedir?
          </Typography>
        </AccordionSummary>
        <AccordionDetails>
          <div className="frame-wrapper">
            <p>
              AWB, “Air Waybill”in kısaltması olup, Türkçe “Hava yolu
              konşimentosu” anlamına gelmektedir. Dış ticaret işlemlerinde hava
              yolu ile taşınan kargo hakkında detaylı bilgiler içeren bir taşıma
              belgesidir. Taşıyıcı firma tarafından, beyanınıza göre doldurulan
              içerik; gönderici, alıcı, destinasyon ve paketleme ile ilgili tüm
              detaylı bilgileri içerir. AWB; malların mülkiyetini temsil etmez
              ve ciro ya da teslim ile devredilemeyeceği için emre düzenlenmez.
            </p>
          </div>
        </AccordionDetails>
      </Accordion>
      <Accordion>
        <AccordionSummary expandIcon={<ExpandMoreIcon />}>
          <Typography ref={(el) => (accordionRefs.current[13] = el)}>
            Konşimento numarası (AWB No) ne anlama geliyor?
          </Typography>
        </AccordionSummary>
        <AccordionDetails>
          <div className="frame-wrapper">
            <p>
              Konşimento numarası; konşimento belgesinin üzerinde yazan ve
              taşıyıcı kargo şirketine ait web sitesinden gönderinizi takip
              etmenizi sağlayan kodu ifade etmektedir. Bu numara, gönderinize
              özel sayı veya harflerden oluşan bir dizindir ve “Tracking Number”
              olarakta bilinmektedir.
            </p>
          </div>
        </AccordionDetails>
      </Accordion>
      <Accordion>
        <AccordionSummary expandIcon={<ExpandMoreIcon />}>
          <Typography ref={(el) => (accordionRefs.current[14] = el)}>
            ETGB nedir?
          </Typography>
        </AccordionSummary>
        <AccordionDetails>
          <div className="frame-wrapper">
            <p>
              Alışveriş ve diğer ticari işlemlerin çevrimiçine dönmekte olduğu
              bu günlerde ETGB, uluslararası ticarette ülkeye gelen ve ülkeden
              çıkan tüm ürünlerin gümrük işlemlerinin çevrimiçi
              gerçekleştirilmesidir. Elektronik Ticaret Gümrük Beyannamesi
              anlamına gelir ve taşınan kargonun tüm gümrük işlemlerinin
              elektronik ortamda yapıldığı bir uygulamadır.
              <br />
              <br />
              Mikro ihracatçıların zaman ve kaynak tasarrufu etmesini sağlayan
              ETGB beyanı kargo firmaları tarafından düzenlenmektedir. Yani ETGB
              düzenleme yetkisi sizde değil, kargo firmanızdadır. Evrak ve kağıt
              işlemleri ile zaman kaybetmeden, satış ve kargo sürecinizi hızlıca
              tamamlamanızı sağlar. Ayrıca ETGB sayesinde gönderinizin KDV
              iadesini alabilirsiniz.
            </p>
          </div>
        </AccordionDetails>
      </Accordion>
      <Accordion>
        <AccordionSummary expandIcon={<ExpandMoreIcon />}>
          <Typography ref={(el) => (accordionRefs.current[15] = el)}>
            Mikro İhracat Nedir?
          </Typography>
        </AccordionSummary>
        <AccordionDetails>
          <div className="frame-wrapper">
            <p>
              Mikro ihracatı; 300 kilogram ağırlık veya 15 bin avro değeri
              aşmayan ürünlerin, ETGB (Elektronik Ticaret Gümrük Beyanı) ile
              yurt dışına yapılan satışı olarak özetleyebiliriz. Tek seferde
              göndereceğiniz ürün 300 kilogramdan ağır, ya da 15 bin avrodan
              pahalıysa bu geleneksel ihracata girmektedir.
              <br />
              <br />
              Günümüzde pek çok küçük ve orta ölçekli işletme, çabuk ve kolay
              bir yöntem olan mikro ihracatı tercih etmekte. Mikro ihracat;
              geleneksel ihracatın karmaşık ve uzun sürecinin aksine, basit ve
              oldukça hızlı bir iş modelidir.
              <br />
              <br />
              Mikro ihracat ile alakalı detaylı bilgiler bulabileceğiniz blog
              yazımıza{" "}
              <NavLink
                to={
                  "https:// blog.iyziship.com/mikro-ihracat-nedir-nasil-yapilir/"
                }
                target="_blank"
              >
                https:// blog.iyziship.com/mikro-ihracat-nedir-nasil-yapilir/
              </NavLink>
              göz atmayı unutmayın.
            </p>
          </div>
        </AccordionDetails>
      </Accordion>
      <Accordion>
        <AccordionSummary expandIcon={<ExpandMoreIcon />}>
          <Typography ref={(el) => (accordionRefs.current[16] = el)}>
            A.TR ne demek?
          </Typography>
        </AccordionSummary>
        <AccordionDetails>
          <div className="frame-wrapper">
            <p>
              A.TR belgesi, Avrupa Birliği ile Türkiye arasında işlenmiş tarım
              veya sanayi ürünlerinin serbest dolaşımında kullanılan ve eşyanın
              meşruiyetini gösteren bir belgedir. Fransızca "Admission
              Temporaire Roulette" kısaltmasının yerine kullanılan A.TR belgesi;
              gümrük müşaviri veya taşıyıcı kurye firmaları tarafından çıkarılır
              ve Ticaret Odası ve ilgili gümrük makamlarının onayına tabidir.
              <br />
              <br />
              A.TR belgesi, Türkiye ve Avrupa Birliği üyesi ülkeler arasındaki
              ticarette kullanılır ve en önemli faydası, varış ülkesinde ithalat
              vergisi oluşmasına engel olmasıdır. Bu sayede ürün, adeta AB üyesi
              bir ülkeden diğerine gönderiliyormuş gibi sadece KDV ödenerek
              ihraç edilebilmektedir.
            </p>
          </div>
        </AccordionDetails>
      </Accordion>
      <Accordion>
        <AccordionSummary expandIcon={<ExpandMoreIcon />}>
          <Typography ref={(el) => (accordionRefs.current[17] = el)}>
            Yurt dışına bireysel gönderi yapılabilir mi?
          </Typography>
        </AccordionSummary>
        <AccordionDetails>
          <div className="frame-wrapper">
            <p>
              Arzu eden herkes belirli limitler ve şartlar dahilinde yurt dışına
              gönderi yapabilmektedir.
            </p>
          </div>
        </AccordionDetails>
      </Accordion>
      <Accordion>
        <AccordionSummary expandIcon={<ExpandMoreIcon />}>
          <Typography ref={(el) => (accordionRefs.current[18] = el)}>
            Üyelik için şirket sahibi olmak gerekli mi?
          </Typography>
        </AccordionSummary>
        <AccordionDetails>
          <div className="frame-wrapper">
            <p>
              Hem şahıslar hem de şirketler için üyelik oluşturma imkanı sunan
              iyziShip; kullanıcı dostu arayüzü ve çeşitli seçenekleriyle,
              uluslararası gönderi yapmak isteyen herkes için kolay ve güvenli
              bir gönderim deneyimi sunar.
            </p>
          </div>
        </AccordionDetails>
      </Accordion>
      <Accordion>
        <AccordionSummary expandIcon={<ExpandMoreIcon />}>
          <Typography ref={(el) => (accordionRefs.current[19] = el)}>
            VAT Numarası ne demek?
          </Typography>
        </AccordionSummary>
        <AccordionDetails>
          <div className="frame-wrapper">
            <p>
              VAT veya Value-Added Tax, İngilizce'de "Katma Değer Vergisi"
              anlamına gelir. Türkçe karşılığı ise Katma Değer Vergisi, yani
              KDV’dir.
            </p>
          </div>
        </AccordionDetails>
      </Accordion>
      <Accordion>
        <AccordionSummary expandIcon={<ExpandMoreIcon />}>
          <Typography ref={(el) => (accordionRefs.current[20] = el)}>
            E-ihracatta vergiler alıcıya mı ait?
          </Typography>
        </AccordionSummary>
        <AccordionDetails>
          <div className="frame-wrapper">
            <p>
              E-ihracatta vergilerin alıcıya mı ait olduğu sorusuna net bir
              cevap veremeyiz. Genel anlamda vergilerin dahil veya hariç
              olduğuna dair kesin bir anlayış yoktur.
              <br />
              <br />
              Satıcılar, varış ülkesindeki gümrük vergileri dahil olarak bir
              fiyat belirleyerek DDP (Delivery Duty Paid) olarak taşıyıcıya
              talimat verebilirler. Bu durumda, taşıyıcı ilgili ülkedeki ithalat
              vergilerini öder ve sonra göndericiden tahsil eder.
              <br />
              <br />
              Ancak aksi belirtilmediği sürece, her ihracatta varış gümrüğünde
              oluşan ithalat vergileri alıcıya aittir. Yani eşya, DDU (Delivery
              Duty Unpaid) olarak gönderilir. KDV veya VAT ise her zaman alıcı
              tarafından ödenmektedir.
            </p>
          </div>
        </AccordionDetails>
      </Accordion>
      <Accordion>
        <AccordionSummary expandIcon={<ExpandMoreIcon />}>
          <Typography ref={(el) => (accordionRefs.current[21] = el)}>
            EORI Numarası nedir?
          </Typography>
        </AccordionSummary>
        <AccordionDetails>
          <div className="frame-wrapper">
            <p>
              EORI No, Ekonomik Operatör Kayıt ve Tanımlama Numarası anlamına
              gelmektedir. İngilizce “Economic Operators Registration and
              Identification Number”ın kısaltmasıdır. Avrupa Birliği ve Birleşik
              Krallık'taki gümrük makamları tarafından kullanılan ve benzersiz
              tanımlama numaralarından oluşan bir sistemdir. EORI numarası, bu
              ülkelerde bulunan kişiler tarafından ilgili gümrük web
              sitelerinden edinilebilir ve ticari faturalara eklenerek ithalat
              işlemleri hızlı ve kolay bir şekilde tamamlanabilir. Bu numara
              ayrıca, uluslararası ticaret yapan şirketlerin gümrük işlemlerinde
              de önemli bir rol oynar.
            </p>
          </div>
        </AccordionDetails>
      </Accordion>
      <Accordion>
        <AccordionSummary expandIcon={<ExpandMoreIcon />}>
          <Typography ref={(el) => (accordionRefs.current[22] = el)}>
            Hemen takip numarası (AWB No) nasıl alabilirim?
          </Typography>
        </AccordionSummary>
        <AccordionDetails>
          <div className="frame-wrapper">
            <p>
              Navlun ücretinizi iyziBakiyem hesabınıza ödediğiniz takdirde,
              entegrasyonlar dahilinde express kurye taşımacılığı yapan
              firmaların pek çoğuyla çalışarak, herhangi bir yardıma ihtiyaç
              duymaksızın, takip numaranızı iyziShip paneli üzerinden kendiniz
              7/24 alabilirsiniz.
            </p>
            <div className="iframe-wrapper">
              <div className="iframe-container">
                {/* <Iframe
                  className="iframe"
                  src="https://www.youtube.com/embed/PHt3xs_KnP4"
                  title="YouTube video player"
                  frameborder="0"
                  allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                  allowfullscreen
                /> */}
              </div>
            </div>
          </div>
        </AccordionDetails>
      </Accordion>
      <Accordion>
        <AccordionSummary expandIcon={<ExpandMoreIcon />}>
          <Typography ref={(el) => (accordionRefs.current[23] = el)}>
            Kargo sigortası yaptırmak gerekli mi?
          </Typography>
        </AccordionSummary>
        <AccordionDetails>
          <div className="frame-wrapper">
            <p>
              Gönderi yaparken tıpkı ev, araba, tatil veya sağlık sigortası
              yaptırdığımız gibi sigorta yaptırmanın önemi büyüktür.
              <br />
              <br />
              Taşıyıcı firmalar, gönderici tarafından yapılan yetersiz ve
              özensiz paketleme nedeniyle oluşan hasarlarda tazminat
              ödememektedir. Ancak iyi bir paketleme sonrası sigorta yapılması
              durumunda, taşıma sırasında meydana gelebilecek herhangi bir
              sorunun tazmini taşıyıcı veya sigorta şirketi tarafından
              gerçekleştirilir ve oluşabilecek mağduriyetler önemli ölçüde
              giderilir.
            </p>
          </div>
        </AccordionDetails>
      </Accordion>
      <Accordion>
        <AccordionSummary expandIcon={<ExpandMoreIcon />}>
          <Typography ref={(el) => (accordionRefs.current[24] = el)}>
            Kargo Sigortasının kapsamı nedir?
          </Typography>
        </AccordionSummary>
        <AccordionDetails>
          <div className="frame-wrapper">
            <p>
              Taşımacılık sigortası; göndericinin beyan ettiği faturada yer alan
              malın değeri ve taşımacılık ücreti kadar bir kapsama sahiptir. Bu
              sebeple, sigorta poliçesi ücreti bu beyanlar göz önünde
              bulundurularak hesaplanır ve gönderi işlemi öncesi sigorta ücreti
              olarak tahsil edilir.
            </p>
          </div>
        </AccordionDetails>
      </Accordion>
      <Accordion>
        <AccordionSummary expandIcon={<ExpandMoreIcon />}>
          <Typography ref={(el) => (accordionRefs.current[25] = el)}>
            Gönderimin yurt dışı takibini nasıl yapacağım?
          </Typography>
        </AccordionSummary>
        <AccordionDetails>
          <div className="frame-wrapper">
            <p>
              yziShip paneliniz üzerinden ilgili gönderinin takip numarasına
              tıkladığınızda, doğrudan taşıyıcı sayfasına yönlendirilerek takip
              işlemini başlatabilirsiniz. Dilerseniz seçtiğiniz taşıyıcı
              firmanın web sitesi üzerinden de takip numarasını girerek,
              gönderinizi takip edebilirsiniz.
            </p>
          </div>
        </AccordionDetails>
      </Accordion>
    </div>
  );
};

export default FrequentAccordions;
