import "../style/orders/Orders.css";
import { useCookies } from "react-cookie";
import { DynamicTitle } from "../utils/DynamicTitle";
import axios from "../api/axios";
import NotificationBar from "../components/sidebar/NotificationBar";
import { ToastContainer } from "react-toastify";
// import PageTransition from "../utils/PageTransition";
import WrapperStyle from "../utils/WrapperStyle";
import OrderDetailSearch from "../components/orders/OrderDetailSearch";
import OrderList from "../components/orders/OrderList";
import OrderTitle from "../components/orders/OrderTitle";
import Errors from "../utils/Errors";
import { useCallback, useEffect, useState } from "react";
import { useRecoilState, useRecoilValue, useSetRecoilState } from "recoil";
import {
  orderBarcodeLabelState,
  orderBarcodeTopLabelState,
  orderCompleteModalState,
  orderListCheckedListState,
  orderListState,
  pageFormatState,
} from "../atoms/orders";
import OrderCount from "../components/orders/OrderCount";
import OrderBasket from "../components/orders/OrderBasket";
import OrderDelete from "../components/orders/orderModal/OrderDelete";
import OrderDomestic from "../components/orders/OrderDomestic";
import { Button } from "@mui/material";
import OrderComplete from "../components/orders/orderModal/OrderComplete";
import OrderTicket from "../components/orders/orderModal/OrderTicket";
import OrderAreYouSureDialog from "../components/orders/orderModal/OrderAreYouSureDialog";
import { basketItemsState, basketTotalItemsState } from "../atoms/basket";
import miniLogo from "../img/logos/logo_white_mini_loading.svg";
import domesticSuppliers from "../json/orderDomesticSuppliers.json";
import OrderSuccess from "../components/orders/orderModal/OrderSuccess";
import { startAnimationState } from "../atoms/startAnimation";
import DomesticAddress from "../components/orders/orderDomestic/DomesticAddress";

const Orders = () => {
  DynamicTitle("Etiket Oluştur | iyziShip");

  const [token] = useCookies(["jwt"]);
  const jwt = token.jwt;
  axios.defaults.headers.common["Authorization"] = `Bearer ${jwt}`;

  const [orderList, setOrderList] = useRecoilState(orderListState);

  const startAnimation = useRecoilValue(startAnimationState);
  const pageFormat = useRecoilValue(pageFormatState);
  const orderListCheckedList = useRecoilValue(orderListCheckedListState);

  const setOrderCompleteModal = useSetRecoilState(orderCompleteModalState);
  const setOrderBarcodeLabel = useSetRecoilState(orderBarcodeLabelState);
  const setOrderBarcodeTopLabel = useSetRecoilState(orderBarcodeTopLabelState);
  const setBasketItems = useSetRecoilState(basketItemsState);
  const setBasketTotalItems = useSetRecoilState(basketTotalItemsState);

  const [openSure, setOpenSure] = useState(false);
  const [loading, setLoading] = useState(false);
  const [getDataLoading, setGetDataLoading] = useState(true);

  const [exchangeRates, setExchangeRates] = useState({});
  const [orderSelectedPackageSystem, setOrderSelectedPackageSystem] = useState("S");
  const [domesticPrice, setDomesticPrice] = useState(null);
  const [freeShippingLimit, setFreeShippingLimit] = useState(null);
  const [memberHasBookingAddress, setMemberHasBookingAddress] = useState(false);
  const [orderSelectedSupplier, setOrderSelectedSupplier] = useState(
    domesticSuppliers.find((supp) => supp.default === 1)
  );

  const isOrderExist = orderList.length > 0;

  // Check if 'Etiket Oluştur' button is disabled
  const isCompleteDisabled =
    orderListCheckedList.length === 0 || Object.keys(orderSelectedSupplier).length === 0;

  // Request sent to retrieve order data
  const getData = useCallback(() => {
    axios
      .get(`parcel/order`)
      .then((response) => {
        setOrderList(response.data.data);
        setDomesticPrice(response.data?.domesticPrice);
        setFreeShippingLimit(response.data?.freeShippingUpperLimit);
        setMemberHasBookingAddress(response.data?.memberHasBookingAddress);
        setExchangeRates(response.data?.currentRates);
      })
      .catch((error) => Errors(error))
      .finally(() => setGetDataLoading(false));
  }, [setOrderList]);

  // Request sent to retrieve order basket data
  const getBasketData = useCallback(() => {
    axios
      .get("basket")
      .then((response) => {
        setBasketItems(response.data.data);
        setBasketTotalItems(response.data.totalItems);
      })
      .catch((error) => Errors(error));
  }, [setBasketItems, setBasketTotalItems]);

  // Opening the confirmation dialog component
  const handleSureCompleteOpen = () => {
    setOpenSure(true);
  };

  // Closing the confirmation dialog component
  const handleSureCompleteClose = () => {
    setOpenSure(false);
  };

  // Modal to be opened for displaying the response received after sending the label creation request
  const handleComplete = useCallback(async () => {
    setOpenSure(false);
    setLoading(true);

    await axios
      .post(`/parcel/turn-orders-to-parcel`, {
        order_ids: orderListCheckedList.map((checkedItem) => checkedItem.id),
        packaging_type: orderSelectedPackageSystem,
        domestic_supplier_name: orderSelectedSupplier?.supplier,
        page_format: pageFormat,
      })
      .then((response) => {
        setOrderCompleteModal(true);
        setOrderBarcodeLabel(response.data.base64);
        if (orderSelectedPackageSystem === "B") {
          setOrderBarcodeTopLabel(response.data.bundle_base64);
        }
        getBasketData();
      })
      .catch((error) => Errors(error))
      .finally(() => setLoading(false));
  }, [
    orderListCheckedList,
    orderSelectedPackageSystem,
    orderSelectedSupplier,
    pageFormat,
    setOrderCompleteModal,
    setOrderBarcodeLabel,
    setOrderBarcodeTopLabel,
    getBasketData,
  ]);

  // useEffect to fetch data every time a page loads
  useEffect(() => {
    getData();
  }, [getData]);

  return (
    <section className="orders">
      <NotificationBar />
      <ToastContainer />
      <>
        {/* Modals */}
        <OrderDelete />
        <OrderTicket />
        <OrderComplete
          courierNavigate={orderSelectedSupplier?.supplier}
          orderSelectedPackageSystem={orderSelectedPackageSystem}
        />
        <OrderSuccess supplier={orderSelectedSupplier} />
        <OrderAreYouSureDialog
          title={"Etiket Oluşturma"}
          text={"Etiket oluşturuyorsunuz. Devam etmek istiyor musunuz?"}
          handleSubmit={handleComplete}
          handleClose={handleSureCompleteClose}
          open={openSure}
          submitText={"Etiket Oluştur"}
          loading={loading}
        />
      </>
      {/* <PageTransition> */}
        <OrderTitle />
        <div className="orders-wrapper" style={WrapperStyle()}>
          {getDataLoading ? (
            <div className="orders-wrapper-loading">
              <img className="loading-logo" src={miniLogo} alt="iyziShip Loading Görseli" />
            </div>
          ) : (
            <>
              <OrderDetailSearch setOrderSelectedPackageSystem={setOrderSelectedPackageSystem} />
              <OrderList
                exchangeRates={exchangeRates}
                setOrderSelectedPackageSystem={setOrderSelectedPackageSystem}
              />
              <OrderCount />
              {isOrderExist && (
                <>
                  <OrderBasket
                    exchangeRates={exchangeRates}
                    orderSelectedPackageSystem={orderSelectedPackageSystem}
                    setOrderSelectedPackageSystem={setOrderSelectedPackageSystem}
                    domesticPrice={domesticPrice}
                    freeShippingLimit={freeShippingLimit}
                    orderSelectedSupplier={orderSelectedSupplier}
                  />
                  {startAnimation ? (
                    <DomesticAddress
                      memberHasBookingAddress={memberHasBookingAddress}
                      setMemberHasBookingAddress={setMemberHasBookingAddress}
                    />
                  ) : (
                    <OrderDomestic
                      exchangeRates={exchangeRates}
                      orderSelectedPackageSystem={orderSelectedPackageSystem}
                      domesticPrice={domesticPrice}
                      freeShippingLimit={freeShippingLimit}
                      orderSelectedSupplier={orderSelectedSupplier}
                      setOrderSelectedSupplier={setOrderSelectedSupplier}
                      memberHasBookingAddress={memberHasBookingAddress}
                    />
                  )}
                  <Button
                    disabled={isCompleteDisabled}
                    onClick={handleSureCompleteOpen}
                    sx={{
                      maxWidth: "1400px",
                      width: "100%",
                      margin: "0 auto 20px",
                      ":disabled": { opacity: 0.3 },
                    }}
                    className="iyzi-button-blue"
                  >
                    Etiket Oluştur
                  </Button>
                </>
              )}
            </>
          )}
        </div>
      {/* </PageTransition> */}
    </section>
  );
};

export default Orders;
