import { Divider } from "@mui/material";
import Currency from "../../../utils/CurrencySymbol";
import LocalPrice from "../../../utils/LocalPrice";

const OrderBasketTotal = ({
  totalRemainingPayment,
  basketDomesticPrice,
  freeShippingLimit,
  orderSelectedPackageSystem,
}) => {
  const aboveLimit = totalRemainingPayment >= freeShippingLimit;
  const totalPrice =
    aboveLimit && orderSelectedPackageSystem === "B"
      ? totalRemainingPayment
      : totalRemainingPayment + basketDomesticPrice;

  return (
    <div className="order-basket-total">
      <div className="order-basket-total-item">
        <div className="total-item-child headline">Sepet Toplamı:</div>
        <div className="total-item-child" style={{ fontWeight: 700 }}>
          {LocalPrice(totalRemainingPayment)} {Currency("TRY")}
        </div>
      </div>
      <div className="order-basket-total-item">
        <div className="total-item-child">Kargo Masrafı:</div>
        <div className="total-item-child">
          {LocalPrice(basketDomesticPrice)} {Currency("TRY")}
        </div>
      </div>
      <div
        className="order-basket-total-item"
        style={{ visibility: orderSelectedPackageSystem === "S" ? "hidden" : "visible" }}
      >
        <div className="total-item-child">
          <div>{LocalPrice(freeShippingLimit)} TL ve üzeri </div>
          <span className="red">Kargo Ücretsiz </span>
        </div>
        <div className="total-item-child" style={{ color: "var(--btnBgBlue)", fontWeight: 700 }}>
          -{LocalPrice(aboveLimit ? basketDomesticPrice : 0)} {Currency("TRY")}
        </div>
      </div>
      <Divider />
      <div className="total-item-final-price" style={{ fontWeight: 700 }}>
        {LocalPrice(totalPrice)} {Currency("TRY")}
      </div>
    </div>
  );
};

export default OrderBasketTotal;
