import { TableCell, TableRow } from "@mui/material";
import countries from "../../../../json/countriesDetailed.json";

const ReceiverAddressTableBody = ({ address }) => {
  return (
    <TableRow>
      <TableCell>{address?.address_description}</TableCell>
      <TableCell>{address?.contact_name}</TableCell>
      <TableCell>{address?.postal_code}</TableCell>
      <TableCell>{address?.phone}</TableCell>
      <TableCell>{address?.state}</TableCell>
      <TableCell>{address?.town}</TableCell>
      <TableCell>{address?.city}</TableCell>
      <TableCell>
        {
          countries.find((country) => country.iso_code === address?.country)
            .country_name_tr
        }
      </TableCell>
      <TableCell>
        {address?.address1} {address?.address2}
      </TableCell>
    </TableRow>
  );
};

export default ReceiverAddressTableBody;
