import "../../../style/createShipment/NewAddress.css";
import Box from "@mui/material/Box";
import CreateAddress from "./CreateAddress";
import Addresses from "./Addresses";
import { useRecoilValue } from "recoil";
import { startAnimationState } from "../../../atoms/startAnimation";
import DefaultBillAddress from "./DefaultBillAddress";
import { isDefaultBillAddressState } from "../../../atoms/profileSettings";
import AddressList from "./AddressList";
import { useState } from "react";

const NewAddress = () => {
  const startAnimation = useRecoilValue(startAnimationState);
  const isDefaultBillAddress = useRecoilValue(isDefaultBillAddressState);
  const [anchorEl, setAnchorEl] = useState(null);
  
  return (
    <Box className="new-address">
      {isDefaultBillAddress?.id === undefined ? (
        <DefaultBillAddress />
      ) : (
        <>
          {startAnimation === false && (
            <>
              <Addresses anchorEl={anchorEl} setAnchorEl={setAnchorEl}  />
              <AddressList anchorEl={anchorEl} setAnchorEl={setAnchorEl} />
            </>
          )}
          <CreateAddress />
        </>
      )}
    </Box>
  );
};

export default NewAddress;
