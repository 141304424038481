import { Modal, Box, TextField, Button, CircularProgress } from "@mui/material";
import { useCallback, useEffect, useState } from "react";
import { useRecoilState } from "recoil";
import { updateDimensionModalState } from "../../../atoms/profileSettings";
import axios from "../../../api/axios";
import { toast } from "react-toastify";
import { useCookies } from "react-cookie";
import Errors from "../../../utils/Errors";

const EditDimension = () => {
  const [token] = useCookies(["jwt"]);
  const jwt = token?.jwt;

  const [updateDimensionModal, setUpdateDimensionModal] = useRecoilState(updateDimensionModalState);

  const [en, setEn] = useState(updateDimensionModal.data.length);
  const [boy, setBoy] = useState(updateDimensionModal.data.width);
  const [yuk, setYuk] = useState(updateDimensionModal.data.height);
  const [kg, setKg] = useState(updateDimensionModal.data.gross);
  const [qty, setQty] = useState(updateDimensionModal.data.qty);
  const [name, setName] = useState(updateDimensionModal.data.name);
  const [supId, setSupId] = useState(updateDimensionModal.data.supplier_id);
  const [supplier, setSupplier] = useState([]);

  const [loading, setLoading] = useState(false);

  const handleupdateDimensionModalClose = () => {
    setUpdateDimensionModal({ status: false, data: {} });
    setEn("");
    setBoy("");
    setYuk("");
    setKg("");
    setQty("");
    setName("");
    setSupId("");
    setSupplier([]);
  };

  const updateDimensionHandler = useCallback(
    async (e) => {
      e.preventDefault();
      setLoading(true);

      const data =
        supId === null || supId === "" || supId === undefined
          ? {
              length: en,
              width: boy,
              height: yuk,
              gross: kg,
              qty,
              name,
            }
          : {
              length: en,
              width: boy,
              height: yuk,
              gross: kg,
              qty,
              name,
              supplier_id: Number(supId),
            };
      await axios
        .post(`member-dimension/update/${updateDimensionModal.data?.id}`, data, {
          headers: {
            Authorization: `Bearer ${jwt}`,
          },
        })
        .then((response) => {
          setLoading(false);
          toast.success(response.data.message, { autoClose: 2000 });
          setTimeout(() => {
            window.location.reload(true);
          }, 1000);
          setUpdateDimensionModal({ status: false, data: {} });
        })
        .catch((error) => {
          setLoading(false);
          Errors(error);
        });
    },
    [qty, name, supId, boy, en, yuk, kg, updateDimensionModal.data, jwt, setUpdateDimensionModal]
  );

  const handleDecimalChange = (e) => {
    let value = e.target.value.replace(/,/g, ".");
    const name = e.target.name;

    if (name === "qty") {
      value = value.replace(/[^0-9]|^0{1,}/g, "");
      setQty(value);
    } else {
      value = value.replace(/[^0-9.]|(?<=\..*)\.|^0{2,}(?=[.])/g, "");

      if (name === "gross") {
        value = value.replace(/^0{2,}/, "0");
      }

      const regex = /^\d+(\.\d{0,2})?$/;

      if (
        value.startsWith(".") ||
        (!regex.test(value) && value !== "") ||
        (name !== "gross" && value.startsWith("0.") && value.length === 1)
      ) {
        return;
      } else {
        if (name === "gross") {
          if (Number(value) > 300) {
            setKg(300);
          } else {
            setKg(value);
          }
        } else {
          if (name === "en") {
            setEn(value);
          } else if (name === "boy") {
            setBoy(value);
          } else if (name === "yuk") {
            setYuk(value);
          }
        }
      }
    }
  };

  const handleBlur = (e) => {
    if (e.target.name === "gross" && e.target.value === "0") {
      setKg("");
    }
  };

  const isDisabled = useCallback(() => {
    if (
      en === "" ||
      boy === "" ||
      yuk === "" ||
      kg === "" ||
      qty === "" ||
      name === "" ||
      supplier === ""
    ) {
      return true;
    } else return false;
  }, [en, boy, yuk, kg, qty, name, supplier]);

  const style = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: "fit-content",
    bgcolor: "background.paper",
    boxShadow: 24,
    p: 4,
  };

  useEffect(() => {
    if (updateDimensionModal.status) {
      axios
        .get("member-dimension/supplier")
        .then((response) => {
          setSupplier(response.data.data);
        })
        .catch((error) => Errors(error));
    }

    setEn(updateDimensionModal.data.length);
    setBoy(updateDimensionModal.data.width);
    setYuk(updateDimensionModal.data.height);
    setKg(updateDimensionModal.data.gross);
    setQty(updateDimensionModal.data.qty);
    setName(updateDimensionModal.data.name);
    setSupId(updateDimensionModal.data.supplier_id);
  }, [updateDimensionModal]);

  return (
    <Modal open={updateDimensionModal.status} onClose={handleupdateDimensionModalClose}>
      <Box
        sx={style}
        style={{
          maxHeight: "90vh",
          overflowY: "auto",
        }}
      >
        <form onSubmit={updateDimensionHandler} className="default-dimension">
          <div
            style={{
              width: "100%",
              display: "flex",
              gap: "var(--gap)",
              marginBottom: "var(--gap2x)",
            }}
            className="shipment-dimension-modal-form-headline"
          >
            <TextField
              size="small"
              label="Başlık"
              required
              fullWidth
              value={name}
              onChange={(e) => setName(e.target.value)}
              autoComplete="off"
            />
          </div>
          <div className="dimension-info-wrapper">
            <TextField
              label="En (cm)"
              size="small"
              name="en"
              value={en}
              autoComplete="off"
              onChange={handleDecimalChange}
              sx={{ marginRight: "10px", marginBottom: "20px" }}
            />
            <TextField
              label="Boy (cm)"
              size="small"
              name="boy"
              value={boy}
              autoComplete="off"
              onChange={handleDecimalChange}
              sx={{ marginRight: "10px", marginBottom: "20px" }}
            />
            <TextField
              label="Yükseklik (cm)"
              size="small"
              name="yuk"
              value={yuk}
              autoComplete="off"
              onChange={handleDecimalChange}
              sx={{ marginRight: "10px", marginBottom: "20px" }}
            />
            <TextField
              label="Kg"
              size="small"
              name="gross"
              value={kg}
              autoComplete="off"
              onChange={handleDecimalChange}
              onBlur={handleBlur}
              sx={{ marginRight: "10px", marginBottom: "20px" }}
            />
          </div>
          <div
            className="shipment-dimension-modal-form-buttons"
            style={{
              gap: "var(--gap2x)",
              display: "flex",
              justifyContent: "flex-end",
              width: "100%",
              marginTop: "var(--gap2x)",
            }}
          >
            <Button onClick={handleupdateDimensionModalClose} type="button" className="iyzi-button">
              Vazgeç
            </Button>
            {loading ? (
              <div
                style={{
                  width: "64px",
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                <CircularProgress
                  sx={{
                    width: "21px !important",
                    height: "21px !important",
                  }}
                />
              </div>
            ) : (
              <Button
                disabled={isDisabled()}
                className="iyzi-button"
                type="submit"
                sx={{
                  ":disabled": {
                    opacity: 0.4,
                  },
                }}
              >
                Kaydet
              </Button>
            )}
          </div>
        </form>
      </Box>
    </Modal>
  );
};

export default EditDimension;
