import "../style/forgot/Forgot.css";
import Header from "../components/beforeLogin/Header";
import Footer from "../components/beforeLogin/Footer";
import ForgotHeadline from "../components/forgot/ForgotHeadline";
import ForgotSignWithSocial from "../components/forgot/ForgotSignWithSocial";
import ResetPassword from "../components/forgot/ResetPassword";
import { DynamicTitle } from "../utils/DynamicTitle";
// import PageTransition from "../utils/PageTransition";

const ForgotPassword = () => {
  DynamicTitle("Şifremi Unuttum | iyziShip");

  return (
    <>
      <Header />
      {/* <PageTransition> */}
      <section className="forgot-main">
        <div className="forgot-wrapper">
          <div className="forgot">
            <ForgotHeadline />
            <ResetPassword />
            <ForgotSignWithSocial />
          </div>
        </div>
      </section>
      <Footer />
      {/* </PageTransition> */}
    </>
  );
};

export default ForgotPassword;
