import { useRecoilValue, useSetRecoilState } from "recoil";
import { orderListCheckedListState } from "../../atoms/orders";
import domesticSuppliers from "../../json/orderDomesticSuppliers.json";
import LocalPrice from "../../utils/LocalPrice";
import Currency from "../../utils/CurrencySymbol";
import { Checkbox, IconButton, Tooltip } from "@mui/material";
import AddCircleOutlineOutlinedIcon from "@mui/icons-material/AddCircleOutlineOutlined";
import { startAnimationState } from "../../atoms/startAnimation";
import { addressTypeState } from "../../atoms/profileSettings";

const OrderDomestic = ({
  exchangeRates,
  orderSelectedPackageSystem,
  domesticPrice,
  orderSelectedSupplier,
  setOrderSelectedSupplier,
  freeShippingLimit,
  memberHasBookingAddress,
}) => {
  const orderListCheckedList = useRecoilValue(orderListCheckedListState);

  const setStartAnimation = useSetRecoilState(startAnimationState);
  const setAddressType = useSetRecoilState(addressTypeState);

  const totalRemainingPayment = orderListCheckedList.reduce((total, item) => {
    const payment = parseFloat(item.remaining_payment) || 0;
    const currency = item.currency;
    const exchangeRate = exchangeRates[currency] || 1; // Eğer döviz kuru bulunamazsa, varsayılan olarak 1 kullanılır.

    return total + payment * exchangeRate;
  }, 0);

  const aboveLimit =
    totalRemainingPayment >= freeShippingLimit && orderSelectedPackageSystem === "B";

  const basketDomesticPrice =
    orderListCheckedList.length > 0
      ? orderSelectedPackageSystem === "S"
        ? orderListCheckedList.length * domesticPrice
        : domesticPrice
      : 0;

  const handleCreateCourierAddress = () => {
    setAddressType("2");
    setStartAnimation(true);
  };

  const handleChange = (dom) => {
    setOrderSelectedSupplier(dom);
  };

  return (
    <div className="order-domestic">
      <div className="order-domestic-wrapper">
        {domesticSuppliers
          .filter((e) => e.courier === true && e.disabled === false)
          .map((dom, key) => (
            <div
              className={`order-domestic-item${dom === orderSelectedSupplier ? " selected" : ""}`}
              key={key}
            >
              <img alt={dom.title} src={dom.src} />
              <p className="domestic-item-title">{dom.title}</p>
              <p className="domestic-item-subtitle">{dom.subtitle}</p>
              <p className="domestic-item-price">
                {LocalPrice(0)} {Currency("TRY")}
              </p>
              {memberHasBookingAddress ? (
                <Checkbox
                  checked={dom === orderSelectedSupplier}
                  onChange={() => handleChange(dom)}
                />
              ) : (
                <Tooltip title="Toplama Adresi Oluştur">
                  <IconButton onClick={handleCreateCourierAddress} sx={{ padding: "9px" }}>
                    <AddCircleOutlineOutlinedIcon sx={{ color: "var(--btnBgBlue)" }} />
                  </IconButton>
                </Tooltip>
              )}
            </div>
          ))}
        {domesticSuppliers
          .filter((e) => e.courier === false && e.disabled === false)
          .map((dom, key) => (
            <div
              className={`order-domestic-item${dom === orderSelectedSupplier ? " selected" : ""}`}
              key={key}
            >
              <img alt={dom.title} src={dom.src} />
              <p className="domestic-item-title">{dom.title}</p>
              <p className="domestic-item-subtitle">{dom.subtitle}</p>
              <p className="domestic-item-price">
                {LocalPrice(
                  dom.supplier === "Getir" ||
                    dom.supplier === "iyziCourier" ||
                    dom.supplier === "Other"
                    ? 0
                    : aboveLimit
                    ? 0
                    : basketDomesticPrice
                )}{" "}
                {Currency("TRY")}
              </p>
              <Checkbox
                checked={dom === orderSelectedSupplier}
                onChange={() => handleChange(dom)}
              />
            </div>
          ))}
      </div>
    </div>
  );
};

export default OrderDomestic;
