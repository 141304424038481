import { TextField } from "@mui/material";

const OrderCWSearch = ({ orderCw, setOrderCw }) => {
  const handleChange = (e) => {
    setOrderCw(e.target.value);
  };

  return (
    <TextField
      value={orderCw}
      onChange={handleChange}
      size="small"
      name="order-cw-field"
      label="CW (kg)"
      type="number"
      autoComplete="off"
    />
  );
};

export default OrderCWSearch;
