import { Box, TableCell, TableHead, TableRow } from "@mui/material";
import { useCallback, useState } from "react";
import axios from "../../../api/axios";
import { useRecoilState, useRecoilValue } from "recoil";
import { billsKonsimentoDataState, billsKonsimentoIdState } from "../../../atoms/myWallet";
import Errors from "../../../utils/Errors";
import KeyboardArrowDownOutlinedIcon from "@mui/icons-material/KeyboardArrowDownOutlined";

const BillsKonsimentoTableHead = () => {
  const billsKonsimentoId = useRecoilValue(billsKonsimentoIdState);
  const [, setBillsKonsimentoData] = useRecoilState(billsKonsimentoDataState);

  const [activeSort, setActiveSort] = useState([{ column: "line_no", direction: "ASC" }]);

  const handleMyBillsPageChange = useCallback(
    async (orderBy) => {
      const updatedSort = activeSort.map((sort) => {
        if (sort.column === orderBy) {
          return {
            ...sort,
            direction: sort.direction === "ASC" ? "DESC" : "ASC",
          };
        }
        return {
          ...sort,
          direction: null, // Diğer sütunların yönünü varsayılan duruma sıfırlayın
        };
      });

      setActiveSort(updatedSort);

      const currentDirection = updatedSort.find((sort) => sort.column === orderBy).direction;

      axios
        .get(`invoice/get-items/${billsKonsimentoId}`, {
          params: {
            page: 1,
            limit: 100,
            order_by: orderBy,
            order_direction: currentDirection,
          },
        })
        .then((response) => {
          setBillsKonsimentoData(response.data.data);
        })
        .catch((error) => {
          Errors(error);
        });
    },
    [activeSort, billsKonsimentoId, setBillsKonsimentoData]
  );

  return (
    <TableHead>
      <TableRow>
        <TableCell>
          <Box
            sx={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              color: "var(--priceText)",
              fontSize: "13px",
            }}
          >
            Sıra No{" "}
            <KeyboardArrowDownOutlinedIcon
              onClick={() => handleMyBillsPageChange("line_no")}
              sx={{
                transform:
                  activeSort.find((sort) => sort.column === "line_no").direction === "ASC"
                    ? "rotate(180deg)"
                    : "rotate(0deg)",
                transition: "transform 0.3s",
              }}
            />
          </Box>
        </TableCell>
        <TableCell>Mal Hizmet</TableCell>
        <TableCell>Konşimento No</TableCell>
        <TableCell>Return No</TableCell>
        <TableCell>Alıcı</TableCell>
        <TableCell>Varış Ülke</TableCell>
        <TableCell>Miktar</TableCell>
        <TableCell>KDV Oranı</TableCell>
        <TableCell>Mal Hizmet Tutarı</TableCell>
      </TableRow>
    </TableHead>
  );
};

export default BillsKonsimentoTableHead;
