import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Typography,
} from "@mui/material";

const DeleteFastDialog = ({ open, setOpen, handleDelete }) => {
  const handleClose = () => {
    setOpen(false);
  };

  const handleSubmit = () => {
    setOpen(false);
    handleDelete();
  };

  return (
    <Dialog open={open} onClose={handleClose}>
      <DialogTitle
        sx={{
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        <img
          style={{ width: "100px", height: "100px" }}
          src="images/order/important.png"
          alt="Önemli Uyarı"
        />
        <Typography sx={{ fontSize: "20px", color: "var(--shipmentRed)", fontWeight: 700 }}>
          DİKKAT!
        </Typography>
      </DialogTitle>
      <DialogContent>
        <DialogContentText>
          <Typography>
            Entegre gönderinizi silmek üzeresiniz. Bu işleme devam etmek istiyor musunuz?
          </Typography>
        </DialogContentText>
        <DialogActions>
          <Button className="iyzi-button-gray" onClick={handleClose}>
            İptal
          </Button>
          <Button className="iyzi-button-red" onClick={handleSubmit}>
            Evet
          </Button>
        </DialogActions>
      </DialogContent>
    </Dialog>
  );
};

export default DeleteFastDialog;
