import { useCallback, useState } from "react";
import axios from "../../api/axios";
import DeleteOutlineOutlinedIcon from "@mui/icons-material/DeleteOutlineOutlined";
import { CircularProgress, IconButton, TableCell, TableRow, Tooltip } from "@mui/material";
import { toast } from "react-toastify";
import Errors from "../../utils/Errors";
import { useRecoilState } from "recoil";
import { currentIngtegrationsState } from "../../atoms/integrations";
import IntegrationDeleteDialog from "./IntegrationDeleteDialog";

const IntegrationListItemChild = ({ child, item }) => {
  const [open, setOpen] = useState(false);
  const [loading, setLoading] = useState(false);
  const [currentIngtegrations, setCurrentIngtegrations] = useRecoilState(currentIngtegrationsState);

  const handleDeleteDialog = () => {
    setOpen(true);
  };

  const handleDeleteIntegration = useCallback(() => {
    setLoading(true);
    axios
      .post(`/market-places-api-key/delete/${child?.portal}/${child?.request_id}`)
      .then((response) => {
        setLoading(false);
        toast.success(response.data.message);
        setCurrentIngtegrations(
          currentIngtegrations?.filter((curr) => curr.shop_id !== child?.shop_id)
        );
      })
      .catch((error) => Errors(error))
      .finally(() => setLoading(false));
  }, [child, currentIngtegrations, setCurrentIngtegrations]);

  return (
    <>
      <IntegrationDeleteDialog
        open={open}
        setOpen={setOpen}
        handleDelete={handleDeleteIntegration}
        child={child}
      />
      <TableRow>
        <TableCell>{child?.shop_id}</TableCell>
        <TableCell>{child?.shop_name}</TableCell>
        <TableCell>
          {/* {loading ? (
            <CircularProgress
              sx={{
                width: "21px!important",
                height: "21px!important",
              }}
            />
          ) : (
            <Tooltip title="Mağazayı Kaldır">
              <IconButton sx={{ color: item.background }} onClick={handleDeleteDialog}>
                <DeleteOutlineOutlinedIcon />
              </IconButton>
            </Tooltip>
          )} */}
        </TableCell>
      </TableRow>
    </>
  );
};

export default IntegrationListItemChild;
