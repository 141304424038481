import { useRecoilState, useRecoilValue, useSetRecoilState } from "recoil";
import {
  orderBarcodeLabelState,
  orderBarcodeTopLabelState,
  orderCompleteModalState,
  orderListCheckedListState,
  orderListState,
  orderSuccessModalState,
} from "../../../atoms/orders";
import { Box, Button, Modal } from "@mui/material";
import { useNavigate } from "react-router-dom";
import QrReader from "../../../utils/QrReader";
import DownloadBase64Pdf from "../../../utils/DownloadBase64Pdf";

const OrderComplete = (props) => {
  const { courierNavigate, orderSelectedPackageSystem } = props;

  const navigate = useNavigate();
  const [orderCompleteModal, setOrderCompleteModal] = useRecoilState(orderCompleteModalState);
  const [orderList, setOrderList] = useRecoilState(orderListState);
  const [orderListCheckedList, setOrderListCheckedList] = useRecoilState(orderListCheckedListState);
  const orderBarcodeLabel = useRecoilValue(orderBarcodeLabelState);
  const orderBarcodeTopLabel = useRecoilValue(orderBarcodeTopLabelState);
  const setOrderSuccessModal = useSetRecoilState(orderSuccessModalState);

  const handleClose = () => {
    const checkedIds = orderListCheckedList.map((item) => item.id);
    const filteredOrderList = orderList.filter((item) => !checkedIds.includes(item.id));
    setOrderList(filteredOrderList);

    setOrderListCheckedList([]);

    setOrderCompleteModal(false);
  };

  const handleNavigate = () => {
    const checkedIds = orderListCheckedList.map((item) => item.id);
    const filteredOrderList = orderList.filter((item) => !checkedIds.includes(item.id));
    setOrderList(filteredOrderList);

    setOrderListCheckedList([]);

    setOrderCompleteModal(false);
    navigate("/courier-registration");
  };

  const handleComplete = () => {
    handleClose();
    setOrderSuccessModal(true);
  };

  const handlePDFDownload = (base64, description) => {
    DownloadBase64Pdf(base64, description);
  };

  return (
    <Modal
      open={orderCompleteModal}
      onClose={handleClose}
      sx={{
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        padding: "20px",
        maxWidth: "1400px",
        width: "100%",
        overflowY: "auto",
        margin: "auto",
      }}
    >
      <Box
        sx={{
          width: "100%",
          backgroundColor: "var(--lighterBg)",
          padding: "20px",
          borderRadius: "var(--wrapperBorderRadius)",
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          gap: "var(--gap)",
          color: "var(--priceText)",
          overflow: "auto",
        }}
        style={{
          maxHeight: "90vh",
          overflowY: "auto",
        }}
      >
        <h3
          style={{
            fontSize: "16px",
            textAlign: "center",
            lineHeight: "30px",
            color: "var(--text)",
            fontWeight: 700,
          }}
        >
          Gönderilerinizi Göndermeden Önce Lütfen Etiketleyiniz
        </h3>
        <p
          style={{
            fontSize: "14px",
            textAlign: "center",
            fontWeight: 700,
            color: "var(--btnBgBlue)",
          }}
        >
          {orderSelectedPackageSystem === "S" ? "Paketlerinizin" : "İlk aşama; paketlerinizin"}{" "}
          üzerine aşağıdaki etiketi yapıştırın. Etiketi yazdırmak veya indirmek için aşağıdaki
          'İndir' butonuna tıklayın.
        </p>
        <Button
          className="iyzi-button-blue"
          onClick={() => handlePDFDownload(orderBarcodeLabel, "boxes_label")}
        >
          PDF İndir
        </Button>
        <QrReader base64String={orderBarcodeLabel} />
        {orderSelectedPackageSystem === "B" && (
          <>
            <p
              style={{
                fontSize: "14px",
                textAlign: "center",
                fontWeight: 700,
                color: "var(--btnBgBlue)",
              }}
            >
              Son aşama; ana (bundle) paketin üzerine aşağıdaki etiketi yapıştırın. Etiketi
              yazdırmak veya indirmek için aşağıdaki 'İndir' butonuna tıklayın.
            </p>
            <Button
              className="iyzi-button-blue"
              onClick={() => handlePDFDownload(orderBarcodeTopLabel, "bundle_label")}
            >
              PDF İndir
            </Button>
            <QrReader base64String={orderBarcodeTopLabel} />
          </>
        )}
        <div
          style={{
            width: "100%",
            display: "grid",
            gridTemplateColumns: "repeat(auto-fit, minmax(100px , 1fr))",
            gap: "var(--gap)",
          }}
        >
          <Button onClick={handleComplete} className="iyzi-button-blue">
            Tamamla
          </Button>
          {courierNavigate === "iyziCourier" && (
            <Button onClick={handleNavigate} className="iyzi-button-blue">
              Kurye Kaydına Yönlendir
            </Button>
          )}
        </div>
      </Box>
    </Modal>
  );
};

export default OrderComplete;
