import React, { useEffect, useRef } from "react";
import Chart from "chart.js/auto";

const DashboardGoodsChart = ({ totalGoods }) => {
  const chartRef = useRef(null);
  const data = totalGoods?.totalpercentsparceldatasets?.map((item) => item.percentage);

  useEffect(() => {
    if (
      chartRef.current &&
      totalGoods &&
      totalGoods.labels &&
      totalGoods.totalpercentsparceldatasets
    ) {
      const labels = totalGoods.labels;
      const data = totalGoods.totalpercentsparceldatasets.map((item) => item.percentage);

      const chartData = {
        labels: labels,
        datasets: [
          {
            data: data,
            backgroundColor: totalGoods.totalpercentsparceldatasets.map((item) =>
              getColor(item.service_type)
            ),
          },
        ],
      };
      
      const chartOptions = {
        aspectRatio: 3.5,
        responsive: true,
        plugins: {
          legend: {
            position: "right",
            display: true,
          },
          tooltip: {
            callbacks: {
              label: function (context) {
                const label = context.label || "";
                const value = context.parsed || 0;
                return label + ": " + value.toFixed(2) + "%";
              },
            },
          },
        },
      };

      new Chart(chartRef.current, {
        type: "pie",
        data: chartData,
        options: chartOptions,
      });
    }
  }, [totalGoods]);

  // Service type'e göre renk seçimi
  const getColor = (serviceType) => {
    switch (serviceType) {
      case "E":
        return "#F765A3";
      case "X":
        return "#165BAA";
      case "P":
        return "#A155B9";
      default:
        return "rgba(0, 0, 0, 0.5)";
    }
  };

  // Check if all elements in the data array are zeros
  const areAllZeros = data?.every((value) => value === 0);

  return (
    <div className="pie-chart">
      <div className="pie-chart-header">
        <div className="pie-text-total">Ürünlerin %'lik Dağılımı</div>
      </div>
      {areAllZeros ? (
        <div className="shipment-count-text-total-detailed" style={{
          marginTop: "5px",
          height: "155px"
        }}>
          Gönderi ödemeniz bulunmamaktadır.
        </div>
      ) : (
        <canvas ref={chartRef} />
      )}
    </div>
  );
};

export default DashboardGoodsChart;
