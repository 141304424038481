import { TextField } from "@mui/material";

const FormattedAddress = ({ formattedAddress, setFormattedAddress }) => {
  return (
    <TextField
      size="small"
      label="Adres Satırı"
      value={formattedAddress}
      autoComplete="off"
      onChange={(e) => setFormattedAddress(e.target.value)}
    />
  );
};

export default FormattedAddress;
