import React from 'react'

const ForgotHeadline = () => {
  return (
    <div className='forgot-headline'>
      <h1>Şifremi Unuttum</h1>
      <p>
        Parolanızı yenilememiz için gereken bilgileri kayıtlı olan e-posta adresinize gönderebiliriz.
      </p>
    </div>
  )
}

export default ForgotHeadline