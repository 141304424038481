import { Pagination } from "@mui/material";

const IbansPagination = ({ totalPage, currPage, onPageChange }) => {
  return (
    <Pagination
      count={totalPage}
      page={currPage}
      size="small"
      onChange={onPageChange}
      showFirstButton
      showLastButton
    />
  );
};

export default IbansPagination;
