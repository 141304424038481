import "../../style/myWallet/MyWallet.css";
import { Box, Pagination } from "@mui/material";
import { useInView, animated } from "@react-spring/web";
import React, { useCallback, useEffect } from "react";
import { useRecoilState, useRecoilValue, useSetRecoilState } from "recoil";
import NotificationBar from "../sidebar/NotificationBar";
import { DynamicTitle } from "../../utils/DynamicTitle";
import { ToastContainer } from "react-toastify";
import axios from "../../api/axios";
import { useCookies } from "react-cookie";
import MyWalletNav from "./MyWalletNav";
import {
  balanceCurrentPageState,
  balanceTotalPageState,
  iyziBalanceDataState,
  totalBalanceState,
} from "../../atoms/myWallet";
import MyWalletBalanceTable from "./myWalletBalance/MyWalletiyziBalanceTable";
import WrapperStyle from "../../utils/WrapperStyle";
import MyWalletSearch from "./myWalletBalance/MyWalletSearch";
import Errors from "../../utils/Errors";
import IyziBalanceTitle from "./title/IyziBalanceTitle";
import LocalPrice from "../../utils/LocalPrice";
import Currency from "../../utils/CurrencySymbol";

const MyWalletiyziBalance = React.memo(() => {
  DynamicTitle("iyziBakiyem");

  const [token] = useCookies(["jwt"]);
  const jwt = token.jwt;
  axios.defaults.headers.common["Authorization"] = `Bearer ${jwt}`;

  const totalBalance = useRecoilValue(totalBalanceState);
  const setIyziBalanceData = useSetRecoilState(iyziBalanceDataState);

  const [ref, springs] = useInView(
    () => ({
      from: {
        opacity: 0,
        y: 20,
      },
      to: {
        opacity: 1,
        y: 0,
      },
    }),
    {
      rootMargin: "-10% 0%",
    }
  );

  // Sayfa Fonksiyonları
  const [balanceTotalPage, setBalanceTotalPage] = useRecoilState(balanceTotalPageState);
  const [balanceCurrentPage, setBalanceCurrentPage] = useRecoilState(balanceCurrentPageState);

  const getData = useCallback(() => {
    axios
      .get(`wallet/list`, {
        params: {
          limit: 20,
          page: balanceCurrentPage,
          order_direction: "DESC",
        },
      })
      .then((res) => {
        setIyziBalanceData(res.data.data);
        setBalanceTotalPage(res.data.totalPages);
        setBalanceCurrentPage(res.data.currentPage);
      })
      .catch((error) => Errors(error));
  }, [balanceCurrentPage, setIyziBalanceData, setBalanceTotalPage, setBalanceCurrentPage]);

  const handleBalancePageChange = useCallback(
    (e, page) => {
      axios
        .get(`wallet/list`, {
          params: {
            limit: 20,
            page: page,
            order_direction: "DESC",
          },
        })
        .then((res) => {
          setIyziBalanceData(res.data.data);
          setBalanceTotalPage(res.data.totalPages);
          setBalanceCurrentPage(res.data.currentPage);
        })
        .catch((error) => Errors(error));
    },
    [setIyziBalanceData, setBalanceTotalPage, setBalanceCurrentPage]
  );

  useEffect(() => {
    getData();
  }, [getData]);

  return (
    <section className="my-wallet">
      <NotificationBar />
      <ToastContainer />
      <IyziBalanceTitle />
      <Box className="my-wallet-wrapper" style={WrapperStyle()}>
        <MyWalletNav />
        <animated.div ref={ref} style={springs}>
          <Box className="my-wallet-container">
            <MyWalletSearch />
            <div style={{ display: "flex", justifyContent: "flex-end", alignItems: "center" }}>
              <div
                style={{
                  backgroundColor: "var(--lighterBg)",
                  display: "flex",
                  justifyContent: "flex-end",
                  padding: "20px",
                  borderRadius: "var(--wrapperBorderRadius)",
                  boxShadow: "var(--shadow)",
                  gap: "7px",
                }}
              >
                <span>iyziBakiyem:</span>
                <span style={{ fontWeight: 500, color: "var(--btnBgBlue)" }}>
                  {LocalPrice(totalBalance)}
                  {Currency("TRY")}
                </span>
              </div>
            </div>
            <MyWalletBalanceTable />
            <Pagination
              showFirstButton
              showLastButton
              size="small"
              count={balanceTotalPage}
              page={balanceCurrentPage}
              onChange={handleBalancePageChange}
            />
          </Box>
        </animated.div>
      </Box>
    </section>
  );
});

export default MyWalletiyziBalance;
