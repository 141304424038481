import React, { useState, useEffect, useCallback } from "react";
import { useRecoilValue } from "recoil";
import { ibanListArrayState } from "../../../atoms/profileSettings";
import IbanListItem from "./IbanListItem";
import IbansPagination from "./IbansPagination";

const ITEMS_PER_PAGE = 5; // Sayfa başına gösterilecek öğe sayısı

const IbanList = () => {
  const ibanListArray = useRecoilValue(ibanListArrayState);
  const [currentPage, setCurrentPage] = useState(1);
  const [paginatedData, setPaginatedData] = useState([]);

  const updatePaginatedData = useCallback(
    (page) => {
      const startIndex = (page - 1) * ITEMS_PER_PAGE;
      const endIndex = startIndex + ITEMS_PER_PAGE;
      const paginatedItems = ibanListArray.slice(startIndex, endIndex);
      setPaginatedData(paginatedItems);
    },
    [ibanListArray]
  );

  const handlePageChange = (event, value) => {
    setCurrentPage(value);
  };

  useEffect(() => {
    updatePaginatedData(currentPage);
  }, [ibanListArray, currentPage, updatePaginatedData]);

  return (
    <>
      <div
        className="ibans-display-wrapper"
        style={{
          display: "grid",
          gridTemplateColumns: " repeat(auto-fill, minmax(250px, 1fr))",
          gap: "var(--gap2x)",
          marginTop: "var(--gap2x)",
        }}
      >
        {paginatedData.length === 0 && (
          <div>Kayıtlı hesabınız bulunmamaktadır.</div>
        )}
        {paginatedData?.map((iban, index) => (
          <IbanListItem key={index} iban={iban} />
        ))}
      </div>
      {paginatedData.length !== 0 && (
        <div style={{ marginTop: "10px" }}>
          <IbansPagination
            totalPage={Math.ceil(ibanListArray.length / ITEMS_PER_PAGE)}
            currPage={currentPage}
            onPageChange={handlePageChange}
          />
        </div>
      )}
    </>
  );
};

export default IbanList;
