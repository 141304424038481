import Box from "@mui/material/Box";
import {
  TextField,
  InputAdornment,
  Autocomplete,
  FormControl,
  FormHelperText,
  Button,
} from "@mui/material";
import { useCallback, useState, useEffect } from "react";
import { toast } from "react-toastify";
import SpringIn from "./SpringIn";
import axios from "../../../api/axios";
import DoneOutlinedIcon from "@mui/icons-material/DoneOutlined";
import CloseOutlinedIcon from "@mui/icons-material/CloseOutlined";
import CircularProgress from "@mui/material/CircularProgress";
import { useCookies } from "react-cookie";
import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/style.css";
import InfoOutlinedIcon from "@mui/icons-material/InfoOutlined";
import { validateEmail } from "../../../utils/emailValidation";
import Errors from "../../../utils/Errors";
import CountriesTurkishSorted from "../../../utils/CountriesTurkishSorted";
import { useNavigate } from "react-router-dom";

const DefaultBillAddress = () => {
  const navigate = useNavigate();
  const userInfo = JSON.parse(localStorage.getItem("user"));

  const [token] = useCookies(["jwt"]);
  const jwt = token?.jwt;

  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const header = "Fatura Adresi";

  const [address, setAddress] = useState("");
  const [address2, setAddress2] = useState("");
  const [phone, setPhone] = useState("");
  const [email, setEmail] = useState(userInfo?.user.email);
  const [companyName, setCompanyName] = useState("");
  const [zipCode, setZipCode] = useState("");
  const [state, setState] = useState("");
  const [district, setDistrict] = useState("");
  const [city, setCity] = useState("");
  const [stateCode, setStateCode] = useState("");
  const [selectedCountry, setSelectedCountry] = useState("TR");

  const [loading, setLoading] = useState(false);
  const [zipLoading, setZipLoading] = useState(false);
  const [zipIsDone, setZipIsDone] = useState(null);

  //* Error */

  const [zipCodeError, setZipCodeError] = useState(false);
  const [phoneIsNotValid, setPhoneIsNotValid] = useState(false);

  //* */

  const addressRegisterHandler = async (e) => {
    e.preventDefault();

    const data = {
      //Todo first_name ve last_name gelince parametrelerde contact_name kaldırılacak yerine first_name ve last_name gelecek.
      contact_name: `${firstName} ${lastName}`,
      address_description: header,
      address1: address,
      address2: address2,
      email: email,
      postal_code: zipCode,
      phone: phone,
      town: district,
      city: city,
      state: state,
      country: selectedCountry,
      state_code: stateCode,
      address_type: 1,
    };

    if (phone.length < 6) {
      setPhoneIsNotValid(true);
      return;
    } else {
      setLoading(true);
      await axios
        .post("member-address/add", data, {
          headers: {
            Authorization: `Bearer ${jwt}`,
          },
        })
        .then((response) => {
          toast.success(response.data.message);
          navigate("/profile-settings/general");
          setTimeout(() => window.location.reload(true), 500);
        })
        .catch((err) => Errors(err))
        .finally(() => {
          setPhoneIsNotValid(false);
          setLoading(false);
        });
    }
  };

  const zipCodeHandler = useCallback(async () => {
    if (zipCode.length === 0) {
      setZipIsDone(null);
    } else {
      setZipLoading(true);
      setZipIsDone(null);
      await axios
        .post("get-geo-details", {
          postalcode: zipCode,
          country: selectedCountry,
        })
        .then((res) => {
          setZipLoading(false);
          if (res.data.data.postalcodes.length === 0) {
            setState("");
            setDistrict("");
            setCity("");
            setStateCode("");
            setZipIsDone(false);
            setZipCodeError(false);
          } else if (res.data.data.postalcodes.length > 1) {
            setZipCode("");
            setState("");
            setDistrict("");
            setCity("");
            setStateCode("");
            setZipCodeError(true);
            setZipIsDone(false);
          } else {
            setZipIsDone(true);
            setState(res.data.data.postalcodes[0].placeName);
            setDistrict(
              res.data.data.postalcodes[0].adminName2
                ? res.data.data.postalcodes[0].adminName2
                : res.data.data.postalcodes[0].placeName
            );
            setCity(res.data.data.postalcodes[0].adminName1);
            setStateCode(res.data.data.postalcodes[0].adminCode1);
            setZipCodeError(false);
          }
        })
        .catch((error) => {
          Errors(error);
          setZipLoading(false);
          setZipIsDone(false);
        });
    }
  }, [selectedCountry, zipCode]);

  const [emailError, setEmailError] = useState(false);
  const [isEmailValid, setIsEmailValid] = useState(true);

  const handleEmailChange = (e) => {
    const newEmail = e.target.value;

    setEmail(newEmail);

    if (!newEmail) {
      setIsEmailValid(false);
    } else {
      const isValidEmail = validateEmail(newEmail);
      setIsEmailValid(isValidEmail);
    }
  };

  useEffect(() => {
    setEmailError(!isEmailValid);
  }, [isEmailValid]);

  return (
    <SpringIn>
      <form
        onSubmit={addressRegisterHandler}
        style={{
          display: "grid",
          gap: "var(--gap2x)",
          marginTop: "var(--gap2x)",
        }}
      >
        <TextField
          defaultValue={header}
          placeholder="Fatura Adresi"
          disabled
          className="new-address-header"
          multiline
          size="small"
          required
          label="Başlık"
        />
        <Box className="new-address-modal-wrapper">
          <TextField
            required
            value={firstName}
            onChange={(e) => setFirstName(e.target.value)}
            fullWidth
            size="small"
            label="İsim"
          />
          <TextField
            required
            value={lastName}
            onChange={(e) => setLastName(e.target.value)}
            fullWidth
            size="small"
            label="Soyisim"
          />
          {userInfo?.user?.user_member?.member_type === "Individual" ? (
            ""
          ) : (
            <TextField
              value={companyName}
              onChange={(e) => setCompanyName(e.target.value)}
              required
              fullWidth
              size="small"
              label="Firma Adı"
            />
          )}
          <TextField
            value={email}
            required
            onChange={handleEmailChange}
            error={emailError}
            helperText={emailError && "Geçerli bir email giriniz."}
            size="small"
            label="E-Posta"
            type="email"
            autoComplete="off"
          />
        </Box>
        <TextField
          required
          value={address}
          onChange={(e) => setAddress(e.target.value)}
          maxRows={4}
          inputProps={{ maxLength: 191 }}
          fullWidth
          size="medium"
          label="Adres Satırı"
        />
        <TextField
          value={address2}
          onChange={(e) => setAddress2(e.target.value)}
          maxRows={4}
          inputProps={{ maxLength: 191 }}
          fullWidth
          size="medium"
          label="2.Adres Satırı"
        />
        <Box className="new-address-modal-wrapper">
          <Autocomplete
            id="country-typing-select"
            fullWidth
            size="small"
            options={CountriesTurkishSorted()}
            value={
              CountriesTurkishSorted().find((option) => option.iso_code === selectedCountry) || null
            }
            onChange={(e, val) => {
              if (val) {
                setSelectedCountry(val.iso_code);
              } else {
                setSelectedCountry("");
              }
            }}
            autoHighlight
            openOnFocus={false}
            getOptionLabel={(option) => `${option.country_name_tr}, ${option.country_name_en}`}
            renderOption={(props, option) => (
              <Box component="li" sx={{ "& > img": { mr: 2, flexShrink: 0 } }} {...props}>
                <img
                  loading="lazy"
                  width="20"
                  src={`https://flagcdn.com/w20/${option.iso_code.toLowerCase()}.png`}
                  srcSet={`https://flagcdn.com/w40/${option.iso_code.toLowerCase()}.png 2x`}
                  alt=""
                />
                {option.country_name_tr}, {option.country_name_en}
              </Box>
            )}
            renderInput={(params) => (
              <TextField
                {...params}
                label="Ülke Seçiniz"
                required
                name="unique-country-field"
                value={selectedCountry}
                inputProps={{
                  ...params.inputProps,
                  autoComplete: "off", // disable autocomplete and autofill
                }}
                helperText={
                  <div
                    style={{
                      fontSize: "inherit",
                      color: "var(--info)",
                      backgroundColor: "var(--pastelBlue)",
                      display: "flex",
                      gap: "5px",
                      padding: "5px",
                      borderRadius: "var(--wrapperBorderRadius)",
                      alignItems: "center",
                    }}
                  >
                    <InfoOutlinedIcon />
                    <div
                      style={{
                        fontSize: "inherit",
                        color: "var(--info)",
                      }}
                    >
                      Fatura ülkesini değiştirirseniz posta kodunu tekrar giriniz.
                    </div>
                  </div>
                }
              />
            )}
          />
          <TextField
            required
            value={zipCode}
            onChange={(e) => setZipCode(e.target.value)}
            placeholder="06010"
            onBlur={zipCodeHandler}
            fullWidth
            autoComplete="off"
            size="small"
            label="Posta Kodu"
            error={zipCodeError}
            helperText={
              <div
                style={{
                  fontSize: "inherit",
                  color: "var(--info)",
                  backgroundColor: "var(--pastelBlue)",
                  display: "flex",
                  gap: "5px",
                  padding: "5px",
                  borderRadius: "var(--wrapperBorderRadius)",
                  alignItems: "center",
                }}
              >
                <InfoOutlinedIcon />
                <div style={{ fontSize: "inherit", color: "var(--info)" }}>
                  Eğer posta kodunun yanında{" "}
                  <CloseOutlinedIcon style={{ color: "red", fontSize: "11px" }} /> görseli varsa bu
                  seçeneklerde bulunmadığı anlamına gelir. Semt, İlçe ve Şehir kısmını doldurarak
                  devam etmenizde bir sakınca yoktur.
                </div>
              </div>
            }
            InputProps={
              zipIsDone === true
                ? {
                    endAdornment: (
                      <InputAdornment position="end">
                        <DoneOutlinedIcon style={{ color: "green" }} />
                      </InputAdornment>
                    ),
                  }
                : zipIsDone === false
                ? {
                    endAdornment: (
                      <InputAdornment position="end">
                        <CloseOutlinedIcon style={{ color: "red" }} />
                      </InputAdornment>
                    ),
                  }
                : zipIsDone === null && zipLoading === true
                ? {
                    endAdornment: (
                      <InputAdornment position="end">
                        <CircularProgress style={{ width: "20px", height: "20px" }} />
                      </InputAdornment>
                    ),
                  }
                : {}
            }
          />
          <FormControl size="small" fullWidth>
            <PhoneInput
              country={selectedCountry.toLowerCase()}
              value={phone}
              onChange={(e) => setPhone(e)}
              autoComplete="off"
              onEnterKeyPress={addressRegisterHandler}
              isValid={(value, country) => {
                if (value.match(/12345/)) {
                  return "Invalid value: " + value + ", " + country.name;
                } else if (value.match(/1234/)) {
                  return false;
                } else {
                  return true;
                }
              }}
              inputProps={{
                name: "phone",
                autoFocus: true,
                required: true,
                id: "ind-phone-input",
                style: {
                  height: "100%",
                  width: "100%",
                  minHeight: "40px",
                  maxHeight: "40px",
                  borderColor: phoneIsNotValid ? "var(--error)" : "var(--inputBorderColor)",
                },
              }}
            />
            {phoneIsNotValid && (
              <FormHelperText sx={{ color: "var(--error)" }}>
                Telefon numaranızı giriniz.
              </FormHelperText>
            )}
          </FormControl>
        </Box>
        <Box className="new-address-modal-wrapper">
          <TextField
            required
            value={state}
            onChange={(e) => setState(e.target.value)}
            fullWidth
            size="small"
            label="Semt / Eyalet"
          />
          <TextField
            value={district}
            onChange={(e) => setDistrict(e.target.value)}
            fullWidth
            size="small"
            label="İlçe"
          />
          <TextField
            required
            value={city}
            onChange={(e) => setCity(e.target.value)}
            fullWidth
            size="small"
            label="Şehir"
          />
          <TextField
            required
            value={stateCode}
            onChange={(e) => setStateCode(e.target.value)}
            fullWidth
            size="small"
            autoComplete="off"
            label="Eyalet Kodu"
          />
        </Box>
        <Box className="new-address-register">
          {loading ? (
            <CircularProgress />
          ) : (
            <Button
              className="iyzi-button-blue"
              type="submit"
              sx={{
                height: "35px",
              }}
            >
              Kaydet
            </Button>
          )}
        </Box>
      </form>
    </SpringIn>
  );
};

export default DefaultBillAddress;
