import { toast } from "react-toastify";

const Errors = (error) => {
  if (error.response.status === 403) {
    localStorage.removeItem("user");
    document.cookie = `jwt=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/;`;
    setTimeout(() => {
      window.location.reload();
    }, 2000);
  }

  if (error.response.data?.errors) {
    return Object.values(error.response.data?.errors)?.map((messages) =>
      messages?.forEach((message) => toast.error(message))
    );
  } else if (error.response.data?.message) return toast.error(error.response.data?.message);
  else
    return toast.error(
      "Bir hata oluştu, lütfen sayfayı yenileyin! Hatayı almaya devam ediyorsanız lütfen sistem yöneticisiyle iletişime geçiniz.",
      { autoClose: 8000 }
    );
};

export default Errors;
