import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
} from "@mui/material";

const DifferentMailDialog = ({ open, setOpen, submit }) => {
  const handleClose = () => {
    setOpen(false);
  };
  const handleApprove = () => {
    submit();
    setOpen(false);
  };

  return (
    <Dialog
      open={open}
      onClose={handleClose}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
    >
      <DialogContent>
        <DialogContentText style={{ color: "var(--text)" }} id="alert-dialog-description">
          Bu işlemi onaylamanız halinde, belirttiğiniz yeni e-posta adresinize bir onay mesajı
          gönderilecektir. Yeni e-posta adresinizin onaylanmasının ardından, hesabınıza bu yeni
          adres üzerinden erişebileceksiniz. Bu işlemi onaylıyor musunuz?
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button className="iyzi-button-gray" onClick={handleClose}>
          Hayır
        </Button>
        <Button className="iyzi-button-blue" onClick={handleApprove} autoFocus>
          Evet
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default DifferentMailDialog;
