import countriesDetailed from "../json/countriesDetailed.json";

const CountriesTurkishSorted = () => {
  const sortedCountries = countriesDetailed.sort((a, b) => {
    const nameA = a.country_name_tr.toUpperCase(); // Büyük harfe çevirerek kıyaslama
    const nameB = b.country_name_tr.toUpperCase(); // Büyük harfe çevirerek kıyaslama

    if (nameA < nameB) {
      return -1; // nameA, nameB'den önce gelmeli
    }
    if (nameA > nameB) {
      return 1; // nameA, nameB'den sonra gelmeli
    }

    return 0; // Eşitlik durumu
  });

  return sortedCountries;
};

export default CountriesTurkishSorted;
