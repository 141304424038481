import { Box } from "@mui/material";
import ControlPointOutlinedIcon from "@mui/icons-material/ControlPointOutlined";
import { useRecoilState } from "recoil";
import { startIbanAnimationState } from "../../../atoms/profileSettings";
const AddIbans = () => {
  const [, setStartIbanAnimation] = useRecoilState(startIbanAnimationState);

  return (
    <Box className="card-adder-wrapper">
      <button
        onClick={() => setStartIbanAnimation(true)}
        className="card-adder"
      >
        <ControlPointOutlinedIcon />
        Yeni Hesap Ekle
      </button>
    </Box>
  );
};
export default AddIbans;
