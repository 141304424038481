function toTurkishUpperCase(str) {
  const trMapToLower = {
    i: "İ",
    ş: "Ş",
    ğ: "Ğ",
    ü: "Ü",
    ö: "Ö",
    ç: "Ç",
    ı: "I",
  };

  const trMapToUpper = {
    İ: "İ",
    Ş: "Ş",
    Ğ: "Ğ",
    Ü: "Ü",
    Ö: "Ö",
    Ç: "Ç",
    I: "I",
  };

  return str
    .replace(/[iışğüöçı]/g, (match) => trMapToLower[match] || match)
    .replace(/[İŞĞÜÖÇI]/g, (match) => trMapToUpper[match] || match)
    .toUpperCase();
}

export default toTurkishUpperCase;
