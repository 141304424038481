import { Avatar, Box, Button, Divider, CircularProgress } from "@mui/material";
import {useEffect, useState} from "react";
import FlightTakeoffOutlinedIcon from "@mui/icons-material/FlightTakeoffOutlined";
import FlightLandOutlinedIcon from "@mui/icons-material/FlightLandOutlined";
import { CircleFlag } from "react-circle-flags";
import LiveHelpOutlinedIcon from "@mui/icons-material/LiveHelpOutlined";
import shipmentLogo_0 from "../../img/myShipments/gonderilerim_0.svg";
import shipmentLogo_1 from "../../img/myShipments/gonderilerim_1.svg";
import shipmentLogo_2 from "../../img/myShipments/gonderilerim_2.svg";
import shipmentLogo_4 from "../../img/myShipments/gonderilerim_4.svg";
import shipmentLogo_5 from "../../img/myShipments/gonderilerim_5.svg";
import countries from "../../json/countries.json";
import { useRecoilState } from "recoil";
import ReturnListItemLinks from "./ReturnListItemLinks";
import {
  abroadPriceInformationOpenState,
  deductionKdvCostTryState,
  parcelReturnOpenState,
  selectedParcelReturnState,
  returnShipmentInformationOpenState
} from "../../atoms/returnShipment";
import axios from "../../api/axios";
import { toast } from "react-toastify";
import { Link } from "react-router-dom";
import Errors from "../../utils/Errors";
import FormatHeadlineText from "../../utils/FormatHeadlineText";

const ReturnShipmentListItem = ({ shipment, keys, loadingReturnBtn }) => {
  const [, setSelectedParcelReturn] = useRecoilState(selectedParcelReturnState);
  const [, setParcelReturnOpen] = useRecoilState(parcelReturnOpenState);
  const [abroadPriceInformationOpen, setAbroadPriceInformationOpen] = useRecoilState(abroadPriceInformationOpenState);
  const [returnShipmentInformationOpen, setReturnShipmentInformationOpen] = useRecoilState(returnShipmentInformationOpenState);
  const [, setDeductionKdvCostTry] = useRecoilState(deductionKdvCostTryState);

  const getPrice = async () => {
    try {
      const response = await axios.get(`parcel-return/price/${shipment.id}?delivery_type=gge`);
      if (response.status === 200) {
        setDeductionKdvCostTry(Number(response.data.data.deduction_kdv_cost_try));
        toast.success(response.message, { autoClose: 500 });
      } else if (response.status === 400) {
        toast.error(response.message, { autoClose: 500 });
      }
    } catch (error) {
      Errors(error);
    }
  };
  const handleReturn = async () => {
    setSelectedParcelReturn(shipment);
    if (Number(shipment.get_parcel.status) === 2.29 && Number(shipment.return_abroad_paid) === 0) {
      getPrice();
      setAbroadPriceInformationOpen(true);
    } else setParcelReturnOpen(true);
  };
  
  return (
    <Box key={shipment.id} className="returnShipment-list-wrapper">
      <Box className="list-steps">
        <Box className="steps-where-to">
          {/*          <Box className="to-integration">
            <div style={{ visibility: "hidden" }}>Etsy</div>
            <div style={{ visibility: "hidden" }}>2410218693</div>
          </Box>*/}
          <Box className="to-plane">
            {countries.find((e) => e.code === shipment.get_parcel.sender_country).label === "Turkey"
              ? "TÜRKİYE"
              : countries
                .find((e) => e.code === shipment.get_parcel.sender_country)
                .label.toUpperCase()}
            <div className="plane-flag">
              <CircleFlag
                countryCode={countries
                  .find((e) => e.code === shipment.get_parcel.sender_country)
                  .code.toLowerCase()}
                height="24"
              />
              <FlightTakeoffOutlinedIcon id="take-off" />
            </div>
          </Box>
          <Box sx={{ display: "grid", justifyContent: "center" }}>
            <Box
              sx={{
                fontSize: "13px",
                color: "var(--priceText)",
                textAlign: "center",
                marginBottom: "3px",
              }}
            >
              GİDİŞ AWB
            </Box>
            <Box sx={{ minWidth: "120px" }} className="to-tracking">
              {shipment.get_parcel.tracking_awb}
            </Box>
          </Box>
          <Box className="to-plane">
            {countries
              .find((e) => e.code === shipment.get_parcel.receiver_country)
              .label.toUpperCase()}
            <div className="plane-flag">
              <FlightLandOutlinedIcon id="land" />
              <CircleFlag
                countryCode={countries
                  .find((e) => e.code === shipment.get_parcel.receiver_country)
                  .code.toLowerCase()}
                height="24"
              />
            </div>
          </Box>
        </Box>
        <Box className="stepper-wrapper-css">
          <div className="step-wrapper-css">
            <div
              className="step-css"
              style={
                shipment.get_parcel.status > "0"
                  ? { backgroundColor: "var(--shipmentRed)" }
                  : { backgroundColor: "var(--shipmentLight)" }
              }
            >
              <img src={shipmentLogo_0} className="step-logo-css" alt="Gönderi 1.Aşama Görseli" />
            </div>
          </div>
          <div className="step-wrapper-css">
            <div
              className="step-css"
              style={
                shipment.get_parcel.status > "1"
                  ? { backgroundColor: "var(--shipmentRed)" }
                  : { backgroundColor: "var(--shipmentLight)" }
              }
            >
              <img src={shipmentLogo_1} className="step-logo-css" alt="Gönderi 2.Aşama Görseli" />
            </div>
          </div>
          <div className="step-wrapper-css">
            <div
              className="step-css"
              style={
                shipment.get_parcel.status > "2"
                  ? { backgroundColor: "var(--shipmentRed)" }
                  : { backgroundColor: "var(--shipmentLight)" }
              }
            >
              <img src={shipmentLogo_2} className="step-logo-css" alt="Gönderi 3.Aşama Görseli" />
            </div>
          </div>
          <div className="step-wrapper-css">
            <div
              className="step-css"
              style={
                shipment.get_parcel.status > "3" ||
                  shipment.get_parcel.status === "2.28" ||
                  shipment.get_parcel.status === "2.29"
                  ? { backgroundColor: "var(--shipmentRed)" }
                  : { backgroundColor: "var(--shipmentLight)" }
              }
            >
              <Avatar
                className="step-shipment-logo-css"
                src={`/images/${shipment.get_parcel?.get_parcel_supplier?.supplier_logo}`}
              />
            </div>
          </div>
          <div className="step-wrapper-css">
            <div
              className="step-css"
              style={
                shipment.get_parcel.status > "4" || shipment.get_parcel.status === "2.29"
                  ? { backgroundColor: "var(--shipmentRed)" }
                  : { backgroundColor: "var(--shipmentLight)" }
              }
            >
              <img src={shipmentLogo_4} className="step-logo-css" alt="Gönderi 5.Aşama Görseli" />
            </div>
          </div>
          <div className="step-wrapper-css">
            <div
              className="step-css"
              style={
                shipment.get_parcel.status > "5"
                  ? { backgroundColor: "var(--shipmentRed)" }
                  : { backgroundColor: "var(--shipmentLight)" }
              }
            >
              <img src={shipmentLogo_5} className="step-logo-css" alt="Gönderi 6.Aşama Görseli" />
            </div>
          </div>
        </Box>
        <Box className="steps-where-to">
          {/*          <Box className="to-integration">
            <div style={{ visibility: "hidden" }}>Etsy</div>
            <div style={{ visibility: "hidden" }}>2410218693</div>
          </Box>*/}
          <Box className="to-plane">
            {countries.find((e) => e.code === shipment.get_parcel.sender_country).label === "Turkey"
              ? "TÜRKİYE"
              : countries
                .find((e) => e.code === shipment.get_parcel.sender_country)
                .label.toUpperCase()}
            <div className="plane-flag">
              <CircleFlag
                countryCode={countries
                  .find((e) => e.code === shipment.get_parcel.sender_country)
                  .code.toLowerCase()}
                height="24"
              />
              <FlightLandOutlinedIcon sx={{ transform: "rotateY(180deg)" }} id="land" />
            </div>
          </Box>
          <Box sx={{ display: "grid", justifyContent: "center" }}>
            <Box
              sx={{
                fontSize: "13px",
                color: "var(--priceText)",
                textAlign: "center",
                marginBottom: "3px",
              }}
            >
              İADE AWB
            </Box>
            <Box sx={{ minWidth: "120px" }} className="to-tracking">
              {shipment.tracking_awb}
            </Box>
          </Box>
          <Box className="to-plane">
            {countries
              .find((e) => e.code === shipment.get_parcel.receiver_country)
              .label.toUpperCase()}
            <div className="plane-flag">
              <FlightTakeoffOutlinedIcon sx={{ transform: "rotateY(180deg)" }} id="take-off" />
              <CircleFlag
                countryCode={countries
                  .find((e) => e.code === shipment.get_parcel.receiver_country)
                  .code.toLowerCase()}
                height="24"
              />
            </div>
          </Box>
        </Box>
        <Box className="steps-info">
          <Box className="steps-info-wrapper info-member">
            <div className="info-top">Üye Tarafından Beyan Edilen Kilo</div>
            <div className="info-bottom">
              <div className="info-bottom-subline">
                NET KG
                <div className="info-bottom-subline-number">
                  {shipment.get_parcel.get_parcel_dimensions
                    .filter((e) => e.status === "1")
                    .reduce((acc, val) => Number(acc) + Number(val.total_gw), 0)
                    .toFixed(2)}
                </div>
              </div>
              <div className="info-bottom-subline">
                DESİ
                <div className="info-bottom-subline-number">
                  {shipment.get_parcel.get_parcel_dimensions
                    .filter((e) => e.status === "1")
                    .reduce((acc, val) => Number(acc) + Number(val.total_vw), 0)
                    .toFixed(2)}
                </div>
              </div>
              <div className="info-bottom-subline">
                NAVLUN KG
                <div className="info-bottom-subline-number">
                  {shipment.get_parcel.get_parcel_dimensions
                    .filter((e) => e.status === "1")
                    .reduce((acc, val) => Number(acc) + Number(val.total_cw), 0)
                    .toFixed(2)}
                </div>
              </div>
            </div>
          </Box>
          {Number(shipment.return_paid) === 0 ? (
            <Box
              sx={{
                display: "flex",
                flexDirection: "column",
              }}
              id="integer_done"
              className="steps-info-done"
            >
              {/*<span className="steps-info-done-price">
                {shipment.return_price}
                {Currency(shipment.currency)}
              </span>
              <Button
                onClick={handlePayment}
                className="steps-info-done-button"
              >
                "Ödeme Yap"
              </Button>*/}
              <div className="steps-info-done-button">
                İade İşlemi Yapılması Bekleniyor
              </div>
              <div
                style={{
                  color: "var(--priceText)",
                  backgroundColor: "var(--background)",
                  padding: "10px",
                  borderRadius: "var(--wrapperBorderRadius)",
                  boxShadow: "var(--shadow)",
                  fontSize: "12px",
                }}
              >
                Güncel:{" "}
                <span style={{ fontWeight: 600, fontSize: "inherit" }}>
                  {FormatHeadlineText(shipment?.get_parcel?.status_name)}
                </span>
              </div>
            </Box>
          ) : (
            <Box
              sx={{
                display: "flex",
                flexDirection: "column",
              }}
              id="integer_done"
              className="steps-info-done"
            >
              {/*<span className="steps-info-done-price paid">
                {shipment.return_price}
                {Currency(shipment.currency)}
              </span>*/}
              <div className="steps-info-done-button paid">
                İade İşlemi Tamamlandı
              </div>
              <div
                style={{
                  color: "var(--priceText)",
                  backgroundColor: "var(--background)",
                  padding: "10px",
                  borderRadius: "var(--wrapperBorderRadius)",
                  boxShadow: "var(--shadow)",
                  fontSize: "12px",
                }}
              >
                Güncel:{" "}
                <span style={{ fontWeight: 600, fontSize: "inherit" }}>
                  {FormatHeadlineText(shipment?.get_parcel?.status_name)}
                </span>
              </div>
            </Box>
          )}
          <Box className="steps-info-wrapper info-system">
            <div className="info-top">iyziTeam Tarafından Beyan Edilen Kilo</div>
            <div className="info-bottom">
              <div className="info-bottom-subline">
                NET KG
                <div className="info-bottom-subline-number">
                  {shipment.get_parcel.get_parcel_dimensions
                    .filter((e) => e.status === "2")
                    .reduce((acc, val) => Number(acc) + Number(val.total_gw), 0)
                    .toFixed(2)}
                </div>
              </div>
              <div className="info-bottom-subline">
                DESİ
                <div className="info-bottom-subline-number">
                  {shipment.get_parcel.get_parcel_dimensions
                    .filter((e) => e.status === "2")
                    .reduce((acc, val) => Number(acc) + Number(val.total_vw), 0)
                    .toFixed(2)}
                </div>
              </div>
              <div className="info-bottom-subline">
                NAVLUN KG
                <div className="info-bottom-subline-number">
                  {shipment.get_parcel.get_parcel_dimensions
                    .filter((e) => e.status === "2")
                    .reduce((acc, val) => Number(acc) + Number(val.total_cw), 0)
                    .toFixed(2)}
                </div>
              </div>
            </div>
          </Box>
        </Box>
      </Box>
      <Divider
        sx={{
          width: "1px",
          backgroundColor: "var(--phoneDigitBg)",
          margin: "0 10px",
        }}
        className="steps-divider"
      />
      <Box className="list-links-wrapper">
        <div className="list-links-container">
          {shipment.return_reason.length > 0 && (
            <Box sx={{ display: "grid", justifyContent: "center" }}>
              <Box sx={{ fontSize: "13px", color: "var(--priceText)", textAlign: "center", marginBottom: "3px" }}>
                İade Nedeni
              </Box>
              <Box sx={{ minWidth: "120px", textAlign: "center" }}>
                {shipment.return_reason}
              </Box>
            </Box>
          )}
          <h6 style={{ fontSize: "13px", textAlign: "center" }}>
            {shipment.get_parcel.parcel_ref_no}
          </h6>
          <ReturnListItemLinks shipment={shipment} />
          {loadingReturnBtn ? (
            <Box
              sx={{
                width: "148px",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <CircularProgress
                sx={{ width: "24px !important", height: "24px !important" }}
              />
            </Box>
          ) : (
            <Button
              className="list-links-help"
              sx={{
                backgroundColor:
                  Number(shipment.return_paid) === 0
                    ? "var(--shipmentRed) !important"
                    : "var(--lightRosy) !important",
              }}
              onClick={() => handleReturn(shipment)}
              disabled={Number(shipment.return_paid) === 1}
            >
              <span>İade Et / Yeni Yük oluştur</span>
            </Button>
          )}

          <Link
            className="list-links-help"
            style={{
              padding: "10px",
              borderRadius: "var(--wrapperBorderRadius)",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              textDecoration: "none",
            }}
            to={`/support?awb=${shipment.get_parcel.parcel_ref_no}`}
          >
            <LiveHelpOutlinedIcon />
            <span>Destek Talebi</span>
          </Link>
        </div>
      </Box>
    </Box>
  );
};

export default ReturnShipmentListItem;
