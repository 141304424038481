import { TextField } from "@mui/material";
import { useRecoilState } from "recoil";
import { nameState } from "../../../../atoms/membership";

const ProfileName = ({ disabled }) => {
  const [name, setName] = useRecoilState(nameState);

  const handleChange = (e) => {
    const value = e.target.value;
    setName(value);
  };

  return (
    <TextField
      disabled={disabled()}
      required
      label="İsim"
      size="small"
      value={name}
      onChange={handleChange}
    />
  );
};

export default ProfileName;
