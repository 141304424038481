import { Button, Divider, Modal } from "@mui/material";
import { useCallback } from "react";
import { useRecoilState } from "recoil";
import { docOrPackModalState } from "../../../atoms/createShipment";
import Inventory2OutlinedIcon from "@mui/icons-material/Inventory2Outlined";
import SummarizeOutlinedIcon from "@mui/icons-material/SummarizeOutlined";

const DocOrPack = () => {
  const [docOrPackModal, setDocOrPackModal] = useRecoilState(docOrPackModalState);

  const handleClose = useCallback(() => {
    setDocOrPackModal(false);
  }, [setDocOrPackModal]);
  return (
    <Modal
      open={docOrPackModal}
      onClose={handleClose}
      sx={{ display: "flex", justifyContent: "center", alignItems: "center" }}
    >
      <div
        style={{
          backgroundColor: "var(--lighterBg)",
          display: "flex",
          flexDirection: "column",
          gap: "var(--gap)",
          padding: "20px",
          margin: "20px",
          borderRadius: "var(--inputBorderRadius)",
          maxHeight: "90vh",
          overFlowY: "auto",
        }}
      >
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            gap: "var(--gap)",
          }}
        >
          <h6
            style={{
              display: "flex",
              gap: "5px",
              alignItems: "center",
              color: "var(--info)",
            }}
          >
            <SummarizeOutlinedIcon /> Döküman
          </h6>
          <span>
            Gönderiniz evrak, basılı fotoğraf, katalog vb döküman sınıfındaysa, gönderilerinizi
            Döküman seçerek ilerleyebilirsiniz.
          </span>
          <span>
            Zarf içine sığabilen küçük değerli eşyalar (yüzük, takı, usb bellek vb.) için Döküman
            seçilmez.
          </span>
        </div>
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            gap: "var(--gap)",
            marginTop: "var(--gap2x)",
          }}
        >
          <h6
            style={{
              display: "flex",
              gap: "5px",
              alignItems: "center",
              color: "var(--info)",
            }}
          >
            <Inventory2OutlinedIcon /> Paket
          </h6>
          <span>
            Zarf/Flyer olmayan her gönderi pakettir. Yükseklik ölçüsü 1cm üstünde olması, gönderiyi
            zart statüsünden çıkarır.
          </span>
        </div>
        <Divider />
        <Button onClick={handleClose} className="iyzi-button">
          Kapat
        </Button>
      </div>
    </Modal>
  );
};

export default DocOrPack;
