import React from "react";
import { Button } from "@mui/material";
import { useNavigate } from "react-router-dom";

const JoinIyziTeam = () => {
  const navigate = useNavigate();

  const navigateTeamPageHandler = () => {
    navigate("/iyziteam");
  };

  const navigateContactPageHandler = () => {
    navigate("/iletisim");
  };
  return (
    <section className="main-info">
      <div className="info-container">
        <div className="info">
          <article>
            <h2>
              E-İhracatın Geleceğini Şekillendiriyor, Sizinle Birlikte
              Büyüyoruz: iyziTeam!
            </h2>
            <p>
              Birlikte büyümek, birlikte başarmak adına iyziTeam olarak; ileri
              teknolojimiz, sektörel bilgimiz, uzun yıllara dayanan tecrübemiz
              ve süper güçlerimiz ile her adımda yanınızdayız. E-ihracatta
              sadece bir ekip değil, sizinle beraber bir vizyonun parçası
              oluyoruz!
            </p>
            <div className="info-buttons">
              <Button
                sx={{
                  padding: " 9px 14px",
                  minWidth: "200px",
                  fontWeight: 700,
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  textDecoration: "none",
                  height: " 49.927px",
                  gap: "10px",
                  fontSize: "14px",
                  lineHeight: "18px",
                  cursor: "pointer",
                  zIndex: "9",
                }}
                className="iyzi-button-blue"
                onClick={navigateTeamPageHandler}
              >
                Biz Kimiz?
              </Button>
              <Button
                sx={{
                  padding: " 9px 14px",
                  minWidth: "200px",
                  fontWeight: 700,
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  textDecoration: "none",
                  height: " 49.927px",
                  gap: "10px",
                  fontSize: "14px",
                  lineHeight: "18px",
                  cursor: "pointer",
                  zIndex: "9",
                }}
                className="iyzi-button-white"
                onClick={navigateContactPageHandler}
              >
                iyziTeam’e Katıl
              </Button>
            </div>
          </article>
        </div>
        <div className="info-image">
          <img
            src="/images/home/iyzi-team.svg"
            alt="Kargo tekliflerini kolayca karşılaştırın, zamandan tasarruf edin"
            loading="lazy"
          />
        </div>
      </div>
    </section>
  );
};

export default JoinIyziTeam;
