import { Box, Modal, ThemeProvider, Typography, createTheme } from "@mui/material";
import { useRecoilState, useRecoilValue } from "recoil";
import { useCallback } from "react";
import axios from "../../../api/axios";
import { myRefundDocModalState, selectedRefundItemState } from "../../../atoms/myWallet";
import MessageTime from "../../../utils/MessageTime";
import Errors from "../../../utils/Errors";

const theme = createTheme({
  components: {
    MuiModal: {
      styleOverrides: {
        root: {
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          padding: "20px",
        },
      },
    },
    MuiOutlinedInput: {
      styleOverrides: {
        root: {
          borderRadius: "8px",
        },
      },
    },
  },
});

const MyRefundDocModal = () => {
  const [myRefundDocModal, setMyRefundDocModal] = useRecoilState(myRefundDocModalState);
  const selectedRefundItem = useRecoilValue(selectedRefundItemState);
  const items = selectedRefundItem?.get_files;

  const handleClose = useCallback(() => {
    setMyRefundDocModal(false);
  }, [setMyRefundDocModal]);

  const getFile = (document) => {
    axios
      .get(`document/get-file/${document}`, { responseType: "arraybuffer" })
      .then((response) => {
        let blob = new Blob([response.data], {
          type: response.headers["content-type"],
        });
        let image = window.URL.createObjectURL(blob);

        window.open(image, "_blank");
      })
      .catch((error) => Errors(error));
  };

  return (
    <ThemeProvider theme={theme}>
      <Modal open={myRefundDocModal} onClose={handleClose}>
        <Box
          className="my-wallet-arrangement-bill-modal"
          style={{
            maxHeight: "90vh",
            overflowY: "auto",
          }}
        >
          <Typography className="my-wallet-arrangement-bill-modal-headline" variant="h6">
            Dosyalar
          </Typography>
          <Box>
            {items?.length !== 0 && (
              <Box
                sx={{
                  color: "var(--priceText)",
                  fontWeight: 700,
                  fontSize: "14px",
                  marginBottom: "20px",
                  marginTop: "20px",
                  paddingLeft: "8px",
                }}
              >
                Yüklenen Dosyalar - {items?.length}
              </Box>
            )}
            {items?.length !== 0 &&
              items?.map((document, key) => (
                <Box
                  sx={{
                    border: "1px solid var(--phoneDigitBg)",
                    marginBottom: "10px",
                    padding: "10px",
                    borderRadius: "var(--wrapperBorderRadius)",
                    fontSize: "13px",
                    cursor: "pointer",
                    color: "var(--priceText)",
                    transition: "all 150ms linear",
                    ":hover": {
                      backgroundColor: "var(--pastelBlue)",
                      transition: "all 150ms linear",
                    },
                  }}
                  key={document.id}
                  onClick={() => getFile(document.file_path.replace(/\//g, "-"))}
                >
                  <span
                    style={{
                      fontSize: "13px",
                      color: "var(--priceText)",
                      fontWeight: "700",
                    }}
                  >
                    {key + 1}
                  </span>{" "}
                  - {document.file_name} / {MessageTime(document.created_at)}
                </Box>
              ))}
          </Box>
        </Box>
      </Modal>
    </ThemeProvider>
  );
};

export default MyRefundDocModal;
