import { Box, Button, createTheme, InputAdornment, TextField, ThemeProvider } from "@mui/material";
import SearchOutlinedIcon from "@mui/icons-material/SearchOutlined";
import { useCallback, useState } from "react";
import axios from "../../../api/axios";
import { useRecoilState } from "recoil";
import {
  myBillsCurrentPageState,
  myBillsDataState,
  myBillsTotalPageState,
} from "../../../atoms/myWallet";
import Errors from "../../../utils/Errors";

const theme = createTheme({
  components: {
    MuiOutlinedInput: {
      styleOverrides: {
        root: {
          borderRadius: "10px",
        },
      },
    },
  },
});

const BillsSearch = () => {
  const [, setLoading] = useState(false);
  const [searchParam, setSearchParam] = useState("");

  const [, setMyBillsTotalPage] = useRecoilState(myBillsTotalPageState);
  const [, setMyBillsCurrentPage] = useRecoilState(myBillsCurrentPageState);
  const [, setMyBillsData] = useRecoilState(myBillsDataState);

  const handleSearch = useCallback(() => {
    axios
      .get(`invoice`, {
        params: {
          page: 1,
          limit: 100,
          order_direction: "DESC",
          search: searchParam,
        },
      })
      .then((resp) => {
        setMyBillsData(resp.data.data);
        setMyBillsCurrentPage(resp.data.currentPage);
        setMyBillsTotalPage(resp.data.totalPages);
      })
      .catch((error) => {
        Errors(error);
      })
      .finally(() => {
        setLoading(false);
      });
  }, [setMyBillsData, searchParam, setMyBillsCurrentPage, setMyBillsTotalPage]);

  const handleClear = useCallback(() => {
    setSearchParam("");
    axios
      .get(`invoice`, {
        params: {
          page: 1,
          limit: 100,
          order_direction: "DESC",
        },
      })
      .then((resp) => {
        setMyBillsData(resp.data.data);
        setMyBillsCurrentPage(resp.data.currentPage);
        setMyBillsTotalPage(resp.data.totalPages);
      })
      .catch((error) => {
        Errors(error);
      })
      .finally(() => setLoading(false));
  }, [setMyBillsData, setMyBillsCurrentPage, setMyBillsTotalPage]);

  return (
    <ThemeProvider theme={theme}>
      <Box
        sx={{
          backgroundColor: "var(--lighterBg)",
          padding: "20px",
          borderRadius: "var(--inputBorderRadius)",
          boxShadow: "0px 2px 10px rgba(0, 0, 0, 0.1)",
          display: "flex",
          gap: "var(--gap)",
        }}
      >
        <TextField
          size="small"
          fullWidth
          label="Arama"
          placeholder="Fatura No, Fatura Tarihi, Fatura Açıklaması, Konşimento Numarası"
          autoComplete="off"
          value={searchParam}
          onChange={(e) => setSearchParam(e.target.value)}
          onKeyDown={(e) => {
            if (e.key === "Enter") {
              handleSearch();
            } else if (e.key === "Escape") {
              handleClear();
            }
          }}
          InputProps={{
            startAdornment: (
              <InputAdornment position="start">
                <SearchOutlinedIcon />
              </InputAdornment>
            ),
          }}
        />
        <Button onClick={handleSearch} className="iyzi-button">
          Ara
        </Button>
        <Button sx={{ minWidth: "90px" }} className="iyzi-button">
          Dışarı Aktar
        </Button>
      </Box>
    </ThemeProvider>
  );
};

export default BillsSearch;
