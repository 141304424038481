import "../../style/myWallet/MyWallet.css";
import { Box, Pagination } from "@mui/material";
import { useInView, animated } from "@react-spring/web";
import { useCallback, useEffect, useState } from "react";
import { useRecoilState, useSetRecoilState } from "recoil";
import NotificationBar from "../../components/sidebar/NotificationBar";
import { DynamicTitle } from "../../utils/DynamicTitle";
import { ToastContainer } from "react-toastify";
import axios from "../../api/axios";
import { useCookies } from "react-cookie";
import MyWalletNav from "../../components/myWallet/MyWalletNav";
import BillsSearch from "./myWalletBills/BillsSearch";
import BillsTable from "./myWalletBills/BillsTable";
import {
  myBillsCurrentPageState,
  myBillsDataState,
  myBillsTotalPageState,
} from "../../atoms/myWallet";
import WrapperStyle from "../../utils/WrapperStyle";
import miniLogo from "../../img/logos/logo_white_mini_loading.svg";
import BillsKonsimentoModal from "./myWalletBills/BillsKonsimentoModal";
import BillsFileModal from "./myWalletBills/BillsFileModal";
import Errors from "../../utils/Errors";
import BillsTitle from "./title/BillsTitle";

const MyWalletBills = () => {
  DynamicTitle("Faturalarım");

  const [token] = useCookies(["jwt"]);
  const jwt = token.jwt;
  axios.defaults.headers.common["Authorization"] = `Bearer ${jwt}`;

  const [loading, setLoading] = useState(false);

  const [ref, springs] = useInView(
    () => ({
      from: {
        opacity: 0,
        y: 20,
      },
      to: {
        opacity: 1,
        y: 0,
      },
    }),
    {
      rootMargin: "-10% 0%",
    }
  );

  // Sayfa Fonksiyonları
  const [myBillsTotalPage, setMyBillsTotalPage] = useRecoilState(myBillsTotalPageState);
  const [myBillsCurrentPage, setMyBillsCurrentPage] = useRecoilState(myBillsCurrentPageState);
  const setMyBillsData = useSetRecoilState(myBillsDataState);

  const getData = useCallback(() => {
    setLoading(true);
    axios
      .get(`invoice`, {
        params: {
          page: 1,
          limit: 100,
          order_direction: "DESC",
          list_type: "initial",
        },
      })
      .then((resp) => {
        setMyBillsData(resp.data.data);
        setMyBillsCurrentPage(resp.data.currentPage);
        setMyBillsTotalPage(resp.data.totalPages);
        setLoading(false);
      })
      .catch((error) => {
        Errors(error);
        setLoading(false);
      });
  }, [setMyBillsData, setMyBillsCurrentPage, setMyBillsTotalPage]);

  const handleMyBillsPageChange = useCallback(
    (e, page) => {
      setLoading(true);
      axios
        .get(`invoice`, {
          params: {
            page: page,
            limit: 100,
            order_direction: "DESC",
            list_type: "initial",
          },
        })
        .then((resp) => {
          setMyBillsData(resp.data.data);
          setMyBillsCurrentPage(resp.data.currentPage);
          setMyBillsTotalPage(resp.data.totalPages);
          setLoading(false);
        })
        .catch((error) => {
          Errors(error);
          setLoading(false);
        });
    },
    [setMyBillsData, setMyBillsCurrentPage, setMyBillsTotalPage]
  );

  useEffect(() => {
    getData();
  }, [getData]);

  return (
    <section className="my-wallet">
      <NotificationBar />
      <ToastContainer />
      <BillsKonsimentoModal />
      <BillsFileModal />
      <BillsTitle />
      <Box className="my-wallet-wrapper" style={WrapperStyle()}>
        <MyWalletNav />
        {loading ? (
          <div
            style={{
              width: "100%",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              minHeight: "500px",
            }}
          >
            <img className="loading-logo" src={miniLogo} alt="" />
          </div>
        ) : (
          <animated.div ref={ref} style={springs}>
            <Box className="my-wallet-container">
              <BillsSearch />
              {myBillsTotalPage > 1 && (
                <Pagination
                  showFirstButton
                  showLastButton
                  size="small"
                  count={myBillsTotalPage}
                  page={myBillsCurrentPage}
                  onChange={handleMyBillsPageChange}
                />
              )}
              <BillsTable />
              {myBillsTotalPage > 1 && (
                <Pagination
                  showFirstButton
                  showLastButton
                  size="small"
                  count={myBillsTotalPage}
                  page={myBillsCurrentPage}
                  onChange={handleMyBillsPageChange}
                />
              )}
            </Box>
          </animated.div>
        )}
      </Box>
    </section>
  );
};

export default MyWalletBills;
