import { FormControl, InputLabel, Select, MenuItem } from "@mui/material";

const ShopIDSearch = ({ shopID, setShopID }) => {
  return (
    <FormControl fullWidth size="small">
      <InputLabel sx={{ fontSize: "14px" }} id="supplier-select-label">
        Platform
      </InputLabel>
      <Select
        label="Tarih Tipi"
        value={shopID}
        onChange={(e) => setShopID(e.target.value)}
      >
        <MenuItem value="">
          <em>Temizle</em>
        </MenuItem>
        <MenuItem value="Etsy">
          <em>Etsy</em>
        </MenuItem>
        <MenuItem value="Shopify">
          <em>Shopify</em>
        </MenuItem>
        <MenuItem value="WooCommerce">
          <em>WooCommerce</em>
        </MenuItem>
      </Select>
    </FormControl>
  );
};

export default ShopIDSearch;
