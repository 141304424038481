import { Modal } from "@mui/material";
import { useRecoilState } from "recoil";
import { useCallback } from "react";
import { proformaModalState } from "../../../atoms/createShipment";
import CreateIOSSNumber from "./CreateIOSSNumber";

const CreateProformaIOSSModal = () => {
  const [proformaModal, setProformaModal] = useRecoilState(proformaModalState);

  const handleClose = useCallback(() => {
    setProformaModal(false);
  }, [setProformaModal]);

  return (
    <Modal
      sx={{
        maxWidth: "1100px",
        width: "100%",
        margin: "20px auto",
      }}
      open={proformaModal}
      onClose={handleClose}
    >
      <div
        style={{
          backgroundColor: "var(--lighterBg)",
          padding: "20px",
          margin: "20px",
          borderRadius: "var(--inputBorderRadius)",
          maxHeight: "90vh",
          overFlowY: "auto",
        }}
      >
        <CreateIOSSNumber />
      </div>
    </Modal>
  );
};

export default CreateProformaIOSSModal;
