import { Button, Dialog, DialogActions, DialogContent, DialogTitle } from "@mui/material";

const AreYourSureDialog = ({ open, setOpen, handleRequest }) => {
  const handleClose = () => {
    setOpen(false);
  };

  const handleSubmit = () => {
    handleClose();
    handleRequest();
  };

  return (
    <Dialog open={open} onClose={handleClose}>
      <DialogTitle>Takip numaranız Etsy Sistemine gönderilecektir.</DialogTitle>
      <DialogContent>Takip numarasını göndermek istiyor musunuz ?</DialogContent>
      <DialogActions>
        <Button className="iyzi-button-gray" onClick={handleClose}>
          Hayır
        </Button>
        <Button className="iyzi-button-blue" onClick={handleSubmit}>
          Evet
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default AreYourSureDialog;
