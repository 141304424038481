import { Button, InputAdornment, TextField } from "@mui/material";
import SearchIcon from "@mui/icons-material/Search";

const FrequentSearch = ({ handleSearch, value, handleButtonSearch }) => {
  return (
    <div className="sss-search">
      <TextField
        label="Arama Yap"
        value={value}
        onChange={handleSearch}
        InputProps={{
          endAdornment: (
            <InputAdornment position="end">
              <SearchIcon />
            </InputAdornment>
          ),
        }}
        size="small"
      />
      <div className="sss-search-buttons">
        <Button
          sx={{ color: value === "" ? "var(--btnBgBlue)" : "black" }}
          className={value === ""}
          onClick={() => handleButtonSearch("")}
        >
          Tümü
        </Button>
        <Button
          sx={{ color: value === "Hasar" ? "var(--btnBgBlue)" : "black" }}
          onClick={() => handleButtonSearch("Hasar")}
        >
          Hasar
        </Button>
        <Button
          sx={{ color: value === "Üyelik" ? "var(--btnBgBlue)" : "black" }}
          onClick={() => handleButtonSearch("Üyelik")}
        >
          Üyelik
        </Button>
        <Button
          sx={{ color: value === "Kurye" ? "var(--btnBgBlue)" : "black" }}
          onClick={() => handleButtonSearch("Kurye")}
        >
          Kurye
        </Button>
        <Button
          sx={{ color: value === "Gönderi" ? "var(--btnBgBlue)" : "black" }}
          onClick={() => handleButtonSearch("Gönderi")}
        >
          Gönderi
        </Button>
      </div>
    </div>
  );
};

export default FrequentSearch;
