import { Modal, Box } from "@mui/material";

const PageHowToWork = ({ open, setOpen, iframe }) => {
  const handleClose = () => setOpen(false);

  return (
    <Modal
      open={open}
      onClose={handleClose}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
      style={{ display: "flex", justifyContent: "center", alignItems: "center" }}
    >
      <Box
        sx={{
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          maxWidth: "800px",
          width: "100%",
          height: "500px",
          backgroundColor: "var(--lighterBg)",
          borderRadius: "7px",
        }}
      >
        {iframe}
      </Box>
    </Modal>
  );
};

export default PageHowToWork;
