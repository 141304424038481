import "../../style/globals/PageHeaderTitle.css";
import HelpOutlineOutlinedIcon from "@mui/icons-material/HelpOutlineOutlined";
import ShoppingCartOutlinedIcon from "@mui/icons-material/ShoppingCartOutlined";
import WrapperStyle from "../../utils/WrapperStyle";
import PageHowToWork from "../../utils/PageHowToWork";
import { useState } from "react";
import { Button } from "@mui/material";

const BasketTitle = () => {
  const [open, setOpen] = useState(false);
  const handleOpenVideo = () => {
    setOpen(true);
  };

  return (
    <>
      <PageHowToWork
        open={open}
        setOpen={setOpen}
        iframe={
          <iframe
            style={{ width: "100%", height: "100%", borderRadius: "7px" }}
            src="https://www.youtube.com/embed/zEuqlMRk_Yc"
            title="Bu Sayfa Nasıl Kullanılır l Sepetim"
            frameborder="0"
            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
            allowfullscreen
          ></iframe>
        }
      />
      <div className="page-header-title" style={WrapperStyle()}>
        <div className="page-header-title-wrapper">
          <div className="page-header-title-headline">
            <ShoppingCartOutlinedIcon
              sx={{
                width: "24px!important",
                height: "24px!important",
              }}
            />
            <h1>Sepetim</h1>
          </div>
          <Button
            sx={{ textTransform: "none" }}
            onClick={handleOpenVideo}
            className="page-header-title-how-link"
          >
            <HelpOutlineOutlinedIcon />
            <span>Bu Sayfa Nasıl Kullanılır?</span>
          </Button>
        </div>
      </div>
    </>
  );
};

export default BasketTitle;
