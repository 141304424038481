import { Link } from "react-router-dom";
import Footer from "../components/beforeLogin/Footer";
import Header from "../components/beforeLogin/Header";
import { DynamicTitle } from "../utils/DynamicTitle";
// import PageTransition from "../utils/PageTransition";

const CookiePolicy = () => {
  DynamicTitle("Çerez Politikası | iyziShip");

  return (
    <>
      {/* <PageTransition> */}
        <Header />
        <main className="iyzi-main">
          <section className="policies">
            <article>
              <h1>Çerez Politikası</h1>
              <p>
                Biz, İYZİ BİLİŞİM ANONİM ŞİRKETİ, olarak güvenliğinize önem
                veriyor ve bu Çerez Politikası ile siz sevgili
                ziyaretçilerimizi, web sitemizde hangi çerezleri, hangi amaçla
                kullandığımız ve çerez ayarlarınızı nasıl değiştireceğiniz
                konularında kısaca bilgilendirmeyi hedefliyoruz.
              </p>
              <p>
                Sizlere daha iyi hizmet verebilmek adına, çerezler vasıtasıyla,
                ne tür kişisel verilerinizin hangi amaçla toplandığı ve nasıl
                işlendiği konularında, kısaca bilgi sahibi olmak için lütfen bu
                Çerez Politikasını okuyunuz. Daha fazla bilgi için bizlerle
                çekinmeden iletişime geçebilirsiniz.
              </p>
            </article>
            <article>
              <h4>Çerez Nedir?</h4>
              <p>
                Çerezler, kullanıcıların web sitelerini daha verimli bir şekilde
                kullanabilmeleri adına, cihazlarına kaydedilen küçük
                dosyacıklardır. Çerezler vasıtasıyla kullanıcıların bilgilerinin
                işleniyor olması sebebiyle, 6698 sayılı Kişisel Verilerin
                Korunması Kanunu gereğince, kullanıcıların bilgilendirilmeleri
                ve onaylarının alınması gerekmektedir.
              </p>
              <p>
                Bizler de siz sevgili ziyaretçilerimizin, web sitemizden en
                verimli şekilde yararlanabilmelerini ve kullanıcı deneyimlerinin
                geliştirilebilmesini sağlamak adına, çeşitli çerezler
                kullanmaktayız.
              </p>
            </article>
            <article>
              <h5>1. Zorunlu Çerezler</h5>
              <p>
                Zorunlu çerezler, web sitesine ilişkin temel işlevleri
                etkinleştirerek web sitesinin kullanılabilir hale gelmesini
                sağlayan çerezlerdir. Web sitesi bu çerezler olmadan düzgün
                çalışmaz.
              </p>
            </article>
            <article>
              <h5>2. Performans Çerezleri</h5>
              <p>
                Performans çerezleri, ziyaretçilerin web sitesine ilişkin
                kullanım bilgilerini ve tercihlerini anonim olarak toplayan ve
                bu sayede web sitesinin performansının geliştirilmesine olanak
                sağlayan çerezlerdir.
              </p>
            </article>
            <article>
              <h5>3. Fonksiyonel Çerezler</h5>
              <p>
                Fonksiyonel çerezler, kullanıcıların web sitesine ilişkin geçmiş
                kullanımlarından yola çıkılarak gelecekteki ziyaretlerinde
                tanınmalarını ve hatırlanmalarını sağlayan ve bu sayede web
                sitelerinin kullanıcılara dil, bölge vb. gibi kişiselleştirilmiş
                bir hizmet sunmasına olanak tanıyan çerezlerdir.
              </p>
            </article>
            <article>
              <h5>4. Reklam Çerezleri</h5>
              <p>
                Reklam çerezleri, üçüncü taraflara ait çerezlerdir ve web
                sitelerinde ziyaretçilerin davranışlarını izlemek için
                kullanılırlar. Bu çerezlerin amaçları, ziyaretçilerin
                ihtiyaçlarına yönelik ilgilerini çekecek reklamların
                gösterilmesine yardımcı olmaktır ve sorumluluğu çerez sahibi
                üçüncü taraflara aittir.
              </p>
            </article>
            <article>
              <h4>Çerezler ile İşlenen Kişisel Veriler Nelerdir?</h4>
              <p>
                Kimlik (isim, soy isim, doğum tarihi vb.) ve iletişim (adres,
                e-posta adresi, telefon, IP, konum vb.) bilgileriniz
                tarafımızca, çerezler vasıtasıyla, otomatik veya otomatik
                olmayan yöntemlerle ve bazen de analitik sağlayıcılar, reklam
                ağları, arama bilgi sağlayıcıları, teknoloji sağlayıcıları gibi
                üçüncü taraflardan elde edilerek, kaydedilerek, depolanarak ve
                güncellenerek, aramızdaki hizmet ve sözleşme ilişkisi
                çerçevesinde ve süresince, meşru menfaat işleme şartına
                dayanılarak işlenecektir.
              </p>
            </article>
            <article>
              <h4>Çerezler Hangi Amaçla Kullanılmaktadır?</h4>
              <p>
                Web sitemizde, şüpheli eylemlerin tespiti yoluyla güvenliğin
                sağlanması, kullanıcıların tercihleri doğrultusunda işlevsellik
                ve performansın artırılması, ürünlerin ve hizmetlerin
                geliştirilmesi ve kişiselleştirilmesi ile bu hizmetlere ulaşımın
                kolaylaştırılması, sözleşmesel ve hukuki sorumlulukların yerine
                getirilmesi amaçlı çerezler kullanmaktadır. Ayrıca
                kullanıcıların daha geniş kapsamlı hizmet sağlayıcılar ile
                buluşturulabilmesi amacıyla reklam çerezleri ve üçüncü
                taraflarla bilgi paylaşımı da söz konusudur.
              </p>
            </article>
            <article>
              <h4>Çerezler Nasıl Yönetilmektedir?</h4>
              <p>
                Tüm bu açıklamalardan sonra, hangi çerezlerin kullanılacağı
                konusu, tamamen kullanıcılarımızın özgür iradelerine
                bırakılmıştır. Çerez tercihlerinizi, tarayıcınızın ayarlarından
                silerek ya da engelleyerek, web sitemize adım attığınız anda
                yönetebilir ya da gelecekte, istediğiniz zaman bu ayarları
                değiştirebilirsiniz. Daha ayrıntılı bilgi için bizlerle
                info@iyziship.com e-posta adresi üzerinden iletişime
                geçebilirsiniz.
              </p>
            </article>
            <article>
              <p className="company-info">
                <span>Ticaret Ünvanı:</span> İYZİ BİLİŞİM ANONİM ŞİRKETİ
              </p>
              <p className="company-info">
                <span>Adresi:</span>{" "}
                <a
                  style={{
                    fontSize: "inherit",
                    fontWeight: "inherit",
                    color: "inherit",
                  }}
                  href="https://goo.gl/maps/qNRaDs7V4fi5CtW2A"
                  target="_blank"
                  rel="noreferrer"
                >
                  <span>
                    Güneşli, 15 Temmuz Mh. Koçman Cd. Demirkol Plaza, No:54/E,
                    34212 Bağcılar/İstanbul
                  </span>
                </a>
              </p>
              <p className="company-info">
                <span>Telefon:</span> +90 (850) 532 49 94
              </p>
              <p className="company-info">
                <span>Fax:</span> +90 (212) 695 0 959
              </p>
              <p className="company-info">
                <span>E-posta:</span>{" "}
                <a
                  style={{
                    fontSize: "inherit",
                    fontWeight: "inherit",
                    color: "inherit",
                  }}
                  rel="noreferrer"
                  href="mailto:info@iyziship.com"
                >
                  info@iyziship.com
                </a>
              </p>
              <p className="company-info">
                <span>Web sitesi:</span>{" "}
                <Link
                  style={{
                    fontSize: "inherit",
                    fontWeight: "inherit",
                    color: "inherit",
                  }}
                  to={"/"}
                >
                  www.iyziship.com
                </Link>
              </p>
            </article>
          </section>
        </main>
        <Footer />
      {/* </PageTransition> */}
    </>
  );
};

export default CookiePolicy;
