import { Divider } from "@mui/material";
import Header from "../components/beforeLogin/Header";
import Footer from "../components/beforeLogin/Footer";
import { DynamicTitle } from "../utils/DynamicTitle";
import ContactForm from "../components/home/ContactForm";
import ContactInfo from "../components/home/ContactInfo";
// import PageTransition from "../utils/PageTransition";
import GetFinish from "../components/home/GetFinish";
const Contact = () => {
  DynamicTitle("İletişim | iyziShip");

  return (
    <>
      {/* <PageTransition> */}
      <Header />
      <main className="iyzi-main">
        <section className="main-contact">
          <div className="contact-header">
            <article>
              <h1>Size Nasıl Yardımcı Olabiliriz?</h1>
              <p>
                Hepimiz bazen bir yardımcıya, bir kılavuza ihtiyaç duyarız,
                özellikle de yurt dışı kargo süreçleri gibi karmaşık konularda.
                İşte bu noktada biz devreye giriyoruz; sorularınız, önerileriniz
                veya yardıma ihtiyaç duyduğunuz her konuda size destek vermek
                için buradayız.
              </p>
            </article>
          </div>
          <ContactInfo />
          <div className="contact-clock">
            <h4>Çalışma Saatlerimiz</h4>
            <div className="clock-items">
              <p className="clock">Pazartesi-Cuma: 09:00-18:00</p>
              <Divider className="clock-divider" />
              <p className="clock">
                Cumartesi: 09:00-12:00 (Yalnızca gönderi kabulü ve transferi
                yapılmaktadır.)
              </p>
              <Divider className="clock-divider" />
              <p className="clock">Pazar: Kapalı</p>
            </div>
          </div>
          <ContactForm />
        </section>
        <GetFinish />
      </main>
      <Footer />
      {/* </PageTransition> */}
    </>
  );
};

export default Contact;
