import { TextField } from "@mui/material";

const BuyerEmail = ({ buyerEmail, setBuyerEmail }) => {
  return (
    <TextField
      size="small"
      label="Müşteri Email"
      value={buyerEmail}
      autoComplete="off"
      onChange={(e) => setBuyerEmail(e.target.value)}
    />
  );
};

export default BuyerEmail;
