import GetToday from "./GetToday";

const DownloadBase64Pdf = (base64, description, title, startDate, endDate) => {
  const today = GetToday();
  const linkSource = `data:application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;base64,${base64}`;
  const downloadLink = document.createElement("a");
  downloadLink.href = linkSource;
  downloadLink.target = "_blank";
  title === "finance"
    ? (downloadLink.download = `${startDate}_${endDate}_${description}.xlsx`)
    : (downloadLink.download = `${today}_${description}.pdf`);
  //downloadLink.download = `${today}_${description}.pdf`
  downloadLink.click();
};

export default DownloadBase64Pdf;
