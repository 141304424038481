import { Modal, IconButton } from "@mui/material";
import InfoOutlinedIcon from "@mui/icons-material/InfoOutlined";
import HighlightOffOutlinedIcon from "@mui/icons-material/HighlightOffOutlined";

const Warring = ({ isOpen, onClose }) => {
  return (
    <Modal
      sx={{ maxWidth: "1100px", width: "100%", margin: "auto" }}
      open={isOpen}
      onClose={onClose}
    >
      <div
        style={{
          margin: "20px",
          padding: "20px",
          backgroundColor: "var(--shipmentRed)",
          borderRadius: "var(--inputBorderRadius)",
          position: "relative",
          maxHeight: "90vh",
          overflowY: "auto",
        }}
      >
        <div
          style={{
            color: "var(--lighterBg)",
            display: "flex",
            alignItems: "center",
            gap: "var(--gap)",
          }}
        >
          <InfoOutlinedIcon sx={{ color: "inherit" }} />
          <span style={{ color: "inherit", marginRight: "30px" }}>
            Posta Ofisine servisimiz yoktur.
          </span>
        </div>
        <IconButton
          onClick={onClose}
          sx={{ position: "absolute", top: 0, right: 0 }}
          aria-label="kapat"
        >
          <HighlightOffOutlinedIcon sx={{ color: "var(--lighterBg)" }} />
        </IconButton>
      </div>
    </Modal>
  );
};

export default Warring;
