const OverChargeMessages = (key) => {
  switch (key) {
    case "OVS":
      return "Ölçü Limit Aşım Ücreti";
    case "OVW":
      return "Kilo Limit Aşım Ücreti";
    case "OOA":
      return "Uzak Bölge Dağıtım Ücreti";
    case "EEU":
      return "Ek Elleçleme Ücreti";
    case "EXC":
      return "Ek Ücret";
    case "GRT":
      return "Paket Çevre Ölçüsü Limit Aşım Ücreti";
    default:
      return "Tanımın detayını öğrenmek için destek hattı ile iletişime geçiniz!";
  }
};

export default OverChargeMessages;
