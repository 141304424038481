import Box from "@mui/material/Box";
import {
  TextField,
  FormControl,
  FormHelperText,
  createTheme,
  ThemeProvider,
  Button,
} from "@mui/material";
import { useCallback, useState, useEffect } from "react";
import countries from "i18n-iso-countries";
import trLocale from "i18n-iso-countries/langs/tr.json";
import { toast } from "react-toastify";
import SpringIn from "../../profilesettings/addressInfo/SpringIn";
import { useRecoilState, useRecoilValue } from "recoil";
import CircularProgress from "@mui/material/CircularProgress";
import { useCookies } from "react-cookie";
import {
  senderAddressesState,
  senderCreateCountryCodeState,
  senderZipCodeState,
  shipmentHideState,
  shipmentSenderCityState,
  shipmentSenderDistrictState,
  shipmentSenderStateCodeState,
  startShipmentSenderAnimationState,
} from "../../../atoms/createShipment";
import axios from "../../../api/axios";
import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/style.css";
import countriesDetailed from "../../../json/countriesDetailed.json";
import { validateEmail } from "../../../utils/emailValidation";
import Errors from "../../../utils/Errors";

const theme = createTheme({
  components: {
    MuiFormHelperText: {
      styleOverrides: {
        root: {
          margin: "5px 0 0",
        },
      },
    },
  },
});

const CreateShipmentSenderAddress = () => {
  const userInfo = JSON.parse(localStorage.getItem("user"));

  const [startShipmentSenderAnimation, setStartShipmentSenderAnimation] = useRecoilState(
    startShipmentSenderAnimationState
  );
  const [, setShipmentHide] = useRecoilState(shipmentHideState);
  const [senderAddresses, setSenderAddresses] = useRecoilState(senderAddressesState);

  const [token] = useCookies(["jwt"]);
  const jwt = token.jwt;

  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");

  const [header, setHeader] = useState("");
  //const [fullName, setFullName] = useState(userInfo?.user?.user_member?.name);
  const [address, setAddress] = useState("");
  const [address2, setAddress2] = useState("");
  const [phone, setPhone] = useState("");
  const [email, setEmail] = useState(userInfo?.user.email);
  const [companyName, setCompanyName] = useState("");
  const [state, setState] = useState("");

  const [requestLoading, setRequestLoading] = useState(false);

  //* Error */
  const [phoneIsNotValid, setPhoneIsNotValid] = useState(false);

  //* ZipCode State */
  const [shipmentSenderDistrict, setShipmentSenderDistrict] = useRecoilState(
    shipmentSenderDistrictState
  );
  const [shipmentSenderCity, setShipmentSenderCity] = useRecoilState(shipmentSenderCityState);
  const [shipmentSenderStateCode, setShipmentSenderStateCode] = useRecoilState(
    shipmentSenderStateCodeState
  );

  const senderZipCode = useRecoilValue(senderZipCodeState);
  const senderCreateCountryCode = useRecoilValue(senderCreateCountryCodeState);

  // Have to register the languages you want to use
  countries.registerLocale(trLocale);

  const addressRegisterHandler = async (e) => {
    if (e) e.preventDefault();
    const data = {
      //Todo first_name ve last_name gelince parametrelerde contact_name kaldırılacak yerine first_name ve last_name gelecek.
      contact_name: `${firstName} ${lastName}`,
      email: email,
      address_description: header,
      address1: address,
      address2: address2,
      postal_code: senderZipCode,
      phone: phone,
      town: shipmentSenderDistrict,
      city: shipmentSenderCity,
      state: state,
      country: senderCreateCountryCode,
      address_type: 4,
      state_code: shipmentSenderStateCode,
      company_name: companyName,
    };

    if (phone.length < 6) {
      setPhoneIsNotValid(true);
      return;
    } else {
      setRequestLoading(true);
      await axios
        .post("member-address/add", data, {
          headers: {
            Authorization: `Bearer ${jwt}`,
          },
        })
        .then((response) => {
          setSenderAddresses([...senderAddresses, response.data.data]);
          toast.success(response.data.message);
          setStartShipmentSenderAnimation(false);
          setShipmentHide(false);
        })
        .catch((error) => Errors(error))
        .finally(() => {
          setRequestLoading(false);
          setPhoneIsNotValid(false);
        });
    }
  };

  const addressRegisterAbortHandler = useCallback(() => {
    setStartShipmentSenderAnimation(false);
    setShipmentHide(false);
  }, [setStartShipmentSenderAnimation, setShipmentHide]);

  const countryName = `${
    countriesDetailed.find((e) => e.iso_code === senderCreateCountryCode).country_name_tr
  }, ${countriesDetailed.find((e) => e.iso_code === senderCreateCountryCode).country_name_en}`;

  const handleSubmit = (e) => {
    if (e) e.preventDefault();

    addressRegisterHandler();
  };

  const [emailError, setEmailError] = useState(false);
  const [isEmailValid, setIsEmailValid] = useState(true);

  const handleEmailChange = (e) => {
    const newEmail = e.target.value;

    setEmail(newEmail);

    if (!newEmail) {
      setIsEmailValid(false);
    } else {
      const isValidEmail = validateEmail(newEmail);
      setIsEmailValid(isValidEmail);
    }
  };

  useEffect(() => {
    setEmailError(!isEmailValid);
  }, [isEmailValid]);

  return (
    <>
      {startShipmentSenderAnimation && (
        <SpringIn>
          <ThemeProvider theme={theme}>
            <form style={{ display: "grid", gap: "var(--gap2x)" }} onSubmit={handleSubmit}>
              <h6 style={{ color: "var(--priceText)" }}>Gönderici Adresi</h6>
              <Box
                sx={{
                  display: "flex",
                  justifyContent: "space-between",
                  flexWrap: "wrap",
                  gap: "var(--gap2x)",
                }}
              >
                <TextField
                  value={header}
                  onChange={(e) => setHeader(e.target.value)}
                  className="new-address-header"
                  placeholder="Ev"
                  autoComplete="off"
                  size="small"
                  label="Başlık"
                />
              </Box>
              <Box className="new-address-modal-wrapper">
                <TextField
                  required
                  value={firstName}
                  onChange={(e) => setFirstName(e.target.value)}
                  fullWidth
                  size="small"
                  label="İsim"
                  autoComplete="off"
                />
                <TextField
                  required
                  value={lastName}
                  onChange={(e) => setLastName(e.target.value)}
                  fullWidth
                  size="small"
                  label="Soyisim"
                  autoComplete="off"
                />
                <TextField
                  value={companyName}
                  onChange={(e) => setCompanyName(e.target.value)}
                  fullWidth
                  size="small"
                  autoComplete="off"
                  label="Firma Adı"
                />
                <TextField
                  value={email}
                  required
                  onChange={handleEmailChange}
                  error={emailError}
                  helperText={emailError && "Geçerli bir email giriniz."}
                  size="small"
                  label="E-Posta"
                  type="email"
                  autoComplete="off"
                />
              </Box>
              <TextField
                required
                value={address}
                onChange={(e) => setAddress(e.target.value)}
                maxRows={4}
                inputProps={{ maxLength: 191 }}
                autoComplete="off"
                fullWidth
                size="medium"
                label="Adres Satırı"
              />
              <TextField
                value={address2}
                onChange={(e) => setAddress2(e.target.value)}
                maxRows={4}
                inputProps={{ maxLength: 191 }}
                autoComplete="off"
                fullWidth
                size="medium"
                label="2.Adres Satırı"
              />
              <Box className="new-address-modal-wrapper">
                <div
                  style={{
                    backgroundColor: "var(--pastelBlue)",
                    display: "flex",
                    alignItems: "center",
                    padding: "0 20px",
                    borderRadius: "var(--buttonBorderRadius)",
                    boxShadow: "var(--shadow)",
                    color: "var(--info)",
                  }}
                >
                  <b>Seçilen Ülke:&nbsp;</b> {countryName}
                </div>
                <div
                  style={{
                    backgroundColor: "var(--pastelBlue)",
                    display: "flex",
                    alignItems: "center",
                    padding: "0 20px",
                    borderRadius: "var(--buttonBorderRadius)",
                    boxShadow: "var(--shadow)",
                    color: "var(--info)",
                  }}
                >
                  <b>Posta Kodu:&nbsp;</b> {senderZipCode}
                </div>
                <FormControl size="small" fullWidth>
                  <PhoneInput
                    country={senderCreateCountryCode.toLowerCase()}
                    value={phone}
                    onChange={(e) => setPhone(e)}
                    onEnterKeyPress={addressRegisterHandler}
                    autoComplete="off"
                    isValid={(value, country) => {
                      if (value.match(/12345/)) {
                        return "Invalid value: " + value + ", " + country.name;
                      } else if (value.match(/1234/)) {
                        return false;
                      } else {
                        return true;
                      }
                    }}
                    inputProps={{
                      name: "phone",
                      autoFocus: true,
                      required: true,
                      id: "ind-phone-input",
                      style: {
                        height: "100%",
                        width: "100%",
                        minHeight: "40px",
                        maxHeight: "40px",
                        borderColor: phoneIsNotValid ? "var(--error)" : "var(--inputBorderColor)",
                      },
                    }}
                  />
                  {phoneIsNotValid && (
                    <FormHelperText sx={{ color: "var(--error)" }}>
                      Telefon numaranızı giriniz.
                    </FormHelperText>
                  )}
                </FormControl>
              </Box>
              <Box className="new-address-modal-wrapper">
                <TextField
                  required
                  value={state}
                  onChange={(e) => setState(e.target.value)}
                  fullWidth
                  size="small"
                  autoComplete="off"
                  label="Semt / Eyalet"
                />
                <TextField
                  value={shipmentSenderDistrict}
                  onChange={(e) => setShipmentSenderDistrict(e.target.value)}
                  fullWidth
                  size="small"
                  autoComplete="off"
                  label="İlçe"
                />
                <TextField
                  required
                  value={shipmentSenderCity}
                  onChange={(e) => setShipmentSenderCity(e.target.value)}
                  fullWidth
                  size="small"
                  autoComplete="off"
                  label="Şehir"
                />
                <TextField
                  required
                  value={shipmentSenderStateCode}
                  onChange={(e) => setShipmentSenderStateCode(e.target.value)}
                  fullWidth
                  size="small"
                  autoComplete="off"
                  label="Eyalet Kodu"
                />
              </Box>
              <Box className="new-address-register">
                {requestLoading ? (
                  <Box
                    sx={{
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                      width: "108px",
                    }}
                  >
                    <CircularProgress sx={{ width: "20px!important", height: "20px!important" }} />
                  </Box>
                ) : (
                  <Button className="iyzi-button-blue" type="submit">
                    Kaydet
                  </Button>
                )}
                <Button
                  type="button"
                  style={{ marginLeft: "10px" }}
                  className="iyzi-button-blue"
                  onClick={addressRegisterAbortHandler}
                >
                  İptal
                </Button>
              </Box>
            </form>
          </ThemeProvider>
        </SpringIn>
      )}
    </>
  );
};

export default CreateShipmentSenderAddress;
