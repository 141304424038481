import "../../../style/fastShipment/FastDimension.css";
import { Box, Button, createTheme, Modal, TextField, ThemeProvider } from "@mui/material";
import { useCallback, useState } from "react";
import { toast } from "react-toastify";
import { useRecoilState } from "recoil";
import axios from "../../../api/axios";
import { fastDimensionState, openFastDimensionState } from "../../../atoms/fastShipment";
import Errors from "../../../utils/Errors";
import {
  FormatFieldWithInteger,
  FormatFieldWithTwoDecimals,
  FormatFieldWithTwoDecimalsWithMaxNumber,
} from "../../../utils/FormatField";

const theme = createTheme({
  components: {
    MuiModal: {
      styleOverrides: {
        root: {
          margin: "20px",
          overflow: "auto",
        },
      },
    },
    MuiSelect: {
      styleOverrides: {
        outlined: {
          display: "flex",
          alignItems: "center",
          gap: "10px",
        },
      },
    },
    MuiFormControlLabel: {
      styleOverrides: {
        label: {
          fontSize: "14px",
          opacity: 0.8,
        },
      },
    },
    MuiOutlinedInput: {
      styleOverrides: {
        root: {
          borderRadius: "10px",
        },
      },
    },
  },
});

const FastDimension = () => {
  const [openFastDimension, setOpenFastDimension] = useRecoilState(openFastDimensionState);
  const [fastDimension, setFastDimension] = useRecoilState(fastDimensionState);

  const [en, setEn] = useState("");
  const [boy, setBoy] = useState("");
  const [yuk, setYuk] = useState("");
  const [kg, setKg] = useState("");
  const [qty, setQty] = useState("");
  const [, setLoading] = useState(false);

  const handleClose = useCallback(() => {
    setEn("");
    setBoy("");
    setYuk("");
    setKg("");
    setQty("");
    setLoading(false);
    setOpenFastDimension(false);
  }, [setOpenFastDimension]);

  // const handleDecimalChange = (e) => {
  //   const { name, value } = e.target;
  //   const regex = /^\d+(\.\d{0,2})?$/;

  //   if (name === "en" || name === "boy" || name === "yuk" || name === "qty") {
  //     if (name === "qty") {
  //       const integerValue = value.replace(/[^0-9]/g, "");
  //       setQty(integerValue);
  //     } else if (value.startsWith(".") || (!regex.test(value) && value !== "")) {
  //       return;
  //     } else {
  //       const decimalValue = value.replace(/[^0-9.]|(?<=\..*)\.|^0{2,}(?=[.])/g, "");

  //       if (name === "en") {
  //         setEn(decimalValue);
  //       } else if (name === "boy") {
  //         setBoy(decimalValue);
  //       } else if (name === "yuk") {
  //         setYuk(decimalValue);
  //       }
  //     }
  //   } else if (name === "gross") {
  //     // Sadece nokta veya virgül girildiyse '0.' ile başlat
  //     let formattedValue = value === "." || value === "," ? "0." : value;

  //     // Virgülü noktaya çevir ve sadece sayı, nokta kabul et
  //     let grossValue = formattedValue.replace(/,/g, ".").replace(/[^0-9.]/g, "");

  //     // Ondalık kısmı iki basamakla sınırla
  //     const match = grossValue.match(/^\d*(\.\d{0,2})?/);
  //     grossValue = match ? match[0] : "";

  //     // Maksimum değeri kontrol et
  //     if (!isNaN(Number(grossValue)) && Number(grossValue) > 999) {
  //       setKg("999");
  //     } else {
  //       setKg(grossValue);
  //     }
  //   }
  // };

  const handleDecimalChange = (e) => {
    const { name } = e.target;

    if (name === "en") {
      FormatFieldWithTwoDecimals(e, setEn);
    } else if (name === "boy") {
      FormatFieldWithTwoDecimals(e, setBoy);
    } else if (name === "yuk") {
      FormatFieldWithTwoDecimals(e, setYuk);
    } else if (name === "gross") {
      FormatFieldWithTwoDecimalsWithMaxNumber(e, setKg, 999);
    } else if (name === "qty") {
      FormatFieldWithInteger(e, setQty);
    }
  };

  const handleAddNewDimension = useCallback(
    (e) => {
      e.preventDefault();
      setLoading(true);
      const data = {
        length: en,
        width: boy,
        height: yuk,
        gross: kg,
        qty: qty,
      };

      axios
        .post("member-dimension/add", data)
        .then((response) => {
          toast.success(response.data.message);
          setFastDimension([response.data.data, ...fastDimension]);
          setOpenFastDimension(false);
        })
        .catch((error) => Errors(error))
        .finally(() => setLoading(false));
    },
    [boy, en, yuk, kg, qty, fastDimension, setFastDimension, setOpenFastDimension]
  );

  return (
    <ThemeProvider theme={theme}>
      <Modal open={openFastDimension} onClose={handleClose}>
        <form
          className="fast-dimension"
          onSubmit={handleAddNewDimension}
          style={{
            maxHeight: "90vh",
            overflowY: "auto",
          }}
        >
          <Box className="fast-dimension-input">
            <TextField
              size="small"
              value={en}
              onChange={handleDecimalChange}
              label="En (cm)"
              name="en"
              required
              autoComplete="off"
            />
            <TextField
              size="small"
              value={boy}
              onChange={handleDecimalChange}
              label="Boy (cm)"
              name="boy"
              required
              autoComplete="off"
            />
            <TextField
              size="small"
              value={yuk}
              onChange={handleDecimalChange}
              label="Yükseklik (cm)"
              name="yuk"
              required
              autoComplete="off"
            />
            <TextField
              size="small"
              value={kg}
              onChange={handleDecimalChange}
              label="Kg"
              name="gross"
              required
              autoComplete="off"
            />
            <TextField
              size="small"
              value={qty}
              onChange={handleDecimalChange}
              label="Adet"
              name="qty"
              required
              autoComplete="off"
            />
          </Box>
          <Box className="fast-dimension-buttons">
            <Button type="button" onClick={handleClose} className="iyzi-button">
              İptal
            </Button>
            <Button type="submit" className="iyzi-button">
              Kaydet
            </Button>
          </Box>
        </form>
      </Modal>
    </ThemeProvider>
  );
};

export default FastDimension;
