import { TableCell, TableHead, TableRow } from "@mui/material";
import { useCallback, useState } from "react";
import axios from "../../../api/axios";
import {
  walletRefundCurrentPageState,
  walletRefundDataState,
  walletRefundTotalPageState,
} from "../../../atoms/myWallet";
import { useRecoilState } from "recoil";
import KeyboardArrowDownOutlinedIcon from "@mui/icons-material/KeyboardArrowDownOutlined";
import Errors from "../../../utils/Errors";

const MyRefundTableHead = () => {
  const user = JSON.parse(localStorage.getItem("user"));
  const memberType = user?.user?.user_member?.member_type;

  const [, setWalletRefundData] = useRecoilState(walletRefundDataState);
  const [walletRefundCurrentPage, setWalletRefundCurrentPage] = useRecoilState(
    walletRefundCurrentPageState
  );
  const [, setWalletRefundTotalPage] = useRecoilState(walletRefundTotalPageState);

  const [activeSort, setActiveSort] = useState([
    { column: "created_at", direction: null },
    { column: "request_refund_amount", direction: null },
    { column: "status", direction: null },
  ]);

  const handlePageChange = useCallback(
    async (orderBy) => {
      const updatedSort = activeSort.map((sort) => {
        if (sort.column === orderBy) {
          return {
            ...sort,
            direction: sort.direction === "ASC" ? "DESC" : "ASC",
          };
        }
        return {
          ...sort,
          direction: null, // Diğer sütunların yönünü varsayılan duruma sıfırlayın
        };
      });

      setActiveSort(updatedSort);

      const currentDirection = updatedSort.find((sort) => sort.column === orderBy).direction;

      axios
        .get(`refund`, {
          params: {
            page: walletRefundCurrentPage,
            limit: 100,
            order_by: orderBy,
            order_direction: currentDirection,
          },
        })
        .then((resp) => {
          setWalletRefundData(resp.data.data);
          setWalletRefundCurrentPage(resp.data.currentPage);
          setWalletRefundTotalPage(resp.data.totalPages);
        })
        .catch((error) => {
          Errors(error);
        });
    },
    [
      setWalletRefundData,
      setWalletRefundCurrentPage,
      setWalletRefundTotalPage,
      walletRefundCurrentPage,
      activeSort,
    ]
  );

  return (
    <TableHead>
      <TableRow>
        <TableCell
          onClick={() => handlePageChange("created_at")}
          sx={{ fontWeight: 700, cursor: "pointer" }}
        >
          <div style={{ fontSize: "inherit", display: "flex", gap: "5px" }}>
            Talep Tarihi{" "}
            <KeyboardArrowDownOutlinedIcon
              sx={{
                transform:
                  activeSort.find((sort) => sort.column === "created_at")?.direction === "ASC"
                    ? "rotate(180deg)"
                    : "rotate(0deg)",
                transition: "transform 0.3s",
              }}
            />
          </div>
        </TableCell>

        <TableCell
          onClick={() => handlePageChange("request_refund_amount")}
          sx={{ fontWeight: 700, cursor: "pointer" }}
        >
          <div style={{ fontSize: "inherit", display: "flex", gap: "5px" }}>
            Tutar{" "}
            <KeyboardArrowDownOutlinedIcon
              sx={{
                transform:
                  activeSort.find((sort) => sort.column === "request_refund_amount")?.direction ===
                  "ASC"
                    ? "rotate(180deg)"
                    : "rotate(0deg)",
                transition: "transform 0.3s",
              }}
            />
          </div>
        </TableCell>
        <TableCell sx={{ fontWeight: 700 }}>Açıklama</TableCell>
        <TableCell
          onClick={() => handlePageChange("status")}
          sx={{ fontWeight: 700, cursor: "pointer" }}
        >
          <div style={{ fontSize: "inherit", display: "flex", gap: "5px" }}>
            Durum{" "}
            <KeyboardArrowDownOutlinedIcon
              sx={{
                transform:
                  activeSort.find((sort) => sort.column === "status")?.direction === "ASC"
                    ? "rotate(180deg)"
                    : "rotate(0deg)",
                transition: "transform 0.3s",
              }}
            />
          </div>
        </TableCell>
        {memberType === "Company" && <TableCell sx={{ fontWeight: 700 }}>Fatura Ekle</TableCell>}
      </TableRow>
    </TableHead>
  );
};

export default MyRefundTableHead;
