const CCard = (e) => {
  const input = e.target.value
    .replace(/\D/g, "")
    .replace(/(\d{4})/g, "$1 ")
    .trim();

  return input;
};

export default CCard;
