import { useState } from "react";
import ShopIDSearch from "./ShopIDSearch";
import ShopNameSearch from "./ShopNameSearch";
import Country from "./Country";
import Suppliers from "./Suppliers";
import ReceiptDate from "./ReceiptDate";
import CreatedDate from "./CreatedDate";
import DateType from "./DateType";
import ParcelRefNo from "./ParcelRefNo";
import Status from "./Status";
import ParcelType from "./ParcelType";
import IsInvoiceFile from "./IsInvoiceFile";
import { Button, Box } from "@mui/material";
import BuyerEmail from "./BuyerEmail";
import ReceiverName from "./ReceiverName";
import FormattedAddress from "./FormattedAddress";
import axios from "../../../api/axios";
import { useRecoilValue, useSetRecoilState, useRecoilState } from "recoil";
import {
  fastLimitState,
  fastListState,
  fastShipmentCurrentPageState,
  fastShipmentTotalPagesState,
  portalState,
  shopIDState,
  shopNameState,
  countryState,
  suppliersState,
  receiptIDState,
  buyerEmailState,
  receiverNameState,
  formattedAddressState,
  statusState,
  parcelRefNoState,
  receiptDateStartState,
  receiptDateEndState,
  createDateStartState,
  createDateEndState,
  receiptDateState,
  createdDateState,
  dateTypeState, parcelTypeState, isInvoiceFileState
} from "../../../atoms/fastShipment";
import Errors from "../../../utils/Errors";
import ReceiptIDSearch from "./ReceiptIDSearch";

const FastShipmentsDetailedSearch = ({ setLoading }) => {
  const portal = useRecoilValue(portalState);
  const fastLimit = useRecoilValue(fastLimitState);

  const setFastList = useSetRecoilState(fastListState);
  const setFastShipmentTotalPages = useSetRecoilState(fastShipmentTotalPagesState);
  const setFastShipmentCurrentPage = useSetRecoilState(fastShipmentCurrentPageState);

  const [shopID, setShopID] = useRecoilState(shopIDState);
  const [shopName, setShopName] = useRecoilState(shopNameState);
  const [country, setCountry] = useRecoilState(countryState);
  const [supplier, setSupplier] = useRecoilState(suppliersState);
  const [receiptID, setReceiptID] = useRecoilState(receiptIDState);
  const [buyerEmail, setBuyerEmail] = useRecoilState(buyerEmailState);
  const [receiverName, setReceiverName] = useRecoilState(receiverNameState);
  const [formattedAddress, setFormattedAddress] = useRecoilState(formattedAddressState);
  const [status, setStatus] = useRecoilState(statusState);
  const [parcelType, setParcelType] = useRecoilState(parcelTypeState);
  const [isInvoiceFile, setIsInvoiceFile] = useRecoilState(isInvoiceFileState);
  const [parcelRefNo, setParcelRefNo] = useRecoilState(parcelRefNoState);
  const [receiptDateStart, setReceiptDateStart] = useRecoilState(receiptDateStartState);
  const [receiptDateEnd, setReceiptDateEnd] = useRecoilState(receiptDateEndState);
  const [createDateStart, setCreateDateStart] = useRecoilState(createDateStartState);
  const [createDateEnd, setCreateDateEnd] = useRecoilState(createDateEndState);
  const [receiptDate, setReceiptDate] = useRecoilState(receiptDateState);
  const [createdDate, setCreatedDate] = useRecoilState(createdDateState);
  const [dateType, setDateType] = useRecoilState(dateTypeState);

  const handleDetailedSearch = async (e) => {
    if (e) e.preventDefault();
    setLoading(true);

    await axios
      .get("general/integration-parcel", {
        params: {
          ...(portal ? { platform: portal } : {}), // Add platform if it's not empty
          limit: 20,
          detailed: 1,
          order_by: "receipt_date",
          is_excel: window.location.href.endsWith("create-fast-all-shipment") ? 1 : 0,
          ...(shopID ? { platform: shopID } : {}), // Add shop_id if it's not empty
          ...(shopName ? { shop_name: shopName } : {}), // Add shop_id if it's not empty
          ...(country ? { country: country } : {}), // Add shop_id if it's not empty
          ...(supplier ? { supplier_id: supplier } : {}), // Add shop_id if it's not empty
          ...(receiptID ? { receipt_id: receiptID } : {}), // Add shop_id if it's not empty
          ...(buyerEmail ? { buyer_email: buyerEmail } : {}), // Add buyer_email if it's not empty
          ...(receiverName ? { contact_name: receiverName } : {}), // Add contact_name if it's not empty
          ...(formattedAddress ? { formatted_address: formattedAddress } : {}), // Add formatted_address if it's not empty
          ...(status ? { status: status } : {}), // Add formatted_address if it's not empty
          ...(parcelType ? { parcel_type: parcelType } : {}), // Add parcelType if it's not empty
          ...(isInvoiceFile ? { is_invoice_file: isInvoiceFile } : {}), // Add parcelType if it's not empty
          ...(parcelRefNo ? { parcel_ref_no: parcelRefNo } : {}), // Add formatted_address if it's not empty
          ...(dateType ? { date_type: dateType } : {}), // Add formatted_address if it's not empt
          ...(receiptDateStart ? { start_date: receiptDateStart } : {}),
          ...(receiptDateEnd ? { end_date: receiptDateEnd } : {}),
          ...(createDateStart ? { start_date: createDateStart } : {}),
          ...(createDateEnd ? { end_date: createDateEnd } : {}),

          // Add formatted_address if it's not empty
        },
      })
      .then((response) => {
        setFastList(response.data.data);
        setFastShipmentTotalPages(response.data.totalPages);
        setFastShipmentCurrentPage(response.data.currentPage);
      })
      .catch((error) => Errors(error))
      .finally(() => setLoading(false));
  };

  const handleClearDetailedSearch = () => {
    setShopID("");
    setShopName("");
    setCountry("");
    setSupplier("");
    setReceiptID("");
    setBuyerEmail("");
    setReceiverName("");
    setFormattedAddress("");
    setStatus("");
    setParcelType("");
    setIsInvoiceFile("");
    setParcelRefNo("");
    setReceiptDateStart("");
    setReceiptDateEnd("");
    setCreateDateStart("");
    setCreateDateEnd("");
    setReceiptDate("");
    setCreatedDate("");
    setDateType("");
  }

  return (
    <form onSubmit={handleDetailedSearch} className="fast-shipment-detailed-search">
      <div className="search-inputs">
        {
          window.location.href.endsWith("create-fast-shipment") && (
            <ShopIDSearch shopID={shopID} setShopID={setShopID} />
          )
        }
        <ShopNameSearch shopName={shopName} setShopName={setShopName} />
        <Country country={country} setCountry={setCountry} />
        <Suppliers supplier={supplier} setSupplier={setSupplier} />
        <ReceiptIDSearch receiptID={receiptID} setReceiptID={setReceiptID} />
        <BuyerEmail buyerEmail={buyerEmail} setBuyerEmail={setBuyerEmail} />
        <ReceiverName receiverName={receiverName} setReceiverName={setReceiverName} />
        <Status status={status} setStatus={setStatus} />
        <ParcelRefNo parcelRefNo={parcelRefNo} setParcelRefNo={setParcelRefNo} />
        <DateType dateType={dateType} setDateType={setDateType} />
        {dateType === "receipt_date" && (
          <ReceiptDate setReceiptDateStart={setReceiptDateStart} setReceiptDateEnd={setReceiptDateEnd} receiptDateStart={receiptDateStart} receiptDateEnd={receiptDateEnd} dateType={dateType} receiptDate={receiptDate} setReceiptDate={setReceiptDate} />
        )}
        {dateType === "created_at" && (
          <CreatedDate setCreateDateStart={setCreateDateStart} setCreateDateEnd={setCreateDateEnd} createDateStart={createDateStart} createDateEnd={createDateEnd} dateType={dateType} createdDate={createdDate} setCreatedDate={setCreatedDate} />
        )}
        <FormattedAddress
          formattedAddress={formattedAddress}
          setFormattedAddress={setFormattedAddress}
        />
        <ParcelType parcelType={parcelType} setParcelType={setParcelType} />
        {/* <IsInvoiceFile IsInvoiceFile={isInvoiceFile} setIsInvoiceFile={setIsInvoiceFile} /> */}
      </div>
      <Box sx={{
        display: "flex",
        width: "100%",
        gap: "15px"
      }}>
        <Button sx={{
          width: "50%"
        }} type="submit" className="iyzi-button-blue">
          Detaylı Ara
        </Button>
        <Button sx={{
          width: "50%"
        }} onClick={handleClearDetailedSearch} className="iyzi-button-blue">
          Detaylı Aramayı Temizle
        </Button>
      </Box>


    </form>
  );
};

export default FastShipmentsDetailedSearch;
