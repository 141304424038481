import "../style/returnShipment/ReturnShipment.css";
import { useState } from "react"
import { Box } from "@mui/material";
import { useCookies } from "react-cookie";
import axios from "../api/axios";
import NotificationBar from "../components/sidebar/NotificationBar";
import { DynamicTitle } from "../utils/DynamicTitle";
import { ToastContainer } from "react-toastify";
import EditShipmentAddress from "../components/myShipments/modals/EditShipmentAddress";
import AddNote from "../components/myShipments/modals/AddNote";
import MyShipmentUpload from "../components/myShipments/modals/MyShipmentUpload";
import ShowFinancialDetails from "../components/myShipments/modals/ShowFinancialDetails";
import ConnectedPayments from "../components/myShipments/modals/ConnectedPayments";
import ShipmentDetail from "../components/myShipments/modals/ShipmentDetail";
import MyShipmentPayment from "../components/myShipments/modals/MyShipmentPayment";
import ReturnShipmentParcelReturn from "../components/returnShipment/modals/ReturnShipmentParcelReturn";
import ReturnShipmentPriceInformation from "../components/returnShipment/modals/ReturnShipmentPriceInformation";
import ReturnShipmentAbroadPriceInformation from "../components/returnShipment/modals/ReturnShipmentAbroadPriceInformation";
import ReturnShipmentInformation from "../components/returnShipment/modals/ReturnShipmentInformation";
import ReturnShipmentConfirmation from "../components/returnShipment/modals/ReturnShipmentConfirmation";
import ReturnShipmentPayment from "../components/returnShipment/modals/ReturnShipmentPayment";
import ReturnShipmentList from "../components/returnShipment/ReturnShipmentList";
import WrapperStyle from "../utils/WrapperStyle";
// import PageTransition from "../utils/PageTransition";
import ReturnShipmentSearch from "../components/returnShipment/ReturnShipmentSearch";
import { showReturnShipmentInformationState } from "../atoms/returnShipment";
import { useRecoilState } from "recoil";
import ReturnShipmentTitle from "../components/returnShipment/ReturnShipmentTitle";

const ReturnShipment = () => {
  DynamicTitle("İadeler | iyziShip");

  const [token] = useCookies(["jwt"]);
  const jwt = token.jwt;
  axios.defaults.headers.common["Authorization"] = `Bearer ${jwt}`;
  const [showReturnShipmentInformation] = useRecoilState(showReturnShipmentInformationState);

  const [loadingReturnBtn, setLoadingReturnBtn] = useState(false);

  return (
    <section className="returnShipment">
      <NotificationBar />
      {/* <PageTransition> */}
        <ToastContainer />
        <ReturnShipmentTitle />
        <Box className="returnShipment-wrapper" style={WrapperStyle()}>
          <ReturnShipmentSearch />
          <ReturnShipmentList loadingReturnBtn={loadingReturnBtn} />
        </Box>
        <>
          <EditShipmentAddress />
          <AddNote />
          <MyShipmentUpload />
          <ShowFinancialDetails />
          <ConnectedPayments />
          <ShipmentDetail />
          <MyShipmentPayment />
          <ReturnShipmentParcelReturn />
          <ReturnShipmentPriceInformation />
          <ReturnShipmentAbroadPriceInformation />
          {showReturnShipmentInformation && <ReturnShipmentInformation />}
          <ReturnShipmentConfirmation setLoadingReturnBtn={setLoadingReturnBtn} />
          <ReturnShipmentPayment />
        </>
      {/* </PageTransition> */}
    </section>
  );
};

export default ReturnShipment;
