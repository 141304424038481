import AccountBalanceOutlinedIcon from "@mui/icons-material/AccountBalanceOutlined";
import ContentCopyOutlinedIcon from "@mui/icons-material/ContentCopyOutlined";
import { IconButton, Tooltip } from "@mui/material";
import { useState } from "react";

const BankInfo = () => {
  const [copied, setCopied] = useState(false);
  const iban = "TR98 0006 2001 4750 0006 2963 88";

  const handleClick = async () => {
    try {
      await navigator.clipboard.writeText(iban);
      setCopied(true);
      setTimeout(() => setCopied(false), 2000);
    } catch (err) {
      console.error("Kopyalama Hatası: ", err);
    }
  };

  return (
    <div className="info-item">
      <h2>
        <div
          style={{
            fontSize: "inherit",
            color: "inherit",
            display: "flex",
            flexWrap: "wrap",
            gap: "5px",
            alignItems: "center",
          }}
        >
          <AccountBalanceOutlinedIcon /> Banka Bilgilerimiz
        </div>
      </h2>
      <div className="bank">
        <img src="/images/info/garanti-info.png" alt="Garanti Bankası" />
        <div className="bank-text">
          <span>Garanti Bankası</span>
          <span>İYZİ BİLİŞİM ANONİM ŞİRKETİ</span>
          <span>
            {iban}
            <Tooltip title={copied ? "Kopyalandı!" : "Kopyalamak için tıkla"}>
              <IconButton
                onClick={handleClick}
                onMouseLeave={() => setCopied(false)}
              >
                <ContentCopyOutlinedIcon />
              </IconButton>
            </Tooltip>
          </span>
          <span>
            EFT veya Havale işlemlerinizi gerçekleştirirken, açıklama kısmına
            sadece rakamsal olarak iyziShip üye numaranızı yazmanızı rica
            ederiz.
          </span>
        </div>
      </div>
    </div>
  );
};

export default BankInfo;
