import { Box } from "@mui/material";
import ControlPointOutlinedIcon from "@mui/icons-material/ControlPointOutlined";
import { useRecoilState } from "recoil";
import { newIossNumberAnimationState } from "../../../atoms/membership";

const AddIossNumber = () => {
  const [, setNewIossNumberAnimation] = useRecoilState(newIossNumberAnimationState);

  return (
    <Box className="new-address-adder-wrapper">
      <button onClick={() => setNewIossNumberAnimation(true)} className="new-address-adder">
        <ControlPointOutlinedIcon />
        Yeni IOSS/VAT Numarası Ekle
      </button>
    </Box>
  );
};

export default AddIossNumber;
