import { ThemeProvider, createTheme } from "@mui/material";
import FrequentAccordions from "./FrequentAccordions";
import FrequentSearch from "./FrequentSearch";
import { useRef, useState } from "react";

const theme = createTheme({
  components: {
    MuiAccordion: {
      styleOverrides: {
        root: {
          margin: "0 !important",
          boxShadow: "0px 2px 10px rgba(0, 0, 0, 0.1) !important",
          borderRadius: "8px",

          ":before": {
            height: "0 !important",
          },
        },
      },
    },
    MuiAccordionSummary: {
      styleOverrides: {
        content: {
          margin: "12px 0 !important",
        },
        root: {
          minHeight: "48px !important",
        },
      },
    },
  },
});

const FrequentQuestions = () => {
  const accordionRefs = useRef([]);
  const [value, setValue] = useState("");

  const handleSearch = (e) => {
    setValue(e.target.value);
    const searchTerm = e.target.value.toLowerCase();

    accordionRefs.current.forEach((ref, index) => {
      const accordionTitle = ref.innerText.toLowerCase();

      if (accordionTitle.includes(searchTerm)) {
        ref.closest(".MuiAccordion-root").style.display = "block";
      } else {
        ref.closest(".MuiAccordion-root").style.display = "none";
      }
    });
  };

  const handleButtonSearch = (e) => {
    setValue(e);
    const searchTerm = e.toLowerCase();

    accordionRefs.current.forEach((ref, index) => {
      const accordionTitle = ref.innerText.toLowerCase();

      if (accordionTitle.includes(searchTerm)) {
        ref.closest(".MuiAccordion-root").style.display = "block";
      } else {
        ref.closest(".MuiAccordion-root").style.display = "none";
      }
    });
  };

  return (
    <ThemeProvider theme={theme}>
      <section className="sss">
        <div className="sss-headline">
          <h1>Sıkça Sorulan Sorular</h1>
        </div>
        <FrequentSearch
          handleSearch={handleSearch}
          handleButtonSearch={handleButtonSearch}
          value={value}
        />
        <FrequentAccordions accordionRefs={accordionRefs} />
      </section>
    </ThemeProvider>
  );
};

export default FrequentQuestions;
