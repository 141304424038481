import { Box, Divider } from "@mui/material";

const CourierListItemDetails = ({ item }) => {
  return (
    <Box className="courier-list-item-details">
      <Divider
        sx={{
          backgroundColor: "var(--inputBackgroundColor)",
          width: "100%",
        }}
      />
      {item.received_parcels.map((awb) => (
        <>
          <Box className="list-item-details">
            <Box className="list-item-detail">
              AWB:{" "}
              <span className="list-item-detail-span">{awb.parcel_ref_no}</span>
            </Box>
            <Box
              className={
                Number(awb.status) === 3
                  ? "list-item-status closed"
                  : "list-item-status active"
              }
            >
              {Number(awb.status) === 3 ? "Teslim Alındı" : "Tamamlandı"}
            </Box>
          </Box>
          <Divider
            sx={{
              backgroundColor: "var(--inputBackgroundColor)",
              width: "100%",
            }}
          />
        </>
      ))}
    </Box>
  );
};

export default CourierListItemDetails;
