import {
  Button,
  CircularProgress,
  MenuItem,
  Select,
  TableCell,
  TableRow,
  ThemeProvider,
  Tooltip,
  createTheme,
  FormControl,
  InputLabel,
  Box,
  TextField,
  InputAdornment
} from "@mui/material";
import LocalPrice from "../../../utils/LocalPrice";
import { useState, useCallback, useEffect, useRef } from "react";
import axios from "../../../api/axios";
import { toast } from "react-toastify";
import Errors from "../../../utils/Errors";
import { useRecoilState, useRecoilValue, useSetRecoilState } from "recoil";
import { fastListState, selectedFilesIntState } from "../../../atoms/fastShipment";
import Currency from "../../../utils/CurrencySymbol";
import countries from "../../../json/countries.json";
import iossCountries from "../../../json/iossCountries.json";
import uploadIcon from "../../../img/icons/upload_icon.svg";
import InfoOutlinedIcon from "@mui/icons-material/InfoOutlined";
import DowloadPdfView from "../../dowloadPdfView/DowloadPdfView";
import { integrationsState, newIossNumberAnimationState, iossFastState } from "../../../atoms/membership";
import { platformCountryState, proformaChangeState, itemPriceChangeState } from "../../../atoms/profileSettings";
import { proformaModalState, } from "../../../atoms/createShipment";
import { useDropzone } from "react-dropzone";
import {
  FormatReturnFieldWithTwoDecimals,
  FormatReturnInteger,
} from "../../../utils/FormatFieldReturn";
import FormatDateHour from "../../../utils/FormatDateHour";
import GetFile from "../../../utils/GetFile";

const theme = createTheme({
  components: {
    MuiTableCell: { styleOverrides: { root: { padding: "8px" } } },
  },
});

const PackageListItem = ({
  itemID,
  selectedCountryForIoss,
  product,
  selectedFastShipmentProduct,
  setSelectedFastShipmentProduct,
  setOpenPriceUpdateConfirmation,
  setUpdatedCommoduty,
  setOpenPriceUpdate,
  setPriceID,
  setPriceIDValue,
  isEditable,
  setSelectedPriceObject,
  index,
  handleClose,
  handleCheckPrice,
  priceIsDisabled,
  allCurrenciesSame,
  setProforma,
  isLastItem,
  isFirstItem,
  totalPriceAll,
  totalQuantityAll,
  initialTotalPrice,
  setInitialTotalPrice,
  bulkPrice,
  setBulkPrice,
  setTotalPriceCalc
}) => {
  const [loading, setLoading] = useState(false);
  const [hsCodeError, setHsCodeError] = useState(false);
  const [hsCodeBulkError, setHsCodeBulkError] = useState(false);
  const [hsCodeChanged, setHsCodeChanged] = useState(false);
  const initialHSCode = useRef(product.hs_code);
  const [proformaCountry, setProformaCountry] = useState(product.country);
  const integrations = useRecoilValue(integrationsState);
  const setProformaModal = useSetRecoilState(proformaModalState);
  const setNewIossNumberAnimation = useSetRecoilState(newIossNumberAnimationState);
  const setPlatformCountry = useSetRecoilState(platformCountryState);
  const setProformaChange = useSetRecoilState(proformaChangeState);
  const setItemPriceChange = useSetRecoilState(itemPriceChangeState);
  const IOSSCountry = iossCountries.find((country) => country.iso_code === itemID?.country) || iossCountries.find((country) => country.iso_code === selectedCountryForIoss);

  const [sendFiles, setSendFiles] = useRecoilState(selectedFilesIntState);
  const [iossFast, setIossFast] = useRecoilState(iossFastState);
  const [selectedFiles, setSelectedFiles] = useState([]);

  const [fastList, setFastList] = useRecoilState(fastListState);
  const [initialPrice, setInitialPrice] = useState(product.price);

  const [bulkParcelType, setBulkParcelType] = useState("");
  const [bulkCurrency, setBulkCurrency] = useState("");
  const [bulkCountry, setBulkCountry] = useState("");
  const [bulkTitle, setBulkTitle] = useState("");
  const [bulkSku, setBulkSku] = useState("");
  const [bulkHsCode, setBulkHsCode] = useState("");
  const [bulkQuantity, setBulkQuantity] = useState("");
  const [totalPrice, setTotalPrice] = useState("");

  useEffect(() => {
    // Calculate and set the initial total price when the component mounts
    setInitialTotalPrice(calculateTotalPrice());
  }, []);

  const handleBulkUpdate = () => {
    const updatedProducts = selectedFastShipmentProduct.map((product) => ({
      ...product,
      parcel_type: bulkParcelType || product.parcel_type,
      currency: bulkCurrency || product.currency,
      country: bulkCountry || product.country,
      title: bulkTitle || product.title,
      sku: bulkSku || product.sku,
      hs_code: bulkHsCode || product.hs_code,
      quantity: bulkQuantity || product.quantity,
      price: bulkPrice || product.price,
    }));
    setSelectedFastShipmentProduct(updatedProducts);
  };

  const handleInputChange = (e) => {
    setProformaCountry(e.target.value);
  };

  const handleProductChange = useCallback((e, key) => {
    setSelectedFastShipmentProduct((prevProducts) => prevProducts.map((product, i) =>
      i === key ? { ...product, title: e.target.value } : product
    ));
  }, [setSelectedFastShipmentProduct]);

  const handleQuantityChange = useCallback((e, key) => {
    setSelectedFastShipmentProduct((prevProducts) => prevProducts.map((product, i) =>
      i === key ? { ...product, quantity: e.target.value } : product
    ));
  }, [setSelectedFastShipmentProduct]);

  const handlePriceChange = useCallback((e, key) => {
    const value = FormatReturnFieldWithTwoDecimals(e.target.value);
    setSelectedFastShipmentProduct((prevProducts) => prevProducts.map((product, i) =>
      i === key ? { ...product, price: value } : product
    ));
    setInitialPrice(product.price); // Store the initial price
  }, [setSelectedFastShipmentProduct]);

  const handleCountryChange = useCallback((e, key) => {
    setSelectedFastShipmentProduct((prevProducts) => prevProducts.map((product, i) =>
      i === key ? { ...product, country: e.target.value } : product
    ));
  }, [setSelectedFastShipmentProduct]);

  const handleSkuChange = useCallback((e, key) => {
    setSelectedFastShipmentProduct((prevProducts) => prevProducts.map((product, i) =>
      i === key ? { ...product, sku: e.target.value } : product
    ));
  }, [setSelectedFastShipmentProduct]);

  const handleParcelTypeChange = useCallback((e, key) => {
    setSelectedFastShipmentProduct((prevProducts) => prevProducts.map((product, i) =>
      i === key ? { ...product, parcel_type: e.target.value } : product
    ));
  }, [setSelectedFastShipmentProduct]);

  const handleCurrencyChange = useCallback((e, key) => {
    setSelectedFastShipmentProduct((prevProducts) => prevProducts.map((product, i) =>
      i === key ? { ...product, currency: e.target.value } : product
    ));
  }, [setSelectedFastShipmentProduct]);

  const handleIossChange = useCallback((e, key) => {
    setSelectedFastShipmentProduct((prevProducts) => prevProducts.map((product, i) =>
      i === key ? { ...product, ioss: e.target.value } : product
    ));
  }, [setSelectedFastShipmentProduct]);

  const handleBillNameChange = useCallback((e, key) => {
    setSelectedFastShipmentProduct((prevProducts) => prevProducts.map((product, i) =>
      i === key ? { ...product, invoice_no: e.target.value } : product
    ));
  }, [setSelectedFastShipmentProduct]);

  const handleBillDateChange = useCallback((e, key) => {
    setSelectedFastShipmentProduct((prevProducts) => prevProducts.map((product, i) =>
      i === key ? { ...product, invoice_date: e.target.value } : product
    ));
  }, [setSelectedFastShipmentProduct]);

  const handleHSCodeChange = useCallback((e, key) => {
    const value = e.target.value;
    setSelectedFastShipmentProduct((prevProducts) => prevProducts.map((product, i) =>
      i === key ? { ...product, hs_code: FormatReturnInteger(value) } : product
    ));
    setHsCodeError(false);
    if (value !== initialHSCode.current) {
      setHsCodeChanged(true);
    }
  }, [setSelectedFastShipmentProduct]);

  const handleHSCodeBlur = (e, key) => {
    const value = e.target.value;
    const inputLength = e.target.value.length;

    const newHSCode = selectedFastShipmentProduct.map((item, index) => {
      if (index === key) {
        if (inputLength < 6 && inputLength !== 0) {
          setHsCodeError(true);
        } else {
          setHsCodeError(false);
        }

        if (inputLength >= 6 && inputLength <= 12) {
          return { ...item, hs_code: value + "0".repeat(12 - inputLength) };
        }
      }
      return item;
    });

    setSelectedFastShipmentProduct(newHSCode);
  };

  const handleBulkHSCodeBlur = (e) => {
    const value = e.target.value;
    const inputLength = value.length;

    if (inputLength < 6 && inputLength !== 0) {
      setHsCodeBulkError(true);
    } else {
      setHsCodeBulkError(false);
    }

    if (inputLength >= 6 && inputLength <= 12) {
      setBulkHsCode(value + "0".repeat(12 - inputLength));
    }
  };

  const handleIOSSCreate = useCallback(() => {
    setProformaModal(true);
    setPlatformCountry(IOSSCountry);
    setNewIossNumberAnimation(true);
  }, [IOSSCountry, setProformaModal, setNewIossNumberAnimation]);

  const handleEditSubmit = async () => {
    if (!hsCodeError) {
      setLoading(true);

      const formData = new FormData();

      selectedFastShipmentProduct.forEach((product, productIndex) => {
        formData.append(`commoduties[${productIndex}][commoduty_id]`, product.id);
        formData.append(`commoduties[${productIndex}][integration_parcel_id]`, product.integration_parcel_id);
        formData.append(`commoduties[${productIndex}][title]`, product.title);
        formData.append(`commoduties[${productIndex}][sku]`, product.sku);
        formData.append(`commoduties[${productIndex}][parcel_type]`, selectedFastShipmentProduct[0]?.parcel_type ?? 1);
        formData.append(`commoduties[${productIndex}][country]`, product.country || "TR");
        formData.append(`commoduties[${productIndex}][quantity]`, product.quantity);
        formData.append(`commoduties[${productIndex}][hs_code]`, product.hs_code);
        formData.append(`commoduties[${productIndex}][price]`, product.price);
        formData.append(`commoduties[${productIndex}][currency]`, selectedFastShipmentProduct[0]?.currency);
        formData.append(`commoduties[${productIndex}][total_price]`, Number(product.quantity) * Number(product.price));
        formData.append(`commoduties[${productIndex}][ioss]`, selectedFastShipmentProduct[0].ioss === "null" || null ? " " : selectedFastShipmentProduct[0].ioss);

        if (productIndex === 0) {
          sendFiles.forEach((file, fileIndex) => {
            formData.append(`commoduties[${productIndex}][files][${fileIndex}]`, file.file);
          });
        }
      });

      try {
        const response = await axios.post(`general/update-commoduty`, formData, {
          headers: {
            'Content-Type': 'multipart/form-data'
          }
        });

        toast.success(response.data.message);

        const updatedFastList = fastList.map((item) => {
          if (item.id === Number(product.integration_parcel_id)) {
            setItemPriceChange(true);
            return {
              ...item,
              get_commoduties: response.data.data?.get_commoduties,
              get_integration_parcel_documents: response.data.data?.get_integration_parcel_documents,
            };
          }
          return item;
        });
        const totalPriceChanged = calculateTotalPrice() !== initialTotalPrice;
        if (!priceIsDisabled && totalPriceChanged) {
          handleCheckPrice();
          setSelectedPriceObject("");
          setPriceID("");
          setPriceIDValue("");
        }
        setUpdatedCommoduty(response.data.data.updated_commoduty)
        setFastList(updatedFastList);
        setProformaChange(true);
        setProforma(true);
      } catch (error) {
        Errors(error);
      } finally {
        setLoading(false);
        handleClose();
      }
    }
  };

  const message = () => {
    if (hsCodeError && !allCurrenciesSame) {
      return "Hs Kodu 6 haneden küçük girilemez ve tüm döviz cinsleri aynı olmalıdır.";
    } else if (hsCodeError) {
      return "Hs Kodu 6 haneden küçük girilemez.";
    } else if (!allCurrenciesSame) {
      return "Tüm döviz cinsleri aynı olmalıdır.";
    }
  };

  const handlePriceBlur = useCallback((e, key) => {
    const value = parseFloat(e.target.value).toFixed(2);
    setSelectedFastShipmentProduct((prevProducts) => prevProducts.map((product, i) =>
      i === key ? { ...product, price: value } : product
    ));
  }, [setSelectedFastShipmentProduct]);

  // Helper function to calculate total price
  const calculateTotalPrice = () => {
    return selectedFastShipmentProduct.reduce((total, product) => {
      return total + (Number(product.quantity) * Number(product.price));
    }, 0);
  };

  const handleFileClick = (fileUrl) => {
    window.open(fileUrl, "_blank");
  };

  useEffect(() => {
    // Calculate and set the initial total price when the component mounts
    setTotalPriceCalc(calculateTotalPrice());
  }, [calculateTotalPrice()]);

  return (
    <ThemeProvider theme={theme}>
      <Box className="package-inputs">
        {isFirstItem && (
          <>
            <Box
              sx={{
                display: "flex",
                gap: "20px",
                marginTop: "20px",
                marginBottom: "20px",
              }}
              className="package-inputs-first"
            >
              <FormControl sx={{ width: "50%" }}>
                <InputLabel id="select-product-description">
                  Gönderi Açıklaması
                </InputLabel>
                <Select
                  labelId="select-product-description"
                  id="select-simple-product-description"
                  value={selectedFastShipmentProduct[0]?.parcel_type ?? 1}
                  required
                  onChange={(e) => handleParcelTypeChange(e, index)}
                  label="Gönderi Açıklaması"
                  name="parcel_type"
                >
                  <MenuItem value="">
                    <em>Temizle</em>
                  </MenuItem>
                  <MenuItem value={1}>Normal Gönderi veya Numune</MenuItem>
                  <MenuItem value={2}>Hediye</MenuItem>
                  <MenuItem value={3}>
                    Mikro İhracat (ETGB'li) E-ihracat
                  </MenuItem>
                </Select>
              </FormControl>
              <FormControl sx={{ width: "50%" }}>
                <InputLabel id="select-currency">Döviz</InputLabel>
                <Select
                  labelId="select-currency"
                  id="select-simple-currency"
                  value={selectedFastShipmentProduct[0]?.currency}
                  onChange={(e) => handleCurrencyChange(e, index)}
                  label="Döviz"
                  name="currency"
                >
                  <MenuItem value="">
                    <em>Temizle</em>
                  </MenuItem>
                  <MenuItem value={"EUR"}>€</MenuItem>
                  <MenuItem value={"USD"}>$</MenuItem>
                  <MenuItem value={"GBP"}>£</MenuItem>
                </Select>
              </FormControl>
              {IOSSCountry && (
                <FormControl
                  sx={{
                    width: "50%",
                    height: "40px",
                  }}
                >
                  <InputLabel id="select-ioss">IOSS/VAT</InputLabel>
                  <Select
                    labelId="select-ioss"
                    id="select-simple-ioss"
                    value={product.ioss}
                    onChange={(e) => handleIossChange(e, index)}
                    label="IOSS/VAT"
                    sx={{ height: "52px" }}
                  >
                    <MenuItem value="">
                      <em>Temizle</em>
                    </MenuItem>
                    <MenuItem
                      sx={{
                        color: "var(--btnBgBlue)",
                        textDecoration: "underline",
                      }}
                      value={""}
                      onClick={handleIOSSCreate}
                    >
                      <em>IOSS Kaydı Oluştur</em>
                    </MenuItem>
                    {integrations?.map((integration) => (
                      <MenuItem
                        sx={{
                          display: "flex",
                          gap: "var(--gap)",
                          alignItems: "center",
                        }}
                        key={integration.id}
                        value={integration.ioss_number}
                      >
                        <img
                          src={`/images/integrations/${integration.market_place}.svg`}
                          style={{
                            width: "50px",
                            height: "20px",
                            objectFit: "contain",
                            objectPosition: "center",
                          }}
                          alt={`${integration.market_place} logo`}
                        />{" "}
                        {integration.ioss_number}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              )}
            </Box>
            <Box sx={{ fontSize: '20px', fontWeight: '500', paddingLeft: '5px', marginBottom: "20px" }}>
              Toplu Proforma Atama
            </Box>
            <Box
              sx={{
                display: "flex",
                gap: "20px",
                justifyContent: "space-between",
              }}
              className="package-inputs-second"
            >
              <Select
                value={bulkCountry}
                onChange={(e) => setBulkCountry(e.target.value)}
                sx={{ height: "54px", width: "50%" }}
                displayEmpty
                renderValue={(selected) => {
                  if (selected === "") {
                    return "Ülke Seçiniz";
                  }
                  const country = countries.find((country) => country.code === selected);
                  return (
                    <Box sx={{ display: 'flex', alignItems: 'center' }}>
                      <img
                        loading="lazy"
                        style={{ marginRight: "5px", width: "20px" }}
                        src={`https://flagcdn.com/w20/${country.code.toLowerCase()}.png`}
                        srcSet={`https://flagcdn.com/w40/${country.code.toLowerCase()}.png 2x`}
                        alt={`${country.label} Milli Bayrağı Görseli`}
                      />
                      {country.label}
                    </Box>
                  );
                }}
              >
                <MenuItem value="">
                  Temizle
                </MenuItem>
                {countries.map((country, key) => (
                  <MenuItem key={key} value={country.code}>
                    <img
                      loading="lazy"
                      style={{ marginRight: "5px", width: "20px" }}
                      src={`https://flagcdn.com/w20/${country.code.toLowerCase()}.png`}
                      srcSet={`https://flagcdn.com/w40/${country.code.toLowerCase()}.png 2x`}
                      alt={`${country.label} Milli Bayrağı Görseli`}
                    />
                    {country.label}
                  </MenuItem>
                ))}
              </Select>

              <TextField
                value={bulkTitle}
                onChange={(e) => setBulkTitle(e.target.value)}
                name="title"
                label="Ürün Açıklaması"
                autoComplete="off"
                sx={{ width: "80%" }}
              />
              <TextField
                value={bulkSku}
                onChange={(e) => setBulkSku(e.target.value)}
                name="sku"
                label="SKU"
                autoComplete="off"
                sx={{ width: "50%" }}
              />
              <TextField
                sx={{ width: "50%" }}
                value={bulkHsCode}
                onChange={(e) => setBulkHsCode(e.target.value)}
                onBlur={handleBulkHSCodeBlur}
                required
                name="hs_code"
                label="HS Kodu"
                autoComplete="off"
                inputProps={{
                  minLength: 6,
                  maxLength: 12,
                }}
                error={hsCodeBulkError}
                helperText={
                  <>
                    {hsCodeBulkError && "Hs Kodu 6 haneden küçük girilemez."}
                    {!hsCodeBulkError && (
                      <a
                        rel="noreferrer"
                        href="https://uygulama.gtb.gov.tr/Tara/"
                        target="_blank"
                        tabIndex={-1}
                        style={{
                          fontSize: "inherit",
                          color: "var(--btnBgBlue)",
                        }}
                      >
                        GTIP - HSCODE sorgula
                      </a>
                    )}
                  </>
                }
              />
              <TextField
                sx={{ width: "50%" }}
                value={bulkQuantity}
                onChange={(e) => setBulkQuantity(e.target.value)}
                name="qty"
                label="Miktar"
                autoComplete="off"
                inputProps={{
                  min: 1, // En küçük değer sıfır olmalıdır
                }}
                error={bulkQuantity < 1 && bulkQuantity.length !== 0}
                helperText={
                  bulkQuantity < 1 &&
                  bulkQuantity.length !== 0 &&
                  "Miktar 1'dan küçük olamaz."
                }
              />
              <TextField
                sx={{ width: "30%" }}
                value={bulkPrice}
                onChange={(e) => setBulkPrice(e.target.value)}
                name="price"
                label="Birim Fiyatı"
                autoComplete="off"
                InputProps={{
                  startAdornment: (
                    <InputAdornment>{Currency(selectedFastShipmentProduct[0]?.currency)}</InputAdornment>
                  ),
                }}
              />
            </Box>
            <Box sx={{
              display: "flex", justifyContent: "flex-end", marginBottom: "30px"
            }}>
              <Button onClick={handleBulkUpdate} className="iyzi-button-blue">
                Toplu Güncelle
              </Button>
            </Box>
          </>
        )}

        <Box
          sx={{
            display: "flex",
            gap: "20px",
            justifyContent: "space-between",
          }}
          className="package-inputs-second"
        >
          <Select
            value={product.country || "TR"}
            onChange={(e) => handleCountryChange(e, index)}
            sx={{ height: "54px", width: "50%" }}
          >
            <MenuItem value="">
              <em>Temizle</em>
            </MenuItem>
            {countries.map((country, key) => (
              <MenuItem key={key} value={country.code}>
                <img
                  loading="lazy"
                  style={{ marginRight: "5px", width: "20px" }}
                  src={`https://flagcdn.com/w20/${country.code.toLowerCase()}.png`}
                  srcSet={`https://flagcdn.com/w40/${country.code.toLowerCase()}.png 2x`}
                  alt={`${country.label} Milli Bayrağı Görseli`}
                />
                {country.label}
              </MenuItem>
            ))}
          </Select>
          <TextField
            value={product.title}
            required
            onChange={(e) => handleProductChange(e, index)}
            name="title"
            label="Ürün Açıklaması"
            autoComplete="off"
            sx={{ width: "80%" }}
          />
          <TextField
            value={product.sku}
            onChange={(e) => handleSkuChange(e, index)}
            name="sku"
            label="SKU"
            autoComplete="off"
            sx={{ width: "50%" }}
          />
          <TextField
            sx={{ width: "50%" }}
            value={product.hs_code}
            required
            onChange={(e) => handleHSCodeChange(e, index)}
            name="hs_code"
            label="HS Kodu"
            autoComplete="off"
            inputProps={{
              minLength: 6,
              maxLength: 12,
            }}
            onBlur={(e) => handleHSCodeBlur(e, index)}
            error={hsCodeError}
            helperText={
              <>
                {hsCodeError && "Hs Kodu 6 haneden küçük girilemez."}
                {!hsCodeError && (
                  <a
                    rel="noreferrer"
                    href="https://uygulama.gtb.gov.tr/Tara/"
                    target="_blank"
                    tabIndex={-1}
                    style={{
                      fontSize: "inherit",
                      color: "var(--btnBgBlue)",
                    }}
                  >
                    GTIP - HSCODE sorgula
                  </a>
                )}
              </>
            }
          />
          <TextField
            sx={{ width: "50%" }}
            value={product.quantity}
            required
            onChange={(e) => handleQuantityChange(e, index)}
            name="qty"
            label="Miktar"
            autoComplete="off"
            inputProps={{
              min: 1, // En küçük değer sıfır olmalıdır
            }}
            error={product.quantity < 1 && product.quantity.length !== 0}
            helperText={
              product.quantity < 1 &&
              product.quantity.length !== 0 &&
              "Miktar 1'dan küçük olamaz."
            }
          />
          <TextField
            sx={{ width: "30%" }}
            value={product.price}
            required
            onChange={(e) => handlePriceChange(e, index)}
            onBlur={(e) => handlePriceBlur(e, index)}
            name="price"
            label="Birim Fiyatı"
            autoComplete="off"
            InputProps={{
              startAdornment: (
                <InputAdornment>{Currency(selectedFastShipmentProduct[0]?.currency)}</InputAdornment>
              ),
            }}
          />
        </Box>
        {isLastItem && (
          <>
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                alignItems: "flex-end",
              }}
            >
              <div
                style={{
                  backgroundColor: "var(--background)",
                  boxShadow: "var(--shadow)",
                  padding: "10px",
                  borderRadius: "var(--inputBorderRadius)",
                  color: "var(--priceText)",
                  display: "flex",
                  alignItems: "center",
                  gap: "5px",
                }}
              >
                Toplam Fiyat: {Currency(selectedFastShipmentProduct[0]?.currency)}
                {LocalPrice(calculateTotalPrice())}{" "}
              </div>
            </div>
            <Box
              sx={{
                display: "flex",
                justifyContent: "flex-end",
                gap: "10px",
                alignItems: "center",
                marginTop: "10px",
              }}
            >
              <Button
                type="button"
                onClick={handleClose}
                className="iyzi-button-blue"
              >
                İptal
              </Button>
              <Tooltip title={message()}>
                <span>
                  <Button
                    disabled={hsCodeError || product?.hs_code?.length < 12 ||
                      (Number(selectedFastShipmentProduct[0]?.parcel_type) === 3
                        ? (itemID?.get_integration_parcel_documents <= 0 && sendFiles <= 0)
                        : false)}
                    sx={{ ":disabled": { opacity: 0.3 }, }}
                    className="iyzi-button-blue"
                    onClick={handleEditSubmit}
                  >
                    Kaydet
                  </Button>
                </span>
              </Tooltip>
            </Box>
            {Number(selectedFastShipmentProduct[0]?.parcel_type) === 3 && (
              <Box>
                <p>
                  Yüklenen Dosyalar <br />
                  <br />
                </p>
                {itemID?.get_integration_parcel_documents
                  ?.filter(
                    (document, index, self) =>
                      index ===
                      self.findIndex((d) => d.name === document.name)
                  )
                  .map((document, index) => (
                    <Box
                      onClick={() => GetFile(document)}
                      key={index}
                      sx={{ marginBottom: 2 }}
                    >
                      <p>
                        <strong>Ad:</strong>
                        <a
                          href="#"
                          onClick={() => GetFile(document)} // Assuming document.url contains the file URL
                          style={{
                            color: "gray",
                            textDecoration: "none",
                            cursor: "pointer",
                          }}
                        >
                          {document.name}
                        </a>
                      </p>
                      <p>
                        <strong>Yüklenme Tarihi:</strong>{" "}
                        {FormatDateHour(document.created_at)}
                      </p>
                    </Box>
                  ))}
              </Box>
            )}
          </>
        )}
      </Box>
    </ThemeProvider >
  );
};

export default PackageListItem;
