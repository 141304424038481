import {
  Box,
  Button,
  FormControl,
  createTheme,
  Modal,
  ThemeProvider,
  FormControlLabel,
  Radio,
  RadioGroup,
} from "@mui/material";
import { useRecoilState } from "recoil";
import { toast } from "react-toastify";
import axios from "../../../api/axios";
import {
  parcelReturnOpenState,
  selectedParcelReturnState,
  deliveryTypeState,
  arrivalCostTryState,
  returnCostTryState,
  returnShipmentPriceInformationOpenState,
  returnShipmentConfirmationOpenState,
  deductionCostTryState,
  deductionKdvCostTryState,
} from "../../../atoms/returnShipment";
import CancelOutlinedIcon from "@mui/icons-material/CancelOutlined";
import ParcelReturnIcon from "@mui/icons-material/AssignmentReturn";
import ChevronRightIcon from "@mui/icons-material/ChevronRight";
import Errors from "../../../utils/Errors";

const theme = createTheme({
  components: {
    MuiModal: {
      styleOverrides: {
        root: {
          padding: "20px",
        },
      },
    },
    MuiOutlinedInput: {
      styleOverrides: {
        root: {
          borderRadius: "8px",
        },
      },
    },
  },
});

const ReturnShipmentParcelReturn = () => {
  const [parcelReturnOpen, setParcelReturnOpen] = useRecoilState(parcelReturnOpenState);
  const [, setReturnShipmentPriceInformationOpen] = useRecoilState(
    returnShipmentPriceInformationOpenState
  );
  const [selectedParcelReturn] = useRecoilState(selectedParcelReturnState);
  const [deliveryType, setDeliveryType] = useRecoilState(deliveryTypeState);
  const [, setArrivalCostTry] = useRecoilState(arrivalCostTryState);
  const [, setReturnCostTry] = useRecoilState(returnCostTryState);
  const [, setDeductionCostTry] = useRecoilState(deductionCostTryState);
  const [, setDeductionKdvCostTry] = useRecoilState(deductionKdvCostTryState);
  const [, setReturnShipmentConfirmationOpen] = useRecoilState(returnShipmentConfirmationOpenState);
  let modalTypeName = "";
  let getDeductionCostTry = 0;
  const getPrice = async () => {
    try {
      const response = await axios.get(
        `parcel-return/price/${selectedParcelReturn.id}?delivery_type=${deliveryType}`
      );
      if (response.status === 200) {
        setArrivalCostTry(Number(response.data.data.arrival_cost_try));
        setReturnCostTry(Number(response.data.data.return_cost_try));
        getDeductionCostTry = Number(response.data.data.deduction_cost_try);
        setDeductionCostTry(Number(response.data.data.deduction_cost_try));
        setDeductionKdvCostTry(Number(response.data.data.deduction_kdv_cost_try));
        //toast.success(response.data.message);
      } else if (response.status === 400) {
        toast.error(response.data.message);
      }
    } catch (error) {
      Errors(error);
    }
  };
  const pagePriceInformation = async () => {
    if (deliveryType === "non_dm_warehouse_courier") {
      try {
        const response = await axios.get(`member-address?address_type=2`);
        if (response.status === 200) {
          if (response.data.data.length === 0) {
            handleClose();
            handleWhatNextPage("collectionAddress");
            return;
          } else {
            await getPrice();
          }
        } else if (response.status === 400) {
          toast.error(response.message, { autoClose: 500 });
        }
      } catch (error) {
        Errors(error);
      }
    } else {
      await getPrice();
    }
    if (Number(selectedParcelReturn.get_parcel.status) === 2.29) modalTypeName = "returnAbroad";
    else if (Number(selectedParcelReturn.get_parcel.status) === 2.28)
      modalTypeName = "returnSupplier";
    else modalTypeName = "returnWarehouse";
    const filteredPaid = selectedParcelReturn.get_parcel.get_parcel_price_informations.filter(item =>Number(item.status) === 1)
    modalTypeName += Number(filteredPaid[0].paid) === 1 ? "Paid" : "Unpaid";
    if (deliveryType !== "new_parcel")
      modalTypeName += Number(getDeductionCostTry) === 0 ? "Free" : "Notfree";
    if (Number(selectedParcelReturn.get_parcel.status) === 2.28) {
      if (Number(selectedParcelReturn.get_parcel.invoice_id) !== 0) {
        modalTypeName += "Invoice";
        modalTypeName +=
          selectedParcelReturn.get_member.member_type === "Individual" ||
          (selectedParcelReturn.get_member.member_type === "Company" &&
            selectedParcelReturn.get_member.company_type === "Individual" &&
            selectedParcelReturn.get_member.invoice_type === "tckn")
            ? "Individual"
            : "Company";
      } else modalTypeName += "Notinvoice";
    }
    if (deliveryType === "new_parcel") {
      handleWhatNextPage(modalTypeName + "NewParcel");
    } else {
      if (deliveryType === "non_dm_frontdesk") modalTypeName += "Frontdesk";
      else if (deliveryType === "non_dm_warehouse_courier") modalTypeName += "WarehouseCourier";
      else if (deliveryType === "domestic") modalTypeName += "Domestic";
      handleClose();
      setReturnShipmentPriceInformationOpen({ status: true, modalType: modalTypeName });
    }
  };

  const handleWhatNextPage = (modalType) => {
    handleClose();
    setReturnShipmentConfirmationOpen({ status: true, modalType: modalType });
  };

  const handleClose = () => {
    setParcelReturnOpen(false);
  };

  return (
    <ThemeProvider theme={theme}>
      <Modal open={parcelReturnOpen} onClose={handleClose}>
        {selectedParcelReturn.length !== 0 ? (
          <Box
            sx={{
              backgroundColor: "var(--lighterBg)",
              borderRadius: "var(--inputBorderRadius)",
              padding: "20px",
              outline: 0,
              display: "grid",
              gap: "var(--gap)",
              width: "100%",
              maxWidth: "400px",
              margin: "auto",
              position: "relative",
            }}
            style={{
              maxHeight: "90vh",
              overflowY: "auto",
            }}
          >
            <CancelOutlinedIcon
              sx={{
                position: "absolute",
                right: 1,
                top: 1,
                cursor: "pointer",
                color: "var(--priceText)",
                transition: "all 80ms linear",
                ":hover": {
                  color: "var(--notRed)",
                  transition: "all 80ms linear",
                },
              }}
              onClick={handleClose}
            />
            <h6
              style={{
                display: "flex",
                alignItems: "center",
                gap: "5px",
                color: "var(--priceText)",
                marginBottom: "10px",
              }}
            >
              <ParcelReturnIcon />
              Gönderinizi Nasıl İade Edelim ?
            </h6>
            <Box sx={{ display: "grid", gap: "var(--gap)" }}>
              <FormControl className="parcel-return-demand" size="small" sx={{ minWidth: 180 }}>
                <RadioGroup
                  aria-label="delivery-type"
                  name="delivery-type"
                  value={deliveryType}
                  onChange={(e) => setDeliveryType(e.target.value)}
                >
                  <FormControlLabel
                    value="non_dm_frontdesk"
                    control={<Radio />}
                    label="Kendim Alacağım (Ücretsiz)"
                  />
                  <FormControlLabel
                    value="non_dm_warehouse_courier"
                    control={<Radio />}
                    label="iyziShip Kuryesi İle Gelsin (Ücretsiz)"
                  />
                  <FormControlLabel
                    value="domestic"
                    control={<Radio />}
                    label="Domestik Tedarikçi ile Gönder (Ücretli)"
                  />
                  <FormControlLabel
                    value="new_parcel"
                    control={<Radio />}
                    label="Yeni Yük Oluştur"
                  />
                </RadioGroup>
              </FormControl>
              <Button
                onClick={pagePriceInformation}
                sx={{
                  justifySelf: "flex-end",
                  backgroundColor: "var(--info)",
                  color: "var(--lighterBg)",
                  textTransform: "none",
                  transition: "all 150ms linear",
                  borderRadius: "var(--buttonBorderRadius)",
                  padding: "7px 16px",
                  ":hover": {
                    backgroundColor: "var(--blueBrand)",
                    transition: "all 150ms linear",
                  },
                }}
              >
                <ChevronRightIcon sx={{ marginRight: "5px" }} />
                İleri
              </Button>
            </Box>
          </Box>
        ) : (
          <Box
            sx={{
              backgroundColor: "var(--lighterBg)",
              borderRadius: "var(--inputBorderRadius)",
              padding: "20px",
              outline: 0,
              display: "grid",
              gap: "var(--gap)",
              width: "100%",
              maxWidth: "1100px",
              margin: "auto",
              position: "relative",
            }}
            style={{
              maxHeight: "90vh",
              overflowY: "auto",
            }}
          >
            <CancelOutlinedIcon
              sx={{
                position: "absolute",
                right: 1,
                top: 1,
                color: "var(--priceText)",
                cursor: "pointer",
                transition: "all 80ms linear",
                ":hover": {
                  color: "var(--notRed)",
                  transition: "all 80ms linear",
                },
              }}
              onClick={handleClose}
            />
            Sistem daha kendine gelemedi, sakin ol kardeşim.
          </Box>
        )}
      </Modal>
    </ThemeProvider>
  );
};
export default ReturnShipmentParcelReturn;
