import { Table, TableBody, TableContainer } from "@mui/material";
import MyWalletiyziBalanceTableHead from "./MyWalletiyziBalanceTableHead";
import MyWalletiyziBalanceTableBody from "./MyWalletiyziBalanceTableBody";
import { useRecoilValue } from "recoil";
import { iyziBalanceDataState } from "../../../atoms/myWallet";

const MyWalletiyziBalanceTable = () => {
  const iyziBalanceData = useRecoilValue(iyziBalanceDataState);

  return (
    <TableContainer
      sx={{
        backgroundColor: "var(--lighterBg)",
        borderRadius: "var(--inputBorderRadius)",
        boxShadow: "0px 2px 10px rgba(0, 0, 0, 0.1)",
      }}
    >
      <Table>
        <MyWalletiyziBalanceTableHead />
        <TableBody>
          {iyziBalanceData
            ?.filter(
              (item) => item.description_code !== "10.10" || item.description_code !== "10.20"
            )
            .map((item) => (
              <MyWalletiyziBalanceTableBody key={item.id} item={item} />
            ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
};

export default MyWalletiyziBalanceTable;
