import CheckCircleOutlineOutlinedIcon from "@mui/icons-material/CheckCircleOutlineOutlined";
import { NavLink } from "react-router-dom";
import { Button } from "@mui/material";

const ShipmentPayment = () => {
  return (
    <div className="shipmentPayment">
      <CheckCircleOutlineOutlinedIcon />
      <div
        style={{
          fontSize: "16px",
          color: "var(--priceText)",
          maxWidth: "600px",
          width: "100%",
        }}
      >
        Tebrikler! Gönderiniz oluşturuldu. Paketinizi iyziMerkez'e göndermeden önce etiket oluşturup
        paket üzerine yapıştırmanız gerekmektedir. Etiket oluşturmak için Etiket Oluştur butonuna
        tıklayınız.
      </div>
      <div className="button-wrapper">
        <NavLink className="button" to={"/orders"}>
          Etiket Oluştur ve Takip No Öğren
        </NavLink>
        <Button
          onClick={() => {
            window.location.reload(true);
          }}
          className="button"
        >
          Yeni Gönderi Oluştur
        </Button>
      </div>
    </div>
  );
};

export default ShipmentPayment;
