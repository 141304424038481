import { useState } from "react";
import Footer from "../components/beforeLogin/Footer";
import Header from "../components/beforeLogin/Header";
import HomePriceForm from "../components/home/HomePriceForm";
import Partner from "../components/home/Partner";
import { DynamicTitle } from "../utils/DynamicTitle";
// import PageTransition from "../utils/PageTransition";
import ShowPrice from "../components/home/ShowPrice";
import { useRef, useEffect } from "react";
import GetFinish from "../components/home/GetFinish";
import FrequentQuestions from "../components/home/FrequentQuestions";
import { Button } from "@mui/material";
import { useNavigate } from "react-router-dom";
import { Helmet } from "react-helmet";

const HomePrice = () => {
  DynamicTitle("Fiyatlandırma | iyziShip");

  const navigate = useNavigate();

  const [showPrice, setShowPrice] = useState(false);
  const [price, setPrice] = useState([]);
  const mainPriceCardRef = useRef(null);

  useEffect(() => {
    if (showPrice && mainPriceCardRef.current) {
      const position = mainPriceCardRef.current.offsetTop - 250; // 50px yukarı kaydır
      window.scrollTo({ top: position, behavior: "smooth" });
    }
  }, [showPrice]);
  return (
    <>
      <Helmet>
        <meta
          name="title"
          content="Yurt Dışı Kargo Fiyat Hesaplama| iyziShip"
        />
        <meta
          name="description"
          content="Yurt dışı kargo fiyatlarını anında hesaplayın ve iyziShip güvencesiyle ekonomik, hızlı ve güvenilir gönderimler yapın."
        />
        <link rel="canonical" href="https://www.iyziship.com/fiyatlandirma" />
      </Helmet>
      {/* <PageTransition> */}
      <Header />
      <main className="iyzi-main">
        <div className="main-price">
          <div className="price-header">
            <article>
              <h1>Yurt Dışı Kargo Fiyat Hesaplama</h1>
              <p>
                İster bir e-ticaret işletmesi olun, ister bireysel bir
                müşteri; yurt dışına kargo göndermek artık günlük yaşamın bir
                parçası. Sizler için en uygun yurt dışı kargo fiyatı
                seçeneklerini sunarak, bu süreci daha ekonomik ve kolay hale
                getiriyoruz.
              </p>
            </article>
          </div>
          <div className="price-form">
            <HomePriceForm setShowPrice={setShowPrice} setPrice={setPrice} />
          </div>
        </div>
        <div
          className="main-price-get-card"
          ref={mainPriceCardRef}
          style={{
            boxShadow: showPrice ? "var(--blueShadow)" : "",
            padding: showPrice ? "40px 20px " : "",
          }}
        >
          {showPrice &&
            price
              .sort((a, b) => a.selling_price - b.selling_price) // selling_price'a göre sıralama
              .map((price, index) => (
                <ShowPrice price={price} key={index} />
              ))}
        </div>
        {showPrice && (
          <Button
            className="iyzi-button-blue"
            onClick={() => navigate("/register")}
          >
            Hemen Gönderi Oluştur
          </Button>
        )}
        <Partner />
        <GetFinish />
      </main>
      <Footer />
      {/* </PageTransition> */}
    </>
  );
};

export default HomePrice;
