import { Button, CircularProgress, TextField, Typography } from "@mui/material";
import Currency from "../../../utils/CurrencySymbol";
import { refundableAmountState, walletRefundDataState } from "../../../atoms/myWallet";
import { useRecoilState, useRecoilValue } from "recoil";
import axios from "../../../api/axios";
import { useCallback, useState } from "react";
import { toast } from "react-toastify";
import Errors from "../../../utils/Errors";

const MyWalletRefundAmount = ({ setNewReq }) => {
  const [refundAmount, setRefundAmount] = useState("");
  const [refundReason, setRefundReason] = useState("");
  const [loading, setLoading] = useState(false);

  const [walletRefundData, setWalletRefundData] = useRecoilState(walletRefundDataState);

  const refundableAmount = useRecoilValue(refundableAmountState);

  const handleRefundChange = (e) => {
    // Eğer değer virgül içeriyorsa, noktaya çevir
    const value = e.target.value.replace(",", ".");

    // Değer boşsa veya sadece rakamlar, nokta ve virgül içeriyorsa durumu güncelle
    if (value === "" || /^\d*([.,]\d{0,2})?$/.test(value)) {
      setRefundAmount(value);
    }
  };

  const handleRefundReason = (e) => {
    const value = e.target.value;
    setRefundReason(value);
  };

  const handleInputBlur = (e) => {
    const value = parseFloat(e.target.value);
    if (refundableAmount === 0) {
      setRefundAmount("");
    } else if (value > refundableAmount) {
      setRefundAmount(refundableAmount.toFixed(2).replace(",", "."));
    }
  };

  const handleClickAmount = (e) => {
    const value = parseFloat(e);
    if (refundableAmount === 0) {
      setRefundAmount("");
    } else if (value > refundableAmount) {
      setRefundAmount(refundableAmount.toFixed(2).replace(",", "."));
    } else {
      setRefundAmount(value.toFixed(2).replace(",", "."));
    }
  };

  const handleSubmitWalletRefund = useCallback(
    async (e) => {
      e.preventDefault();
      setLoading(true);
      await axios
        .post(`refund/add`, {
          request_refund_amount: Number(refundAmount),
          request_description: refundReason,
        })
        .then((response) => {
          const data = { ...response.data.data, get_files: [] };
          setWalletRefundData([...walletRefundData, data]);
          toast.success(response.data.message, { autoClose: 2000 });
          setRefundAmount("");
          setRefundReason("");
          setNewReq(false);
        })
        .catch((error) => {
          Errors(error);
        })
        .finally(() => {
          setLoading(false);
        });
    },
    [setWalletRefundData, setNewReq, refundAmount, refundReason, walletRefundData]
  );

  return (
    <div className="my-wallet-refund-amount-wrapper">
      <form onSubmit={handleSubmitWalletRefund} className="my-wallet-refund-amount">
        <TextField
          label="Ödeme Tutarı"
          placeholder="500"
          disabled={refundableAmount === 0 ? true : false}
          className="my-wallet-refund-amount-value"
          required
          size="small"
          autoComplete="off"
          inputProps={{
            inputMode: "numeric",
          }}
          sx={{ opacity: refundableAmount === 0 ? 0.4 : 1 }}
          value={refundAmount === "" ? "" : refundAmount}
          onChange={handleRefundChange}
          onBlur={handleInputBlur}
        />
        <div className="my-wallet-refund-amount-buttons">
          <Button
            disabled={refundableAmount === 0 ? true : false}
            sx={{
              opacity: refundableAmount === 0 ? 0.4 : 1,
              border: "1px solid var(--inputBorderColor)",
              color: "var(--priceText)",
            }}
            onClick={() => handleClickAmount(100)}
            type="button"
          >
            100 {Currency("TRY")}
          </Button>
          <Button
            disabled={refundableAmount === 0 ? true : false}
            sx={{
              opacity: refundableAmount === 0 ? 0.4 : 1,
              border: "1px solid var(--inputBorderColor)",
              color: "var(--priceText)",
            }}
            onClick={() => handleClickAmount(250)}
            type="button"
          >
            250 {Currency("TRY")}
          </Button>
          <Button
            disabled={refundableAmount === 0 ? true : false}
            sx={{
              opacity: refundableAmount === 0 ? 0.4 : 1,
              border: "1px solid var(--inputBorderColor)",
              color: "var(--priceText)",
            }}
            onClick={() => handleClickAmount(500)}
            type="button"
          >
            500 {Currency("TRY")}
          </Button>
          <Button
            disabled={refundableAmount === 0 ? true : false}
            sx={{
              opacity: refundableAmount === 0 ? 0.4 : 1,
              border: "1px solid var(--inputBorderColor)",
              color: "var(--priceText)",
            }}
            onClick={() => handleClickAmount(1000)}
            type="button"
          >
            1000 {Currency("TRY")}
          </Button>
          <Button
            disabled={refundableAmount === 0 ? true : false}
            sx={{
              opacity: refundableAmount === 0 ? 0.4 : 1,
              border: "1px solid var(--inputBorderColor)",
              color: "var(--priceText)",
            }}
            onClick={() => handleClickAmount(1500)}
            type="button"
          >
            1500 {Currency("TRY")}
          </Button>
        </div>
        <TextField
          label="İade Sebebi"
          autoComplete="off"
          value={refundReason}
          onChange={handleRefundReason}
          disabled={refundableAmount === 0 ? true : false}
          sx={{ opacity: refundableAmount === 0 ? 0.4 : 1 }}
          size="small"
          className="my-wallet-refund-amount-reason"
          required
        />
        {refundAmount !== "" && (
          <div className="my-wallet-refund-amount-show">
            <Typography color={"var(--priceText)"} fontSize={13}>
              İade Edilecek Tutar
            </Typography>
            <Typography color={"var(--info)"} fontSize={16}>
              {refundAmount} {Currency("TRY")}
            </Typography>
          </div>
        )}
        <div className="my-wallet-refund-amount-submit">
          {loading ? (
            <div
              style={{
                height: "33px",
                width: "108px",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <CircularProgress
                sx={{
                  height: "21px!important",
                  width: "21px!important",
                }}
              />
            </div>
          ) : (
            <Button
              disabled={refundableAmount === 0 ? true : false}
              sx={{ opacity: refundableAmount === 0 ? 0.4 : 1 }}
              type="submit"
              className="iyzi-button"
            >
              Ödeme Talep Et
            </Button>
          )}
        </div>
      </form>
    </div>
  );
};

export default MyWalletRefundAmount;
