import DeleteIcon from "@mui/icons-material/Delete";
import { Avatar, Box, CircularProgress, Tooltip } from "@mui/material";
import { useCallback, useState } from "react";
import { useRecoilState } from "recoil";
import axios from "../../../api/axios";
import { basketItemsState, basketTotalItemsState } from "../../../atoms/basket";
import Currency from "../../../utils/CurrencySymbol";
import NavigateParcel from "../../../utils/NavigateParcel";
import Errors from "../../../utils/Errors";
import LocalPrice from "../../../utils/LocalPrice";

const BasketItems = ({ basket }) => {
  const [basketTotalItems, setBasketTotalItems] = useRecoilState(basketTotalItemsState);
  const [basketItems, setBasketItems] = useRecoilState(basketItemsState);

  const [deleteLoading, setDeleteLoading] = useState(false);

  const handleDelete = useCallback(
    (e) => {
      setDeleteLoading(true);

      axios
        .post(`basket/delete/${basket.id}`)
        .then((response) => {
          if (response.data.status === 200) {
            setBasketItems(basketItems.filter((basket) => basket.id !== e.id));
            setBasketTotalItems(basketTotalItems - 1);
            setDeleteLoading(false);
          }
        })
        .catch((error) => {
          Errors(error);
        });
    },
    [basketItems, setBasketItems, basket.id, setBasketTotalItems, basketTotalItems]
  );

  return (
    <Box
      sx={{
        display: "flex",
        justifyContent: "space-between",
        height: "60px",
        gap: "var(--gap)",
        padding: "0 10px",
        margin: "5px 0",
        borderBottom: "1px solid var(--inputBorderColor)",
      }}
    >
      <img
        src={`/images/${basket.get_payment?.get_parcel?.get_parcel_supplier?.supplier_logo}`}
        style={{
          height: "42px",
          width: "42px",
          display: "flex",
          alignSelf: "center",
        }}
      />
      <div style={{ display: "grid", alignContent: "space-around" }}>
        <div style={{ color: "var(--priceText)", fontSize: "12px" }}>
          <NavigateParcel parcel={basket?.get_payment?.get_parcel?.parcel_ref_no} />
        </div>
      </div>
      <div style={{ display: "grid", alignContent: "space-around" }}>
        <div
          style={{
            color: "var(--info)",
            fontWeight: 700,
            fontSize: "13px",
          }}
        >
          {Currency(basket?.get_payment?.get_parcel?.currency)}
          {LocalPrice(basket?.get_payment?.get_parcel?.remaining_payment)}
        </div>
        <div style={{ color: "var(--priceText)", fontSize: "13px" }}>
          Kur: {basket?.get_payment?.get_details[0]?.exchange_rate}
        </div>
      </div>
      <div
        style={{
          fontWeight: 700,
          fontSize: "13px",
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        {LocalPrice(basket?.get_payment?.get_parcel?.remaining_payment_try)} ₺
      </div>
      {deleteLoading ? (
        <CircularProgress
          sx={{
            width: "16px !important",
            height: "16px !important",
            display: "flex",
            justifyContent: "center",
            alignSelf: "center",
            outline: 0,
          }}
        />
      ) : (
        <Tooltip title="Listeden Kaldır">
          <DeleteIcon
            sx={{
              width: "24px",
              height: "24px",
              display: "flex",
              justifyContent: "center",
              alignSelf: "center",
              color: "var(--notRed)",
              transition: "all 150ms linear",
              cursor: "pointer",
              outline: 0,
              ":hover": {
                color: "var(--redBrand)",
              },
            }}
            onClick={() => handleDelete(basket)}
          />
        </Tooltip>
      )}
    </Box>
  );
};

export default BasketItems;
