import React, { useEffect, useRef, useState } from "react";
import Chart from "chart.js/auto";
import { Box } from "@mui/material";

const DashboardCountriesChart = ({ bestCountries }) => {
  const chartRef = useRef(null);
  const [expanded, setExpanded] = useState(false);

  const onClickHandler = () => {
    setExpanded(!expanded);
  };

  useEffect(() => {
    const createChart = (ref, data) => {
      if (ref.current) {
        const labels = data.map((item) => item.label);
        const values = data.map((item) => item.data);

        const chartData = {
          labels: labels,
          datasets: [
            {
              label: "Kargo Sayısı",
              data: values,
              fill: false,
              backgroundColor: "#1d8ff7",
              borderColor: "#1d8ff7",
              barPercentage: 0.3,
            },
          ],
        };

        const chartOptions = {
          aspectRatio: 4,
          scales: {
            x: {
              type: "category",
            },
            y: {
              beginAtZero: true,
              ticks: {
                stepSize: 1,
              },
            },
          },
          options: {
            responsive: true,
            maintainAspectRatio: false,
            aspectRatio: 1,
          },
          plugins: {
            legend: {
              display: false,
            },
          },
        };

        if (ref.current.chart) {
          ref.current.chart.destroy();
        }

        const newChart = new Chart(ref.current, {
          type: "bar",
          data: chartData,
          options: chartOptions,
        });

        ref.current.chart = newChart;
      }
    };

    createChart(chartRef, bestCountries);
  }, [bestCountries]);

  return (
    <Box className={`pie-chart ${expanded ? "expanded" : ""}`}>
      <div className="pie-chart-header">
        <div className="pie-text-total">Ülkeler</div>
      </div>
      {bestCountries?.length === 0 ? (
        <div
          style={{
            marginTop: "15px",
          }}
        >
          Gönderiniz bulunmamaktadır.
        </div>
      ) : (
        <canvas ref={chartRef} />
      )}
      <div className="pie-chart-footer">
        <div>Gönderdiğim ülkeler</div>
        <div className="count-button">
          <button onClick={onClickHandler}>
            {expanded ? "Daha Az Göster" : "Daha Fazla Göster"}
          </button>
        </div>
      </div>
    </Box>
  );
};

export default DashboardCountriesChart;
