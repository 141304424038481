import { Button, Dialog, DialogActions, DialogContent, DialogTitle } from "@mui/material";
import PriorityHighOutlinedIcon from "@mui/icons-material/PriorityHighOutlined";
import { useSetRecoilState } from "recoil";
import { selectedFastShipmentProductState } from "../../../atoms/fastShipment";

const HSCodeMissingFastDialog = ({ open, setOpen, handleSubmit, setDialog, commoduties }) => {
  const setSelectedFastShipmentProduct = useSetRecoilState(selectedFastShipmentProductState);

  const handleClose = () => {
    setOpen(false);
  };

  const handleEdit = () => {
    setOpen(false);
    setSelectedFastShipmentProduct(commoduties);
    setDialog(true);
  };

  const handleCreate = () => {
    handleClose();
    handleSubmit();
  };

  return (
    <Dialog open={open} onClose={handleClose}>
      <DialogTitle sx={{ width: "100%", display: "flex", justifyContent: "center" }}>
        <PriorityHighOutlinedIcon
          sx={{
            width: "60px",
            height: "60px",
            color: "var(--btnBgBlue)",
          }}
        />
      </DialogTitle>
      <DialogContent>
        Hs Kodu / GTIP - Gümrük Tarife Numarasını girmediniz! Bu sebeple takip numarası
        alamayabilirsiniz.
      </DialogContent>
      <DialogActions>
        <Button onClick={handleCreate} className="iyzi-button-blue">
          Devam
        </Button>
        <Button onClick={handleEdit} className="iyzi-button-blue">
          Düzenle
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default HSCodeMissingFastDialog;
