import { Box, Button } from "@mui/material";
import VisibilityOutlinedIcon from "@mui/icons-material/VisibilityOutlined";
import VisibilityOffOutlinedIcon from "@mui/icons-material/VisibilityOffOutlined";
import { useRecoilState } from "recoil";
import { courierDetailShowState } from "../../atoms/courierRegistration";
import CourierListItemDetails from "./CourierListItemDetails";
import FormatDateHour from "../../utils/FormatDateHour";

const CourierListDeliveredItem = ({ item }) => {
  const [courierDetailShow, setCourierDetailShow] = useRecoilState(
    courierDetailShowState
  );

  const handleShowDetail = () => {
    setCourierDetailShow(item.booking_code);
  };

  const handleCloseDetail = () => {
    setCourierDetailShow(null);
  };

  return (
    <>
      <Box className="courier-list-item-delivered">
        <Box className="delivery-status-wrapper">
          <Box className="delivery-status active">{Number(item.status) === 3 ? "Teslim Alındı" : "Tamamlandı"}</Box>
        </Box>
        <Box className="courier-list-item-detail">
          <Box className="courier-column">
            <Box className="delivery-column">
              <Box className="delivery-column-row">
                Teslimat Kodu:{" "}
                <span className="delivery-code-span">{item.booking_code}</span>
              </Box>
              <Box className="delivery-column-row">
                Teslim Eden:{" "}
                <span className="delivery-column-row-span">
                  {item.submitter}
                </span>
              </Box>
            </Box>
            <Box className="delivery-column">
              <Box className="delivery-column-row">
                Teslim Tarihi:{" "}
                <span className="delivery-column-row-span">
                  {FormatDateHour(item.created_at)}
                </span>
              </Box>
              <Box className="delivery-column-row">
                Teslim Alan:{" "}
                <span className="delivery-column-row-span">
                  {item.get_receiver.name} {item.get_receiver.surname}
                </span>
              </Box>
            </Box>
          </Box>
          <Box className="delivery-address">
            Teslimat Adresi:{" "}
            <span className="delivery-address-span">
              {item?.get_address?.address1}{" "}
              {item?.get_address?.address2 &&
                ` - ${item?.get_address?.address2}`}
              {item?.get_address?.address3 &&
                ` - ${item?.get_address?.address3}`}
            </span>
          </Box>
        </Box>
        <Box className="courier-list-item-detail-show">
          {courierDetailShow === item.booking_code ? (
            <Button onClick={handleCloseDetail}>
              <VisibilityOffOutlinedIcon />
              Teslim Alınanları Kapat
            </Button>
          ) : (
            <Button onClick={handleShowDetail}>
              <VisibilityOutlinedIcon />
              Teslim Alınanları Gör
            </Button>
          )}
        </Box>
      </Box>
      {courierDetailShow === item.booking_code ? (
        <CourierListItemDetails item={item} />
      ) : null}
    </>
  );
};

export default CourierListDeliveredItem;
