import {
  Box,
  Button,
  FormControl,
  FormControlLabel,
  FormLabel,
  Modal,
  Radio,
  RadioGroup,
} from "@mui/material";
import { useCallback, useState } from "react";
import { useRecoilState } from "recoil";
import { barcodeSingleState } from "../../../atoms/myShipments";
import axios from "../../../api/axios";
import { toast } from "react-toastify";
import DownloadBase64Pdf from "../../../utils/DownloadBase64Pdf";
import Errors from "../../../utils/Errors";

const BarcodeSingleDownload = () => {
  const [barcodeSingle, setBarcodeSingle] = useRecoilState(barcodeSingleState);

  const item = barcodeSingle.item;
  const status = barcodeSingle.status;

  const [pageFormat, setPageFormat] = useState("A4");
  const [includeBundle, setIncludeBundle] = useState(null);

  const handleClose = useCallback(() => {
    setBarcodeSingle({ status: false, item: {} });
    setIncludeBundle(null);
    setPageFormat("A4");
  }, [setBarcodeSingle]);

  const handleSubmit = useCallback(async () => {
    const params =
      item?.packaging_type === "B"
        ? {
            page_format: pageFormat,
            include_bundle: includeBundle,
          }
        : { page_format: pageFormat };

    await axios
      .post(`parcel/get-barcode/${item?.id}`, params)
      .then((response) => {
        if (response.data.bundle_base64) {
          DownloadBase64Pdf(response.data.bundle_base64, "bundle_barcode");
          DownloadBase64Pdf(response.data.base64, "barcode");
        } else {
          DownloadBase64Pdf(response.data.base64, "barcode");
        }
        toast.success(response.data.message);
        setBarcodeSingle({ status: false, item: {} });
        setIncludeBundle(null);
        setPageFormat("A4");
      })
      .catch((error) => Errors(error));
  }, [pageFormat, item, includeBundle, setBarcodeSingle]);

  return (
    <Modal
      open={status}
      onClose={handleClose}
      aria-labelledby="confirmation-modal-title"
      aria-describedby="confirmation-modal-description"
      sx={{
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
      }}
    >
      <Box
        sx={{
          backgroundColor: "white",
          border: "1px solid #ccc",
          boxShadow: 24,
          p: 4,
          maxWidth: 400,
          textAlign: "center",
        }}
        style={{
          maxHeight: "90vh",
          overflowY: "auto",
        }}
      >
        <FormControl component="fieldset" sx={{ mt: 4 }}>
          <FormLabel component="legend">Hangi formatta istediğinizi seçin:</FormLabel>
          <RadioGroup
            aria-label="page-format"
            name="page-format"
            value={pageFormat}
            onChange={(e) => setPageFormat(e.target.value)}
            sx={{ flexDirection: "row" }}
          >
            <FormControlLabel value="A4" control={<Radio />} label="A4" sx={{ ml: 2 }} />
            <FormControlLabel value="TW-2037" control={<Radio />} label="TW-2037" />
          </RadioGroup>
        </FormControl>
        {item?.packaging_type === "B" && (
          <FormControl component="fieldset" sx={{ mt: 4 }}>
            <FormLabel component="legend">
              Bundle olarak oluşturduğunuz diğer gönderilerin etiketlerini de indirmek ister
              misiniz?
            </FormLabel>
            <RadioGroup
              aria-label="include-bundle"
              name="include-bundle"
              value={includeBundle}
              onChange={(e) => setIncludeBundle(e.target.value)}
              sx={{ flexDirection: "row", justifyContent: "center" }}
            >
              <FormControlLabel value={1} control={<Radio />} label="Evet" sx={{ ml: 2 }} />
              <FormControlLabel value={0} control={<Radio />} label="Hayır" />
            </RadioGroup>
          </FormControl>
        )}
        <Box sx={{ mt: 4 }}>
          <Button
            className="iyzi-button"
            disabled={
              item?.packaging_type === "B" ? (includeBundle === null ? true : false) : false
            }
            onClick={handleSubmit}
            sx={{ mx: 1, ":disabled": { opacity: 0.3 } }}
          >
            Barkod İndir
          </Button>
          <Button className="iyzi-button-red" onClick={handleClose} sx={{ mx: 1 }}>
            Kapat
          </Button>
        </Box>
      </Box>
    </Modal>
  );
};

export default BarcodeSingleDownload;
