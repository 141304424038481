import { useRecoilValue, useSetRecoilState } from "recoil";
import {
  confirmationCodeState,
  shipmentDocumentState,
  shipmentPriceArrayLengthState,
  shipmentPriceArrayState,
} from "../../atoms/createShipment";
import AddDimension from "./shipmentDimension/AddDimension";
import ShipmentDimensionModal from "./shipmentDimension/ShipmentDimensionModal";
import { useEffect } from "react";

const ShipmentDimension = () => {
  const shipmentDocument = useRecoilValue(shipmentDocumentState);

  const setShipmentPriceArray = useSetRecoilState(shipmentPriceArrayState);
  const setConfirmationCode = useSetRecoilState(confirmationCodeState);
  const setShipmentPriceArrayLength = useSetRecoilState(shipmentPriceArrayLengthState);

  useEffect(() => {
    setShipmentPriceArray([]);
    setConfirmationCode("");
    setShipmentPriceArrayLength([]);
  }, [setShipmentPriceArray, setConfirmationCode, setShipmentPriceArrayLength]);

  return (
    <div className="shipmentDimension">
      <ShipmentDimensionModal />
      <div className="shipmentDimension-form">
        <div className="form-dimensions">{shipmentDocument === "N" && <AddDimension />}</div>
      </div>
    </div>
  );
};

export default ShipmentDimension;
