import { useRecoilValue } from "recoil";
import { dimensionListArrayState } from "../../../atoms/profileSettings";

import DimensionListItem from "./DimensionListItem";

const DimensionList = () => {
  const dimensionListArray = useRecoilValue(dimensionListArrayState);

  return (
    <div className="dimensions-display-wrapper">
      {dimensionListArray?.map((dimension, key) => (
        <DimensionListItem key={key} dimension={dimension} />
      ))}
      {dimensionListArray?.length === 0 && "Kayıtlı ölçünüz bulunmamaktadır."}
    </div>
  );
};
export default DimensionList;
