import { Button } from "@mui/material";
import { useEffect, useState } from "react";
import { useCallback } from "react";
import {
  activeStepState,
  dimensionArrayState,
  dimensionTotalQtyState,
  registeredDimensionsState,
  totalCwState,
  totalGrossState,
  totalGwState,
  totalVwState,
} from "../../../atoms/createShipment";
import { useRecoilState, useSetRecoilState } from "recoil";
import AddCircleOutlineIcon from "@mui/icons-material/AddCircleOutline";
import axios from "../../../api/axios";
import { useCookies } from "react-cookie";
import CreateDimension from "./CreateDimension";
import { useNavigate } from "react-router-dom";
import Errors from "../../../utils/Errors";
import iyziWeightRoundSystem from "../../../utils/iyziWeightRoundSystem";

const AddDimension = () => {
  const navigate = useNavigate();
  const [, setCookies] = useCookies(["jwt"]);

  const [token] = useCookies(["jwt"]);
  const jwt = token.jwt;
  axios.defaults.headers.common["Authorization"] = `Bearer ${jwt}`;

  const [dimensionArray, setDimensionArray] = useRecoilState(dimensionArrayState);
  const [totalCw, setTotalCw] = useRecoilState(totalCwState);

  const setActiveStep = useSetRecoilState(activeStepState);
  const setRegisteredDimensions = useSetRecoilState(registeredDimensionsState);
  const setTotalGw = useSetRecoilState(totalGwState);
  const setTotalVw = useSetRecoilState(totalVwState);
  const setTotalGross = useSetRecoilState(totalGrossState);
  const setDimensionTotalQty = useSetRecoilState(dimensionTotalQtyState);

  const [dimensions, setDimensions] = useState([
    { width: "", length: "", height: "", gross: "", qty: 1, id: 0 },
  ]);
  const [dimensionLoading, setDimensionLoading] = useState(true);

  const isDisabledFunc = (dimensions) => {
    return dimensions.some((dimension) => {
      return (
        !dimension.width ||
        !dimension.length ||
        !dimension.height ||
        !dimension.gross ||
        !dimension.qty
      );
    });
  };

  const isDisabled = isDisabledFunc(dimensions);

  const handleAddInput = useCallback(() => {
    if (
      dimensions[dimensions.length - 1]?.width === "" ||
      dimensions[dimensions.length - 1]?.length === "" ||
      dimensions[dimensions.length - 1]?.height === "" ||
      dimensions[dimensions.length - 1]?.gross === ""
    ) {
      return false;
    } else {
      setDimensions((prevDimensions) => [
        ...prevDimensions,
        {
          width: "",
          length: "",
          height: "",
          gross: "",
          qty: 1,
          id: prevDimensions.length,
        },
      ]);
    }
  }, [dimensions, setDimensions]);

  const handleInputChange = useCallback(
    (e, i) => {
      const values = [...dimensions];
      let fvalue = e.target.value.replace(/,/g, ".");

      const value = fvalue.replace(/[^0-9.]|(?<=\..*)\.|^0{2,}(?=[.])/g, "");
      // const value = fvalue.replace(/[^\d]/g, "");
      const regex = /^\d+(\.\d{0,2})?$/;

      if (value.startsWith(".") || (!regex.test(value) && value !== "")) {
        return;
      } else {
        if (e.target.name === "gross") {
          let newValue = fvalue
            .replace(/[^0-9.]|(?<=\..*)\.|^0{2,}(?=[.])/g, "")
            .replace(/^0{2,}/, "0");

          // Sadece nokta veya virgül girildiyse '0.' ile başlat
          let formattedValue = newValue === "." || newValue === "," ? "0." : newValue;

          if (!isNaN(Number(formattedValue)) && Number(formattedValue) > 999) {
            newValue = 999;
          } else if (isNaN(Number(formattedValue))) {
            // Eğer numericValue sayısal bir değer değilse, yeni değeri ayarlayın.
            // Örneğin, boş bir string veya geçersiz bir değer için:
            formattedValue = "";
          }
          values[i] = {
            ...values[i],
            gross: formattedValue,
          };
        } else {
          values[i] = {
            ...values[i],
            [e.target.name]: value,
          };
        }
      }

      setDimensions(values);
    },
    [dimensions, setDimensions]
  );

  const handleDimensionClear = useCallback(
    (key) => {
      // Yeni dimensions array'ini oluştur
      const newDimensions = dimensions.map((item, index) => {
        if (index === key) {
          // İlgili index'teki öğeyi başlangıç değerleri ile döndür
          return { width: "", length: "", height: "", gross: "", qty: "", id: item.id };
        }
        return item; // Diğer öğeleri olduğu gibi bırak
      });

      // Güncellenmiş array ile state'i güncelle
      setDimensions(newDimensions);
    },
    [dimensions]
  );

  const getTotalWeight = useCallback(() => {
    const totalCurrVw = dimensions.reduce(
      (prev, curr, index, array) =>
        Number(prev) + Number(((curr.width * curr.length * curr.height) / 5000) * curr.qty),
      0
    );
    const totalCurrGw = dimensions.reduce(
      (prev, curr, index, array) => Number(prev) + Number(curr.qty * curr.gross),
      0
    );

    setTotalGross(
      dimensions.reduce((prev, curr, index, array) => Number(prev) + Number(curr.gross), 0)
    );
    setTotalVw(totalCurrVw.toFixed(2));
    setTotalGw(totalCurrGw.toFixed(2));
    setTotalCw(
      Number(totalCurrVw) > Number(totalCurrGw)
        ? Number(totalCurrVw).toFixed(2)
        : Number(totalCurrGw).toFixed(2)
    );
    setDimensionTotalQty(
      dimensions.reduce((prev, curr, index, array) => Number(prev) + Number(curr.qty), 0)
    );
  }, [setTotalVw, setTotalGw, setTotalCw, setTotalGross, setDimensionTotalQty, dimensions]);

  const handleDimensionSubmit = useCallback(
    (e) => {
      e.preventDefault();

      getTotalWeight();
      setDimensionArray(dimensions);
      setActiveStep((prevActiveStep) => prevActiveStep + 1);
    },
    [dimensions, setActiveStep, setDimensionArray, getTotalWeight]
  );

  const handleDimensionDelete = useCallback(
    (id) => {
      setDimensions((prevDimensions) => prevDimensions.filter((dim) => dim.id !== id));

      // Ücret Ağırlığını hesapla ve totalCwState atomunu güncelle
      setTotalCw((prevTotalCw) => {
        const deletedDimension = dimensions.find((dim) => dim.id === id);

        const deletedWeight =
          (deletedDimension.width *
            deletedDimension.length *
            deletedDimension.height *
            deletedDimension.qty) /
          5000;

        const deletedGrossWeight = deletedDimension.gross * deletedDimension.qty;

        const newTotalCw = prevTotalCw - Math.max(deletedWeight, deletedGrossWeight, prevTotalCw);

        return Math.ceil(newTotalCw);
      });
    },
    [dimensions, setDimensions, setTotalCw]
  );

  /** --------------------- Calculate Dimension CW ------------------------- **/

  useEffect(() => {
    if (dimensions.length === 0) {
      setTotalCw(0);
    } else {
      const calculatedCw = dimensions.reduce((acc, dim) => {
        const vw =
          (Math.ceil(Number(dim.width)) *
            Math.ceil(Number(dim.length)) *
            Math.ceil(Number(dim.height)) *
            Number(dim.qty)) /
          5000;
        const gw = Number(dim.gross) * Number(dim.qty);

        // vw ve gw değerlerini karşılaştırarak büyük olanı al
        const cw = Math.max(vw, gw);

        // Toplam cw değerini güncelle
        return acc + cw;
      }, 0);

      setTotalCw(calculatedCw);
    }
  }, [dimensions, setTotalCw]);

  /** --------------------- Select Dimension ------------------------- **/

  useEffect(() => {
    axios
      .get("member-dimension")
      .then((response) => {
        setRegisteredDimensions(response.data.data);
      })
      .catch((error) => Errors(error))
      .finally(() => setDimensionLoading(false));
  }, [setRegisteredDimensions, setCookies, navigate]);

  useEffect(() => {
    if (dimensionArray.length > 0) {
      setDimensions(dimensionArray);
    }
  }, [dimensionArray]);

  return (
    <form onSubmit={handleDimensionSubmit}>
      <div className="form-dimensions-wrapper">
        <div className="form-dimensions-contain">
          {dimensions?.map((dimension, key) => (
            <CreateDimension
              key={key}
              dimension={dimension}
              id={key}
              handleInputChange={handleInputChange}
              handleDimensionDelete={handleDimensionDelete}
              handleDimensionClear={handleDimensionClear}
              dimensionLoading={dimensionLoading}
              setDimensions={setDimensions}
              dimensions={dimensions}
            />
          ))}
          <div
            className="form-add-dimension-button-wrapper"
            style={{ width: "100%", display: "flex", justifyContent: "center" }}
          >
            <Button onClick={handleAddInput} className="add-dimension-button">
              <AddCircleOutlineIcon
                className="add-dimension-button-icon"
                sx={dimensions.map((e) =>
                  e.width === "" ||
                  e.length === "" ||
                  e.height === "" ||
                  e.gross === "" ||
                  e.qty === ""
                    ? { opacity: "0.2", cursor: "auto", fontSize: "24px" }
                    : { opacity: 1, cursor: "pointer", fontSize: "24px" }
                )}
              />
            </Button>
          </div>
        </div>
        <div className="dimension-wrapper-button-container">
          <div className="button-container-cw">
            <span className="cw-headline">Toplam Ücret Ağırlığı</span>:{" "}
            <span className="cw-amount">{iyziWeightRoundSystem(totalCw)} KG</span>
          </div>
          <Button
            onClick={handleDimensionSubmit}
            style={{ textTransform: "none" }}
            type="submit"
            className="iyzi-button-blue"
            disabled={isDisabled}
            sx={{ opacity: isDisabled ? 0.3 : 1 }}
          >
            İlerle
          </Button>
        </div>
      </div>
    </form>
  );
};

export default AddDimension;
