import {
  Button,
  CircularProgress,
  createTheme,
  Modal,
  TextField,
  ThemeProvider,
} from "@mui/material";
import { Box } from "@mui/system";
import { useCallback, useState } from "react";
import { useRecoilState } from "recoil";
import axios from "../../api/axios";
import { rateCommentOpenState, rateCommentState } from "../../atoms/support";
import Errors from "../../utils/Errors";

const theme = createTheme({
  components: {
    MuiModal: {
      styleOverrides: {
        root: {
          margin: "20px",
        },
      },
    },
  },
});

const RateComment = () => {
  const [rateCommentOpen, setRateCommentOpen] = useRecoilState(rateCommentOpenState);
  const [rateComment, setRateComment] = useRecoilState(rateCommentState);

  const [loading, setLoading] = useState(false);

  const handleSendRateComment = useCallback(async (e) => {
    e.preventDefault();
    setLoading(true);

    await axios
      .post("")
      .then(() => {
        setLoading(false);
      })
      .catch((error) => {
        setLoading(false);
        Errors(error);
      });
  }, []);

  return (
    <ThemeProvider theme={theme}>
      <Modal open={rateCommentOpen} onClose={() => setRateCommentOpen(false)}>
        <form
          onSubmit={handleSendRateComment}
          style={{
            display: "grid",
            gap: "var(--gap2x)",
            backgroundColor: "var(--lighterBg)",
            padding: "20px",
            borderRadius: "var(--inputBorderRadius)",
            maxWidth: "500px",
            margin: "auto",
            maxHeight: "90vh",
            overFlowY: "auto",
          }}
        >
          <h6 style={{ color: "var(--priceText)" }}>Destek Hakkındaki Fikirleriniz</h6>
          <TextField
            label="Notunuz"
            value={rateComment}
            onChange={(e) => setRateComment}
            fullWidth
            autoComplete="off"
            autoFocus
          />
          {loading ? (
            <Box
              sx={{
                width: "100%",
                display: "flex",
                alignItems: "center",
                justifyContent: "flex-end",
              }}
            >
              <CircularProgress
                sx={{
                  width: "20px !important",
                  height: "20px !important",
                  marginRight: "20px",
                }}
              />
            </Box>
          ) : (
            <Box
              sx={{
                width: "100%",
                display: "flex",
                justifyContent: "flex-end",
              }}
            >
              <Button
                sx={{
                  textTransform: "none",
                  backgroundColor: "var(--info)",
                  color: "var(--lighterBg)",
                  fontSize: "13px",
                  transition: "all 150ms linear",
                  ":hover": {
                    backgroundColor: "var(--blueBrand)",
                    transition: "all 150ms linear",
                  },
                }}
              >
                Gönder
              </Button>
            </Box>
          )}
        </form>
      </Modal>
    </ThemeProvider>
  );
};

export default RateComment;
