import {
  Box,
  Button,
  CircularProgress,
  createTheme,
  Pagination,
  Table,
  TableBody,
  TableContainer,
  ThemeProvider,
} from "@mui/material";
import SupportListTableBody from "./SupportListTableBody";
import SupportListTableHead from "./SupportListTableHead";
import ListAltOutlinedIcon from "@mui/icons-material/ListAltOutlined";
import QuestionAnswerIcon from "@mui/icons-material/QuestionAnswer";
import { useRecoilState, useRecoilValue } from "recoil";
import {
  openSupportListState,
  openSupportRequestState,
  supportCurrentPageState,
  supportListState,
  supportOrderByState,
  supportOrderDirectionState,
  supportPageLimitState,
  supportTotalPageState,
} from "../../atoms/support";
import { useCallback, useState } from "react";
import axios from "../../api/axios";
import Errors from "../../utils/Errors";
import { toast } from "react-toastify";

const theme = createTheme({
  components: {
    MuiTableCell: {
      styleOverrides: {
        head: {
          fontSize: "13px",
          padding: "6px",
        },
        body: {
          fontSize: "13px",
          padding: "6px",
        },
      },
    },
  },
});

const SupportList = () => {
  const supportOrderDirection = useRecoilValue(supportOrderDirectionState);
  const supportOrderBy = useRecoilValue(supportOrderByState);
  const supportPageLimit = useRecoilValue(supportPageLimitState);

  const [, setOpenSupportRequest] = useRecoilState(openSupportRequestState);
  const [, setOpenSupportList] = useRecoilState(openSupportListState);
  const [supportList, setSupportList] = useRecoilState(supportListState);
  const [supportTotalPage, setSupportTotalPage] = useRecoilState(supportTotalPageState);
  const [supportCurrentPage, setSupportCurrentPage] = useRecoilState(supportCurrentPageState);

  const [loading, setLoading] = useState(false);

  const handleCreateSupportDemand = useCallback(() => {
    setOpenSupportRequest(true);
    setOpenSupportList(false);
  }, [setOpenSupportList, setOpenSupportRequest]);

  const handlePageChange = useCallback(
    async (e, pageValue) => {
      setLoading(true);
      await axios
        .get(
          `ticket?order_direction=${supportOrderDirection}&limit=${supportPageLimit}&page=${pageValue}&order_by=${supportOrderBy}`
        )
        .then((response) => {
          setLoading(false);
          setSupportCurrentPage(pageValue);
          setSupportList(response.data.data);
          setSupportTotalPage(response.data.totalPages);
          if (response.data.data.length > 0) {
            setOpenSupportList(true);
          } else {
            toast.info(response.data.message);
            setOpenSupportList(false);
          }
        })
        .catch((error) => {
          Errors(error);
          setLoading(false);
          setOpenSupportList(false);
        });
    },
    [
      setOpenSupportList,
      setSupportList,
      setSupportTotalPage,
      setSupportCurrentPage,
      supportOrderBy,
      supportOrderDirection,
      supportPageLimit,
    ]
  );

  return (
    <ThemeProvider theme={theme}>
      <Box className="support-list">
        <h6>
          <ListAltOutlinedIcon /> Destek Talepleriniz
        </h6>
        <Box className="support-list-new-support-demand">
          <Button className="iyzi-button-blue" onClick={handleCreateSupportDemand}>
            <QuestionAnswerIcon />
            Yeni Talep Oluştur
          </Button>
        </Box>
        {loading ? (
          <Box
            sx={{
              width: "100%",
              height: "300px",
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            <CircularProgress />
          </Box>
        ) : (
          <>
            <TableContainer>
              <Table>
                <SupportListTableHead />
                <TableBody>
                  {supportList.map((item, key) => (
                    <SupportListTableBody key={key} item={item} />
                  ))}
                </TableBody>
              </Table>
            </TableContainer>
            <Box className="support-list-pagination-wrapper">
              <Pagination
                showFirstButton
                showLastButton
                size="small"
                count={supportTotalPage}
                page={supportCurrentPage}
                onChange={handlePageChange}
              />
            </Box>
          </>
        )}
      </Box>
    </ThemeProvider>
  );
};

export default SupportList;
