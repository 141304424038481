import "../../style/sidebar/Navbar.css";
import Box from "@mui/material/Box";
import List from "@mui/material/List";
import GridViewOutlinedIcon from "@mui/icons-material/GridViewOutlined";
import LogoutRoundedIcon from "@mui/icons-material/LogoutRounded";
import SupportAgentIcon from "@mui/icons-material/SupportAgent";
import ListItem from "@mui/material/ListItem";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import { NavLink, useNavigate } from "react-router-dom";
import { useRecoilValue } from "recoil";
import { drawerOpenState } from "../../atoms/viewPadding";
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Button,
  createTheme,
  Tooltip,
} from "@mui/material";
import axios from "../../api/axios";
import { toast } from "react-toastify";
import { useCookies } from "react-cookie";
import { ExpandMoreOutlined } from "@mui/icons-material";
import { ThemeProvider } from "@emotion/react";
import Errors from "../../utils/Errors";
import ShoppingCartOutlinedIcon from "@mui/icons-material/ShoppingCartOutlined";
import CalculateOutlinedIcon from "@mui/icons-material/CalculateOutlined";
import ExtensionOutlinedIcon from "@mui/icons-material/ExtensionOutlined";
import LocalShippingOutlinedIcon from "@mui/icons-material/LocalShippingOutlined";
import { useEffect, useState } from "react";

const theme = createTheme({
  components: {
    MuiAccordionDetails: {
      styleOverrides: {
        root: {
          padding: 0,
        },
      },
    },
    MuiAccordion: {
      styleOverrides: {
        root: {
          boxShadow: "none",
        },
      },
    },
    MuiAccordionSummary: {
      styleOverrides: {
        root: {
          minHeight: "48px!important",
        },
        content: {
          margin: "0 !important",
          alignItems: "center",
        },
      },
    },
    MuiTypography: {
      styleOverrides: {
        root: {
          fontSize: "14px",
        },
      },
    },
    MuiListItemIcon: {
      styleOverrides: {
        root: {
          minWidth: "24px !important",
        },
      },
    },
    MuiListItemText: {
      styleOverrides: {
        root: {
          display: "flex",
          justifyContent: "flex-start",
        },
      },
    },
  },
});

const NavLinks = () => {
  const navigate = useNavigate();
  const drawerOpen = useRecoilValue(drawerOpenState);
  const [token] = useCookies(["jwt"]);
  const jwt = token?.jwt;

  const [expanded, setExpanded] = useState(false);

  const isCreateShipment =
    window.location.pathname === "/create-shipment" ||
    window.location.pathname === "/create-fast-shipment";

  // const user = JSON.parse(localStorage.getItem("user"));
  // const statusActive = Number(user?.user?.user_member?.status) === 2;
  const statusActive = true

  const handleExpand = () => {
    setExpanded(!expanded);
  };

  const logoutHandler = async () => {
    axios.defaults.headers.common["Authorization"] = `Bearer ${jwt}`;

    await axios
      .post("logout")
      .then((res) => {
        toast.success(res.data.message, { autoClose: 500 });
        localStorage.removeItem("user");
        document.cookie = `jwt=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/;`;
        navigate("/login");
      })
      .catch((err) => {
        Errors(err);
        localStorage.removeItem("user");
        document.cookie = `jwt=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/;`;
        navigate("/login");
      });
  };

  const handleNavigate = (location) => {
    if (statusActive) {
      navigate(location);
    }
  };

  useEffect(() => {
    if (
      window.location.pathname === "/create-shipment" ||
      window.location.pathname === "/create-fast-shipment"
    ) {
      setExpanded(true);
    }
  }, []);

  return (
    <Box className="navbar-links">
      <List>
        {[
          { page: "Pano", location: "/dashboard" },
          [
            { page: "Gönderi Oluştur", location: "/create-shipment" },
            {
              page: "Entegre Gönderi Oluştur",
              location: "/create-fast-shipment",
            },
            {
              page: "Toplu Gönderi Oluştur",
              location: "/create-fast-all-shipment",
            },
          ],
          { page: "Etiket Oluştur", location: "/orders" },
          { page: "Gönderilerim", location: "/my-shipment" },
          { page: "İadeler", location: "/returns" },
          { page: "Sepetim", location: "/basket" },
          { page: "Entegrasyon", location: "/integrations" },
          { page: "Fiyat Hesaplama", location: "/calculator" },
          { page: "Kurye Kaydı", location: "/courier-registration" },
          { page: "Destek Talebi", location: "/support" },
          { page: "Gerekli Bilgiler", location: "/info" },
        ].map((text, index) =>
          Array.isArray(text) ? (
            <ThemeProvider key={index} theme={theme}>
              <Accordion
                onChange={handleExpand}
                expanded={!statusActive ? false : expanded}
                key={index}
                disabled={!statusActive}
              >
                <AccordionSummary
                  expandIcon={
                    <ExpandMoreOutlined
                      sx={{
                        display: drawerOpen ? "block" : "none",
                        color: isCreateShipment ? "var(--navActiveLink)" : "var(--priceText)",
                      }}
                    />
                  }
                  aria-controls="panel1a-content"
                  id="panel1a-header"
                  sx={{
                    position: "relative",
                    color: isCreateShipment ? "var(--navActiveLink)" : "#000000DE",
                  }}
                >
                  {index === 1 ? (
                    <div
                      style={{
                        color: isCreateShipment ? "var(--navActiveLink)" : "var(--priceText)",
                        display: "flex",
                        alignItems: "center",
                      }}
                    >
                      <svg
                        width="24"
                        height="24"
                        viewBox="0 0 24 24"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                        style={{
                          marginLeft: drawerOpen ? "8px" : "3px",
                          transition: "all 150ms linear",
                        }}
                      >
                        <mask
                          id="mask0_6114_14419"
                          style={{ maskType: "alpha" }}
                          maskUnits="userSpaceOnUse"
                          x="0"
                          y="0"
                          width="24"
                          height="24"
                        >
                          <rect y="0.5" width="24" height="24" fill="#D9D9D9" />
                        </mask>
                        <g mask="url(#mask0_6114_14419)">
                          <path
                            d="M5 8.5H13.45H13H13.35H5ZM5.4 6.5H18.6L17.75 5.5H6.25L5.4 6.5ZM10 13.25L12 12.25L14 13.25V8.5H10V13.25ZM14.55 21.5H5C4.45 21.5 3.97917 21.3042 3.5875 20.9125C3.19583 20.5208 3 20.05 3 19.5V7.025C3 6.79167 3.0375 6.56667 3.1125 6.35C3.1875 6.13333 3.3 5.93333 3.45 5.75L4.7 4.225C4.88333 3.99167 5.1125 3.8125 5.3875 3.6875C5.6625 3.5625 5.95 3.5 6.25 3.5H17.75C18.05 3.5 18.3375 3.5625 18.6125 3.6875C18.8875 3.8125 19.1167 3.99167 19.3 4.225L20.55 5.75C20.7 5.93333 20.8125 6.13333 20.8875 6.35C20.9625 6.56667 21 6.79167 21 7.025V11.925C20.6833 11.8083 20.3583 11.7167 20.025 11.65C19.6917 11.5833 19.35 11.55 19 11.55V8.5H16V12.325C15.4167 12.6583 14.9083 13.0708 14.475 13.5625C14.0417 14.0542 13.7 14.6083 13.45 15.225L12 14.5L8 16.5V8.5H5V19.5H13.35C13.4833 19.8833 13.65 20.2417 13.85 20.575C14.05 20.9083 14.2833 21.2167 14.55 21.5ZM18 21.5V18.5H15V16.5H18V13.5H20V16.5H23V18.5H20V21.5H18Z"
                            fill="currentColor"
                          />
                        </g>
                      </svg>
                      <ExpandMoreOutlined sx={{ display: drawerOpen ? "none" : "block" }} />
                    </div>
                  ) : null}
                  <ListItemText
                    primary={"Yeni Gönderi Oluştur"}
                    sx={{
                      opacity: drawerOpen ? 1 : 0,
                      marginLeft: "24px",
                    }}
                  />
                </AccordionSummary>
                <AccordionDetails>
                  <List>
                    {text.map((subText, subIndex) => (
                      <ListItem
                        key={subIndex}
                        className={window.location.pathname === subText.location ? "active" : ""}
                        disablePadding
                        sx={{
                          display: "block",
                        }}
                      >
                        <NavLink
                          style={{
                            minHeight: 20,
                            justifyContent: drawerOpen ? "initial" : "center",
                            px: 2.5,
                            display: "flex",
                            textDecoration: "none",
                            color: "currentcolor",
                            alignItems: "center ",
                          }}
                          to={subText.location}
                          id={`navlink_menu_${subIndex}`}
                          className="list-item-link"
                          reloadDocument
                        >
                          <ListItemText
                            style={
                              window.location.pathname === subText.location
                                ? { color: "var(--navActiveLink)" }
                                : {}
                            }
                            className="list-item-text"
                            primary={subText.page}
                            sx={{
                              opacity: drawerOpen ? 1 : 0,
                              marginRight: "30px",
                              marginLeft:
                                window.location.pathname === subText.location ? "25px" : "30px",
                              padding: "10px 10px 10px 42px",
                              backgroundColor:
                                window.location.pathname === subText.location
                                  ? "var(--pastelBlue)"
                                  : "var(--lighterBg)",
                              borderRadius: "var(--buttonBorderRadius)",
                              fontWeight: 600,
                            }}
                          />
                        </NavLink>
                      </ListItem>
                    ))}
                  </List>
                </AccordionDetails>
              </Accordion>
            </ThemeProvider>
          ) : (
            <ListItem
              key={index}
              className={window.location.pathname === text.location ? "active" : ""}
              disablePadding
              sx={{
                display: "flex",
              }}
            >
              <Button
                style={{
                  minHeight: 48,
                  justifyContent: drawerOpen ? "initial" : "center",
                  px: 2.5,
                  display: "flex",
                  textDecoration: "none",
                  color: "currentcolor",
                  alignItems: "center ",
                  position: "relative",
                  padding: 0,
                  textTransform: "none",
                  width: "100%",
                }}
                disabled={!statusActive}
                sx={{ ":disabled": { opacity: 0.3 } }}
                onClick={() => handleNavigate(text.location)}
                id={`navlink_menu_${index}`}
                className={statusActive ? "list-item-link" : "list-item-link disabled"}
                reloadDocument={index === 7 || index === 1 || index === 9 ? true : false}
              >
                <Tooltip title={drawerOpen ? "" : text.page} placement="right">
                  <ListItemIcon
                    sx={
                      window.location.pathname === text.location
                        ? {
                            minWidth: 0,
                            mr: drawerOpen ? 3 : 3,
                            ml: drawerOpen ? 2.4 : 1.9,
                            justifyContent: "center",
                            transition: "all 150ms linear",
                          }
                        : {
                            minWidth: 0,
                            mr: drawerOpen ? 3 : 3,
                            ml: drawerOpen ? 3 : 2.5,
                            justifyContent: "center",
                            transition: "all 150ms linear",
                          }
                    }
                    style={
                      window.location.pathname === text.location
                        ? { color: "var(--navActiveLink)" }
                        : {}
                    }
                  >
                    {index === 0 ? (
                      <GridViewOutlinedIcon
                        sx={{
                          width: "24px!important",
                          height: "24px!important",
                        }}
                      />
                    ) : index === 1 ? (
                      <svg
                        width="24"
                        height="24"
                        viewBox="0 0 24 24"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <mask
                          id="mask0_6114_14419"
                          style={{ maskType: "alpha" }}
                          maskUnits="userSpaceOnUse"
                          x="0"
                          y="0"
                          width="24"
                          height="24"
                        >
                          <rect y="0.5" width="24" height="24" fill="#D9D9D9" />
                        </mask>
                        <g mask="url(#mask0_6114_14419)">
                          <path
                            d="M5 8.5H13.45H13H13.35H5ZM5.4 6.5H18.6L17.75 5.5H6.25L5.4 6.5ZM10 13.25L12 12.25L14 13.25V8.5H10V13.25ZM14.55 21.5H5C4.45 21.5 3.97917 21.3042 3.5875 20.9125C3.19583 20.5208 3 20.05 3 19.5V7.025C3 6.79167 3.0375 6.56667 3.1125 6.35C3.1875 6.13333 3.3 5.93333 3.45 5.75L4.7 4.225C4.88333 3.99167 5.1125 3.8125 5.3875 3.6875C5.6625 3.5625 5.95 3.5 6.25 3.5H17.75C18.05 3.5 18.3375 3.5625 18.6125 3.6875C18.8875 3.8125 19.1167 3.99167 19.3 4.225L20.55 5.75C20.7 5.93333 20.8125 6.13333 20.8875 6.35C20.9625 6.56667 21 6.79167 21 7.025V11.925C20.6833 11.8083 20.3583 11.7167 20.025 11.65C19.6917 11.5833 19.35 11.55 19 11.55V8.5H16V12.325C15.4167 12.6583 14.9083 13.0708 14.475 13.5625C14.0417 14.0542 13.7 14.6083 13.45 15.225L12 14.5L8 16.5V8.5H5V19.5H13.35C13.4833 19.8833 13.65 20.2417 13.85 20.575C14.05 20.9083 14.2833 21.2167 14.55 21.5ZM18 21.5V18.5H15V16.5H18V13.5H20V16.5H23V18.5H20V21.5H18Z"
                            fill="currentColor"
                          />
                        </g>
                      </svg>
                    ) : index === 2 ? (
                      <svg
                        width="24"
                        height="24"
                        viewBox="0 0 24 24"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <mask
                          id="mask0_6114_12750"
                          style={{ maskType: "alpha" }}
                          maskUnits="userSpaceOnUse"
                          x="0"
                          y="0"
                          width="24"
                          height="24"
                        >
                          <rect y="0.5" width="24" height="24" fill="#D9D9D9" />
                        </mask>
                        <g mask="url(#mask0_6114_12750)">
                          <path
                            d="M2.5 7.5C2.22386 7.5 2 7.27614 2 7V3C2 2.72386 2.22386 2.5 2.5 2.5H6.5C6.77614 2.5 7 2.72386 7 3V4C7 4.27614 6.77614 4.5 6.5 4.5H4V7C4 7.27614 3.77614 7.5 3.5 7.5H2.5ZM2.5 22.5C2.22386 22.5 2 22.2761 2 22V18C2 17.7239 2.22386 17.5 2.5 17.5H3.5C3.77614 17.5 4 17.7239 4 18V20C4 20.2761 4.22386 20.5 4.5 20.5H6.5C6.77614 20.5 7 20.7239 7 21V22C7 22.2761 6.77614 22.5 6.5 22.5H2.5ZM17.5 22.5C17.2239 22.5 17 22.2761 17 22V21C17 20.7239 17.2239 20.5 17.5 20.5H20V18C20 17.7239 20.2239 17.5 20.5 17.5H21.5C21.7761 17.5 22 17.7239 22 18V22C22 22.2761 21.7761 22.5 21.5 22.5H17.5ZM20.5 7.5C20.2239 7.5 20 7.27614 20 7V5C20 4.72386 19.7761 4.5 19.5 4.5H17.5C17.2239 4.5 17 4.27614 17 4V3C17 2.72386 17.2239 2.5 17.5 2.5H21.5C21.7761 2.5 22 2.72386 22 3V7C22 7.27614 21.7761 7.5 21.5 7.5H20.5ZM17.5 18H19V19.5H17.5V18ZM17.5 15H19V16.5H17.5V15ZM16 16.5H17.5V18H16V16.5ZM14.5 18H16V19.5H14.5V18ZM13 16.5H14.5V18H13V16.5ZM16 13.5H17.5V15H16V13.5ZM14.5 15H16V16.5H14.5V15ZM13 13.5H14.5V15H13V13.5ZM19 5.5V11.5H13V5.5H19ZM11 13.5V19.5H5V13.5H11ZM11 5.5V11.5H5V5.5H11ZM9.5 18V15H6.5V18H9.5ZM9.5 10V7H6.5V10H9.5ZM17.5 10V7H14.5V10H17.5Z"
                            fill="currentColor"
                          />
                        </g>
                      </svg>
                    ) : index === 3 ? (
                      <svg
                        width="24"
                        height="24"
                        viewBox="0 0 24 24"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <mask
                          id="mask0_6114_17436"
                          style={{ maskType: "alpha" }}
                          maskUnits="userSpaceOnUse"
                          x="0"
                          y="0"
                          width="24"
                          height="24"
                        >
                          <rect y="0.5" width="24" height="24" fill="#D9D9D9" />
                        </mask>
                        <g mask="url(#mask0_6114_17436)">
                          <path
                            d="M5 8.5V19.5H19V8.5H16V16.5L12 14.5L8 16.5V8.5H5ZM5 21.5C4.45 21.5 3.97917 21.3042 3.5875 20.9125C3.19583 20.5208 3 20.05 3 19.5V7.025C3 6.79167 3.0375 6.56667 3.1125 6.35C3.1875 6.13333 3.3 5.93333 3.45 5.75L4.7 4.225C4.88333 3.99167 5.1125 3.8125 5.3875 3.6875C5.6625 3.5625 5.95 3.5 6.25 3.5H17.75C18.05 3.5 18.3375 3.5625 18.6125 3.6875C18.8875 3.8125 19.1167 3.99167 19.3 4.225L20.55 5.75C20.7 5.93333 20.8125 6.13333 20.8875 6.35C20.9625 6.56667 21 6.79167 21 7.025V19.5C21 20.05 20.8042 20.5208 20.4125 20.9125C20.0208 21.3042 19.55 21.5 19 21.5H5ZM5.4 6.5H18.6L17.75 5.5H6.25L5.4 6.5ZM10 8.5V13.25L12 12.25L14 13.25V8.5H10Z"
                            fill="currentColor"
                          />
                        </g>
                      </svg>
                    ) : index === 4 ? (
                      <svg
                        width="24"
                        height="24"
                        viewBox="0 0 24 24"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <mask
                          id="mask0_6114_16820"
                          style={{ maskType: "alpha" }}
                          maskUnits="userSpaceOnUse"
                          x="0"
                          y="0"
                          width="24"
                          height="24"
                        >
                          <rect y="0.5" width="24" height="24" fill="#D9D9D9" />
                        </mask>
                        <g mask="url(#mask0_6114_16820)">
                          <path
                            d="M12 21.5C10.75 21.5 9.57917 21.2625 8.4875 20.7875C7.39583 20.3125 6.44583 19.6708 5.6375 18.8625C4.82917 18.0542 4.1875 17.1042 3.7125 16.0125C3.2375 14.9208 3 13.75 3 12.5C3 11.25 3.2375 10.0792 3.7125 8.9875C4.1875 7.89583 4.82917 6.94583 5.6375 6.1375C6.44583 5.32917 7.39583 4.6875 8.4875 4.2125C9.57917 3.7375 10.75 3.5 12 3.5C13.3667 3.5 14.6625 3.79167 15.8875 4.375C17.1125 4.95833 18.15 5.78333 19 6.85V4.5H21V10.5H15V8.5H17.75C17.0667 7.56667 16.225 6.83333 15.225 6.3C14.225 5.76667 13.15 5.5 12 5.5C10.05 5.5 8.39583 6.17917 7.0375 7.5375C5.67917 8.89583 5 10.55 5 12.5C5 14.45 5.67917 16.1042 7.0375 17.4625C8.39583 18.8208 10.05 19.5 12 19.5C13.75 19.5 15.2792 18.9333 16.5875 17.8C17.8958 16.6667 18.6667 15.2333 18.9 13.5H20.95C20.7 15.7833 19.7208 17.6875 18.0125 19.2125C16.3042 20.7375 14.3 21.5 12 21.5Z"
                            fill="currentColor"
                          />
                        </g>
                      </svg>
                    ) : index === 5 ? (
                      <ShoppingCartOutlinedIcon
                        sx={{
                          width: "24px!important",
                          height: "24px!important",
                        }}
                      />
                    ) : index === 6 ? (
                      <ExtensionOutlinedIcon
                        sx={{
                          width: "24px!important",
                          height: "24px!important",
                        }}
                      />
                    ) : index === 7 ? (
                      <CalculateOutlinedIcon
                        sx={{
                          width: "24px!important",
                          height: "24px!important",
                        }}
                      />
                    ) : index === 8 ? (
                      <LocalShippingOutlinedIcon
                        sx={{
                          width: "24px!important",
                          height: "24px!important",
                        }}
                      />
                    ) : index === 9 ? (
                      <svg
                        width="24"
                        height="24"
                        viewBox="0 0 24 24"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <mask
                          id="mask0_6114_17680"
                          style={{ maskType: "alpha" }}
                          maskUnits="userSpaceOnUse"
                          x="0"
                          y="0"
                          width="24"
                          height="24"
                        >
                          <rect y="0.5" width="24" height="24" fill="#D9D9D9" />
                        </mask>
                        <g mask="url(#mask0_6114_17680)">
                          <path
                            d="M12 7.5C12.2833 7.5 12.5208 7.40417 12.7125 7.2125C12.9042 7.02083 13 6.78333 13 6.5C13 6.21667 12.9042 5.97917 12.7125 5.7875C12.5208 5.59583 12.2833 5.5 12 5.5C11.7167 5.5 11.4792 5.59583 11.2875 5.7875C11.0958 5.97917 11 6.21667 11 6.5C11 6.78333 11.0958 7.02083 11.2875 7.2125C11.4792 7.40417 11.7167 7.5 12 7.5ZM11 15.5H13V9.5H11V15.5ZM2 22.5V4.5C2 3.95 2.19583 3.47917 2.5875 3.0875C2.97917 2.69583 3.45 2.5 4 2.5H20C20.55 2.5 21.0208 2.69583 21.4125 3.0875C21.8042 3.47917 22 3.95 22 4.5V16.5C22 17.05 21.8042 17.5208 21.4125 17.9125C21.0208 18.3042 20.55 18.5 20 18.5H6L2 22.5ZM5.15 16.5H20V4.5H4V17.625L5.15 16.5Z"
                            fill="currentColor"
                          />
                        </g>
                      </svg>
                    ) : index === 10 ? (
                      <SupportAgentIcon
                        sx={{
                          width: "24px!important",
                          height: "24px!important",
                        }}
                      />
                    ) : (
                      ""
                    )}
                  </ListItemIcon>
                </Tooltip>
                <ListItemText
                  style={
                    window.location.pathname === text.location
                      ? { color: "var(--navActiveLink)" }
                      : {}
                  }
                  className="list-item-text"
                  primary={text.page}
                  sx={{ opacity: drawerOpen ? 1 : 0 }}
                />
                {(index === 2 || index === 4) && drawerOpen && (
                  <div
                    style={{
                      position: "absolute",
                      right: "60px",
                      backgroundColor: "var(--shipmentYellow)",
                      fontSize: "11px",
                      fontWeight: 700,
                      padding: "4px",
                      borderRadius: "var(--buttonBorderRadius)",
                    }}
                  >
                    YENİ
                  </div>
                )}
              </Button>
            </ListItem>
          )
        )}
        {["Güvenli Çıkış"].map((text, index) => (
          <ListItem key={index} disablePadding sx={{ display: "block" }}>
            <ListItemButton
              sx={{
                minHeight: 48,
                justifyContent: drawerOpen ? "initial" : "center",
                px: 2.5,
              }}
              onClick={logoutHandler}
            >
              <Tooltip title={drawerOpen ? "" : text} placement="right">
                <ListItemIcon
                  sx={{
                    minWidth: 0,
                    mr: drawerOpen ? 3 : "auto",
                    justifyContent: "center",
                    marginLeft: "5px",
                    marginRight: "20px",
                  }}
                >
                  <LogoutRoundedIcon sx={{ width: "24px!important", height: "24px!important" }} />
                </ListItemIcon>
              </Tooltip>
              <ListItemText
                className="navbar-link-text"
                primary={text}
                sx={{ opacity: drawerOpen ? 1 : 0 }}
              />
            </ListItemButton>
          </ListItem>
        ))}
      </List>
    </Box>
  );
};

export default NavLinks;
