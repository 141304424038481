import { Box, TableCell, TableHead, TableRow } from "@mui/material";
import KeyboardArrowDownOutlinedIcon from "@mui/icons-material/KeyboardArrowDownOutlined";
import { useCallback, useState } from "react";
import { useRecoilState } from "recoil";
import axios from "../../../api/axios";
import {
  myBillsCurrentPageState,
  myBillsDataState,
  myBillsTotalPageState,
} from "../../../atoms/myWallet";
import Errors from "../../../utils/Errors";

const BillsTableHead = () => {
  const [, setMyBillsData] = useRecoilState(myBillsDataState);
  const [myBillsCurrentPage, setMyBillsCurrentPage] = useRecoilState(myBillsCurrentPageState);
  const [, setMyBillsTotalPage] = useRecoilState(myBillsTotalPageState);
  const [activeSort, setActiveSort] = useState([
    { column: "invoice_no", direction: null },
    { column: "invoice_date", direction: null },
    { column: "description", direction: null },
    { column: "get_invoice_items_count", direction: null },
    { column: "invoice_amount", direction: null },
  ]);

  const handleMyBillsPageChange = useCallback(
    async (orderBy) => {
      const updatedSort = activeSort.map((sort) => {
        if (sort.column === orderBy) {
          return {
            ...sort,
            direction: sort.direction === "ASC" ? "DESC" : "ASC",
          };
        }
        return {
          ...sort,
          direction: null, // Diğer sütunların yönünü varsayılan duruma sıfırlayın
        };
      });

      setActiveSort(updatedSort);

      const currentDirection = updatedSort.find((sort) => sort.column === orderBy).direction;

      await axios
        .get(`invoice`, {
          params: {
            page: myBillsCurrentPage,
            limit: 100,
            order_by: orderBy,
            order_direction: currentDirection,
            list_type: "initial",
          },
        })
        .then((resp) => {
          setMyBillsData(resp.data.data);
          setMyBillsCurrentPage(resp.data.currentPage);
          setMyBillsTotalPage(resp.data.totalPages);
        })
        .catch((error) => {
          Errors(error);
        });
    },
    [setMyBillsData, setMyBillsCurrentPage, setMyBillsTotalPage, myBillsCurrentPage, activeSort]
  );

  return (
    <TableHead>
      <TableRow>
        <TableCell sx={{ fontWeight: 700 }} align="center">
          <Box
            sx={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              color: "var(--priceText)",
              fontSize: "13px",
            }}
          >
            Fatura No{" "}
            <KeyboardArrowDownOutlinedIcon
              onClick={() => handleMyBillsPageChange("invoice_no")}
              sx={{
                transform:
                  activeSort.find((sort) => sort.column === "invoice_no").direction === "ASC"
                    ? "rotate(180deg)"
                    : "rotate(0deg)",
                transition: "transform 0.3s",
              }}
            />
          </Box>
        </TableCell>
        <TableCell sx={{ fontWeight: 700 }} align="center">
          <Box
            sx={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              color: "var(--priceText)",
              fontSize: "13px",
            }}
          >
            Tarih{" "}
            <KeyboardArrowDownOutlinedIcon
              onClick={() => handleMyBillsPageChange("invoice_date")}
              sx={{
                transform:
                  activeSort.find((sort) => sort.column === "invoice_date").direction === "ASC"
                    ? "rotate(180deg)"
                    : "rotate(0deg)",
                transition: "transform 0.3s",
              }}
            />
          </Box>
        </TableCell>
        <TableCell sx={{ fontWeight: 700 }} align="center">
          <Box
            sx={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              color: "var(--priceText)",
              fontSize: "13px",
            }}
          >
            Fatura Açıklaması{" "}
            <KeyboardArrowDownOutlinedIcon
              onClick={() => handleMyBillsPageChange("description")}
              sx={{
                transform:
                  activeSort.find((sort) => sort.column === "description").direction === "ASC"
                    ? "rotate(180deg)"
                    : "rotate(0deg)",
                transition: "transform 0.3s",
              }}
            />
          </Box>
        </TableCell>
        <TableCell sx={{ fontWeight: 700 }} align="center">
          <Box
            sx={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              color: "var(--priceText)",
              fontSize: "13px",
            }}
          >
            Fatura Gönderi Adedi{" "}
            <KeyboardArrowDownOutlinedIcon
              onClick={() => handleMyBillsPageChange("get_invoice_items_count")}
              sx={{
                transform:
                  activeSort.find((sort) => sort.column === "get_invoice_items_count").direction ===
                  "ASC"
                    ? "rotate(180deg)"
                    : "rotate(0deg)",
                transition: "transform 0.3s",
              }}
            />
          </Box>
        </TableCell>
        <TableCell sx={{ fontWeight: 700 }} align="center">
          <Box
            sx={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              color: "var(--priceText)",
              fontSize: "13px",
            }}
          >
            Fatura Tutarı{" "}
            <KeyboardArrowDownOutlinedIcon
              onClick={() => handleMyBillsPageChange("invoice_amount")}
              sx={{
                transform:
                  activeSort.find((sort) => sort.column === "invoice_amount").direction === "ASC"
                    ? "rotate(180deg)"
                    : "rotate(0deg)",
                transition: "transform 0.3s",
              }}
            />
          </Box>
        </TableCell>
      </TableRow>
    </TableHead>
  );
};

export default BillsTableHead;
