import PhoneInput from "react-phone-input-2";
import { useRecoilState, useRecoilValue, useSetRecoilState } from "recoil";
import { phoneState, phoneVerifiedState, timerSmsModalState } from "../../../../atoms/membership";

import HighlightOffIcon from "@mui/icons-material/HighlightOff";
import CheckCircleOutlineIcon from "@mui/icons-material/CheckCircleOutline";
import axios from "../../../../api/axios";
import { toast } from "react-toastify";
import Errors from "../../../../utils/Errors";
import { FormControl, FormHelperText } from "@mui/material";

const ProfilePhone = ({ handleSubmit, disabled, edit, valid, setValid }) => {
  const phoneVerified = useRecoilValue(phoneVerifiedState);
  const setTimerSmsModal = useSetRecoilState(timerSmsModalState);
  const [phone, setPhone] = useRecoilState(phoneState);

  const handleChange = (value) => {
    setValid(false);
    setPhone(value);
  };

  const handleSMSVerify = () => {
    axios
      .post("profile/phone-verification-sms", {
        phone: `+${phone}`,
      })
      .then((response) => {
        toast.success(response.data.message);
        setTimerSmsModal(true);
      })
      .catch((error) => Errors(error));
  };

  return (
    <div>
      <div style={{ height: "37px", position: "relative" }}>
        <FormControl size="small" fullWidth>
          <PhoneInput
            country={"tr"}
            value={phone}
            onChange={handleChange}
            onEnterKeyPress={handleSubmit}
            disabled={disabled()}
            isValid={(value, country) => {
              if (value.match(/12345/)) {
                return "Invalid value: " + value + ", " + country.name;
              } else if (value.match(/1234/)) {
                return false;
              } else {
                return true;
              }
            }}
            inputProps={{
              name: "phone",
              required: true,
              autoFocus: true,
              id: "ind-phone-input",
              style: {
                height: "100%",
                width: "100%",
                minHeight: "37px",
                maxHeight: "37px",
                opacity: disabled() ? 0.4 : 1,
                borderColor: valid ? "var(--error)" : "var(--inputBorderColor)",
              },
            }}
          />
          {valid && (
            <FormHelperText sx={{ color: "var(--error)" }}>
              Telefon numaranızı giriniz.
            </FormHelperText>
          )}
        </FormControl>
        {Number(phoneVerified) === 1 ? (
          <CheckCircleOutlineIcon
            sx={{
              position: "absolute",
              width: "24px",
              height: "24px",
              color: "var(--shipmentGreen)",
              right: "5px",
              opacity: disabled() ? 0.4 : 1,
              top: "7px",
            }}
          />
        ) : (
          phone !== null && (
            <HighlightOffIcon
              sx={{
                position: "absolute",
                width: "24px",
                height: "24px",
                color: "var(--shipmentRed)",
                right: "5px",
                top: "7px",
              }}
            />
          )
        )}
      </div>
      {!edit && Number(phoneVerified) === 0 && phone !== null && (
        <div style={{ padding: "5px", color: "var(--shipmentRed)" }}>
          Tekrar sms ile onay kodu almak için{" "}
          <span
            style={{ textDecoration: "underline", fontWeight: 700, cursor: "pointer" }}
            onClick={handleSMSVerify}
          >
            tıklayınız!
          </span>
        </div>
      )}
    </div>
  );
};

export default ProfilePhone;
