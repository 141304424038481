import { Autocomplete, Box, TextField } from "@mui/material";
import { useRecoilState } from "recoil";
import { tckTypeState } from "../../../../atoms/membership";
import CountriesTurkishSorted from "../../../../utils/CountriesTurkishSorted";

const ProfileCountry = ({ disabled }) => {
  const [tckType, setTckType] = useRecoilState(tckTypeState);

  return (
    <Autocomplete
      id="country-typing-select"
      fullWidth
      size="small"
      sx={{ ":disabled": { opacity: 0.3 } }}
      disabled={disabled()}
      options={CountriesTurkishSorted()}
      value={CountriesTurkishSorted().find((option) => option.iso_code === tckType) || null}
      onChange={(e, val) => {
        if (val) {
          setTckType(val.iso_code);
        }
      }}
      openOnFocus={false}
      getOptionLabel={(option) => `${option.country_name_tr}, ${option.country_name_en}`}
      renderOption={(props, option) => (
        <Box component="li" sx={{ "& > img": { mr: 2, flexShrink: 0 } }} {...props}>
          <img
            loading="lazy"
            width="20"
            src={`https://flagcdn.com/w20/${option.iso_code.toLowerCase()}.png`}
            srcSet={`https://flagcdn.com/w40/${option.iso_code.toLowerCase()}.png 2x`}
            alt=""
          />
          {option.country_name_tr}, {option.country_name_en}
        </Box>
      )}
      renderInput={(params) => (
        <TextField
          {...params}
          label="Uyruk"
          required
          name="unique-country-field"
          value={tckType}
          inputProps={{
            ...params.inputProps,
            autoComplete: "off", // disable autocomplete and autofill
          }}
        />
      )}
    />
  );
};

export default ProfileCountry;
