import {
  Modal,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from "@mui/material";
import TrendingUpOutlinedIcon from "@mui/icons-material/TrendingUpOutlined";
import CancelOutlinedIcon from "@mui/icons-material/CancelOutlined";
import { basketFinancialState } from "../../atoms/basket";
import { useRecoilState } from "recoil";
import { useCallback } from "react";
import FormatDate from "../../utils/FormatDate";
import LocalPrice from "../../utils/LocalPrice";
import Currency from "../../utils/CurrencySymbol";

const BasketDetailedFinancialModal = () => {
  const [basketFinancial, setBasketFinancial] = useRecoilState(basketFinancialState);

  const handleClose = useCallback(() => {
    setBasketFinancial({ status: false, data: [], date: "" });
  }, [setBasketFinancial]);

  return (
    <Modal open={basketFinancial.status} onClose={handleClose}>
      <TableContainer
        sx={{
          outline: 0,
          maxWidth: 1100,
          margin: "20px auto",
          position: "relative",
          maxHeight: "90vh",
          overflowY: "auto",
        }}
        component={Paper}
      >
        <CancelOutlinedIcon
          sx={{
            position: "absolute",
            right: 1,
            top: 1,
            color: "var(--priceText)",
            cursor: "pointer",
            transition: "all 80ms linear",
            ":hover": {
              color: "var(--notRed)",
              transition: "all 80ms linear",
            },
          }}
          onClick={handleClose}
        />
        <div
          style={{
            display: "flex",
            alignItems: "center",
            fontWeight: 700,
            gap: "var(--gap)",
            padding: "20px",
          }}
        >
          <TrendingUpOutlinedIcon /> Finansal Değerler
        </div>
        <Table aria-label="simple table">
          <TableHead>
            <TableRow>
              <TableCell sx={{ fontWeight: 700, paddingLeft: "20px" }}>Tarih</TableCell>
              <TableCell sx={{ fontWeight: 700 }}>Açıklama</TableCell>
              <TableCell sx={{ fontWeight: 700 }}>Fiyat</TableCell>
              <TableCell sx={{ fontWeight: 700, paddingRight: "20px" }}>Durum</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {basketFinancial?.data?.get_details?.map((row, key) => (
              <TableRow
                key={key}
                sx={{
                  "&:last-child td, &:last-child th": {
                    border: 0,
                  },
                  ":nth-of-type(even)": {
                    backgroundColor: "var(--pastelBlue)",
                  },
                }}
              >
                <TableCell component="th" scope="row" sx={{ paddingLeft: "20px" }}>
                  {FormatDate(basketFinancial.date)}
                  <br />
                  {row.hour}
                </TableCell>
                <TableCell align="left">{row.type}</TableCell>
                <TableCell align="left">
                  <div style={{ fontSize: "12px" }}>
                    {Currency(basketFinancial?.data?.get_parcel?.currency)}
                    {LocalPrice(row.selling_price)}
                  </div>
                </TableCell>
                <TableCell align="left" sx={{ paddingRight: "20px" }}>
                  <div
                    style={
                      row.paid === "0"
                        ? {
                            backgroundColor: "var(--error)",
                            width: "fit-content",
                            borderRadius: "var(--buttonBorderRadius)",
                            color: "var(--lighterBg)",
                            padding: "2px 7px",
                            fontSize: "12px",
                          }
                        : {
                            backgroundColor: "var(--greenBrand)",
                            width: "fit-content",
                            borderRadius: "var(--buttonBorderRadius)",
                            color: "var(--lighterBg)",
                            padding: "2px 7px",
                            fontSize: "12px",
                          }
                    }
                  >
                    {row.paid === "0" ? "Ödenmedi" : "Ödendi"}
                  </div>
                  <div style={{ fontSize: "12px" }}>{/* (Kur:&nbsp;{row.exchangeRate}) */}</div>
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </Modal>
  );
};

export default BasketDetailedFinancialModal;
