import { TextField } from "@mui/material";
import { DatePicker, LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";

const OrderBtwDateSearch = ({
  orderStartDate,
  setOrderStartDate,
  orderEndDate,
  setOrderEndDate,
}) => {
  return (
    <LocalizationProvider dateAdapter={AdapterDayjs}>
      <DatePicker
        label="Başlangıç Tarihi"
        inputFormat="DD-MM-YYYY"
        value={orderStartDate}
        onChange={(newValue) => {
          setOrderStartDate(newValue);
        }}
        renderInput={(params) => <TextField autoComplete="off" size="small" {...params} />}
      />
      <DatePicker
        label="Bitiş Tarihi"
        inputFormat="DD-MM-YYYY"
        value={orderEndDate}
        onChange={(newValue) => {
          setOrderEndDate(newValue);
        }}
        renderInput={(params) => <TextField autoComplete="off" size="small" {...params} />}
      />
    </LocalizationProvider>
  );
};

export default OrderBtwDateSearch;
