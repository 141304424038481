import { atom } from "recoil";

export const basketBalanceState = atom({
  key: "basketBalance",
  default: 0,
});

export const basketItemsState = atom({
  key: "basketItems",
  default: [],
});

export const basketItemsCurrentPageState = atom({
  key: "basketItemsCurrentPage",
  default: 1,
});

export const basketItemsTotalPageState = atom({
  key: "basketItemsTotalPage",
  default: 1,
});

export const basketTotalItemsState = atom({
  key: "basketTotalItems",
  default: 0,
});

export const basketPaymentOpenState = atom({
  key: "basketPaymentOpen",
  default: false,
});

export const basketCheckListState = atom({
  key: "basketCheckList",
  default: [],
});

export const basketTotalPriceState = atom({
  key: "basketTotalPrice",
  default: 0,
});

export const basketTotalPriceTryState = atom({
  key: "basketTotalPriceTry",
  default: 0,
});

export const basketFinancialState = atom({
  key: "basketFinancial",
  default: { status: false, data: [], date: "" },
});

export const basketInfoState = atom({
  key: "basketInfo",
  default: false,
});

export const freshListState = atom({
  key: "freshList",
  default: false,
});
