import "../style/forgot/Forgot.css";
import { DynamicTitle } from "../utils/DynamicTitle";
import ResetHeadline from "../components/forgot/ResetHeadline";
import ResetPasswordGet from "../components/forgot/ResetPasswordGet";

const ForgotPassword = () => {
  DynamicTitle("Şifremi Unuttum | iyziShip");

  const queryParams = new URLSearchParams(window.location.search);
  const token = queryParams.get("token");

  return (
    <>
      <section className="forgot-main">
        <div
          className="forgot-wrapper"
          style={{ padding: token ? "50px 70px" : 0 }}
        >
          {!token ? (
            <div
              style={{
                height: "100vh",
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                fontWeight: 700,
                color: "var(--priceText)",
                fontSize: "30px",
              }}
            >
              404 Not Found
            </div>
          ) : (
            <div className="forgot">
              <ResetHeadline />
              <ResetPasswordGet />
            </div>
          )}
        </div>
      </section>
    </>
  );
};

export default ForgotPassword;
