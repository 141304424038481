import "../../style/profileSettings/RegisteredCards.css";
import { Box } from "@mui/material";
import AddCardOutlinedIcon from "@mui/icons-material/AddCardOutlined";
import { useInView, animated } from "@react-spring/web";
import Cards from "./registeredCards/Cards";
import { DynamicTitle } from "../../utils/DynamicTitle";
import ProfileNavBar from "./ProfileNavBar";
import NotificationBar from "../sidebar/NotificationBar";
import { ToastContainer } from "react-toastify";
import { useCookies } from "react-cookie";
import axios from "../../api/axios";
import WrapperStyle from "../../utils/WrapperStyle";
import ProfileSettingsStyle from "../../style/styleJs/ProfileSettingsStyle";
import ProfileSettingsTitle from "./ProfileSettingsTitle";

const RegisteredCards = () => {
  DynamicTitle("Kayıtlı Kartlar");

  const [token] = useCookies(["jwt"]);
  const jwt = token?.jwt;
  axios.defaults.headers.common["Authorization"] = `Bearer ${jwt}`;

  const [ref, springs] = useInView(
    () => ({
      from: {
        opacity: 0,
        y: 20,
      },
      to: {
        opacity: 1,
        y: 0,
      },
    }),
    {
      rootMargin: "-10% 0%",
    }
  );

  return (
    <Box className="registered-cards-wrapper">
      <NotificationBar />
      <ProfileSettingsTitle />
      <ProfileNavBar />
      <ToastContainer />
      <div style={WrapperStyle()}>
        <animated.div ref={ref} style={springs}>
          <div style={ProfileSettingsStyle()} className="registered-cards">
            <div className="profile-card-wrapper">
              <div className="profile-card-header">
                <AddCardOutlinedIcon
                  sx={{
                    width: "20px",
                    height: "20px",
                    color: "var(--priceText)",
                  }}
                />
                <span style={{ color: "var(--priceText)", fontWeight: 700 }}>Kayıtlı Kartlar</span>
              </div>
            </div>
            <Cards />
          </div>
        </animated.div>
      </div>
    </Box>
  );
};

export default RegisteredCards;
