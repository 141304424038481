import "../style/login/Login.css";
import Header from "../components/beforeLogin/Header";
import Footer from "../components/beforeLogin/Footer";
import Register from "../components/sign/Register";
import { useNavigate } from "react-router-dom";
import { useCallback, useEffect } from "react";
import { useCookies } from "react-cookie";
// import PageTransition from "../utils/PageTransition";

const Home = () => {
  const navigate = useNavigate();

  const [token] = useCookies(["jwt"]);
  const jwt = token?.jwt;

  const handleLogin = useCallback(() => {
    navigate("/login");
  }, [navigate]);

  const handleSign = useCallback(() => {
    navigate("/register");
  }, [navigate]);

  useEffect(() => {
    if (jwt !== undefined && jwt !== null && jwt.length !== 0) {
      navigate("/dashboard");
    }
  }, [jwt, navigate]);

  return (
    <>
      {/* <PageTransition> */}
        <Header />
        <main className="login">
          <section className="login-sign">
            <div className="login-sign-container">
              <div className="sign-choice">
                <div className="sign-choice-wrapper">
                  <button onClick={handleLogin} className="navLink">
                    Giriş Yap
                  </button>
                  <button onClick={handleSign} className="navLink active">
                    Kayıt Ol
                  </button>
                </div>
              </div>
            </div>
            <Register />
          </section>
        </main>
        <Footer />
      {/* </PageTransition> */}
    </>
  );
};

export default Home;
