import { TextField } from "@mui/material";

const ParcelRefNo = ({ parcelRefNo, setParcelRefNo }) => {
  return (
    <TextField
      size="small"
      label="Ref No"
      value={parcelRefNo}
      autoComplete="off"
      onChange={(e) => setParcelRefNo(e.target.value)}
    />
  );
};

export default ParcelRefNo;
