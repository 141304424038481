import { Link } from "@mui/material";
import laptop from "../../img/getFinish/new.svg";

function GetFinish() {
  return (
    <section className="main-get-finish">
      <div className="insider">
        <div className="get-finish-headline">
          <article>
            <h6>Yurt dışına kargo göndermek artık bir tık uzağınızda!</h6>
            <p>
              Her bütçeye uygun, hızlı ve güvenilir çözümlerimizle yurt dışı
              kargo sürecinizi kolaylaştırıyoruz. Hemen kayıt olun ve iyziShip
              farkını her gönderinizde hissedin!
            </p>
          </article>
          <div className="headline-links">
            <a
              style={{
                padding: " 9px 14px",
                minWidth: "200px",
                fontWeight: 700,
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                textDecoration: "none",
                height: " 49.927px",
                gap: "10px",
                fontSize: "14px",
                lineHeight: "18px",
                cursor: "pointer",
              }}
              href="/register"
              className="iyzi-button-blue"
            >
              Ücretsiz Deneyin
            </a>
            <a
              style={{
                padding: "10px 30px",
                minWidth: "200px",
                fontWeight: 700,
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                textDecoration: "none",
                height: " 49.927px",
                gap: "10px",
                fontSize: "14px",
                lineHeight: "18px",
                cursor: "pointer",
              }}
              href="/fiyatlandirma"
              className="iyzi-button-bg"
            >
              Hemen Fiyat Alın
            </a>
          </div>
        </div>
        <div className="get-finish-mockup">
          <img alt="iyziShip" src={laptop} className="get-finish-laptop" />
        </div>
      </div>
    </section>
  );
}

export default GetFinish;
