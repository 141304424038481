import Box from "@mui/material/Box";
import {
  TextField,
  createTheme,
  ThemeProvider,
  FormControl,
  FormHelperText,
  Button,
} from "@mui/material";
import { useCallback, useState, useEffect } from "react";
import { toast } from "react-toastify";
import SpringIn from "../../profilesettings/addressInfo/SpringIn";
import { useRecoilState, useRecoilValue } from "recoil";
import CircularProgress from "@mui/material/CircularProgress";
import { useCookies } from "react-cookie";
import {
  arrivalCountryCodeState,
  receiverAddressesState,
  shipmentHideState,
  shipmentReceiverCityState,
  shipmentReceiverDistrictState,
  shipmentReceiverStateCodeState,
  shipmentReceiverStateState,
  shipmentZipCodeState,
  startShipmentReceiverAnimationState,
} from "../../../atoms/createShipment";
import axios from "../../../api/axios";
import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/style.css";
import countriesDetailed from "../../../json/countriesDetailed.json";
import { validateEmail } from "../../../utils/emailValidation";
import Errors from "../../../utils/Errors";

const theme = createTheme({
  components: {
    MuiFormHelperText: {
      styleOverrides: {
        root: {
          margin: "5px 0 0",
        },
      },
    },
  },
});

const CreateShipmentReceiverAddress = () => {
  const arrivalCountryCode = useRecoilValue(arrivalCountryCodeState);
  const shipmentZipCode = useRecoilValue(shipmentZipCodeState);

  const [startShipmentReceiverAnimation, setStartShipmentReceiverAnimation] = useRecoilState(
    startShipmentReceiverAnimationState
  );
  const [, setShipmentHide] = useRecoilState(shipmentHideState);
  const [receiverAddresses, setReceiverAddresses] = useRecoilState(receiverAddressesState);

  const [token] = useCookies(["jwt"]);
  const jwt = token.jwt;

  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");

  const [header, setHeader] = useState("");
  const [address, setAddress] = useState("");
  const [address2, setAddress2] = useState("");
  const [phone, setPhone] = useState("");
  const [email, setEmail] = useState("");
  const [companyName, setCompanyName] = useState("");

  const [shipmentReceiverState, setShipmentReceiverState] = useRecoilState(
    shipmentReceiverStateState
  );
  const [shipmentReceiverDistrict, setShipmentReceiverDistrict] = useRecoilState(
    shipmentReceiverDistrictState
  );
  const [shipmentReceiverCity, setShipmentReceiverCity] = useRecoilState(shipmentReceiverCityState);
  const [shipmentReceiverStateCode, setShipmentReceiverStateCode] = useRecoilState(
    shipmentReceiverStateCodeState
  );

  const selectedCountry = arrivalCountryCode;

  const [requestLoading, setRequestLoading] = useState(false);

  //* Error */

  const [phoneIsNotValid, setPhoneIsNotValid] = useState(false);

  //* Error End */

  // Returns an object not a list

  const addressRegisterHandler = async (e) => {
    e.preventDefault();

    // first_name ve last_name olarak parametreler geldiğinde inputlar ayrıldığı için ayrı ayrı gönderilecek. first_name: firstName, last_name: lastName şeklinde olacak.

    const data = {
      //Todo first_name ve last_name gelince parametrelerde contact_name kaldırılacak yerine first_name ve last_name gelecek.
      contact_name: `${firstName} ${lastName}`,
      email: email,
      address_description: header,
      address1: address,
      address2: address2,
      postal_code: shipmentZipCode,
      phone: phone,
      town: shipmentReceiverDistrict,
      city: shipmentReceiverCity,
      state: shipmentReceiverState,
      country: arrivalCountryCode,
      address_type: 5,
      state_code: shipmentReceiverStateCode,
      company_name: companyName,
    };

    if (phone.length < 6) {
      setPhoneIsNotValid(true);
      return;
    } else {
      setRequestLoading(true);
      await axios
        .post("member-address/add", data, {
          headers: {
            Authorization: `Bearer ${jwt}`,
          },
        })
        .then((response) => {
          setReceiverAddresses([...receiverAddresses, response.data.data]);
          toast.success(response.data.message);
          setStartShipmentReceiverAnimation(false);
          setShipmentHide(false);
        })
        .catch((error) => {
          Errors(error);
          setPhone("");
        })
        .finally(() => {
          setRequestLoading(false);
          setPhoneIsNotValid(false);
        });
    }
  };

  const addressRegisterAbortHandler = useCallback(() => {
    setStartShipmentReceiverAnimation(false);
    setShipmentHide(false);
  }, [setStartShipmentReceiverAnimation, setShipmentHide]);

  const countryName = `${
    countriesDetailed.find((e) => e.iso_code === selectedCountry).country_name_tr
  }, ${countriesDetailed.find((e) => e.iso_code === selectedCountry).country_name_en}`;

  const [emailError, setEmailError] = useState(false);
  const [isEmailValid, setIsEmailValid] = useState(true);

  const handleEmailChange = (e) => {
    const newEmail = e.target.value;

    setEmail(newEmail);

    if (!newEmail) {
      setIsEmailValid(false);
    } else {
      const isValidEmail = validateEmail(newEmail);
      setIsEmailValid(isValidEmail);
    }
  };

  useEffect(() => {
    setEmailError(!isEmailValid);
  }, [isEmailValid]);

  return (
    <>
      {startShipmentReceiverAnimation && (
        <SpringIn>
          <ThemeProvider theme={theme}>
            <form
              onSubmit={addressRegisterHandler}
              style={{ display: "grid", gap: "var(--gap2x)" }}
            >
              <h6 style={{ color: "var(--priceText)" }}>Alıcı Adresi</h6>
              <Box
                sx={{
                  display: "flex",
                  justifyContent: "space-between",
                  flexWrap: "wrap",
                  gap: "var(--gap2x)",
                }}
              >
                <TextField
                  value={header}
                  onChange={(e) => setHeader(e.target.value)}
                  className="new-address-header"
                  placeholder="Ev"
                  size="small"
                  label="Başlık"
                  autoComplete="off"
                />
              </Box>
              <Box className="new-address-modal-wrapper">
                <TextField
                  required
                  value={firstName}
                  onChange={(e) => setFirstName(e.target.value)}
                  fullWidth
                  size="small"
                  label="İsim"
                  autoComplete="off"
                />
                <TextField
                  required
                  value={lastName}
                  onChange={(e) => setLastName(e.target.value)}
                  fullWidth
                  size="small"
                  label="Soyisim"
                  autoComplete="off"
                />
                <TextField
                  value={companyName}
                  onChange={(e) => setCompanyName(e.target.value)}
                  fullWidth
                  size="small"
                  label="Firma Adı"
                  autoComplete="off"
                />
                <TextField
                  value={email}
                  required
                  onChange={handleEmailChange}
                  error={emailError}
                  helperText={emailError && "Geçerli bir email giriniz."}
                  size="small"
                  label="E-Posta"
                  type="email"
                  autoComplete="off"
                />
              </Box>
              <TextField
                required
                value={address}
                onChange={(e) => setAddress(e.target.value)}
                maxRows={4}
                inputProps={{ maxLength: 191 }}
                fullWidth
                size="medium"
                autoComplete="off"
                label="Adres Satırı"
              />
              <TextField
                value={address2}
                onChange={(e) => setAddress2(e.target.value)}
                maxRows={4}
                inputProps={{ maxLength: 191 }}
                fullWidth
                size="medium"
                autoComplete="off"
                label="2.Adres Satırı"
              />
              <Box className="new-address-modal-wrapper">
                <div
                  style={{
                    backgroundColor: "var(--pastelBlue)",
                    display: "flex",
                    alignItems: "center",
                    padding: "0 20px",
                    borderRadius: "var(--buttonBorderRadius)",
                    boxShadow: "var(--shadow)",
                    color: "var(--info)",
                  }}
                >
                  <b>Seçilen Ülke:&nbsp;</b> {countryName}
                </div>
                <div
                  style={{
                    backgroundColor: "var(--pastelBlue)",
                    display: "flex",
                    alignItems: "center",
                    padding: "0 20px",
                    borderRadius: "var(--buttonBorderRadius)",
                    boxShadow: "var(--shadow)",
                    color: "var(--info)",
                  }}
                >
                  <b>Posta Kodu:&nbsp;</b> {shipmentZipCode}
                </div>
                <FormControl size="small" fullWidth>
                  <PhoneInput
                    country={selectedCountry.toLowerCase()}
                    value={phone}
                    onChange={(e) => setPhone(e)}
                    onEnterKeyPress={addressRegisterHandler}
                    autoComplete="off"
                    isValid={(value, country) => {
                      if (value.match(/12345/)) {
                        return "Invalid value: " + value + ", " + country.name;
                      } else if (value.match(/1234/)) {
                        return false;
                      } else {
                        return true;
                      }
                    }}
                    inputProps={{
                      name: "phone",
                      autoFocus: true,
                      required: true,
                      id: "ind-phone-input",
                      style: {
                        height: "100%",
                        width: "100%",
                        minHeight: "40px",
                        maxHeight: "40px",
                        borderColor: phoneIsNotValid ? "var(--error)" : "var(--inputBorderColor)",
                      },
                    }}
                  />
                  {phoneIsNotValid && (
                    <FormHelperText sx={{ color: "var(--error)" }}>
                      Telefon numaranızı giriniz.
                    </FormHelperText>
                  )}
                </FormControl>
              </Box>
              <Box className="new-address-modal-wrapper">
                <TextField
                  required
                  value={shipmentReceiverState}
                  onChange={(e) => setShipmentReceiverState(e.target.value)}
                  fullWidth
                  size="small"
                  autoComplete="off"
                  label="Semt / Eyalet"
                />
                <TextField
                  required
                  value={shipmentReceiverDistrict}
                  onChange={(e) => setShipmentReceiverDistrict(e.target.value)}
                  fullWidth
                  size="small"
                  label="İlçe"
                  autoComplete="off"
                />
                <TextField
                  required
                  value={shipmentReceiverCity}
                  onChange={(e) => setShipmentReceiverCity(e.target.value)}
                  fullWidth
                  size="small"
                  autoComplete="off"
                  label="Şehir"
                />
                <TextField
                  required
                  value={shipmentReceiverStateCode}
                  onChange={(e) => setShipmentReceiverStateCode(e.target.value)}
                  fullWidth
                  size="small"
                  autoComplete="off"
                  label="Eyalet Kodu"
                />
              </Box>
              <Box className="new-address-register">
                {requestLoading ? (
                  <Box
                    sx={{
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                      width: "108px",
                    }}
                  >
                    <CircularProgress sx={{ width: "20px!important", height: "20px!important" }} />
                  </Box>
                ) : (
                  <Button className="iyzi-button-blue" type="submit">
                    Kaydet
                  </Button>
                )}
                <Button
                  type="button"
                  style={{ marginLeft: "10px" }}
                  className="iyzi-button-blue"
                  onClick={addressRegisterAbortHandler}
                >
                  İptal
                </Button>
              </Box>
            </form>
          </ThemeProvider>
        </SpringIn>
      )}
    </>
  );
};

export default CreateShipmentReceiverAddress;
