import { TableCell, TableRow } from "@mui/material";
import CurrencySymbol from "../../../utils/CurrencySymbol";
import { Link } from "react-router-dom";
import NavigateParcel from "../../../utils/NavigateParcel";
import LocalPrice from "../../../utils/LocalPrice";

const BillsKonsimentoTableBody = ({ row }) => {
  return (
    <TableRow
      sx={{
        transition: "all 150ms linear",
        ":hover": {
          transition: "all 150ms linear",
          backgroundColor: "var(--pastelBlue)",
        },
      }}
    >
      <TableCell>{row.line_no}</TableCell>
      <TableCell>{row.item_name}</TableCell>
      <TableCell>
        {/* {
          <Link
            style={{ fontSize: "inherit", color: "var(--inherit)" }}
            to={`/my-shipment?awb=${row.parcel_ref_no}`}
          >
            {row.parcel_ref_no}
          </Link>
        } */}
        {row.parcel_ref_no ? (
          <NavigateParcel parcel={row.parcel_ref_no} status={row?.status} />
        ) : (
          "---"
        )}
      </TableCell>
      <TableCell>
        {row.parcel_return_no === "0" ? (
          "---"
        ) : (
          <Link
            style={{ fontSize: "inherit", color: "var(--inherit)" }}
            to={`/my-shipment?awb=${row.parcel_return_no}`}
          >
            {row.parcel_return_no}
          </Link>
        )}
      </TableCell>
      <TableCell>{row.receiver_name}</TableCell>
      <TableCell>{row.receiver_country}</TableCell>
      <TableCell>{row.qty}</TableCell>
      <TableCell>%{row.vat}</TableCell>
      <TableCell>
        {CurrencySymbol(row.currency)}
        {LocalPrice(row.item_amount)}
      </TableCell>
    </TableRow>
  );
};

export default BillsKonsimentoTableBody;
