import { Button, Tooltip } from "@mui/material";
import { useRecoilValue, useSetRecoilState } from "recoil";
import axios from "../../../api/axios";
import {
  addressTypeState,
  isDefaultBillAddressState,
  isDefaultCollectionAddressState,
} from "../../../atoms/profileSettings";
import { useCookies } from "react-cookie";
import {
  profileBookingAddressState,
  profileReceiverAddressState,
  profileSenderAddressState,
  receiverCurrPageState,
  receiverTotalPageState,
  senderCurrPageState,
  senderTotalPageState,
} from "../../../atoms/membership";
import DefaultAddress from "./DefaultAddress";
import AddressSearch from "./AddressSearch";
import AddressPagination from "./AddressPagination";
import BookingAddress from "./BookingAddress";
import ControlPointOutlinedIcon from "@mui/icons-material/ControlPointOutlined";
import ChangeCircleOutlinedIcon from "@mui/icons-material/ChangeCircleOutlined";
import { startAnimationState } from "../../../atoms/startAnimation";
import { useCallback } from "react";
import ReceiverAddressTable from "./receiverAddress/ReceiverAddressTable";
import SenderAddressTable from "./senderAddress/SenderAddressTable";
import InfoOutlinedIcon from "@mui/icons-material/InfoOutlined";

const AddressList = ({ setAnchorEl }) => {
  const isDefaultBillAddress = useRecoilValue(isDefaultBillAddressState);
  const profileSenderAddress = useRecoilValue(profileSenderAddressState);
  const profileReceiverAddress = useRecoilValue(profileReceiverAddressState);
  const profileBookingAddress = useRecoilValue(profileBookingAddressState);
  const senderCurrPage = useRecoilValue(senderCurrPageState);
  const receiverCurrPage = useRecoilValue(receiverCurrPageState);
  const senderTotalPage = useRecoilValue(senderTotalPageState);
  const receiverTotalPage = useRecoilValue(receiverTotalPageState);
  const isDefaultCollectionAddress = useRecoilValue(isDefaultCollectionAddressState);

  const isBillExist = isDefaultBillAddress ? true : false;
  const isCollectionExist = isDefaultCollectionAddress ? true : false;

  const [token] = useCookies(["jwt"]);
  const jwt = token.jwt;
  axios.defaults.headers.common["Authorization"] = `Bearer ${jwt}`;

  const setStartAnimation = useSetRecoilState(startAnimationState);
  const setAddressType = useSetRecoilState(addressTypeState);

  const handleBillClose = useCallback(() => {
    setAnchorEl(null);
    setAddressType("1");
    setStartAnimation(true);
  }, [setStartAnimation, setAddressType, setAnchorEl]);

  const handleSenderClose = useCallback(() => {
    setAnchorEl(null);
    setAddressType("4");
    setStartAnimation(true);
  }, [setStartAnimation, setAddressType, setAnchorEl]);

  const handleReceiverClose = useCallback(() => {
    setAnchorEl(null);
    setAddressType("5");
    setStartAnimation(true);
  }, [setStartAnimation, setAddressType, setAnchorEl]);

  const handleBookingClose = useCallback(() => {
    setAnchorEl(null);
    setAddressType("2");
    setStartAnimation(true);
  }, [setStartAnimation, setAddressType, setAnchorEl]);

  return (
    <div className="address-list">
      <div className="address-list-defaults">
        <div className="address-list-default">
          <article style={{ marginLeft: "5px" }}>
            <h6>
              Fatura Adresim&nbsp;&nbsp;&nbsp;
              <Tooltip title={isBillExist ? "Fatura Adresini Değiştir" : "Fatura Adresi Ekle"}>
                <Button
                  className="iyzi-button-bg"
                  sx={{ justifyContent: "space-between" }}
                  onClick={handleBillClose}
                >
                  {isBillExist ? (
                    <>
                      <ChangeCircleOutlinedIcon /> Değiştir
                    </>
                  ) : (
                    <>
                      <ControlPointOutlinedIcon /> Ekle
                    </>
                  )}
                </Button>
              </Tooltip>
            </h6>
            <p
              style={{
                color: "var(--priceText)",
                display: "flex",
                alignItems: "center",
              }}
            >
              <InfoOutlinedIcon />
              &nbsp;&nbsp;Gönderinin faturasında yer alacak adres
            </p>
          </article>
          <div className="bill-address">
            {isDefaultBillAddress?.id !== undefined && (
              <DefaultAddress address={isDefaultBillAddress} key={isDefaultBillAddress?.id} />
            )}
          </div>
        </div>
        <div className="address-list-default">
          <article style={{ marginLeft: "5px" }}>
            <h6>
              Toplama Adresim&nbsp;&nbsp;&nbsp;
              <Tooltip
                title={isCollectionExist ? "Toplama Adresini Değiştir" : "Toplama Adresi Ekle"}
              >
                <Button
                  className="iyzi-button-bg"
                  sx={{ justifyContent: "space-between" }}
                  onClick={handleBookingClose}
                >
                  {isCollectionExist ? (
                    <>
                      <ChangeCircleOutlinedIcon /> Değiştir
                    </>
                  ) : (
                    <>
                      <ControlPointOutlinedIcon /> Ekle
                    </>
                  )}
                </Button>
              </Tooltip>
            </h6>
            <p
              style={{
                color: "var(--priceText)",
                display: "flex",
                alignItems: "center",
              }}
            >
              <InfoOutlinedIcon />
              &nbsp;&nbsp;Kurye kaydı açmanız durumunda gönderinin toplanacağı adres
            </p>
          </article>
          {/* <AddressSearch type={2} /> */}
          <div className="sender-address" style={{ display: "flex !important" }}>
            {profileBookingAddress
              .filter((address, idx) => idx < 1)
              .map((address) => (
                <BookingAddress key={address.id} address={address} />
              ))}
            {profileBookingAddress.length === 0 && "Toplama Adresiniz Bulunmamaktadır!"}
          </div>
        </div>
      </div>
      <div className="address-list-tables">
        <article style={{ marginLeft: "5px" }}>
          <h6>
            Gönderici Adreslerim&nbsp;&nbsp;&nbsp;
            <Tooltip title="Gönderici Adresi Ekle">
              <Button
                className="iyzi-button-bg"
                sx={{ justifyContent: "space-between" }}
                onClick={handleSenderClose}
              >
                <ControlPointOutlinedIcon /> Ekle
              </Button>
            </Tooltip>
          </h6>
          <p
            style={{
              color: "var(--priceText)",
              display: "flex",
              alignItems: "center",
            }}
          >
            <InfoOutlinedIcon />
            &nbsp;&nbsp;Konşimentonun üzerinde yer alacak gönderici adresi
          </p>
        </article>
        <AddressSearch type={4} />
        <div className="sender-address">
          {profileSenderAddress.length === 0 ? (
            <div
              style={{
                padding: "5px 10px",
                borderRadius: "var(--buttonBorderRadius)",
                backgroundColor: "var(--pastelBlue)",
                boxShadow: "var(--shadow)",
              }}
            >
              Gönderici Adresiniz Bulunmamaktadır!
            </div>
          ) : (
            <SenderAddressTable address={profileSenderAddress} />
          )}
        </div>
        {profileSenderAddress.length !== 0 && (
          <AddressPagination type={4} totalPage={senderTotalPage} currPage={senderCurrPage} />
        )}
      </div>
      <div className="address-list-tables">
        <article style={{ marginLeft: "5px" }}>
          <h6>
            Alıcı Adreslerim&nbsp;&nbsp;&nbsp;
            <Tooltip title="Alıcı Adresi Ekle">
              <Button
                className="iyzi-button-bg"
                sx={{ justifyContent: "space-between" }}
                onClick={handleReceiverClose}
              >
                <ControlPointOutlinedIcon /> Ekle
              </Button>
            </Tooltip>
          </h6>
          <p
            style={{
              color: "var(--priceText)",
              display: "flex",
              alignItems: "center",
            }}
          >
            <InfoOutlinedIcon />
            &nbsp;&nbsp;Konşimentonun üzerinde yer alacak alıcı adresi
          </p>
        </article>
        <AddressSearch type={5} />
        <div className="sender-address">
          {profileReceiverAddress.length === 0 ? (
            <div
              style={{
                padding: "5px 10px",
                borderRadius: "var(--buttonBorderRadius)",
                backgroundColor: "var(--pastelBlue)",
                boxShadow: "var(--shadow)",
              }}
            >
              Alıcı Adresiniz Bulunmamaktadır!
            </div>
          ) : (
            <ReceiverAddressTable />
          )}
        </div>
        {profileReceiverAddress.length !== 0 && (
          <AddressPagination type={5} totalPage={receiverTotalPage} currPage={receiverCurrPage} />
        )}
      </div>
    </div>
  );
};

export default AddressList;
