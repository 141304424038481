import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  ThemeProvider,
  Tooltip,
  Typography,
  createTheme,
} from "@mui/material";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import Currency from "../../../utils/CurrencySymbol";
import InfoOutlinedIcon from "@mui/icons-material/InfoOutlined";
import OverChargeMessages from "../../../utils/OverChargeMessages";
import LocalPrice from "../../../utils/LocalPrice";

const theme = createTheme({
  components: {
    MuiAccordion: {
      styleOverrides: {
        root: {
          margin: "0 !important",
          boxShadow: "var(--shadow)",
          ":before": {
            height: "0 !important",
          },
        },
      },
    },
    MuiAccordionSummary: {
      styleOverrides: {
        root: {
          borderRadius: "var(--inputBorderRadius)",
        },
      },
    },
  },
});

const DisplayOverCharges = (props) => {
  const { data, currency, item } = props;

  return (
    <ThemeProvider theme={theme}>
      {Object.entries(data).every((item) => item.status !== 0) && (
        <Accordion>
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls="panel1a-content"
            id="panel1a-header"
          >
            <Typography>
              {Object.keys(data).length > 1
                ? "Ekstra Fiyat Detayları"
                : "Ekstra Fiyat Detayı"}
            </Typography>
          </AccordionSummary>
          <AccordionDetails>
            <div
              style={{
                fontSize: "15px",
                color: "var(--text)",
                fontWeight: 500,
                marginBottom: "var(--gap)",
                display: "flex",
                alignItems: "center",
                justifyContent: "space-between",
              }}
            >
              <div>Navlun Fiyatı:</div>
              <div style={{ fontWeight: 700 }}>
                {Currency(item?.currency)}
                {LocalPrice(item?.navlun_selling_price)}
              </div>
            </div>
            <p
              style={{
                color: "var(--text)",
                fontWeight: 500,
                marginBottom: "3px",
              }}
            >
              {Object.entries(data).length > 1
                ? "Ekstra Maliyetler"
                : "Ekstra Maliyet"}
              ;
            </p>
            {Object.entries(data).map(([key, value], index) => (
              <div
                key={index}
                style={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "space-between",
                  gap: "5px",
                  // border: "1px solid var(--inputBorderColor)",
                  borderRadius: "var(--buttonBorderRadius)",
                  padding: "3px",
                  boxShadow: "var(--shadow)",
                }}
              >
                <Tooltip title={OverChargeMessages(key)}>
                  <InfoOutlinedIcon sx={{ color: "var(--btnBgBlue)" }} />
                </Tooltip>{" "}
                <span>{key}: </span>{" "}
                <div
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    gap: "5px",
                  }}
                >
                  {value.details?.map((detail, key) => (
                    <div
                      key={key}
                      style={{
                        display: "flex",
                        gap: "5px",
                        alignItems: "center",
                      }}
                    >
                      <div style={{ fontSize: "12px" }}>{detail.message}</div>
                      <div style={{ fontWeight: 700, color: "var(--text)" }}>
                        {Currency(currency)}
                        {LocalPrice(detail.price)}
                      </div>
                    </div>
                  ))}
                </div>
              </div>
            ))}
          </AccordionDetails>
        </Accordion>
      )}
    </ThemeProvider>
  );
};

export default DisplayOverCharges;
