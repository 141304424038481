import { useRecoilValue } from "recoil";
import { drawerOpenState } from "../../atoms/viewPadding";
import { styled } from "@mui/material/styles";

const DrawerHeader = styled("div")(({ theme }) => ({
  display: "flex",
  alignItems: "center",
  justifyContent: "flex-start",
  padding: theme.spacing(0, 1),
  ...theme.mixins.toolbar,
  position: "sticky",
  top: 0,
  zIndex: 1,
}));

const NavDrawerHeader = () => {
  const drawerOpen = useRecoilValue(drawerOpenState);

  return (
    <DrawerHeader className="navbar-drawer-header">
      <img
        className={drawerOpen ? "navbar-logo" : "navbar-logo-hidden"}
        src="/images/home/logo.svg"
        loading="lazy"
        alt="IyziShip Logo"
      />
    </DrawerHeader>
  );
};

export default NavDrawerHeader;
