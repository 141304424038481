import "../../style/profileSettings/Captcha.css";
import { Button, TextField } from "@mui/material";
import CachedOutlinedIcon from "@mui/icons-material/CachedOutlined";

const Captcha = ({
  securityCode,
  setSecurityCode,
  captchaUrl,
  handleSearchTaxNo,
  captchaRefresh,
  disabled,
}) => {
  return (
    <div className="captcha-wrapper">
      <form onSubmit={handleSearchTaxNo} className="captcha-field">
        <TextField
          size="small"
          label="Güvenlik Kodu"
          value={securityCode}
          onChange={(e) => setSecurityCode(e.target.value)}
          sx={{ minWidth: 200 }}
          fullWidth
        />
        <Button
          disabled={securityCode?.length !== 6}
          sx={{ ":disabled": { opacity: 0.3 } }}
          className="iyzi-button"
          type="submit"
        >
          Vergi Kimlik Numarası Doğrula
        </Button>
      </form>
      <div className="captcha">
        <img alt="Güvenlik Kodu" src={captchaUrl} style={{ width: "200px" }} />
        <Button
          className="iyzi-button"
          sx={{
            display: "flex",
            alignItems: "center",
            gap: "5px",
            justifyContent: "center",
            width: "200px",
          }}
          onClick={captchaRefresh}
        >
          <CachedOutlinedIcon /> Yenile
        </Button>
      </div>
    </div>
  );
};

export default Captcha;
