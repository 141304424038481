import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
} from "@mui/material";
import { useRecoilState } from "recoil";
import { basketInfoState } from "../../atoms/basket";

const BasketInfo = () => {
  const [basketInfo, setBasketInfo] = useRecoilState(basketInfoState);

  const handleClose = () => {
    setBasketInfo(false);
  };

  return (
    <Dialog open={basketInfo} onClose={handleClose}>
      <DialogTitle>Sepet Gönderi Sayısı Limit Aşımı</DialogTitle>
      <DialogContent>
        <DialogContentText>
          Tek seferde en fazla 5 gönderi ödemesi yapabilirsiniz!
        </DialogContentText>
        <DialogActions>
          <Button onClick={handleClose} className="iyzi-button-blue">
            Tamam
          </Button>
        </DialogActions>
      </DialogContent>
    </Dialog>
  );
};

export default BasketInfo;
