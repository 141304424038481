import "../../style/globals/PageHeaderTitle.css";
import HelpOutlineOutlinedIcon from "@mui/icons-material/HelpOutlineOutlined";
import WrapperStyle from "../../utils/WrapperStyle";
import { Button } from "@mui/material";
import { useState } from "react";
import PageHowToWork from "../../utils/PageHowToWork";

const SupportTitle = () => {
  const [open, setOpen] = useState(false);
  const handleOpenVideo = () => {
    setOpen(true);
  };
  return (
    <>
      <PageHowToWork
        open={open}
        setOpen={setOpen}
        iframe={
          <iframe
            style={{ width: "100%", height: "100%", borderRadius: "7px" }}
            src="https://www.youtube.com/embed/mTpej9No9H4"
            title="Bu Sayfa Nasıl Kullanılır l Destek Talebi"
            frameborder="0"
            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
            allowfullscreen
          ></iframe>
        }
      />
      <div className="page-header-title" style={WrapperStyle()}>
        <div className="page-header-title-wrapper">
          <div className="page-header-title-headline">
            <svg
              width="24"
              height="24"
              viewBox="0 0 24 24"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <mask
                id="mask0_6114_17680"
                style={{ maskType: "alpha" }}
                maskUnits="userSpaceOnUse"
                x="0"
                y="0"
                width="24"
                height="24"
              >
                <rect y="0.5" width="24" height="24" fill="#D9D9D9" />
              </mask>
              <g mask="url(#mask0_6114_17680)">
                <path
                  d="M12 7.5C12.2833 7.5 12.5208 7.40417 12.7125 7.2125C12.9042 7.02083 13 6.78333 13 6.5C13 6.21667 12.9042 5.97917 12.7125 5.7875C12.5208 5.59583 12.2833 5.5 12 5.5C11.7167 5.5 11.4792 5.59583 11.2875 5.7875C11.0958 5.97917 11 6.21667 11 6.5C11 6.78333 11.0958 7.02083 11.2875 7.2125C11.4792 7.40417 11.7167 7.5 12 7.5ZM11 15.5H13V9.5H11V15.5ZM2 22.5V4.5C2 3.95 2.19583 3.47917 2.5875 3.0875C2.97917 2.69583 3.45 2.5 4 2.5H20C20.55 2.5 21.0208 2.69583 21.4125 3.0875C21.8042 3.47917 22 3.95 22 4.5V16.5C22 17.05 21.8042 17.5208 21.4125 17.9125C21.0208 18.3042 20.55 18.5 20 18.5H6L2 22.5ZM5.15 16.5H20V4.5H4V17.625L5.15 16.5Z"
                  fill="currentColor"
                />
              </g>
            </svg>
            <h1>Destek Talebi</h1>
          </div>
          <Button
            sx={{ textTransform: "none" }}
            onClick={handleOpenVideo}
            className="page-header-title-how-link"
          >
            <HelpOutlineOutlinedIcon />
            <span>Bu Sayfa Nasıl Kullanılır?</span>
          </Button>
        </div>
      </div>
    </>
  );
};

export default SupportTitle;
