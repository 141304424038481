import "../style/myShipments/MyShipments.css";
import { Box } from "@mui/material";
import { useCallback, useEffect } from "react";
import { useCookies } from "react-cookie";
import { useRecoilValue, useSetRecoilState } from "recoil";
import axios from "../api/axios";
import NotificationBar from "../components/sidebar/NotificationBar";
import { DynamicTitle } from "../utils/DynamicTitle";
import MyShipmentSearch from "../components/myShipments/MyShipmentsSearch";
import MyShipmentsList from "../components/myShipments/MyShipmentsList";
import { ToastContainer } from "react-toastify";
import EditShipmentAddress from "../components/myShipments/modals/EditShipmentAddress";
import AddNote from "../components/myShipments/modals/AddNote";
import MyShipmentUpload from "../components/myShipments/modals/MyShipmentUpload";
import ShowFinancialDetails from "../components/myShipments/modals/ShowFinancialDetails";
import ConnectedPayments from "../components/myShipments/modals/ConnectedPayments";
import ShipmentDetail from "../components/myShipments/modals/ShipmentDetail";
import MyShipmentPayment from "../components/myShipments/modals/MyShipmentPayment";
import { isReturnShipmentState, shipmentStatusState } from "../atoms/myShipments";
import WrapperStyle from "../utils/WrapperStyle";
import ShipmentDimensionDetail from "../components/myShipments/modals/ShipmentDimensionDetail";
// import PageTransition from "../utils/PageTransition";
import ParcelReturnDemand from "../components/myShipments/modals/ParcelReturnDemand";
import ErrorModal from "../utils/ErrorModal";
import BarcodeDownload from "../components/myShipments/modals/BarcodeDownload";
import { errorModalState } from "../atoms/error";
import BarcodeSingleDownload from "../components/myShipments/modals/BarcodeSingleDownload";
import MyShipmentsTitle from "../components/myShipments/MyShipmentsTitle";
import MyShipmentDomestic from "../components/myShipments/modals/MyShipmentDomestic";
import InfoModal from "../components/myShipments/modals/InfoModal";

const MyShipments = () => {
  DynamicTitle("Gönderilerim | iyziShip");

  const [token] = useCookies(["jwt"]);
  const jwt = token.jwt;
  axios.defaults.headers.common["Authorization"] = `Bearer ${jwt}`;

  const errorModal = useRecoilValue(errorModalState);

  const setIsReturnShipment = useSetRecoilState(isReturnShipmentState);
  const setShipmentStatus = useSetRecoilState(shipmentStatusState);

  const setter = useCallback(() => {
    setIsReturnShipment(false);
  }, [setIsReturnShipment]);

  useEffect(() => {
    setter();
  }, [setter]);

  useEffect(() => {
    setShipmentStatus("");
  }, [setShipmentStatus]);

  return (
    <section className="myShipments">
      <NotificationBar />
      <ToastContainer />
      {/* <PageTransition> */}
        <MyShipmentsTitle />
        <Box className="myShipments-wrapper" style={WrapperStyle()}>
          <MyShipmentSearch />
          <MyShipmentsList />
        </Box>
        <>
          {/* Modals */}
          <MyShipmentDomestic />
          <EditShipmentAddress />
          <AddNote />
          <MyShipmentUpload />
          <ShowFinancialDetails />
          <ConnectedPayments />
          <ShipmentDetail />
          <MyShipmentPayment />
          <ShipmentDimensionDetail />
          <ParcelReturnDemand />
          <BarcodeDownload />
          <BarcodeSingleDownload />
          <InfoModal />
          {errorModal?.page === "myShipments" && <ErrorModal />}
        </>
      {/* </PageTransition> */}
    </section>
  );
};

export default MyShipments;
