import "../../../style/globals/PageHeaderTitle.css";
import HelpOutlineOutlinedIcon from "@mui/icons-material/HelpOutlineOutlined";
import WrapperStyle from "../../../utils/WrapperStyle";
import ReceiptLongOutlinedIcon from "@mui/icons-material/ReceiptLongOutlined";
import { totalBalanceState } from "../../../atoms/myWallet";
import { useRecoilValue } from "recoil";
import LocalPrice from "../../../utils/LocalPrice";

const IyziBalanceTitle = () => {
  const totalBalance = useRecoilValue(totalBalanceState);

  return (
    <div className="page-header-title" style={WrapperStyle()}>
      <div className="page-header-title-wrapper">
        <div className="page-header-title-headline">
          <ReceiptLongOutlinedIcon
            sx={{
              width: "24px!important",
              height: "24px!important",
            }}
          />
          <h1>iyziBakiyem</h1>
          <span className="page-header-title-span">{LocalPrice(totalBalance)} ₺</span>
        </div>
        <div className="page-header-title-how-link">
          <HelpOutlineOutlinedIcon />
          <span>Bu Sayfa Nasıl Kullanılır?</span>
        </div>
      </div>
    </div>
  );
};

export default IyziBalanceTitle;
