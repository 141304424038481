import "swiper/css";
import "swiper/css/pagination";
import "swiper/css/navigation";
import SwiperCore, { Navigation } from "swiper";
import { Button } from "@mui/material";
import Masonry, { ResponsiveMasonry } from "react-responsive-masonry";
import { useMemo, useState } from "react";

SwiperCore.use([Navigation]);

const Slider = () => {
  const [showAll, setShowAll] = useState(false); // Tüm box'ların görüntülenip görüntülenmediğini kontrol etmek için bir state

  const handleShowAll = () => {
    setShowAll(!showAll);
  };

  const boxes = useMemo(
    () => [
      {
        imageSrc: "/images/profilePhotos/hero-2.png",
        name: "Kraft Atölye",
        content:
          '"Yaklaşık 2 senedir sizinle kargo gönderimi yapıyoruz ve sizinle çalışmaya başladığımız için çok mutluyum. Biz zaten yoğun çalıştığımız için en azından kargo sorunlarında sizin desteğinizi almak benim işimi çok kolaylaştırıyor. Benim bile bazı unuttuğum gönderileri sürekli takip etmeniz ve beni sürekli bilgilendirmeniz sayesinde, kargo kısımlarını kafamdan atıyorum. Özellikle kolay ulaşılabiliyor olmanıza ve destek ekibinizin hızlılığına çok güveniyorum. Her şey için çok teşekkür ederim."',
      },

      {
        imageSrc: "/images/profilePhotos/hero-4.png",
        name: "Ahmet Ö.",
        content:
          '"Daha çalışmaya başlayalı birkaç ay oldu ama şimdiden fazlasıyla memnunum. Piyasanın en uygun ve en kaliteli yurt dışına kargo hizmeti sağlayan firması olarak başarılarınızın devamını dilerim."',
      },
      {
        imageSrc: "/images/profilePhotos/hero-3.png",
        name: "Niyazi K.",
        content:
          '  "Yaklaşık 1 yıldır iyziShip firması ile gönderi yapmaktayım. Daha önce farklı firmalar ile de çalışmalarım oldu, fakat iyziShip bence bu konuda birinci sırada. Bir çok konuda bu birinciliği hakettiklerini düşünüyorum. Örnek vermek gerekirse; müşteri ile olan iletişimleri, sorun çözümlemeleri, ilgi ve alakaları, çalışma saatleri dışında bile önemli konularda iletişime geçmeleri… Kısacası olması gerekenden fazla işleriyle ilgileniyor olmaları. Benim açımdan bu firma birçok övgüyü fazlasıyla hak ediyor. Çevremde bulunan kişilere de tavsiye ediyorum ve etmeye devam edeceğim." ',
      },
      {
        imageSrc: "/images/profilePhotos/hero-2.png",
        name: "Ahmet Gürkan A.",
        content:
          '"Yurt dışı gönderimlerinde çok sıkıntı çekiyorduk. iyziShip sayesinde artık kafamız çok rahatladı. Her ihtiyacımız olduğunda hızlı bir şekilde bize yardımcı olan ve sorunlarımızı çözen tüm ekibinize sevgiler, saygılar, hürmetler.    "  ',
      },
      {
        imageSrc: "/images/profilePhotos/hero-6.png",
        name: "Funda D.",
        content:
          ' "1 yıldan fazla süredir iyziShip ile çalışmaktayım. Başta müşteri hizmetleri olmak üzere tüm ekibe çok teşekkür ederim. İlgileri, alakaları, iş takibi mükemmeller. " ',
      },
      {
        imageSrc: "/images/profilePhotos/hero-7.png",
        name: "Şener A.",
        content:
          '"Yıllardır özellikle ABD pazarında büyümeye çalışıyor fakat doğru lojistik partnerini bulamadığımız için, hedeflerimizi tutturamıyorduk. Yaklaşık 1 yıl önce iyziShip ile tanıştık ve tüm e-ihracat sürecimiz daha ilk aylarda gözle görülür bir şekilde iyileşti. Müşteri hizmetlerinden saha ekiplerine kadar herkese çok teşekkür ederiz. İyi ki varsınız.”',
      },
      {
        imageSrc: "/images/profilePhotos/hero-4.png",
        name: "Ali G.",
        content:
          ' "Müşteri hizmetlerinde görüştüğüm her arkadaşın ilgi ve alakaları için çok teşekkür ederim. Bir sorunum olduğunda tek bir telefon kadar yakınımda olduklarını hissettiriyorlar.”',
      },
      {
        imageSrc: "/images/profilePhotos/hero-5.png",
        name: "Gizem Ç.",

        content:
          '"Her seferinde hızlı şekilde dönüş sağlamanız ve çözüm odaklı olmanızdan çok memnunum. Umarım uzun yıllar beraber çalışabiliriz. Tüm iyziShip çalışanlarına saygılarımı sunuyorum. ”',
      },
      {
        imageSrc: "/images/profilePhotos/hero-7.png",
        name: "Salih Y.",
        content:
          '"Ramazan ayında oruç nedeni ile paketlerimi yetiştiremiyordum. Kuryeniz bunu farkedince toplama saatinde bir iyileştirme yaptı. Sayesinde ETSY dükkanım kapanmaktan kurtuldu. Güzel insanlar çalıştırıyorsunuz maşallah 🧿"',
      },
      {
        imageSrc: "/images/profilePhotos/hero-5.png",
        name: "Ümit A.",
        content:
          '"Bugün de etsy siparişinde bir sorun olmuştu arkadaşlar hemen çözdü. Çok teşekkür ederim. E ticarete operasyon işlerimizi sizler sayesinde güvenli bir şekilde çözüyoruz."',
      },
      {
        imageSrc: "/images/profilePhotos/hero-5.png",
        name: "Aynur B.",
        content:
          '"Adı içinde ship ve navlun olan her firma ile çalıştım sayılır. iyziShip dahil hepsinde kg farkı çıkıyordu ama hiç kg’nın düştüğünü görmemiştim. Artık fazla kg çıktığında da içim sizinle rahat çünkü şeffaf ölçüm yaptığınıza inanıyorum."',
      },
      {
        imageSrc: "/images/profilePhotos/hero-5.png",
        name: "Nilay N.",
        content:
          '"Yanlış paketlediğim için kargo ücreti yüksek çıkıyordu. Beni aradınız ve daha farklı paketleyerek daha az ödeyeceğimi detaylıca anlattınız. Önce bunu daha çok kazanmak için yaptığınızı düşünmedim desem yalan olur :)) Haftada Etsy’den 1-2 adet satış yaparken, kargo fiyatlandırmamı güncelledikten sonra 1 ay içinde haftada 10 satışı geçtim. İyi ki varsınız ve bu işi çok iyi biliyorsunuz."',
      },
      {
        imageSrc: "/images/profilePhotos/hero-2.png",
        name: "Faruk A.",
        content:
          '"Altın-Gümüş kaplama ürünler satıyorum ve sizinle gönderiyorum.Alıcılar iade istediğinde çok zor durumda kalıyor ve zarar ediyordum. Ahmet bey yardımcı oldu sağolsun. İngiltere ve ABD’de artık sizin deponuza ürünlerim iade oluyor ve tekrar aynı ürünü satınca oradan daha uyguna göndermemi sağladığınız için başta Ahmet bey ve hepinize sonsuz teşekkürler."',
      },
      {
        imageSrc: "/images/profilePhotos/hero-4.png",
        name: "Mahmut D.",
        content:
          '"İadelerim Amerika da ki deponuza gittiği için artık zarar etmekten kurtuldum ve para kazanıyorum. Allah razı olsun bütün iyziShip ekibinden. Teşekkürler…"',
      },
      {
        imageSrc: "/images/profilePhotos/hero-5.png",
        name: "Pelin A.",
        content: '"Mükemmel destek ve hizmet."',
      },
      {
        imageSrc: "/images/profilePhotos/hero-5.png",
        name: "Can B.",
        content: '"Hizmetlerinizden oldukça memnun kaldık. Başarılarınızın devamını dilerim."',
      },
    ],
    []
  );

  return (
    <div className="main-slider">
      <div className="slider-headline">
        <h2>Bizi Müşterilerimizden Dinleyin</h2>
      </div>

      <ResponsiveMasonry columnsCountBreakPoints={{ 500: 1, 700: 2, 900: 2, 1000: 3, 1330: 4 }}>
        <Masonry className={showAll ? "my-masonry" : "my-masonry-closed"}>
          {boxes.map((box, index) => (
            <div
              key={index}
              className={
                index > 7 && showAll
                  ? "my-animation-in  slider-box "
                  : index > 7 && !showAll
                  ? "my-animation-out slider-box"
                  : "slider-box"
              }
            >
              <div className="bottom-field">
                <img src={box.imageSrc} alt="resim" />
                <div className="right-field">
                  <h3>{box.name}</h3>
                </div>
              </div>
              <div className="content"><p>{box.content}</p></div>
            </div>
          ))}
        </Masonry>
      </ResponsiveMasonry>

      <div className="mansory-bottom"></div>

      <div style={{ display: "flex", justifyContent: "center", marginTop: "25px" }}>
        <Button
          sx={{
            padding: " 9px 14px",
            minWidth: "200px",
            fontWeight: 700,
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            textDecoration: "none",
            height: " 49.927px",
            gap: "10px",
            fontSize: "14px",
            lineHeight: "18px",
            cursor: "pointer",
            zIndex: "9",
          }}
          onClick={handleShowAll}
          className="iyzi-button-blue"
        >
          {showAll ? "Daha Az Gör" : "Tümünü Gör"}
        </Button>
        {/*  <a
          style={{
            padding: " 9px 14px",
            minWidth: "200px",
            fontWeight: 700,
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            textDecoration: "none",
            height: " 49.927px",
            gap: "10px",
            fontSize: "14px",
            lineHeight: "18px",
            cursor: "pointer",
            zIndex:"9"
          }}
          href="/"
          className="iyzi-button-blue"
        >
          Tümünü Gör
        </a> */}
      </div>
    </div>
  );
};

export default Slider;
