import { Button, TableCell, TableRow } from "@mui/material";
import { useRecoilState } from "recoil";
import {
  arrangementCompanyBillModalOpenState,
  myWalletDocModalState,
  selectedArrangementItemState,
} from "../../../atoms/myWallet";
import { useCallback } from "react";
import MyWalletArrangementInfo from "./MyWalletArrangementInfo";
import axios from "../../../api/axios";
import InsertDriveFileOutlinedIcon from "@mui/icons-material/InsertDriveFileOutlined";
import ReceiptLongOutlinedIcon from "@mui/icons-material/ReceiptLongOutlined";
import LocalPrice from "../../../utils/LocalPrice";
import FormatDateHour from "../../../utils/FormatDateHour";
import Errors from "../../../utils/Errors";

const MyWalletArrangementTableBody = ({ item }) => {
  const user = JSON.parse(localStorage.getItem("user"));
  const memberType = user?.user?.user_member?.member_type;

  const [, setArrangementCompanyBillModalOpen] = useRecoilState(
    arrangementCompanyBillModalOpenState
  );
  const [, setSelectedArrangementItem] = useRecoilState(selectedArrangementItemState);
  const [, setMyWalletDocModal] = useRecoilState(myWalletDocModalState);

  const handleUploadBill = useCallback(() => {
    setSelectedArrangementItem(item);
    setArrangementCompanyBillModalOpen(true);
  }, [setArrangementCompanyBillModalOpen, setSelectedArrangementItem, item]);

  const handleUploadDoc = useCallback(() => {
    setSelectedArrangementItem(item);
    setMyWalletDocModal(true);
  }, [setMyWalletDocModal, setSelectedArrangementItem, item]);

  const amount = item?.member_payable_amount;
  const refNo = item?.parcel_ref_no;
  const invoiceFileIsExist =
    item?.member_invoice_file === null || item?.member_invoice_file === "" ? false : true;

  const getFile = (document) => {
    axios
      .get(`document/get-file/${document.replaceAll("/", "-")}`, {
        responseType: "arraybuffer",
      })
      .then((response) => {
        let blob = new Blob([response.data], {
          type: response.headers["content-type"],
        });
        let image = window.URL.createObjectURL(blob);

        window.open(image, "_blank");
      })
      .catch((error) => Errors(error));
  };

  return (
    <>
      <TableRow
        className={
          Number(item?.status) === 3
            ? ""
            : Number(item?.status) === 4 || Number(item?.status) === 4.1
            ? "borderless-tablerow"
            : invoiceFileIsExist === false
            ? ""
            : ""
        }
      >
        <TableCell>{FormatDateHour(item?.created_at)}</TableCell>
        <TableCell>{item?.member_invoice_no === null ? "---" : item?.member_invoice_no}</TableCell>
        <TableCell>{LocalPrice(item?.member_payable_amount)} ₺</TableCell>
        <TableCell>{item?.description}</TableCell>
        <TableCell>
          <div
            className={
              Number(item?.status) === 0 || Number(item?.status) === 1 || Number(item?.status) === 2
                ? "my-wallet-arrangement-table-status hold"
                : Number(item?.status) === 3
                ? "my-wallet-arrangement-table-status active"
                : "my-wallet-arrangement-table-status denied"
            }
          >
            {Number(item?.status) === 0
              ? "Destek Hattı"
              : Number(item?.status) === 1
              ? "Müşteriden Cevap Bekleniyor"
              : Number(item?.status) === 2
              ? "Beklemede"
              : Number(item?.status) === 3
              ? "Onaylandı"
              : Number(item?.status) === 4
              ? "Reddedildi"
              : "Hatalı Dosya"}
          </div>
        </TableCell>
        <TableCell sx={{ width: "160px", padding: "16px 8px" }}>
          {Number(item?.status) === 3 || Number(item?.status) === 4 ? (
            ""
          ) : (
            <Button
              onClick={
                item?.member_consent_file === null || item?.member_consent_file === ""
                  ? handleUploadDoc
                  : () => getFile(item?.member_consent_file)
              }
              className="iyzi-button"
            >
              {item?.member_consent_file === null || item?.member_consent_file === "" ? (
                "Muvafakatname Yükle"
              ) : (
                <InsertDriveFileOutlinedIcon />
              )}
            </Button>
          )}
        </TableCell>
        {memberType === "Company" && (
          <TableCell sx={{ width: "140px" }}>
            {Number(item?.status) === 3 || Number(item?.status) === 4 ? (
              ""
            ) : (
              <Button
                onClick={
                  item?.member_invoice_file === null || item?.member_invoice_file === ""
                    ? handleUploadBill
                    : () => getFile(item?.member_invoice_file)
                }
                className="iyzi-button"
              >
                {item?.member_invoice_file === null || item?.member_invoice_file === "" ? (
                  "Fatura Yükle"
                ) : (
                  <ReceiptLongOutlinedIcon />
                )}
              </Button>
            )}
          </TableCell>
        )}
      </TableRow>
      {Number(item?.status) === 3 || Number(item?.status) === 4
        ? ""
        : invoiceFileIsExist && (
            <TableRow
              className={
                Number(item?.status) === 4 || Number(item?.status) === 4.1
                  ? "borderless-tablerow-reject borderless2"
                  : "borderless-tablerow-reject"
              }
            >
              <TableCell colSpan={memberType === "Company" ? 7 : 6}>
                <MyWalletArrangementInfo amount={amount} refNo={refNo} />
              </TableCell>
            </TableRow>
          )}
      {(Number(item?.status) === 4 || Number(item?.status) === 4.1) && (
        <TableRow className="borderless-tablerow-reject">
          <TableCell colSpan={memberType === "Company" ? 7 : 6}>
            <strong style={{ fontSize: "inherit", color: "var(--shipmentRed)" }}>
              İptal Nedeni:
            </strong>{" "}
            {item?.response_description}
          </TableCell>
        </TableRow>
      )}
    </>
  );
};

export default MyWalletArrangementTableBody;
