import "../style/support/Support.css";
import { Box } from "@mui/material";
import { useInView, animated } from "@react-spring/web";
import { useCallback, useEffect, useState } from "react";
import { useCookies } from "react-cookie";
import { ToastContainer } from "react-toastify";
import { useRecoilState, useRecoilValue } from "recoil";
import axios from "../api/axios";
import NotificationBar from "../components/sidebar/NotificationBar";
import { DynamicTitle } from "../utils/DynamicTitle";
import NoSupport from "../components/support/NoSupport";
import SupportRequest from "../components/support/SupportRequest";
import {
  openSupportDetailState,
  openSupportListState,
  openSupportRequestState,
  supportListState,
  supportTotalPageState,
} from "../atoms/support";
import SupportList from "../components/support/SupportList";
import miniLogo from "../img/logos/logo_white_mini_loading.svg";
import RateComment from "../components/support/RateComment";
import SupportDetail from "../components/support/SupportDetail";
import WrapperStyle from "../utils/WrapperStyle";
import { useLocation } from "react-router-dom";
// import PageTransition from "../utils/PageTransition";
import Errors from "../utils/Errors";
import SupportTitle from "../components/support/SupportTitle";

const Support = () => {
  DynamicTitle("Destek | iyziShip");

  const [token] = useCookies(["jwt"]);
  const jwt = token.jwt;
  axios.defaults.headers.common["Authorization"] = `Bearer ${jwt}`;

  const openSupportDetail = useRecoilValue(openSupportDetailState);

  const [openSupportRequest, setOpenSupportRequest] = useRecoilState(openSupportRequestState);
  const [, setSupportList] = useRecoilState(supportListState);
  const [openSupportList, setOpenSupportList] = useRecoilState(openSupportListState);
  const [, setSupportTotalPage] = useRecoilState(supportTotalPageState);

  const [loading, setLoading] = useState(false);

  const [ref, springs] = useInView(
    () => ({
      from: {
        opacity: 0,
        y: 20,
      },
      to: {
        opacity: 1,
        y: 0,
      },
    }),
    {
      rootMargin: "-10% 0%",
    }
  );

  const getSupportData = useCallback(async () => {
    setLoading(true);
    await axios
      .get(`ticket?order_direction=DESC&limit=10&page=1&order_by=id`)
      .then((response) => {
        setLoading(false);
        setSupportList(response.data.data);
        setSupportTotalPage(response.data.totalPages);
        if (response.data.data.length > 0) {
          setOpenSupportList(true);
        } else {
          setOpenSupportList(false);
        }
      })
      .catch((error) => {
        setLoading(false);
        setOpenSupportList(false);
        Errors(error);
      });
  }, [setOpenSupportList, setSupportList, setSupportTotalPage]);

  useEffect(() => {
    setOpenSupportRequest(false);
    // getSupportData();
  }, [setOpenSupportRequest, getSupportData]);

  // Parametreleri al
  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);
  const awbParam = searchParams.get("awb");

  useEffect(() => {
    if (awbParam !== null) {
      setOpenSupportRequest(true);
    } else {
      getSupportData();
    }
  }, [awbParam, getSupportData, setOpenSupportRequest]);

  return (
    <section className="support">
      <NotificationBar />
      {/* <PageTransition> */}
        <ToastContainer />
        <RateComment />
        <SupportTitle />
        <Box className="support-wrapper" style={WrapperStyle()}>
          {loading ? (
            <Box
              sx={{
                height: "500px",
                width: "100%",
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
              }}
            >
              <img className="loading-logo" src={miniLogo} alt="" />
            </Box>
          ) : (
            <animated.div ref={ref} style={springs}>
              <Box className="support-container">
                {!openSupportRequest && !openSupportList && !openSupportDetail && <NoSupport />}
                {openSupportRequest && <SupportRequest />}
                {openSupportList && !openSupportRequest && <SupportList />}
                {!openSupportList && !openSupportRequest && openSupportDetail && <SupportDetail />}
              </Box>
            </animated.div>
          )}
        </Box>
      {/* </PageTransition> */}
    </section>
  );
};

export default Support;
