import { Box, Modal } from "@mui/material";
import { useRecoilValue } from "recoil";
import { ticketDimensionsState, ticketState } from "../../../atoms/support";
import { useState } from "react";

const SupportDetailStepperInfo = () => {
  const ticket = useRecoilValue(ticketState);
  const ticketDimensions = useRecoilValue(ticketDimensionsState);

  const [open, setOpen] = useState(false);

  const handleOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  return (
    <>
      <Modal
        className="shipment-dimension-detail-modal"
        open={open}
        sx={{ height: "fit-content" }}
        onClose={handleClose}
      >
        <div
          className="shipment-dimension-detail-wrapper"
          style={{
            maxHeight: "90vh",
            overflowY: "auto",
          }}
        >
          <div className="shipment-dimension-detail">
            <div className="dimension-detail-per-side-wrapper">
              <div className="per-side-headline customer">Ölçüleriniz</div>
              {ticketDimensions
                ?.filter((e) => Number(e.status) === 1)
                .map((dim, key) => (
                  <div key={key} className="dimension-detail-per-side">
                    <div className="detail-per-side">
                      <div className="per-side-subline">Kap Başına (Kap Sayısı: {dim?.qty});</div>
                      <div className="per-side-content">Ücret Ağırlığı: {dim?.cw}</div>
                      <div className="per-side-content">Brüt Ağırlık: {dim?.gw}</div>
                      <div className="per-side-content">Hacim / Desi: {dim?.vw}</div>
                      <div className="per-side-content">Ağırlık: {dim?.gross} kg</div>
                      <div className="per-side-content">En: {dim?.length} cm</div>
                      <div className="per-side-content">Boy: {dim?.width} cm</div>
                      <div className="per-side-content">Yükselik: {dim?.height} cm</div>
                    </div>
                    <div></div>
                  </div>
                ))}
            </div>
            <div className="dimension-detail-per-side-wrapper">
              <div className="per-side-headline iyzi">iyziTeam Ölçüleri</div>
              {ticketDimensions
                ?.filter((e) => Number(e.status) === 2)
                ?.map((dim, key) => (
                  <div key={key} className="dimension-detail-per-side">
                    <div className="detail-per-side">
                      <div className="per-side-subline">Kap Başına (Kap Sayısı: {dim?.qty});</div>
                      <div className="per-side-content">Ücret Ağırlığı: {dim?.cw}</div>
                      <div className="per-side-content">Brüt Ağırlık: {dim?.gw}</div>
                      <div className="per-side-content">Hacim / Desi: {dim?.vw}</div>
                      <div className="per-side-content">Ağırlık: {dim?.gross} kg</div>
                      <div className="per-side-content">En: {dim?.length} cm</div>
                      <div className="per-side-content">Boy: {dim?.width} cm</div>
                      <div className="per-side-content">Yükselik: {dim?.height} cm</div>
                    </div>
                  </div>
                ))}
              {ticketDimensions?.filter((e) => Number(e.status) === 2)?.length === 0 &&
                "Ekibimiz ölçü girişini gerçekleştirdiğinde detayları burada görebileceksiniz."}
            </div>
          </div>
        </div>
      </Modal>
      {ticket.get_parcel?.doc_type === "D" && (
        <Box
          className="steps-info"
          sx={{
            padding: "5px",
            borderRadius: "var(--wrapperBorderRadius)",
            transition: "all 150ms linear",
            ":hover": {
              boxShadow: "var(--shadow)",
              cursor: "pointer",
              transition: "all 150ms linear",
            },
          }}
        >
          <Box className="steps-info-wrapper info-member">
            <div className="info-top">Üye Tarafından Beyan Edilen Kilo</div>
            <div className="info-bottom">
              <div className="info-bottom-subline">
                NET KG
                <div className="info-bottom-subline-number">{ticket.get_parcel?.gw}</div>
              </div>
            </div>
          </Box>
          <Box className="steps-info-wrapper info-system">
            <div className="info-top">iyziTeam Tarafından Beyan Edilen Kilo</div>
            <div className="info-bottom">
              <div className="info-bottom-subline">
                NET KG
                <div className="info-bottom-subline-number">0</div>
              </div>
            </div>
          </Box>
        </Box>
      )}
      {ticket.get_parcel?.doc_type === "N" && (
        <Box
          className="steps-info"
          sx={{
            padding: "5px",
            borderRadius: "var(--wrapperBorderRadius)",
            transition: "all 150ms linear",
            ":hover": {
              boxShadow: "var(--shadow)",
              cursor: "pointer",
              transition: "all 150ms linear",
            },
          }}
          onClick={handleOpen}
        >
          <Box className="steps-info-wrapper info-member">
            <div className="info-top">Üye Tarafından Beyan Edilen Kilo</div>
            <div className="info-bottom">
              <div className="info-bottom-subline">
                NET KG
                <div className="info-bottom-subline-number">
                  {ticket.get_parcel?.get_parcel_dimensions
                    .filter((e) => e.status === "1")
                    .reduce((acc, val) => Number(acc) + Number(val.total_gw), 0)
                    .toFixed(2)}
                </div>
              </div>
              <div className="info-bottom-subline">
                DESİ
                <div className="info-bottom-subline-number">
                  {ticket.get_parcel?.get_parcel_dimensions
                    .filter((e) => e.status === "1")
                    .reduce((acc, val) => Number(acc) + Number(val.total_vw), 0)
                    .toFixed(2)}
                </div>
              </div>
              <div className="info-bottom-subline">
                NAVLUN KG
                <div className="info-bottom-subline-number">
                  {ticket.get_parcel?.get_parcel_dimensions
                    .filter((e) => e.status === "1")
                    .reduce((acc, val) => Number(acc) + Number(val.total_cw), 0)
                    .toFixed(2)}
                </div>
              </div>
            </div>
          </Box>
          <Box className="steps-info-wrapper info-system">
            <div className="info-top">iyziTeam Tarafından Beyan Edilen Kilo</div>
            <div className="info-bottom">
              <div className="info-bottom-subline">
                NET KG
                <div className="info-bottom-subline-number">
                  {ticket.get_parcel?.get_parcel_dimensions
                    .filter((e) => e.status === "2")
                    .reduce((acc, val) => Number(acc) + Number(val.total_gw), 0)
                    .toFixed(2)}
                </div>
              </div>
              <div className="info-bottom-subline">
                DESİ
                <div className="info-bottom-subline-number">
                  {ticket.get_parcel?.get_parcel_dimensions
                    .filter((e) => e.status === "2")
                    .reduce((acc, val) => Number(acc) + Number(val.total_vw), 0)
                    .toFixed(2)}
                </div>
              </div>
              <div className="info-bottom-subline">
                NAVLUN KG
                <div className="info-bottom-subline-number">
                  {ticket.get_parcel?.get_parcel_dimensions
                    .filter((e) => e.status === "2")
                    .reduce((acc, val) => Number(acc) + Number(val.total_cw), 0)
                    .toFixed(2)}
                </div>
              </div>
            </div>
          </Box>
        </Box>
      )}
    </>
  );
};

export default SupportDetailStepperInfo;
