import React from "react";
import {
  Box,
  FormControl,
  FormControlLabel,
  Modal,
  Button,
  FormLabel,
  RadioGroup,
  Radio,
} from "@mui/material";
import { useRecoilState } from "recoil";
import {
  confirmationModalOpenState,
  selectedMyShipmentListItemState,
} from "../../../atoms/myShipments";
import { toast } from "react-toastify";
import axios from "../../../api/axios";
import DownloadBase64Pdf from "../../../utils/DownloadBase64Pdf";
import Errors from "../../../utils/Errors";
import { useCallback, useState } from "react";

const BarcodeDownload = () => {
  // const selectedShipmentForMyShipments = useRecoilValue(selectedShipmentForMyShipmentsState);
  const [selectedMyShipmentListItem, setSelectedMyShipmentListItem] = useRecoilState(
    selectedMyShipmentListItemState
  );
  const [confirmationModalOpen, setConfirmationModalOpen] = useRecoilState(
    confirmationModalOpenState
  );

  const [pageFormat, setPageFormat] = useState("A4");
  const handleBarcodeDownload = () => {
    axios
      .post("parcel/get-barcode-multiple", {
        ids: selectedMyShipmentListItem.map((e) => e.id),
        page_format: pageFormat,
      })
      .then((response) => {
        if (response.status === 200) {
          const { base64 } = response.data;
          DownloadBase64Pdf(base64, "barcode");
          toast.success(response.data.message);
          setSelectedMyShipmentListItem([]);
        }
      })
      .catch((error) => Errors(error));
  };
  const handleConfirmationModalSubmit = () => {
    setConfirmationModalOpen(false);
    handleBarcodeDownload();
  };

  const handleCloseBarcode = useCallback(() => {
    setConfirmationModalOpen(false);
  }, [setConfirmationModalOpen]);

  const handleConfirmationModalClose = () => {
    setConfirmationModalOpen(false);
  };
  return (
    <Modal
      open={confirmationModalOpen}
      onClose={handleConfirmationModalClose}
      aria-labelledby="confirmation-modal-title"
      aria-describedby="confirmation-modal-description"
      sx={{
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
      }}
    >
      <Box
        sx={{
          backgroundColor: "white",
          border: "1px solid #ccc",
          boxShadow: 24,
          p: 4,
          maxWidth: 400,
          textAlign: "center",
        }}
        style={{
          maxHeight: "90vh",
          overflowY: "auto",
        }}
      >
        <FormControl component="fieldset" sx={{ mt: 4 }}>
          <FormLabel component="legend">Hangi formatta istediğinizi seçin:</FormLabel>
          <RadioGroup
            aria-label="page-format"
            name="page-format"
            value={pageFormat}
            onChange={(e) => setPageFormat(e.target.value)}
            sx={{ flexDirection: "row" }}
          >
            <FormControlLabel value="A4" control={<Radio />} label="A4" sx={{ ml: 2 }} />
            <FormControlLabel value="TW-2037" control={<Radio />} label="TW-2037" />
          </RadioGroup>
        </FormControl>
        <Box sx={{ mt: 4 }}>
          <Button className="iyzi-button" onClick={handleConfirmationModalSubmit} sx={{ mx: 1 }}>
            Barkod İndir
          </Button>
          <Button className="iyzi-button-red" onClick={handleCloseBarcode} sx={{ mx: 1 }}>
            Kapat
          </Button>
        </Box>
      </Box>
    </Modal>
  );
};

export default BarcodeDownload;
