import { useRecoilState } from "recoil";
import SpringCardIn from "../registeredCards/SpringCardIn";
import { Button, CircularProgress, createTheme, TextField, ThemeProvider } from "@mui/material";
import {
  dimensionListArrayState,
  startDimensionAnimationState,
} from "../../../atoms/profileSettings";
import { useCallback, useEffect, useState } from "react";
import axios from "../../../api/axios";
import { useCookies } from "react-cookie";
import { toast } from "react-toastify";
import { registeredDimensionsState } from "../../../atoms/createShipment";
import Errors from "../../../utils/Errors";
import {
  FormatFieldWithTwoDecimals,
  FormatFieldWithTwoDecimalsWithMaxNumber,
} from "../../../utils/FormatField";

const theme = createTheme({
  components: {
    MuiSelect: {
      styleOverrides: {
        outlined: {
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
        },
      },
    },
    MuiOutlinedInput: {
      styleOverrides: {
        root: {
          borderRadius: "10px",
        },
      },
    },
    MuiPaper: {
      styleOverrides: {
        root: {
          maxHeight: "300px !important",
        },
      },
    },
    MuiInputBase: {
      styleOverrides: {
        input: {
          display: "flex",
          alignItems: "center",
          gap: "var(--gap)",
        },
      },
    },
  },
});

const CreateDimension = () => {
  const [startDimensionAnimation, setstartDimensionAnimation] = useRecoilState(
    startDimensionAnimationState
  );
  const [dimensionListArray, setDimensionListArray] = useRecoilState(dimensionListArrayState);
  const [, setRegisteredDimensions] = useRecoilState(registeredDimensionsState);

  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(false);

  const [en, setEn] = useState("");
  const [boy, setBoy] = useState("");
  const [yuk, setYuk] = useState("");
  const [kg, setKg] = useState("");
  const [name, setName] = useState("");
  const [supId, setSupId] = useState(null);

  const qty = 1;

  const [token] = useCookies(["jwt"]);
  const jwt = token?.jwt;
  axios.defaults.headers.common["Authorization"] = `Bearer ${jwt}`;

  const handleClose = useCallback(() => {
    setstartDimensionAnimation(false);
    setEn("");
    setBoy("");
    setYuk("");
    setKg("");
    setName("");
    setSupId("");
  }, [setstartDimensionAnimation]);

  const addDimensionHandler = async (e) => {
    if (e) e.preventDefault();
    setLoading(true);

    const data =
      supId === null || supId === "" || supId === undefined
        ? {
            length: en,
            width: boy,
            height: yuk,
            gross: kg,
            qty,
            name,
          }
        : {
            length: en,
            width: boy,
            height: yuk,
            gross: kg,
            qty,
            name,
            supplier_id: Number(supId),
          };

    await axios
      .post("member-dimension/add", data)
      .then((response) => {
        toast.success(response.data.message, { autoClose: 2000 });
        setDimensionListArray([response.data.data, ...dimensionListArray]);
        setRegisteredDimensions([response.data.data, ...dimensionListArray]);
        handleClose();
      })
      .catch((error) => Errors(error))
      .finally(() => setLoading(false));
  };

  const handleDecimalChange = (e) => {
    const { name } = e.target;

    if (name === "en") {
      FormatFieldWithTwoDecimals(e, setEn);
    } else if (name === "boy") {
      FormatFieldWithTwoDecimals(e, setBoy);
    } else if (name === "yuk") {
      FormatFieldWithTwoDecimals(e, setYuk);
    } else if (name === "gross") {
      FormatFieldWithTwoDecimalsWithMaxNumber(e, setKg, 999);
    }
  };

  const handleBlur = (e) => {
    if (e.target.name === "gross" && e.target.value === "0") {
      setKg("");
    }
  };

  const isDisabled = useCallback(() => {
    if (
      en === "" ||
      boy === "" ||
      yuk === "" ||
      kg === "" ||
      qty === "" ||
      name === ""
      // ||
      // supplier === ""
    ) {
      return true;
    } else return false;
  }, [en, boy, yuk, kg, qty, name]);

  useEffect(() => {
    if (startDimensionAnimation) {
      if (en !== "" && boy !== "" && yuk !== "" && Number(en) * Number(boy) * Number(yuk) < 6) {
        setError(true);
      } else {
        setError(false);
      }
    }
  }, [en, boy, yuk, startDimensionAnimation]);

  return (
    <>
      {startDimensionAnimation && (
        <SpringCardIn>
          <ThemeProvider theme={theme}>
            <form onSubmit={addDimensionHandler} className="default-dimension">
              <div
                style={{
                  width: "100%",
                  display: "flex",
                  gap: "var(--gap)",
                  marginBottom: "var(--gap2x)",
                }}
                className="shipment-dimension-modal-form-headline"
              >
                <TextField
                  size="small"
                  label="Başlık"
                  required
                  fullWidth
                  value={name}
                  onChange={(e) => setName(e.target.value)}
                  autoComplete="off"
                />
              </div>
              <div className="dimension-info-wrapper">
                <TextField
                  label="En (cm)"
                  size="small"
                  name="en"
                  value={en}
                  error={error}
                  helperText={error && "En, Boy ve Yükseklik çarpımı en az 6 cm olmak zorundadır."}
                  autoComplete="off"
                  onChange={handleDecimalChange}
                />
                <TextField
                  label="Boy (cm)"
                  size="small"
                  name="boy"
                  value={boy}
                  error={error}
                  helperText={error && "En, Boy ve Yükseklik çarpımı en az 6 cm olmak zorundadır."}
                  autoComplete="off"
                  onChange={handleDecimalChange}
                />
                <TextField
                  label="Yükseklik (cm)"
                  size="small"
                  name="yuk"
                  value={yuk}
                  error={error}
                  helperText={error && "En, Boy ve Yükseklik çarpımı en az 6 cm olmak zorundadır."}
                  autoComplete="off"
                  onChange={handleDecimalChange}
                />
                <TextField
                  label="Kg"
                  size="small"
                  name="gross"
                  value={kg}
                  autoComplete="off"
                  onChange={handleDecimalChange}
                  onBlur={handleBlur}
                />
              </div>
              <div
                className="shipment-dimension-modal-form-buttons"
                style={{
                  gap: "var(--gap2x)",
                  display: "flex",
                  justifyContent: "flex-end",
                  width: "100%",
                  marginTop: "var(--gap2x)",
                }}
              >
                <Button onClick={handleClose} type="button" className="iyzi-button">
                  Vazgeç
                </Button>
                {loading ? (
                  <div
                    style={{
                      width: "64px",
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                    }}
                  >
                    <CircularProgress
                      sx={{
                        width: "21px !important",
                        height: "21px !important",
                      }}
                    />
                  </div>
                ) : (
                  <Button
                    disabled={isDisabled()}
                    className="iyzi-button"
                    type="submit"
                    sx={{
                      ":disabled": {
                        opacity: 0.4,
                      },
                    }}
                  >
                    Kaydet
                  </Button>
                )}
              </div>
            </form>
          </ThemeProvider>
        </SpringCardIn>
      )}
    </>
  );
};

export default CreateDimension;
