import { atom } from "recoil";

export const shipmentStatusState = atom({
  key: "shipmentStatus",
  default: "",
});

export const myShipmentListLoadingState = atom({
  key: "myShipmentListLoading",
  default: false,
});

export const openInfoModalState = atom({
  key: "openInfoModal",
  default: false,
});

export const myShipmentListState = atom({
  key: "myShipmentList",
  default: [],
});

export const myShipmentsListLimitState = atom({
  key: "myShipmentsListLimit",
  default: 50,
});

export const myShipmentsListBasicLimitState = atom({
  key: "myShipmentsListBasicLimit",
  default: 1000,
});
export const shipmentListCurrentPageState = atom({
  key: "shipmentCurrentListPage",
  default: 1,
});

export const shipmentListOrderDirectionState = atom({
  key: "shipmentOrderDirectionPage",
  default: "DESC",
});

export const basicSearchState = atom({
  key: "basicSearch",
  default: "",
});

export const editReceiverAddressOpenState = atom({
  key: "editReceiverAddressOpen",
  default: false,
});

export const editReceiverAddressIntegratedOpenState = atom({
  key: "editReceiverAddressIntegratedOpen",
  default: false,
});

export const editProformaUpdateOpenState = atom({
  key: "editProformaUpdateOpenState",
  default: false,
});

export const editSenderAddressOpenState = atom({
  key: "editSenderAddressOpen",
  default: false,
});
export const weightCheckOpenState = atom({
  key: "weightCheckOpen",
  default: false,
});

export const receiverAddressDialogOpenState = atom({
  key: "receiverAddressDialogOpen",
  default: { status: false, data: {} },
});

export const selectedDocumentsState = atom({
  key: "selectedDocuments",
  default: [],
});

export const selectedShipmentForMyShipmentsState = atom({
  key: "selectedShipmentForMyShipments",
  default: [],
});

export const addNoteOpenState = atom({
  key: "addNoteOpen",
  default: false,
});

export const myShipmentsNoteState = atom({
  key: "myShipmentsNote",
  default: "",
});

export const parcelReturnDemandOpenState = atom({
  key: "parcelReturnDemandOpen",
  default: false,
});

export const myShipmentDomesticState = atom({
  key: "myShipmentDomestic",
  default: { status: false, data: {} },
});

export const returnReasonState = atom({
  key: "returnReason",
  default: "",
});

export const uploadShipmentDocumentOpenState = atom({
  key: "uploadShipmentDocumentOpen",
  default: false,
});

export const showFinancialDetailsOpenState = atom({
  key: "showFinancialDetailsOpen",
  default: false,
});

export const connectedPaymentsOpenState = atom({
  key: "connectedPaymentsOpen",
  default: false,
});

export const pastAwbModalOpenState = atom({
  key: "pastAwbModalOpen",
  default: false,
});

export const confirmationModalOpenState = atom({
  key: "confirmationModalOpen",
  default: false,
});

export const pastAwbModalState = atom({
  key: "pastAwbModal",
  default: false,
});

export const shipmentsListArrayLengthState = atom({
  key: "shipmentsListArrayLength",
  default: [],
});

export const checkedShipmentsState = atom({
  key: "checkedShipments",
  default: [],
});

export const selectedMyShipmentListItemState = atom({
  key: "selectedMyShipmentListItem",
  default: [],
});

export const barcodeSingleState = atom({
  key: "barcodeSingle",
  default: { status: false, item: {} },
});

export const shipmentDetailModalState = atom({
  key: "shipmentDetailModal",
  default: { status: false, data: [], shipment: [] },
});

export const listStyleState = atom({
  key: "listStyle",
  default: false,
});

export const shipmentDetailOpenState = atom({
  key: "shipmentDetailOpen",
  default: false,
});

export const shipmentPaymentOpenState = atom({
  key: "shipmentPaymentOpen",
  default: false,
});

export const proformaUpdateOpenState = atom({
  key: "proformaUpdateOpen",
  default: false,
});

export const exportSelectedDesktopState = atom({
  key: "exportSelectedDesktop",
  default: "",
});

export const checkedAllState = atom({
  key: "checkedAll",
  default: false,
});

export const timeState = atom({
  key: "time",
  default: "",
});

export const senderCountryState = atom({
  key: "senderCountry",
  default: "",
});

export const senderCountryCodeState = atom({
  key: "senderCountryCode",
  default: "",
});

export const receiverCountryState = atom({
  key: "receiverCountry",
  default: "",
});

export const receiverCountryCodeState = atom({
  key: "receiverCountryCode",
  default: "",
});

export const selectedSupplierState = atom({
  key: "selectedSupplier",
  default: "",
});

export const selectedPriceStatusState = atom({
  key: "selectedPriceStatus",
  default: "",
});

export const selectedExportStatusState = atom({
  key: "selectedExportStatus",
  default: "",
});

export const selectedDomesticStatusState = atom({
  key: "selectedDomesticStatus",
  default: "",
});

export const selectedCaseStatusState = atom({
  key: "selectedCaseStatus",
  default: "",
});

export const awbState = atom({
  key: "awb",
  default: "",
});

export const refIdState = atom({
  key: "refId",
  default: "",
});

export const senderState = atom({
  key: "sender",
  default: "",
});

export const receiverState = atom({
  key: "receiver",
  default: "",
});

export const detailedStatusState = atom({
  key: "detailedStatus",
  default: 0,
});

export const totalPagesState = atom({
  key: "totalPages",
  default: 0,
});

export const basicSearchErrorState = atom({
  key: "basicSearchError",
  default: false,
});

export const errorSetState = atom({
  key: "errorSet",
  default: true,
});

export const isReturnShipmentState = atom({
  key: "isReturnShipment",
  default: false,
});

export const selectedPaymentShipmentState = atom({
  key: "selectedPaymentShipment",
  default: {},
});

export const shipmentDimensionDetailState = atom({
  key: "shipmentDimensionDetail",
  default: { status: false, data: {} },
});

export const dimensionsModalOpenState = atom({
  key: "dimensionsModalOpen",
  default: false,
});

export const adminDimensionsModalOpenState = atom({
  key: "adminDimensionsModalOpen",
  default: false,
});

export const adminFilesModalOpenState = atom({
  key: "adminFilesModalOpen",
  default: { status: false, data: [], id: null },
});

export const supplierChangeOpenState = atom({
  key: "supplierChangeOpen",
  default: false,
});

export const supplierConsigmentDownloadState = atom({
  key: "supplierConsigmentDownload",
  default: { status: false, data: [] },
});

export const filesModalOpenState = atom({
  key: "filesModalOpenState",
  default: { status: false, data: [] },
});

export const returnModalOpenState = atom({
  key: "returnModalOpenState",
  default: false,
});

export const supplierChangeModalOpenState = atom({
  key: "supplierChangeModalOpenState",
  default: false,
});

export const updateHAWBModalOpenState = atom({
  key: "updateHAWBModalOpenState",
  default: false,
});

export const statusChangeModalOpenState = atom({
  key: "statusChangeModalOpen",
  default: false,
});

export const courierStatusState = atom({
  key: "courierStatus",
  default: false,
});

export const fieldStatusState = atom({
  key: "fieldStatus",
  default: "status",
});

export const arrayStatusState = atom({
  key: "arrayStatus",
  default: [0.0, 0.01, 0.02, 0.03, 0.04, 2.21],
});

export const domesticModalState = atom({
  key: "domesticModal",
  default: false,
});

export const adminParcelMemberIdState = atom({
  key: "adminParcelMemberId",
  default: null,
});

export const erasedShipmentState = atom({
  key: "erasedShipment",
  default: 0,
});
