import { Box } from "@mui/material";
import { useRecoilValue } from "recoil";
import {
  courierListState,
  isCourierRegistrationExistState,
} from "../../atoms/courierRegistration";
import CourierListDeliveredItem from "./CourierListDeliveredItem";
import CourierListItem from "./CourierListItem";

const CourierList = () => {
  const isCourierRegistrationExist = useRecoilValue(
    isCourierRegistrationExistState
  );
  const courierList = useRecoilValue(courierListState);
  return (
    <Box className="courier-list">
      {courierList.length === 0 && (
        <Box className="myShipments-list-none">Kurye Kaydı bulunamadı.</Box>
      )}
      {isCourierRegistrationExist &&
        courierList
          .filter(
            (item) =>
              Number(item.status) === 0 ||
              Number(item.status) === 1 ||
              Number(item.status) === 2 ||
              Number(item.status) === 5
          )
          .map((item, key) => <CourierListItem key={key} item={item} />)}
      {isCourierRegistrationExist &&
        courierList
          .filter(
            (item) => Number(item.status) === 3 || Number(item.status) === 4
          )
          .map((item, key) => (
            <CourierListDeliveredItem key={key} item={item} />
          ))}
    </Box>
  );
};

export default CourierList;
