import { TableCell, TableRow } from "@mui/material";
import LocalPrice from "../../../utils/LocalPrice";
import FormatDateHour from "../../../utils/FormatDateHour";

const MyRefundTableBody = ({ item }) => {
  return (
    <>
      <TableRow className={Number(item?.status) === 2 ? "borderless-tablerow" : ""}>
        <TableCell>{FormatDateHour(item?.created_at)}</TableCell>
        <TableCell>{LocalPrice(item?.request_refund_amount)} ₺</TableCell>
        <TableCell>{item?.response_description}</TableCell>

        <TableCell>
          <div className={`status status${item?.status}`}>
            {Number(item?.status) === 0
              ? "Beklemede"
              : Number(item?.status) === 1
              ? "Onaylandı"
              : Number(item?.status) === 2
              ? "Reddedildi"
              : "İptal Edildi"}
          </div>
        </TableCell>
      </TableRow>
      {Number(item?.status) === 2 && (
        <TableRow className="borderless-tablerow-reject">
          <TableCell colSpan={4}>
            <strong style={{ fontSize: "inherit", color: "var(--shipmentRed)" }}>
              İptal Nedeni:
            </strong>{" "}
            {item?.response_description}
          </TableCell>
        </TableRow>
      )}
    </>
  );
};

export default MyRefundTableBody;
