import { atom } from "recoil";
import CircularProgress from "@mui/material/CircularProgress";
import Box from "@mui/material/Box";

export const loadingState = atom({
  key: "loading",
  default: false,
});

export default function Loading({ width, circularWidth, circularHeight }) {
  const Width = () => {
    if (width) {
      return width;
    } else return "100%";
  };

  const CircularWidth = () => {
    if (circularWidth) {
      return circularWidth;
    } else return "21px!important";
  };

  const CircularHeight = () => {
    if (circularHeight) {
      return circularHeight;
    } else return "21px!important";
  };

  return (
    <Box sx={{ display: "flex", justifyContent: "center", alignItems: "center", width: Width() }}>
      <CircularProgress sx={{ width: CircularWidth(), height: CircularHeight }} />
    </Box>
  );
}
