import {
  Box,
  Card,
  CardActions,
  CardContent,
  ClickAwayListener,
  Collapse,
  createTheme,
  IconButton,
  styled,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  ThemeProvider,
} from "@mui/material";
import { useCallback, useState } from "react";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import TrendingUpOutlinedIcon from "@mui/icons-material/TrendingUpOutlined";

const ExpandMore = styled((props) => {
  const { expand, ...other } = props;
  return <IconButton {...other} />;
})(({ theme, expand }) => ({
  transform: !expand ? "rotate(0deg)" : "rotate(180deg)",
  marginLeft: "auto",
}));

const theme = createTheme({
  components: {
    MuiModal: {
      styleOverrides: {
        root: {
          padding: "20px 5px 20px",
        },
      },
    },
    MuiPaper: {
      styleOverrides: {
        root: {
          borderRadius: "10px",
        },
      },
    },
    MuiTableCell: {
      styleOverrides: {
        root: {
          padding: "10px 3px",
          fontSize: "12px",
        },
      },
    },
    MuiOutlinedInput: {
      styleOverrides: {
        root: {
          borderRadius: "8px",
        },
      },
    },
  },
});

const SupportDetailFinance = ({ rows }) => {
  const [expandFinance, setExpandFinance] = useState(false);
  const handleClickFinanceAway = () => {
    setExpandFinance(false);
  };

  const handleExpandFinanceClick = useCallback(() => {
    setExpandFinance(!expandFinance);
  }, [setExpandFinance, expandFinance]);

  return (
    <ThemeProvider theme={theme}>
      <ClickAwayListener
        mouseEvent="onMouseDown"
        touchEvent="onTouchStart"
        onClickAway={handleClickFinanceAway}
      >
        <Card className="support-detail-files" sx={{ width: "100%" }}>
          <CardActions
            sx={{ cursor: "pointer" }}
            onClick={handleExpandFinanceClick}
            disableSpacing
          >
            <Box className="support-detail-files-header">
              <TrendingUpOutlinedIcon /> Fiyat Bilgileri
            </Box>
            <ExpandMore
              expand={expandFinance}
              onClick={handleExpandFinanceClick}
              aria-expanded={expandFinance}
            >
              <ExpandMoreIcon />
            </ExpandMore>
          </CardActions>
          <Collapse in={expandFinance} timeout="auto" unmountOnExit>
            <CardContent className="support-detail-files-content">
              <TableContainer
                sx={{
                  outline: 0,
                  maxWidth: 1100,
                  margin: "0 auto",
                  position: "relative",
                }}
              >
                <Table aria-label="simple table">
                  <TableHead>
                    <TableRow>
                      <TableCell sx={{ fontWeight: 700, paddingLeft: "20px" }}>
                        Tarih
                      </TableCell>
                      <TableCell sx={{ fontWeight: 700 }}>Açıklama</TableCell>
                      <TableCell sx={{ fontWeight: 700 }}>Fiyat</TableCell>
                      <TableCell sx={{ fontWeight: 700 }}>Oluşturan</TableCell>
                      <TableCell sx={{ fontWeight: 700, paddingRight: "20px" }}>
                        Durum
                      </TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {rows.map((row, key) => (
                      <TableRow
                        key={key}
                        sx={{
                          "&:last-child td, &:last-child th": {
                            border: 0,
                          },
                          ":nth-of-type(even)": {
                            backgroundColor: "var(--pastelBlue)",
                          },
                        }}
                      >
                        <TableCell
                          component="th"
                          scope="row"
                          sx={{ paddingLeft: "20px" }}
                        >
                          {row.date}
                          <br />
                          {row.hour}
                        </TableCell>
                        <TableCell align="left">
                          {row.type_name}
                        </TableCell>
                        <TableCell align="left">
                          <div style={{ fontSize: "12px" }}>{row.price}</div>
                          <div style={{ fontSize: "12px" }}>
                            (Kur:&nbsp;{row.exchangeRate})
                          </div>
                        </TableCell>
                        <TableCell align="left">
                          <div
                            style={
                              row.creater === "Sistem"
                                ? {
                                    backgroundColor: "var(--pastelBlue)",
                                    color: "var(--blueBrand)",
                                    padding: "2px 7px",
                                    width: "fit-content",
                                    borderRadius: "var(--buttonBorderRadius",
                                    fontSize: "12px",
                                  }
                                : {
                                    backgroundColor: "var(--pastelYellow)",
                                    color: "var(--orangeBrand)",
                                    padding: "2px 7px",
                                    width: "fit-content",
                                    borderRadius: "var(--buttonBorderRadius",
                                    fontSize: "12px",
                                  }
                            }
                          >
                            {row.fullName}
                          </div>
                        </TableCell>
                        <TableCell align="left" sx={{ paddingRight: "20px" }}>
                          <div
                            style={
                              row.paid === "0"
                                ? {
                                    backgroundColor: "var(--pastelRed)",
                                    width: "fit-content",
                                    borderRadius: "var(--buttonBorderRadius)",
                                    color: "var(--redBrand)",
                                    padding: "2px 7px",
                                    fontSize: "12px",
                                  }
                                : {
                                    backgroundColor: "var(--pastelGreen)",
                                    width: "fit-content",
                                    borderRadius: "var(--buttonBorderRadius)",
                                    color: "var(--greenBrand)",
                                    padding: "2px 7px",
                                    fontSize: "12px",
                                  }
                            }
                          >
                            {row.paid === "0" ? "Ödenmedi" : "Ödendi"}
                          </div>
                          <div style={{ fontSize: "12px" }}>
                            (Kur:&nbsp;{row.exchangeRate})
                          </div>
                        </TableCell>
                      </TableRow>
                    ))}
                  </TableBody>
                </Table>
              </TableContainer>
            </CardContent>
          </Collapse>
        </Card>
      </ClickAwayListener>
    </ThemeProvider>
  );
};

export default SupportDetailFinance;
