import {
  Button,
  createTheme,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Slide,
  ThemeProvider,
} from "@mui/material";
import React from "react";
import { useRecoilState } from "recoil";
import { insuranceSlideWarningState } from "../../../atoms/createShipment";
import WarningAmberIcon from "@mui/icons-material/WarningAmber";
import ErrorOutlineOutlinedIcon from "@mui/icons-material/ErrorOutlineOutlined";
import LocalPrice from "../../../utils/LocalPrice";
import Currency from "../../../utils/CurrencySymbol";

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="down" ref={ref} {...props} />;
});

const theme = createTheme({
  components: {
    MuiBackdrop: {
      styleOverrides: {
        root: {
          backgroundColor: "rgba(0, 0, 0, 0.2)",
        },
      },
    },
    MuiPaper: {
      styleOverrides: {
        root: {
          boxShadow: "none",
          marginTop: "90px !important",
        },
      },
    },
    MuiDialog: {
      styleOverrides: {
        container: {
          alignItems: "baseline",
        },
      },
    },
  },
});

const InsuranceSlideWarning = () => {
  const [insuranceSlideWarning, setInsuranceSlideWarning] = useRecoilState(
    insuranceSlideWarningState
  );

  const status = insuranceSlideWarning.status;
  const supplierID = insuranceSlideWarning.data?.supplier_id;

  const handleClose = () => {
    setInsuranceSlideWarning({ status: false, data: {} });
  };

  const currency = supplierID === 306 ? "USD" : "GBP";

  return (
    <ThemeProvider theme={theme}>
      <Dialog
        open={status}
        TransitionComponent={Transition}
        keepMounted
        onClose={handleClose}
        aria-describedby="alert-dialog-slide-description"
        style={{ zIndex: 9000 }}
      >
        <DialogTitle
          sx={{
            display: "flex",
            alignItems: "center",
            gap: "5px",
            color: "var(--notRed)",
          }}
        >
          <WarningAmberIcon />
          {"Uyarı!"}
        </DialogTitle>
        <DialogContent
          sx={{
            padding: "20px!important",
            borderTop: "1px solid var(--phoneDigitBg)",
            borderBottom: "1px solid var(--phoneDigitBg)",
          }}
        >
          {(Number(supplierID) === 306 ||
            Number(supplierID) === 307 ||
            Number(supplierID) === 308 ||
            Number(supplierID) === 309) && (
            <DialogContentText
              id="alert-dialog-slide-description2"
              style={{
                display: "flex",
                gap: "5px",
                marginTop: "var(--gap)",
                alignItems: "center",
              }}
            >
              <ErrorOutlineOutlinedIcon sx={{ color: "var(--shipmentRed)" }} />{" "}
              <span>
                {Currency(currency)}
                {LocalPrice(50)} mal değerine kadar sigorta kapsamına alınır.
              </span>
            </DialogContentText>
          )}
          <DialogContentText id="alert-dialog-slide-description">
            Sigortası yapılacak olan gönderiniz ile ilgili taşıma esnasında oluşacak zarar, ziyan ve
            kayıp durumlarında zararınızın sigortaya rücu edilebilmesi için alıcınızın hasar tespit
            tutanağı tutması kati ve zorunludur. Hasar tespit tutanağı olmayan gönderiler için
            sigorta tazmini yapılamamaktadır. Alıcılarınızı bilgilendirmenizi rica ederiz.
          </DialogContentText>
          <DialogContentText
            id="alert-dialog-slide-description2"
            style={{
              display: "flex",
              gap: "5px",
              marginTop: "var(--gap)",
              marginBottom: "var(--gap2x)",
              alignItems: "center",
            }}
          >
            <ErrorOutlineOutlinedIcon sx={{ color: "var(--shipmentRed)" }} />{" "}
            <span>Cam, vazo, porselen vb. kırılabilir eşyalar sigorta kapsamı dışındadır.</span>
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button className="iyzi-button" onClick={handleClose}>
            Anladım
          </Button>
        </DialogActions>
      </Dialog>
    </ThemeProvider>
  );
};

export default InsuranceSlideWarning;
