import { Button, Dialog, DialogActions, DialogContent, DialogContentText } from "@mui/material";

const ProformaWarning = ({ notEvenPriceDialog, setNotEvenPriceDialog }) => {
  const handleClose = () => {
    setNotEvenPriceDialog(false);
  };

  return (
    <Dialog
      open={notEvenPriceDialog}
      onClose={handleClose}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
    >
      <DialogContent>
        <DialogContentText id="alert-dialog-description">
          İlk aşamada belirlediğiniz mal bedeli (Miktar * Birim Fiyatı) toplam fiyata eşit değil!
          Lütfen düzeltmenizi yapıp tekrar deneyin.
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button className="iyzi-button-blue" onClick={handleClose}>
          Anladım
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default ProformaWarning;
