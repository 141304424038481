import {
  Button,
  CircularProgress,
  FormControl,
  InputLabel,
  MenuItem,
  Modal,
  Select,
  TextField,
} from "@mui/material";
import { useRecoilState } from "recoil";
import { myShipmentDomesticState } from "../../../atoms/myShipments";
import { useEffect, useState } from "react";
import axios from "../../../api/axios";
import Errors from "../../../utils/Errors";
import { toast } from "react-toastify";

const MyShipmentDomestic = () => {
  const [loading, setLoading] = useState(false);
  const [myShipmentDomestic, setMyShipmentDomestic] = useRecoilState(myShipmentDomesticState);

  const status = myShipmentDomestic?.status;
  const data = myShipmentDomestic?.data;

  const [domesticSupplier, setDomesticSupplier] = useState("");
  const [domesticAWB, setDomesticAWB] = useState("");

  const handleClose = () => {
    setMyShipmentDomestic({ status: false, data: {} });
    setDomesticSupplier(data?.domestic_supplier);
    setDomesticAWB(data?.domestic_awb);
  };

  const handleSubmit = (e) => {
    if (e) e.preventDefault();
    setLoading(true);

    axios
      .post(`parcel/parcel-domestic/add`, {
        parcel_data: [
          { id: data?.id, domestic_awb: domesticAWB, domestic_supplier: domesticSupplier },
        ],
      })
      .then((response) => {
        toast.success(response.data.message);
        setMyShipmentDomestic({ status: false, data: {} });
      })
      .catch((error) => Errors(error))
      .finally(() => setLoading(false));
  };

  useEffect(() => {
    if (status) {
      setDomesticSupplier(data?.domestic_supplier === null ? "" : data?.domestic_supplier);
      setDomesticAWB(data?.domestic_awb === null ? "" : data?.domestic_awb);
    }
  }, [status, data]);

  return (
    <Modal
      open={status}
      onClose={handleClose}
      sx={{ display: "flex", justifyContent: "center", alignItems: "center", padding: "20px" }}
    >
      <div
        style={{
          maxHeight: "90vh",
          overflow: "auto",
          margin: "auto",
          display: "flex",
          flexDirection: "column",
          gap: "var(--gap2x)",
          backgroundColor: "var(--lighterBg)",
          borderRadius: "var(--wrapperBorderRadius)",
          padding: "20px",
          maxWidth: "600px",
          width: "100%",
          outline: "none",
        }}
      >
        <form
          onSubmit={handleSubmit}
          style={{ display: "flex", flexDirection: "column", width: "100%", gap: "var(--gap2x)" }}
        >
          <FormControl required size="small" fullWidth>
            <InputLabel id="demo-simple-select-label">Domestik Taşıyıcı</InputLabel>
            <Select
              labelId="demo-simple-select-label"
              id="demo-simple-select"
              value={domesticSupplier}
              label="Domestik Taşıyıcı"
              onChange={(e) => setDomesticSupplier(e.target.value)}
            >
              <MenuItem value={"Aras"}>Aras Kargo</MenuItem>
              <MenuItem value={"Surat"}>Sürat Kargo</MenuItem>
              <MenuItem value={"Mng"}>Mng Kargo</MenuItem>
            </Select>
          </FormControl>

          <TextField
            required
            fullWidth
            size="small"
            label="Domestik Numarası"
            value={domesticAWB}
            onChange={(e) => setDomesticAWB(e.target.value)}
          />
          <div
            style={{
              display: "flex",
              alignItems: "center",
              gap: "var(--gap)",
              justifyContent: "flex-end",
            }}
          >
            {loading ? (
              <div
                style={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                  width: "64px",
                }}
              >
                <CircularProgress sx={{ width: "21px!important", height: "21px!important" }} />
              </div>
            ) : (
              <Button type="submit" className="iyzi-button-blue">
                Kaydet
              </Button>
            )}
            <Button className="iyzi-button-gray" type="button" onClick={handleClose}>
              İptal
            </Button>
          </div>
        </form>
      </div>
    </Modal>
  );
};

export default MyShipmentDomestic;
