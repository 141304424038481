import { Modal } from "@mui/material";
import { useEffect, useState } from "react";

const Popup = () => {
  const [modalOpen, setModalOpen] = useState(true);

  const handleAccept = () => {
    localStorage.setItem("cookie-accept", "1"); // Set as a string
    setModalOpen(false);
  };

  useEffect(() => {
    // Use loose equality (==) to check for both value and type
    if (localStorage.getItem("cookie-accept") === "1") {
      setModalOpen(false);
    }
  }, [modalOpen]);

  return (
    <div
      style={{
        display: modalOpen === true ? "flex" : "none",
      }}
      className="pop-up"
    >
      <div className="pop-up-main">
        <div className="pop-up-article">
          <div className="pop-up-article-header">
            <p>Size daha iyi bir deneyim sunmak için izninizi istiyoruz.</p>
          </div>
          <div className="pop-up-article-info">
            <p>
              Kullanıcı deneyiminizi iyileştirmek ve sizlere daha iyi hizmet
              sunabilmek için sitemizde çerezlerden faydalanıyoruz.
            </p>
          </div>
        </div>
        <div className="pop-up-buttons">
          <button onClick={handleAccept} className="pop-up-button-accept">
            Kabul Et
          </button>
          <a href="/cerez-politikasi" className="pop-up-button-cookies">
            Çerez Politikası
          </a>
        </div>
      </div>
    </div>
  );
};

export default Popup;
