import {
  arrivalCountryCodeState,
  confirmationCodeState,
  contractIdsState,
  dimensionArrayState,
  documentMaxGrossState,
  productCurrencyState,
  productValueState,
  shipmentDocumentState,
  shipmentPriceArrayLengthState,
  shipmentPriceArrayState,
  shipmentZipCodeState,
} from "../../atoms/createShipment";
import { useRecoilValue, useSetRecoilState } from "recoil";
import { useCallback } from "react";
import axios from "../../api/axios";
import { useEffect } from "react";
import { useState } from "react";
import ShipmentPriceFlags from "./shipmentPrice/ShipmentPriceFlags";
import { CircularProgress } from "@mui/material";
import ShipmentPriceGetM from "./shipmentPrice/ShipmentPriceGetM";
import Errors from "../../utils/Errors";
import InfoOutlined from "@mui/icons-material/InfoOutlined";

const ShipmentPrice = () => {
  const [loading, setLoading] = useState(false);

  const arrivalCountryCode = useRecoilValue(arrivalCountryCodeState);
  const shipmentDocument = useRecoilValue(shipmentDocumentState);
  const productValue = useRecoilValue(productValueState);
  const productCurrency = useRecoilValue(productCurrencyState);
  const documentMaxGross = useRecoilValue(documentMaxGrossState);
  const dimensionArray = useRecoilValue(dimensionArrayState);
  const shipmentZipCode = useRecoilValue(shipmentZipCodeState);

  const setShipmentPriceArray = useSetRecoilState(shipmentPriceArrayState);
  const setContractIds = useSetRecoilState(contractIdsState);
  const setConfirmationCode = useSetRecoilState(confirmationCodeState);
  const setShipmentPriceArrayLength = useSetRecoilState(shipmentPriceArrayLengthState);

  const totalQtyArray = dimensionArray?.map((e) => Number(e.qty));
  let totalQty = 0;

  for (let i = 0; i < totalQtyArray.length; i++) {
    totalQty = totalQty + totalQtyArray[i];
  }

  const getPrice = useCallback(async () => {
    setLoading(true);

    const data =
      shipmentDocument === "N"
        ? {
            origin_country_code: "TR",
            country_code: arrivalCountryCode,
            document_type: shipmentDocument,
            product_value: Number(Number(productValue).toFixed(2)),
            product_value_currency: productCurrency,
            dimensions: dimensionArray,
            total_qty: totalQty,
            postal_code: shipmentZipCode,
          }
        : shipmentDocument === "D"
        ? {
            origin_country_code: "TR",
            country_code: arrivalCountryCode,
            document_type: shipmentDocument,
            max_gross: documentMaxGross,
            postal_code: shipmentZipCode,
          }
        : "";

    await axios
      .post("parcel/get-parcel-price", data)
      .then((response) => {
        const arrayLength = response.data.data.length;
        setShipmentPriceArrayLength(arrayLength);
        setShipmentPriceArray(response.data.data);
        setConfirmationCode(response.data.contract_code);
        setContractIds(response.data.contract_ids);
      })
      .catch((error) => Errors(error))
      .finally(() => setLoading(false));
  }, [
    arrivalCountryCode,
    shipmentDocument,
    productValue,
    productCurrency,
    documentMaxGross,
    totalQty,
    dimensionArray,
    shipmentZipCode,
    setShipmentPriceArray,
    setShipmentPriceArrayLength,
    setConfirmationCode,
    setContractIds,
  ]);

  useEffect(() => {
    getPrice();
  }, [getPrice]);

  return (
    <div className="shipmentPrice">
      <ShipmentPriceFlags />
      <div className="shipmentPrice-info">
        <InfoOutlined />
        <p>
          Ana taşıyıcıların yakıt artışı gibi dönemlik artışları sonrası yurtdışı edilen gönderiler
          için güncel fiyatlar geçerli olacaktır.
        </p>
      </div>
      {loading ? <CircularProgress sx={{ margin: "20px auto" }} /> : <ShipmentPriceGetM />}
    </div>
  );
};

export default ShipmentPrice;
