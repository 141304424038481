import SyncIcon from "@mui/icons-material/Sync";
import {
  Button,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TextField,
  createTheme,
} from "@mui/material";
import { useCallback, useState } from "react";
import axios from "../../api/axios";
import { toast } from "react-toastify";
import { ThemeProvider } from "@emotion/react";
import IntegrationListItemChild from "./IntegrationListItemChild";
import Errors from "../../utils/Errors";

const theme = createTheme({
  components: {
    MuiOutlinedInput: {
      styleOverrides: {
        root: {
          borderRadius: "8px",
        },
      },
    },
  },
});

const IntegrationListItem = ({ item }) => {
  const [loading, setLoading] = useState(false);
  const [open, setOpen] = useState(false);
  const [shopName, setShopName] = useState("");

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const endPoint = useCallback(() => {
    if (item.title === "etsy") {
      return "etsy/auth";
    } else if (item.title === "shopify") {
      return `shopify/auth?shop_name=${shopName}`;
    } else if (item.title === "woocommerce") {
      return `woocommerce/auth?protocol=https&shop_name=${shopName}`;
    }
  }, [item.title, shopName]);

  const openNewTab = (url) => {
    window.open(url);
  };

  const handleIntegrate = useCallback(() => {
    setLoading(true);
    if (item.title === "etsy") {
      axios
        .post(endPoint())
        .then((response) => {
          setLoading(false);
          toast.success(response.data.message);
          setTimeout(() => {
            openNewTab(response.data.url);
          }, 1000);
        })
        .catch((error) => Errors(error))
        .finally(() => setLoading(false));
    } else {
      axios
        .post(endPoint())
        .then((response) => {
          toast.success(response.data.message);
          setTimeout(() => {
            openNewTab(response.data.url);
          }, 1000);
        })
        .catch((error) => Errors(error))
        .finally(() => setLoading(false));
    }
    handleClose();
  }, [item, endPoint]);

  return (
    <ThemeProvider theme={theme}>
      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">{"Mağaza Linki (URL)"}</DialogTitle>
        <DialogContent>
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              gap: "var(--gap2x)",
            }}
          >
            <div>
              İlgili mağazada bulunan kişisel mağaza linkini (Başında http / https bulunmadan!){" "}
              <b>türkçe karakter kullanmadan</b> aşağıdaki alana giriniz. (Örnek:
              "shop.iyziship.com")
            </div>
            <TextField
              label="Mağaza Linki"
              fullWidth
              required
              size="small"
              value={shopName}
              autoComplete="off"
              onChange={(e) => setShopName(e.target.value)}
            />
          </div>
        </DialogContent>
        <DialogActions>
          <Button className="iyzi-button" onClick={handleClose}>
            İptal
          </Button>
          <Button
            sx={{
              ":disabled": {
                opacity: 0.4,
              },
            }}
            disabled={shopName.length === 0}
            className="iyzi-button"
            onClick={handleIntegrate}
            autoFocus
          >
            Gönder
          </Button>
        </DialogActions>
      </Dialog>
      <div
        style={{
          boxShadow: "var(--shadow)",
          display: "flex",
          flexDirection: "column",
          borderRadius: "var(--wrapperBorderRadius)",
          position: "relative",
          width: "100%",
        }}
      >
        <div
          style={{
            position: "absolute",
            top: "10px",
            left: "10px",
            color: "var(--lighterBg)",
            boxShadow: "1px 1px 8px rgba(0, 0, 0, 0.2)",
            borderRadius: "var(--wrapperBorderRadius)",
            padding: "5px 10px",
          }}
        >
          {item.title === "" ? "Yakında" : "Aktif"}
        </div>
        <div
          style={{
            backgroundColor: item.background,
            minHeight: "174px",
            maxHeight: "174px",
            height: "174px",
            borderTopLeftRadius: "var(--wrapperBorderRadius)",
            borderTopRightRadius: "var(--wrapperBorderRadius)",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <img src={item.src} alt={item.alt} />
        </div>
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            gap: "var(--gap)",
          }}
        >
          {loading ? (
            <CircularProgress sx={{ width: "21px !important", height: "21px !important" }} />
          ) : (
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                gap: "3px",
                margin: "10px 0",
              }}
            >
              <Button
                sx={{
                  backgroundColor: item.background,
                  textTransform: "none",
                  color: "var(--lighterBg)",
                  transition: "all 150ms linear",
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  gap: "5px",
                  ":hover": {
                    backgroundColor: item.background,
                    opacity: 0.8,
                    transition: "all 150ms linear",
                  },
                  ":disabled": {
                    opacity: 0.4,
                  },
                }}
                disabled={item.title === ""}
                onClick={item.title === "etsy" ? handleIntegrate : handleClickOpen}
              >
                <SyncIcon /> {item.content}
              </Button>
              {item.integration.length !== 0 && (
                <TableContainer>
                  <Table>
                    <TableHead>
                      <TableRow>
                        <TableCell>Mağaza ID</TableCell>
                        <TableCell>Mağaza Adı</TableCell>
                        <TableCell></TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {item.integration?.map((child, key) => (
                        <IntegrationListItemChild key={key} child={child} item={item} />
                      ))}
                    </TableBody>
                  </Table>
                </TableContainer>
              )}
            </div>
          )}
        </div>
      </div>
    </ThemeProvider>
  );
};

export default IntegrationListItem;
