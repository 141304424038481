export const FormatFieldWithInteger = (e, setState) => {
  const value = e.target.value.replace(/[^0-9]/g, "");
  setState(value);
};

export const FormatFieldWithTwoDecimals = (e, setState) => {
  const value = e.target.value.replace(/,/g, ".");
  const decimalValue = value.replace(/[^0-9.]|(?<=\..*)\.|^0{2,}(?=[.])/g, "");
  const regex = /^\d+(\.\d{0,2})?$/;

  const formattedValue = decimalValue === "." || decimalValue === "," ? "0." : decimalValue;
  if (formattedValue.startsWith(".") || (!regex.test(formattedValue) && formattedValue !== "")) {
    return;
  } else {
    setState(formattedValue);
  }
};

export const FormatFieldWithTwoDecimalsWithMaxNumber = (e, setState, maxNumber) => {
  const value = e.target.value.replace(/,/g, ".");
  const decimalValue = value.replace(/[^0-9.]|(?<=\..*)\.|^0{2,}(?=[.])/g, "");
  const regex = /^\d+(\.\d{0,2})?$/;

  const formattedValue = decimalValue === "." || decimalValue === "," ? "0." : decimalValue;

  if (formattedValue.startsWith(".") || (!regex.test(formattedValue) && formattedValue !== "")) {
    return;
  } else {
    if (Number(formattedValue) > maxNumber) {
      setState(maxNumber);
    } else {
      setState(formattedValue);
    }
  }
};
