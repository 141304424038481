import planeOff from "../../../img/images/shipment-price/plane_take_off.svg";
import planeIn from "../../../img/images/shipment-price/plane_take_in.svg";
import lineBtwPlanes from "../../../img/images/shipment-price/line_between_planes.svg";
import { useRecoilValue } from "recoil";
import {
  arrivalCountryCodeState,
  arrivalCountryState,
  documentMaxGrossState,
  senderCreateCountryCodeState,
  senderCreateCountryState,
  shipmentDocumentState,
  shipmentPriceArrayState,
} from "../../../atoms/createShipment";
import { CircleFlag } from "react-circle-flags";

const ShipmentPriceFlags = () => {
  const senderCreateCountry = useRecoilValue(senderCreateCountryState);
  const senderCreateCountryCode = useRecoilValue(senderCreateCountryCodeState);
  const arrivalCountry = useRecoilValue(arrivalCountryState);
  const arrivalCountryCode = useRecoilValue(arrivalCountryCodeState);
  const shipmentPriceArray = useRecoilValue(shipmentPriceArrayState);
  const shipmentDocument = useRecoilValue(shipmentDocumentState);
  const documentMaxGross = useRecoilValue(documentMaxGrossState);

  return (
    <div className="shipmentPrice-header">
      <div className="shipmentPrice-header-sides-wrapper">
        <div className="shipmentPrice-header-sides">
          <div className="shipmentPrice-header-sides-top">Çıkış</div>
          <CircleFlag
            height={27}
            countryCode={senderCreateCountryCode.toLowerCase()}
          />
          <div className="shipmentPrice-header-sides-bottom">
            {senderCreateCountry}
          </div>
        </div>
        <img src={planeOff} alt="Uçak Kalkış Görseli" />
      </div>
      <div className="shipmentPrice-header-connection">
        <img src={lineBtwPlanes} alt="Ülke Bağlantı Çizgi Görseli" />
        <div className="shipmentPrice-header-connection-gross">
          Ücret Ağırlığı:{" "}
          {shipmentDocument === "D"
            ? Number(documentMaxGross).toFixed(2)
            : Number(shipmentPriceArray[0]?.total_cw).toFixed(2)}{" "}
          kg
        </div>
      </div>
      <div className="shipmentPrice-header-sides-wrapper">
        <img src={planeIn} alt="Uçak İniş Görseli" />
        <div className="shipmentPrice-header-sides">
          <div className="shipmentPrice-header-sides-top">Varış</div>
          <CircleFlag
            height={27}
            countryCode={arrivalCountryCode.toLowerCase()}
          />
          <div className="shipmentPrice-header-sides-bottom">
            {arrivalCountry}
          </div>
        </div>
      </div>
    </div>
  );
};

export default ShipmentPriceFlags;
