import ProfileSettingsStyle from "../../../style/styleJs/ProfileSettingsStyle";
import DefaultBillAddress from "../addressInfo/DefaultBillAddress";
import AssignmentIndOutlinedIcon from "@mui/icons-material/AssignmentIndOutlined";

const InvoiceAddress = () => {
  return (
    <section style={ProfileSettingsStyle()} className="general-info">
      <div className="profile-card-wrapper">
        <div className="profile-card-header">
          <AssignmentIndOutlinedIcon className="profile-card-header-icon" />
          <span>Fatura Adresi</span>
        </div>
      </div>
      <DefaultBillAddress />
    </section>
  );
};

export default InvoiceAddress;
