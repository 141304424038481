const iyziWeightRoundSystem = (weight) => {
  if (weight <= 0.01) {
    return 0.01;
  } else if (weight < 10) {
    const integerPart = Math.floor(weight);
    const decimalPart = weight - integerPart;
    if (decimalPart > 0 && decimalPart <= 0.5) {
      return integerPart + 0.5;
    } else {
      return Math.ceil(weight * 2) / 2; // Bu, 0.5 adımlarla yukarı doğru yuvarlar
    }
  } else {
    return Math.ceil(weight);
  }
};

export default iyziWeightRoundSystem;
