import { NavLink } from "react-router-dom";

const ForgotSignWithSocial = () => {
  return (
    <div className="sign-others">
      <div className="others-divider">
        <div className="others-divider-line"></div>
        <div className="others-divider-text">veya giriş yap</div>
        <div className="others-divider-line"></div>
      </div>
      <NavLink
        to="/login"
        className="iyzi-button-sea"
        style={{
          textDecoration: "none",
          padding: "10px 20px",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        Giriş Yapın
      </NavLink>
    </div>
  );
};

export default ForgotSignWithSocial;
