import { Link } from "react-router-dom";

const IntegrationGetStart = () => {
  return (
    <section className="main-header integration-getStart">
      <div className="main-header-text">
        <article>
          <h1>
            <p className="dynamic-text integration-text">
              Ücretsiz entegrasyonlar ile
            </p>{" "}
            <p className="constant-text">lojistik sürecinizi optimize edin!</p>
          </h1>
          <p>
            Yurt dışı pazaryerlerinde bulunan mağazanızı ya da e-ticaret
            altyapınızı iyziShip paneline tek tıkla entegre edin. Bu sayede,
            lojistik süreçlerinizi optimize ederek rekabette öne çıkın.
          </p>
        </article>
        <div className="main-header-text-buttons">
          <Link
            to={"/login"}
            style={{
              padding: "10px 30px",
              minWidth: "200px",
              fontWeight: 700,
              textDecoration: "none",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
            className="iyzi-button-sea"
          >
            Ücretsiz Deneyin
          </Link>
          <Link
            to={"/nasil-calisir"}
            style={{
              padding: "10px 30px",
              minWidth: "200px",
              fontWeight: 700,
              textDecoration: "none",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
            className="iyzi-button-bg"
          >
            Detaylı Bilgi Alın
          </Link>
        </div>
      </div>
      <div className="main-header-img">
        <img
          src="/images/home/how/3-solutions-with-integration.svg"
          alt="Pazaryeri Entegrasyonları"
          loading="lazy"
        />
      </div>
    </section>
  );
};

export default IntegrationGetStart;
