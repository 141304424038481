import {
  Pagination,
  TextField,
  ThemeProvider,
  createTheme,
} from "@mui/material";
import { useCallback, useState } from "react";
import axios from "../../../api/axios";
import { useRecoilState, useRecoilValue, useSetRecoilState } from "recoil";
import {
  arrivalCountryCodeState,
  receiverAddressCurrentPageState,
  receiverAddressTotalPagesState,
  receiverAddressesState,
  shipmentZipCodeState,
} from "../../../atoms/createShipment";
import Errors from "../../../utils/Errors";

const theme = createTheme({
  components: {
    MuiInputBase: {
      styleOverrides: { root: { borderRadius: "10px !important" } },
    },
  },
});

const ShipmentAddressSearch = ({ setLoading }) => {
  const arrivalCountryCode = useRecoilValue(arrivalCountryCodeState);
  const shipmentZipCode = useRecoilValue(shipmentZipCodeState);

  const setReceiverAddresses = useSetRecoilState(receiverAddressesState);

  const [receiverAddressTotalPages, setReceiverAddressTotalPages] =
    useRecoilState(receiverAddressTotalPagesState);
  const [receiverAddressCurrentPage, setReceiverAddressCurrentPage] =
    useRecoilState(receiverAddressCurrentPageState);

  const [search, setSearch] = useState("");

  const handleSearch = useCallback(
    async (e) => {
      if (e) e.preventDefault();

      await axios
        .get("member-address", {
          params: {
            search: search,
            country: arrivalCountryCode,
            postal_code: shipmentZipCode,
            address_type: 5,
            limit: 10,
            detailed: 1,
          },
        })
        .then((response) => {
          setReceiverAddresses(response.data.data);
          setReceiverAddressCurrentPage(response.data.currentPage);
          setReceiverAddressTotalPages(response.data.totalPages);
          setLoading(false);
        })
        .catch((error) => Errors(error))
        .finally(() => setLoading(false));
    },
    [
      arrivalCountryCode,
      shipmentZipCode,
      search,
      setLoading,
      setReceiverAddresses,
      setReceiverAddressCurrentPage,
      setReceiverAddressTotalPages,
    ]
  );

  const handlePageChange = useCallback(
    (e, pageValue) => {
      axios
        .get("member-address", {
          params: {
            search: search,
            country: arrivalCountryCode,
            postal_code: shipmentZipCode,
            address_type: 5,
            detailed: 1,
            limit: 10,
            page: pageValue,
          },
        })
        .then(() => {
          setReceiverAddressCurrentPage(pageValue);
        })
        .catch((error) => Errors(error));
    },
    [arrivalCountryCode, shipmentZipCode, search, setReceiverAddressCurrentPage]
  );

  return (
    <ThemeProvider theme={theme}>
      <form onSubmit={handleSearch}>
        <TextField
          fullWidth
          label="Müşteri Adı, Firma Adı, Adresi Başlığı"
          size="small"
          value={search}
          onChange={(e) => setSearch(e.target.value)}
          autoComplete="off"
        />
      </form>
      <Pagination
        showFirstButton
        showLastButton
        size="small"
        count={receiverAddressTotalPages}
        page={receiverAddressCurrentPage}
        onChange={handlePageChange}
      />
    </ThemeProvider>
  );
};

export default ShipmentAddressSearch;
