const QrReader = ({ base64String }) => {
  return (
    <>
      <object
        data={`data:application/pdf;base64,${base64String}`}
        type="application/pdf"
        style={{
          height: "400px",
          width: "100%",
          boxShadow: "var(--shadow)",
          borderRadius: "var(--inputBorderRadius)",
        }}
      >
        <embed src={`data:application/pdf;base64,${base64String}`} type="application/pdf" />
      </object>
    </>
  );
};

export default QrReader;
