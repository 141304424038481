import { Checkbox } from "@mui/material";
import { useRecoilState, useSetRecoilState } from "recoil";
import CheckCircle from "@mui/icons-material/CheckCircle";
import CircleUnchecked from "@mui/icons-material/RadioButtonUnchecked";
import {
  receiverAddressesState,
  selectedReceiverAddressIdState,
  selectedReceiverAddressState,
} from "../../../atoms/createShipment";
import { useEffect, useState } from "react";
import axios from "axios";
import DeleteIcon from "@mui/icons-material/Delete";
import IconButton from "@mui/material/IconButton";
import { useCookies } from "react-cookie";
import { toast } from "react-toastify";
import DeleteAddressDialog from "./DeleteAddressDialog";

const ShipmentReceiverAddress = () => {
  const [receiverAddresses, setReceiverAddresses] = useRecoilState(
    receiverAddressesState
  );

  const setSelectedReceiverAddress = useSetRecoilState(
    selectedReceiverAddressState
  );

  const [selectedReceiverAddressId, setSelectedReceiverAddressId] =
    useRecoilState(selectedReceiverAddressIdState);

  const handleReceiverCheckedChange = (event, id) => {
    setSelectedReceiverAddressId(id);
    const selectedReceiverAddressIndex = receiverAddresses.findIndex(
      (address) => address.id === id
    );
    setSelectedReceiverAddress(receiverAddresses[selectedReceiverAddressIndex]);
  };

  const [deleteDialog, setDeleteDialog] = useState(false);
  const [itemID, setItemID] = useState(false);

  // jwt
  const [token] = useCookies(["jwt"]);
  const jwt = token.jwt;

  const handleDeleteAddress = async () => {
    try {
      const response = await axios.post(
        `https://api.iyziship.com/v2/member-address/delete/${itemID}`,
        null,
        {
          headers: {
            Authorization: `Bearer ${jwt}`,
          },
        }
      );
      const updatedAddresses = receiverAddresses.filter(
        (address) => address.id !== itemID
      );
      setReceiverAddresses(updatedAddresses);

      if (response.status === 200) {
        toast.success(response.data.message, { autoClose: 1000 });
      }
    } catch (error) {
      toast.error(error.response.data.message, { autoClose: 1000 });
    }
  };

  const handleDeleteDialog = (addressId) => {
    setItemID(addressId);
    setDeleteDialog(true);
  };

  //** -------------- Select Default Receiver Address -------------- **//

  useEffect(() => {
    setSelectedReceiverAddressId(
      receiverAddresses?.find((e) => e.is_default === "1")?.id
    );
    setSelectedReceiverAddress(
      receiverAddresses[
        receiverAddresses?.findIndex((e) => e.is_default === "1")
      ]
    );
  }, [
    setSelectedReceiverAddress,
    setSelectedReceiverAddressId,
    receiverAddresses,
  ]);

  return (
    <>
      <div className="list-board-items">
        <DeleteAddressDialog
          open={deleteDialog}
          setOpen={setDeleteDialog}
          handleDelete={handleDeleteAddress}
          id={itemID}
        />
        {receiverAddresses.map((address) => (
          <div className="list-board-item" key={address.id}>
            <Checkbox
              className="item-select"
              icon={<CircleUnchecked />}
              checkedIcon={<CheckCircle />}
              checked={selectedReceiverAddressId === address.id}
              onChange={(e) => handleReceiverCheckedChange(e, address.id)}
            />
            <div style={{ marginBottom: "var(--gap)" }}>
              {address.address_description}
            </div>
            <div style={{ marginBottom: "5px" }}>
              {address.contact_name} {address.company_name ? " / " : ""}{" "}
              {address.company_name}
            </div>
            <div style={{ marginBottom: "var(--gap)" }}></div>
            <div style={{ fontSize: "12px", color: "var(--priceText)" }}>
              <span style={{ color: "inherit", fontSize: "inherit" }}>
                {address.postal_code}
              </span>
              {" / "}
              <span style={{ color: "inherit", fontSize: "inherit" }}>
                {address.address1}
              </span>{" "}
              <span style={{ color: "inherit", fontSize: "inherit" }}>
                {address.address2}
              </span>{" "}
              <span
                style={{
                  marginBottom: "5px",
                  color: "inherit",
                  fontSize: "inherit",
                }}
              >
                {address.address3}
              </span>
            </div>
            <div>
              {address.town}/{address.city}
            </div>
            <div>{address.phone}</div>
            <IconButton
              aria-label="Delete"
              color="error"
              onClick={() => handleDeleteDialog(address.id)}
              sx={{ position: "absolute", bottom: "0", right: "-2%" }}
            >
              <DeleteIcon />
            </IconButton>
          </div>
        ))}
      </div>
      {receiverAddresses.length === 0 && (
        <div
          style={{
            backgroundColor: "var(--pastelBlue)",
            padding: "10px 20px",
            borderRadius: "var(--wrapperBorderRadius)",
            boxShadow: "var(--shadow)",
          }}
        >
          Bu posta koduna kayıtlı alıcı adresiniz bulunmamaktadır.
        </div>
      )}
    </>
  );
};

export default ShipmentReceiverAddress;
