const DownloadFile = ({ fileUrl, fileName }) => {
  const base = window.location.origin;

  fetch(`${base}/${fileUrl}`)
    .then((response) => response.blob())
    .then((blob) => {
      // Blob'u kullanarak dosyayı indirme bağlantısı oluşturun
      const url = window.URL.createObjectURL(blob);

      // Bir <a> etiketi oluşturun ve indirme bağlantısını ayarlayın
      const a = document.createElement("a");
      a.href = url;
      a.download = fileName; // İndirilecek dosyanın adını ayarlayın

      // <a> etiketini tıklayarak indirme işlemi
      document.body.appendChild(a);
      a.click();

      // Temizlik işlemleri
      document.body.removeChild(a);
      window.URL.revokeObjectURL(url);
    })
    .catch((error) => console.error("Dosya indirme hatası:", error));
};

export default DownloadFile;
