import "../../style/profileSettings/TimerSmsModal.css";
import { Box, Button, CircularProgress, Modal } from "@mui/material";
import { CountdownCircleTimer } from "react-countdown-circle-timer";
import { useRecoilState, useSetRecoilState } from "recoil";
import { memberStatusState, phoneVerifiedState, timerSmsModalState } from "../../atoms/membership";
import { useState } from "react";
import OtpInput from "react18-input-otp";
import axios from "../../api/axios";
import { toast } from "react-toastify";
import { useCookies } from "react-cookie";
import Errors from "../../utils/Errors";
import { saveUser } from "../../utils/User";

const TimerSmsModal = () => {
  const [token] = useCookies(["jwt"]);
  const jwt = token.jwt;
  axios.defaults.headers.common["Authorization"] = `Bearer ${jwt}`;

  const [timerSmsModal, setTimerSmsModal] = useRecoilState(timerSmsModalState);

  const setPhoneVerified = useSetRecoilState(phoneVerifiedState);
  const setMemberStatus = useSetRecoilState(memberStatusState);

  const [timeIsDone, setTimeIsDone] = useState(false);
  const [otp, setOtp] = useState("");
  const [loading, setLoading] = useState(false);

  const handleClose = () => {
    setTimerSmsModal(false);
    setOtp("");
    setLoading(false);
  };

  const handleOtpChange = (enteredOtp) => {
    setOtp(enteredOtp);
  };

  const children = ({ remainingTime }) => {
    setTimeIsDone(false);
    let minutes = Math.floor(remainingTime / 60);
    let seconds = remainingTime % 60;

    if (minutes === 0 && seconds === 0) {
      setTimeIsDone(true);

      setTimeout(() => {
        setTimerSmsModal(false);
      }, [2000]);
    }

    return `${minutes}:${seconds}`;
  };

  const codeSendHandler = async (e) => {
    e.preventDefault();
    setLoading(true);

    await axios
      .post("profile/verify-phone", {
        phone_verification_code: otp,
      })
      .then((response) => {
        saveUser(response);

        setPhoneVerified(response.data.data?.phone_verified);
        setMemberStatus(response.data.data?.user_member?.status);

        toast.success(response.data.message);
      })
      .catch((error) => Errors(error))
      .finally(() => handleClose());
  };

  return (
    <Modal className="timer-sms-modal" open={timerSmsModal} onClose={handleClose}>
      <Box
        className="timer-sms-modal-box"
        style={{
          maxHeight: "90vh",
          overflowY: "auto",
        }}
      >
        <CountdownCircleTimer
          isPlaying
          duration={120}
          colors={["#2F80ED", "#2F80ED", "#EB5757", "#EB5757"]}
          colorsTime={[120, 11, 10, 0]}
        >
          {children}
        </CountdownCircleTimer>
        <div className="timer-sms-div">
          {timeIsDone ? (
            "Süreniz bitti, lütfen tekrar deneyin."
          ) : (
            <form
              style={{
                display: "flex",
                flexDirection: "column",
                gap: "var(--gap)",
              }}
              onSubmit={codeSendHandler}
            >
              <OtpInput
                value={otp}
                shouldAutoFocus={true}
                onChange={handleOtpChange}
                numInputs={6}
                separator={<span>&nbsp;</span>}
              />
              {loading ? (
                <div
                  style={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                    height: "35px",
                  }}
                >
                  <CircularProgress sx={{ width: "30px !important", height: "30px !important" }} />
                </div>
              ) : (
                <Button
                  disabled={otp.length !== 6 ? true : false}
                  sx={{ opacity: otp.length !== 6 ? 0.4 : 1 }}
                  type="submit"
                  className="iyzi-button-blue"
                >
                  Kodu Onayla
                </Button>
              )}
              <Button
                className="iyzi-button-blue"
                onClick={() => setTimerSmsModal(false)}
                type="button"
              >
                Kapat
              </Button>
            </form>
          )}
        </div>
      </Box>
    </Modal>
  );
};

export default TimerSmsModal;
