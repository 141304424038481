import {
  Box,
  Button,
  FormControl,
  Modal,
  ThemeProvider,
  createTheme,
  TextField,
} from "@mui/material";
import { useRecoilState } from "recoil";
import CancelOutlinedIcon from "@mui/icons-material/CancelOutlined";
import ParcelReturnIcon from "@mui/icons-material/AssignmentReturn";
import {
  parcelReturnOpenState,
  selectedParcelReturnState,
  returnShipmentPriceInformationOpenState,
  arrivalCostTryState,
  returnCostTryState,
  returnShipmentConfirmationOpenState,
  deductionCostTryState,
  deductionKdvCostTryState,
} from "../../../atoms/returnShipment";
import ChevronRightIcon from "@mui/icons-material/ChevronRight";
import ChevronLeftIcon from "@mui/icons-material/ChevronLeft";

const theme = createTheme({
  components: {
    MuiModal: {
      styleOverrides: {
        root: {
          padding: "20px",
        },
      },
    },
    MuiOutlinedInput: {
      styleOverrides: {
        root: {
          borderRadius: "8px",
        },
      },
    },
  },
});

const ReturnShipmentPriceInformation = () => {
  const [, setParcelReturnOpen] = useRecoilState(parcelReturnOpenState);
  const [returnShipmentPriceInformationOpen, setReturnShipmentPriceInformationOpen] =
    useRecoilState(returnShipmentPriceInformationOpenState);
  const [selectedParcelReturn] = useRecoilState(selectedParcelReturnState);
  const [returnCostTry] = useRecoilState(returnCostTryState);
  const [arrivalCostTry] = useRecoilState(arrivalCostTryState);
  const [, setReturnShipmentConfirmationOpen] = useRecoilState(returnShipmentConfirmationOpenState);
  const [deductionCostTry] = useRecoilState(deductionCostTryState);
  const [deductionKdvCostTry] = useRecoilState(deductionKdvCostTryState);
  const handleNextPage = () => {
    handleClose();
    setReturnShipmentConfirmationOpen({
      status: true,
      modalType: returnShipmentPriceInformationOpen.modalType,
    });
  };
  const handleClose = () => {
    setReturnShipmentPriceInformationOpen({ status: false, modalType: "" });
  };
  const handleBack = () => {
    handleClose();
    setParcelReturnOpen(true);
  };
  return (
    <ThemeProvider theme={theme}>
      <Modal open={returnShipmentPriceInformationOpen.status} onClose={handleClose}>
        {selectedParcelReturn.length !== 0 ? (
          <Box
            sx={{
              backgroundColor: "var(--lighterBg)",
              borderRadius: "var(--inputBorderRadius)",
              padding: "20px",
              outline: 0,
              display: "grid",
              gap: "var(--gap)",
              width: "100%",
              maxWidth: "500px",
              margin: "auto",
              position: "relative",
            }}
            style={{
              maxHeight: "90vh",
              overflowY: "auto",
            }}
          >
            <CancelOutlinedIcon
              sx={{
                position: "absolute",
                right: 1,
                top: 1,
                cursor: "pointer",
                color: "var(--priceText)",
                transition: "all 80ms linear",
                ":hover": {
                  color: "var(--notRed)",
                  transition: "all 80ms linear",
                },
              }}
              onClick={handleClose}
            />
            <h6
              style={{
                display: "flex",
                alignItems: "center",
                gap: "5px",
                color: "var(--priceText)",
                marginBottom: "10px",
              }}
            >
              <ParcelReturnIcon />
              Gönderi Masrafları
            </h6>
            <Box sx={{ display: "grid", gap: "var(--gap)" }}>
              <FormControl className="parcel-return-demand" size="small" sx={{ minWidth: 180 }}>
                {selectedParcelReturn.return_country !== "GGE" && (
                  <TextField
                    fullWidth
                    value={arrivalCostTry}
                    size="small"
                    label="Adresinizden Depomuza  Geliş Masrafı"
                    sx={{ marginBottom: "20px" }}
                    disabled={true}
                  />
                )}
                <TextField
                  fullWidth
                  value={returnCostTry}
                  size="small"
                  label="Depomuzdan Adresinize Geri Dönüş Masrafı"
                  sx={{ marginBottom: "20px" }}
                  disabled={true}
                />
                <span
                  style={{
                    fontSize: "inherit",
                    fontWeight: 700,
                    color: "var(--btnBgBlue)",
                  }}
                >
                  Toplam: {deductionCostTry} TL + KDV ({deductionKdvCostTry})
                </span>
              </FormControl>
            </Box>
            <Box
              sx={{ display: "grid", gap: "var(--gap)", alignItems: "end", justifyItems: "end" }}
            >
              <div>
                <Button
                  onClick={handleBack}
                  sx={{
                    backgroundColor: "var(--info)",
                    color: "var(--lighterBg)",
                    textTransform: "none",
                    transition: "all 150ms linear",
                    borderRadius: "var(--buttonBorderRadius)",
                    padding: "7px 16px",
                    ":hover": {
                      backgroundColor: "var(--blueBrand)",
                      transition: "all 150ms linear",
                    },
                    marginRight: "10px",
                  }}
                >
                  <ChevronLeftIcon sx={{ marginRight: "5px" }} />
                  Geri Dön
                </Button>

                <Button
                  sx={{
                    backgroundColor: "var(--info)",
                    color: "var(--lighterBg)",
                    textTransform: "none",
                    transition: "all 150ms linear",
                    borderRadius: "var(--buttonBorderRadius)",
                    padding: "7px 16px",
                    ":hover": {
                      backgroundColor: "var(--blueBrand)",
                      transition: "all 150ms linear",
                    },
                  }}
                  onClick={() => handleNextPage()}
                >
                  <ChevronRightIcon sx={{ marginRight: "5px" }} />
                  {Number(deductionCostTry) === 0.0 ? "İleri" : "Ödeme Yap"}
                </Button>
              </div>
            </Box>
          </Box>
        ) : (
          <Box
            sx={{
              backgroundColor: "var(--lighterBg)",
              borderRadius: "var(--inputBorderRadius)",
              padding: "20px",
              outline: 0,
              display: "grid",
              gap: "var(--gap)",
              width: "100%",
              maxWidth: "1100px",
              margin: "auto",
              position: "relative",
            }}
            style={{
              maxHeight: "90vh",
              overflowY: "auto",
            }}
          >
            <CancelOutlinedIcon
              sx={{
                position: "absolute",
                right: 1,
                top: 1,
                color: "var(--priceText)",
                cursor: "pointer",
                transition: "all 80ms linear",
                ":hover": {
                  color: "var(--notRed)",
                  transition: "all 80ms linear",
                },
              }}
              onClick={handleClose}
            />
            Sistem daha kendine gelemedi, sakin ol kardeşim.
          </Box>
        )}
      </Modal>
    </ThemeProvider>
  );
};

export default ReturnShipmentPriceInformation;
