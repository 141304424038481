import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
} from "@mui/material";

const SuccessPaymentNotDialog = ({ open, setOpen }) => {
  const handleClose = () => {
    setOpen(false);
  };

  return (
    <Dialog open={open} onClose={handleClose}>
      <DialogTitle
        id="alert-dialog-title"
        style={{
          display: "flex",
          flexDirection: "column",
          gap: "var(--gap2x)",
          alignItems: "center",
        }}
      >
        <svg
          width="149"
          height="150"
          viewBox="0 0 149 150"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M74.5471 144.597C112.698 144.597 143.625 113.396 143.625 74.9069C143.625 36.4182 112.698 5.21692 74.5471 5.21692C36.3962 5.21692 5.46875 36.4182 5.46875 74.9069C5.46875 113.396 36.3962 144.597 74.5471 144.597Z"
            stroke="#86B817"
            strokeWidth="10"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
          <path
            d="M43.8438 74.9069L66.8699 98.1369L105.247 59.4202"
            stroke="#86B817"
            strokeWidth="10"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
        </svg>
        <h5 style={{ color: "var(--text)", fontSize: "24px" }}>
          Ödeme Bildirimi Başarıyla Oluşmuştur
        </h5>
      </DialogTitle>
      <DialogContent>
        <DialogContentText
          id="alert-dialog-description"
          style={{ textAlign: "center", color: "var(--text)", fontSize: "16px" }}
        >
          Finans ekibimizin kontrolleri sonucunda bakiyeniz cüzdanınıza aktarılacaktır, bildirimleri
          sayfanın altında takip edebilirsiniz
        </DialogContentText>
      </DialogContent>
      <DialogActions style={{ justifyContent: "center" }}>
        <Button className="iyzi-button-blue" onClick={handleClose} style={{ padding: "5px 50px" }}>
          Tamam
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default SuccessPaymentNotDialog;
