const ProfileSettingsStyle = () => {
  const style = {
    backgroundColor: "var(--lighterBg)",
    padding: "20px 30px",
    borderRadius: "var(--wrapperBorderRadius)",
    maxWidth: "1400px",
    width: "100%",
    margin: "auto",
    boxShadow: "var(--shadow)",
  };

  return style;
};

export default ProfileSettingsStyle;
