import "../../style/termsModal/TermsModal.css";
import Box from "@mui/material/Box";
import Modal from "@mui/material/Modal";
import Fade from "@mui/material/Fade";
import { useRecoilState, useSetRecoilState } from "recoil";
import { registerModalState } from "../../atoms/registerTerms";
import { termsState } from "../../atoms/termsIsAccepted";
import { Link } from "react-router-dom";
import { Button } from "@mui/material";

const RegisterModal = () => {
  const [registerTerms, setRegisterTerms] = useRecoilState(registerModalState);

  const setTermsIsAccepted = useSetRecoilState(termsState);

  const handleTermsAccept = () => {
    setRegisterTerms(false);
    setTermsIsAccepted(true);
  };

  const handleTermsReject = () => {
    setRegisterTerms(false);
    setTermsIsAccepted(false);
  };

  const handleClose = () => {
    setRegisterTerms(false);
  };

  return (
    <Modal
      aria-labelledby="transition-modal-title"
      aria-describedby="transition-modal-description"
      open={registerTerms}
      onClose={handleClose}
      closeAfterTransition
      className="terms-modal-wrapper"
    >
      <Fade in={registerTerms}>
        <Box
          className="terms-modal"
          style={{
            maxHeight: "90vh",
            overflowY: "auto",
          }}
        >
          <main>
            <section className="policies">
              <article>
                <br />
                <h1>Kullanıcı (Üyelik) Sözleşmesi</h1>
                <p>
                  İş bu “Kullanıcı (Üyelik) Sözleşmesi” (“Sözleşme”), www.iyziShip.com yani bu web
                  sitesinin (“Site”) ve burada sunulan yazılımların ve hizmetlerin (“Hizmet(ler)”)
                  kullanımını düzenlemektedir.
                </p>
                <p>
                  Sözleşme, Kullanıcının (Üyenin) elektronik ortamda kabulü ile yürürlüğe girecek
                  olup; taraflarca Sözleşme ’de belirtilen usuller doğrultusunda sona erdirilmediği
                  sürece yürürlükte kalmaya devam edecektir. İş bu Sözleşme, üyenin münferit abone,
                  tek bir şirket veya kuruluş (“Üye(ler)”) olması fark etmeksizin, kullanıcı olan
                  Üye ve “İYZİ BİLİŞİM ANONİM ŞİRKETİ” kısaca markası ve web sayfa ismi ile
                  “iyziShip”) arasında gerçekleşen yasal bir sözleşmedir.
                </p>
                <p>
                  Kullanıcı (Üye), web sitemize üye olmakla birlikte, işbu sözleşme ile bağlı
                  olduğunu kabul etmiş olup Kullanıcı (Üyelik) Bilgilerinin, aşağıda ve web
                  sayfasında yer verilen KVKK ’da belirtilen şekilde toplanmasını ve kullanılmasını
                  da kabul etmiş sayılacaktır. <Link to="/kvkk">KVKK</Link>
                </p>
                <p>
                  Eğer kullanıcı (üye); bir şirket, kuruluş veya tüzel kişi adına bir hesap açıyor
                  veya kullanıyorsa: (i) iş bu sözleşmeyle bağlı olacak şekilde o kurumun yetkili
                  temsilcisi olduğunu; (ii) iş bu sözleşmeyi okuduğunu; (iii) anladığını (iv) tüzel
                  kişi adına onayladığını kabul ve taahhüt etmiş olacaktır.
                </p>
              </article>
              <article>
                <h4>1) Hizmetin Tanımı</h4>
                <p>
                  İşbu Site ve burada sunulan Hizmetler “İYZİ BİLİŞİM ANONİM ŞİRKETİ” ‘ne ( kısaca
                  markası ve web sayfa ismi ile “iyziShip” ) ait olup, perakendecilikle uğraşanlar
                  için sipariş karşılama ve gönderim sürecini kolaylaştıran web tabanlı bir nakliye
                  çözümüdür. Popüler pazarlara ve site üzerinde kamuya duyurulan alışveriş
                  platformlarına gerçek zamanlı entegrasyonlarla, sipariş alma ve toplu etiket
                  oluşturmadan müşteri iletişimine kadar her türlü faaliyeti gerçekleştirmektedir.
                  Gelişmiş özelleştirme seçenekleri, iyziShip ‘in işletmelere istenilen sayıda
                  kullanıcı veya konumla uyum sağlamasını destekler. iyziShip ’in yazılımını ve
                  yazılım dışında sunulan lojistik hizmetinden de faydalananlar “Üye” olarak
                  adlandırılmaktadır. Her üyenin aldığı hizmetlerin, ücret ve koşullar birbirinden
                  farklıdır.
                </p>
                <br />
                <p>
                  Yürütme, planlama, organize etme, iptal etme, yeni hizmet ekleme, veya var olan
                  hizmetleri sonlandırma vb. tüm süreçlere ilişkin hakları İYZİ BİLİŞİM ANONİM
                  ŞİRKETİ saklı kalmak üzere iyziShip Platformunda verilen temel ve ek hizmetler
                  aşağıdaki şekilde tanımlanmaktadır.
                  <br />
                  <br />
                  Sipariş Alma : iyziShip internet sitesi aracılığı ile kullanıcı (üye)’nin, bir
                  emtianın kendisine ulaştırılması veya kendisi namına belirli bir adresten alınması
                  için başvuruda bulunması amacıyla yaptığı başvuru sonucunda, iyziShip’in bu
                  emtianın ilgili noktadan fiziken teslim alınması için gerekli organizasyon ve
                  planlamaların tümünü,
                  <br />
                  <br />
                  Etiketleme/Toplu Etiketleme : iyziShip’in, bir kullanıcı (üye) talimatıyla,
                  fiziken aldığı gönderiye, etiket, barkod vb. materyallerin yapıştırılmasını,
                  <br />
                  <br />
                  Ambalajlama : iyziShip’in, bir kullanıcı (üye) talimatıyla fiziken telsim aldığı
                  gönderiyi belirli bir ücret karşılığında dış çevre koşulları (nem, sıcaklık,
                  soğukluk, ıslanma, hava teması) etkilenmeyecek şekilde dış paket-çevre ambalaj ile
                  kaplanması veya yeniden kaplanmasını,
                  <br />
                  <br />
                  Nakliye ve Taşıma : iyziShip’in; bir kullanıcı (üye) talebi doğrultusunda,
                  kendisine teslim edilen ve kendisinin teslim alması talep edilen yükün, kullanıcı
                  (üye)’nin bildirdiği varış noktasına ulaştırılması için 3. Taraf lojistik hizmet
                  sağlayıcılar aracılığı ile fiilen taşınması için gerekli tüm organizasyonu,
                  <br />
                  <br />
                  Depozito (iyziBakiye) : iyziShip’in, kullanıcılarına (üyelerine), sunduğu/sunacağı
                  hizmetler ve üyelik hizmetine ilişkin işbu sözleşme içerisinde Kullanıcı (Üye)
                  sorumluluğunda tanımlanan her türlü ücretin, Kullanıcı (Üye) tarafından kolaylıkla
                  geçekleştirilebilmesi amacıyla iyziShip tarafından oluşturulan önden üyenin
                  depozito bakiyeyi iyziShip hesaplarına göndermesi sonrası sanal ödeme yöntemini,
                  <br />
                  <br />
                  Gönderi Takibi : İyziship, bir kullanıcı (üye)’nin İyziship üzerinden nakliye ve
                  taşıma hizmetini aldığı durumda, oluşturulan gönderiye ilişkin 3. Taraf Lojistik
                  Hizmet Sağlayıcılar fiilen taşınan gönderilere ilişkin sağlanan gönderi kodu
                  vasıtasıyla kullanıcıya (üye)’ye, 3. Taraf Lojistik Hizmet Sağlayıcılar tarafından
                  gönderinin tabi tutulduğu lojistik süreç ve aşamaların iletilmesine aracılık
                  etmesini ifade etmektedir.
                  <br />
                  <br />
                  Fiyat Teklifi : İyziShip, kullanıcı (üye) ‘ya site’de ki Gönderi Oluştur ve
                  Entegre Gönderi Oluştur menülerinden kullanıcı (üye) ‘nin girmiş olduğu alıcı
                  adres, posta kodu ve gönderi ölçü ve ağırlığına göre teklifleri sunar. Bu fiyat
                  teklifi kullanıcı (üye) ‘nin verdiği bilgilere göre oluşan ve oluşturduğu zaman ki
                  maliyetlere göre verilmiş tekliftir. Ana taşıyıcı olan iyziShip’in
                  tedarikçilerinin fiyat güncellemesi durumunda, kullanıcı (üye) ’ye uygulanacak
                  olan satış fiyatı da güncellenecektir. Maliyet değişikliklerinde henüz iyziShip
                  Merkezinden tedarikçilere teslim edilmemiş gönderilerin satış ücretleri
                  güncellenir ve fiyat farkı varsa kullanıcı (üye) ‘den talep ve tahsil edilerek
                  gönderinin çıkışı ödeme tamamlandıktan sonra çıkış yapar.
                  <br />
                  <br />
                  Etiket Oluşturma ve Depo’ya Gönderme : Kullanıcı (üye) iyziShip web sayfasında
                  gönderi oluşturduğunda verilen teklif iyziShip Merkez ve/veya Depo ofisinden
                  çıkarak yurt dışında kullanıcı (üye) ‘nin belirttiği adrese teslimatı için
                  verilmiştir. Hazırlanan gönderi ve/veya gönderilerin kullanıcı(üye) ‘nın toplama
                  adresinden iyziShip Merkez ve/veya iyziShip Depo adresine ulaştırılması için 3.
                  Taraf lojistik hizmet sağlayıcılardan kullanıcı(üye) tarafından iyziShip ‘e ait
                  cari, müşteri numarası veya hesap numarası ile hizmet alırsa bu ilave hizmet 3.
                  Taraf lojistik hizmet sağlayıcılar tarafından iyziShip ‘e fatura edilir. Bu
                  nedenle oluşacak bu ilave hizmet için kullanıcı (üye) ‘ya fatura edilmek üzere ek
                  ücret oluşur. Kullanıcı (üye) oluşacak bu ek ücreti gönderi yurt dışı edilmeden
                  iyziShip’in belirlediği ücreti ödemeyi gönderi en başta gönderi oluşturduğunda
                  peşin olarak ödeyeceğini kabul ve taahhüt eder.
                  <br />
                  <br />
                </p>
                <p>
                  Kullanıcı (üye), Md.1.2. altında tanımlanan hizmetlerin kendisine sunulması için
                  sözleşme içerisinde belirtilen (Bkz. 3. Kullanıcı Hak ve Yükümlülükleri ve alt
                  maddeleri) yükümlülüklerini yerine getirmek zorundadır. Aksi durumda hizmet süreci
                  kısmen başlatılmış ise dahi, iyziShip tarafından tek taraflı şekilde
                  sonlandırılabilir.
                </p>
                <p>
                  İşbu sözleşme uyarınca, Kullanıcı (üye), Md.1.2. altında tanımlanan ve
                  faydalandığı hizmetlerin içeriklerini kabul etmiş sayılır. Gönderinin durumu
                  gereği, İlgili tanımlama haricinde işlemleri veya süreçlerin temini gerektirmesi
                  durumunda, bu işlem ve süreçlerin İyziship’ten talep edilmeyeceği veya
                  iyziShip’ten yerine getirilmeyeceğini kabul etmiş sayılır.
                </p>
              </article>
              <article>
                <h4>2) Site ve Hizmete Dair Genel Bilgiler</h4>
                <p>
                  2.1. iyziShip, site ’de ki bilgilerin doğruluğunu ve güncelliğini sürekli olarak
                  kontrol eder. Ancak, gösterilen tüm çabalara rağmen ortaya çıkan yazım, yazılım ve
                  sistem hatalarından iyziShip sorumlu değildir. Üye, Hizmet ’e erişimin muhtelif
                  zamanlarda engellenebileceğini ya da erişimin kesilebileceği kabul eder.
                </p>
                <p>
                  2.2. iyziShip, site’de yer alan veya alacak olan bilgileri, formları, içeriği ve
                  iş bu Kullanıcı(Üyelik) Sözleşmesi’ni dilediği zaman değiştirme hakkını saklı
                  tutmaktadır.
                </p>
                <p>
                  2.3. iyziShip, hesapları feshedebilir, Hizmete gönderilen tüm içeriği veya
                  bilgileri silebilir ve / veya herhangi bir sebepten dolayı Hizmet ’in (veya Hizmet
                  ’in herhangi bir kısmının veya özelliğinin) kullanımını veya erişimini herhangi
                  bir zamanda, kendi takdirine bağlı olarak, bildirimde bulunarak veya bulunmayarak
                  engelleyebilir. iyziShip ‘in herhangi bir sebepten ötürü Site ’de yer alan
                  herhangi bir hesabı (tamamen veya kısmen) feshetmesi kendi inisiyatifindedir.
                  Ayrıca, iyziShip, herhangi bir üye verisinin görüntülenmesini durdurma hakkı da
                  dahil olmak üzere Hizmet ’in herhangi bir yönünü durdurma hakkını saklı tutar.
                </p>
                <p>
                  2.4. Site diğer web sitelerine bağlantılar içerebilir ("Bağlantılı Siteler").
                  Bağlantılı Sitelerde, iyziShip ‘in herhangi bir kontrolü ve sorumluluğu
                  bulunmamaktadır.
                </p>
                <p>
                  2.5. Taşıma, Nakliye ve Depolama Hizmetleri kapsamında, Türk Ticaret Kanunu ve/
                  veya ilgili ülke mevzuatları ile birlikte lojistik ve taşıma süreçlerinde uygulama
                  alanı bulan uluslararası mevzuatlar uyarınca yasaklanmış bulunan ürünlerin
                  gönderilmesi talepleri karşılanmayacak olup iyziShip ‘in söz konusu hallerde
                  Siteye ve ilgili hizmetlere erişimi engelleme hakkı bulunmaktadır.
                </p>
                <p>
                  2.5.1. iyziShip’e kullanıcı tarafından teslim edilen bir paket içeriğinde,
                  herhangi bir mevzuat açısından yasaklı içerik olduğu taşıma-nakliye-depolama ve
                  tüm lojistik süreç içerisindeki herhangi bir aşamada tespit edilen gönderiler
                  sebebiyle doğabilecek herhangi bir cezai durumda iyziShip’in sorumluluğu olmayıp;
                  kullanıcı (üye) gönderdiği ürün ve ürüne ilişkin beyanının yasa dışı olmasından
                  doğacak tüm sorumlulukları kabul etmiş sayılır.
                </p>
                <p>
                  2.5.2. iyziShip tarafından; işbu kullanıcı sözleşmesi kapsamında Md. 1.2.
                  içeriğinde tanımlanan temel ve ek faaliyet alanlarına konu olacak gönderilerin
                  içeriğine ilişkin, kendisine teslim anı veya sonrasında kontrol yükümlülüğü,
                  zorunluluğu bulunmamaktadır. Kullanıcı (üye), iyziShip’in bu şekilde bir
                  yükümlülüğü olmadığını ve gönderiye ilişkin içerik beyanlarından doğacak her türlü
                  olumsuz sonucu kabul etmiş sayılır.
                </p>
                <p>
                  2.6. iyziShip, verdiği hizmet ile Kullanıcı (Üye)’ye taahhüt ettiği hizmetleri
                  yerine getirirken gönderide meydana gelecek zarar (kısmi ve/veya tam zayi, hasar,
                  gecikme vb.) veya herhangi bir hizmet aksaklığından kaynaklanan taraflar arası
                  ihtilafta, öncelikli olarak ilgili nakliye-taşıma sürecinde Türkiye
                  Cumhuriyeti’nin taraf olduğu uluslararası mevzuat uygulanacak olup; bu mevzuat
                  kapsamında bulunmayan hallerde ulusal mevzuat uygulanacaktır.
                </p>
                <p>
                  2.7. iyziShip, üye tarafından kendisi ile paylaşılan bilgi ve verileri işbu
                  Sözleşme ’ye ek niteliğinde olan “Gizlilik Politikası” kapsamında saklayacak ve
                  kullanacaktır. Üye, iyziShip ’in yürürlükteki mevzuat uyarınca yetkili makamlardan
                  talep gelmesi halinde üye ’nin kendisinde bulunan bilgilerini ilgili yetkili
                  makamlarla paylaşabileceğini kabul eder.
                </p>
                <p>
                  2.8. iyziShip, üyeyi bilgilendirmeden gerekli gördüğü sistem değişikliklerini,
                  güncellemelerini ve kısıtlamalarını değiştirme hakkını saklı tutar.
                </p>
              </article>
              <article>
                <h4>3) Kullanıcının Hak ve Yükümlülükleri</h4>
                <p>
                  3.1. Kullanıcı ( üye) siteyi kullanırken iyziShip’in sunmakta olduğu hizmetlere
                  ilişkin olarak, yayınlayacağı bildirimlere ve başta Türk Ceza Kanunu, Türk Borçlar
                  Kanunu, Türk Medeni Kanunu, Türk Ticaret Kanunu, Fikir ve Sanat Eserleri Kanunu,
                  Marka ve Patent Mevzuatı olmak üzere her türlü yasal düzenlemeye uygun davranmayı,
                  aykırı kullanım halinde hukukî, cezaî ve idarî her türlü sorumluluğun kullanıcıya
                  ait olacağını beyan, kabul ve taahhüt eder.
                </p>
                <p>
                  3.2. Kullanıcı internet sitesini genel ahlaka aykırı, kamu düzenini bozmaya
                  yönelik, başkalarını rahatsız edici şekilde yasalara aykırı bir amaç uğruna
                  kullanamaz.
                </p>
                <p>
                  3.3 Kullanıcı işbu sözleşme ile üstlenmiş olduğu yükümlülüklere ve kullanım
                  şartlarına aykırı hareketi nedeniyle iyziShip’in uğrayacağı her türlü zararı aynen
                  tazmin etmeyi, kullanıcının sözleşmeye aykırı davranışları nedeniyle iyziShip’in
                  kamu kurumlarına ve/veya üçüncü şahıslara ödemek zorunda kalabileceği her türlü
                  tazminat ve/veya idari, adli para cezaları için Kullanıcıya aynen rücû hakkı
                  bulunduğunu beyan, kabul ve taahhüt eder.
                </p>
                <p>
                  3.4 18 yaşından küçük kişiler İnternet Sitesinden hizmet alamazlar. Kullanıcı,
                  .iyziShip’ e giriş yapmakla ve/veya üye olmakla 18 yaşından büyük olduğunu beyan
                  ve kabul eder.
                </p>
                <p>
                  3.5Üye, Kullanıcı (Üyelik) hesabını ve işbu Sözleşme ile Site kullanımından doğan
                  hak ve yükümlülüklerini herhangi bir şekilde üçüncü bir kişiye devir veya temlik
                  edemez. iyziShip ’in sunduğu Hizmetler’den yararlananlar ve Site 'yi kullananlar,
                  yalnızca hukuka uygun ve şahsi/kurumsal amaçlarla Site üzerinden işlem
                  yapabilirler. Üye 'nin, Site kapsamında gerçekleştirdiği her işlem ve eylemdeki
                  hukuki ve cezai sorumluluk kendisine aittir.
                </p>
                <p>
                  3.6. Üye tarafından Site ’ye erişim e-posta adresi ve şifresi kullanılarak
                  gerçekleştirilecektir. Bu şifrenin gizliliğinin ve güvenliğinin korunmasından üye
                  sorumlu olacak olup Site üzerinden söz konusu bilgilerin kullanımı ile
                  gerçekleştirilen her türlü faaliyetin üye tarafından gerçekleştirildiği kabul
                  edilecek ve aynı zamanda bu faaliyetlerden doğan her türlü yasal ve cezai
                  sorumluluk üye ’ye ait olacaktır. Üye, şifresinin yetkisiz kullanımı veya
                  güvenliğin başka şekilde ihlalinden haberdar olduğunda bu durumu derhal iyziShip
                  ’e bildirecektir. Üye, sadece tanıtım, reklam, kampanya, promosyon, duyuru vb.
                  pazarlama faaliyetleri kapsamında kullanılması ile sınırlı olmak üzere, iyziShip
                  ’in, kendisine ait iletişim, portföy durumu ve demografik bilgilerini iştirakleri
                  ya da bağlı bulunduğu şirketler ile paylaşmasına muvafakat ettiğini kabul ve beyan
                  eder. Bu kişisel bilgiler iyziShip bünyesinde müşteri profili belirlemek, müşteri
                  profiline uygun promosyon ve kampanyalar sunmak ve istatistiksel çalışmalar yapmak
                  amacıyla kullanılabilecektir. Üye, içerik ve diğer bilgilerin iyziShip veya üçüncü
                  kişiler tarafından Türkiye’de veya yurt dışında bulunan veri merkezlerinde
                  saklanabileceğini kabul eder.
                </p>
                <p>
                  3.7. Üye kendisine site içerisinde bildirilen gönderi ve/veya paket ve/veya ürün
                  takip numarası ile gönderisini ve/veya paketini ve/veya ürününü takip etmek ve
                  herhangi bir durumda ( Adres hatası, gümrüklü işlem dolayısı ile gecikme ve
                  benzeri) gerek alıcı gerek iyziShip ile yazılı iletişime geçerek konunun çözümü
                  için bilgi vereceğini ve gönderisini yurtdışına taşıyan ana taşıyıcılara ( DHL,
                  UPS, Fedex, TNT, USPS, Royal Mail, Deutschepost, GLS, DPD ve benzeri alanda ki
                  diğer firmalara ) ait web sayfalarından bizzat takip etmek ile sorumludur. Üye,
                  gerek kendi gerek gönderinin nihai alıcısının takipsizlik iletişimsizliği sonucu
                  oluşacak herhangi bir masraf veya durumdan iyziShip ‘i sorumlu tutmayacağını kabul
                  eder.
                </p>
                <p>
                  3.8 Web sitesine hatalı, eksik ve yanıltıcı, genel ahlak kuralları ile kamu
                  düzenine ve Türkiye Cumhuriyeti hukuk kurallarına aykırı bilgiler ve ifadeler
                  girildiğinin, site ve işletim sisteminin güvenliğini tehdit edici nitelikte
                  faaliyete girişildiğinin, site içeriğindeki bilgilerin değiştirilmeye, silinmeye
                  çalışıldığının ve buna benzer durumların tespit edilmesi halinde, kullanıcının Web
                  sitesine erişim hakkı iyziShip tarafından engellenebilecektir. Bu gibi
                  faaliyetlere karışan kişi ve kurumlar hakkında iyziShip’in sözleşmeyi tek taraflı
                  olarak feshetme hakkı dahil olmak üzere her türlü hukukî hakkı saklı
                  tutulmaktadır.
                </p>
              </article>
              <article>
                <h4>4) Fikri Mülkiyet Hakları</h4>
                <p>
                  4.1. Üye, Site ’de ve verilen Hizmet ’te tüm fikri mülkiyet haklarının iyziShip ’e
                  ait olduğunu kabul ve beyan eder.
                </p>
                <p>
                  4.2. İşbu Kullanıcı(Üyelik) Sözleşmesi dahilinde iyziShip tarafından sarahaten
                  izin verilen durumlar haricinde, üyeler, iyziShip ’in telif haklarına tabi
                  çalışmalarını çoğaltamaz, işleyemez, dağıtamaz veya bunlardan türemiş çalışmalar
                  yapamaz veya hazırlayamaz.
                </p>
                <p>
                  4.3 Bu site üzerinde sunulan metin ve grafik dosyaları Copyright ve/veya müseccel
                  marka kanunlarıyla ya da başka herhangi bir yolla iyziShip ‘e ait tescilli tasarım
                  olarak korunmaktadır. Bu sebeple internet sitesi ve uzantılarında yer alan ve
                  yukarıda sayılı hiçbir metin ya da görsel materyal dahil hiçbir unsur önceden
                  izinsiz alınmaksızın kullanılamaz. Bu sitede yer alan hiçbir materyal ve
                  fonksiyon, iyziShip ‘in izni olmadan, başka mecralarda yayınlanamaz, kopyalanamaz,
                  çoğaltılamaz, değiştirilemez, tekrar üretilemez, başka bir lisansa çevrilemez,
                  posta ile gönderilemez, bilgisayara yüklenip her ne amaçla olursa olsun
                  kullanılamaz, link veremez, başka internet sitelerinde kullanılamaz. Bu sayılanlar
                  dışında bulunan diğer tüm haklar saklı tutulmakla beraber, bu sayılanlara aykırı
                  hareket edilmesi halinde iyziShip her türlü hukukî ve cezaî yola başvurma hakkına
                  sahiptir.
                  <br />
                  <br />
                  Kullanıcılar, yukarıda sayılan ve bunlarla sınırlı olmayan Web sitesi yazılım,
                  donanım ve içeriğini çoğaltmayacağını, kopyalamayacağını, dağıtmayacağını,
                  işlemeyeceğini, gerek bu eylemleri ile gerekse de başka yollarla iyziShip ile
                  doğrudan ve/veya dolaylı olarak rekabete girmeyeceğini kabul ve taahhüt
                  etmektedir. Kullanıcı, iyziShip hizmetlerini, bilgilerini ve telif haklarına tâbi
                  çalışmalarını yeniden satmak, işlemek, paylaşmak, dağıtmak, sergilemek veya
                  başkasının iyziShip hizmetlerine erişmesi veya kullanmasına izin vermek hakkına
                  sahip değildir.
                </p>
              </article>
              <article>
                <h4>5) Mücbir Sebepler</h4>
                <p>
                  iyziShip, hukuken mücbir sebep sayılan tüm durumlarda, işbu sözleşme içeriğinden,
                  sözleşmede belirtilen temel ve ek faaliyetlerden ve gizlilik politikasından doğan
                  yükümlülüklerinden herhangi birini tam olarak ve zamanında ifa edememekten dolayı
                  sorumlu değildir. Böyle bir hususun varlığı halinde iyziShip ’in herhangi bir
                  tazminat sorumluluğu doğmayacaktır.
                </p>
              </article>
              <article>
                <h4>6) Ücretler ve Vergiler</h4>
                <p>
                  Üyelik dahilinde üyeye özel tanımlanan ücretler, iyziShip ’e abone olan üyeler
                  için geçerli olacak olup, iyziShip farklı ücretler belirlemek hakkını haizdir.
                </p>
                <p>
                  6.1. İşbu sözleşmenin 1. Maddesi içeriğinde sayılan temel ve ek hizmetler ile
                  tarafların bunlar dışında mutabık kaldığı diğer hizmetlere ilişkin, ödeme ve vergi
                  gibi ücretlerin geçerli bir ödeme yöntemiyle ve zamanında ödenmesinden Kullanıcı
                  (Üye) sorumludur. Aksi belirtilmedikçe, tüm ücretler ABD(Amerikan)
                  Doları, AVRO(Euro) ve POUND (İngiliz Poundu) para cinsindendir. Ve ödemenin
                  yapılacağı gün ki efektif satış döviz kuru ile üye tarafından ödenir. Satış Türk
                  Lirası üzerinden yapılır. Tüm ödemeler Hizmet içerisinde belirtilen yöntemlerle
                  yapılmalıdır. Tüm ücretler ve masraflar tarafımızdan alındıktan sonra kazanılır ve
                  (a) açıkça belirtildiği durumlarda ve / veya (b) yürürlükteki yasaların
                  gerektirdiği haller dışında, iade edilemez.
                </p>
                <p>
                  6.2. Kullanıcı (Üye) satış, kullanım veya katma değer vergisi gibi Hizmet 'i
                  kullanmasına dair öngörülen herhangi bir devlet vergisini ödemekle yükümlüdür.
                  iyziShip 'in bu tür vergileri tahsil etmek zorunda olduğu ölçüde, geçerli vergi,
                  faturalandırma hesabına eklenecektir ve üyeden tahsil edilecektir. iyziShip,
                  yurtdışından iade olarak gelen gönderi navlun ücretlerini, 3. Taraf Lojistik
                  Hizmet Sağlayıcıları firmaların sonradan gönderi ile ilgili iyziShip ‘e fatura
                  ettiği ek ücretleri, Türkiye veya yurtdışında oluşan vergi, ceza vb. tüm
                  masrafları üyeden tahsil edecektir. Üye bu tahsilatı en başından gönderiyi
                  yurtdışına gönderirken kabul etmiş sayılmaktadır ve üye’nin{" "}
                  <Link to={"/"}>iyziShip</Link> web sayfası içinde kendine özel panelinde kendisine
                  tebliğ edildiği gün itibari ile 15 gün içinde ödeyeceğini kabul ve taahhüt eder.
                </p>
                <p>
                  6.3. Kullanıcı (Üye)’nin iyziBakiye ‘e yaptığı para ve bakiye yüklemeleri önden
                  ödeme (depozito) niteliğindedir. iyziShip’in sunduğu hizmet sonrası, alacağı
                  oluşması durumunda tüm alacak bakiyesini üye ’ye ait olan iyziBakiye özelliğinde
                  bulunan fazla bakiyeden tahsil etme hakkına sahiptir, üye bu tahsilat için
                  iyziShip ’e sözleşmenin kabul edildiği andan itibaren yetki vermiş sayılır.
                </p>
                <p>
                  6.4. Kullanıcı (Üye)’ye ait olan iyziBakiye yani önden ödediği depozito bakiye
                  özelliğinde borcuna karşılık yeterli miktar bulunmaması durumunda kredi kartı,
                  eft/havale veya nakit olarak ödeyeceğini taahhüt etmiştir.
                </p>
                <p>
                  6.5. iyziShip, Kullanıcı (üye)’nin iyziBakiye hizmetinden faydalandığı durumlarda,
                  işbu sözleşme içeriğinde 6.1. ve 6.2. maddelerinde belirtilen ödemeleri
                  Kullanıcı(Üye)’ye önceden bildirim yapmak kaydıyla, Kullanıcı (Üye)’ye ait
                  iyziBakiye ‘de ki Bakiyesinden tahsil etme hakkına sahiptir.
                </p>
                <p>
                  6.6. Seçilen ödeme yöntemi hesabını tahsil etme yetkisi, Hizmet içindeki tercihler
                  iptal edinceye veya değiştirilinceye dek yürürlükte kalacaktır; Ancak, böyle bir
                  bildirim, iyziShip 'in makul bir şekilde harekete geçmesinden önce sunulan
                  ücretleri etkilemeyecektir.
                </p>
                <p>
                  6.7. iyziShip, Hizmet için herhangi bir ücreti, tarifeyi, ücret sınırını veya
                  miktarını değiştirme ve üyelerine önceden bildirimde bulunarak yeni ücret veya
                  şartlar oluşturma hakkını saklı tutar. Herhangi bir üyelik süresi için ücretler
                  peşin olarak ödenir ve tamamen veya kısmen iade edilmez.
                </p>
                <p>
                  6.8. Hizmet iptal edilmediği müddetçe aylık üyelik her ayın sonunda, yıllık üyelik
                  ise her yıl dönümü bitiminde otomatik olarak yenilenecektir.
                </p>
                <p>
                  6.9. Yıllık üyelikler için, yıllık yenileme tarihinden en az 30 gün önce,
                  aboneliğin iptal edilmek istendiği bildirilmelidir. Aylık üyelikler için ise yedi
                  (7) gün öncesinde bildirim yapmak gerekmektedir.
                </p>
                <p>
                  6.10. Kullanıcı (Üye) herhangi bir sebepten ötürü (Yasalara aykırı içerik veya alt
                  tedarikçi kurye ve/veya lojistik depolama firmalarının kararları nedeni ile)
                  yurtdışına çıkartılamamış gönderiler ile ilgili; Kullanıcı (Üye)’den iyziShip ’in
                  adresine ulaştırılması esnasında oluşan her türlü lojistik hizmet (taşıma,
                  depolama, kiralama, araç temini, elleçleme, yükleme-boşaltma vb.) ve buna ilave
                  varsa bu süreçte oluşan cezai yaptırımları ödeyeceğini peşinen kabul etmiştir. Bu
                  süreçte oluşacak Lojistik Hizmet Organizasyonundan doğan ücretlerin tutarlarını
                  belirleme hakkını iyziShip kendisinde saklı tutar.
                </p>
              </article>
              <article>
                <h4>7) Sözleşmenin Sonlandırılması</h4>
                <p>
                  7.1. Taraflardan herhangi biri, diğer tarafça bildirilen elektronik posta adresine
                  1 (bir) hafta önceden yapacağı yazılı bir bildirimle işbu Sözleşme’ yi dilediği
                  zaman herhangi bir gerekçe göstermeksizin ve tazminat ödemeksizin
                  feshedebilecektir.
                </p>
                <p>
                  7.2. İşbu Sözleşme’nin herhangi bir hükmünün veya sözleşmede yer alan herhangi bir
                  ifadenin geçersizliği, yasaya aykırılığı ve uygulanamazlığı, Sözleşme’nin geri
                  kalan hükümlerinin yürürlüğünü ve geçerliliğini etkilemeyecektir.
                </p>
                <p>
                  7.3. İşbu Sözleşme’nin uygulanmasından veya yorumlanmasından doğacak her türlü
                  uyuşmazlığın çözümünde İstanbul Çağlayan Adliyesi Mahkemeleri ve İcra Daireleri
                  yetkilidir.
                </p>
              </article>
              <article>
                <h4>8) Tebligat</h4>
                <p>
                  8.1. Kullanıcının iyziShip ’e bildirdiği elektronik posta adresi, bu sözleşme ile
                  ilgili olarak yapılacak her türlü bildirim için yasal adresin isteneceği
                  elektronik posta olarak kabul edilir. Bu elektronik posta adresinin kullanılarak
                  Kullanıcıya yapılan her türlü bildirimin Kullanıcıya postanın gönderilmesinden 1
                  gün sonra ulaştığı kabul edilir.
                </p>
                <p>
                  8.2. Taraflar, mevcut elektronik postalarındaki değişiklikleri yazılı olarak diğer
                  tarafa 5 (beş) gün içinde bildirmedikçe, eski elektronik postalara yapılacak
                  isteklerin geçerli olacağını ve kendilerine yapılmış sayılacağını kabul ederler.
                </p>
                <p>
                  8.3. Kullanıcı, bu kullanıcı sözleşmesinde yer alan maddelerin tümünü okuduğunu,
                  anladığını, kabul ettiğini ve kendisiyle ilgili olarak verdiği bilgilerin
                  doğruluğunu onayladığını, bu onaylamadan sonra kendisine gönderilen tüm ticarî
                  içerikli gönderilere muvafakati bulunduğunu beyan, kabul ve taahhüt eder.
                </p>
              </article>
              <article>
                <h4>9) Ekler</h4>
                <p>
                  Dönem dönem iyziShip tarafından Web sitesinde kullanıcı ilişkileri ile iyziShip’
                  in mal ve hizmetlerine ilişkin olarak yayınlarak kullanıcılara duyurulan çeşitli
                  metinler ile halihazırda web sitesinde yayınlanmakta olan tüm metinler işbu
                  sözleşmenin eki ve ayrılmaz parçasıdır. İşbu Sözleşme’nin tarafı olan her
                  Kullanıcı, web sitesinde yer alan tüm metinleri okuduğunu anladığını kabul ve
                  taahhüt eder.
                </p>
              </article>
            </section>
          </main>
          <footer>
            <Button className="iyzi-button-red" onClick={handleTermsReject}>
              Kabul Etmiyorum
            </Button>
            <Button
              style={{ backgroundColor: "var(--success)" }}
              className="iyzi-button-blue"
              onClick={handleTermsAccept}
            >
              Kabul Ediyorum
            </Button>
          </footer>
        </Box>
      </Fade>
    </Modal>
  );
};

export default RegisterModal;
