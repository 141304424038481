import {
  Box,
  Button,
  Card,
  CardContent,
  FormControl,
  InputAdornment,
  InputLabel,
  MenuItem,
  Select,
  Collapse,
  createTheme,
  TextField,
  ThemeProvider,
} from "@mui/material";
import { useCallback, useEffect, useState } from "react";
import ReturnShipmentDetailedSearch from "./ReturnShipmentDetailedSearch";
import SearchIcon from "@mui/icons-material/Search";
import {
  awbState,
  receiverCountryCodeState,
  receiverState,
  refIdState,
  selectedExportStatusState,
  selectedDomesticStatusState,
  selectedCaseStatusState,
  selectedPriceStatusState,
  selectedSupplierState,
  senderCountryCodeState,
  senderState,
  shipmentStatusState,
  timeState,
} from "../../atoms/myShipments";
import { useRecoilState, useRecoilValue } from "recoil";
import axios from "../../api/axios";
import { useLocation } from "react-router-dom";
import {
  returnShipmentListLoadingState,
  returnShipmentListState,
  returnShipmentListLimitState,
  returnShipmentListOrderDirectionState,
  returnDetailedStatusState,
  returnBasicSearchErrorState,
  returnBasicSearchState,
  returnTotalPagesState,
  returnErrorSetState,
  returnShipmentListCurrentPageState,
  returnShipmentsListArrayLengthState,
} from "../../atoms/returnShipment";
import Errors from "../../utils/Errors";

const theme = createTheme({
  components: {
    MuiPaper: {
      styleOverrides: {
        root: {
          borderRadius: "10px",
          boxShadow: "none",
        },
      },
    },
  },
});

const ReturnShipmentSearch = () => {
  const [expanded, setExpanded] = useState(false);
  const handleExpandClick = () => {
    setExpanded(!expanded);
  };

  const [returnBasicSearch, setReturnBasicSearch] = useRecoilState(
    returnBasicSearchState
  );
  const [returnShipmentList, setReturnShipmentList] = useRecoilState(
    returnShipmentListState
  );
  const [, setReturnShipmentListLoading] = useRecoilState(
    returnShipmentListLoadingState
  );
  const [, setReturnDetailedStatus] = useRecoilState(returnDetailedStatusState);
  const [, setDeductionCostTryPages] = useRecoilState(returnTotalPagesState);
  const [returnBasicSearchError, setReturnBasicSearchError] = useRecoilState(
    returnBasicSearchErrorState
  );
  const [returnErrorSet, setReturnErrorSet] =
    useRecoilState(returnErrorSetState);
  const returnShipmentListLimit = useRecoilValue(returnShipmentListLimitState);
  const returnShipmentOrderDirectionPage = useRecoilValue(
    returnShipmentListOrderDirectionState
  );

  const [shipmentStatus, setShipmentStatus] =
    useRecoilState(shipmentStatusState);
  const [, setReturnShipmentsListArrayLength] = useRecoilState(
    returnShipmentsListArrayLengthState
  );

  const awb = useRecoilValue(awbState);
  const refId = useRecoilValue(refIdState);
  const sender = useRecoilValue(senderState);
  const receiver = useRecoilValue(receiverState);
  const time = useRecoilValue(timeState);
  const senderCountryCode = useRecoilValue(senderCountryCodeState);
  const receiverCountryCode = useRecoilValue(receiverCountryCodeState);
  const selectedSupplier = useRecoilValue(selectedSupplierState);
  const selectedPriceStatus = useRecoilValue(selectedPriceStatusState);
  const selectedExportStatus = useRecoilValue(selectedExportStatusState);
  const selectedDomesticStatus = useRecoilValue(selectedDomesticStatusState);
  const selectedCaseStatus = useRecoilValue(selectedCaseStatusState);
  const detailedStatus = useRecoilValue(returnDetailedStatusState);
  const returnShipmentListCurrentPage = useRecoilValue(
    returnShipmentListCurrentPageState
  );

  const location = useLocation();

  // Parametreleri al
  const searchParams = new URLSearchParams(location.search);
  const awbParam = searchParams.get("awb");

  const handleBasicSearch = useCallback(async () => {
    setReturnShipmentListLoading(true);
    setReturnDetailedStatus(0);

    await axios
      .get(
        `parcel-return?order_direction=${returnShipmentOrderDirectionPage}&limit=${returnShipmentListLimit}&page=${1}&search=${returnBasicSearch}`
      )
      .then((response) => {
        setReturnShipmentList(response.data.data);
        setDeductionCostTryPages(response.data.totalPages);
        setReturnShipmentListLoading(false);

        if (returnBasicSearch === "") {
          setReturnErrorSet(true);
          setReturnBasicSearchError(false);
        }
      })
      .catch((error) => {
        Errors(error);
        setReturnShipmentListLoading(false);
      });
  }, [
    setDeductionCostTryPages,
    setReturnShipmentList,
    setReturnShipmentListLoading,
    setReturnDetailedStatus,
    setReturnBasicSearchError,
    setReturnErrorSet,
    returnShipmentListLimit,
    returnShipmentOrderDirectionPage,
    returnBasicSearch,
  ]);

  const handleClearBasicSearch = () => {
    setReturnBasicSearch("");
  };

  const handleDetailedSearch = useCallback(
    async (shipmentStatusValue) => {
      setReturnShipmentListLoading(true);
      setReturnDetailedStatus(1);

      await axios
        .get(
          `parcel-return?order_direction=${returnShipmentOrderDirectionPage}&limit=${returnShipmentListLimit}&page=${1}&tracking_awb=${awb}&parcel_ref_no=${refId}&supplier_id=${
            selectedSupplier !== ""
              ? Number(selectedSupplier)
              : selectedSupplier
          }&status=${shipmentStatusValue}&tracking_awb_date=${time}&sender_name=${sender}&receiver_name=${receiver}&sender_country=${senderCountryCode}&receiver_country=${receiverCountryCode}&parcel_type=${selectedExportStatus}&paid=${selectedPriceStatus}&is_domestic=${selectedDomesticStatus}&is_case=${selectedCaseStatus}&detailed=${1}`
        )
        .then((response) => {
          setReturnShipmentList(response.data.data);
          setDeductionCostTryPages(response.data.totalPages);
          setReturnShipmentListLoading(false);
          setReturnErrorSet(true);
          setReturnBasicSearchError(false);
        })
        .catch((error) => {
          Errors(error);
          setReturnShipmentListLoading(false);
        });
    },
    [
      setReturnShipmentList,
      setReturnShipmentListLoading,
      setReturnDetailedStatus,
      setDeductionCostTryPages,
      setReturnErrorSet,
      setReturnBasicSearchError,
      awb,
      time,
      returnShipmentOrderDirectionPage,
      returnShipmentListLimit,
      refId,
      selectedSupplier,
      sender,
      senderCountryCode,
      receiver,
      receiverCountryCode,
      selectedExportStatus,
      selectedDomesticStatus,
      selectedCaseStatus,
      selectedPriceStatus,
    ]
  );

  const handleAwbSearch = useCallback(async () => {
    setReturnShipmentListLoading(true);
    setReturnDetailedStatus(0);

    await axios
      .get(
        `parcel-return?order_direction=${returnShipmentOrderDirectionPage}&limit=${returnShipmentListLimit}&page=${1}&search=${awbParam}`
      )
      .then((response) => {
        setReturnShipmentList(response.data.data);
        setDeductionCostTryPages(response.data.totalPages);
        setReturnShipmentListLoading(false);
      })
      .catch((error) => {
        Errors(error);
        setReturnShipmentListLoading(false);
      });
  }, [
    setDeductionCostTryPages,
    setReturnShipmentList,
    setReturnShipmentListLoading,
    setReturnDetailedStatus,
    returnShipmentListLimit,
    returnShipmentOrderDirectionPage,
    awbParam,
  ]);

  const getShipment = useCallback(async () => {
    setReturnShipmentListLoading(true);

    await axios
      .get(
        detailedStatus === "1"
          ? `parcel-return?order_direction=${returnShipmentOrderDirectionPage}&limit=${returnShipmentListLimit}&page=${returnShipmentListCurrentPage}&tracking_awb=${awb}&parcel_ref_no=${refId}&supplier_id=${
              selectedSupplier !== ""
                ? Number(selectedSupplier)
                : selectedSupplier
            }&status=${shipmentStatus}&tracking_awb_date=${time}&sender_name=${sender}&receiver_name=${receiver}&sender_country=${senderCountryCode}&receiver_country=${receiverCountryCode}&parcel_type=${selectedExportStatus}&paid=${selectedPriceStatus}&is_domestic=${selectedDomesticStatus}&is_case=${selectedCaseStatus}&detailed=1`
          : `parcel-return?order_direction=${returnShipmentOrderDirectionPage}&limit=${returnShipmentListLimit}&page=${returnShipmentListCurrentPage}`
      )
      .then((response) => {
        setReturnShipmentList(response.data.data);
        setDeductionCostTryPages(response.data.totalPages);
        setReturnShipmentListLoading(false);
        setReturnShipmentsListArrayLength(response.data.data.length);
      })
      .catch((error) => {
        Errors(error);
        setReturnShipmentListLoading(false);
      });
  }, [
    setReturnShipmentList,
    setReturnShipmentListLoading,
    setReturnShipmentsListArrayLength,
    setDeductionCostTryPages,
    returnShipmentListLimit,
    returnShipmentListCurrentPage,
    returnShipmentOrderDirectionPage,
    time,
    senderCountryCode,
    receiverCountryCode,
    selectedSupplier,
    selectedPriceStatus,
    selectedExportStatus,
    selectedDomesticStatus,
    selectedCaseStatus,
    awb,
    refId,
    sender,
    receiver,
    shipmentStatus,
    detailedStatus,
  ]);

  useEffect(() => {
    if (returnShipmentList.length === 0 && !returnErrorSet) {
      setReturnBasicSearchError(true);
    }

    if (returnShipmentList.length !== 0) {
      setReturnErrorSet(false);
    }
  }, [
    returnShipmentList,
    returnErrorSet,
    setReturnBasicSearchError,
    setReturnErrorSet,
  ]);

  useEffect(() => {
    if (awbParam !== null) {
      handleAwbSearch();
    } else {
      getShipment();
    }
  }, [awbParam, handleAwbSearch, getShipment]);

  return (
    <ThemeProvider theme={theme}>
      <Box className="returnShipment-search">
        <Card className="search-card">
          <CardContent className="search-card-content">
            <Box sx={{ display: "flex", gap: "var(--gap)", width: "100%" }}>
              <TextField
                className="search-card-content-search"
                label="İade Arama"
                placeholder="İade Numarası, İzleme Numarası, Tedarikçi İsmi, Alıcı İsmi, Gönderici İsmi"
                value={returnBasicSearch}
                error={returnBasicSearchError}
                autoComplete="off"
                helperText={
                  returnBasicSearchError
                    ? "Arama kriterlerinizi değiştirip yeniden deneyiniz."
                    : ""
                }
                onChange={(e) => setReturnBasicSearch(e.target.value)}
                onKeyDown={(e) =>
                  e.key === "Enter"
                    ? handleBasicSearch()
                    : e.key === "Escape"
                    ? handleClearBasicSearch()
                    : ""
                }
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      <SearchIcon />
                    </InputAdornment>
                  ),
                }}
                size="small"
              />
            </Box>
            <Box
              className="search-wrapper"
              sx={{ display: "flex", gap: "var(--gap)" }}
            >
              <FormControl
                className="search-card-content-status"
                size="small"
                sx={{ minWidth: 200 }}
              >
                <InputLabel
                  sx={{ fontSize: "14px" }}
                  id="detailed-search-label"
                >
                  İade Durum Bilgisi
                </InputLabel>
                <Select
                  labelId="detailed-search-label"
                  id="detailed-search"
                  label="İade Durum Bilgisi"
                  sx={{ fontSize: "14px", display: "flex", minHeight: "40px" }}
                  value={shipmentStatus}
                  onChange={(e) => setShipmentStatus(e.target.value)}
                >
                  <MenuItem onClick={() => handleDetailedSearch("")} value={""}>
                    Tümü
                  </MenuItem>
                  <MenuItem onClick={() => handleDetailedSearch(0)} value={0}>
                    Gönderi Oluşturuldu
                  </MenuItem>
                  <MenuItem onClick={() => handleDetailedSearch(1)} value={1}>
                    iyziMerkez'e Geliyor
                  </MenuItem>
                  <MenuItem
                    onClick={() => handleDetailedSearch(2.6)}
                    value={2.6}
                  >
                    iyziMerkez'de Kilo Kontrol
                  </MenuItem>
                  <MenuItem
                    onClick={() => handleDetailedSearch(2.12)}
                    value={2.12}
                  >
                    Ödeme Bekliyor
                  </MenuItem>
                  <MenuItem
                    onClick={() => handleDetailedSearch(3.2)}
                    value={3.2}
                  >
                    Çıkış Yapıldı
                  </MenuItem>
                  <MenuItem onClick={() => handleDetailedSearch(4)} value={4}>
                    Gönderildi
                  </MenuItem>
                  <MenuItem onClick={() => handleDetailedSearch(5)} value={5}>
                    Teslim Edildi
                  </MenuItem>
                  <MenuItem onClick={() => handleDetailedSearch(8)} value={8}>
                    İptal Edildi
                  </MenuItem>
                </Select>
              </FormControl>
              <Button
                //className="search-card-content-handler-button"
                className="iyzi-button"
                sx={{ minWidth: "150px" }}
                onClick={handleExpandClick}
              >
                {expanded ? "Detaylı Aramayı Kapat" : "Detaylı Aramayı Aç"}
              </Button>
            </Box>
          </CardContent>
          <Collapse in={expanded} timeout="auto" unmountOnExit>
            <CardContent>
              <ReturnShipmentDetailedSearch expanded={expanded} />
            </CardContent>
          </Collapse>
        </Card>
      </Box>
    </ThemeProvider>
  );
};

export default ReturnShipmentSearch;
