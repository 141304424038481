const maskDate = (e) => {
  let input = e.target;
  if (e.charCode < 47 || e.charCode > 57) {
    e.preventDefault();
  }
  var len = input.value.length;

  if (len !== 1 || len !== 3) {
    if (e.charCode === 47) {
      e.preventDefault();
    }
  }

  if (len === 4) {
    input.value += " ";
  }

  if (len === 9) {
    input.value += " ";
  }

  if (len === 14) {
    input.value += " ";
  }

  return input.value;
};

export default maskDate;
