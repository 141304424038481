function Integration() {
  const data = [
    {
      url: "/images/integrations/etsy-wb.png",
      alt: "Etsy Entegrasyonu",
    },
    {
      url: "/images/integrations/shopify-wb.png",
      alt: "Shopify Entegrasyonu",
    },
    {
      url: "/images/integrations/woocommerce-wb.png",
      alt: "WooCommerce Entegrasyonu",
    },
    {
      url: "/images/integrations/amazon-wb.png",
      alt: "Amazon Entegrasyonu",
    },
    {
      url: "/images/integrations/prestaShop-wb.png",
      alt: "PrestaShop Entegrasyonu",
    },
    {
      url: "/images/integrations/aliExpress-wb.png",
      alt: "AliExpress Entegrasyonu",
    },
    {
      url: "/images/integrations/ebay-wb.png",
      alt: "eBay Entegrasyonu",
    },
    {
      url: "/images/integrations/wish-wb.png",
      alt: "Wish Entegrasyonu",
    },
    {
      url: "/images/integrations/otto-wb.png",
      alt: "OTTO Entegrasyonu",
    },
    {
      url: "/images/integrations/walmart-wb.png",
      alt: "Walmart Entegrasyonu",
    },
    {
      url: "/images/integrations/ozon-wb.png",
      alt: "OZON Entegrasyonu",
    },
  ];

  return (
    <section className="main-integration">
      <div className="integration-wrapper">
        <article>
          <h2>Ücretsiz Entegrasyonlar</h2>
          <p>
            Satış kanallarınızı kolayca entegre edin, e-ihracat sürecinizi tek
            panel üzerinden yönetin.
          </p>
          <div className="integration-container">
            {data.map((integration, index) => (
              <img
                key={index}
                src={integration.url}
                alt={integration.alt}
                className={index < data.length - 3 ? "special-class" : ""}
              />
            ))}
          </div>
        </article>
      </div>
    </section>
  );
}

export default Integration;
