import { useCallback, useState } from "react";
import axios from "../../../api/axios";
import Errors from "../../../utils/Errors";
import { Button, CircularProgress, Modal, TextField } from "@mui/material";
import { useRecoilState } from "recoil";
import { resetPasswordModalState } from "../../../atoms/membership";
import { toast } from "react-toastify";

const ResetPassword = () => {
  const [resetPasswordModal, setResetPasswordModal] = useRecoilState(resetPasswordModalState);

  const [loading, setLoading] = useState(false);

  const [pwd, setPwd] = useState("");
  const [pwdRpt, setPwdRpt] = useState("");

  const handleClose = useCallback(() => {
    setPwd("");
    setPwdRpt("");
    setResetPasswordModal(false);
  }, [setResetPasswordModal]);

  const handleResetPwd = async (e) => {
    e?.preventDefault();

    setLoading(true);
    await axios
      .post(`profile/password-update`, {
        password: pwd,
        password_confirmation: pwdRpt,
      })
      .then((response) => {
        toast.success(response.data.message);
        handleClose();
      })
      .catch((error) => Errors(error))
      .finally(() => setLoading(false));
  };

  return (
    <Modal
      open={resetPasswordModal}
      onClose={handleClose}
      sx={{ display: "flex", justifyContent: "center", alignItems: "center" }}
    >
      <div
        style={{
          backgroundColor: "var(--background)",
          display: "flex",
          flexDirection: "column",
          gap: "var(--gap)",
          padding: "20px",
          margin: "20px",
          borderRadius: "var(--inputBorderRadius)",
          maxWidth: "600px",
          width: "100%",

          maxHeight: "90vh",
          overflowY: "auto",
        }}
      >
        <form
          style={{ display: "flex", flexDirection: "column", gap: "var(--gap2x)" }}
          onSubmit={handleResetPwd}
        >
          <h1 style={{ fontSize: "26px", color: "var(--priceText)" }}>Şifremi Değiştir</h1>
          <TextField
            type="password"
            size="small"
            placeholder="Şifre"
            label="Şifre"
            value={pwd}
            onChange={(e) => setPwd(e.target.value)}
          />
          <TextField
            type="password"
            size="small"
            placeholder="Şifre Tekrarı"
            label="Şifre Tekrarı"
            value={pwdRpt}
            onChange={(e) => setPwdRpt(e.target.value)}
          />
          <div
            style={{
              display: "flex",
              alignItems: "center",
              gap: "var(--gap)",
              justifyContent: "flex-end",
            }}
          >
            {loading ? (
              <div
                style={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  width: "83px",
                }}
              >
                <CircularProgress sx={{ width: "21px!important", height: "21px!important" }} />
              </div>
            ) : (
              <Button
                className="iyzi-button-blue"
                disabled={
                  pwd === "" ||
                  pwdRpt === "" ||
                  pwd !== pwdRpt ||
                  pwd.length < 6 ||
                  pwdRpt.length < 6
                }
                sx={{ ":disabled": { opacity: 0.3 } }}
                type="submit"
              >
                Şifremi Değiştir
              </Button>
            )}
            <Button type="button" className="iyzi-button-blue" onClick={handleClose}>
              İptal
            </Button>
          </div>
        </form>
      </div>
    </Modal>
  );
};

export default ResetPassword;
