import React, { useEffect, useRef } from "react";
import Chart from "chart.js/auto";

const DashboardCouriersChart = ({ topCouriers }) => {
  const chartRef = useRef(null);

  useEffect(() => {
    const labels = topCouriers.map((item) => item.supplier_name);
    const values = topCouriers.map((item) => item.parcel_count);

    const chartData = {
      labels: labels,
      datasets: [
        {
          label: "Kargo Sayısı",
          data: values,
          fill: false,
          backgroundColor: "#1d8ff7",
          borderColor: "#1d8ff7",
          borderWidth: 1,
        },
      ],
    };

    const chartOptions = {
      aspectRatio: 3, // Grafik boyutunu düzenlemek için aspectRatio değerini ayarlayabilirsiniz.
      scales: {
        x: {
          beginAtZero: true,
          ticks: {
            stepSize: 1,
          },
        },
        y: {
          beginAtZero: true,
        },
      },
      responsive: true,
      maintainAspectRatio: false,
      plugins: {
        legend: {
          display: false,
        },
      },
      indexAxis: "y",
    };

    // Veya sadece bir veri olduğunda bar genişliğini ayarlamak için
    const chartOptionsSingleData = {
      aspectRatio: 0.5, // Özel bir aspectRatio değeri
      scales: {
        x: {
          beginAtZero: true,
          ticks: {
            stepSize: 1,
          },
        },
        y: {
          beginAtZero: true,
        },
      },
      responsive: true,
      maintainAspectRatio: false,
      plugins: {
        legend: {
          display: false,
        },
      },
      indexAxis: "y",
    };

    if (chartRef.current && topCouriers && topCouriers.length > 0) {
      new Chart(chartRef.current, {
        type: "bar",
        data: chartData,
        options: topCouriers.length > 1 ? chartOptions : chartOptionsSingleData, // Veri sayısına göre farklı seçenekler kullanın
      });
    }
  }, [topCouriers]);

  return (
    <div className="pie-chart">
      <div className="pie-chart-header">
        <div className="pie-text-total">Favori Taşıyıcılarım</div>
      </div>
      <div
        style={{
          height: "150px",
        }}
      >
        {topCouriers.length === 0 ? (
          <div>Gönderiniz bulunmamaktadır.</div>
        ) : (
          <canvas ref={chartRef} />
        )}
      </div>
    </div>
  );
};

export default DashboardCouriersChart;
