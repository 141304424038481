import { Box, CircularProgress, createTheme, ThemeProvider } from "@mui/material";
import { useState } from "react";
import { toast } from "react-toastify";
import axios from "../../api/axios";
import DownloadBase64Pdf from "../../utils/DownloadBase64Pdf";
import SimCardDownloadOutlinedIcon from "@mui/icons-material/SimCardDownloadOutlined";
import NoteAddOutlinedIcon from "@mui/icons-material/NoteAddOutlined";
import { useRecoilState } from "recoil";
import {
  addNoteOpenState,
  connectedPaymentsOpenState,
  selectedShipmentForMyShipmentsState,
  showFinancialDetailsOpenState,
  uploadShipmentDocumentOpenState,
} from "../../atoms/myShipments";
import TrendingUpOutlinedIcon from "@mui/icons-material/TrendingUpOutlined";
import BackupOutlinedIcon from "@mui/icons-material/BackupOutlined";
import PaymentsOutlinedIcon from "@mui/icons-material/PaymentsOutlined";
import Errors from "../../utils/Errors";

const theme = createTheme({
  components: {
    MuiBackdrop: {
      styleOverrides: {
        root: {
          backgroundColor: "rgba(0, 0, 0, 0.1);",
        },
      },
    },
  },
});

const ReturnListItemLinks = ({ shipment }) => {
  const [consignmentLoading, setConsignmentLoading] = useState(false);
  const [, setAddNoteOpen] = useRecoilState(addNoteOpenState);
  const [, setSelectedShipmentForMyShipments] = useRecoilState(selectedShipmentForMyShipmentsState);
  const [, setShowFinancialDetailsOpen] = useRecoilState(showFinancialDetailsOpenState);
  const [, setUploadShipmentDocumentOpen] = useRecoilState(uploadShipmentDocumentOpenState);
  const [, setConnectedPaymentsOpen] = useRecoilState(connectedPaymentsOpenState);
  const handleConsignmentDownload = () => {
    setConsignmentLoading(true);
    axios
      .get(`parcel/get-consignment/${shipment.get_parcel.id}`)
      .then((response) => {
        if (response.status === 200) {
          DownloadBase64Pdf(response.data.base64, shipment.get_parcel.parcel_ref_no);
          toast.success(response.data.message, { autoClose: 500 });
        }
      })
      .catch((error) => Errors(error))
      .finally(() => setConsignmentLoading(false));
  };
  const handleAddNoteModal = () => {
    setSelectedShipmentForMyShipments(shipment.get_parcel);
    setAddNoteOpen(true);
  };

  const handleFinancialDetailsOpen = () => {
    setSelectedShipmentForMyShipments(shipment.get_parcel);
    setShowFinancialDetailsOpen(true);
  };

  const handleUploadModal = () => {
    setSelectedShipmentForMyShipments(shipment.get_parcel);
    setUploadShipmentDocumentOpen(true);
  };

  const handleConnectedPaymentsModal = () => {
    setSelectedShipmentForMyShipments(shipment.get_parcel);
    setConnectedPaymentsOpen(true);
  };

  return (
    <ThemeProvider theme={theme}>
      <Box className="list-links">
        {consignmentLoading ? (
          <div
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              height: "15px",
            }}
          >
            <CircularProgress sx={{ height: "15px !important", width: "15px !important" }} />
          </div>
        ) : (
          <>
            <div onClick={handleAddNoteModal}>
              <NoteAddOutlinedIcon sx={{ height: "13px" }} />{" "}
              {(shipment.get_parcel.get_parcel_notes.length !== 0 &&
                shipment.get_parcel.get_parcel_notes.filter((e) => e.type === "1")) === false
                ? "Not Ekle"
                : "Notu Düzenle"}
            </div>
            <div onClick={() => handleConsignmentDownload(shipment)}>
              <SimCardDownloadOutlinedIcon sx={{ height: "13px" }} /> Konşimento İndir
            </div>
            {/*              <div onClick={() => handleConsignmentDownload(shipment)}>
                <SimCardDownloadOutlinedIcon sx={{height: "13px"}}/> Gönderi Detayı
              </div>*/}
            <div onClick={handleFinancialDetailsOpen}>
              <TrendingUpOutlinedIcon sx={{ height: "13px" }} /> Finansal Değerler
            </div>
            <div onClick={handleUploadModal}>
              <BackupOutlinedIcon sx={{ height: "13px" }} /> Dosya Yükle{" "}
              {shipment?.get_parcel_documents?.length > 0 && "/ Göster"}
            </div>
            <div onClick={handleConnectedPaymentsModal}>
              <PaymentsOutlinedIcon sx={{ height: "13px" }} /> Bağlı Ödemeler
            </div>
            {/*<div>
                <PaymentsOutlinedIcon sx={{ height: "13px" }} /> Faturalarım
              </div>*/}
          </>
        )}
      </Box>
    </ThemeProvider>
  );
};

export default ReturnListItemLinks;
