import { TextField } from "@mui/material";

const OrderReceiverNameSearch = ({ orderReceiverName, setOrderReceiverName }) => {
  const handleChange = (e) => {
    setOrderReceiverName(e.target.value);
  };

  return (
    <TextField
      value={orderReceiverName}
      onChange={handleChange}
      size="small"
      name="order-receiver-name-field"
      label="Alıcı Adı"
      type="text"
      autoComplete="off"
    />
  );
};

export default OrderReceiverNameSearch;
