import { useState } from "react";
import {
  Button,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
} from "@mui/material";
import { useRecoilValue, useSetRecoilState } from "recoil";
import {
  basicSearchState,
  myShipmentListLoadingState,
  myShipmentListState,
  myShipmentsListLimitState,
  shipmentListOrderDirectionState,
  totalPagesState,
} from "../../../atoms/myShipments";
import axios from "../../../api/axios";
import { useNavigate } from "react-router-dom";
import Errors from "../../../utils/Errors";

const AddressNavigateDialog = ({ open, setOpen, handleSubmit, parcel }) => {
  const navigate = useNavigate();

  const parcelRefNo = parcel?.parcel_ref_no;

  const [loading, setLoading] = useState(false);

  //* Gönderilerim sayfasının global stateleri
  const setBasicSearch = useSetRecoilState(basicSearchState);
  const setMyShipmentList = useSetRecoilState(myShipmentListState);
  const setMyShipmentListLoading = useSetRecoilState(myShipmentListLoadingState);
  const setTotalPages = useSetRecoilState(totalPagesState);

  const shipmentOrderDirectionPage = useRecoilValue(shipmentListOrderDirectionState);
  const myShipmentsListLimit = useRecoilValue(myShipmentsListLimitState);

  const handleClose = () => {
    setOpen(false);
  };

  const handleNavigate = async () => {
    setLoading(true);
    setBasicSearch(parcelRefNo);
    await axios
      .get(
        `parcel?order_direction=${shipmentOrderDirectionPage}&limit=${myShipmentsListLimit}&page=${1}&search=${parcelRefNo}`
      )
      .then((response) => {
        setMyShipmentList(response.data.data);
        setTotalPages(response.data.totalPages);
        navigate("/my-shipment");
      })
      .catch((error) => Errors(error))
      .finally(() => {
        setMyShipmentListLoading(false);
        setLoading(false);
      });
  };

  return (
    <Dialog open={open} onClose={handleClose}>
      <DialogTitle
        id="alert-dialog-title"
        style={{
          display: "flex",
          flexDirection: "column",
          gap: "var(--gap2x)",
          alignItems: "center",
        }}
      >
        <h5 style={{ color: "var(--text)", fontSize: "24px" }}>
          Adresinizi Güncellemek mi İstiyorsunuz ?
        </h5>
      </DialogTitle>
      <DialogContent>
        <DialogContentText
          id="alert-dialog-description"
          style={{ textAlign: "center", color: "var(--text)", fontSize: "16px" }}
        >
          Gönderinizi henüz depoya göndermediyseniz gönderilerim sayfasındaki ilgili gönderinin
          yanında yer alan adres düzenle bölümünden değişiklik yapabilirsiniz.
        </DialogContentText>
      </DialogContent>
      <DialogActions style={{ justifyContent: "center" }}>
        <Button className="iyzi-button-blue" onClick={handleSubmit} style={{ padding: "5px 50px" }}>
          Devam
        </Button>
        {loading ? (
          <div
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              width: "216px",
              height: "33px",
            }}
          >
            <CircularProgress sx={{ width: "21px!important", height: "21px!important" }} />
          </div>
        ) : (
          <Button
            className="iyzi-button-blue"
            onClick={handleNavigate}
            style={{ padding: "5px 50px" }}
          >
            İlgili Gönderiye Git
          </Button>
        )}
      </DialogActions>
    </Dialog>
  );
};

export default AddressNavigateDialog;
