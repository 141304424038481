import { Checkbox, FormControlLabel } from "@mui/material";
import { orderListCheckedListState, orderListState } from "../../../atoms/orders";
import { useRecoilState, useRecoilValue } from "recoil";
import { useCallback } from "react";

const OrderSelectAll = ({ setOrderSelectedPackageSystem }) => {
  const orderList = useRecoilValue(orderListState);
  const [orderListCheckedList, setOrderListCheckedList] = useRecoilState(orderListCheckedListState);

  const isChecked = orderListCheckedList.length === orderList.length;

  const handleCheck = useCallback(() => {
    if (isChecked) {
      setOrderListCheckedList([]);
      setOrderSelectedPackageSystem("S");
    } else {
      setOrderListCheckedList(orderList);
    }
  }, [orderList, setOrderListCheckedList, isChecked, setOrderSelectedPackageSystem]);

  return (
    <FormControlLabel
      control={<Checkbox checked={isChecked} onChange={handleCheck} />}
      label="Tümünü Seç"
    />
  );
};

export default OrderSelectAll;
