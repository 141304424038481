import { TextField } from "@mui/material";
import { useEffect } from "react";

const ReceiptDate = ({ receiptDateStart, receiptDateEnd, setReceiptDateStart, setReceiptDateEnd, dateType }) => {

  useEffect(() => {
    setReceiptDateStart("")
    setReceiptDateEnd("")
  }, [dateType]);

  return (
    <>
      <TextField
        size="small"
        label="Tarih Başlangıç"
        type="date"
        value={receiptDateStart}
        onChange={(e) => setReceiptDateStart(e.target.value)}
        InputLabelProps={{
          shrink: true,
        }}
        InputProps={{
          placeholder: '',
        }}
        autoComplete="off" />
      <TextField
        size="small"
        label="Tarih Bitiş"
        type="date"
        value={receiptDateEnd}
        onChange={(e) => setReceiptDateEnd(e.target.value)}
        InputLabelProps={{
          shrink: true,
        }}
        InputProps={{
          placeholder: '',
        }}
        autoComplete="off" />
    </>
  );
};

export default ReceiptDate;
