import {
  Autocomplete,
  Box,
  Button,
  CircularProgress,
  FormControl,
  InputLabel,
  MenuItem,
  Paper,
  Select,
  TextField,
  ThemeProvider,
  createTheme,
} from "@mui/material";
import { useRecoilState, useSetRecoilState } from "recoil";
import {
  openSupportDetailState,
  openSupportListState,
  openSupportRequestState,
  supportCurrentPageState,
  supportListState,
  supportTotalPageState,
  ticketDocumentState,
  ticketState,
} from "../../atoms/support";
import QuestionAnswerIcon from "@mui/icons-material/QuestionAnswer";
import courierSupport from "../../json/courierSupport.json";
import { useCallback, useEffect, useMemo, useState } from "react";
import InfoIcon from "@mui/icons-material/Info";
import { NavLink, useLocation, useNavigate } from "react-router-dom";
import axios from "../../api/axios";
import { toast } from "react-toastify";
import Errors from "../../utils/Errors";
import AddressNavigateDialog from "./supportModal/AddressNavigateDialog";

const theme = createTheme({
  components: {
    MuiPaper: {
      styleOverrides: {
        root: {
          maxHeight: "320px!important",
        },
      },
    },
  },
});

const SupportRequest = () => {
  // awb parametresi url'de bulunuyorsa silmek için.
  const navigate = useNavigate();
  const location = useLocation();
  const searchParams = useMemo(() => {
    return new URLSearchParams(location.search);
  }, [location.search]);

  const awbParam = searchParams.get("awb");

  const setSupportTotalPage = useSetRecoilState(supportTotalPageState);
  const setSupportCurrentPage = useSetRecoilState(supportCurrentPageState);
  const setOpenSupportRequest = useSetRecoilState(openSupportRequestState);
  const setOpenSupportDetail = useSetRecoilState(openSupportDetailState);
  const setOpenSupportList = useSetRecoilState(openSupportListState);
  const setTicket = useSetRecoilState(ticketState);
  const setTicketDocument = useSetRecoilState(ticketDocumentState);

  const [supportList, setSupportList] = useRecoilState(supportListState);

  const [addressNavigateDialog, setAddressNavigateDialog] = useState(false);

  const [awbData, setAwbData] = useState([]);
  const [loading, setLoading] = useState(false);
  const [courierRegistration, setCourierRegistration] = useState(false);
  const [subject, setSubject] = useState("");
  const [description, setDescription] = useState("");
  const [parcelRefNo, setParcelRefNo] = useState(null);
  const [isAwbExist, setIsAwbExist] = useState(false);
  const [awbId, setAwbId] = useState(null);

  const handleCreateSupportRequest = useCallback(
    async (e) => {
      if (e) e.preventDefault();
      setLoading(true);

      await axios
        .post("ticket/add", {
          subject,
          description,
          parcel_ref_no: subject === 5 ? "" : parcelRefNo?.parcel_ref_no,
        })
        .then((response) => {
          setLoading(false);
          toast.success(response.data.message);
          setSubject("");
          setDescription("");
          setParcelRefNo("");
          setSupportList([response.data.data, ...supportList]);
          setOpenSupportRequest(false);
          setOpenSupportList(true);
          if (awbParam !== null) {
            // Remove the AWB parameter from the URL
            searchParams.delete("awb");

            // Update the URL without causing a navigation
            navigate(location.pathname + "?" + searchParams.toString(), {
              replace: true,
            });
          }
        })
        .catch((error) => {
          setLoading(false);
          Errors(error);

          // Object.entries(error.response.data.errors)?.forEach(
          //   ([field, messages]) =>
          //     messages.forEach((message) => toast.error(`${field}: ${message}`))
          // );
        });
    },
    [
      setSupportList,
      setOpenSupportRequest,
      setOpenSupportList,
      setParcelRefNo,
      navigate,
      searchParams,
      awbParam,
      description,
      location.pathname,
      parcelRefNo,
      subject,
      supportList,
    ]
  );

  const handleDialog = (e) => {
    if (e) e.preventDefault();

    setAddressNavigateDialog(true);
  };

  const handleCancelSupportRequest = useCallback(() => {
    if (awbParam !== null) {
      // Remove the AWB parameter from the URL
      searchParams.delete("awb");

      // Update the URL without causing a navigation
      navigate(location.pathname + "?" + searchParams.toString(), {
        replace: true,
      });
    }
    setOpenSupportRequest(false);
    if (supportList.length === 0) {
      setOpenSupportList(false);
    } else {
      setOpenSupportList(true);
    }
  }, [
    setOpenSupportList,
    setOpenSupportRequest,
    navigate,
    awbParam,
    location.pathname,
    supportList,
    searchParams,
  ]);

  const getAwbData = useCallback(() => {
    axios
      .get(`parcel`, {
        params: {
          detailed: 1,
          unlimited: 1,
          select: JSON.stringify({ parcel_ref_no: 1 }),
        },
      })
      .then((response) => {
        setAwbData(response.data.data);
      })
      .catch((error) => Errors(error));
  }, []);

  const getTicketListData = useCallback(async () => {
    setLoading(true);
    await axios
      .get(`ticket?order_direction=DESC`)
      .then((response) => {
        setLoading(false);
        setSupportCurrentPage(1);
        setSupportList(response.data.data);
        setSupportTotalPage(response.data.totalPages);

        if (awbParam) {
          setIsAwbExist(response.data.data.find((e) => e.parcel_ref_no === awbParam) !== undefined);

          let foundObject = response.data.data.find((e) => e.parcel_ref_no === awbParam);

          if (foundObject !== undefined) {
            setAwbId(foundObject?.id);
          }
        }

        if (response.data.data.length > 0) {
          setOpenSupportList(true);
        } else {
          setOpenSupportList(false);
        }
      })
      .catch((error) => {
        setLoading(false);
        setOpenSupportList(false);
        Errors(error);
      });
  }, [
    setOpenSupportList,
    setSupportList,
    setSupportTotalPage,
    setSupportCurrentPage,
    setIsAwbExist,
    awbParam,
  ]);

  const handleOpenSupportDetail = useCallback(async () => {
    setLoading(true);
    await axios
      .get(`ticket/${awbId}`)
      .then((response) => {
        setLoading(false);
        setOpenSupportDetail(true);
        setOpenSupportList(false);
        setOpenSupportRequest(false);
        setTicket(response.data.data);
        setTicketDocument(response.data.data.get_ticket_documents);
      })
      .catch((error) => {
        setLoading(false);
        Errors(error);
      });
  }, [
    setOpenSupportDetail,
    setOpenSupportList,
    setTicket,
    setTicketDocument,
    setOpenSupportRequest,
    awbId,
  ]);

  useEffect(() => {
    if (subject === "Kurye Kaydı") {
      setCourierRegistration(true);
    } else {
      setCourierRegistration(false);
    }
    if (awbParam) {
      getTicketListData();
    }
    getAwbData();
  }, [subject, getAwbData, getTicketListData, awbParam]);

  useEffect(() => {
    // awbData içerisinde AWB parametresine denk gelen veriyi bul
    const initialParcel = awbData.find((parcel) => parcel.parcel_ref_no === awbParam);

    if (initialParcel) {
      setParcelRefNo(initialParcel);
    }
  }, [awbData, awbParam]);

  useEffect(() => {
    if (awbParam) {
      if (isAwbExist) {
        handleOpenSupportDetail();
      }
    }
  }, [awbParam, isAwbExist, handleOpenSupportDetail]);

  return (
    <ThemeProvider theme={theme}>
      <AddressNavigateDialog
        open={addressNavigateDialog}
        setOpen={setAddressNavigateDialog}
        handleSubmit={handleCreateSupportRequest}
        parcel={parcelRefNo}
      />
      <form
        onSubmit={subject === 2 ? handleDialog : handleCreateSupportRequest}
        className="support-request"
      >
        <h6>
          <QuestionAnswerIcon /> Destek Talebi Oluştur
        </h6>
        {courierRegistration && (
          <p className="support-request-subject-info">
            <InfoIcon sx={{ color: "var(--info)" }} />
            <p>
              Kurye kaydı nasıl yapabilirim ? Konusunda bilgi almak istiyorsanız lütfen{" "}
              <NavLink className="support-request-subject-info-link" to={"/courier-registration"}>
                buraya
              </NavLink>{" "}
              tıklayınız.
            </p>
          </p>
        )}
        <Box className="support-request-wrapper">
          <Box className="support-request-left">
            <FormControl required={subject !== 5} className="support-request-subject" size="small">
              <InputLabel id="support-subject">Konu</InputLabel>
              <Select
                labelId="support-subject"
                id="support-subject-select"
                label="Konu"
                value={subject}
                onChange={(e) => setSubject(e.target.value)}
              >
                <MenuItem value={""}>
                  <em>Temizle</em>
                </MenuItem>
                {courierSupport.map((sup, key) => (
                  <MenuItem key={key} value={sup.supValue}>
                    {sup.support}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
            {subject !== 5 && subject !== 6 && subject !== 0 && (
              <Autocomplete
                id="support-awb-select"
                fullWidth
                size="small"
                options={awbData}
                autoHighlight
                openOnFocus={false}
                getOptionLabel={(option) => `${option.parcel_ref_no}`}
                value={parcelRefNo}
                onChange={(event, newValue) => setParcelRefNo(newValue)}
                PaperComponent={({ children }) => (
                  <Paper style={{ overflow: "hidden" }}>{children}</Paper>
                )}
                renderOption={(props, option) => (
                  <Box component="li" {...props}>
                    {option.parcel_ref_no}
                  </Box>
                )}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    label="AWB Seçiniz"
                    name="support-awb"
                    variant="outlined"
                    inputProps={{
                      ...params.inputProps,
                      autoComplete: "off",
                    }}
                    required
                  />
                )}
              />
            )}
          </Box>
          <TextField
            className="support-request-note"
            multiline
            required
            rows={3}
            label="Notunuz"
            value={description}
            onChange={(e) => setDescription(e.target.value)}
            fullWidth
            autoComplete="off"
          />
        </Box>
        <Box className="support-request-done">
          <Button onClick={handleCancelSupportRequest}>Vazgeç</Button>
          {loading ? (
            <Box
              sx={{
                width: "160px",
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
              }}
            >
              <CircularProgress sx={{ width: "20px !important", height: "20px !important" }} />
            </Box>
          ) : (
            <Button type="submit">Destek Talebi Oluştur</Button>
          )}
        </Box>
      </form>
    </ThemeProvider>
  );
};

export default SupportRequest;
