import { TextField, Autocomplete, Box } from "@mui/material";
import CountriesTurkishSorted from "../../../utils/CountriesTurkishSorted";

const Country = ({ country, setCountry }) => {
  return (
    <Autocomplete
      id="country-typing-select"
      fullWidth
      size="small"
      sx={{ maxWidth: 380 }}
      autoComplete="off"
      name="country"
      options={CountriesTurkishSorted()}
      autoHighlight
      getOptionLabel={(option) =>
        `${option.country_name_tr}, ${option.country_name_en}`
      }
      renderOption={(props, option) => (
        <Box component="li" sx={{ "& > img": { mr: 2, flexShrink: 0 } }} {...props}>
          <img
            loading="lazy"
            width="20"
            src={`https://flagcdn.com/w20/${option.iso_code.toLowerCase()}.png`}
            srcSet={`https://flagcdn.com/w40/${option.iso_code.toLowerCase()}.png 2x`}
            alt=""
          />
          {option.country_name_tr}, {option.country_name_en}
        </Box>
      )}
      onChange={(event, value) => {
        if (value) {
          setCountry(value.iso_code);
        } else {
          setCountry(''); 
        }
      }}
      onInputChange={(event, value, reason) => {
        if (reason === 'clear') {
          setCountry(''); 
        }
      }}
      renderInput={(params) => (
        <TextField
          {...params}
          label="Ülke"
          autoComplete="off"
          name="country"
          inputProps={{
            ...params.inputProps,
            autoComplete: "off", 
          }}
        />
      )}
    />
  );
};

export default Country;
