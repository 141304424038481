import "../style/home/Home.css";
import "../style/globals/Globals.css";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { DynamicTitle } from "../utils/DynamicTitle";
import { useCookies } from "react-cookie";
import Info from "../components/home/Info";
import Integration from "../components/home/Integration";
import Header from "../components/beforeLogin/Header";
import GetStart from "../components/home/GetStart";
import Slider from "../components/home/Slider";
import Partner from "../components/home/Partner";
import Footer from "../components/beforeLogin/Footer";
import Blog from "../components/home/Blog";
// import PageTransition from "../utils/PageTransition";
import IsHowItWorkFrame from "../components/home/IsHowItWorkFrame";
import GetFinish from "../components/home/GetFinish";
import Popup from "../components/home/Popup";
import JoinIyziTeam from "../components/home/JoinIyziTeam";
import ChangePasswordInfo from "../components/home/ChangePasswordInfo";
import { Helmet } from "react-helmet";

const Home = () => {
  DynamicTitle("Yurt dışına kargo göndermenin en kolay yolu! | iyziShip");

  const navigate = useNavigate();
  const [token] = useCookies(["jwt"]);
  const jwt = token?.jwt;

  const [open, setOpen] = useState(true);

  useEffect(() => {
    if (jwt !== undefined && jwt !== null && jwt.length !== 0) {
      navigate("/dashboard");
    }
  }, [jwt, navigate]);

  return (
    <>
      <Helmet>
        <meta
          name="title"
          content="Yurt dışına kargo göndermek artık çok kolay! iyziShip'le e-ihracat sürecinizi basitleştirin ve %80'e varan indirimlerle lojistik maliyetlerinizi minimuma indirin."
        />
        <meta
          name="description"
          content="Ücretsiz e-ticaret ve pazaryeri entegrasyonlarımızdan yararlanın, bu sayede tüm e-ihracat sürecinizi tek panelden yönetin."
        />
        <link rel="canonical" href="https://www.iyziship.com"/>
      </Helmet>
      {/* <PageTransition> */}
        <Header />
        <main className="iyzi-main">
          <GetStart />
          <IsHowItWorkFrame />
          <Info />
          <Integration />
          <Slider />
          <Partner />
          <JoinIyziTeam />
          <Blog />
          <GetFinish />
          <Popup />
        </main>
        <Footer />
      {/* </PageTransition> */}
    </>
  );
};

export default Home;
