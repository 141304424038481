import "../style/basket/Basket.css";
import { useInView, animated } from "@react-spring/web";
import { Box } from "@mui/material";
import { useCallback, useEffect, useState } from "react";
import { useCookies } from "react-cookie";
import { ToastContainer } from "react-toastify";
import { useRecoilState, useSetRecoilState } from "recoil";
import axios from "../api/axios";
import NotificationBar from "../components/sidebar/NotificationBar";
import { DynamicTitle } from "../utils/DynamicTitle";
import miniLogo from "../img/logos/logo_white_mini_loading.svg";
import BasketDetailedItems from "../components/basketDetailed/BasketDetailedItems";
import {
  basketBalanceState,
  basketItemsCurrentPageState,
  basketItemsState,
  basketItemsTotalPageState,
} from "../atoms/basket";
import BasketDetailedPayment from "../components/basketDetailed/BasketDetailedPayment";
import WrapperStyle from "../utils/WrapperStyle";
import BasketPayment from "../components/sidebar/basket/BasketPayment";
import BasketDetailedFinancialModal from "../components/basketDetailed/BasketDetailedFinancialModal";
// import PageTransition from "../utils/PageTransition";
import Errors from "../utils/Errors";
import BasketTitle from "../components/basketDetailed/BasketTitle";
import BasketInfo from "../components/basketDetailed/BasketInfo";

const BasketDetailed = () => {
  DynamicTitle("Sepetim | iyziShip");

  const [token] = useCookies(["jwt"]);
  const jwt = token.jwt;
  axios.defaults.headers.common["Authorization"] = `Bearer ${jwt}`;

  const [ref, springs] = useInView(
    () => ({
      from: {
        opacity: 0,
        y: 20,
      },
      to: {
        opacity: 1,
        y: 0,
      },
    }),
    {
      rootMargin: "-10% 0%",
    }
  );

  const [basketItems, setBasketItems] = useRecoilState(basketItemsState);
  const setBasketBalance = useSetRecoilState(basketBalanceState);
  const setBasketItemsCurrentPage = useSetRecoilState(
    basketItemsCurrentPageState
  );
  const setBasketItemsTotalPage = useSetRecoilState(basketItemsTotalPageState);

  const [loading, setLoading] = useState(true);
  const [isBasketEmpty, setIsBasketEmpty] = useState(false);
  const [totalItems, setTotalItems] = useState(null);

  const getBasketItems = useCallback(() => {
    axios
      .get("basket", {
        params: {
          detailed: 1,
          limit: 5,
          page: 1,
        },
      })
      .then((response) => {
        setLoading(false);
        setBasketItems(response.data.data);
        setBasketItemsCurrentPage(response.data.currentPage);
        setBasketItemsTotalPage(response.data.totalPages);
        setTotalItems(response.data.totalItems);
      })
      .catch((error) => Errors(error))
      .finally(() => setLoading(false));
  }, [setBasketItems, setBasketItemsCurrentPage, setBasketItemsTotalPage]);

  const getBalance = useCallback(() => {
    axios
      .get(`wallet`)
      .then((response) => {
        setBasketBalance(response.data.data);
      })
      .catch((error) => Errors(error));
  }, [setBasketBalance]);

  useEffect(() => {
    if (basketItems.length === 0) {
      setIsBasketEmpty(true);
    } else {
      setIsBasketEmpty(false);
    }
  }, [basketItems, setIsBasketEmpty]);

  useEffect(() => {
    getBasketItems();
    getBalance();
  }, [getBasketItems, getBalance]);

  return (
    <section className="basket-detailed">
      <NotificationBar />
      {/* <PageTransition> */}
      <ToastContainer />
      <BasketPayment />
      <BasketTitle />
      <BasketInfo />
      <BasketDetailedFinancialModal />
      <Box className="basket-detailed-wrapper" style={WrapperStyle()}>

        {loading ? (
          <Box className="basket-loading-wrapper">
            <img className="loading-logo" src={miniLogo} alt="" />
          </Box>
        ) : (
          <animated.div ref={ref} style={springs}>
            {<BasketDetailedPayment />}
            {basketItems.length === 0 && (
              <div className="basket-no-item">Sepetinizde Gönderi Bulunmuyor.</div>
            )}
            {basketItems.length !== 0 && (
              <Box className="basket-detailed-container">
                <BasketDetailedItems
                  setLoading={setLoading}
                  totalItems={totalItems}
                  setTotalItems={setTotalItems}
                />
              </Box>
            )}
          </animated.div>
        )}
      </Box>
      {/* </PageTransition> */}
    </section>
  );
};

export default BasketDetailed;
