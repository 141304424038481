import Footer from "../components/beforeLogin/Footer";
import Header from "../components/beforeLogin/Header";
import GetFinish from "../components/home/GetFinish";
// import PageTransition from "../utils/PageTransition";

const AboutUs = () => {
  return (
    // <PageTransition>
    <>
      <Header />
      <main className="iyzi-main">
        <section className="main-about-us">
          <h1>Hakkımızda</h1>
          <article>
            <p>
              iyziShip, sınır ötesi e-ticaret ve taşımacılığı anlamında yaklaşık
              20 yıllık ciddi tecrübeleri olan ve uluslararası lojistik
              alanındaki deneyimleri 30 yıla varan bir ekip tarafından kurulmuş,
              Türkiye'nin öncü e-lojistik platformlarından biridir. Amacımız,
              lojistik ve teknolojiyi uluslararası bir platformda birleştirerek
              global e-ticaretin önünü açmaktır.
            </p>
          </article>
          <article>
            <h3>Misyonumuz</h3>
            <p>
              Global düşünüp lokal hareket eden bir yaklaşımla, lojistik ve
              teknolojiyi birleştirmek ve ülkemiz için değer yaratan yerli ve
              milli bir "E-Lojistik Platformu" olmaktır.
            </p>
            <h3>Vizyonumuz</h3>
            <p>
              Türkiye'nin uluslararası e-ticaretteki potansiyelini görmekteyiz.
              Global e-ticaret pazarında hak ettiğimiz büyüklüğe ulaşmayı
              hedeflerken, teknoloji ve lojistiği birleştirerek ülkemizin
              ihracat hedeflerine ulaşmasına katkı sağlamayı arzuluyoruz.
            </p>
          </article>
          <h2>iyziDünyası ile Tanışın</h2>
          <div className="about-us-iyziworld">
            <div className="about-us-iyziworld-here">
              <img src="/images/home/iyziShip-logo.png" alt="iyziShip" />
              <div className="here">
                <div />
                <p>ŞU AN BURADASINIZ</p>
              </div>
            </div>
            <img src="/images/home/iyziBox-logo.png" alt="iyziBox" />
            <img src="/images/home/iyziBuy-logo.png" alt="iyziBuy" />
          </div>
        </section>
        <GetFinish />
      </main>
      <Footer />
    </>
  );
};

export default AboutUs;
