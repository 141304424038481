import { Box, Button } from "@mui/material";
import { useRecoilState } from "recoil";
import ControlPointOutlinedIcon from "@mui/icons-material/ControlPointOutlined";
import {
  shipmentHideState,
  startShipmentSenderAnimationState,
} from "../../../atoms/createShipment";

const AddSenderAddress = () => {
  const [, setStartShipmentSenderAnimation] = useRecoilState(
    startShipmentSenderAnimationState
  );
  const [, setShipmentHide] = useRecoilState(shipmentHideState);

  const addressAddHandler = () => {
    setStartShipmentSenderAnimation(true);
    setShipmentHide(true);
  };

  return (
    <Box
      className="new-address-adder-wrapper"
      style={{ justifyContent: "flex-start" }}
    >
      <Button
        sx={{
          color: "var(--btnBgBlue)",
          textTransform: "none",
          display: "flex",
          alignItems: "center",
          gap: "5px",
          fontSize: "14px",
          fontWeight: 600,
        }}
        onClick={addressAddHandler}
      >
        <ControlPointOutlinedIcon />
        Yeni Gönderici Adresi Ekle
      </Button>
    </Box>
  );
};

export default AddSenderAddress;
