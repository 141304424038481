import "../style/courierRegistration/CourierRegistration.css";
import { Box, Button, CircularProgress } from "@mui/material";
import { useCallback, useEffect, useState } from "react";
import { useCookies } from "react-cookie";
import { ToastContainer } from "react-toastify";
import { useRecoilState, useSetRecoilState } from "recoil";
import axios from "../api/axios";
import NotificationBar from "../components/sidebar/NotificationBar";
import { DynamicTitle } from "../utils/DynamicTitle";
import CourierDistrict from "../components/courierRegistration/CourierDistrict";
import { useInView, animated } from "@react-spring/web";
import CreateCourierRegistration from "../components/courierRegistration/CreateCourierRegistration";
import CourierRegisterSearch from "../components/courierRegistration/CourierRegisterSearch";
import {
  courierListState,
  isCourierRegistrationExistState,
  isCreateRegistrationState,
  showAddButtonState,
  createRegisterModalState,
} from "../atoms/courierRegistration";
import CourierList from "../components/courierRegistration/CourierList";
import WrapperStyle from "../utils/WrapperStyle";
// import PageTransition from "../utils/PageTransition";
import ControlPointOutlinedIcon from "@mui/icons-material/ControlPointOutlined";
import Modal from "@mui/material/Modal";
import Errors from "../utils/Errors";
import CourierRegistrationTitle from "../components/courierRegistration/CourierRegistrationTitle";

const CourierRegistration = () => {
  DynamicTitle("Kurye Kaydı | iyziShip");

  const [token] = useCookies(["jwt"]);
  const jwt = token.jwt;
  axios.defaults.headers.common["Authorization"] = `Bearer ${jwt}`;

  const [isCreateRegistration, setIsCreateRegistration] = useRecoilState(isCreateRegistrationState);

  const setCourierList = useSetRecoilState(courierListState);
  const setShowAddButton = useSetRecoilState(showAddButtonState);

  const [isCourierRegistrationExist, setIsCourierRegistrationExist] = useRecoilState(
    isCourierRegistrationExistState
  );
  const [createRegisterModal, setCreateRegisterModal] = useRecoilState(createRegisterModalState);

  const [loading, setLoading] = useState(false);

  const getData = useCallback(async () => {
    setLoading(true);
    await axios
      .get("booking")
      .then((response) => {
        setLoading(false);
        if (response?.data?.totalItems === 0) {
          setCourierList([]);
          setIsCourierRegistrationExist(false);
          setIsCreateRegistration(true);
          setShowAddButton(false);
        } else {
          setCourierList(response.data.data);
          setIsCourierRegistrationExist(true);
          setIsCreateRegistration(false);
          setShowAddButton(true);
        }
      })
      .catch((error) => {
        Errors(error);
        setLoading(false);
      });
  }, [setCourierList, setIsCourierRegistrationExist, setIsCreateRegistration, setShowAddButton]);

  const [ref, springs] = useInView(
    () => ({
      from: {
        opacity: 0,
        y: 20,
      },
      to: {
        opacity: 1,
        y: 0,
      },
    }),
    {
      rootMargin: "-10% 0%",
    }
  );

  useEffect(() => {
    getData();
  }, [getData]);

  const style = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: " 80%",
    bgcolor: "background.paper",
    boxShadow: 24,
    p: 4,
  };

  const handleOpen = () => setCreateRegisterModal(true);
  const handleClose = () => {
    setCreateRegisterModal(false);
    setIsCourierRegistrationExist(true);
  };

  return (
    <section className="courier-registration">
      <NotificationBar />
      {/* <PageTransition> */}
        <ToastContainer />
        <CourierRegistrationTitle />
        <Box className="courier-registration-wrapper" style={WrapperStyle()}>
          {loading ? (
            <Box
              sx={{
                height: "50vh",
                width: "100%",
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
              }}
            >
              <CircularProgress />
            </Box>
          ) : (
            <animated.div ref={ref} style={springs}>
              {!isCreateRegistration && !isCourierRegistrationExist && <CourierDistrict />}
              {isCreateRegistration && <CreateCourierRegistration />}

              <Modal
                open={createRegisterModal}
                onClose={handleClose}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
                disableEnforceFocus
              >
                <Box
                  sx={style}
                  style={{
                    maxHeight: "90vh",
                    overflowY: "auto",
                  }}
                >
                  <CreateCourierRegistration />
                </Box>
              </Modal>
              {!isCreateRegistration && isCourierRegistrationExist && (
                <Box className="courier-registration-wrapper">
                  <CourierRegisterSearch />
                  <Button
                    className="iyzi-button-blue courier-button"
                    id="basic-button"
                    onClick={handleOpen}
                  >
                    <ControlPointOutlinedIcon />
                    Yeni Kurye Kaydı Oluştur
                  </Button>
                  <CourierList />
                </Box>
              )}
            </animated.div>
          )}
        </Box>
      {/* </PageTransition> */}
    </section>
  );
};

export default CourierRegistration;
