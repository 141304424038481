import { useSpring, animated } from '@react-spring/web'

const SpringIn = ({ children }) => {
  const slideInSpring = useSpring({
    from: { transform: 'translateX(-105%)', opacity: 0 },
    opacity: 1,
    transform: 'translateX(0)',
    config: { mass: 1, tension: 40, friction: 10 }
  })
  return (
    <animated.div className='new-address-modal' style={{ ...slideInSpring }}>
      {children}
    </animated.div>
  )
}

export default SpringIn