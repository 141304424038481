import Footer from "../components/beforeLogin/Footer";
import Header from "../components/beforeLogin/Header";
import Frame from "../components/home/Frame";
import GetFinish from "../components/home/GetFinish";
import HowInfoWorks from "../components/home/HowInfoWorks";
import { DynamicTitle } from "../utils/DynamicTitle";
// import PageTransition from "../utils/PageTransition";
import { Helmet } from "react-helmet";

const HowItWork = () => {
  DynamicTitle("Nasıl Çalışır?: Yurt Dışına Kargo Gönderme Süreci | iyziShip");

  return (
    <>
      <Helmet>
        <meta
          name="title"
          content="Nasıl Çalışır?: Yurt Dışına Kargo Gönderme Süreci | iyziShip"
        />
        <meta
          name="description"
          content="Yurt dışına kargo gönderme işlemlerinizi iyziShip ile nasıl kolaylaştırabileceğinizi öğrenin. Güvenilir, hızlı ve ekonomik lojistik çözümlerimizle tanışın."
        />
        <link rel="canonical" href="https://www.iyziship.com/nasil-calisir"/>
      </Helmet>
      {/* <PageTransition> */}
        <Header />
        <main className="iyzi-main">
          <Frame />
          <HowInfoWorks />
          <GetFinish />
        </main>
        <Footer />
      {/* </PageTransition> */}
    </>
  );
};

export default HowItWork;
