import { Box } from "@mui/material";
import axios from "../../api/axios";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import LocalPrice from "../../utils/LocalPrice";
import DashboardShipmentChart from "./DashboardCharts/DashboardShipmentChart";
import DashboardGoodsChart from "./DashboardCharts/DashboardGoodsChart";
import DashboardCountriesChart from "./DashboardCharts/DashboardCountriesChart";
import DashboardCouriersChart from "./DashboardCharts/DashboardCouriersChart";
import Errors from "../../utils/Errors";
import Currency from "../../utils/CurrencySymbol";

const DashboardShipmentCount = () => {
  const navigate = useNavigate();

  const handleButtonWalletNavigate = () => {
    navigate("/my-wallet");
  };

  const handleButtonSavingsNavigate = () => {
    navigate("/basket");
  };

  const handleButtonNewShipmentsNavigate = () => {
    navigate("/orders");
  };

  const handleButtonSupportNavigate = () => {
    navigate("/support");
  };

  const [totalBalance, setTotalBalance] = useState("0");

  const [totalCome, setTotalCome] = useState([]);
  const [totalGoods, setTotalGoods] = useState([]);
  const [totalShipmentPrice, setTotalShipmentPrice] = useState("0");
  const [bestCountries, setBestCountries] = useState([]);
  const [topCouriers, setTopCouriers] = useState([]);
  const [newShipments, setNewShipments] = useState("0");
  const [declarationDiffrence, setDeclarationDiffrence] = useState("0");
  const [unpaidBasketTotal, setUnpaidBasketTotal] = useState("0");
  const [totalCases, setTotalCases] = useState("0");

  const getData = () => {
    axios
      .get(`dashboard`)
      .then((response) => {
        setTotalBalance(response.data.data_total_balance);
        setTotalCome(response.data.data_total_endorsement_chart);
        setTotalGoods(response.data.datasets_total_percent_parcel);
        setTotalShipmentPrice(response.data.data_total_per_parcels);
        setBestCountries(response.data.data_most_popular_country);
        setTopCouriers(response.data.data_five_use_track);
        setNewShipments(response.data.data_total_new_parcel);
        setDeclarationDiffrence(response.data.data_gains_from_difference);
        setUnpaidBasketTotal(response.data.data_unpaid_total_basket_price);
        setTotalCases(response.data.data_parcels_with_cases);
      })
      .catch((error) => Errors(error));
  };

  useEffect(() => {
    getData();
  }, []);

  return (
    <Box className="dashboard-shipment-count">
      {/* <div className="header">
        <h3 className="">Rapor Dönemi</h3>
      </div> */}
      <div className="shipment-count-wrapper">
        <div className="shipment-count-container">
          <div className="shipment-count-text">
            <div className="shipment-count-text-total">iyziBakiyem</div>
            <div className="shipment-count-text-number blue">
              {LocalPrice(totalBalance)} ₺
            </div>
            <div className="shipment-count-text-footer">
              <div className="shipment-count-text-time">
                Cüzdanda kalan bakiyenizi gösterir
              </div>
              <div className="shipment-count-text-time blue">
                <button
                  onClick={handleButtonWalletNavigate}
                  className="count-button"
                >
                  Detaylı İncele
                </button>
              </div>
            </div>
          </div>
        </div>
        <div className="shipment-count-container">
          <div className="shipment-count-text">
            <div className="shipment-count-text-total">
              Sepette Ödeme Bekleyen Tutar
            </div>
            <div
              className="shipment-count-text-number blue"
              style={{
                color: String(unpaidBasketTotal).includes("-")
                  ? "var(--shipmentRed)"
                  : "var(--shipmentGreen)",
              }}
            >
              { isNaN(unpaidBasketTotal) ? 0 : LocalPrice(unpaidBasketTotal) } ₺
            </div>
            <div className="shipment-count-text-footer">
              <div className="shipment-count-text-time">
                Sepette Ödenmemiş Tutarların Toplamı
              </div>
              <div className="shipment-count-text-time blue">
                <button
                  onClick={handleButtonSavingsNavigate}
                  className="count-button"
                >
                  Detaylı İncele
                </button>
              </div>
            </div>
          </div>
        </div>
        <div className="shipment-count-container">
          <div className="shipment-count-text">
            <div className="shipment-count-text-total">Yeni Gönderiler</div>
            <div className="shipment-count-text-number blue">
              {newShipments}
            </div>
            <div className="shipment-count-text-footer">
              <div className="shipment-count-text-time">
                Etiket Oluşturulmamış Gönderiler
              </div>
              <div className="shipment-count-text-time blue">
                <button
                  onClick={handleButtonNewShipmentsNavigate}
                  className="count-button"
                >
                  Detaylı İncele
                </button>
              </div>
            </div>
          </div>
        </div>
        <div className="shipment-count-container">
          <div className="shipment-count-text">
            <div className="shipment-count-text-total">
              iyziTeam Ölçümlerinden Kazanımlarım
            </div>
            <div
              className="shipment-count-text-number blue"
              style={{
                color: String(declarationDiffrence).includes("-")
                  ? "var(--shipmentRed)"
                  : "var(--shipmentGreen)",
              }}
            >
              {LocalPrice(declarationDiffrence)} ₺
            </div>
            <div className="shipment-count-text-footer">
              <div className="shipment-count-text-time">
                iyziTeam Ölçümü Sonucu iyziBakiyenize İade Edilen Tutar
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="dashboard-shipment-line">
        <DashboardShipmentChart totalCome={totalCome} />
      </div>
      <div className="shipment-count-wrapper pie">
        <div className="dashboard-shipment-pie">
          <DashboardCouriersChart topCouriers={topCouriers} />
        </div>

        <div className="pie-counts">
          <div className="shipment-count-container">
            <div className="shipment-count-text">
              <div className="shipment-count-text-total">
                Ortalama Gönderi Maliyeti
              </div>
              <div className="shipment-count-text-number blue">
                {Currency("USD")}
                {LocalPrice(totalShipmentPrice)}
              </div>
              <div className="shipment-count-text-time">
                Gönderi başına ödediğim ortalama ücret
              </div>
            </div>
          </div>
          <div className="shipment-count-container">
            <div className="shipment-count-text">
              <div className="shipment-count-text-total">Destek Dosyalarım</div>
              <div className="shipment-count-text-number blue">
                {totalCases}
              </div>
              <div className="shipment-count-text-footer">
                <div className="shipment-count-text-time">
                  Açık destek taleplerim
                </div>
                <div className="shipment-count-text-time blue">
                  <button
                    onClick={handleButtonSupportNavigate}
                    className="count-button"
                  >
                    Detaylı İncele
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="pie shipment-count-wrapper">
        <div className="dashboard-shipment-pie">
          <DashboardGoodsChart totalGoods={totalGoods} />
        </div>
        <div className="dashboard-shipment-pie">
          <DashboardCountriesChart bestCountries={bestCountries} />
        </div>
      </div>
    </Box>
  );
};

export default DashboardShipmentCount;
