import { Box, Button } from "@mui/material";
import QuestionAnswerIcon from "@mui/icons-material/QuestionAnswer";
import { useCallback } from "react";
import { useRecoilState } from "recoil";
import { openSupportRequestState } from "../../atoms/support";

const NoSupport = () => {
  const [, setOpenSupportRequest] = useRecoilState(openSupportRequestState);

  const handleSupportRequest = useCallback(() => {
    setOpenSupportRequest(true);
  }, [setOpenSupportRequest]);

  return (
    <Box className="no-support">
      <img
        className="no-support-img"
        src="/images/support/support_question_mark.svg"
        alt="Destek Talebiniz Yok Görseli"
      />
      <p className="no-support-text">Destek Talebiniz bulunmamaktadır.</p>
      <Button onClick={handleSupportRequest} className="no-support-button">
        <QuestionAnswerIcon />
        Destek Talebi Oluştur
      </Button>
    </Box>
  );
};

export default NoSupport;
