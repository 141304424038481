const IntegrationMarketplace = () => {
  const images = [
    {
      src: "/images/integrations/etsy-wb.png",
      alt: "Etsy",
    },
    {
      src: "/images/integrations/amazon-wb.png",
      alt: "Amazon",
    },
    {
      src: "/images/integrations/aliExpress-wb.png",
      alt: "Ali Express",
    },
    {
      src: "/images/integrations/ebay-wb.png",
      alt: "Ebay",
    },
    {
      src: "/images/integrations/wish-wb.png",
      alt: "Wish",
    },
    {
      src: "/images/integrations/otto-wb.png",
      alt: "Otto",
    },
    {
      src: "/images/integrations/walmart-wb.png",
      alt: "Walmart",
    },
    {
      src: "/images/integrations/ozon-wb.png",
      alt: "Ozon",
    },
  ];

  return (
    <section className="main-integration">
      <div className="integration-wrapper">
        <h2 style={{ marginBottom: "var(--gap3x)" }}>
          Pazaryeri Entegrasyonları
        </h2>
        <div className="integration-container">
          {images.map((image) => (
            <img src={image.src} alt={image.alt} />
          ))}
        </div>
      </div>
    </section>
  );
};

export default IntegrationMarketplace;
