import { TableCell, TableHead, TableRow } from "@mui/material";
import { useCallback, useState } from "react";
import axios from "../../../api/axios";
import { useRecoilState } from "recoil";
import {
  walletArrangementCurrentPageState,
  walletArrangementDataState,
  walletArrangementTotalPageState,
} from "../../../atoms/myWallet";
import KeyboardArrowDownOutlinedIcon from "@mui/icons-material/KeyboardArrowDownOutlined";
import Errors from "../../../utils/Errors";

const MyWalletArrangementTableHead = () => {
  const user = JSON.parse(localStorage.getItem("user"));
  const memberType = user?.user?.user_member?.member_type;

  const [, setWalletArrangementData] = useRecoilState(walletArrangementDataState);
  const [walletArrangementCurrentPage, setWalletArrangementCurrentPage] = useRecoilState(
    walletArrangementCurrentPageState
  );
  const [, setWalletArrangementTotalPage] = useRecoilState(walletArrangementTotalPageState);

  const [activeSort, setActiveSort] = useState([
    { column: "created_at", direction: null },
    { column: "member_invoice_no", direction: null },
    { column: "member_payable_amount", direction: null },
    { column: "invoice_note", direction: null },
    { column: "status", direction: null },
  ]);

  const handlePageChange = useCallback(
    async (orderBy) => {
      const updatedSort = activeSort.map((sort) => {
        if (sort.column === orderBy) {
          return {
            ...sort,
            direction: sort.direction === "ASC" ? "DESC" : "ASC",
          };
        }
        return {
          ...sort,
          direction: null, // Diğer sütunların yönünü varsayılan duruma sıfırlayın
        };
      });

      setActiveSort(updatedSort);

      const currentDirection = updatedSort.find((sort) => sort.column === orderBy).direction;

      await axios
        .get(`refund-compensation`, {
          params: {
            page: walletArrangementCurrentPage,
            limit: 100,
            order_by: orderBy,
            order_direction: currentDirection,
          },
        })
        .then((resp) => {
          setWalletArrangementData(resp.data.data);
          setWalletArrangementCurrentPage(resp.data.currentPage);
          setWalletArrangementTotalPage(resp.data.totalPages);
        })
        .catch((error) => Errors(error));
    },
    [
      setWalletArrangementData,
      setWalletArrangementCurrentPage,
      setWalletArrangementTotalPage,
      walletArrangementCurrentPage,
      activeSort,
    ]
  );

  return (
    <TableHead>
      <TableRow>
        <TableCell
          onClick={() => handlePageChange("created_at")}
          sx={{ fontWeight: 700, cursor: "pointer" }}
        >
          <div style={{ fontSize: "inherit", display: "flex", gap: "5px" }}>
            İşlem Tarihi{" "}
            <KeyboardArrowDownOutlinedIcon
              sx={{
                transform:
                  activeSort.find((sort) => sort.column === "created_at")?.direction === "ASC"
                    ? "rotate(180deg)"
                    : "rotate(0deg)",
                transition: "transform 0.3s",
              }}
            />
          </div>
        </TableCell>
        <TableCell
          onClick={() => handlePageChange("member_invoice_no")}
          sx={{ fontWeight: 700, cursor: "pointer" }}
        >
          <div style={{ fontSize: "inherit", display: "flex", gap: "5px" }}>
            Fatura No{" "}
            <KeyboardArrowDownOutlinedIcon
              sx={{
                transform:
                  activeSort.find((sort) => sort.column === "member_invoice_no")?.direction ===
                  "ASC"
                    ? "rotate(180deg)"
                    : "rotate(0deg)",
                transition: "transform 0.3s",
              }}
            />
          </div>
        </TableCell>
        <TableCell
          onClick={() => handlePageChange("member_payable_amount")}
          sx={{ fontWeight: 700, cursor: "pointer" }}
        >
          <div style={{ fontSize: "inherit", display: "flex", gap: "5px" }}>
            Tutar{" "}
            <KeyboardArrowDownOutlinedIcon
              sx={{
                transform:
                  activeSort.find((sort) => sort.column === "member_payable_amount")?.direction ===
                  "ASC"
                    ? "rotate(180deg)"
                    : "rotate(0deg)",
                transition: "transform 0.3s",
              }}
            />
          </div>
        </TableCell>
        <TableCell
          onClick={() => handlePageChange("invoice_note")}
          sx={{ fontWeight: 700, cursor: "pointer" }}
        >
          <div style={{ fontSize: "inherit", display: "flex", gap: "5px" }}>
            Açıklama{" "}
            <KeyboardArrowDownOutlinedIcon
              sx={{
                transform:
                  activeSort.find((sort) => sort.column === "invoice_note")?.direction === "ASC"
                    ? "rotate(180deg)"
                    : "rotate(0deg)",
                transition: "transform 0.3s",
              }}
            />
          </div>
        </TableCell>
        <TableCell
          onClick={() => handlePageChange("status")}
          sx={{ fontWeight: 700, cursor: "pointer" }}
        >
          <div style={{ fontSize: "inherit", display: "flex", gap: "5px" }}>
            Durum{" "}
            <KeyboardArrowDownOutlinedIcon
              sx={{
                transform:
                  activeSort.find((sort) => sort.column === "status")?.direction === "ASC"
                    ? "rotate(180deg)"
                    : "rotate(0deg)",
                transition: "transform 0.3s",
              }}
            />
          </div>
        </TableCell>
        <TableCell sx={{ fontWeight: 700 }}>Muvafakatname </TableCell>
        {memberType === "Company" && <TableCell sx={{ fontWeight: 700 }}>Fatura</TableCell>}
      </TableRow>
    </TableHead>
  );
};

export default MyWalletArrangementTableHead;
