import { Autocomplete, Box, TextField } from "@mui/material";
import CountriesTurkishSorted from "../../../utils/CountriesTurkishSorted";

const OrderReceiverCountrySearch = ({ orderRecCountry, setOrderRecCountry }) => {
  return (
    <Autocomplete
      id="country-receiver-country-select"
      fullWidth
      size="small"
      options={CountriesTurkishSorted()}
      autoHighlight
      value={CountriesTurkishSorted().find((option) => option.iso_code === orderRecCountry) || null}
      onChange={(e, val) => {
        if (val) {
          setOrderRecCountry(val.iso_code);
        } else {
          setOrderRecCountry("");
        }
      }}
      getOptionLabel={(option) => `${option.country_name_tr}, ${option.country_name_en}`}
      renderOption={(props, option) => (
        <Box component="li" sx={{ "& > img": { mr: 2, flexShrink: 0 } }} {...props}>
          <img
            loading="lazy"
            width="20"
            src={`https://flagcdn.com/w20/${option.iso_code.toLowerCase()}.png`}
            srcSet={`https://flagcdn.com/w40/${option.iso_code.toLowerCase()}.png 2x`}
            alt=""
          />
          {option.country_name_tr}, {option.country_name_en}
        </Box>
      )}
      renderInput={(params) => (
        <TextField
          {...params}
          autoComplete="off"
          label="Alıcı Ülkesi"
          value={orderRecCountry}
          inputProps={{
            ...params.inputProps,
            autoComplete: "new-password", // disable autocomplete and autofill
          }}
        />
      )}
    />
  );
};

export default OrderReceiverCountrySearch;
