import { Button, Modal, Box, Typography } from "@mui/material";
import CancelOutlinedIcon from "@mui/icons-material/CancelOutlined";
import React, { useState } from "react";

const Frame = () => {
  const [modalOpen, setModalOpen] = useState(false);

  const isHowItWork = () => {
    return document.location.pathname === "/nasil-calisir";
  };

  const handleClick = () => {
    setModalOpen(true);
  };

  const handleClose = () => {
    setModalOpen(false);
  };

  return (
    <div className="main-frame">
      <div
        className="frame-wrapper"
        style={{ paddingBottom: isHowItWork() ? "15px" : "20px" }}
      >
        <article>
          <h2>iyziShip Nasıl Çalışır?</h2>
          <p>
            Uluslararası e-ticaret arenası, iyziShip ile artık daha
            erişilebilir! Yurt dışına satış gerçekleştiren işletmeler ve
            bireyler için, gönderi süreçlerini kolaylaştırıyoruz. Yurt dışı
            kargo teklifi alımından, hızlı ve güvenilir gönderiye kadar olan tüm
            lojistik süreçlerinizde yanınızdayız.
          </p>
        </article>

        <Button
          sx={{
            display: "flex",
            gap: "15px",
            padding: "10px 30px",
            fontWeight: 700,
          }}
          className="iyzi-button-white"
          onClick={handleClick}
        >
          <img src="/images/icons/playButton.svg" alt="Bir Gönderi Hikayesi" />{" "}
          Bir Gönderi Hikayesi
        </Button>
      </div>

      <Modal
        open={modalOpen}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box
          sx={{
            position: "absolute",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            width: "70%",
            height: "70%",
            bgcolor: "background.paper",
            boxShadow: 24,
            p: 4,
            borderRadius: 5,
          }}
        >
          <Typography
            sx={{
              display: "flex",
              justifyContent: "flex-end",
              alignItems: "center",
              lineHeight: "1.5",
            }}
            id="modal-modal-title"
            variant="h6"
            component="h2"
          >
            <CancelOutlinedIcon
              sx={{
                right: 1,
                top: 1,
                cursor: "pointer",
                color: "var(--priceText)",
                transition: "all 80ms linear",
                ":hover": {
                  color: "var(--notRed)",
                  transition: "all 80ms linear",
                },
              }}
              onClick={handleClose}
            />
            <br /> <br />
          </Typography>
          <iframe
            width="100%"
            height="85%"
            src="https://www.youtube.com/embed/ClY9lrjy_gA"
            title="iyziShip Nasıl Çalışır? | Bir Gönderi Hikayesi"
            frameborder="0"
            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
            allowfullscreen
          ></iframe>
        </Box>
      </Modal>
    </div>
  );
};

export default Frame;
