import { useState, useEffect } from "react";
import { NavLink } from "react-router-dom";
import axios from "../../api/axios";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { useRecoilState } from "recoil";
import Loading, { loadingState } from "../../atoms/loading";
import { DynamicTitle } from "../../utils/DynamicTitle";
import { useCookies } from "react-cookie";
import {
  Button,
  CircularProgress,
  FormControl,
  IconButton,
  InputAdornment,
  InputLabel,
  OutlinedInput,
  TextField,
} from "@mui/material";
import { Visibility, VisibilityOff } from "@mui/icons-material";
import { validateEmail } from "../../utils/emailValidation";
import Errors from "../../utils/Errors";

const Login = () => {
  DynamicTitle("Giriş Yap | iyziShip");
//  const setMemberStatusModal = useSetRecoilState(memberStatusModalState);

  const [email, setEmail] = useState("");
  const [loading, setLoading] = useRecoilState(loadingState);
  const [values, setValues] = useState({
    password: "",
    showPassword: false,
  });

  const [isMailPassive, setIsMailPassive] = useState(false);
  const [mailLoading, setMailLoading] = useState(false);
  const [isEmailValid, setIsEmailValid] = useState(true);
  const [emailError, setEmailError] = useState(false);

  const [, setCookies] = useCookies(["jwt"]);

  const handleChange = (prop) => (e) => {
    setValues({ ...values, [prop]: e.target.value });
  };

  const handleClickShowPassword = () => {
    setValues({
      ...values,
      showPassword: !values.showPassword,
    });
  };

  const handleMouseDownPassword = (e) => {
    e.preventDefault();
  };

  const handleLogin = async (e) => {
    e.preventDefault();
    setLoading(true);
    await axios
      .post("login", {
        email,
        password: values.password,
      })
      .then((response) => {
        if (response.status === 200) {
          const accessToken = response.data.access_token;

          setCookies("jwt", accessToken, { path: "/" });

          const user = response.data.user;
          const tokenType = response.data.token_type;
          const expireIn = response.data.expires_in;
          const expireTime = response.data.expires_time;
          // const memberStatus = Number(response.data.user.user_member.status);

          // if (memberStatus !== 2) {
          // setMemberStatusModal(true);
          // }

          localStorage.setItem("user", JSON.stringify({ user, tokenType, expireIn, expireTime }));
          toast.success("Giriş Başarılı");
        }
      })
      .catch((error) => {
        Errors(error);

        if (error.response.status === 401) {
          setIsMailPassive(true);
        }
      })
      .finally(() => setLoading(false));
  };

  const handleActivateMailLink = () => {
    setMailLoading(true);

    axios
      .post("email/verify", {
        email,
      })
      .then((response) => {
        toast.success(response.data.message);
      })
      .catch((error) => Errors(error))
      .finally(() => {
        setMailLoading(false);
        setIsMailPassive(false);
      });
  };

  const handleEmailChange = (e) => {
    const newEmail = e.target.value;

    setEmail(newEmail);

    if (!newEmail) {
      setIsEmailValid(false);
    } else {
      const isValidEmail = validateEmail(newEmail);
      setIsEmailValid(isValidEmail);
    }
  };

  useEffect(() => {
    setEmailError(!isEmailValid);
  }, [isEmailValid]);

  return (
    <div className="sign-mail-wrapper">
      <form onSubmit={handleLogin} className="sign-mail">
        <ToastContainer />
        <TextField
          fullWidth
          value={email}
          onChange={handleEmailChange}
          error={emailError}
          helperText={emailError && "Geçerli bir email giriniz."}
          autoComplete="on"
          inputMode="email"
          type="email"
          id="outlined-register-email"
          label="Email Adresiniz"
          variant="outlined"
          required
        />
        <FormControl fullWidth variant="outlined">
          <InputLabel htmlFor="outlined-adornment-password">Şifre</InputLabel>
          <OutlinedInput
            id="outlined-adornment-password"
            type={values.showPassword ? "text" : "password"}
            value={values.password}
            autoComplete="off"
            onChange={handleChange("password")}
            onKeyDown={(e) => (e.key === "Enter" ? handleLogin : "")}
            required
            endAdornment={
              <InputAdornment position="end">
                <IconButton
                  aria-label="toggle password visibility"
                  onClick={handleClickShowPassword}
                  onMouseDown={handleMouseDownPassword}
                  edge="end"
                >
                  {values.showPassword ? <VisibilityOff /> : <Visibility />}
                </IconButton>
              </InputAdornment>
            }
            label="Password"
          />
          <div className={isMailPassive ? "sign-mail-links" : "sign-mail-links mail-active"}>
            {isMailPassive &&
              (mailLoading ? (
                <div
                  style={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    width: "150px",
                  }}
                >
                  <CircularProgress sx={{ width: "21px!important", height: "21px!important" }} />
                </div>
              ) : (
                <Button onClick={handleActivateMailLink} className="sign-mail-confirmation">
                  Email Onayı Gönder
                </Button>
              ))}
            <NavLink to="/forgot" className="sign-mail-forgot">
              Şifremi Unuttum
            </NavLink>
          </div>
        </FormControl>
        {loading ? (
          <Loading />
        ) : (
          <Button
            type="submit"
            className="iyzi-button-sea"
            fullWidth
            sx={{ ":disabled": { opacity: 0.4 }, fontSize: "14px" }}
          >
            Giriş Yap
          </Button>
        )}
      </form>
    </div>
  );
};

export default Login;
