import "../../style/globals/PageHeaderTitle.css";
import HelpOutlineOutlinedIcon from "@mui/icons-material/HelpOutlineOutlined";
import WrapperStyle from "../../utils/WrapperStyle";
import { useState } from "react";

const MyShipmentsTitle = () => {
  const [open, setOpen] = useState(false);
  const handleOpenVideo = () => {
    setOpen(true);
  };

  return (
    <>
      {/* <PageHowToWork
        open={open}
        setOpen={setOpen}
        iframe={}
      /> */}
      <div className="page-header-title" style={WrapperStyle()}>
        <div className="page-header-title-wrapper">
          <div className="page-header-title-headline">
            <svg
              width="24"
              height="24"
              viewBox="0 0 24 24"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <mask
                id="mask0_6114_17436"
                style={{ maskType: "alpha" }}
                maskUnits="userSpaceOnUse"
                x="0"
                y="0"
                width="24"
                height="24"
              >
                <rect y="0.5" width="24" height="24" fill="#D9D9D9" />
              </mask>
              <g mask="url(#mask0_6114_17436)">
                <path
                  d="M5 8.5V19.5H19V8.5H16V16.5L12 14.5L8 16.5V8.5H5ZM5 21.5C4.45 21.5 3.97917 21.3042 3.5875 20.9125C3.19583 20.5208 3 20.05 3 19.5V7.025C3 6.79167 3.0375 6.56667 3.1125 6.35C3.1875 6.13333 3.3 5.93333 3.45 5.75L4.7 4.225C4.88333 3.99167 5.1125 3.8125 5.3875 3.6875C5.6625 3.5625 5.95 3.5 6.25 3.5H17.75C18.05 3.5 18.3375 3.5625 18.6125 3.6875C18.8875 3.8125 19.1167 3.99167 19.3 4.225L20.55 5.75C20.7 5.93333 20.8125 6.13333 20.8875 6.35C20.9625 6.56667 21 6.79167 21 7.025V19.5C21 20.05 20.8042 20.5208 20.4125 20.9125C20.0208 21.3042 19.55 21.5 19 21.5H5ZM5.4 6.5H18.6L17.75 5.5H6.25L5.4 6.5ZM10 8.5V13.25L12 12.25L14 13.25V8.5H10Z"
                  fill="currentColor"
                />
              </g>
            </svg>
            <h1>Gönderilerim</h1>
          </div>
          {/* <Button
            sx={{ textTransform: "none" }}
            onClick={handleOpenVideo}
            className="page-header-title-how-link"
          >
            <HelpOutlineOutlinedIcon />
            <span>Bu Sayfa Nasıl Kullanılır?</span>
          </Button> */}
        </div>
      </div>
    </>
  );
};

export default MyShipmentsTitle;
