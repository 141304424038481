import {
  Avatar,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  ThemeProvider,
  createTheme,
} from "@mui/material";
import axios from "../../../api/axios";
import Errors from "../../../utils/Errors";
import { useCallback, useEffect, useState } from "react";

const theme = createTheme({
  components: {
    MuiOutlinedInput: {
      styleOverrides: {
        input: {
          display: "flex",
          alignItems: "center",
        },
      },
    },
  },
});

const OrderSupplierIDSearch = ({ orderSupplierID, setOrderSupplierID }) => {
  const [suppliers, setSuppliers] = useState([]);
  const handleChange = (e) => {
    setOrderSupplierID(e.target.value);
  };

  const getData = useCallback(() => {
    axios
      .get(`supplier`)
      .then((response) => setSuppliers(response.data.data))
      .catch((error) => Errors(error));
  }, [setSuppliers]);

  useEffect(() => {
    getData();
  }, [getData]);

  return (
    <ThemeProvider theme={theme}>
      <FormControl size="small">
        <InputLabel id="supplier-id-select">Tedarikçi</InputLabel>
        <Select
          labelId="supplier-id-select"
          id="supplier-id-field"
          name="supplier-id-field"
          value={orderSupplierID}
          label="Tedarikçi"
          onChange={handleChange}
        >
          <MenuItem value={""}>Hepsi</MenuItem>
          {suppliers.map((sup) => (
            <MenuItem key={sup.id} value={sup.id}>
              <Avatar
                sx={{ height: "20px", width: "20px", marginRight: "5px" }}
                src={`images/${sup.supplier_logo}`}
              />
              {sup.supplier_name}
            </MenuItem>
          ))}
        </Select>
      </FormControl>
    </ThemeProvider>
  );
};

export default OrderSupplierIDSearch;
