import { Button, CircularProgress, TextField } from "@mui/material";
import React from "react";
import { useEffect } from "react";
import { useState } from "react";
import { useNavigate } from "react-router-dom";
import { toast, ToastContainer } from "react-toastify";
import axios from "../../api/axios";
import Errors from "../../utils/Errors";

const ResetPasswordGet = () => {
  const navigate = useNavigate();

  const [pwd, setPwd] = useState("");
  const [pwdRpt, setPwdRpt] = useState("");
  const [errorLength, setErrorLength] = useState(false);
  const [error, setError] = useState(false);
  const [error2, setError2] = useState(false);
  const [errorRpt, setErrorRpt] = useState(false);
  const [errorRpt2, setErrorRpt2] = useState(false);
  const [loading, setLoading] = useState(false);

  const queryParams = new URLSearchParams(window.location.search);
  const token = queryParams.get("token");

  const resetPassword = (e) => {
    e.preventDefault();

    if (pwd.length === 0) {
      setError(true);
    }

    if (pwdRpt.length === 0) {
      setErrorRpt(true);
    }

    if (pwd !== pwdRpt) {
      setErrorRpt2(true);
    }

    if (pwd === pwdRpt && pwd.length < 6) {
      setErrorLength(true);
    }

    if (!errorLength && !error && !error2 && !errorRpt && !errorRpt2 && token.length !== 0) {
      setLoading(true);
      axios
        .post("update-password", {
          token,
          password: pwd,
          password_confirmation: pwdRpt,
        })
        .then((response) => {
          if (response.data.status === 422) {
            toast.error(response.data.message, { autoClose: 500 });
          }

          if (response.data.status === 200) {
            toast.success(response.data.message);
            setTimeout(() => {
              navigate("/login");
            }, 2000);
          }
        })
        .catch((error) => Errors(error))
        .finally(() => setLoading(false));
    }
  };

  useEffect(() => {
    if (pwd.length !== 0) {
      setError(false);
      setError2(false);
      setErrorLength(false);
    }

    if (pwdRpt.length !== 0) {
      setErrorRpt(false);
      setErrorRpt2(false);
    }
  }, [pwd, pwdRpt]);

  useEffect(() => {
    setTimeout(() => {
      if (token.length === 0) {
        navigate("/login");
      }
    }, 2000);
  }, [navigate, token]);

  return (
    <form onSubmit={resetPassword} className="forgot-reset">
      <TextField
        fullWidth
        size="small"
        type="password"
        id="outlined-basic"
        label="Parola"
        value={pwd}
        onChange={(e) => setPwd(e.target.value)}
        variant="outlined"
        error={error || error2 || errorLength}
        helperText={
          error ? "Parolanızı Giriniz" : errorLength ? "Parolanızın Uzunluğu En Az 6 Olmalıdır" : ""
        }
      />
      <TextField
        fullWidth
        size="small"
        type="password"
        id="outlined-basic"
        label="Parola Tekrarı"
        value={pwdRpt}
        onChange={(e) => setPwdRpt(e.target.value)}
        variant="outlined"
        error={errorRpt || errorRpt2}
        helperText={
          !error && errorRpt
            ? "Parola Tekrarınızı Giriniz"
            : !error && errorRpt2
            ? "Parola ve Parola Tekrarı Aynı Olmak Zorunda"
            : ""
        }
      />
      {loading ? (
        <div style={{ display: "flex", width: "100%", justifyContent: "center" }}>
          <CircularProgress sx={{ height: "40px !important" }} />
        </div>
      ) : (
        <Button
          type="submit"
          className="iyzi-button-sea"
          sx={{ ":disabled": { opacity: 0.4 }, fontSize: "14px" }}
        >
          Şifremi Değiştir
        </Button>
      )}
      <ToastContainer />
    </form>
  );
};

export default ResetPasswordGet;
