import { Box, CircularProgress, createTheme } from "@mui/material";
import { useEffect } from "react";
import SquareFootOutlinedIcon from "@mui/icons-material/SquareFootOutlined";
import { useInView, animated } from "@react-spring/web";
import Dimensions from "./registeredDimensions/Dimensions";
import { ThemeProvider } from "@emotion/react";
import axios from "../../api/axios";
import { useRecoilState } from "recoil";
import { dimensionListArrayState } from "../../atoms/profileSettings";
import "../../style/profileSettings/RegisteredDimensions.css";
import NotificationBar from "../sidebar/NotificationBar";
import { DynamicTitle } from "../../utils/DynamicTitle";
import ProfileNavBar from "./ProfileNavBar";
import { useState } from "react";
import { ToastContainer } from "react-toastify";
import WrapperStyle from "../../utils/WrapperStyle";
import ProfileSettingsStyle from "../../style/styleJs/ProfileSettingsStyle";
import EditDimension from "./registeredDimensions/EditDimension";
import Errors from "../../utils/Errors";
import { useCookies } from "react-cookie";
import ProfileSettingsTitle from "./ProfileSettingsTitle";

const RegisteredDimensions = () => {
  DynamicTitle("Kayıtlı Ölçüler");

  const [token] = useCookies(["jwt"]);
  const jwt = token.jwt;
  axios.defaults.headers.common["Authorization"] = `Bearer ${jwt}`;

  const [, setDimensionListArray] = useRecoilState(dimensionListArrayState);

  const [loading, setLoading] = useState(false);

  const theme = createTheme({
    components: {
      MuiCardContent: {
        styleOverrides: {
          root: {
            padding: "0 0 24px 0",
          },
        },
      },
    },
  });

  const [ref, springs] = useInView(
    () => ({
      from: {
        opacity: 0,
        y: 20,
      },
      to: {
        opacity: 1,
        y: 0,
      },
    }),
    {
      rootMargin: "-10% 0%",
    }
  );

  useEffect(() => {
    setLoading(true);
    axios
      .get("member-dimension")
      .then((response) => {
        setLoading(false);
        setDimensionListArray(response.data.data);
      })
      .catch((error) => {
        setLoading(false);
        Errors(error);
      });
  }, [setDimensionListArray]);

  return (
    <ThemeProvider theme={theme}>
      <Box className="registered-dimensions">
        <NotificationBar />
        <ProfileSettingsTitle />
        <ProfileNavBar />
        <ToastContainer />
        <EditDimension />
        <div style={WrapperStyle()}>
          <animated.div style={springs} ref={ref}>
            <div style={ProfileSettingsStyle()} className="dimensions">
              <div className="profile-card-wrapper">
                <div className="profile-card-header">
                  <SquareFootOutlinedIcon
                    sx={{
                      width: "20px",
                      height: "20px",
                      color: "var(--priceText)",
                    }}
                  />
                  <span>Kayıtlı Ölçüler</span>
                </div>
              </div>
              {loading ? (
                <div style={{ width: "100%", display: "flex" }}>
                  <CircularProgress sx={{ margin: "auto" }} />
                </div>
              ) : (
                <Dimensions />
              )}
            </div>
          </animated.div>
        </div>
      </Box>
    </ThemeProvider>
  );
};

export default RegisteredDimensions;
