import { useRecoilState } from "recoil";
import { startCardAnimationState } from "../../../atoms/startCardAnimation";
import SpringCardIn from "./SpringCardIn";
import {
  Box,
  Button,
  Checkbox,
  CircularProgress,
  FormControlLabel,
  TextField,
} from "@mui/material";
import { useState } from "react";
import maskCardNumber from "../../../utils/maskCardNumber";
import ReactCardFlip from "react-card-flip";
import axios from "../../../api/axios";
import { useCookies } from "react-cookie";
import CardImgChange from "../../../utils/CardImgChange";
import { useCallback } from "react";
import { useEffect } from "react";
import { toast } from "react-toastify";
import CCard from "../../../utils/CCard";
import toTurkishUpperCase from "../../../utils/toTurkishUpperCase";
import Errors from "../../../utils/Errors";

const CreateCard = () => {
  const [token] = useCookies(["jwt"]);
  const jwt = token?.jwt;
  axios.defaults.headers.common["Authorization"] = `Bearer ${jwt}`;

  const [startCardAnimation, setStartCardAnimation] = useRecoilState(
    startCardAnimationState
  );

  const [isFlipped, setIsFlipped] = useState(false);
  const [loading, setLoading] = useState(false);

  const [isDefaultCard, setIsDefaultCard] = useState(false);
  const [fullName, setFullName] = useState("");
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [creditCard, setCreditCard] = useState("");
  const [errorMessageShow, setErrorMessageShow] = useState(false);
  const [month, setMonth] = useState("");
  const [year, setYear] = useState("");
  const [cvc2, setCvc2] = useState("");

  const creditCardChanger = (e) => {
    maskCardNumber(e);
    setCreditCard(CCard(e));
  };

  const handleMonthChange = (e) => {
    const value = e.target.value;
    if (value === "" || Number(value) <= 12) {
      setMonth(value);
    }
  };

  const handleMonthBlur = (e) => {
    let value = e.target.value;

    if (value === "0" || value > 12 || value < 1) {
      setMonth("");
    } else if (value.length === 1) {
      value = "0" + value;
      setMonth(value);
    }
  };

  const handleYearChange = (e) => {
    let currentYear = new Date().getFullYear();

    // Yılın son iki hanesini al
    currentYear = currentYear.toString().slice(-2);

    let yearValue = e.target.value;
    if (
      yearValue < 0 ||
      yearValue > 99 ||
      isNaN(yearValue) ||
      (yearValue.length === 2 && yearValue < currentYear)
    ) {
      setYear("");
    } else {
      setYear(yearValue);
    }
  };

  const handleYearBlur = (e) => {
    let value = e.target.value;
    if (value.length < 2) {
      setYear("");
    }
  };

  const handleCvc2Change = (e) => {
    const value = e.target.value;
    if (value.match(/^[0-9]*$/)) {
      setCvc2(value);
    }
  };

  const handleCvc2Blur = (e) => {
    setIsFlipped(false);
    let value = e.target.value;
    if (value.length < 3) {
      setCvc2("");
    }
  };

  const handleClose = useCallback(() => {
    setStartCardAnimation(false);
    setFirstName("");
    setLastName("");
    setCreditCard("");
    setMonth("");
    setYear("");
    setCvc2("");
  }, [setStartCardAnimation]);

  const handleAddCard = useCallback(
    async (e) => {
      e.preventDefault();

      if (creditCard.length === 0) {
        setErrorMessageShow(true);
      }

      if (creditCard.length > 0 && creditCard.length < 19) {
        setErrorMessageShow(true);
      }

      if (creditCard.length === 19) {
        setLoading(true);
      }

      await axios
        .post(`profile/store-card`, {
          is_default_card: isDefaultCard,
          from: "IYZICO",
          pan: Number(creditCard.replaceAll(" ", "")),
          expire_month: Number(month),
          expire_year: 2000 + Number(year),
        })
        .then((response) => {
          setLoading(false);
          if (response.data.status === 400) {
            toast.error(response.data.message);
          }

          if (response.data.status === 200) {
            toast.success("Kartınız Sistemimize Başarıyla Eklenmiştir.", {
              autoClose: 500,
            });
            setTimeout(() => {
              window.location.reload(true);
            }, 1000);
          }
        })
        .catch((error) => {
          setLoading(false);
          setErrorMessageShow(true);
          Errors(error);
        })
        .finally(() => {
          setLoading(false);
        });
    },
    [creditCard, month, year, isDefaultCard]
  );

  useEffect(() => {
    setFullName(`${firstName} ${lastName}`);
  }, [firstName, lastName]);

  const isDisabled = useCallback(() => {
    if (
      firstName === "" ||
      lastName === "" ||
      fullName === "" ||
      creditCard === "" ||
      month === "" ||
      year === "" ||
      cvc2 === ""
    ) {
      return true;
    } else return false;
  }, [firstName, lastName, fullName, creditCard, month, year, cvc2]);

  useEffect(() => {
    if (creditCard.length !== 0) {
      setErrorMessageShow(false);
    }

    if (creditCard.length === 19) {
      setErrorMessageShow(false);
    }
  }, [creditCard]);

  return (
    <>
      {startCardAnimation && (
        <SpringCardIn>
          <form onSubmit={handleAddCard} className="default-card">
            <Box className="card-info-wrapper">
              <div className="card-info-text">
                <FormControlLabel
                  sx={{ userSelect: "none" }}
                  control={
                    <Checkbox
                      checked={isDefaultCard}
                      onChange={(e) => setIsDefaultCard(e.target.checked)}
                    />
                  }
                  label="Varsayılan Kart"
                />
                <TextField
                  value={firstName}
                  onChange={(e) =>
                    setFirstName(toTurkishUpperCase(e.target.value))
                  }
                  size="small"
                  fullWidth
                  label="İsim"
                  autoComplete="off"
                />

                <TextField
                  value={lastName}
                  onChange={(e) =>
                    setLastName(toTurkishUpperCase(e.target.value))
                  }
                  size="small"
                  fullWidth
                  label="Soyisim"
                  autoComplete="off"
                />

                <TextField
                  value={creditCard}
                  onChange={creditCardChanger}
                  size="small"
                  inputMode="numeric"
                  inputProps={{ maxLength: 19 }}
                  fullWidth
                  label="Kart Numarası"
                  placeholder="1111 0000 2222 4444"
                  autoComplete="off"
                  error={errorMessageShow}
                />
                <Box className="card-info-text-date">
                  <TextField
                    size="small"
                    label="Ay"
                    value={month}
                    onChange={handleMonthChange}
                    onBlur={handleMonthBlur}
                    placeholder="03"
                    sx={{ maxWidth: "75px" }}
                    autoComplete="off"
                    inputProps={{ maxLength: 2 }}
                  />
                  <TextField
                    size="small"
                    label="Yıl"
                    value={year}
                    onChange={handleYearChange}
                    onBlur={handleYearBlur}
                    placeholder="23"
                    sx={{ maxWidth: "75px" }}
                    inputProps={{ maxLength: 2 }}
                    autoComplete="off"
                  />
                  <TextField
                    size="small"
                    label="CVC2"
                    placeholder="CVC2"
                    sx={{ maxWidth: "75px" }}
                    inputProps={{ maxLength: 3 }}
                    autoComplete="off"
                    value={cvc2}
                    type="password"
                    inputMode="numeric"
                    onChange={handleCvc2Change}
                    onBlur={handleCvc2Blur}
                    onFocus={() => setIsFlipped(true)}
                  />
                </Box>
              </div>
              <div className="card-info-display-wrapper">
                <ReactCardFlip flipDirection="horizontal" isFlipped={isFlipped}>
                  <div className="card-info-display">
                    <div className="card-top">{CardImgChange(creditCard)}</div>
                    <div className="card-bottom">
                      <div>{creditCard}</div>
                      <div style={{ display: "flex", gap: "var(--gap3x)" }}>
                        <span>{`${firstName} ${lastName}`}</span>
                        <span>
                          {month}
                          {month.length === 2 ? "/" : ""}
                          {year}
                        </span>
                      </div>
                    </div>
                  </div>
                  <div className="card-info-display display-back">
                    <div className="card-back">{cvc2}</div>
                  </div>
                </ReactCardFlip>
              </div>
            </Box>
            <div className="card-info-register">
              {loading ? (
                <div
                  style={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    width: "88px",
                    height: "33px",
                  }}
                >
                  <CircularProgress sx={{ height: "33px" }} />
                </div>
              ) : (
                <Button
                  type="submit"
                  disabled={isDisabled()}
                  sx={{
                    ":disabled": {
                      opacity: 0.4,
                    },
                  }}
                  className="iyzi-button"
                >
                  Kaydet
                </Button>
              )}
              <Button
                type="button"
                className="iyzi-button"
                onClick={handleClose}
              >
                Vazgeç
              </Button>
            </div>
          </form>
        </SpringCardIn>
      )}
    </>
  );
};

export default CreateCard;
