import CreditCardIcon from "@mui/icons-material/CreditCard";

const CardImgChange = (e, i) => {
  const firstDigit = e.toString().charAt(0);

  const istyle = {
    width: "60px",
    height: "60px",
    position: "relative",
    top: "-10px",
  };

  const rstyle = {
    width: "24px",
    height: "24px",
  };

  switch (firstDigit) {
    case "3":
      return (
        <img
          style={i === undefined ? rstyle : istyle}
          src="/images/creditcard/american_express.svg"
          alt="American Express Görseli"
        />
      );
    case "4":
      return (
        <img
          style={i === undefined ? rstyle : istyle}
          src="/images/creditcard/visa.svg"
          alt="Visa Görseli"
        />
      );
    case "5":
      return (
        <img
          style={i === undefined ? rstyle : istyle}
          src="/images/creditcard/mastercard.svg"
          alt="MasterCard Görseli"
        />
      );
    case "9":
      return (
        <img
          style={i === undefined ? rstyle : istyle}
          src="/images/creditcard/troy.svg"
          alt="TROY Görseli"
        />
      );
    default:
      return <CreditCardIcon />;
  }
};

export default CardImgChange;
