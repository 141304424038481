import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
} from "@mui/material";

const IOSSDeleteDialog = ({ IOSSDelete, setIOSSDelete, handleDelete }) => {
  const handleClose = () => {
    setIOSSDelete(false);
  };
  const handleApprove = () => {
    handleDelete();
    setIOSSDelete(false);
  };

  return (
    <Dialog
      open={IOSSDelete}
      onClose={handleClose}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
    >
      <DialogContent>
        <DialogContentText id="alert-dialog-description">
          Kayıtlı IOSS/VAT numarasını silmek üzeresiniz. Bu işleme devam etmek
          istediğinize emin misiniz?
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button className="iyzi-button-blue" onClick={handleClose}>
          Hayır
        </Button>
        <Button className="iyzi-button-red" onClick={handleApprove} autoFocus>
          Evet
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default IOSSDeleteDialog;
