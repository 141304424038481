import { Box } from "@mui/material";
import { useRecoilValue } from "recoil";
import { startDimensionAnimationState } from "../../../atoms/profileSettings";
import AddDimension from "./AddDimension";
import CreateDimension from "./CreateDimension";
import DimensionList from "./DimensionList";

const Dimensions = () => {
  const startDimensionAnimation = useRecoilValue(startDimensionAnimationState);

  return (
    <>
      {startDimensionAnimation ? (
        ""
      ) : (
        <Box className="dimension-list">
          <AddDimension />
          <DimensionList />
        </Box>
      )}
      <CreateDimension />
    </>
  );
};

export default Dimensions;
