import { Link } from "react-router-dom";

const Info = () => {
  return (
    <section className="main-info">
      <div className="info-container">
        <div className="info-image">
          <img
            src="/images/home/how/1-export-cargo.png"
            alt="Yurt dışı kargolarınızı %80’e varan indirimlerle gönderin"
            loading="lazy"
          />
        </div>
        <div className="info">
          <article>
            <h2>Yurt dışı kargolarınızı %80’e varan indirimlerle gönderin</h2>
            <p>
              Yurt dışı kargo gönderimi, çoğu zaman yüksek maliyetleri
              beraberinde getirir. Ancak iyziShip, kullanıcılarına en uygun yurt
              dışı kargo fiyatlarını sunarak bu yükü hafifletir. Sizde hemen
              iyziShip’li olun, yurt dışı kargolarınızı %80'e varan indirimlerle
              gönderme ayrıcalığını deneyimleyin.
            </p>
          </article>
        </div>
      </div>
      <div className="info-container">
        <div className="info-image">
          <img
            src="/images/home/how/2-cargo-difference.png"
            alt="Kargo tekliflerini kolayca karşılaştırın, zamandan tasarruf edin"
            loading="lazy"
          />
        </div>
        <div className="info">
          <article>
            <h2>
              Kargo tekliflerini kolayca karşılaştırın, zamandan tasarruf edin
            </h2>
            <p>
              Bütçenize en uygun yurt dışı kargo fiyatını bulmak için daha fazla
              zaman harcamayın. iyziShip’in kullanıcı dostu arayüzü sayesinde;
              tek tıklamanızla sektörün önde gelen lojistik firmalarından yurt
              dışı kargo teklifi alabilir ve bu teklifleri kolayca
              karşılaştırabilirsiniz.
            </p>
          </article>
        </div>
      </div>
      <div className="info-container">
        <div className="info-image">
          <img
            src="/images/home/how/3-solutions-with-integration.png"
            alt="E-ihracat çözümlerimiz ile işletmenizi dünyaya açın"
            loading="lazy"
          />
        </div>
        <div className="info">
          <article>
            <h2>E-ihracat çözümlerimiz ile işletmenizi dünyaya açın</h2>
            <p>
              Etsy, Amazon, eBay gibi uluslararası pazaryerlerine ve Shopify,
              Woocommerce, Magento gibi e-ticaret platformlarına ücretsiz
              entegrasyon sağlayarak global pazardaki yerinizi alın.
              İşletmenizin e-ihracat potansiyelini keşfetmek için hemen bizimle
              iletişime geçin ve küresel başarıya doğru ilk adımınızı atın!
            </p>
          </article>
        </div>
      </div>
      <div className="info-container">
        <div className="info-image">
          <img
            src="/images/home/how/4-global-network.png"
            alt="Global depo ağımız sayesinde, maliyetlerinizi optimize edin"
            loading="lazy"
          />
        </div>
        <div className="info">
          <article>
            <h2>Global depo ağımız sayesinde, maliyetlerinizi optimize edin</h2>
            <p>
              Uluslararası piyasada rekabet edebilmeniz için, maliyetleri
              düşürme ve iş süreçlerinizi verimli bir şekilde yönetme konusunda
              yardımcı oluyoruz. Global depo ağımız, siparişlerinizi dünya
              genelinde daha hızlı ve daha düşük maliyetle göndermenize olanak
              sağlar. Küresel pazarda yerinizi almak için hemen{" "}
              <span>
                <Link style={{
                  color: "var(--mavi, #007fff)",
                  fontFamily: "Inter",
                  fontSize: "20px",
                  fontStyle: "normal",
                  fontWeight: "700",
                  lineHeight: "28px",
                  textDecorationLine: "underline",
                }} to={"/iletisim"}>bizimle iletişime geçin</Link>{" "}
              </span>{" "}
              ve işletmenizin e-ihracat yeteneklerini maksimize edin!
            </p>
          </article>
        </div>
      </div>
    </section>
  );
};

export default Info;
