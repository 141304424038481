import { Box } from '@mui/material'
import ControlPointOutlinedIcon from '@mui/icons-material/ControlPointOutlined';
import { useRecoilState } from 'recoil';
import { startDimensionAnimationState } from '../../../atoms/profileSettings';


const AddDimension = () => {
  const [, setStartDimensionAnimation] = useRecoilState(startDimensionAnimationState);

  return (
    <Box className='card-adder-wrapper'>
      <button onClick={() => setStartDimensionAnimation(true)} className="card-adder">
        <ControlPointOutlinedIcon />
        Yeni Ölçü Ekle
      </button>
    </Box>
  )
}

export default AddDimension