import MarkEmailReadIcon from "@mui/icons-material/MarkEmailRead";
import { useState } from "react";
import { useEffect } from "react";
import { useNavigate } from "react-router-dom";

const EmailConfirmation = () => {
  const navigate = useNavigate();

  const queryParams = new URLSearchParams(window.location.search);
  const token = queryParams.get("token");

  const [pageRendered, setPageRendered] = useState(false);

  const handleNavigate = () => {
    navigate("/dashboard");
  };

  useEffect(() => {
    if (token) {
      setPageRendered(true);
      setTimeout(() => {
        navigate("/dashboard");
      }, 5000);
    } else {
      setPageRendered(true);
      setTimeout(() => {
        navigate("/dashboard");
      }, 4000);
    }
  }, [token, navigate]);

  return (
    pageRendered && (
      <section>
        {!token ? (
          <div
            style={{
              height: "100vh",
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              fontWeight: 700,
              color: "var(--priceText)",
              fontSize: "30px",
            }}
          >
            404 Not Found
          </div>
        ) : (
          <div
            style={{
              height: "100vh",
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              justifyContent: "center",
              fontWeight: 700,
              color: "var(--priceText)",
            }}
          >
            <MarkEmailReadIcon
              sx={{
                color: "var(--shipmentGreen)",
                width: "150px",
                height: "auto",
              }}
            />
            <span style={{ fontSize: "40px" }}>Email Onaylandı!</span>
            <span
              style={{
                textDecoration: "underline",
                fontSize: "16px",
                textAlign: "center",
                cursor: "pointer",
              }}
              onClick={handleNavigate}
            >
              iyziShip'e gitmek için tıklayınız.
            </span>
          </div>
        )}
      </section>
    )
  );
};

export default EmailConfirmation;
