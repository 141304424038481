import { Typography } from "@mui/material";
import CurrencySymbol from "../../../utils/CurrencySymbol";
import { useCallback, useEffect, useState } from "react";
import axios from "../../../api/axios";
import { useRecoilState } from "recoil";
import {
  nonRefundableAmountState,
  refundableAmountState,
  totalBalanceState,
  walletRefundCurrentPageState,
  walletRefundDataState,
  walletRefundTotalPageState,
} from "../../../atoms/myWallet";
import LocalPrice from "../../../utils/LocalPrice";
import miniLogo from "../../../img/logos/logo_white_mini_loading.svg";
import Errors from "../../../utils/Errors";

const MyWalletRefundBalance = () => {
  const [loading, setLoading] = useState(false);
  const [totalBalance, setTotalBalance] = useRecoilState(totalBalanceState);
  const [refundableAmount, setRefundableAmount] = useRecoilState(refundableAmountState);
  const [nonRefundableAmount, setNonRefundableAmount] = useRecoilState(nonRefundableAmountState);
  const [, setWalletRefundData] = useRecoilState(walletRefundDataState);
  const [, setWalletRefundTotalPage] = useRecoilState(walletRefundTotalPageState);
  const [, setWalletRefundCurrentPage] = useRecoilState(walletRefundCurrentPageState);

  const getBalance = useCallback(async () => {
    setLoading(true);
    await axios
      .get("refund")
      .then((response) => {
        setWalletRefundData(response.data.data);
        setTotalBalance(response.data.balanceInfo.total_balance);
        setRefundableAmount(response.data.balanceInfo.refundable_balance);
        setNonRefundableAmount(response.data.balanceInfo.non_refundable_balance);
        setWalletRefundTotalPage(response.data.totalPages);
        setWalletRefundCurrentPage(response.data.currentPage);
      })
      .catch((error) => {
        Errors(error);
      })
      .finally(() => setLoading(false));
  }, [
    setTotalBalance,
    setRefundableAmount,
    setNonRefundableAmount,
    setWalletRefundData,
    setWalletRefundTotalPage,
    setWalletRefundCurrentPage,
  ]);

  useEffect(() => {
    getBalance();
  }, [getBalance]);

  return loading ? (
    <div
      style={{
        width: "100%",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        minHeight: "500px",
      }}
    >
      <img className="loading-logo" src={miniLogo} alt="" />
    </div>
  ) : (
    <div className="my-wallet-refund-balance">
      <div className="my-wallet-refund-balance-item">
        <Typography className="refund-balance-item-headline">Bakiye</Typography>
        <Typography className="refund-balance-item-amount">
          {LocalPrice(totalBalance)} {CurrencySymbol("TRY")}
        </Typography>
      </div>
      <div className="my-wallet-refund-balance-item">
        <Typography className="refund-balance-item-headline">İade Edilebilir Bakiye</Typography>
        <Typography className="refund-balance-item-amount">
          {LocalPrice(refundableAmount)} {CurrencySymbol("TRY")}
        </Typography>
      </div>
      <div className="my-wallet-refund-balance-item">
        <Typography className="refund-balance-item-headline">İade Edilemez Bakiye</Typography>
        <Typography className="refund-balance-item-amount">
          {LocalPrice(nonRefundableAmount)} {CurrencySymbol("TRY")}
        </Typography>
        <Typography className="refund-balance-item-info">* Ödül bakiye iade edilemez.</Typography>
      </div>
    </div>
  );
};

export default MyWalletRefundBalance;
