import { Table, TableBody, TableContainer } from "@mui/material";
import BillsTableBody from "./BillsTableBody";
import BillsTableHead from "./BillsTableHead";
import { myBillsDataState } from "../../../atoms/myWallet";
import { useRecoilValue } from "recoil";
import { Info } from "@mui/icons-material";

const BillsTable = () => {
  const myBillsData = useRecoilValue(myBillsDataState);

  return (
    <TableContainer
      sx={{
        backgroundColor: "var(--lighterBg)",
        borderRadius: "var(--inputBorderRadius)",
        boxShadow: "0px 2px 10px rgba(0, 0, 0, 0.1)",
      }}
    >
      <div
        style={{
          padding: "20px",
        }}
      >
        <p
          style={{
            display: "flex",
            gap: "5px",
            alignItems: "center",
            backgroundColor: "var(--pastelBlue)",
            borderRadius: "var(--buttonBorderRadius)",
            width: "fit-content",
            padding: "3px 10px",
            color: "var(--btnBgBlue)",
            fontWeight: 600,
          }}
        >
          <Info sx={{ color: "var(--btnBgBlue)" }} /> Faturalar her ayın son iş gününde
          kesilmektedir!
        </p>
      </div>
      <Table>
        <BillsTableHead />
        <TableBody>
          {myBillsData.map((row, key) => (
            <BillsTableBody key={key} row={row} />
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
};

export default BillsTable;
