import {
  Checkbox,
  FormControl,
  FormControlLabel,
  FormGroup,
  InputLabel,
  MenuItem,
  Select,
  TextField,
  Button,
  CircularProgress,
} from "@mui/material";
import EditOutlinedIcon from "@mui/icons-material/EditOutlined";
import CheckOutlinedIcon from "@mui/icons-material/CheckOutlined";
import CloseOutlinedIcon from "@mui/icons-material/CloseOutlined";
import ProfileSettingsStyle from "../../../style/styleJs/ProfileSettingsStyle";
import AssignmentIndOutlinedIcon from "@mui/icons-material/AssignmentIndOutlined";
import { useRecoilState, useRecoilValue } from "recoil";
import {
  companyNameState,
  kgDiffPaymentPermState,
  memberStatusState,
  profileCompanyTypeState,
  profileMemberTypeState,
  taxNoState,
  taxOfficeCityState,
  taxOfficeState,
} from "../../../atoms/membership";
import { useCallback, useEffect, useState } from "react";
import Captcha from "../../navbar/Captcha";
import axios from "../../../api/axios";
import Errors from "../../../utils/Errors";
import { toast } from "react-toastify";
import taxData from "../../../json/FinanceGovermentTaxOffices.json";
import { saveUser } from "../../../utils/User";

const MembershipInfo = () => {
  const user = JSON.parse(localStorage.getItem("user"));
  const kgDiff = user?.user?.user_member?.auto_charge_difference_wallet;

  const memberInvoiceType = user?.user?.user_member?.invoice_type;
  const memberInvoiceTypeName = user?.user?.user_member?.invoice_type_name;
  const isMainUser = user?.user?.is_main_user;
  const identityNo = user?.user?.user_member?.identity_no;

  const memberStatus = useRecoilValue(memberStatusState);
  const isProfileCompleted = Number(memberStatus) === 2 ? true : false;

  const [profileMemberType, setProfileMemberType] = useRecoilState(profileMemberTypeState);
  const [profileCompanyType, setProfileCompanyType] = useRecoilState(profileCompanyTypeState);
  const [taxOffice, setTaxOffice] = useRecoilState(taxOfficeState);
  const [taxNo, setTaxNo] = useRecoilState(taxNoState);
  const [companyName, setCompanyName] = useRecoilState(companyNameState);
  const [kgDiffPaymentPerm, setKgDiffPaymentPerm] = useRecoilState(kgDiffPaymentPermState);

  const [taxOfficeCity, setTaxOfficeCity] = useRecoilState(taxOfficeCityState);
  const [invoiceType, setInvoiceType] = useState(
    memberInvoiceType === null ? "vkn" : memberInvoiceType
  );
  const [securityCode, setSecurityCode] = useState("");
  const [captchaUrl, setCaptchaUrl] = useState(
    "https://ivd.gib.gov.tr/captcha/jcaptcha?imageID=37fpd9lmowi2md00"
  );
  // const [taxOfficeError, setTaxOfficeError] = useState(false);
  // const [taxNoError, setTaxNoError] = useState(false);
  const [editPerm, setEditPerm] = useState(false);
  const [loading, setLoading] = useState(false);
  const [vknChecker, setVknChecker] = useState(false);

  const captchaRefresh = () => {
    // Zaman damgası ekleyerek URL'yi güncelle
    const newCaptchaUrl = `https://ivd.gib.gov.tr/captcha/jcaptcha?imageID=37fpd9lmowi2md00&timestamp=${new Date().getTime()}`;
    setCaptchaUrl(newCaptchaUrl);
  };

  const handleAutoChargeDiffPermChange = () => {
    if (Number(kgDiffPaymentPerm) === 1) {
      setKgDiffPaymentPerm("0");
    } else {
      setKgDiffPaymentPerm("1");
    }
  };

  const handleTaxNoChange = (e) => {
    const value = e.target.value;
    setTaxNo(value);
  };

  // const isInvoiceTypeDisabled = () => {
  //   if (profileCompanyType === "Company") {
  //     return false;
  //   } else return true;
  // };

  const handleMembershipChange = (e) => {
    const value = e.target.value;
    setProfileMemberType(value);
  };

  const handleMembershipCompanyChange = (e) => {
    const value = e.target.value;
    setProfileCompanyType(value);
  };

  const handleInvoiceTypeChange = (e) => {
    const value = e.target.value;
    if (memberInvoiceTypeName === "tckn") {
      setInvoiceType("tckn");
    } else {
      setInvoiceType("vkn");
    }
    setInvoiceType(value);
  };

  const handleEdit = () => {
    setEditPerm(true);
    setProfileMemberType(user?.user?.user_member.member_type);
  };

  const handleEditAbort = () => {
    setEditPerm(false);
    setTaxOffice(parseInt(user?.user?.user_member.tax_office, 10).toString());
    setTaxNo(user?.user?.user_member.tax_no);
    setProfileMemberType(user?.user?.user_member.member_type);
    setProfileCompanyType(user?.user?.user_member.company_type);
    setInvoiceType(user?.user?.user_member?.invoice_type);
    setKgDiffPaymentPerm(user?.user?.user_member?.auto_charge_difference_wallet);
    setSecurityCode("");
  };

  const disabled = () => {
    if (editPerm) {
      return false;
    } else return true;
  };

  const disabledPermanent = () => {
    if (isProfileCompleted || !editPerm) {
      return true;
    } else return false;
  };

  const taxHandler = useCallback(
    (e) => {
      setTaxOffice(e.target.value);

      if (e.target.value.length === 4) {
        setTaxOfficeCity(`00${e.target.value.slice(0, 1)}`);
      } else if (e.target.value.length === 5) {
        setTaxOfficeCity(`0${e.target.value.slice(0, 2)}`);
      }
    },
    [setTaxOffice, setTaxOfficeCity]
  );

  const handleSearchTaxNo = useCallback(
    (e) => {
      if (e) e.preventDefault();

      if (invoiceType === "tckn") {
        axios
          .post("verify-vkn", {
            type: "tckn",
            tax_office:
              taxOffice.length === 4
                ? `00${taxOffice}`
                : taxOffice.length === 5
                ? `0${taxOffice}`
                : "",
            tax_office_city: taxOfficeCity,
            identity_no: identityNo,
            security_code: securityCode,
          })
          .then((response) => {
            setCompanyName(response.data.result.unvan);
            toast.success(response.data.message);
            setVknChecker(true);
          })
          .catch((error) => Errors(error))
          .finally(() => captchaRefresh());
      } else {
        axios
          .post("verify-vkn", {
            type: "vkn",
            tax_no: taxNo,
            tax_office:
              taxOffice.length === 4
                ? `00${taxOffice}`
                : taxOffice.length === 5
                ? `0${taxOffice}`
                : "",
            tax_office_city: taxOfficeCity,
            security_code: securityCode,
          })
          .then((response) => {
            setCompanyName(response.data.result.unvan);
            toast.success(response.data.message);
            setVknChecker(true);
          })
          .catch((error) => Errors(error))
          .finally(() => captchaRefresh());
      }
    },
    [taxNo, taxOffice, taxOfficeCity, securityCode, identityNo, invoiceType, setCompanyName]
  );

  const handleSubmit = async (e) => {
    if (e) e.preventDefault();

    setLoading(true);

    // ilk kural adamın profili tamamlanmışsa sadece kg farkı düzenleyebilir.

    // profil tamamlanmamışsa üyelik tipine göre alanlar değişiyo:
    // Bireysel: değişiklik yok şimdilik
    // Kurumsal: Firma Tipi, Fatura Tipi
    //           Firma Tipine Göre: VKN ise: vergi kimlik no, vergi dairesi, Firma İsmi
    //                              TCKN ise: tc kimlik no, vergi dairesi, Firma İsmi

    const chargeDiff = {
      auto_charge_difference_wallet: kgDiffPaymentPerm,
    };

    const commonData = {
      auto_charge_difference_wallet: kgDiffPaymentPerm,
      member_type: profileMemberType,
      company_type: profileCompanyType,
      company_name: companyName,
      invoice_type: invoiceType,
    };

    const requestData = isProfileCompleted
      ? chargeDiff
      : invoiceType === "tckn"
      ? {
          ...commonData,
          identity_no: identityNo,
          tax_office:
            taxOffice.length === 4
              ? `00${taxOffice}`
              : taxOffice.length === 5
              ? `0${taxOffice}`
              : "",
        }
      : {
          ...commonData,
          tax_no: taxNo,
          tax_office:
            taxOffice.length === 4
              ? `00${taxOffice}`
              : taxOffice.length === 5
              ? `0${taxOffice}`
              : "",
        };

    try {
      const response = await axios.post(`member/update`, requestData);

      if (response.data.status === 200) {
        toast.success(response.data.message);
        saveUser(response);
      } else {
        toast.success(response.data.message);
      }

      setEditPerm(false);
    } catch (error) {
      Errors(error);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    if (profileCompanyType === "Company") {
      setInvoiceType("vkn");
    }
    if (taxOffice === 4) {
      setTaxOfficeCity(`00${taxOffice.slice(0, 1)}`);
    } else if (taxOffice.length === 5) {
      setTaxOfficeCity(`0${taxOffice.slice(0, 2)}`);
    }
  }, [profileCompanyType, setTaxOfficeCity, taxOffice]);

  useEffect(() => {
    setKgDiffPaymentPerm(kgDiff);
  }, [kgDiff, setKgDiffPaymentPerm]);

  return (
    <section style={ProfileSettingsStyle()} className="general-info">
      <div className="profile-card-wrapper">
        <div className="profile-card-header">
          <AssignmentIndOutlinedIcon className="profile-card-header-icon" />
          <span>Üyelik Bilgileri</span>
        </div>
      </div>
      <div className="general-info-membership">
        <FormControlLabel
          disabled={disabled()}
          control={
            <Checkbox
              checked={Number(kgDiffPaymentPerm) === 1}
              onChange={handleAutoChargeDiffPermChange}
            />
          }
          label={
            <div>
              KG farklarını otomatik <strong>iyziBakiye </strong> ile öde
            </div>
          }
        />
        <FormGroup className="membership-types">
          <FormControl
            size="small"
            sx={{ maxWidth: profileMemberType === "Individual" ? 300 : "auto" }}
          >
            <InputLabel id="demo-simple-select-label">Üyelik Tipi</InputLabel>
            <Select
              labelId="demo-simple-select-label"
              id="demo-simple-select"
              value={profileMemberType}
              label="Üyelik Tipi"
              onChange={handleMembershipChange}
              disabled={disabledPermanent()}
            >
              <MenuItem value={"Individual"}>Bireysel</MenuItem>
              <MenuItem value={"Company"}>Kurumsal</MenuItem>
            </Select>
          </FormControl>
          {profileMemberType === "Company" && (
            <FormControl disabled={disabledPermanent()} size="small">
              <InputLabel id="demo-simple-select-label">Firma Tipi</InputLabel>
              <Select
                labelId="demo-simple-select-label"
                id="demo-simple-select"
                value={profileCompanyType}
                label="Firma Tipi"
                onChange={handleMembershipCompanyChange}
              >
                <MenuItem value={"Individual"}>Şahıs</MenuItem>
                <MenuItem value={"Company"}>Tüzel</MenuItem>
              </Select>
            </FormControl>
          )}
        </FormGroup>
        {profileMemberType === "Company" && (
          <>
            <FormControl disabled={disabledPermanent()} size="small" fullWidth>
              <InputLabel id="demo-simple-select-label">
                Faturam Hangi Kimlik No ile Kesilsin
              </InputLabel>
              <Select
                labelId="demo-simple-select-label"
                id="demo-simple-select"
                value={invoiceType}
                label="Faturam Hangi Kimlik No ile Kesilsin"
                onChange={handleInvoiceTypeChange}
              >
                <MenuItem value={"vkn"}>Vergi Kimlik Numarası</MenuItem>
                <MenuItem value={"tckn"}>TC Kimlik Numarası</MenuItem>
              </Select>
            </FormControl>
            <FormGroup className="membership-types">
              <FormControl disabled={disabledPermanent()} size="small">
                <InputLabel id="demo-simple-select-label">Vergi Dairesi</InputLabel>
                <Select
                  labelId="demo-simple-select-label"
                  id="demo-simple-select"
                  value={taxOffice}
                  label="Vergi Dairesi"
                  onChange={taxHandler}
                  // error={taxOfficeError}
                >
                  {taxData.map((e) => {
                    return (
                      <MenuItem value={e.vd_kodu} key={e.id}>
                        {e.vd_adi}
                      </MenuItem>
                    );
                  })}
                </Select>
              </FormControl>
              <TextField
                disabled={disabledPermanent()}
                label={invoiceType === "vkn" ? "Vergi Kimlik Numarası" : "TC Kimlik Numarası"}
                value={invoiceType === "vkn" ? (taxNo === null ? "" : taxNo) : identityNo}
                onChange={handleTaxNoChange}
                autoComplete="off"
                required
                size="small"
                // error={taxNoError}
                // helperText={
                //   taxNoError &&
                //   (invoiceType === "vkn"
                //     ? "Vergi numarası boş bırakılamaz!"
                //     : "TC Kimlik numarası boş bırakalamaz!")
                // }
              />
            </FormGroup>
            {!isProfileCompleted && editPerm && (
              <div className="info-vkn-form-requst">
                <Captcha
                  securityCode={securityCode}
                  setSecurityCode={setSecurityCode}
                  handleSearchTaxNo={handleSearchTaxNo}
                  captchaUrl={captchaUrl}
                  captchaRefresh={captchaRefresh}
                />
              </div>
            )}

            <div>
              <strong>Firma Ünvanı: </strong> <span>{companyName}</span>
            </div>
          </>
        )}
        {editPerm && (
          <div
            className="profile-user-button"
            style={{ display: "flex", alignItems: "center", justifyContent: "flex-end" }}
          >
            <Button
              sx={{
                marginRight: "10px",
              }}
              type="button"
              onClick={handleEditAbort}
              className="iyzi-button-blue"
            >
              <CloseOutlinedIcon /> <span>İptal</span>
            </Button>
            {loading ? (
              <div
                style={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                  width: "83px",
                  height: "36px",
                }}
              >
                <CircularProgress sx={{ width: "21px!important", height: "21px!important" }} />
              </div>
            ) : (
              <Button
                onClick={handleSubmit}
                disabled={profileMemberType === "Individual" ? false : !vknChecker ? true : false}
                type="submit"
                className="iyzi-button-blue"
                sx={{ opacity: profileMemberType === "Individual" ? 1 : !vknChecker ? 0.3 : 1 }}
              >
                <CheckOutlinedIcon /> <span>Kaydet</span>
              </Button>
            )}
          </div>
        )}
        {!editPerm && isMainUser && (
          <div
            style={{ display: "flex", alignItems: "center", justifyContent: "flex-end" }}
            className="profile-user-button"
          >
            <Button onClick={handleEdit} className="iyzi-button-blue">
              <EditOutlinedIcon /> <span>Düzenle</span>
            </Button>
          </div>
        )}
      </div>
    </section>
  );
};

export default MembershipInfo;
