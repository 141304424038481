import {
  Box,
  Button,
  CircularProgress,
  createTheme,
  Modal,
  TextField,
  ThemeProvider,
} from "@mui/material";
import { toast } from "react-toastify";
import { useRecoilState, useRecoilValue } from "recoil";
import axios from "../../../api/axios";
import {
  addNoteOpenState,
  isReturnShipmentState,
  myShipmentsNoteState,
  selectedShipmentForMyShipmentsState,
} from "../../../atoms/myShipments";
import NoteAddOutlinedIcon from "@mui/icons-material/NoteAddOutlined";
import CancelOutlinedIcon from "@mui/icons-material/CancelOutlined";
import { useState } from "react";
import Errors from "../../../utils/Errors";

const theme = createTheme({
  components: {
    MuiModal: {
      styleOverrides: {
        root: {
          padding: "20px",
        },
      },
    },
    MuiOutlinedInput: {
      styleOverrides: {
        root: {
          borderRadius: "8px",
        },
      },
    },
  },
});

const AddNote = () => {
  const [loading, setLoading] = useState(false);

  const isReturnShipment = useRecoilValue(isReturnShipmentState);
  const [addNoteOpen, setAddNoteOpen] = useRecoilState(addNoteOpenState);
  const [myShipmentsNote, setMyShipmentsNote] = useRecoilState(myShipmentsNoteState);
  const selectedShipmentForMyShipments = useRecoilValue(selectedShipmentForMyShipmentsState);

  const sendNoteKey = (e) => {
    setLoading(true);
    if (e.key === "Enter") {
      axios
        .post(`parcel/parcel-note/add/${selectedShipmentForMyShipments.id}`, {
          description: myShipmentsNote,
        })
        .then((response) => {
          if (response.status === 200) {
            toast.success(response.data.message);
            setTimeout(() => {
              window.location.reload(true);
            }, 1000);
          }
        })
        .catch((error) => Errors(error))
        .finally(() => {
          setLoading(false);
          setMyShipmentsNote("");
          setAddNoteOpen(false);
        });
    } else if (e.key === "Escape") {
      setMyShipmentsNote("");
      toast.success("Sayfa Temizlendi", { autoClose: 500 });
    }
  };

  const sendNote = () => {
    setLoading(true);
    axios
      .post(`parcel/parcel-note/add/${selectedShipmentForMyShipments.id}`, {
        description: myShipmentsNote,
      })
      .then((response) => {
        if (response.status === 200) {
          toast.success(response.data.message);
          setTimeout(() => {
            window.location.reload(true);
          }, 1000);
        }
      })
      .catch((error) => Errors(error))
      .finally(() => {
        setLoading(false);
        setMyShipmentsNote("");
        setAddNoteOpen(false);
      });
  };

  const updateNoteText = (note) => {
    const updatingText = document.getElementById(`update_note_${note.id}`);
    return setMyShipmentsNote(updatingText.value);
  };

  const updateNote = async (note) => {
    setLoading(true);
    await axios
      .post(`parcel/parcel-note/update/${note.id}`, {
        description: myShipmentsNote,
      })
      .then((response) => {
        toast.success(response.data.message);
        setTimeout(() => {
          window.location.reload(true);
        }, 1000);
      })
      .catch((error) => Errors(error))
      .finally(() => {
        setLoading(false);
        setAddNoteOpen(false);
      });
  };

  const notes =
    !isReturnShipment &&
    selectedShipmentForMyShipments?.length !== 0 &&
    selectedShipmentForMyShipments.get_parcel_notes?.filter((e) => e.type === "1");

  const handleClose = () => {
    setMyShipmentsNote("");
    setAddNoteOpen(false);
  };

  return (
    <ThemeProvider theme={theme}>
      <Modal open={addNoteOpen} onClose={handleClose}>
        {selectedShipmentForMyShipments.length !== 0 && !isReturnShipment ? (
          <Box
            sx={{
              backgroundColor: "var(--lighterBg)",
              borderRadius: "var(--inputBorderRadius)",
              padding: "20px",
              outline: 0,
              display: "grid",
              gap: "var(--gap)",
              width: "100%",
              maxWidth: "1100px",
              margin: "auto",
              position: "relative",
            }}
            style={{
              maxHeight: "90vh",
              overflowY: "auto",
            }}
          >
            <CancelOutlinedIcon
              sx={{
                position: "absolute",
                right: 1,
                top: 1,
                cursor: "pointer",
                color: "var(--priceText)",
                transition: "all 80ms linear",
                ":hover": {
                  color: "var(--notRed)",
                  transition: "all 80ms linear",
                },
              }}
              onClick={handleClose}
            />
            <h6
              style={{
                display: "flex",
                alignItems: "center",
                gap: "5px",
                color: "var(--priceText)",
                marginBottom: "10px",
              }}
            >
              <NoteAddOutlinedIcon />
              Not Ekle
            </h6>
            <Box
              sx={{
                marginBottom: "var(--gap)",
                display: "grid",
                gap: "var(--gap)",
              }}
            >
              {notes?.map((note) => (
                <div key={note.id}>
                  <div
                    style={{
                      paddingLeft: "var(--gap)",
                      fontSize: "14px",
                      color: "var(--lighterTextColor)",
                      paddingBottom: "4px",
                    }}
                  >
                    {note?.created_at.slice(0, 10)} {note?.created_at.slice(11, 19)} {" - "}
                    {note?.get_created_by?.name} {note?.get_created_by?.surname}
                  </div>
                  <Box
                    sx={{
                      display: "grid",
                      gap: "var(--gap)",
                      marginTop: "var(--gap)",
                    }}
                  >
                    <TextField
                      defaultValue={note.description}
                      multiline
                      fullWidth
                      autoFocus
                      onFocus={(e) => {
                        let descValue = e.target.value;
                        e.target.value = "";
                        e.target.value = descValue;
                      }}
                      label="Notu Düzenle"
                      id={`update_note_${note.id}`}
                      onChange={() => updateNoteText(note)}
                    />
                    <Box
                      sx={{
                        display: "flex",
                        width: "100%",
                        justifyContent: "flex-end",
                        alignItems: "center",
                        gap: "var(--gap)",
                      }}
                    >
                      {loading ? (
                        <Box>
                          <CircularProgress />
                        </Box>
                      ) : (
                        <Button
                          sx={{
                            textTransform: "none",
                            backgroundColor: "var(--info)",
                            color: "var(--lighterBg)",
                            padding: "7px 16px",
                            borderRadius: "var(--buttonBorderRadius)",
                            transition: "all 150ms linear",
                            ":hover": {
                              backgroundColor: "var(--blueBrand)",
                              transition: "all 150ms linear",
                            },
                          }}
                          onClick={() => updateNote(note)}
                        >
                          Güncelle
                        </Button>
                      )}
                      <Button
                        sx={{
                          textTransform: "none",
                          backgroundColor: "var(--info)",
                          color: "var(--lighterBg)",
                          padding: "7px 16px",
                          borderRadius: "var(--buttonBorderRadius)",
                          transition: "all 150ms linear",
                          ":hover": {
                            backgroundColor: "var(--blueBrand)",
                            transition: "all 150ms linear",
                          },
                        }}
                        onClick={() => setAddNoteOpen(false)}
                      >
                        İptal
                      </Button>
                    </Box>
                  </Box>
                </div>
              ))}
            </Box>
            {notes?.length === 0 ? (
              <Box sx={{ display: "grid", gap: "var(--gap)" }}>
                <TextField
                  value={myShipmentsNote}
                  onChange={(e) => setMyShipmentsNote(e.target.value)}
                  onKeyDown={(e) => sendNoteKey(e)}
                  label="Notunuz"
                  autoFocus
                  onFocus={(e) => {
                    let descValue = e.target.value;
                    e.target.value = "";
                    e.target.value = descValue;
                  }}
                  multiline
                  rows={8}
                />
                <Button
                  onClick={sendNote}
                  sx={{
                    justifySelf: "flex-end",
                    backgroundColor: "var(--info)",
                    color: "var(--lighterBg)",
                    textTransform: "none",
                    transition: "all 150ms linear",
                    borderRadius: "var(--buttonBorderRadius)",
                    padding: "7px 16px",
                    ":hover": {
                      backgroundColor: "var(--blueBrand)",
                      transition: "all 150ms linear",
                    },
                  }}
                >
                  Gönder
                </Button>
              </Box>
            ) : (
              ""
            )}
          </Box>
        ) : (
          <Box
            sx={{
              backgroundColor: "var(--lighterBg)",
              borderRadius: "var(--inputBorderRadius)",
              padding: "20px",
              outline: 0,
              display: "grid",
              gap: "var(--gap)",
              width: "100%",
              maxWidth: "1100px",
              margin: "auto",
              position: "relative",
            }}
            style={{
              maxHeight: "90vh",
              overflowY: "auto",
            }}
          >
            <CancelOutlinedIcon
              sx={{
                position: "absolute",
                right: 1,
                top: 1,
                color: "var(--priceText)",
                cursor: "pointer",
                transition: "all 80ms linear",
                ":hover": {
                  color: "var(--notRed)",
                  transition: "all 80ms linear",
                },
              }}
              onClick={handleClose}
            />
            Sistem daha kendine gelemedi, sakin ol kardeşim.
          </Box>
        )}
      </Modal>
    </ThemeProvider>
  );
};

export default AddNote;
