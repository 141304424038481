import { Checkbox } from "@mui/material";
import { useRecoilState } from "recoil";
import CheckCircle from "@mui/icons-material/CheckCircle";
import CircleUnchecked from "@mui/icons-material/RadioButtonUnchecked";
import {
  selectedSenderAddressIdState,
  selectedSenderAddressState,
  senderAddressesState,
} from "../../../atoms/createShipment";
import { useEffect, useState } from "react";
import axios from "axios";
import DeleteIcon from "@mui/icons-material/Delete";
import IconButton from "@mui/material/IconButton";
import { useCookies } from "react-cookie";
import { toast } from "react-toastify";
import DeleteAddressDialog from "./DeleteAddressDialog";

const ShipmentSenderAddressList = () => {
  const [senderAddresses, setSenderAddresses] =
    useRecoilState(senderAddressesState);

  const [, setSelectedSenderAddress] = useRecoilState(
    selectedSenderAddressState
  );
  const [selectedSenderAddressId, setSelectedSenderAddressId] = useState(
    selectedSenderAddressIdState
  );

  const [deleteDialog, setDeleteDialog] = useState(false);
  const [itemID, setItemID] = useState(false);

  const handleCheckedChange = (event, id) => {
    setSelectedSenderAddressId(id);
    const selectedSenderAddressIndex = senderAddresses.findIndex(
      (address) => address.id === id
    );
    setSelectedSenderAddress(senderAddresses[selectedSenderAddressIndex]);
  };

  //** -------------- Select Default Sender Address -------------- **//

  useEffect(() => {
    setSelectedSenderAddressId(
      senderAddresses.find((e) => e.is_default === "1")?.id
    );
    setSelectedSenderAddress(
      senderAddresses[senderAddresses.findIndex((e) => e.is_default === "1")]
    );
  }, [setSelectedSenderAddressId, setSelectedSenderAddress, senderAddresses]);

  // jwt
  const [token] = useCookies(["jwt"]);
  const jwt = token.jwt;

  const handleDeleteAddress = async () => {
    try {
      const response = await axios.post(
        `https://api.iyziship.com/v2/member-address/delete/${itemID}`,
        null,
        {
          headers: {
            Authorization: `Bearer ${jwt}`,
          },
        }
      );
      const updatedAddresses = senderAddresses.filter(
        (address) => address.id !== itemID
      );
      setSenderAddresses(updatedAddresses);

      if (response.status === 200) {
        toast.success(response.data.message, { autoClose: 1000 });
      }
    } catch (error) {
      toast.error(error.response.data.message, { autoClose: 1000 });
    }
  };

  const handleDeleteDialog = (addressId) => {
    setItemID(addressId);
    setDeleteDialog(true);
  };

  return (
    <div className="list-board-items">
      <DeleteAddressDialog
        open={deleteDialog}
        setOpen={setDeleteDialog}
        handleDelete={handleDeleteAddress}
        id={itemID}
      />
      {senderAddresses.map((address) => (
        <div className="list-board-item" key={address.id}>
          <Checkbox
            className="item-select"
            icon={<CircleUnchecked />}
            checkedIcon={<CheckCircle />}
            checked={selectedSenderAddressId === address.id}
            onChange={(e) => handleCheckedChange(e, address.id)}
          />
          <div style={{ marginBottom: "var(--gap)" }}>
            {address.address_description}
          </div>
          <div style={{ marginBottom: "var(--gap)" }}>
            {address.contact_name} {address.company_name ? " / " : ""}{" "}
            {address.company_name}
          </div>
          <div style={{ fontSize: "12px", color: "var(--priceText)" }}>
            <span style={{ color: "inherit", fontSize: "inherit" }}>
              {address.postal_code}
            </span>
            {" / "}
            <span style={{ color: "inherit", fontSize: "inherit" }}>
              {address.address1}
            </span>{" "}
            <span style={{ color: "inherit", fontSize: "inherit" }}>
              {address.address2}
            </span>{" "}
            <span
              style={{
                marginBottom: "5px",
                color: "inherit",
                fontSize: "inherit",
              }}
            >
              {address.address3}
            </span>
          </div>
          <div>
            {address.town}/{address.city}
          </div>
          <div>{address.phone}</div>
          <IconButton
            aria-label="Delete"
            color="error"
            onClick={() => handleDeleteDialog(address.id)}
            sx={{ position: "absolute", bottom: "0", right: "-2%" }}
          >
            <DeleteIcon />
          </IconButton>
        </div>
      ))}
    </div>
  );
};

export default ShipmentSenderAddressList;
