import { useCookies } from "react-cookie";
import { Navigate, Outlet } from "react-router-dom";

const PrivateRoutes = () => {
  const [token] = useCookies(["jwt"]);
  const user = JSON.parse(localStorage.getItem("user"));
  const role = user?.user?.user_role?.name;

  if (token && role === "MEM") {
    return <Outlet />;
  } else {
    localStorage.removeItem("user");
    document.cookie = `jwt=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/;`;
    return <Navigate to="/login" />;
  }
};

export default PrivateRoutes;
