import { atom } from "recoil";

export const isCreateRegistrationState = atom({
  key: "isCreateRegistration",
  default: false,
});

export const selectedDistrictState = atom({
  key: "selectedDistrict",
  default: false,
});

export const selectedCityState = atom({
  key: "selectedCity",
  default: false,
});

export const selectedStateState = atom({
  key: "selectedState",
  default: false,
});

export const courierDeliveryAddressState = atom({
  key: "courierDeliveryAddress",
  default: "",
});

export const courierListState = atom({
  key: "courierList",
  default: [],
});

export const isCourierRegistrationExistState = atom({
  key: "isCourierRegistrationExist",
  default: false,
});

export const courierDetailShowState = atom({
  key: "courierDetailShow",
  default: false,
});

export const postalCodeState = atom({
  key: "postalCode",
  default: "",
});

export const courierBasicSearchErrorState = atom({
  key: "courierBasicSearchError",
  default: false,
});

export const errorSetState = atom({
  key: "errorSett",
  default: true,
});

export const showAddButtonState = atom({
  key: "showAddButton",
  default: false,
});

export const createRegisterModalState = atom({
  key: "createRegisterModal",
  default: false,
});