import "../../style/sidebar/Notification.css";
import "../../style/sidebar/Navbar.css";
import { styled } from "@mui/material/styles";
import MuiDrawer from "@mui/material/Drawer";
import IconButton from "@mui/material/IconButton";
import NotificationsOutlinedIcon from "@mui/icons-material/NotificationsOutlined";
import ChevronRightIcon from "@mui/icons-material/ChevronRight";
import ChevronLeftIcon from "@mui/icons-material/ChevronLeft";
import { useRecoilState } from "recoil";
import { drawerOpenState, notificationOpenState } from "../../atoms/viewPadding";
import { Badge, Button, Tooltip } from "@mui/material";
import { useState, useCallback, useEffect } from "react";
import axios from "../../api/axios";
import { useNavigate } from "react-router-dom";
import { useCookies } from "react-cookie";
import Basket from "./Basket";
import NavDrawerHeader from "../navbar/NavDrawerHeader";
import ProfileDetails from "../navbar/ProfileDetails";
import NavLinks from "../navbar/NavLinks";
import MenuOutlinedIcon from "@mui/icons-material/MenuOutlined";
import SettingsOutlinedIcon from "@mui/icons-material/SettingsOutlined";
import WalletInfo from "./WalletInfo";
import NotificationList from "./NotificationList";

const drawerWidth = 215;

const openedMixin = (theme) => ({
  width: drawerWidth,
  transition: theme.transitions.create("width", {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.enteringScreen,
  }),
  overflowX: "hidden",
  right: 0,
  left: "unset",
});

const closedMixin = (theme) => ({
  transition: theme.transitions.create("width", {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  overflowX: "hidden",
  width: 0,
  right: 0,
  left: "unset",
});

const Drawer = styled(MuiDrawer, {
  shouldForwardProp: (prop) => prop !== "notificationOpen",
})(({ theme, notificationOpen }) => ({
  width: drawerWidth,
  flexShrink: 0,
  whiteSpace: "nowrap",
  boxSizing: "border-box",
  ...(notificationOpen && {
    ...openedMixin(theme),
    "& .MuiDrawer-paper": openedMixin(theme),
  }),
  ...(!notificationOpen && {
    ...closedMixin(theme),
    "& .MuiDrawer-paper": closedMixin(theme),
  }),
}));

// **** Menu ****

const menuWidth = 265;

const openedMixinMenu = (theme) => ({
  width: menuWidth,
  transition: theme.transitions.create("width", {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.enteringScreen,
  }),
  overflowX: "hidden",
});

const closedMixinMenu = (theme) => ({
  transition: theme.transitions.create("width", {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  overflowX: "hidden",
  width: "0",
  justifyContent: "space-between",
});

const DrawerMenu = styled(MuiDrawer, {
  shouldForwardProp: (prop) => prop !== "drawerOpen",
})(({ theme, drawerOpen }) => ({
  width: menuWidth,
  flexShrink: 0,
  whiteSpace: "nowrap",
  boxSizing: "border-box",
  ...(drawerOpen && {
    ...openedMixinMenu(theme),
    "& .MuiDrawer-paper": openedMixinMenu(theme),
  }),
  ...(!drawerOpen && {
    ...closedMixinMenu(theme),
    "& .MuiDrawer-paper": closedMixinMenu(theme),
  }),
}));

const NotificationBar = () => {
  const navigate = useNavigate();
  const [token] = useCookies(["jwt"]);
  const jwt = token.jwt;

  const [notificationOpen, setNotificationOpen] = useRecoilState(notificationOpenState);
  const [drawerOpen, setDrawerOpen] = useRecoilState(drawerOpenState);

  const [isNotificationGet, setIsNotificationGet] = useState(false);

  const [not, setNot] = useState([]);

  const handleDrawerOpen = () => {
    setNotificationOpen(true);
  };

  const handleDrawerClose = () => {
    setNotificationOpen(false);
  };

  const handleMenuOpen = () => {
    setDrawerOpen(false);
  };

  const handleMenuClose = () => {
    setDrawerOpen(true);
  };

  const getNotification = useCallback(async () => {
    await axios
      .get("notification", {
        headers: {
          Authorization: `Bearer ${jwt}`,
        },
      })
      .then((response) => {
        if (response.data.data.length === 0) {
          setNotificationOpen(false);
        }
        setNot(response.data.data);
      });
  }, [setNotificationOpen, jwt]);

  const handleNavigate = useCallback(() => {
    navigate("/profile-settings/general");
  }, [navigate]);

  useEffect(() => {
    if (isNotificationGet === false) {
      getNotification();
      setIsNotificationGet(true);
    }
  }, [isNotificationGet, getNotification]);

  useEffect(() => {
    if (window.innerWidth <= 768) {
      setDrawerOpen(false);
    }
  }, [setDrawerOpen]);

  return (
    <div className="notification">
      <div className="drawer-arrow-wrapper">
        <div className="drawer-arrow-contain">
          <div className="drawer-left">
            {drawerOpen ? (
              <IconButton className="menu-arrow" onClick={handleMenuOpen}>
                <ChevronLeftIcon />
              </IconButton>
            ) : (
              <IconButton className="menu-arrow" onClick={handleMenuClose}>
                <MenuOutlinedIcon />
              </IconButton>
            )}
            <img
              style={{ cursor: "pointer" }}
              onClick={() => {
                navigate("/dashboard");
              }}
              src="/images/home/logo.svg"
              alt="iyziShip"
            />
          </div>
          <div className="drawer-right">
            <WalletInfo />
            <Basket />
            <Tooltip title={"Profil Ayarları"} placement="bottom">
              <Button onClick={handleNavigate} className="drawer-settings">
                <SettingsOutlinedIcon />
              </Button>
            </Tooltip>
            <Tooltip title={"Bildirimler"} placement="bottom">
              {notificationOpen ? (
                <IconButton className="drawer-arrow" onClick={handleDrawerClose}>
                  <ChevronRightIcon />
                </IconButton>
              ) : (
                <IconButton className="drawer-arrow" onClick={handleDrawerOpen}>
                  <Badge badgeContent={not.length} overlap="circular" color="primary">
                    <NotificationsOutlinedIcon color="action" />
                  </Badge>
                </IconButton>
              )}
            </Tooltip>
          </div>
        </div>
      </div>
      <DrawerMenu className="navbar-drawer" variant="permanent" drawerOpen={drawerOpen}>
        <NavDrawerHeader />
        <ProfileDetails />
        <NavLinks />
      </DrawerMenu>
      <Drawer
        className="notification-drawer"
        variant="permanent"
        notificationOpen={notificationOpen}
      >
        <div
          style={{
            gap: "var(--gap)",
            padding: "10px",
            display: "flex",
            flexDirection: "column-reverse",
          }}
          className="notifications"
        >
          {not.length > 0 ? (
            not
              ?.reverse()
              .map((e, key) => (
                <NotificationList key={key} notification={e} not={not} setNot={setNot} />
              ))
          ) : (
            <div
              style={{
                marginTop: "10px",
                whiteSpace: notificationOpen ? "break-spaces" : "inherit",
                backgroundColor: "var(--btnBgBlue)",
                color: "var(--lighterBg)",
                boxShadow: "var(--shadow)",
                borderRadius: "var(--buttonBorderRadius)",
                padding: "5px",
              }}
            >
              Bildiriminiz Bulunmamaktadır.
            </div>
          )}
        </div>
      </Drawer>
    </div>
  );
};

export default NotificationBar;
