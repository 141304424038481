import "../../style/profileSettings/AddressInfo.css";
import { Box } from "@mui/material";
import WorkOutlineOutlinedIcon from "@mui/icons-material/WorkOutlineOutlined";
import { useInView, animated } from "@react-spring/web";
import { useCookies } from "react-cookie";
import NotificationBar from "../sidebar/NotificationBar";
import { DynamicTitle } from "../../utils/DynamicTitle";
import ProfileNavBar from "./ProfileNavBar";
import NewIossNumbers from "./iossNumbers/NewIossNumbers";
import { ToastContainer } from "react-toastify";
import axios from "../../api/axios";
import WrapperStyle from "../../utils/WrapperStyle";
import ProfileSettingsStyle from "../../style/styleJs/ProfileSettingsStyle";
import ProfileSettingsTitle from "./ProfileSettingsTitle";

const IossNumbers = () => {
  const [token] = useCookies(["jwt"]);
  const jwt = token?.jwt;
  axios.defaults.headers.common["Authorization"] = `Bearer ${jwt}`;

  DynamicTitle("IOSS Numaralarım");

  const [ref, springs] = useInView(
    () => ({
      from: {
        opacity: 0,
        y: 20,
      },
      to: {
        opacity: 1,
        y: 0,
      },
    }),
    {
      rootMargin: "-10% 0%",
    }
  );

  return (
    <Box className="address-info">
      <NotificationBar />
      <ProfileSettingsTitle />
      <ProfileNavBar />
      <ToastContainer />
      <div style={WrapperStyle()}>
        <animated.div ref={ref} style={springs}>
          <Box style={ProfileSettingsStyle()}>
            <div className="profile-card-wrapper">
              <div className="profile-card-header">
                <WorkOutlineOutlinedIcon
                  sx={{
                    width: "20px",
                    height: "20px",
                    color: "var(--priceText)",
                  }}
                />
                <span style={{ color: "var(--priceText)", fontWeight: 700 }}>IOSS/VAT Numaralarım</span>
              </div>
            </div>
            <NewIossNumbers />
          </Box>
        </animated.div>
      </div>
    </Box>
  );
};

export default IossNumbers;
