import { FormControl, InputLabel, Select, MenuItem } from "@mui/material";
import { currentIngtegrationsState } from "../../../atoms/integrations";
import { useRecoilValue } from "recoil";
import Errors from "../../../utils/Errors";
import axios from "../../../api/axios";
import { useEffect, useState, useCallback } from "react";

const ShopNameSearch = ({ shopName, setShopName }) => {
  const currentIngtegrations = useRecoilValue(
    currentIngtegrationsState
  );

  const [fastList, setFastList] = useState([]);

  const uniqueNames = currentIngtegrations.map((item) => item.shop_name);

  const uniqueNamesExcel = [...new Set(fastList.map((item) => item.shop_name))];

  const fastShipmentListExcel = useCallback(async () => {
    await axios
      .get("general/integration-parcel", {
        params: {
          is_excel: window.location.href.endsWith("create-fast-all-shipment") ? 1 : 0
        },
      })
      .then((response) => {
        setFastList(response.data.data)
      })
      .catch((error) => Errors(error))
      .finally();
  }, [setFastList]);

  useEffect(() => {
    fastShipmentListExcel();
  },[])

  return (
    <FormControl fullWidth size="small">
      <InputLabel sx={{ fontSize: "14px" }} id="supplier-select-label">
        Mağaza Adı
      </InputLabel>
      <Select
        label="Tarih Tipi"
        value={shopName}
        onChange={(e) => setShopName(e.target.value)}
      >
        <MenuItem value="">
          <em>Temizle</em>
        </MenuItem>
        {window.location.href.endsWith("create-fast-all-shipment")
          ? uniqueNamesExcel?.map((item, index) => (
            <MenuItem key={index} value={item}>
              {item}
            </MenuItem>
          ))
          : uniqueNames?.map((item, index) => (
            <MenuItem key={index} value={item}>
              {item}
            </MenuItem>
          ))}
      </Select>

    </FormControl>
  );
};

export default ShopNameSearch;
