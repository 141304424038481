import "../../../style/globals/CountryRestrictInfo.css";
import { Modal } from "@mui/material";
import InfoIcon from "@mui/icons-material/Info";

const CountryRestrictInfo = ({ open, setOpen }) => {
  const handleClose = () => {
    setOpen(false);
  };

  return (
    <Modal open={open} onClose={handleClose} className="country-restrict-info-wrapper">
      <div className="country-restrict-info">
        <h1>Gümrük Muafiyet Limitleri</h1>
        <article className="country-restrict-areas">
          <figure>
            <img src="/images/countries/us.png" alt="Amerika Birleşik Devletleri" />
            <figcaption className="country">
              <p className="title">Amerika Birleşik Devletleri</p>
              <p>800 USD (Amerikan Doları)</p>
            </figcaption>
          </figure>
          <figure>
            <img src="/images/countries/eu.png" alt="Avrupa Birliği" />
            <figcaption className="country">
              <p className="title">Avrupa Birliği Ülkeleri</p>
              <p>0 Euro (Avro)</p>
            </figcaption>
          </figure>
          <figure>
            <img src="/images/countries/uk.png" alt="İngiltere - Birleşik Krallık" />
            <figcaption className="country">
              <p className="title">İngiltere - Birleşik Kralık</p>
              <p>0 GBP (İngiliz Poundu)</p>
            </figcaption>
          </figure>
          <figure>
            <img src="/images/countries/ca.png" alt="Kanada" />
            <figcaption className="country">
              <p className="title">Kanada</p>
              <p>15 CAD (Kanada Doları)</p>
            </figcaption>
          </figure>
        </article>
        <article className="country-restrict-areas">
          <figure>
            <img src="/images/countries/world.png" alt="Dünya" />
            <figcaption className="others">
              <strong>Diğer Ülkeler</strong> İçin Müşteri Hizmetlerimize Danışınız
            </figcaption>
          </figure>
        </article>
        <article className="info">
          <h5>
            <InfoIcon sx={{ color: "var(--btnBgBlue)" }} />
            Önemli Bilgi;
          </h5>
          <p className="info-question">
            Bu limitlerden <strong>YÜKSEK</strong> olursa ne olur?
          </p>
          <p>
            - Alıcınız <strong>KDV</strong> ve/veya <strong>İthalat Vergisi</strong> gibi
            kalemlerden oluşan <strong>GÜMRÜK MASRAFI</strong> öder.
          </p>
          <p className="info-question">
            Bu limitlerden <strong>DÜŞÜK</strong> olursa ne olur?
          </p>
          <p>
            - Kesinlikle <strong>GÜMRÜK MASRAFI</strong> ödemeyeceği anlamına gelmez. Ülke gümrük
            otoriteleri gönderinin içeriğine, değerine, GTIP numarasına göre değerlendirir ve{" "}
            <strong>KDV</strong> oluşup oluşmamasına karar verir.
          </p>
          <p className="info-question">
            <strong>IOSS Numarası</strong> ile mi göndereceksiniz?
          </p>
          <p>
            - Beyan edilen değer €150'dan az ve pazar yerinin IOSS Numarasını yazarak
            gönderiyorsanız alıcı <strong>KDV</strong>'yi satın alırken ödediği için tekrar bir
            masraf oluşmayacaktır.
          </p>
        </article>
      </div>
    </Modal>
  );
};

export default CountryRestrictInfo;
