import {
  Box,
  Button,
  CircularProgress,
  Modal,
  TextField,
  ThemeProvider,
  Typography,
  createTheme,
} from "@mui/material";
import CheckCircleOutlinedIcon from "@mui/icons-material/CheckCircleOutlined";
import { useRecoilState } from "recoil";
import {
  arrangementCompanyBillModalOpenState,
  selectedArrangementItemState,
} from "../../../atoms/myWallet";
import { useCallback, useState } from "react";
import GetToday from "../../../utils/GetToday";
import { useDropzone } from "react-dropzone";
import axios from "../../../api/axios";
import { toast } from "react-toastify";
import uploadIcon from "../../../img/icons/upload_icon.svg";
import DowloadPdfView from "../../dowloadPdfView/DowloadPdfView";
import Errors from "../../../utils/Errors";

const theme = createTheme({
  components: {
    MuiModal: {
      styleOverrides: {
        root: {
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          padding: "20px",
        },
      },
    },
    MuiOutlinedInput: {
      styleOverrides: {
        root: {
          borderRadius: "8px",
        },
      },
    },
  },
});

const MyWalletArrangementCompanyBillModal = () => {
  const [arrangementCompanyBillModalOpen, setArrangementCompanyBillModalOpen] = useRecoilState(
    arrangementCompanyBillModalOpenState
  );
  const [selectedArrangementItem, setSelectedArrangementItem] = useRecoilState(
    selectedArrangementItemState
  );

  const [loading, setLoading] = useState(false);
  const [selectedDocuments, setSelectedDocuments] = useState([]);
  const [billDate, setBillDate] = useState(GetToday());
  const [billNo, setBillNo] = useState("");
  const [billAmount, setBillAmount] = useState("");
  const [sendFiles, setSendFiles] = useState([]);

  const handleBillDateChange = (e) => {
    setBillDate(e.target.value);
  };

  const handleBillNoChange = (e) => {
    setBillNo(e.target.value.toUpperCase());
  };

  const billAmountChange = (e) => {
    // Eğer değer nokta içeriyorsa, virgüle çevir
    const value = e.target.value.replace(",", ".");

    // Değer boşsa veya sadece rakamlar ve virgül içeriyorsa durumu güncelle
    if (value === "" || /^\d*([.,]\d{0,2})?$/.test(value)) {
      setBillAmount(value);
    }
  };

  const billAmountBlur = (e) => {
    const value = e.target.value;
    if (Number(value) > Number(selectedArrangementItem?.member_payable_amount)) {
      setBillAmount(
        Number(selectedArrangementItem?.member_payable_amount).toFixed(2).replace(",", ".")
      );
    }
  };

  const handleClose = useCallback(() => {
    setArrangementCompanyBillModalOpen(false);
    setSelectedArrangementItem({});
    setSelectedDocuments([]);
  }, [setArrangementCompanyBillModalOpen, setSelectedDocuments, setSelectedArrangementItem]);

  const handleDrop = (acceptedFiles) => {
    const newFiles = acceptedFiles.map((file) => ({
      file,
      id: Math.random().toString(),
      type: file.type.split("/")[1].toLowerCase(),
    }));
    setSendFiles((prevFiles) => [...prevFiles, ...newFiles]);
    setSelectedDocuments((prevFiles) => [...prevFiles, ...newFiles]);
  };
  const handleSetState = (id) => {
    setSendFiles((prevFiles) => prevFiles.filter((file) => file.id !== id));
    setSelectedDocuments((prevFiles) => prevFiles.filter((file) => file.id !== id));
  };

  const { getRootProps, getInputProps } = useDropzone({
    onDrop: handleDrop,
    multiple: true,
  });

  const uploadSelected = useCallback(() => {
    setLoading(true);
    let formData = new FormData();
    Array.from(selectedDocuments).forEach((file, index) => {
      formData.append(`member_invoice_file`, file.file);
    });
    formData.append("member_invoice_no", billNo);
    formData.append("member_invoice_date", billDate);
    formData.append("member_invoice_amount", Number(billAmount));

    axios
      .post(`refund-compensation/update/${selectedArrangementItem.id}`, formData)
      .then((response) => {
        toast.success(response.data.message);
        setLoading(false);
        setTimeout(() => {
          window.location.reload(true);
        }, 1000);
      })
      .catch((error) => {
        Errors(error);
        setLoading(false);
      });
  }, [selectedArrangementItem, selectedDocuments, billNo, billDate, billAmount]);

  return (
    <ThemeProvider theme={theme}>
      <Modal open={arrangementCompanyBillModalOpen} onClose={handleClose}>
        <Box
          className="my-wallet-arrangement-bill-modal"
          style={{
            maxHeight: "90vh",
            overflowY: "auto",
          }}
        >
          <Typography className="my-wallet-arrangement-bill-modal-headline" variant="h6">
            Kurumsal Üyelik
          </Typography>
          <Box className="my-wallet-arrangement-bill-modal-info">
            <CheckCircleOutlinedIcon />
            <Typography>
              Kurumsal müşteri olduğunuzdan tazmin iade faturası düzenlenmeniz gerekmektedir.
              Aşağıda belirtilmiş tutar kadar iade faturasını keserek sisteme işlemenizi rica
              ederiz.
            </Typography>
          </Box>
          <Box className="my-wallet-arrangement-bill-modal-inputs">
            <TextField
              fullWidth
              value={billDate}
              onChange={handleBillDateChange}
              autoComplete="off"
              type="date"
              label="Fatura Tarihi"
              size="small"
            />
            <TextField
              fullWidth
              value={billNo}
              onChange={handleBillNoChange}
              autoComplete="off"
              placeholder="GIB202300000563"
              label="Fatura Numarası"
              size="small"
            />
            <TextField
              fullWidth
              value={billAmount}
              onChange={billAmountChange}
              onBlur={billAmountBlur}
              autoComplete="off"
              inputProps={{
                inputMode: "numeric",
              }}
              label="Fatura Tutarı"
              size="small"
            />
          </Box>
          <Box className="dropzone-wrapper">
            <Box className="dropzone">
              <div className="dropzone-area" {...getRootProps()}>
                <input {...getInputProps()} />
                <div className="dropzone-area-text">
                  <img src={uploadIcon} alt="Upload Icon" />
                  <span className="dropzone-area-text-headline">
                    Dosyayı sürükleyip bırakın veya{" "}
                    <span style={{ color: "var(--info)" }}>Gözat</span>
                  </span>
                  <span className="dropzone-area-text-sub">Desteklenen Format: PDF</span>
                </div>
              </div>
            </Box>
            <DowloadPdfView sendFiles={sendFiles} handleSetState={handleSetState} />
            <Box
              sx={{
                display: "flex",
                justifyContent: "flex-end",
                gap: "var(--gap)",
                width: "100%",
              }}
            >
              <Button className="iyzi-button" onClick={handleClose}>
                İptal
              </Button>
              {loading ? (
                <div
                  style={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    width: "64px",
                    height: "33px",
                  }}
                >
                  <CircularProgress sx={{ width: "24px!important", height: "24px!important" }} />
                </div>
              ) : (
                <Button className="iyzi-button" onClick={uploadSelected}>
                  Kaydet
                </Button>
              )}
            </Box>
          </Box>
          <Box>
            {selectedDocuments?.length !== 0 && (
              <Box
                sx={{
                  color: "var(--priceText)",
                  fontWeight: 700,
                  fontSize: "14px",
                  marginBottom: "20px",
                  marginTop: "20px",
                  paddingLeft: "8px",
                }}
              >
                Yüklenecek Dosyalar - {selectedDocuments?.length}
              </Box>
            )}
            {selectedDocuments?.length !== 0 &&
              selectedDocuments?.map((document, key) => (
                <Box
                  sx={{
                    border: "1px solid var(--phoneDigitBg)",
                    marginBottom: "10px",
                    padding: "10px",
                    borderRadius: "var(--wrapperBorderRadius)",
                    fontSize: "13px",
                    cursor: "pointer",
                    color: "var(--priceText)",
                    transition: "all 150ms linear",
                    ":hover": {
                      backgroundColor: "var(--pastelBlue)",
                      transition: "all 150ms linear",
                    },
                  }}
                  key={key}
                >
                  <span
                    style={{
                      fontSize: "13px",
                      color: "var(--priceText)",
                      fontWeight: "700",
                    }}
                  >
                    {key + 1}
                  </span>{" "}
                  - {document.file.name} / {document.file.type}
                </Box>
              ))}
          </Box>
        </Box>
      </Modal>
    </ThemeProvider>
  );
};

export default MyWalletArrangementCompanyBillModal;
