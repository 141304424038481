import { Modal, Table, TableBody, TableContainer, ThemeProvider, createTheme } from "@mui/material";
import { useRecoilState } from "recoil";
import { billsKonsimentoDataState, billsKonsimentoModalOpenState } from "../../../atoms/myWallet";
import { useCallback } from "react";
import BillsKonsimentoTableHead from "./BillsKonsimentoTableHead";
import BillsKonsimentoTableBody from "./BillsKonsimentoTableBody";
import CancelOutlinedIcon from "@mui/icons-material/CancelOutlined";

const theme = createTheme({
  components: {
    MuiModal: {
      styleOverrides: {
        root: {
          margin: "20px",
          overflow: "auto",
        },
      },
    },
    MuiSelect: {
      styleOverrides: {
        outlined: {
          display: "flex",
          alignItems: "center",
          gap: "10px",
        },
      },
    },
    MuiFormControlLabel: {
      styleOverrides: {
        label: {
          fontSize: "14px",
          opacity: 0.8,
        },
      },
    },
  },
});

const BillsKonsimentoModal = () => {
  const [billsKonsimentoModalOpen, setBillsKonsimentoModalOpen] = useRecoilState(
    billsKonsimentoModalOpenState
  );
  const [billsKonsimentoData, setBillsKonsimentoData] = useRecoilState(billsKonsimentoDataState);

  const handleClose = useCallback(() => {
    setBillsKonsimentoModalOpen(false);
    setBillsKonsimentoData([]);
  }, [setBillsKonsimentoModalOpen, setBillsKonsimentoData]);

  return (
    <ThemeProvider theme={theme}>
      <Modal open={billsKonsimentoModalOpen} onClose={handleClose}>
        <TableContainer
          sx={{
            backgroundColor: "var(--lighterBg)",
            borderRadius: "var(--inputBorderRadius)",
            padding: "20px",
            outline: 0,
            display: "grid",
            gap: "var(--gap)",
            width: "100%",
            maxWidth: "1100px",
            margin: "auto",
            position: "relative",
          }}
          style={{
            maxHeight: "90vh",
            overflowY: "auto",
          }}
        >
          <CancelOutlinedIcon
            sx={{
              position: "absolute",
              right: 1,
              top: 1,
              cursor: "pointer",
              color: "var(--priceText)",
              transition: "all 80ms linear",
              ":hover": {
                color: "var(--notRed)",
                transition: "all 80ms linear",
              },
            }}
            onClick={handleClose}
          />
          <Table>
            <BillsKonsimentoTableHead />
            <TableBody>
              {billsKonsimentoData.map((row, key) => (
                <BillsKonsimentoTableBody key={key} row={row} />
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      </Modal>
    </ThemeProvider>
  );
};

export default BillsKonsimentoModal;
