import { useRecoilState } from "recoil";
import { memberStatusModalState } from "../../atoms/membership";
import { Modal, IconButton, Button } from "@mui/material";
import { useCallback } from "react";
import HighlightOffOutlinedIcon from "@mui/icons-material/HighlightOffOutlined";
import { useNavigate } from "react-router-dom";

const ProfileStatus = () => {
  const [memberStatusModal, setMemberStatusModal] = useRecoilState(memberStatusModalState);
  const user = JSON.parse(localStorage.getItem("user"));
  const status = user?.user?.user_member?.status;

  const handleClose = useCallback(() => {
    if (Number(status) === 2) {
      setMemberStatusModal(false);
    }
  }, [status, setMemberStatusModal]);

  const navigate = useNavigate();

  const handleProfileNavigate = () => {
    navigate("/profile-settings/general");
    setMemberStatusModal(false);
  };

  const handleAddressNavigate = () => {
    navigate("/profile-settings/address-info");
    setMemberStatusModal(false);
  };

  return (
    <Modal
      sx={{ display: "flex", alignItems: "center", justifyContent: "center", padding: "20px" }}
      open={memberStatusModal}
      onClose={handleClose}
    >
      <div
        style={{
          padding: "20px",
          borderRadius: "var(--inputBorderRadius)",
          position: "relative",
          backgroundColor: "var(--lighterBg)",
          maxHeight: "90vh",
          overFlowY: "auto",
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
          alignItems: "center",
          gap: "var(--gap2x)",
          maxWidth: 600,
          width: "100%",
        }}
      >
        <IconButton
          onClick={handleClose}
          sx={{ position: "absolute", top: 0, right: 0 }}
          aria-label="kapat"
        >
          <HighlightOffOutlinedIcon sx={{ color: "black" }} />
        </IconButton>
        <div style={{ display: "flex", flexDirection: "column", gap: "var(--gap2x)" }}>
          <img
            src="images/home/profile.png"
            alt=""
            style={{ maxWidth: "500px", width: "100%", margin: "auto" }}
          />
          {/* <div
            style={{
              display: "flex",
              flexDirection: "column",
              gap: "var(--gap2x)",
              alignItems: "center",
            }}
          >
            <h6>Destek Hattı Numaralarımız</h6>
            <a
              style={{
                fontSize: "20px",
                fontWeight: 700,
                color: "var(--btnBgBlue)",
                textDecoration: "none",
              }}
              href="tel:05304084994"
            >
              0530 408 49 94
            </a>
            <a
              style={{
                fontSize: "20px",
                fontWeight: 700,
                color: "var(--btnBgBlue)",
                textDecoration: "none",
              }}
              href="tel:05323794994"
            >
              0532 379 49 94
            </a>
            <a
              style={{
                fontSize: "20px",
                fontWeight: 700,
                color: "var(--btnBgBlue)",
                textDecoration: "none",
              }}
              href="tel:05335984994"
            >
              0533 598 49 94
            </a>
            <p style={{ fontSize: "16px", color: "var(--text)" }}></p>
          </div> */}
          <h6 style={{ fontSize: "20px", color: "var(--text)", textAlign: "center" }}>
            Değerli Üyemiz,
          </h6>
          <p style={{ fontSize: "16px", color: "var(--text)" }}>
            Ödeme yaparken problem yaşayan üyelerimizin,{" "}
            <span style={{ fontSize: "inherit", fontWeight: 700 }}>fatura adresi</span> bilgilerinin
            ve <span style={{ fontSize: "inherit", fontWeight: 700 }}>profil</span> bilgilerinin
            doğruluğunu kontrol etmelerini rica ederiz. Fatura adres bilgilerinde eksiklik veya hata
            olan üyelerimiz, güvenlik protokolleri sebebiyle ödeme yapamayacaktır.
          </p>
          <p style={{ fontSize: "16px", color: "var(--text)" }}>
            Sorun yaşamaya devam ediyorsanız, destek bildirimi oluşturabilir veya yukarıdaki
            numaralardan birinden müşteri hizmetleri hattımız üzerinden bize ulaşabilirsiniz.
          </p>
        </div>
        <div style={{ display: "flex", alignItems: "center", gap: "var(--gap)" }}>
          <Button className="iyzi-button-blue" onClick={handleProfileNavigate} type="submit">
            Profil Ayarlarını Kontrol Et
          </Button>
          <Button className="iyzi-button-blue" onClick={handleAddressNavigate} type="submit">
            Fatura Adresini Kontrol Et
          </Button>
        </div>
      </div>
    </Modal>
  );
};

export default ProfileStatus;
