import React, { useCallback } from "react";
import {
  Box,
  Card,
  CardContent,
  createTheme,
  InputAdornment,
  TextField,
  ThemeProvider,
} from "@mui/material";
import { useRecoilState, useSetRecoilState } from "recoil";
import axios from "../../api/axios";
import SearchIcon from "@mui/icons-material/Search";
import {
  courierBasicSearchErrorState,
  courierListState,
} from "../../../src/atoms/courierRegistration";
import Errors from "../../utils/Errors";

const theme = createTheme({
  components: {
    MuiPaper: {
      styleOverrides: {
        root: {
          borderRadius: "10px",
          boxShadow: "none",
        },
      },
    },
  },
});

function CourierRegisterSearch() {
  const [courierBasicSearchError, setCourierBasicSearchError] = useRecoilState(
    courierBasicSearchErrorState
  );
  const setCourierList = useSetRecoilState(courierListState);

  const handleBasicSearch = useCallback(
    async (keyword) => {
      try {
        const response = await axios.get("booking");

        const searchData = response.data.data;

        const filteredItems = searchData.filter((item) =>
          item.will_be_delivered_parcels.some((element) => element?.parcel_ref_no === keyword)
        );

        if (keyword.length > 0) {
          if (filteredItems === undefined || filteredItems.length === 0) {
            setCourierBasicSearchError(true);
            setCourierList([]);
          } else {
            setCourierList(filteredItems);
            setCourierBasicSearchError(false);
          }
        } else {
          setCourierList(searchData);
          setCourierBasicSearchError(false);
        }
      } catch (error) {
        Errors(error);
      }
    },
    [setCourierBasicSearchError, setCourierList]
  );

  return (
    <ThemeProvider theme={theme}>
      <Box
        className="myShipments-search"
        sx={{
          boxShadow: "var(--shadow)",
          backgroundColor: "var(--lighterBg)",
          borderRadius: "var(--wrapperBorderRadius)",
          maxWidth: "1400px",
          width: "100%",
          margin: "auto",
        }}
      >
        <Card className="search-card">
          <CardContent className="search-card-content">
            <Box sx={{ display: "flex", gap: "var(--gap)", width: "100%" }}>
              <TextField
                className="search-card-content-search"
                sx={{ width: "100% !important" }}
                label="Kurye Kaydı Arama"
                placeholder="AWB numarası giriniz"
                error={courierBasicSearchError}
                autoComplete="off"
                helperText={
                  courierBasicSearchError
                    ? "Arama kriterlerinizi değiştirip yeniden deneyiniz."
                    : ""
                }
                onKeyDown={(e) => handleBasicSearch(e.target.value)}
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      <SearchIcon />
                    </InputAdornment>
                  ),
                }}
                size="small"
              />
            </Box>
          </CardContent>
        </Card>
      </Box>
    </ThemeProvider>
  );
}

export default CourierRegisterSearch;
