import {
  Box,
  Button,
  Card,
  CardContent,
  Collapse,
  createTheme,
  FormControl,
  InputAdornment,
  InputLabel,
  MenuItem,
  Select,
  TextField,
  ThemeProvider,
} from "@mui/material";
import { useCallback, useEffect, useState } from "react";
import MyShipmentsDetailedSearch from "./MyShipmentsDetailedSearch";
import SearchIcon from "@mui/icons-material/Search";
import ClearIcon from "@mui/icons-material/Clear";
import {
  awbState,
  basicSearchErrorState,
  basicSearchState,
  detailedStatusState,
  errorSetState,
  myShipmentListLoadingState,
  myShipmentListState,
  myShipmentsListLimitState,
  receiverCountryCodeState,
  receiverState,
  refIdState,
  selectedExportStatusState,
  selectedDomesticStatusState,
  selectedCaseStatusState,
  selectedPriceStatusState,
  selectedSupplierState,
  senderCountryCodeState,
  senderState,
  shipmentListCurrentPageState,
  shipmentListOrderDirectionState,
  shipmentsListArrayLengthState,
  shipmentStatusState,
  timeState,
  totalPagesState,
} from "../../atoms/myShipments";
import { useRecoilState, useRecoilValue, useSetRecoilState } from "recoil";
import axios from "../../api/axios";
import { useLocation } from "react-router-dom";
import Errors from "../../utils/Errors";

const theme = createTheme({
  components: {
    MuiPaper: {
      styleOverrides: {
        root: {
          borderRadius: "10px",
          boxShadow: "none",
        },
      },
    },
  },
});

const MyShipmentsSearch = () => {
  const [expanded, setExpanded] = useState(false);

  const handleExpandClick = () => {
    setExpanded(!expanded);
  };

  const [shipmentStatus, setShipmentStatus] = useRecoilState(shipmentStatusState);
  const [basicSearch, setBasicSearch] = useRecoilState(basicSearchState);
  const [myShipmentList, setMyShipmentList] = useRecoilState(myShipmentListState);
  const [basicSearchError, setBasicSearchError] = useRecoilState(basicSearchErrorState);
  const [errorSet, setErrorSet] = useRecoilState(errorSetState);

  const myShipmentsListLimit = useRecoilValue(myShipmentsListLimitState);
  const shipmentOrderDirectionPage = useRecoilValue(shipmentListOrderDirectionState);
  const awb = useRecoilValue(awbState);
  const refId = useRecoilValue(refIdState);
  const sender = useRecoilValue(senderState);
  const receiver = useRecoilValue(receiverState);
  const time = useRecoilValue(timeState);
  const senderCountryCode = useRecoilValue(senderCountryCodeState);
  const receiverCountryCode = useRecoilValue(receiverCountryCodeState);
  const selectedSupplier = useRecoilValue(selectedSupplierState);
  const selectedPriceStatus = useRecoilValue(selectedPriceStatusState);
  const selectedExportStatus = useRecoilValue(selectedExportStatusState);
  const selectedDomesticStatus = useRecoilValue(selectedDomesticStatusState);
  const selectedCaseStatus = useRecoilValue(selectedCaseStatusState);
  const detailedStatus = useRecoilValue(detailedStatusState);
  const shipmentListCurrentPage = useRecoilValue(shipmentListCurrentPageState);

  const setMyShipmentListLoading = useSetRecoilState(myShipmentListLoadingState);
  const setDetailedStatus = useSetRecoilState(detailedStatusState);
  const setTotalPages = useSetRecoilState(totalPagesState);
  const setShipmentsListArrayLength = useSetRecoilState(shipmentsListArrayLengthState);

  const location = useLocation();

  // Parametreleri al
  const searchParams = new URLSearchParams(location.search);
  const awbParam = searchParams.get("awb");

  const handleBasicSearch = useCallback(async () => {
    setMyShipmentListLoading(true);
    setDetailedStatus(0);

    await axios
      .get(`parcel`, {
        params: {
          order_direction: shipmentOrderDirectionPage,
          limit: myShipmentsListLimit,
          page: 1,
          search: basicSearch,
        },
      })
      .then((response) => {
        setMyShipmentList(response.data.data);
        setTotalPages(response.data.totalPages);

        if (basicSearch === "") {
          setErrorSet(true);
          setBasicSearchError(false);
        }
      })
      .catch((error) => Errors(error))
      .finally(() => setMyShipmentListLoading(false));
  }, [
    setTotalPages,
    setMyShipmentList,
    setMyShipmentListLoading,
    setDetailedStatus,
    setBasicSearchError,
    setErrorSet,
    myShipmentsListLimit,
    shipmentOrderDirectionPage,
    basicSearch,
  ]);

  const handleClearBasicSearch = useCallback(async () => {
    setBasicSearch("");

    setMyShipmentListLoading(true);
    setDetailedStatus(0);
    setErrorSet(true);
    setBasicSearchError(false);

    await axios
      .get(`parcel`, {
        params: {
          order_direction: shipmentOrderDirectionPage,
          limit: myShipmentsListLimit,
          page: 1,
        },
      })
      .then((response) => {
        setMyShipmentList(response.data.data);
        setTotalPages(response.data.totalPages);
        setMyShipmentListLoading(false);
      })
      .catch((error) => Errors(error))
      .finally(() => setMyShipmentListLoading(false));
  }, [
    setBasicSearch,
    setTotalPages,
    setMyShipmentList,
    setMyShipmentListLoading,
    setDetailedStatus,
    setBasicSearchError,
    setErrorSet,
    myShipmentsListLimit,
    shipmentOrderDirectionPage,
  ]);

  const handleDetailedSearch = useCallback(
    async (shipmentStatusValue) => {
      setMyShipmentListLoading(true);
      setDetailedStatus(1);

      await axios
        .get(`parcel`, {
          params: {
            order_direction: shipmentOrderDirectionPage,
            limit: myShipmentsListLimit,
            page: 1,
            tracking_awb: awb,
            parcel_ref_no: refId,
            supplier_id: selectedSupplier !== "" ? Number(selectedSupplier) : selectedSupplier,
            status: shipmentStatusValue,
            tracking_awb_date: time,
            sender_name: sender,
            receiver_name: receiver,
            sender_country: senderCountryCode,
            receiver_country: receiverCountryCode,
            parcel_type: selectedExportStatus,
            paid: selectedPriceStatus,
            is_domestic: selectedDomesticStatus,
            is_case: selectedCaseStatus,
            detailed: 1,
          },
        })
        .then((response) => {
          setMyShipmentList(response.data.data);
          setTotalPages(response.data.totalPages);
          setMyShipmentListLoading(false);
          setErrorSet(true);
          setBasicSearchError(false);
        })
        .catch((error) => Errors(error))
        .finally(() => setMyShipmentListLoading(false));
    },
    [
      setMyShipmentList,
      setMyShipmentListLoading,
      setDetailedStatus,
      setTotalPages,
      setErrorSet,
      setBasicSearchError,
      awb,
      time,
      shipmentOrderDirectionPage,
      myShipmentsListLimit,
      refId,
      selectedSupplier,
      sender,
      senderCountryCode,
      receiver,
      receiverCountryCode,
      selectedExportStatus,
      selectedDomesticStatus,
      selectedCaseStatus,
      selectedPriceStatus,
    ]
  );

  const handleAwbSearch = useCallback(async () => {
    setMyShipmentListLoading(true);
    setDetailedStatus(0);

    await axios
      .get(`parcel`, {
        params: {
          order_direction: shipmentOrderDirectionPage,
          limit: myShipmentsListLimit,
          page: 1,
          search: awbParam,
        },
      })
      .then((response) => {
        setMyShipmentList(response.data.data);
        setTotalPages(response.data.totalPages);
        setMyShipmentListLoading(false);
      })
      .catch((error) => Errors(error))
      .finally(() => setMyShipmentListLoading(false));
  }, [
    setTotalPages,
    setMyShipmentList,
    setMyShipmentListLoading,
    setDetailedStatus,
    myShipmentsListLimit,
    shipmentOrderDirectionPage,
    awbParam,
  ]);

  const getShipment = useCallback(async () => {
    setMyShipmentListLoading(true);

    const params =
      Number(detailedStatus) === 1
        ? {
            order_direction: shipmentOrderDirectionPage,
            limit: myShipmentsListLimit,
            page: shipmentListCurrentPage,
            tracking_awb: awb,
            parcel_ref_no: refId,
            supplier_id: selectedSupplier !== "" ? Number(selectedSupplier) : selectedSupplier,
            status: shipmentStatus,
            tracking_awb_date: time,
            sender_name: sender,
            receiver_name: receiver,
            sender_country: senderCountryCode,
            receiver_country: receiverCountryCode,
            parcel_type: selectedExportStatus,
            paid: selectedPriceStatus,
            is_domestic: selectedDomesticStatus,
            is_case: selectedCaseStatus,
            detailed: 1,
          }
        : {
            order_direction: shipmentOrderDirectionPage,
            limit: myShipmentsListLimit,
            page: shipmentListCurrentPage,
          };

    await axios
      .get(`parcel`, { params: params })
      .then((response) => {
        setMyShipmentList(response.data.data);
        setTotalPages(response.data.totalPages);
        setMyShipmentListLoading(false);
        setShipmentsListArrayLength(response.data.data.length);
      })
      .catch((error) => Errors(error))
      .finally(() => setMyShipmentListLoading(false));
  }, [
    setMyShipmentList,
    setMyShipmentListLoading,
    setShipmentsListArrayLength,
    setTotalPages,
    myShipmentsListLimit,
    shipmentListCurrentPage,
    shipmentOrderDirectionPage,
    time,
    senderCountryCode,
    receiverCountryCode,
    selectedSupplier,
    selectedPriceStatus,
    selectedExportStatus,
    selectedDomesticStatus,
    selectedCaseStatus,
    awb,
    refId,
    sender,
    receiver,
    shipmentStatus,
    detailedStatus,
  ]);

  useEffect(() => {
    if (myShipmentList.length === 0 && !errorSet) {
      setBasicSearchError(true);
    }

    if (myShipmentList.length !== 0) {
      setErrorSet(false);
    }
  }, [myShipmentList, errorSet, setBasicSearchError, setErrorSet]);

  useEffect(() => {
    if (awbParam !== null) {
      handleAwbSearch();
    } else {
      //getShipment(); //* URL Parametresi yerine search doluluğuna göre bir deneme yapacağım o yüzden şimdilik burayı yorum satırına alıyorum.
      if (basicSearch === "") {
        getShipment();
      } else return;
    }
  }, [awbParam, handleAwbSearch]);

  return (
    <ThemeProvider theme={theme}>
      <Box className="myShipments-search">
        <Card className="search-card">
          <CardContent className="search-card-content">
            <Box sx={{ display: "flex", gap: "var(--gap)", width: "100%" }}>
              <TextField
                className="search-card-content-search"
                label="Gönderi Arama"
                placeholder="Gönderi Numarası, İzleme Numarası, Tedarikçi İsmi, Alıcı İsmi, Gönderici İsmi"
                value={basicSearch}
                error={basicSearchError}
                autoComplete="off"
                helperText={
                  basicSearchError ? "Arama kriterlerinizi değiştirip yeniden deneyiniz." : ""
                }
                onChange={(e) => setBasicSearch(e.target.value)}
                onKeyDown={(e) =>
                  e.key === "Enter"
                    ? handleBasicSearch()
                    : e.key === "Escape"
                    ? handleClearBasicSearch()
                    : ""
                }
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      <SearchIcon
                        sx={{ ":hover": { cursor: "pointer" } }}
                        onClick={handleBasicSearch}
                      />
                    </InputAdornment>
                  ),
                  endAdornment: (
                    <InputAdornment position="start">
                      {basicSearch !== "" && (
                        <ClearIcon
                          sx={{ ":hover": { cursor: "pointer" } }}
                          onClick={handleClearBasicSearch}
                        />
                      )}
                    </InputAdornment>
                  ),
                }}
                size="small"
              />
            </Box>
            <Box className="search-wrapper" sx={{ display: "flex", gap: "var(--gap)" }}>
              <Button
                //className="search-card-content-handler-button"
                className="iyzi-button"
                sx={{ minWidth: "150px" }}
                onClick={handleExpandClick}
              >
                {expanded ? "Detaylı Aramayı Kapat" : "Detaylı Aramayı Aç"}
              </Button>
            </Box>
          </CardContent>
          <Collapse in={expanded} timeout="auto" unmountOnExit>
            <CardContent>
              <MyShipmentsDetailedSearch expanded={expanded} />
            </CardContent>
          </Collapse>
        </Card>
      </Box>
    </ThemeProvider>
  );
};

export default MyShipmentsSearch;
