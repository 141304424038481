import { Button, Card, CardContent, Collapse } from "@mui/material";
import OrderSelectAll from "./orderDetailSearch/OrderSelectAll";
import { useState } from "react";
import OrderCWSearch from "./orderDetailSearch/OrderCWSearch";
import axios from "../../api/axios";
import { useSetRecoilState } from "recoil";
import { orderListCheckedListState, orderListState } from "../../atoms/orders";
import Errors from "../../utils/Errors";
import OrderSenderCountrySearch from "./orderDetailSearch/OrderSenderCountrySearch";
import OrderReceiverCountrySearch from "./orderDetailSearch/OrderReceiverCountrySearch";
import OrderTypeSearch from "./orderDetailSearch/OrderTypeSearch";
import OrderReceiverNameSearch from "./orderDetailSearch/OrderReceiverNameSearch";
import OrderSupplierIDSearch from "./orderDetailSearch/OrderSupplierIDSearch";
import OrderParcelType from "./orderDetailSearch/OrderParcelType";
import OrderBtwDateSearch from "./orderDetailSearch/OrderBtwDateSearch";
import FormatDate from "../../utils/FormatDate";

const OrderDetailSearch = ({ setOrderSelectedPackageSystem }) => {
  const setOrderList = useSetRecoilState(orderListState);
  const setOrderListCheckedList = useSetRecoilState(orderListCheckedListState);

  const [expanded, setExpanded] = useState(false);
  const [orderCw, setOrderCw] = useState("");
  const [orderSenCountry, setOrderSenCountry] = useState("");
  const [orderRecCountry, setOrderRecCountry] = useState("");
  const [orderReceiverName, setOrderReceiverName] = useState("");
  const [orderSupplierID, setOrderSupplierID] = useState("");
  const [orderParcelType, setOrderParcelType] = useState("");
  const [orderStartDate, setOrderStartDate] = useState(null);
  const [orderEndDate, setOrderEndDate] = useState(null);
  const [orderType, setOrderType] = useState("");

  const handleExpandClick = () => {
    setExpanded(!expanded);
  };

  const isClear =
    orderSenCountry.length === 0 &&
    orderRecCountry.length === 0 &&
    orderReceiverName.length === 0 &&
    orderSupplierID.length === 0 &&
    orderParcelType.length === 0 &&
    orderType.length === 0 &&
    orderStartDate === null &&
    orderEndDate === null &&
    orderCw.length === 0;

  const handleSubmit = (e) => {
    if (e) e.preventDefault();

    const allParams = {
      detailed: 1,
      cw: orderCw,
      supplier_id: orderSupplierID,
      sender_country: orderSenCountry,
      receiver_country: orderRecCountry,
      receiver_name: orderReceiverName,
      doc_type: orderType,
      from_date: orderStartDate && FormatDate(orderStartDate),
      to_date: orderEndDate && FormatDate(orderEndDate),
      parcel_type: Number(orderParcelType),
    };

    const params = Object.entries(allParams).reduce((acc, [key, value]) => {
      if (value) acc[key] = value;
      return acc;
    }, {});

    axios
      .get(`parcel/order`, { params })
      .then((response) => {
        setOrderListCheckedList([]);
        setOrderList(response.data.data);
      })
      .catch((error) => Errors(error));
  };

  const handleClear = async () => {
    setOrderCw("");
    setOrderSenCountry("");
    setOrderRecCountry("");
    setOrderReceiverName("");
    setOrderSupplierID("");
    setOrderParcelType("");
    setOrderStartDate(null);
    setOrderEndDate(null);
    setOrderType("");

    await axios
      .get(`parcel/order`)
      .then((response) => setOrderList(response.data.data))
      .catch((error) => Errors(error));
  };

  return (
    <Card className="order-detail-search">
      <CardContent className="order-detail-search-header">
        <div className="order-detail-search-select-all">
          <OrderSelectAll setOrderSelectedPackageSystem={setOrderSelectedPackageSystem} />
        </div>
        <div className="order-detail-search-button">
          <Button className="iyzi-button-blue" onClick={handleExpandClick}>
            {expanded ? "Detaylı Aramayı Kapat" : "Detaylı Aramayı Aç"}
          </Button>
        </div>
      </CardContent>
      <Collapse className="order-detail-search-collapse" in={expanded} timeout="auto" unmountOnExit>
        <CardContent className="order-detail-search-content">
          <form className="order-detail-search-form" onSubmit={handleSubmit}>
            <div className="order-detail-search-form-children">
              <OrderCWSearch orderCw={orderCw} setOrderCw={setOrderCw} />
              <OrderSenderCountrySearch
                orderSenCountry={orderSenCountry}
                setOrderSenCountry={setOrderSenCountry}
              />
              <OrderReceiverCountrySearch
                orderRecCountry={orderRecCountry}
                setOrderRecCountry={setOrderRecCountry}
              />
              <OrderReceiverNameSearch
                orderReceiverName={orderReceiverName}
                setOrderReceiverName={setOrderReceiverName}
              />
              <OrderTypeSearch orderType={orderType} setOrderType={setOrderType} />
              <OrderSupplierIDSearch
                orderSupplierID={orderSupplierID}
                setOrderSupplierID={setOrderSupplierID}
              />
              <OrderParcelType
                orderParcelType={orderParcelType}
                setOrderParcelType={setOrderParcelType}
              />
              <OrderBtwDateSearch
                orderStartDate={orderStartDate}
                setOrderStartDate={setOrderStartDate}
                orderEndDate={orderEndDate}
                setOrderEndDate={setOrderEndDate}
              />
            </div>
            <div className="order-detail-search-form-submit">
              {!isClear && (
                <Button onClick={handleClear} type="button" className="iyzi-button-blue">
                  Temizle
                </Button>
              )}
              <Button type="submit" className="iyzi-button-blue">
                Detaylı Ara
              </Button>
            </div>
          </form>
        </CardContent>
      </Collapse>
    </Card>
  );
};

export default OrderDetailSearch;
