import { useCallback, useEffect, useState } from "react";
import { CircularProgress, ThemeProvider, createTheme } from "@mui/material";
import DeleteOutlineOutlinedIcon from "@mui/icons-material/DeleteOutlineOutlined";
import axios from "../../../api/axios";
import { useRecoilState } from "recoil";
import {
  dimensionListArrayState,
  checkedDimensionIdState,
  updateDimensionModalState,
} from "../../../atoms/profileSettings";
import { toast } from "react-toastify";
import ModeEditOutlineOutlinedIcon from "@mui/icons-material/ModeEditOutlineOutlined";
import { Checkbox } from "@mui/material";
import { useCookies } from "react-cookie";
import Errors from "../../../utils/Errors";
import DimensionDeleteDialog from "./DimensionDeleteDialog";

const theme = createTheme({
  components: {
    MuiSelect: {
      styleOverrides: {
        outlined: {
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
        },
      },
    },
    MuiOutlinedInput: {
      styleOverrides: {
        root: {
          borderRadius: "10px",
        },
      },
    },
    MuiPaper: {
      styleOverrides: {
        root: {
          maxHeight: "300px !important",
        },
      },
    },
    MuiInputBase: {
      styleOverrides: {
        input: {
          display: "flex",
          alignItems: "center",
          gap: "var(--gap)",
        },
      },
    },
  },
});

const DimensionListItem = ({ dimension }) => {
  const [loading, setLoading] = useState(false);
  const [dimensionDeleteDialog, setDimensionDeleteDialog] = useState(false);

  const [, setUpdateDimensionModal] = useRecoilState(updateDimensionModalState);
  const [dimensionListArray, setDimensionListArray] = useRecoilState(
    dimensionListArrayState
  );
  const [checkedDimensionId, setCheckedDimensionId] = useRecoilState(
    checkedDimensionIdState
  );

  useEffect(() => {
    const itemm = dimensionListArray.filter((item) => item.is_default === "1");
    setCheckedDimensionId(itemm[0]?.id);
  }, [dimensionListArray, setCheckedDimensionId]);

  const [token] = useCookies(["jwt"]);
  const jwt = token?.jwt;

  const handleUpdateDimensionModal = () => {
    setUpdateDimensionModal({
      status: true,
      data: dimension,
    });
  };

  const handleDelete = useCallback(async () => {
    setLoading(true);
    await axios
      .post(`member-dimension/delete/${dimension?.id}`)
      .then((response) => {
        setDimensionListArray(
          dimensionListArray?.filter((item) => item !== dimension)
        );
        toast.success(response.data.message);
      })
      .catch((error) => Errors(error))
      .finally(() => {
        setLoading(false);
      });
  }, [dimension, setDimensionListArray, dimensionListArray]);

  const handleDeleteDialog = () => {
    setDimensionDeleteDialog(true);
  };

  const defaultDimensionChangeHandler = useCallback(async () => {
    setCheckedDimensionId(dimension.id);
    await axios
      .post(`member-dimension/set-default/${dimension.id}`, {
        headers: {
          Authorization: `Bearer ${jwt}`,
        },
      })
      .then((response) => {
        if (response.data.status === 200) {
          toast.success(response.data.message);
        }
      })
      .catch((error) => Errors(error));
  }, [dimension.id, setCheckedDimensionId, jwt]);

  return (
    <ThemeProvider theme={theme}>
      <DimensionDeleteDialog
        handleDelete={handleDelete}
        dimensionDeleteDialog={dimensionDeleteDialog}
        setDimensionDeleteDialog={setDimensionDeleteDialog}
      />
      <div key={dimension.id} className="dimensions-display">
        <div
          className="display-header"
          style={{
            display: "flex",
            justifyContent: "space-between",
            fontWeight: 700,
            background: "var(--pastelBlue)",
            borderTopLeftRadius: "10px",
            borderTopRightRadius: "10px",
          }}
        >
          <div>
            <Checkbox
              size="small"
              checked={checkedDimensionId === dimension.id}
              onChange={defaultDimensionChangeHandler}
              sx={{ padding: 0, marginRight: "5px" }}
            />
            {dimension.name !== null &&
              dimension.name !== undefined &&
              dimension.name !== "" &&
              dimension.name}
            {(dimension.name === null ||
              dimension.name === undefined ||
              dimension.name === "") &&
              `${dimension.width}x${dimension.length}x${dimension.height}x
            ${Number(dimension.gross).toFixed(0)}x${dimension.qty}`}
          </div>
          <div>
            {loading ? (
              <CircularProgress
                sx={{ width: "21px!important", height: "21px!important" }}
              />
            ) : (
              <div>
                <ModeEditOutlineOutlinedIcon
                  style={{
                    height: "1em",
                    cursor: "pointer",
                    marginRight: "5px",
                  }}
                  onClick={handleUpdateDimensionModal}
                />
                <DeleteOutlineOutlinedIcon
                  onClick={handleDeleteDialog}
                  sx={{
                    color: "var(--shipmentRed)",
                    cursor: "pointer",
                    ":hover": {
                      color: "var(--notRed)",
                      transition: "all 150ms linear",
                    },
                  }}
                />
              </div>
            )}
          </div>
        </div>
        <div
          style={{
            width: "100%",
            height: "1px",
            backgroundColor: "var(--inputBorderColor)",
          }}
        />
        <div
          className="display-content"
          style={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
          }}
        >
          <div>
            <div>
              {dimension.length}x{dimension.width}x{dimension.height}
            </div>
            <div>{Number(dimension.gross)} kg</div>
            <div>{dimension.qty} adet</div>
          </div>
        </div>
      </div>
    </ThemeProvider>
  );
};

export default DimensionListItem;
