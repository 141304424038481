import { Table, TableBody, TableContainer } from "@mui/material";
import ReceiverAddressTableHead from "./ReceiverAddressTableHead";
import { useRecoilValue } from "recoil";
import { profileReceiverAddressState } from "../../../../atoms/membership";
import ReceiverAddressTableBody from "./ReceiverAddressTableBody";

const ReceiverAddressTable = () => {
  const profileReceiverAddress = useRecoilValue(profileReceiverAddressState);

  return (
    <TableContainer>
      <Table>
        <ReceiverAddressTableHead />
        <TableBody>
          {profileReceiverAddress.map((address) => (
            <ReceiverAddressTableBody key={address.id} address={address} />
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
};

export default ReceiverAddressTable;
