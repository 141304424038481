import "../../style/profileSettings/GeneralInfo.css";
import { CircularProgress } from "@mui/material";
import { useCallback, useEffect, useState } from "react";
import { useInView, animated } from "@react-spring/web";
import TimerSmsModal from "./TimerSmsModal";
import axios from "../../api/axios";
import {
  bDayState,
  emailState,
  nameState,
  phoneState,
  surnameState,
  tckApproveState,
  tckState,
  verifiedEmailState,
  verifiedPhoneState,
  emailHandState,
  tckNoHandState,
  taxNoState,
  personalCompanyState,
  membershipDocumentsState,
  companyNameState,
  invoiceOptionState,
  taxOfficeState,
  memberPhotoState,
  profileCompanyTypeState,
  profileMemberTypeState,
  tckTypeState,
  mailPermissionState,
  smsPermissionState,
  phoneVerifiedState,
  memberStatusState,
} from "../../atoms/membership";
import { useRecoilState, useSetRecoilState } from "recoil";
import { useCookies } from "react-cookie";
import NotificationBar from "../sidebar/NotificationBar";
import { DynamicTitle } from "../../utils/DynamicTitle";
import ProfileNavBar from "./ProfileNavBar";
import WrapperStyle from "../../utils/WrapperStyle";
import Errors from "../../utils/Errors";
import { ToastContainer } from "react-toastify";
import ProfileInfo from "./generalInfo/ProfileInfo";
import MembershipInfo from "./generalInfo/MembershipInfo";
import CorporateDocuments from "./generalInfo/CorporateDocuments";
import { isDefaultBillAddressState } from "../../atoms/profileSettings";
import InvoiceAddress from "./generalInfo/InvoiceAddress";
import ProfileSettingsTitle from "./ProfileSettingsTitle";

const GeneralInfo = () => {
  DynamicTitle("Genel Bilgi");

  const [token] = useCookies(["jwt"]);
  const jwt = token.jwt;
  axios.defaults.headers.common["Authorization"] = `Bearer ${jwt}`;

  const userInfo = JSON.parse(localStorage.getItem("user"));
  const memberCompanyType = userInfo?.user?.user_member?.company_type;
  const isMainUser = userInfo?.user?.is_main_user;

  const [isDefaultBillAddress, setIsDefaultBillAddress] = useRecoilState(isDefaultBillAddressState);
  const [profileMemberType, setProfileMemberType] = useRecoilState(profileMemberTypeState);

  const setName = useSetRecoilState(nameState);
  const setSurname = useSetRecoilState(surnameState);
  const setBDay = useSetRecoilState(bDayState);
  const setEmail = useSetRecoilState(emailState);
  const setPhone = useSetRecoilState(phoneState);
  const setPhoneVerified = useSetRecoilState(phoneVerifiedState);
  const setVerifiedPhone = useSetRecoilState(verifiedPhoneState);
  const setTck = useSetRecoilState(tckState);
  const setTckType = useSetRecoilState(tckTypeState);
  const setTckApprove = useSetRecoilState(tckApproveState);
  const setVerifiedEmail = useSetRecoilState(verifiedEmailState);
  const setEmailHand = useSetRecoilState(emailHandState);
  const setTckNoHand = useSetRecoilState(tckNoHandState);
  const setTaxNo = useSetRecoilState(taxNoState);
  const setPersonalCompany = useSetRecoilState(personalCompanyState);
  const setCompanyName = useSetRecoilState(companyNameState);
  const setMemberShipDocuments = useSetRecoilState(membershipDocumentsState);
  const setInvoiceOption = useSetRecoilState(invoiceOptionState);
  const setTaxOffice = useSetRecoilState(taxOfficeState);
  const setSmsPermission = useSetRecoilState(smsPermissionState);
  const setMailPermission = useSetRecoilState(mailPermissionState);
  const setMemberPhoto = useSetRecoilState(memberPhotoState);
  const setProfileCompanyType = useSetRecoilState(profileCompanyTypeState);
  const setMemberStatus = useSetRecoilState(memberStatusState);

  const [loading, setLoading] = useState(false);
  // const [timer, setTimer] = useState(null);
  const [membershipCompanyType, setMembershipCompanyType] = useState(memberCompanyType);

  const [ref, springs] = useInView(
    () => ({
      from: {
        opacity: 0,
        y: 20,
      },
      to: {
        opacity: 1,
        y: 0,
      },
    }),
    {
      rootMargin: "-10% 0%",
    }
  );

  const getData = useCallback(async () => {
    setLoading(true);

    const [invoiceResponse, profileResponse] = await Promise.all([
      axios.get("member-address", { params: { address_type: 1, limit: 4 } }),
      axios.get(`profile`),
    ])
      .catch((error) => Errors(error))
      .finally(() => setLoading(false));

    setIsDefaultBillAddress(
      invoiceResponse.data.data?.find(
        (e) => Number(e.address_type) === 1 && Number(e.is_active) === 1
      )
    );

    setName(profileResponse.data.data.name);
    setSurname(profileResponse.data.data.surname);
    setBDay(profileResponse.data.data.birth_date);
    setEmail(profileResponse.data.data.email);
    setPhone(profileResponse.data.data.phone);
    setPhoneVerified(profileResponse.data.data.phone_verified);
    setVerifiedPhone(profileResponse.data.data.phone);
    setTck(profileResponse.data.data.user_member.identity_no);
    setTckType(profileResponse.data.data.user_member.identity_type);
    setTckApprove(profileResponse.data.data.user_member.identity_verified);
    setVerifiedEmail(profileResponse.data.data.email_verified);
    setEmailHand(profileResponse.data.data.email);
    setTckNoHand(profileResponse.data.data.identity_no);
    setLoading(false);
    setTaxNo(profileResponse.data.data.user_member?.tax_no);
    setProfileCompanyType(profileResponse.data.data?.user_member?.company_type);
    setProfileMemberType(profileResponse.data.data.user_member.member_type);
    setPersonalCompany(
      profileResponse.data.data?.user_member?.company_type === "Individual" ? true : false
    );
    setMemberShipDocuments(profileResponse.data.data?.user_member?.get_member_documents);
    setCompanyName(profileResponse.data.data?.user_member?.name);
    setInvoiceOption(profileResponse.data.data?.user_member?.invoice_type);
    setMemberPhoto(profileResponse.data.data?.photo);
    setMemberStatus(profileResponse.data.data?.user_member?.status);

    const sms = profileResponse.data.data.send_sms_approve === "1" ? true : false;
    const mail = profileResponse.data.data.send_mail_approve === "1" ? true : false;

    setSmsPermission(sms);
    setMailPermission(mail);

    if (profileResponse.data.data?.user_member?.tax_office) {
      const data = profileResponse.data.data?.user_member?.tax_office;
      const result = parseInt(data, 10).toString(); // Başındaki sıfırları atmak için önce sayıya çeviriyoruz sonrasında tekrar stringe çeviriyoruz.

      setTaxOffice(result);
    }

    // LocalStorage'dan 'user' verisini al
    const userStr = localStorage.getItem("user");

    // Eğer 'user' verisi varsa, onu bir nesneye dönüştür
    if (userStr) {
      const userObj = JSON.parse(userStr);

      // 'user' objesini güncelle
      userObj.user = profileResponse.data.data;

      // Güncellenmiş nesneyi tekrar LocalStorage'a kaydet
      localStorage.setItem("user", JSON.stringify(userObj));
    }
  }, [
    setIsDefaultBillAddress,
    setVerifiedEmail,
    setName,
    setSurname,
    setBDay,
    setEmail,
    setPhone,
    setPhoneVerified,
    setVerifiedPhone,
    setTck,
    setTckType,
    setTckApprove,
    setTckNoHand,
    setEmailHand,
    setInvoiceOption,
    setCompanyName,
    setMemberShipDocuments,
    setPersonalCompany,
    setTaxNo,
    setTaxOffice,
    setMemberPhoto,
    setProfileCompanyType,
    setProfileMemberType,
    setSmsPermission,
    setMailPermission,
    setMemberStatus,
  ]);

  useEffect(() => {
    getData();
  }, [getData]);

  return (
    <div className="general-info-wrapper">
      <NotificationBar />
      <ProfileSettingsTitle />
      <ProfileNavBar />
      <TimerSmsModal />
      <ToastContainer />
      <div className="general-info-container" style={WrapperStyle()}>
        <animated.div ref={ref} style={springs}>
          {loading ? (
            <div
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                height: "200px",
              }}
            >
              <CircularProgress />
            </div>
          ) : (
            <div className="general-infos">
              <ProfileInfo />
              <MembershipInfo />
              {profileMemberType === "Company" && isMainUser && (
                <CorporateDocuments
                  membershipCompanyType={membershipCompanyType}
                  setMembershipCompanyType={setMembershipCompanyType}
                />
              )}
              {!isDefaultBillAddress && <InvoiceAddress />}
            </div>
          )}
        </animated.div>
      </div>
    </div>
  );
};

export default GeneralInfo;
