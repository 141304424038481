import { useNavigate } from "react-router-dom";
import { useRecoilValue, useSetRecoilState } from "recoil";
import {
  basicSearchState,
  myShipmentListLoadingState,
  myShipmentListState,
  myShipmentsListLimitState,
  shipmentListOrderDirectionState,
  totalPagesState,
} from "../atoms/myShipments";
import { useCallback, useState } from "react";
import axios from "../api/axios";
import Errors from "./Errors";
import ContentCopyIcon from "@mui/icons-material/ContentCopy";
import { Button, IconButton, Tooltip } from "@mui/material";

const NavigateParcel = ({ parcel, status, fontSize }) => {
  const navigate = useNavigate();

  const returnStatusNumbers = 2.22 || 2.23 || 2.24 || 2.25 || 2.26 || 2.27 || 2.28 || 2.29;

  const [copied, setCopied] = useState(false);

  //* Gönderilerim sayfasının global stateleri
  const setBasicSearch = useSetRecoilState(basicSearchState);
  const setMyShipmentList = useSetRecoilState(myShipmentListState);
  const setMyShipmentListLoading = useSetRecoilState(myShipmentListLoadingState);
  const setTotalPages = useSetRecoilState(totalPagesState);

  const shipmentOrderDirectionPage = useRecoilValue(shipmentListOrderDirectionState);
  const myShipmentsListLimit = useRecoilValue(myShipmentsListLimitState);

  const copyToClipboard = (text) => {
    navigator.clipboard
      .writeText(text)
      .then(() => setCopied(true))
      .catch((err) => console.error("Panoya kopyalama başarısız:", err))
      .finally(() => setTimeout(() => setCopied(false), 1000));
  };

  const handleNavigateShipment = useCallback(async () => {
    if (status === returnStatusNumbers) {
      alert("Daha iadeler sayfası bitmedi");
    } else {
      setBasicSearch(parcel);
      await axios
        .get(
          `parcel?order_direction=${shipmentOrderDirectionPage}&limit=${myShipmentsListLimit}&page=${1}&search=${parcel}`
        )
        .then((response) => {
          setMyShipmentList(response.data.data);
          setTotalPages(response.data.totalPages);
          navigate("/my-shipment");
        })
        .catch((error) => Errors(error))
        .finally(() => {
          setMyShipmentListLoading(false);
        });
    }
  }, [
    parcel,
    status,
    shipmentOrderDirectionPage,
    myShipmentsListLimit,
    returnStatusNumbers,
    setBasicSearch,
    navigate,
    setMyShipmentList,
    setMyShipmentListLoading,
    setTotalPages,
  ]);

  return (
    <div style={{ display: "flex", alignItems: "center", justifyContent: "center", gap: "3px" }}>
      <Tooltip title={copied ? "Panoya kopyalandı!" : "Kopyalamak için tıklayın!"}>
        <IconButton onClick={() => copyToClipboard(parcel)}>
          <ContentCopyIcon />
        </IconButton>
      </Tooltip>
      <Button
        style={{
          textTransform: "none",
          fontSize: fontSize ? fontSize : "inherit",
          padding: 0,
          color: "var(--btnBgBlue)",
          ":hover": { backgroundColor: "transparent" },
        }}
        onClick={handleNavigateShipment}
      >
        {parcel}
      </Button>
    </div>
  );
};

export default NavigateParcel;
