import { orderListCheckedListState } from "../../atoms/orders";
import { useRecoilValue } from "recoil";
import OrderBasketTotal from "./orderBasket/OrderBasketTotal";
import OrderBasketSystem from "./orderBasket/OrderBasketSystem";

const OrderBasket = (props) => {
  const {
    exchangeRates,
    orderSelectedPackageSystem,
    setOrderSelectedPackageSystem,
    freeShippingLimit,
    domesticPrice,
    orderSelectedSupplier,
  } = props;

  const orderListCheckedList = useRecoilValue(orderListCheckedListState);
  const isMultipleOrder = orderListCheckedList.length > 1;

  const totalRemainingPayment = orderListCheckedList.reduce((total, item) => {
    const payment = parseFloat(item.remaining_payment) || 0;
    const currency = item.currency;
    const exchangeRate = exchangeRates[currency] || 1; // Eğer döviz kuru bulunamazsa, varsayılan olarak 1 kullanılır.

    return total + payment * exchangeRate;
  }, 0);

  const isFree = !orderSelectedSupplier.courier_cost;

  const basketDomesticPrice =
    orderListCheckedList.length > 0
      ? isFree
        ? 0
        : orderSelectedPackageSystem === "S"
        ? orderListCheckedList.length * domesticPrice
        : domesticPrice
      : 0;

  return (
    <div className="order-basket">
      <div className="order-basket-wrapper">
        <OrderBasketSystem
          setOrderSelectedPackageSystem={setOrderSelectedPackageSystem}
          orderSelectedPackageSystem={orderSelectedPackageSystem}
          isMultipleOrder={isMultipleOrder}
        />
        <OrderBasketTotal
          totalRemainingPayment={totalRemainingPayment}
          basketDomesticPrice={basketDomesticPrice}
          freeShippingLimit={freeShippingLimit}
          orderSelectedPackageSystem={orderSelectedPackageSystem}
          orderSelectedSupplier={orderSelectedSupplier}
        />
      </div>
    </div>
  );
};

export default OrderBasket;
