import { Box, Pagination } from "@mui/material";
import { useCallback } from "react";
import { useEffect, useState } from "react";
import { useRecoilState } from "recoil";
import axios from "../../../api/axios";
import {
  integrationsState,
  iossPageState,
  iossTotalPageState,
} from "../../../atoms/membership";
import miniLogo from "../../../img/logos/logo_white_mini_loading.svg";
import IossNumberListItem from "./IossNumberListItem";
import Errors from "../../../utils/Errors";

const IossNumberList = () => {
  const [loading, setLoading] = useState(true);
  const [integrations, setIntegrations] = useRecoilState(integrationsState);
  const [iossPage, setIossPage] = useRecoilState(iossPageState);
  const [iossTotalPage, setIossTotalPage] = useRecoilState(iossTotalPageState);

  const getIossList = useCallback(async () => {
    axios
      .get(`member-ioss-number?limit=${Number(10)}`)
      .then((response) => {
        setIntegrations(response.data.data);
        setLoading(false);
        setIossPage(1);
        setIossTotalPage(response.data.totalPages);
      })
      .catch((error) => {
        Errors(error);
        setLoading(false);
      });
  }, [setIntegrations, setIossPage, setIossTotalPage]);

  const handlePageChange = useCallback(
    (e, pageValue) => {
      setLoading(true);
      axios
        .get(`member-ioss-number?limit=${Number(10)}&page=${Number(pageValue)}`)
        .then((response) => {
          setIntegrations(response.data.data);
          setLoading(false);
          setIossTotalPage(response.data.totalPages);
          setIossPage(pageValue);
        })
        .catch((error) => {
          Errors(error);
          setLoading(false);
        });
    },
    [setIntegrations, setIossTotalPage, setIossPage]
  );

  useEffect(() => {
    getIossList();
  }, [getIossList]);

  return (
    <Box className="address-list">
      <Box
        sx={
          loading
            ? { marginTop: "20px", display: "flex", justifyContent: "center" }
            : {
                marginTop: "20px",
                display: "grid",
                gridTemplateColumns: "repeat(auto-fill, minmax(228px, 1fr))",
                gap: "var(--gap)",
              }
        }
      >
        {loading ? (
          <div
            style={{
              width: "100%",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              minHeight: "200px",
            }}
          >
            <img className="loading-logo" src={miniLogo} alt="" />
          </div>
        ) : (
          <>
            {integrations.length === 0 &&
              "Kayıtlı IOSS/VAT numarası bulunmamaktadır."}
            {integrations
              .filter((integration) => integration.status === "2")
              .map((integration) => (
                <div
                  key={integration.id}
                  style={{
                    maxWidth: "230px",
                    width: "100%",
                    borderRadius: "var(--inputBorderRadius)",
                    border: "1px solid var(--inputBorderColor)",
                  }}
                >
                  <IossNumberListItem integration={integration} />
                </div>
              ))}
            {integrations
              .filter((integration) => integration.status === "1")
              .map((integration) => (
                <div
                  key={integration.id}
                  style={{
                    width: "100%",
                    borderRadius: "var(--inputBorderRadius)",
                    border: "1px solid var(--inputBorderColor)",
                  }}
                >
                  <IossNumberListItem integration={integration} />
                </div>
              ))}
          </>
        )}
      </Box>
      {!loading && integrations.length !== 0 && (
        <Pagination
          showFirstButton
          showLastButton
          size="small"
          count={iossTotalPage}
          page={iossPage}
          onChange={handlePageChange}
        />
      )}
    </Box>
  );
};

export default IossNumberList;
