import LocalShippingOutlinedIcon from "@mui/icons-material/LocalShippingOutlined";

const YurtIciInfo = () => {
  return (
    <div className="info-item">
      <h2>
        <div
          style={{
            fontSize: "inherit",
            color: "inherit",
            display: "flex",
            flexWrap: "wrap",
            gap: "5px",
            alignItems: "center",
          }}
        >
          <LocalShippingOutlinedIcon /> Anlaşmalı Yurt İçi Kargo Bilgileri
        </div>
      </h2>
      <div className="cargo">
        <div className="cargo-item">
          <img src="/images/info/aras-info.png" alt="Aras Info" />
          <div className="cargo-item-text">
            <b>Aras Kargo</b> : 2206354550828
          </div>
        </div>
        <div className="cargo-item">
          <img src="/images/info/surat-info.png" alt="Sürat Kargo" />
          <div className="cargo-item-text">
            <b>Sürat Kargo</b> : 1316232654
          </div>
        </div>
        <div className="cargo-item">
          <img width={150} height={106} src="/images/info/mng-kargo.svg" alt="MNG Kargo" />
          <div className="cargo-item-text">
            <b>MNG Kargo</b> : 841699904
          </div>
        </div>
      </div>
    </div>
  );
};

export default YurtIciInfo;
