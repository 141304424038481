import { Link } from "react-router-dom";

function Partner() {
  const data = [
    {
      url: "/images/home/integrations/fedex-wb.png",
      alt: "fedex",
    },
    {
      url: "/images/home/integrations/dhl-wb.png",
      alt: "dhl",
    },
    {
      url: "/images/home/integrations/ups-wb.png",
      alt: "ups",
    },
    {
      url: "/images/home/integrations/usps-wb.png",
      alt: "usdpostyservis",
    },
    {
      url: "/images/home/integrations/royal-mail-wb.png",
      alt: "royalmail",
    },
    {
      url: "/images/home/integrations/tnt-wb.png",
      alt: "tnt",
    },
    {
      url: "/images/home/integrations/gls-wb.png",
      alt: "gls",
    },
    {
      url: "/images/home/integrations/dpd-wb.png",
      alt: "dpd",
    },
  ];
  return (
    <section className="main-integration main-info">
      <div className="integration-wrapper">
        <article>
          <h2>Çözüm Ortaklarımız</h2>
          <p>
            Yurt dışı kargolarınızı, dünyanın en prestijli uluslararası kargo
            firmaları ile göndermenin ayrıcalığını yaşayın.
          </p>
          <div className="integration-container">
            {data.map((partner, key) => (
              <img
                key={key}
                className="integration"
                src={partner.url}
                alt={partner.alt}
              />
            ))}
          </div>
        </article>
      </div>
      {/* <div className="info-container">
        <div className="info-image">
          <img
            src="/images/home/how/team-persons.svg"
            alt="Teklifleri karşılaştırın, zamandan tasarruf edin"
            loading="lazy"
          />
        </div>
        <div className="info">
          <h2>iyziTeam</h2>
          <p>
            Her bütçeye uygun, hızlı ve güvenilir çözümlerimizle yurt dışı kargo
            sürecinizi kolaylaştırıyoruz. Hemen kayıt olun ve iyziShip farkını
            her gönderinizde hissedin!
          </p>
          <div className="info-buttons">
            <Link
              to={"/login"}
              style={{
                padding: "10px 30px",
                minWidth: "200px",
                fontWeight: 700,
                textDecoration: "none",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
              className="iyzi-button-sea"
            >
              Hakkımızda
            </Link>
            <Link
              to={"/nasil-calisir"}
              style={{
                padding: "10px 30px",
                minWidth: "200px",
                fontWeight: 700,
                textDecoration: "none",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
              className="iyzi-button-bg"
            >
              Hemen Başvur
            </Link>
          </div>
        </div>
      </div> */}
    </section>
  );
}

export default Partner;
