import "../../../style/dropzone/Dropzone.css";
import { Box } from "@mui/system";
import { Button } from "@mui/material";
import { useDropzone } from "react-dropzone";
import uploadIcon from "../../../img/icons/upload_icon.svg";
import { useRecoilValue, useSetRecoilState } from "recoil";
import { selectedFilesState } from "../../../atoms/selectedFiles";
import { useState } from "react";
import DowloadPdfView from "../../dowloadPdfView/DowloadPdfView";
import {
  membershipDocumentsState,
  personalCompanyState,
} from "../../../atoms/membership";
import axios from "../../../api/axios";
import { toast } from "react-toastify";
import Errors from "../../../utils/Errors";
import CircularProgress from "@mui/material/CircularProgress";
import CloudUploadOutlinedIcon from "@mui/icons-material/CloudUploadOutlined";
import { saveUser } from "../../../utils/User";

const Dropzone = () => {
  const user = JSON.parse(localStorage.getItem("user"));

  const membershipDocuments = useRecoilValue(membershipDocumentsState);
  const personalCompany = useRecoilValue(personalCompanyState);

  const [uploadLoading, setUploadLoading] = useState(false);

  const setSelectedFiles = useSetRecoilState(selectedFilesState);

  const [sendFiles, setSendFiles] = useState([]);

  const handleDrop = (acceptedFiles) => {
    const newFiles = acceptedFiles.map((file) => ({
      file,
      id: Math.random().toString(),
    }));
    setSendFiles((prevFiles) => [...prevFiles, ...newFiles]);
    setSelectedFiles((prevFiles) => [...prevFiles, ...newFiles]);
  };

  const handleSetState = (id) => {
    setSendFiles((prevFiles) => prevFiles.filter((file) => file.id !== id));
    setSelectedFiles((prevFiles) => prevFiles.filter((file) => file.id !== id));
  };

  const { getRootProps, getInputProps } = useDropzone({
    onDrop: handleDrop,
    multiple: true,
  });

  const isDisabled = () => {
    const requiredLength = personalCompany ? 2 : 5;
    return (
      membershipDocuments?.length === requiredLength &&
      membershipDocuments.filter((e) => Number(e.status) === 2).length ===
        requiredLength
    );
  };

  const uploadSelected = () => {
    let formData = new FormData();
    Array.from(sendFiles).forEach((file, index) => {
      formData.append(`files[${index}]`, file.file); //file -> file.file
    });
    formData.append("parent_id", user?.user?.member_id);
    formData.append("parent_type", "Member");

    axios
      .post(`document/add`, formData)
      .then((response) => {
        toast.success(response.data.message);
        saveUser(response);
        // setTimeout(() => {
        //   window.location.reload(true);
        // }, 1000);
      })
      .catch((error) => Errors(error));
  };

  return (
    <Box
      className="dropzone-wrapper"
      sx={{
        userSelect: isDisabled() ? "none" : "auto",
        pointerEvents: isDisabled() ? "none" : "auto",
        opacity: isDisabled() ? 0.2 : 1,
      }}
    >
      <Box
        className="dropzone"
        sx={{
          ":hover": {
            backgroundColor: isDisabled()
              ? "var(--lighterBg) !important"
              : "var(--pastelBlue) !important",
          },
        }}
      >
        <div
          className="dropzone-area"
          {...getRootProps()}
          style={{
            userSelect: isDisabled() ? "none" : "auto",
            pointerEvents: isDisabled() ? "none" : "auto",
          }}
        >
          <input
            disabled={isDisabled()}
            style={{
              userSelect: isDisabled() ? "none" : "auto",
              pointerEvents: isDisabled() ? "none" : "auto",
            }}
            {...getInputProps()}
          />
          <div className="dropzone-area-text">
            <img src={uploadIcon} alt="Upload Icon" />
            <span className="dropzone-area-text-headline">
              Dosyayı sürükleyip bırakın veya{" "}
              <span style={{ color: "var(--info)" }}>Gözat</span>
            </span>
            <span className="dropzone-area-text-sub">
              Desteklenen Formatlar: JPEG, PNG, PDF
            </span>
          </div>
        </div>
      </Box>
      <DowloadPdfView sendFiles={sendFiles} handleSetState={handleSetState} />
      {sendFiles &&
        (uploadLoading ? (
          <Box
            sx={{
              width: "40px",
              height: "40px",
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            <CircularProgress
              sx={{ width: "24px !important", height: "24px !important" }}
            />
          </Box>
        ) : (
          <Button
            onClick={uploadSelected}
            type="button"
            className="detail-chat-send-message-button"
            disabled={isDisabled()}
          >
            <CloudUploadOutlinedIcon sx={{ color: "var(--btnBgBlue)" }} />
          </Button>
        ))}
    </Box>
  );
};

export default Dropzone;
