import { TextField } from "@mui/material";

const ReceiptIDSearch = ({ receiptID, setReceiptID }) => {
  return (
    <TextField
      size="small"
      label="Sipariş No"
      value={receiptID}
      autoComplete="off"
      onChange={(e) => setReceiptID(e.target.value)}
    />
  );
};

export default ReceiptIDSearch;
