import { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import "./../../style/getStart/GetStart.css";
import { CSSTransition, TransitionGroup } from "react-transition-group";
import MainButton from "../../img/v2_logos/MainDown.svg";
import { Button } from "@mui/material";
const GetStart = () => {
  const texts = ["Yurt dışına kargo göndermenin", "Yurt dışına numune göndermenin", "E-ihracatın"];
  const [index, setIndex] = useState(0);
  const [showText, setShowText] = useState(false);

  useEffect(() => {
    setShowText(true);
    const interval = setInterval(() => {
      setShowText(false);
      setTimeout(() => {
        setIndex((prevIndex) => (prevIndex + 1) % texts.length);
        setShowText(true);
      }, 500); // Geçiş süresi ile eşleşen bir süre
    }, 2000);

    return () => {
      clearInterval(interval);
    };
  }, [texts.length]);
  const scrollStep = 10; // Her adımda kaydırılacak piksel sayısı
  const scrollDelay = 10; // Her adım arasındaki gecikme süresi (milisaniye cinsinden)

  const handleAutoScroll = () => {
    let currentPosition = 0;

    const scrollInterval = setInterval(() => {
      if (currentPosition < 800) {
        window.scrollBy(0, scrollStep);
        currentPosition += scrollStep;
      } else {
        clearInterval(scrollInterval);
      }
    }, scrollDelay);
  };

  return (
    <section className="main-header get-start">
      <div className="field">
        <div className="main-header-text get-start-text">
          <article>
            <h1>
              <TransitionGroup style={{ overflow: "hidden" }} component={null}>
                {showText && (
                  <CSSTransition key={texts[index]} timeout={500} classNames="fade">
                    <span className="dynamic-text">{texts[index]}</span>
                  </CSSTransition>
                )}
              </TransitionGroup>
              <span className="constant-text">en kolay yolu!</span>
            </h1>
            <p>
              Satış kanallarınızı ücretsiz entegre edin, teklifleri kolayca karşılaştırın ve yurt
              dışı kargolarınızı bütçenize en uygun seçenekler ile sorunsuz bir şekilde gönderin.
              iyziShip'in yenilikçi lojistik çözümleriyle, sınırları aşan bir e-ihracat deneyimine
              hazır olun!
            </p>
          </article>
          <div className="main-header-text-buttons">
            <Link
              style={{
                padding: " 9px 14px",
                minWidth: "200px",
                fontWeight: 700,
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                textDecoration: "none",
                height: " 49.927px",
                gap: "10px",
                fontSize: "14px",
                lineHeight: "18px",
              }}
              to={"/register"}
              className="iyzi-button-blue"
            >
              Ücretsiz Deneyin
            </Link>
            <Link
              style={{
                padding: "10px 30px",
                minWidth: "200px",
                fontWeight: 700,
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                textDecoration: "none",
                height: " 49.927px",
                gap: "10px",
                fontSize: "14px",
                lineHeight: "18px",
              }}
              to={"/fiyatlandirma"}
              className="iyzi-button-bg"
            >
              Hemen Fiyat Alın
            </Link>
          </div>
        </div>
        <div className="main-header-img">
          <img
            src="/images/home/main-header-img.svg"
            alt="Pazaryeri Entegrasyonları"
            loading="lazy"
          />
        </div>
      </div>
      <div className="down-btn">
        <Button
          onClick={handleAutoScroll}
          sx={{
            marginTop: "-70px",
            marginBottom: "30px",
            "&:hover": {
              backgroundColor: "transparent", // Hover durumunda arka plan rengini iptal et
            },
          }}
        >
          <img src={MainButton} alt="button" />
        </Button>
      </div>
    </section>
  );
};

export default GetStart;
