import { ThemeProvider } from "@emotion/react";
import {
  CircularProgress,
  IconButton,
  InputAdornment,
  TextField,
  createTheme,
} from "@mui/material";
import SearchIcon from "@mui/icons-material/Search";
import { useCallback, useState } from "react";
import { useSetRecoilState } from "recoil";
import {
  checkedReceiverAddressIdState,
  checkedSenderAddressIdState,
  profileReceiverAddressState,
  profileSenderAddressState,
} from "../../../atoms/membership";
import axios from "../../../api/axios";
import { toast } from "react-toastify";
import Errors from "../../../utils/Errors";

const theme = createTheme({
  components: {
    MuiOutlinedInput: {
      styleOverrides: {
        root: {
          borderRadius: "10px",
          gap: "10px",
        },
      },
    },
  },
});

const AddressSearch = ({ type }) => {
  const [search, setSearch] = useState("");
  const [loading, setLoading] = useState(false);

  const setProfileSenderAddress = useSetRecoilState(profileSenderAddressState);
  const setProfileReceiverAddress = useSetRecoilState(profileReceiverAddressState);
  const setCheckedSenderAddressId = useSetRecoilState(checkedSenderAddressIdState);
  const setCheckedReceiverAddressId = useSetRecoilState(checkedReceiverAddressIdState);

  const handleSearch = useCallback(async () => {
    setLoading(true);
    axios
      .get("member-address", {
        params: {
          search,
          address_type: type,
          limit: type === 4 || type === 5 ? 20 : 4,
        },
      })
      .then((response) => {
        if (type === 4) {
          setProfileSenderAddress(response.data.data);
          setCheckedSenderAddressId(response.data.data.find((e) => e.is_default === "1")?.id);
        } else if (type === 5) {
          setProfileReceiverAddress(response.data.data);
          setCheckedReceiverAddressId(response.data.data.find((e) => e.is_default === "1")?.id);
        }
        toast.success(response.data.message);
      })
      .catch((error) => {
        Errors(error);
      })
      .finally(() => setLoading(false));
  }, [
    setProfileReceiverAddress,
    setProfileSenderAddress,
    setCheckedSenderAddressId,
    setCheckedReceiverAddressId,
    type,
    search,
  ]);

  const handleClear = useCallback(async () => {
    setLoading(true);
    setSearch("");

    await axios
      .get("member-address", {
        params: {
          search: "",
          address_type: type,
          limit: type === 4 || type === 5 ? 20 : 4,
        },
      })
      .then((response) => {
        if (type === 4) {
          setProfileSenderAddress(response.data.data);
          setCheckedSenderAddressId(response.data.data.find((e) => e.is_default === "1")?.id);
        } else if (type === 5) {
          setProfileReceiverAddress(response.data.data);
          setCheckedReceiverAddressId(response.data.data.find((e) => e.is_default === "1")?.id);
        }
        toast.success(response.data.message);
      })
      .catch((error) => {
        Errors(error);
      })
      .finally(() => setLoading(false));
  }, [
    setProfileReceiverAddress,
    setProfileSenderAddress,
    setCheckedSenderAddressId,
    setCheckedReceiverAddressId,
    type,
  ]);
  return (
    <ThemeProvider theme={theme}>
      <TextField
        autoComplete="off"
        value={search}
        onChange={(e) => setSearch(e.target.value)}
        label="Adres Ara"
        placeholder="Ülke Kodu (TR, US vb.), İsim Soyisim, Adres Başlığı, Şirket, Şehir, İlçe, Semt"
        onKeyDown={(e) =>
          e.key === "Enter" ? handleSearch() : e.key === "Escape" ? handleClear() : null
        }
        InputProps={{
          startAdornment: (
            <InputAdornment position="start">
              {loading ? (
                <div
                  style={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    width: "37px",
                    height: "37px",
                  }}
                >
                  <CircularProgress sx={{ width: "21px!important", height: "21px!important" }} />
                </div>
              ) : (
                <IconButton onClick={handleSearch} edge="end">
                  <SearchIcon />
                </IconButton>
              )}
            </InputAdornment>
          ),
        }}
      />
    </ThemeProvider>
  );
};

export default AddressSearch;
