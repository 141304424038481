import "../../style/profileSettings/AddressInfo.css";
import { CircularProgress } from "@mui/material";
import NewAddress from "./addressInfo/NewAddress";
import BusinessOutlinedIcon from "@mui/icons-material/BusinessOutlined";
import { useInView, animated } from "@react-spring/web";
import React, { useCallback, useEffect } from "react";
import axios from "../../api/axios";
import { useCookies } from "react-cookie";
import { useRecoilState, useSetRecoilState } from "recoil";
import {
  isDefaultBillAddressState,
  isDefaultCollectionAddressState,
} from "../../atoms/profileSettings";
import { DynamicTitle } from "../../utils/DynamicTitle";
import NotificationBar from "../sidebar/NotificationBar";
import ProfileNavBar from "./ProfileNavBar";
import {
  addressLoadingState,
  checkedReceiverAddressIdState,
  checkedSenderAddressIdState,
  profileBookingAddressState,
  profileReceiverAddressState,
  profileSenderAddressState,
  receiverCurrPageState,
  receiverTotalPageState,
  senderCurrPageState,
  senderTotalPageState,
} from "../../atoms/membership";
import { ToastContainer } from "react-toastify";
import WrapperStyle from "../../utils/WrapperStyle";
import ProfileSettingsStyle from "../../style/styleJs/ProfileSettingsStyle";
import ProfileSettingsTitle from "./ProfileSettingsTitle";

const AddressInfo = () => {
  DynamicTitle("Adres Bilgileri");

  const [token] = useCookies(["jwt"]);
  const jwt = token.jwt;
  axios.defaults.headers.common["Authorization"] = `Bearer ${jwt}`;

  const [addressLoading, setAddressLoading] = useRecoilState(addressLoadingState);

  const setIsDefaultBillAddress = useSetRecoilState(isDefaultBillAddressState);
  const setIsDefaultCollectionAddress = useSetRecoilState(isDefaultCollectionAddressState);

  const setProfileSenderAddress = useSetRecoilState(profileSenderAddressState);
  const setProfileReceiverAddress = useSetRecoilState(profileReceiverAddressState);
  const setProfileBookingAddress = useSetRecoilState(profileBookingAddressState);

  const setCheckedSenderAddressId = useSetRecoilState(checkedSenderAddressIdState);
  const setCheckedReceiverAddressId = useSetRecoilState(checkedReceiverAddressIdState);

  const setSenderCurrPage = useSetRecoilState(senderCurrPageState);
  const setReceiverCurrPage = useSetRecoilState(receiverCurrPageState);
  const setSenderTotalPage = useSetRecoilState(senderTotalPageState);
  const setReceiverTotalPage = useSetRecoilState(receiverTotalPageState);

  const [ref, springs] = useInView(
    () => ({
      from: {
        opacity: 0,
        y: 20,
      },
      to: {
        opacity: 1,
        y: 0,
      },
    }),
    {
      rootMargin: "-10% 0%",
    }
  );

  const getAddresses = useCallback(async () => {
    setAddressLoading(true);

    const [defaultResponse, bookingResponse, senderResponse, receiverResponse] = await Promise.all([
      axios.get("member-address", { params: { address_type: 1, limit: 4 } }),
      axios.get("member-address", { params: { address_type: 2, limit: 4 } }),
      axios.get("member-address", { params: { address_type: 4, limit: 20 } }),
      axios.get("member-address", { params: { address_type: 5, limit: 20 } }),
    ]).finally(() => {
      setAddressLoading(false);
    });

    setIsDefaultBillAddress(
      defaultResponse.data.data?.find(
        (e) => Number(e.address_type) === 1 && Number(e.is_active) === 1
      )
    );
    setIsDefaultCollectionAddress(
      bookingResponse.data.data?.find(
        (e) => Number(e.address_type) === 2 && Number(e.is_active) === 1
      )
    );

    setProfileSenderAddress(senderResponse.data.data);
    setCheckedSenderAddressId(senderResponse.data.data.find((e) => e.is_default === "1")?.id);
    setSenderCurrPage(senderResponse.data.currentPage);
    setSenderTotalPage(senderResponse.data.totalPages);

    setProfileReceiverAddress(receiverResponse.data.data);
    setCheckedReceiverAddressId(receiverResponse.data.data.find((e) => e.is_default === "1")?.id);
    setReceiverCurrPage(receiverResponse.data.currentPage);
    setReceiverTotalPage(receiverResponse.data.totalPages);

    setProfileBookingAddress(bookingResponse.data.data);
  }, [
    setProfileSenderAddress,
    setCheckedSenderAddressId,
    setProfileReceiverAddress,
    setCheckedReceiverAddressId,
    setIsDefaultBillAddress,
    setAddressLoading,
    setProfileBookingAddress,
    setReceiverCurrPage,
    setReceiverTotalPage,
    setSenderCurrPage,
    setSenderTotalPage,
    setIsDefaultCollectionAddress,
  ]);

  useEffect(() => {
    getAddresses();
  }, [getAddresses]);

  return (
    <div className="address-info">
      <NotificationBar />
      <ProfileSettingsTitle />
      <ProfileNavBar />
      <ToastContainer />
      <div style={WrapperStyle()}>
        <animated.div ref={ref} style={springs}>
          <div style={ProfileSettingsStyle()}>
            <div className="profile-card-wrapper">
              <div className="profile-card-header">
                <BusinessOutlinedIcon
                  sx={{
                    width: "20px",
                    height: "20px",
                    color: "var(--priceText)",
                  }}
                />
                <span>Adres Bilgileri</span>
              </div>
            </div>
            {addressLoading ? (
              <div style={{ width: "100%", display: "flex" }}>
                <CircularProgress sx={{ margin: "15vh auto" }} />
              </div>
            ) : (
              <NewAddress />
            )}
          </div>
        </animated.div>
      </div>
    </div>
  );
};

export default AddressInfo;
