import { toast } from "react-toastify";
import axios from "../api/axios";

const RefreshToken = async (jwt, navigate, setCookies) => {
  return axios
    .post("refresh", {
      api_token: jwt,
    })
    .then((response) => {
      setCookies("jwt", response.data.access_token, { path: "/" });
      toast.success("Oturum süreniz dolmuştur, lütfen sayfayı yenileyiniz!", { autoClose: 10000 });
    })
    .catch(() => {
      axios
        .post("logout")
        .then(() => {
          localStorage.removeItem("user");
          document.cookie = `jwt=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/;`;
          navigate("/login");
        })
        .catch(() => {
          localStorage.removeItem("user");
          document.cookie = `jwt=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/;`;
          navigate("/login");
        });
    });
};

export default RefreshToken;
