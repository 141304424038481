import "../../../style/myShipments/ConnectedPayments.css";
import {
  Box,
  CircularProgress,
  createTheme,
  Modal,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  ThemeProvider,
} from "@mui/material";
import { useRecoilState, useRecoilValue } from "recoil";
import {
  connectedPaymentsOpenState,
  selectedShipmentForMyShipmentsState,
} from "../../../atoms/myShipments";
import RemoveDoneOutlinedIcon from "@mui/icons-material/RemoveDoneOutlined";
import DoneAllOutlinedIcon from "@mui/icons-material/DoneAllOutlined";
import PaymentsOutlinedIcon from "@mui/icons-material/PaymentsOutlined";
import CancelOutlinedIcon from "@mui/icons-material/CancelOutlined";
import { useCallback, useState, useEffect } from "react";
import { toast } from "react-toastify";
import Currency from "../../../utils/CurrencySymbol";
import axios from "../../../api/axios";
import LocalPrice from "../../../utils/LocalPrice";

const theme = createTheme({
  components: {
    MuiModal: {
      styleOverrides: {
        root: {
          padding: "20px 5px 20px",
        },
      },
    },
    MuiPaper: {
      styleOverrides: {
        root: {
          borderRadius: "10px",
        },
      },
    },
    MuiTableCell: {
      styleOverrides: {
        root: {
          padding: "10px 5px",
          fontSize: "12px",
        },
      },
    },
    MuiOutlinedInput: {
      styleOverrides: {
        root: {
          borderRadius: "8px",
        },
      },
    },
  },
});

const ConnectedPayments = () => {
  const [connectedPaymentsOpen, setConnectedPaymentsOpen] = useRecoilState(
    connectedPaymentsOpenState
  );

  const selectedShipmentForMyShipments = useRecoilValue(selectedShipmentForMyShipmentsState);

  const [loading, setLoading] = useState(false);
  const [rows, setRows] = useState([]);

  const getFinancialData = useCallback(() => {
    setLoading(true);
    if (selectedShipmentForMyShipments.length !== 0) {
      axios
        .get(`parcel/finance-information/${selectedShipmentForMyShipments.id}`)
        .then((response) => {
          if (response.data.data === null) {
            toast.info(response.data.message);
            setConnectedPaymentsOpen(false);
          } else {
            setRows(response.data.data?.map((row) => row.get_details).flat());
          }
        })
        .catch((error) => {
          toast.info(error.response.data.message);
          setConnectedPaymentsOpen(false);
        })
        .finally(() => {
          setLoading(false);
        });
    }
  }, [selectedShipmentForMyShipments, setConnectedPaymentsOpen]);

  const handleClose = useCallback(() => {
    setConnectedPaymentsOpen(false);
    setRows([]);
  }, [setConnectedPaymentsOpen]);

  useEffect(() => {
    if (connectedPaymentsOpen) {
      getFinancialData();
    }
  }, [getFinancialData, connectedPaymentsOpen]);

  return (
    <ThemeProvider theme={theme}>
      <Modal open={connectedPaymentsOpen} onClose={handleClose}>
        <>
          {loading && (
            <Box
              sx={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                height: "200px",
                width: "100%",
                maxWidth: "1100px",
                margin: "auto",
                outline: 0,
                backgroundColor: "var(--lighterBg)",
                borderRadius: "var(--inputBorderRadius)",
                position: "relative",
              }}
            >
              <CancelOutlinedIcon
                sx={{
                  position: "absolute",
                  right: 1,
                  top: 1,
                  color: "var(--priceText)",
                  cursor: "pointer",
                  transition: "all 80ms linear",
                  ":hover": {
                    color: "var(--notRed)",
                    transition: "all 80ms linear",
                  },
                }}
                onClick={handleClose}
              />
              <CircularProgress />
            </Box>
          )}
          {!loading && rows.length > 0 && (
            <TableContainer
              sx={{
                outline: 0,
                maxWidth: 1100,
                margin: "0 auto",
                position: "relative",
              }}
              style={{
                maxHeight: "90vh",
                overflowY: "auto",
              }}
              component={Paper}
              className="connected-payments"
            >
              <CancelOutlinedIcon
                sx={{
                  position: "absolute",
                  right: 1,
                  top: 1,
                  color: "var(--priceText)",
                  cursor: "pointer",
                  transition: "all 80ms linear",
                  ":hover": {
                    color: "var(--notRed)",
                    transition: "all 80ms linear",
                  },
                }}
                onClick={() => setConnectedPaymentsOpen(false)}
              />
              <Box className="connected-payments-headline">
                <PaymentsOutlinedIcon /> Bağlı Ödemeler
              </Box>
              <Table aria-label="simple table">
                <TableHead>
                  <TableRow
                    sx={{
                      ":nth-of-type(even)": {
                        backgroundColor: "var(--pastelBlue)",
                      },
                    }}
                  >
                    <TableCell align="center" sx={{ fontWeight: 700 }}>
                      Tarih
                    </TableCell>
                    <TableCell align="center" sx={{ fontWeight: 700 }}>
                      Durum
                    </TableCell>
                    <TableCell align="center" sx={{ fontWeight: 700 }}>
                      iyziShip Ödeme No
                    </TableCell>
                    <TableCell align="center" sx={{ fontWeight: 700 }}>
                      Kurum Ödeme No
                    </TableCell>
                    <TableCell align="center" sx={{ fontWeight: 700 }}>
                      Ödeme Kuruluşu
                    </TableCell>
                    <TableCell align="center" sx={{ fontWeight: 700 }}>
                      AWB Tutarı Toplam Ödenen
                    </TableCell>
                    <TableCell align="center" sx={{ fontWeight: 700 }}>
                      Froud Durumu <br /> (Ödeme Durumu)
                    </TableCell>
                    <TableCell align="center" sx={{ fontWeight: 700 }}>
                      Ödeme Açıklaması
                    </TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {rows?.map((row, key) => (
                    <TableRow key={key} sx={{ "&:last-child td, &:last-child th": { border: 0 } }}>
                      <TableCell align="center">
                        {row?.created_at.slice(0, 10)}
                        <br />
                        {row?.created_at.slice(11, 19)}
                      </TableCell>
                      <TableCell align="center">
                        {Number(row?.paid) === 1 ? (
                          <DoneAllOutlinedIcon
                            sx={{
                              color: "var(--notGreen)",
                            }}
                          />
                        ) : (
                          <RemoveDoneOutlinedIcon
                            sx={{
                              color: "var(--notRed)",
                            }}
                          />
                        )}
                      </TableCell>
                      <TableCell align="center">
                        {row?.payment_id ? row?.payment_id : "---"}
                      </TableCell>
                      <TableCell align="center">
                        {row?.payment_basket_no ? row?.payment_basket_no : "---"}
                      </TableCell>
                      <TableCell align="center">
                        {row?.payment_id ? "Iyzico" : "IyziBakiyem"}
                      </TableCell>
                      <TableCell align="center">
                        {Currency(selectedShipmentForMyShipments.currency)}
                        {LocalPrice(row?.paid_price)}
                        <br />
                        {LocalPrice(row?.paid_price_try)} ₺
                      </TableCell>
                      <TableCell align="center">
                        {Number(row?.paid) === 1 ? (
                          <DoneAllOutlinedIcon
                            sx={{
                              color: "var(--notGreen)",
                            }}
                          />
                        ) : (
                          <RemoveDoneOutlinedIcon
                            sx={{
                              color: "var(--notRed)",
                            }}
                          />
                        )}
                      </TableCell>
                      <TableCell align="left">{row.type_name}</TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </TableContainer>
          )}
        </>
      </Modal>
    </ThemeProvider>
  );
};

export default ConnectedPayments;
