import { Box } from "@mui/material";
import { useRecoilState, useRecoilValue } from "recoil";
import ArrowBackIosNewOutlinedIcon from "@mui/icons-material/ArrowBackIosNewOutlined";
import { useCallback } from "react";
import {
  awbHeaderState,
  caseHeaderState,
  dateHeaderState,
  lastActionHeaderState,
  openSupportListState,
  statusHeaderState,
  subjectHeaderState,
  supportCurrentPageState,
  supportListLoadingState,
  supportListState,
  supportOrderByState,
  supportOrderDirectionState,
  supportPageLimitState,
  supportTotalPageState,
} from "../../../atoms/support";
import axios from "../../../api/axios";
import Errors from "../../../utils/Errors";

const StatusHeader = () => {
  const [statusHeader, setStatusHeader] = useRecoilState(statusHeaderState);
  const [, setSubjectHeader] = useRecoilState(subjectHeaderState);
  const [, setCaseHeader] = useRecoilState(caseHeaderState);
  const [, setAwbHeader] = useRecoilState(awbHeaderState);
  const [, setDateHeader] = useRecoilState(dateHeaderState);
  const [, setLastActionHeader] = useRecoilState(lastActionHeaderState);
  const [, setSupportOrderBy] = useRecoilState(supportOrderByState);
  const [, setSupportOrderDirection] = useRecoilState(supportOrderDirectionState);

  const [, setOpenSupportList] = useRecoilState(openSupportListState);
  const [, setSupportList] = useRecoilState(supportListState);
  const [, setSupportTotalPage] = useRecoilState(supportTotalPageState);
  const [supportCurrentPage, setSupportCurrentPage] = useRecoilState(supportCurrentPageState);
  const [, setSupportListLoading] = useRecoilState(supportListLoadingState);
  const supportPageLimit = useRecoilValue(supportPageLimitState);

  const handleStatus = useCallback(() => {
    setSupportListLoading(true);
    if (statusHeader) {
      setStatusHeader(false);
      setSupportOrderBy("status");
      setSupportOrderDirection("DESC");

      axios
        .get(
          `ticket?order_direction=DESC&limit=${supportPageLimit}&page=${supportCurrentPage}&order_by=status`
        )
        .then((response) => {
          setSupportListLoading(false);
          setSupportCurrentPage(Number(response.data.currentPage));
          setSupportList(response.data.data);
          setSupportTotalPage(response.data.totalPages);
          if (response.data.data.length > 0) {
            setOpenSupportList(true);
          } else {
            setOpenSupportList(false);
          }
        })
        .catch((error) => {
          setSupportListLoading(false);
          setOpenSupportList(false);
          Errors(error);
        });
    } else {
      setStatusHeader(true);
      setSubjectHeader(false);
      setCaseHeader(false);
      setAwbHeader(false);
      setDateHeader(false);
      setLastActionHeader(false);
      setSupportOrderBy("status");
      setSupportOrderDirection("ASC");

      axios
        .get(
          `ticket?order_direction=ASC&limit=${supportPageLimit}&page=${supportCurrentPage}&order_by=status`
        )
        .then((response) => {
          setSupportListLoading(false);
          setSupportCurrentPage(Number(response.data.currentPage));
          setSupportList(response.data.data);
          setSupportTotalPage(response.data.totalPages);
          if (response.data.data.length > 0) {
            setOpenSupportList(true);
          } else {
            setOpenSupportList(false);
          }
        })
        .catch((error) => {
          setSupportListLoading(false);
          setOpenSupportList(false);
          Errors(error);
        });
    }
  }, [
    setStatusHeader,
    statusHeader,
    setSupportOrderBy,
    setSupportOrderDirection,
    setSubjectHeader,
    setCaseHeader,
    setAwbHeader,
    setDateHeader,
    setLastActionHeader,
    setSupportListLoading,
    setSupportCurrentPage,
    setSupportList,
    setSupportTotalPage,
    setOpenSupportList,
    supportCurrentPage,
    supportPageLimit,
  ]);

  return (
    <Box onClick={handleStatus} className="th-container">
      Durum{" "}
      <ArrowBackIosNewOutlinedIcon
        sx={
          statusHeader
            ? {
                width: "15px",
                height: "15px",
                transform: "rotate(90deg)",
                transition: "all 150ms linear",
              }
            : {
                width: "15px",
                height: "15px",
                transform: "rotate(270deg)",
                transition: "all 150ms linear",
              }
        }
      />
    </Box>
  );
};

export default StatusHeader;
