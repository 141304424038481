const OrderBasketSystem = ({
  orderSelectedPackageSystem,
  setOrderSelectedPackageSystem,
  isMultipleOrder,
}) => {
  const handlePackageSystem = (system) => {
    if (system === "B" && !isMultipleOrder) {
      return;
    } else setOrderSelectedPackageSystem(system);
  };

  return (
    <>
      <div
        className={`order-button ${orderSelectedPackageSystem === "S" && "selected"}`}
        onClick={() => handlePackageSystem("S")}
      >
        <div className="order-button-headline">Ayrı Kargolar Olarak</div>
        <img
          className="order-button-image"
          src="images/order/all_shipment_seperate.png"
          alt="Ayrı Kargolar Halinde"
        />
        <p>
          Her gönderiyi ayrı yurt içi kargo olarak <span>iyziMerkez</span>’e göndereceğim.
        </p>
      </div>
      <div
        className={`order-button ${!isMultipleOrder && "disabled"} ${
          orderSelectedPackageSystem === "B" && "selected"
        }`}
        onClick={() => handlePackageSystem("B")}
      >
        <div className="order-button-headline">Tek Ana Paket İçinde Toplu Olarak</div>
        <img
          className="order-button-image"
          src="images/order/all_shipment_in_one_package.png"
          alt="Tek Ana Paket İçinde Toplu Halde"
        />
        <p>
          Tüm gönderilerimi tek bir ana paket içerisinde paketleyip, toplu olarak{" "}
          <span>iyziMerkez</span>’e göndereceğim.
        </p>
      </div>
    </>
  );
};

export default OrderBasketSystem;
