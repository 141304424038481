import "../../style/myWallet/MyWallet.css";
import { Box, Button, Pagination } from "@mui/material";
import { useInView, animated } from "@react-spring/web";
import { useCallback, useState } from "react";
import { useRecoilState, useSetRecoilState } from "recoil";
import NotificationBar from "../../components/sidebar/NotificationBar";
import { DynamicTitle } from "../../utils/DynamicTitle";
import WrapperStyle from "../../utils/WrapperStyle";
import { ToastContainer } from "react-toastify";
import axios from "../../api/axios";
import { useCookies } from "react-cookie";
import MyWalletNav from "../../components/myWallet/MyWalletNav";
import MyWalletRefundBalance from "./myWalletRefund/MyWalletRefundBalance";
import MyWalletRefundAmount from "./myWalletRefund/MyWalletRefundAmount";
import MyRefundTable from "./myWalletRefund/MyRefundTable";
import MyRefundDocModal from "./myWalletRefund/MyRefundDocModal";
import MyWalletRefundCompanyBillModal from "./myWalletRefund/MyWalletRefundCompanyBillModal";
import {
  walletRefundCurrentPageState,
  walletRefundDataState,
  walletRefundTotalPageState,
} from "../../atoms/myWallet";
import Errors from "../../utils/Errors";
import RefundTitle from "./title/RefundTitle";

const MyWalletRefund = () => {
  DynamicTitle("Bakiye İade Talebi");

  const [token] = useCookies(["jwt"]);
  const jwt = token.jwt;
  axios.defaults.headers.common["Authorization"] = `Bearer ${jwt}`;
  const [newReq, setNewReq] = useState(false);

  const setWalletRefundData = useSetRecoilState(walletRefundDataState);
  const [walletRefundCurrentPage, setWalletRefundCurrentPage] = useRecoilState(
    walletRefundCurrentPageState
  );
  const [walletRefundTotalPage, setWalletRefundTotalPage] = useRecoilState(
    walletRefundTotalPageState
  );

  const handlePageChange = useCallback(
    (e, page) => {
      axios
        .get(`refund-compensation`, {
          params: {
            page: page,
            limit: 100,
            order_direction: "DESC",
          },
        })
        .then((resp) => {
          setWalletRefundData(resp.data.data);
          setWalletRefundCurrentPage(resp.data.currentPage);
          setWalletRefundTotalPage(resp.data.totalPages);
        })
        .catch((error) => {
          Errors(error);
        });
    },
    [setWalletRefundData, setWalletRefundCurrentPage, setWalletRefundTotalPage]
  );

  const [ref, springs] = useInView(
    () => ({
      from: {
        opacity: 0,
        y: 20,
      },
      to: {
        opacity: 1,
        y: 0,
      },
    }),
    {
      rootMargin: "-10% 0%",
    }
  );

  return (
    <section className="my-wallet">
      <NotificationBar />
      <ToastContainer />
      <RefundTitle />
      <Box className="my-wallet-wrapper" style={WrapperStyle()}>
        <MyWalletNav />
        <animated.div ref={ref} style={springs}>
          <Box className="my-wallet-container">
            <div className="my-wallet-refund">
              <MyRefundDocModal />
              {/* <MyRefundEditModal /> */}
              <MyWalletRefundCompanyBillModal />
              <MyWalletRefundBalance />
              <Button className="iyzi-button" onClick={() => setNewReq(!newReq)}>
                {newReq ? "Yeni Talebi İptal Et" : "Yeni Talep Oluştur"}
              </Button>
              {newReq && <MyWalletRefundAmount setNewReq={setNewReq} />}
              {walletRefundTotalPage > 1 && (
                <Pagination
                  showFirstButton
                  showLastButton
                  size="small"
                  count={walletRefundTotalPage}
                  page={walletRefundCurrentPage}
                  onChange={handlePageChange}
                />
              )}
              <MyRefundTable />
              {walletRefundTotalPage > 1 && (
                <Pagination
                  showFirstButton
                  showLastButton
                  size="small"
                  count={walletRefundTotalPage}
                  page={walletRefundCurrentPage}
                  onChange={handlePageChange}
                />
              )}
            </div>
          </Box>
        </animated.div>
      </Box>
    </section>
  );
};

export default MyWalletRefund;
