import "../../style/myWallet/MyWallet.css";
import { Button, Pagination } from "@mui/material";
import { useInView, animated } from "@react-spring/web";
import { useCallback, useEffect, useState } from "react";
import { useRecoilState, useSetRecoilState } from "recoil";
import NotificationBar from "../../components/sidebar/NotificationBar";
import { DynamicTitle } from "../../utils/DynamicTitle";
import { ToastContainer, toast } from "react-toastify";
import axios from "../../api/axios";
import { useCookies } from "react-cookie";
import MyWalletNav from "../../components/myWallet/MyWalletNav";
import {
  extractCurrentPageState,
  extractDataState,
  extractTotalPageState,
  totalInvoicableAmountState,
} from "../../atoms/myWallet";
import MyWalletExtractTable from "./myWalletExtract/MyWalletExtraxtTable";
import DownloadOutlinedIcon from "@mui/icons-material/DownloadOutlined";
import ReactDatePicker from "react-datepicker";
import FormatDate from "../../utils/FormatDate";
import DownloadBase64Pdf from "../../utils/DownloadBase64Pdf";
import WrapperStyle from "../../utils/WrapperStyle";
import MyWalletExtractDetailModal from "./myWalletExtract/MyWalletExtractDetailModal";
import Errors from "../../utils/Errors";
import ExtractTitle from "./title/ExtractTitle";
import LocalPrice from "../../utils/LocalPrice";
import Currency from "../../utils/CurrencySymbol";

const MyWalletExtract = () => {
  DynamicTitle("Ekstrem");

  const [token] = useCookies(["jwt"]);
  const jwt = token.jwt;
  axios.defaults.headers.common["Authorization"] = `Bearer ${jwt}`;

  const [ref, springs] = useInView(
    () => ({
      from: {
        opacity: 0,
        y: 20,
      },
      to: {
        opacity: 1,
        y: 0,
      },
    }),
    {
      rootMargin: "-10% 0%",
    }
  );

  // Sayfa Fonksiyonları
  const [extractTotalPage, setExtractTotalPage] = useRecoilState(extractTotalPageState);
  const [extractCurrentPage, setExtractCurrentPage] = useRecoilState(extractCurrentPageState);
  const setExtractData = useSetRecoilState(extractDataState);
  const [totalInvoicableAmount, setTotalInvoicableAmount] = useRecoilState(
    totalInvoicableAmountState
  );

  const [startDate, setStartDate] = useState(new Date());
  const [endDate, setEndDate] = useState(new Date());

  const handleExtractPageChange = useCallback(
    (e, page) => {
      axios
        .get(`wallet/extrem-list`, {
          params: {
            page: page,
          },
        })
        .then((response) => {
          setExtractData(response.data.data);
          setExtractCurrentPage(page);
          setExtractTotalPage(response.data.totalPages);
          setTotalInvoicableAmount(response.data?.invoiceable_balance_total);
        })
        .catch((error) => Errors(error));
    },
    [setExtractData, setExtractCurrentPage, setExtractTotalPage, setTotalInvoicableAmount]
  );

  const handleExport = useCallback(() => {
    axios
      .post(`wallet/extrem-list/export`, {
        export: 1,
        start_date: FormatDate(startDate),
        end_date: FormatDate(endDate),
      })
      .then((res) => {
        if (res.data.status === 200) {
          DownloadBase64Pdf(
            res.data.base64,
            "Ekstrem",
            "finance",
            FormatDate(startDate),
            FormatDate(endDate)
          );
        } else {
          toast.error(res.data.message);
        }
      })
      .catch((error) => Errors(error));
  }, [startDate, endDate]);

  // const receivableBalanceTotal = extractData
  //   .map((item) => parseFloat(item.receivable_balance))
  //   .reduce((sum, value) => sum + parseFloat(value), 0);

  // const debtBalanceTotal = extractData
  //   .map((item) => parseFloat(item.debt_balance))
  //   .reduce((sum, value) => sum + parseFloat(value), 0);

  // const invoicableAmountTotal = receivableBalanceTotal + debtBalanceTotal;

  useEffect(() => {
    // Eğer başlangıç tarihi bitiş tarihinden sonrasına atarsa bitiş tarihini tekrar düzenlememiz gerekiyor.
    if (startDate.getTime() > endDate.getTime()) {
      setEndDate(new Date(startDate.getTime() + 1000 * 60 * 60 * 24));
    }
  }, [endDate, startDate]);

  return (
    <section className="my-wallet">
      <NotificationBar />
      <ToastContainer />
      <MyWalletExtractDetailModal />
      <ExtractTitle />
      <div className="my-wallet-wrapper" style={WrapperStyle()}>
        <MyWalletNav />
        <animated.div ref={ref} style={springs}>
          <div className="my-wallet-container">
            <div
              style={{
                backgroundColor: "var(--lighterBg)",
                display: "flex",
                justifyContent: "flex-end",
                padding: "20px",
                borderRadius: "var(--wrapperBorderRadius)",
                boxShadow: "var(--shadow)",
                gap: "var(--gap2x)",
              }}
            >
              <div className="reactpicker-wrapper" style={{ maxWidth: 180 }}>
                <label htmlFor="start-date">Başlangıç Tarihi</label>
                <ReactDatePicker
                  id="start-date"
                  selected={startDate}
                  onChange={(date) => setStartDate(date)}
                  selectsStart
                  startDate={startDate}
                  endDate={endDate}
                  dateFormat="yyyy-MM-dd"
                  showIcon
                  monthsShown={2}
                />
              </div>
              <div className="reactpicker-wrapper" style={{ maxWidth: 180 }}>
                <label htmlFor="end-date">Bitiş Tarihi</label>
                <ReactDatePicker
                  selected={endDate}
                  onChange={(date) => setEndDate(date)}
                  selectsEnd
                  startDate={startDate}
                  endDate={endDate}
                  minDate={startDate}
                  dateFormat="yyyy-MM-dd"
                  showIcon
                  monthsShown={2}
                />
              </div>
              <Button onClick={handleExport} sx={{ minWidth: "90px" }} className="iyzi-button">
                <DownloadOutlinedIcon /> Dışarı Aktar
              </Button>
            </div>
            <div style={{ display: "flex", justifyContent: "flex-end", alignItems: "center" }}>
              <div
                style={{
                  backgroundColor: "var(--lighterBg)",
                  display: "flex",
                  justifyContent: "flex-end",
                  padding: "20px",
                  borderRadius: "var(--wrapperBorderRadius)",
                  boxShadow: "var(--shadow)",
                  gap: "7px",
                }}
              >
                <span>Fatura Edilecek Tutar:</span>
                <span style={{ fontWeight: 500, color: "var(--btnBgBlue)" }}>
                  {LocalPrice(totalInvoicableAmount)}
                  {Currency("TRY")}
                </span>
              </div>
            </div>
            <MyWalletExtractTable />
            <Pagination
              showFirstButton
              showLastButton
              size="small"
              count={extractTotalPage}
              page={extractCurrentPage}
              onChange={handleExtractPageChange}
            />
          </div>
        </animated.div>
      </div>
    </section>
  );
};

export default MyWalletExtract;
