import Footer from "../components/beforeLogin/Footer";
import Header from "../components/beforeLogin/Header";
import { DynamicTitle } from "../utils/DynamicTitle";
// import PageTransition from "../utils/PageTransition";

const CancellationsRefunds = () => {
  DynamicTitle("İptal ve İadeler | iyziShip");

  return (
    // <PageTransition>
    <>
      <Header />
      <main className="iyzi-main">
        <div className="policies">
          <article>
            <h1>Kullanıcı İptal Ve İade Süreçleri</h1>
            <p>
              İşbu “Kullanıcı (Üyelik) Sözleşmesi” (“Sözleşme”),
              www.iyziship.com yani bu web sitesinin (“Site”) ve burada sunulan
              yazılımların ve hizmetlerin (“Hizmet(ler)”) kullanımını
              düzenlemektedir.
            </p>
            <p>
              Sözleşme, Kullanıcının (Üyenin) elektronik ortamda kabulü ile
              birlikte yürürlüğe girecek olup; taraflarca Sözleşme’de belirtilen
              usuller doğrultusunda sona erdirilmediği sürece yürürlükte kalmaya
              devam edecektir. İşbu Sözleşme, üyenin münferit abone, tek bir
              şirket veya kuruluş (“Üye(ler)”) olması fark etmeksizin, kullanıcı
              olan Üye ve “İYZİ BİLİŞİM ANONİM ŞİRKETİ” kısaca markası ve web
              sitesi ismi ile “iyziShip”) arasında gerçekleşen yasal bir
              sözleşmedir.
            </p>
            <p>
              Kullanıcı (Üye), web sitemize üye olmakla birlikte, işbu sözleşme
              ile bağlı olduğunu kabul etmiş olup Kullanıcı (Üyelik)
              Bilgilerinin, web sitesinde yer verilen KVKK Aydınlatma Metni’nde
              belirtilen şekilde toplanmasını ve kullanılmasını da kabul etmiş
              sayılacaktır.
            </p>
            <p>
              Eğer kullanıcı (üye); bir şirket, kuruluş veya tüzel kişi adına
              bir hesap açıyor veya kullanıyorsa:
            </p>
            <ul>
              <li style={{ listStyleType: "lower-roman" }}>
                İşbu sözleşmeyle bağlı olacak şekilde o kurumun yetkili
                temsilcisi olduğunu,
              </li>
              <li style={{ listStyleType: "lower-roman" }}>
                İşbu sözleşmeyi okuduğunu
              </li>
              <li style={{ listStyleType: "lower-roman" }}>Anladığını</li>
              <li style={{ listStyleType: "lower-roman" }}>
                Tüzel kişi adına onayladığını kabul ve taahhüt etmiş olacaktır.
              </li>
            </ul>
          </article>
          <article>
            <h4>Kullanıcının İptal ve İade Hakları</h4>
            <ul>
              <li>
                Üye’nin iyziShip üzerinde oluşturduğu gönderinin ödemesini
                yaptığı andan itibaren herhangi bir ilerleme kaydedilmemişse
                (iyziship Merkezi’ne teslim, yurt dışı çıkışı vb.) anında iptal
                edilir ve iadesi iyziBakiyem üzerinden gerçekleştirilir.
              </li>
              <li>
                Üyenin iyziShip üzerinden oluşturduğu gönderinin üye tarafından
                seçilmiş ana taşıyıcı kurye firmasına (FedEx, TNT, DHL, UPS,
                USPS, Royal Mail, DPD, Hermes, GLS vb.) iyziShip tarafından
                teslim edilmesi sonrasında gönderinin alıcıya ulaşmaması veya
                kaybolması durumunda Üye, yükün navlun ve ürün bedelini iade
                isteme hakkına sahiptir.
              </li>
              <li>
                Üyenin iyziShip üzerinden oluşturduğu gönderi iyziShip’e
                ulaşmamış ve iyziShip’e gönderirken kullandığı bir yurt içi
                taşıma şirketinde (3. kişi veya şirket) kaybolması durumunda
                Üye, yükün navlun bedelini iade isteme hakkına sahiptir.
              </li>
              <li>
                Üyenin iyziShip üzerinde oluşturduğu gönderi iyziShip Merkezi’ne
                hasarlı olarak ulaşırsa, hasarlı gönderiyi iptal etmeden
                gönderme veya iptal etme hakkına sahiptir. Gönderi elden teslim
                edilmemişse (yurt içinde bir kargo firmasıyla teslim edilmişse)
                yükün iade miktarının tamamı iade edilemez. Yurt içi taşıma
                bedeli düşüldükten sonra kalan miktar iade edilir.
              </li>
            </ul>
          </article>
        </div>
      </main>
      <Footer />
    </>

    // </PageTransition>
  );
};

export default CancellationsRefunds;
