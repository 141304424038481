import {
  Box,
  Button,
  CircularProgress,
  FormControl,
  InputLabel,
  MenuItem,
  TextField,
  Select,
  Dialog,
  DialogContent,
  Tooltip,
  IconButton,
  Checkbox,
  Modal,
  FormHelperText,
} from "@mui/material";
import ContentCopyOutlinedIcon from "@mui/icons-material/ContentCopyOutlined";
import { useTheme } from "@mui/material/styles";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import { DatePicker, LocalizationProvider } from "@mui/x-date-pickers";
import "react-phone-input-2/lib/style.css";
import { useCallback, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import { useRecoilState, useRecoilValue, useSetRecoilState } from "recoil";
import axios from "../../api/axios";
import PhoneInput from "react-phone-input-2";
import OutlinedInput from "@mui/material/OutlinedInput";
import FormatDate from "../../utils/FormatDate";
import FormatDateTR from "../../utils/FormatDateTR";

import {
  awbState,
  detailedStatusState,
  myShipmentListState,
  myShipmentsListLimitState,
  receiverCountryCodeState,
  receiverState,
  refIdState,
  selectedExportStatusState,
  selectedDomesticStatusState,
  selectedCaseStatusState,
  selectedPriceStatusState,
  selectedSupplierState,
  senderCountryCodeState,
  senderState,
  shipmentListCurrentPageState,
  shipmentListOrderDirectionState,
  shipmentsListArrayLengthState,
  shipmentStatusState,
  timeState,
  totalPagesState,
  fieldStatusState,
  arrayStatusState,
  myShipmentsListBasicLimitState,
} from "../../atoms/myShipments";
import {
  courierDeliveryAddressState,
  courierListState,
  isCourierRegistrationExistState,
  isCreateRegistrationState,
  createRegisterModalState,
  showAddButtonState,
} from "../../atoms/courierRegistration";
import { addressTypeState } from "../../atoms/profileSettings";
import "../../style/courierRegistration/CourierRegistration.css";
import Errors from "../../utils/Errors";

const CreateCourierRegistration = () => {
  const navigate = useNavigate();
  const [createRegisterModal, setCreateRegisterModal] = useRecoilState(createRegisterModalState);
  const [address, setAddress] = useState([]);
  const [deliveryDate, setDeliveryDate] = useState(FormatDate(new Date()));
  const [deliveryPerson, setDeliveryPerson] = useState("");
  const [cellPhone, setCellPhone] = useState("");
  const [parcel, setParcel] = useState([]);

  const [courierDeliveryAddress, setCourierDeliveryAddress] = useRecoilState(
      courierDeliveryAddressState
  );
  const setIsCreateRegistration = useSetRecoilState(isCreateRegistrationState);
  const setIsCourierRegistrationExist = useSetRecoilState(isCourierRegistrationExistState);
  const setShowAddButton = useSetRecoilState(showAddButtonState);

  const time = useRecoilValue(timeState);
  const senderCountryCode = useRecoilValue(senderCountryCodeState);
  const receiverCountryCode = useRecoilValue(receiverCountryCodeState);
  const selectedSupplier = useRecoilValue(selectedSupplierState);
  const selectedPriceStatus = useRecoilValue(selectedPriceStatusState);
  const selectedExportStatus = useRecoilValue(selectedExportStatusState);
  const selectedDomesticStatus = useRecoilValue(selectedDomesticStatusState);
  const selectedCaseStatus = useRecoilValue(selectedCaseStatusState);
  const awb = useRecoilValue(awbState);
  const fieldStatus = useRecoilValue(fieldStatusState);
  const arrayStatus = useRecoilValue(arrayStatusState);
  const refId = useRecoilValue(refIdState);
  const sender = useRecoilValue(senderState);
  const receiver = useRecoilValue(receiverState);
  const shipmentStatus = useRecoilValue(shipmentStatusState);
  const detailedStatus = useRecoilValue(detailedStatusState);
  const shipmentOrderDirectionPage = useRecoilValue(shipmentListOrderDirectionState);

  const [courierList, setCourierList] = useRecoilState(courierListState);
  const [myShipmentList, setMyShipmentList] = useRecoilState(myShipmentListState);
  const myShipmentsListLimit = useRecoilValue(myShipmentsListLimitState);
  const myShipmentsListBasicLimit = useRecoilValue(myShipmentsListBasicLimitState);
  const shipmentListCurrentPage = useRecoilValue(shipmentListCurrentPageState);
  const [, setShipmentsListArrayLength] = useRecoilState(shipmentsListArrayLengthState);

  const [, setAddressType] = useRecoilState(addressTypeState);

  const [, setTotalPages] = useRecoilState(totalPagesState);
  const [loading, setLoading] = useState(false);

  const [dialogOpen, setDialogOpen] = useState(false);
  const [copied, setCopied] = useState(false);
  const [selectedParcels, setSelectedParcels] = useState([]);
  const [copiedMessage, setCopiedMessage] = useState("");

  //* Error */

  const [phoneIsNotValid, setPhoneIsNotValid] = useState(false);

  // Bugün için saat 11:30'u temsil eden bir tarih nesnesi oluştur
  const elevenThirty = new Date();
  elevenThirty.setHours(11, 30, 0, 0); // Saati 11:30 olarak ayarla

  const handleClick = async (parcelRefNo, receiverName) => {
    const textToCopy = `${parcelRefNo} - ${receiverName}`;
    try {
      await navigator.clipboard.writeText(textToCopy);
      setCopied(true);
      setTimeout(() => setCopied(false), 2000);
    } catch (err) {
      console.error("Kopyalama Hatası: ", err);
    }
  };

  const handleCopySelected = async () => {
    const textToCopy = selectedParcels
        .map((itemId) => {
          const item = myShipmentList.find((shipment) => shipment.id === itemId);
          return `${item.parcel_ref_no} - ${item.receiver_name}`;
        })
        .join("\n");

    try {
      await navigator.clipboard.writeText(textToCopy);
      setCopied(true);
      setCopiedMessage("Kopyalandı!");
      setTimeout(() => setCopied(false), 2000);
      setTimeout(() => {
        setCopiedMessage("");
      }, 1000);
    } catch (err) {
      console.error("Kopyalama Hatası: ", err);
    }
  };

  const handleSelectParcel = (itemId) => {
    const selectedIndex = selectedParcels.indexOf(itemId);
    let newSelected = [];

    if (selectedIndex === -1) {
      newSelected = newSelected.concat(selectedParcels, itemId);
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(selectedParcels.slice(1));
    } else if (selectedIndex === selectedParcels.length - 1) {
      newSelected = newSelected.concat(selectedParcels.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(
          selectedParcels.slice(0, selectedIndex),
          selectedParcels.slice(selectedIndex + 1)
      );
    }

    setSelectedParcels(newSelected);
  };

  const isSelected = (itemId) => selectedParcels.indexOf(itemId) !== -1;

  const handleForwardDialogClose = () => {
    setDialogOpen(false);
  };

  const handleText = (e) => {
    let value = e.target.value;
    // Eğer değer boşlukla başlıyorsa, boşluğu kaldır
    if (value.startsWith(" ")) {
      value = value.trimStart();
    }
    // Yalnızca harfler, boşluklar ve uluslararası karakterlere izin verir
    const internationalLetters = /^[a-zA-Z\u00C0-\u017F\s]*$/;
    if (!value.match(internationalLetters)) return;

    setDeliveryPerson(value);
  };

  const getAddress = useCallback(async () => {
    await axios
        .post("member-address/list", {
          address_type: 2,
          country: "TR",
        })
        .then((response) => {
          setAddress(response.data.data);
        })
        .catch((error) => Errors(error));
  }, [setAddress]);

  const getShipment = useCallback(async () => {
    const parcelUrlBase = "parcel";
    const parcelUrlParams = {
      order_direction: shipmentOrderDirectionPage,
      limit: myShipmentsListLimit,
      page: shipmentListCurrentPage,
      detailed: "1",
      booking: "1",
    };

    if (detailedStatus === "1") {
      parcelUrlParams.tracking_awb = awb;
      parcelUrlParams.parcel_ref_no = refId;
      parcelUrlParams.supplier_id =
          selectedSupplier !== "" ? Number(selectedSupplier) : selectedSupplier;
      parcelUrlParams.status = shipmentStatus;
      parcelUrlParams.tracking_awb_date = time;
      parcelUrlParams.sender_name = sender;
      parcelUrlParams.receiver_name = receiver;
      parcelUrlParams.sender_country = senderCountryCode;
      parcelUrlParams.receiver_country = receiverCountryCode;
      parcelUrlParams.parcel_type = selectedExportStatus;
      parcelUrlParams.paid = selectedPriceStatus;
      parcelUrlParams.is_domestic = selectedDomesticStatus;
      parcelUrlParams.is_case = selectedCaseStatus;
    } else {
      parcelUrlParams.order_direction = shipmentOrderDirectionPage;
      parcelUrlParams.limit = myShipmentsListBasicLimit;
      parcelUrlParams.page = shipmentListCurrentPage;
      parcelUrlParams.where_in_field = fieldStatus;
      parcelUrlParams.where_in_array = arrayStatus;
    }

    try {
      const response = await axios.get(parcelUrlBase, {
        params: parcelUrlParams,
      });

      setMyShipmentList(response.data.data);
      setTotalPages(response.data.totalPages);
      setShipmentsListArrayLength(response.data.data.length);
    } catch (error) {
      Errors(error);
    }
  }, [
    setMyShipmentList,
    setShipmentsListArrayLength,
    setTotalPages,
    myShipmentsListLimit,
    shipmentListCurrentPage,
    shipmentOrderDirectionPage,
    time,
    senderCountryCode,
    receiverCountryCode,
    selectedSupplier,
    selectedPriceStatus,
    selectedExportStatus,
    selectedDomesticStatus,
    selectedCaseStatus,
    awb,
    refId,
    sender,
    receiver,
    shipmentStatus,
    detailedStatus,
    fieldStatus,
    arrayStatus,
    myShipmentsListBasicLimit,
  ]);

  useEffect(() => {
    getAddress();
  }, [getAddress]);


  useEffect(() => {
    setDeliveryDate(FormatDate(deliveryDate));
  }, [deliveryDate]);


  useEffect(() => {
    getShipment();
  }, [getShipment]);

  const handleChange = useCallback(
      (event) => {
        const value = event.target.value;
        setParcel(Array.isArray(value) ? value : value.split(","));
      },
      [setParcel]
  );

  const handleCrateCourierRegistration = useCallback(
      (e) => {
        if (e) e.preventDefault();

        if (cellPhone.length < 6) {
          setPhoneIsNotValid(true);
        } else {
          setPhoneIsNotValid(false);
          setLoading(true);
          axios
              .post("booking/add", {
                booking_date: deliveryDate,
                submitter: deliveryPerson,
                phone: cellPhone,
                will_be_delivered_parcels: parcel,
              })
              .then((response) => {
                if (response.data.status === 200) {
                  setCourierList([response.data.data]);
                  setIsCourierRegistrationExist(true);
                  setIsCreateRegistration(false);
                  toast.success(response.data.message);
                  setShowAddButton(true);
                  setCreateRegisterModal(false);
                  if (courierList.length !== 0) {
                    window.location.reload();
                  }
                } else if (response.status === 400) {
                  setCourierList([]);
                  setIsCourierRegistrationExist(false);
                  setIsCreateRegistration(true);
                  setShowAddButton(false);
                  toast.error(response.data.message);
                }
              })
              .catch((error) => {
                setDialogOpen(true);
                Errors(error);
              })
              .finally(() => setLoading(false));
        }
      },
      [
        deliveryDate,
        deliveryPerson,
        cellPhone,
        setCourierList,
        setIsCourierRegistrationExist,
        setIsCreateRegistration,
        parcel,
        courierList.length,
        setCreateRegisterModal,
        setShowAddButton,
      ]
  );

  const theme = useTheme();
  const ITEM_HEIGHT = 48;
  const ITEM_PADDING_TOP = 8;
  const MenuProps = {
    PaperProps: {
      style: {
        maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
        width: 250,
      },
    },
  };

  function getStyles(name, parcel, theme) {
    return {
      fontWeight:
          parcel.indexOf(name) === -1
              ? theme.typography.fontWeightRegular
              : theme.typography.fontWeightMedium,
    };
  }
  function disableWeekends(date) {
    return date.getDay() === 0 || date.getDay() === 6;
  }

  const handleAddressNavigate = useCallback(
      (e) => {
        e.preventDefault();
        setLoading(true);
        setAddressType("2");
        navigate("/profile-settings/address-info");
      },
      [navigate, setAddressType]
  );

  const isDisabledDateTime = (deliveryDate) => {
    const currentDate = new Date();
    const selectedDate = new Date(deliveryDate);

    // Sadece tarih bilgisini karşılaştır
    currentDate.setHours(0, 0, 0, 0);
    selectedDate.setHours(0, 0, 0, 0);

    // Şu anki saat ve dakikayı al
    const currentHour = new Date().getHours();
    const currentMinute = new Date().getMinutes();

    const targetHour = 11;
    const targetMinute = 30;

    // Eğer seçilen tarih bugün ve saat henüz 11:30'a gelmemişse false dön
    if (
        selectedDate.getTime() === currentDate.getTime() &&
        (currentHour < targetHour || (currentHour === targetHour && currentMinute < targetMinute))
    ) {
      return false;
    }

    // Diğer durumlarda true dön
    return true;
  };

  const [isModalOpen, setIsModalOpen] = useState(false);

  const openModal = () => {
    setIsModalOpen(true);
  };

  const closeModal = () => {
    setIsModalOpen(false);
  };

  const handleCellPhoneChange = (e) => {
    setPhoneIsNotValid(false);
    setCellPhone(e);
  };

  useEffect(() => {
    if (!isDisabledDateTime()) {
      openModal();
    }
  }, []);

  return (
      <>
        <form onSubmit={handleCrateCourierRegistration} className="create-courier-registration ">
          <h6>Kurye Kaydı Oluştur</h6>
          <Box
              className="create-courier-registration-form "
              sx={{
                display: createRegisterModal ? "grid" : "",
                gridTemplateColumns: createRegisterModal ? "repeat(auto-fit, minmax(200px, 1fr))" : "",
                gap: createRegisterModal ? "var(--gap2x)" : "",
                margin: createRegisterModal ? "20px 0" : "",
              }}
          >
            <FormControl size="small" fullWidth required>
              <InputLabel id="delivery-address">Teslim Adresi Seçiniz</InputLabel>
              <Select
                  labelId="delivery-address"
                  id="courier-delivery-address"
                  label="Teslim Adresi Seçiniz"
                  value={courierDeliveryAddress}
                  onChange={(e) => setCourierDeliveryAddress(e.target.value)}
              >
                <MenuItem value="">
                  <em>Temizle</em>
                </MenuItem>
                {address?.length === 0 && (
                    <MenuItem onClick={handleAddressNavigate}>Toplama Adresi Oluştur</MenuItem>
                )}
                {address?.map((item, key) => (
                    <MenuItem key={key} value={item?.id} selected={item.is_default === "1"}>
                      {item.address_description === null || item.address_description === ""
                          ? ""
                          : `( Başlık : ${item.address_description} ) - `}
                      {item.state} - {item.town} - {item.city}
                    </MenuItem>
                ))}
              </Select>
            </FormControl>

            <div
                style={{
                  display: "flex",
                  flexDirection: "column",
                }}
            >
              <LocalizationProvider dateAdapter={AdapterDateFns}>
                <DatePicker
                    label="Teslim Tarihi"
                    value={deliveryDate}
                    minDate={new Date()}
                    onChange={(e) => setDeliveryDate(FormatDate(e))}
                    renderInput={(params) => <TextField size="small" required {...params} />}
                    shouldDisableDate={disableWeekends}
                    inputFormat="dd-MM-yyyy"
                    disablePast
                />
              </LocalizationProvider>
              <Modal
                  open={isModalOpen}
                  onClose={closeModal}
                  sx={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    padding: "20px",
                  }}
              >
                <div
                    style={{
                      backgroundColor: "var(--lighterBg)",
                      borderRadius: "var(--wrapperBorderRadius)",
                      maxWidth: "600px",
                      width: "100%",
                      padding: "20px",

                      maxHeight: "90vh",
                      overflowY: "auto",
                    }}
                >
                  <div
                      style={{
                        display: "flex",
                        flexDirection: "column",
                        gap: "var(--gap)",
                      }}
                  >
                    <p>
                      11.30'dan sonra oluşturulacak kurye kayıtları için sonraki günü seçmelisiniz.
                      11.30'dan öncesi için gün içinde de kurye kaydı oluşturabilirsiniz.
                    </p>
                    <Button className="iyzi-button-blue" onClick={closeModal}>
                      Anladım
                    </Button>
                  </div>
                </div>
              </Modal>
            </div>
            <TextField
                required
                label="Toplama Yapılacak Kişi"
                fullWidth
                autoComplete="off"
                size="small"
                value={deliveryPerson}
                onChange={handleText}
            />
            <FormControl size="small" fullWidth>
              <PhoneInput
                  country={"tr"}
                  value={cellPhone}
                  onChange={handleCellPhoneChange}
                  onEnterKeyPress={handleCrateCourierRegistration}
                  isValid={(value, country) => {
                    if (value.match(/12345/)) {
                      return "Invalid value: " + value + ", " + country.name;
                    } else if (value.match(/1234/)) {
                      return false;
                    } else {
                      return true;
                    }
                  }}
                  inputProps={{
                    name: "phone",
                    required: true,
                    autoFocus: true,
                    id: "ind-phone-input",
                    style: {
                      height: "100%",
                      width: "100%",
                      minHeight: "37px",
                      maxHeight: "37px",
                      borderColor: phoneIsNotValid ? "var(--error)" : "var(--inputBorderColor)",
                    },
                  }}
              />
              {phoneIsNotValid && (
                  <FormHelperText sx={{ color: "var(--error)" }}>
                    Telefon numaranızı giriniz.
                  </FormHelperText>
              )}
            </FormControl>
          </Box>
          <FormControl size="small" fullWidth required>
            <InputLabel id="parcel-label">Gönderi Seçin</InputLabel>
            <Select
                labelId="parcel-label"
                id="demo-multiple-name"
                multiple
                value={parcel}
                onChange={handleChange}
                input={<OutlinedInput label="parcel-label" />}
                MenuProps={MenuProps}
            >
              {loading ? (
                  <CircularProgress />
              ) : (
                  myShipmentList.map((item, key) => (
                      <MenuItem
                          key={key}
                          value={Number(item.id)}
                          style={getStyles(item.id, parcel, theme)}
                          disabled={item.disabled}
                      >
                        {item.parcel_ref_no} {"-"} {item.receiver_name}
                      </MenuItem>
                  ))
              )}
            </Select>
          </FormControl>

          <Box
              className="create-courier-registration-form-done"
              sx={{
                marginTop: createRegisterModal ? "20px" : "",
                display: createRegisterModal ? " flex " : "",
                justifyContent: createRegisterModal ? "end" : "",
              }}
          >
            {loading ? (
                <Box
                    sx={{
                      display: "flex",
                      width: "70px",
                      justifyContent: "center",
                      alignItems: "center",
                    }}
                >
                  <CircularProgress
                      sx={{
                        width: "24px !important",
                        height: "24px !important",
                      }}
                  />
                </Box>
            ) : (
                <Button
                    sx={{
                      ":disabled": {
                        opacity: 0.4,
                      },
                    }}
                    type="submit"
                    className="iyzi-button"
                >
                  Kayıt Oluştur
                </Button>
            )}
          </Box>
        </form>
        <Dialog
            open={dialogOpen}
            onClose={handleForwardDialogClose}
            sx={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              padding: "20px",
            }}
        >
          <Box
              sx={{
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
                gap: "20px",
                padding: "20px",
                borderRadius: "var(--wrapperBorderRadius)",
              }}
          >
            <DialogContent>
              <b>{FormatDateTR(deliveryDate)}</b> tarihi için bir kurye kaydınız bulunmaktadır. Aynı
              tarih için ikinci bir kayıt oluşturulamamaktadır. Değişiklik veya ekleme yapmak için
              destek hattı ile iletişime geçiniz.
            </DialogContent>

            {loading ? (
                <CircularProgress />
            ) : (
                <div
                    style={{
                      display: "flex",
                      flexDirection: "column",
                    }}
                >
                  {myShipmentList.map((item, key) => (
                      <Box
                          key={key}
                          value={Number(item.id)}
                          style={getStyles(item.id, parcel, theme)}
                          disabled={item.disabled}
                          sx={{ display: "flex", alignItems: "center", gap: "5px" }}
                      >
                        <Checkbox
                            checked={isSelected(item.id)}
                            onChange={() => handleSelectParcel(item.id)}
                        />
                        <div style={{ minWidth: "240px" }}>
                          {item.parcel_ref_no} {"-"} {item.receiver_name}
                        </div>
                        <Tooltip
                            title={
                              isSelected(item.id) ? (copied ? "Kopyalandı!" : "Kopyalamak için tıkla") : ""
                            }
                        >
                          <IconButton
                              onClick={() =>
                                  isSelected(item.id) && handleClick(item.parcel_ref_no, item.receiver_name)
                              }
                              onMouseLeave={() => setCopied(false)}
                          >
                            <ContentCopyOutlinedIcon />
                          </IconButton>
                        </Tooltip>
                      </Box>
                  ))}
                  <Button
                      className="iyzi-button"
                      sx={{ ":disabled": { opacity: 0.3 } }}
                      onClick={handleCopySelected}
                      disabled={selectedParcels.length === 0}
                  >
                    {copiedMessage || "Seçilenleri Kopyala"}
                  </Button>
                </div>
            )}
            <Box
                sx={{
                  display: "flex",
                  gap: "10px",
                }}
            >
              <Button
                  className="iyzi-button-blue"
                  onClick={() => {
                    navigate("/support");
                  }}
              >
                Destek Hattı ile İletişime Geç
              </Button>
              <Button
                  className="iyzi-button-blue"
                  onClick={() => {
                    handleForwardDialogClose();
                  }}
              >
                İptal
              </Button>
            </Box>
          </Box>
        </Dialog>
      </>
  );
};

export default CreateCourierRegistration;
