const FormatDateHour = (date) => {
  const options = {
    year: "numeric",
    month: "2-digit",
    day: "2-digit",
    hour: "2-digit",
    minute: "2-digit",
  };

  const turkishLocale = "tr-TR";

  return new Date(date).toLocaleDateString(turkishLocale, options);
};
export default FormatDateHour;
