import ProfileSettingsStyle from "../../../style/styleJs/ProfileSettingsStyle";
import BusinessCenterOutlinedIcon from "@mui/icons-material/BusinessCenterOutlined";
import CorporateUploader from "./CorporateUploader";

const CorporateDocuments = () => {
  return (
    <section style={ProfileSettingsStyle()} className="general-info">
      <div className="profile-card-wrapper">
        <div className="profile-card-header">
          <BusinessCenterOutlinedIcon className="profile-card-header-icon" />
          <span>Şirket Evrakları</span>
        </div>
      </div>
      <div className="general-info-membership">
        <CorporateUploader />
      </div>
    </section>
  );
};

export default CorporateDocuments;
