import "../style/fastShipment/FastShipment.css";
import { Box } from "@mui/material";
import { useInView, animated } from "@react-spring/web";
import { useCallback, useEffect, useState } from "react";
import { useCookies } from "react-cookie";
import { ToastContainer } from "react-toastify";
import { useRecoilState } from "recoil";
import axios from "../api/axios";
import NotificationBar from "../components/sidebar/NotificationBar";
import { DynamicTitle } from "../utils/DynamicTitle";
import WrapperStyle from "../utils/WrapperStyle";
import IntegrationList from "../components/integrations/IntegrationList";
import { currentIngtegrationsState } from "../atoms/integrations";
// import PageTransition from "../utils/PageTransition";
import Errors from "../utils/Errors";
import IntegrationTitle from "../components/integrations/IntegrationTitle";

const Integrations = () => {
  DynamicTitle("Ücretsiz E-Ticaret ve Pazaryeri Entegrasyonları | iyziShip");

  const [token] = useCookies(["jwt"]);
  const jwt = token.jwt;
  axios.defaults.headers.common["Authorization"] = `Bearer ${jwt}`;

  const [ref, springs] = useInView(
    () => ({
      from: {
        opacity: 0,
        y: 20,
      },
      to: {
        opacity: 1,
        y: 0,
      },
    }),
    {
      rootMargin: "-10% 0%",
    }
  );

  const [currentIngtegrations, setCurrentIntegrations] = useRecoilState(
    currentIngtegrationsState
  );
  const [integrationListed, setIntegrationListed] = useState(false);

  const getData = useCallback(() => {
    axios
      .get(`general/integration-list`)
      .then((response) => {
        setCurrentIntegrations(response.data.data);
        setIntegrationListed(true);
      })
      .catch((error) => Errors(error));
  }, [setCurrentIntegrations]);

  useEffect(() => {
    getData();
  }, [getData]);

  return (
    <section className="fast-shipment">
      
      <NotificationBar />
      {/* <PageTransition> */}
        <IntegrationTitle />
        <ToastContainer />
        <Box className="fast-shipment-wrapper" style={WrapperStyle()}>
          <animated.div ref={ref} style={springs}>
            <Box
              className="fast-shipment-container"
              sx={{ maxWidth: "1400px", width: "100%", margin: "0 auto" }}
            >
              {integrationListed && currentIngtegrations.length === 0 && (
                <div
                  style={{
                    backgroundColor: "var(--lighterBg)",
                    padding: "20px",
                    borderRadius: "var(--wrapperBorderRadius)",
                    boxShadow: "var(--shadow)",
                  }}
                >
                  Bağlı entegrasyonunuz bulunmamaktadır.
                </div>
              )}
              <IntegrationList />
            </Box>
          </animated.div>
        </Box>
      {/* </PageTransition> */}
    </section>
  );
};

export default Integrations;
