import React, { useEffect, useRef, useCallback } from "react";
import { Box } from "@mui/material";
import Chart from "chart.js/auto";
import axios from "../../../api/axios";
import Errors from "../../../utils/Errors";
import DownloadBase64Pdf from "../../../utils/DownloadBase64Pdf";
import { toast } from "react-toastify";
import FormatDate from "../../../utils/FormatDate";

const DashboardShipmentChart = ({ totalCome }) => {
  const handleExport = useCallback(() => {
    axios
      .post(`dashboard/export`, {
        export: 1,
        start_date: FormatDate(new Date()),
        end_date: FormatDate(new Date()),
      })
      .then((res) => {
        if (res.data.status === 200) {
          DownloadBase64Pdf(
            res.data.base64,
            "Ekstrem",
            "finance",
            FormatDate(new Date()),
            FormatDate(new Date())
          );
        } else {
          toast.error(res.data.message);
        }
      })
      .catch((error) => Errors(error));
  }, []);

  const chartRef = useRef(null);

  useEffect(() => {
    let myChart;

    const monthNames = [
      "Ocak",
      "Şubat",
      "Mart",
      "Nisan",
      "Mayıs",
      "Haziran",
      "Temmuz",
      "Ağustos",
      "Eylül",
      "Ekim",
      "Kasım",
      "Aralık",
    ];

    const labels = monthNames;

    const datasets = Object.keys(totalCome).map((year) => {
      const item = totalCome[year];
      const data = item.data;
      const borderColor = item.label;

      const monthlyData = monthNames.map((month, index) => ({
        x: month,
        y: data[index] || 0,
      }));

      const filteredMonthlyData = [];
      let foundNonZero = false;

      // Tüm ayları döngüyle dolaşarak, mevcut ayda 0'dan büyük olan ilk değeri bul
      monthlyData.forEach((item, index) => {
        if (!foundNonZero && item.y > 0) {
          foundNonZero = true;
          // Mevcut ayda 0'dan büyük bir değer varsa, önceki tüm ayları da ekler
          for (let i = 0; i < index; i++) {
            filteredMonthlyData.push(monthlyData[i]);
          }
        }

        // Mevcut ayda 0'dan büyük bir değer yoksa, her zaman mevcut öğeyi ekler
        if (!foundNonZero || item.y > 0) {
          filteredMonthlyData.push(item);
        }
      });

      return {
        label: year,
        data: filteredMonthlyData,
        borderColor:
          borderColor === "2024"
            ? "#EB7062"
            : borderColor === "#F765A3"
            ? "blue"
            : borderColor === "2022"
            ? "#A155B9"
            : " #165BAA",
        backgroundColor:
          borderColor === "2024"
            ? "#EB7062"
            : borderColor === "#F765A3"
            ? "blue"
            : borderColor === "2022"
            ? "#A155B9"
            : " #165BAA",
        fill: false,
      };
    });

    const chartData = {
      labels: labels,
      datasets: datasets,
    };

    const chartOptions = {
      aspectRatio: 3.5,
      scales: {
        x: {
          type: "category",
          labels: labels,
        },
        y: {
          beginAtZero: true,
        },
      },
      responsive: true,
      maintainAspectRatio: false,
      plugins: {
        legend: {
          position: "bottom",
          display: true,
        },
      },
    };

    const createChart = () => {
      myChart = new Chart(chartRef.current, {
        type: "line",
        data: chartData,
        options: chartOptions,
      });
    };
    createChart();
    return () => {
      if (myChart) {
        myChart.destroy();
      }
    };
  }, [totalCome]);

  return (
    <Box className="line-chart">
      <div className="line-chart-header">
        <div className="shipment-count-text-total">Cironuz</div>
        <div className="shipment-count-text-total-detailed">Detaylı İncele</div>
      </div>
      {totalCome.length === 0 ? (
        <div
          className="shipment-count-text-total-detailed"
          style={{
            marginTop: "15px",
          }}
        >
          Gönderi ödemeniz bulunmamaktadır.
        </div>
      ) : (
        <div style={{ height: "400px", width: "100%" }}>
          <canvas ref={chartRef} />
        </div>
      )}
      <div className="line-chart-footer">
        <button onClick={handleExport} className="count-button">
          Dışarı Aktar
        </button>
      </div>
    </Box>
  );
};

export default DashboardShipmentChart;
