import { Box, TableCell, TableHead, TableRow } from "@mui/material";
import axios from "../../../api/axios";
import { useCallback, useState } from "react";
import { useRecoilState } from "recoil";
import {
  extractCurrentPageState,
  extractDataState,
  extractTotalPageState,
} from "../../../atoms/myWallet";
import KeyboardArrowDownOutlinedIcon from "@mui/icons-material/KeyboardArrowDownOutlined";
import Errors from "../../../utils/Errors";

const MyWalletExtractTableHead = () => {
  const [, setExtractData] = useRecoilState(extractDataState);
  const [extractCurrentPage, setExtractCurrentPage] = useRecoilState(extractCurrentPageState);
  const [, setExtractTotalPage] = useRecoilState(extractTotalPageState);

  const [activeSort, setActiveSort] = useState([
    { column: "created_at", direction: null },
    { column: "description_code", direction: null },
    { column: "debt_balance", direction: null },
    { column: "receivable_balance", direction: null },
    // { column: "total_balance", direction: null },
  ]);

  const handleMyBillsPageChange = useCallback(
    async (orderBy) => {
      const updatedSort = activeSort.map((sort) => {
        if (sort.column === orderBy) {
          return {
            ...sort,
            direction: sort.direction === "ASC" ? "DESC" : "ASC",
          };
        }
        return {
          ...sort,
          direction: null, // Diğer sütunların yönünü varsayılan duruma sıfırlayın
        };
      });

      setActiveSort(updatedSort);

      const currentDirection = updatedSort.find((sort) => sort.column === orderBy).direction;

      axios
        .get(`wallet/extrem-list`, {
          params: {
            page: extractCurrentPage,
            limit: 100,
            order_by: orderBy,
            order_direction: currentDirection,
            list_type: "initial",
          },
        })
        .then((response) => {
          setExtractData(response.data.data);
          setExtractCurrentPage(response.data.currentPage);
          setExtractTotalPage(response.data.totalPage);
        })
        .catch((error) => {
          Errors(error);
        });
    },
    [activeSort, extractCurrentPage, setExtractData, setExtractCurrentPage, setExtractTotalPage]
  );

  return (
    <TableHead>
      <TableRow>
        <TableCell align="left">
          <Box
            sx={{
              display: "flex",
              justifyContent: "left",
              alignItems: "center",
              color: "var(--priceText)",
              fontWeight: 700,
              fontSize: "inherit",
            }}
          >
            Tarih{" "}
            <KeyboardArrowDownOutlinedIcon
              onClick={() => handleMyBillsPageChange("created_at")}
              sx={{
                transform:
                  activeSort.find((sort) => sort.column === "created_at").direction === "ASC"
                    ? "rotate(180deg)"
                    : "rotate(0deg)",
                transition: "transform 0.3s",
              }}
            />
          </Box>
        </TableCell>
        <TableCell align="start">
          <Box
            sx={{
              display: "flex",
              justifyContent: "left",
              alignItems: "center",
              color: "var(--priceText)",
              fontWeight: 700,
              fontSize: "inherit",
            }}
          >
            Açıklama{" "}
            <KeyboardArrowDownOutlinedIcon
              onClick={() => handleMyBillsPageChange("description_code")}
              sx={{
                transform:
                  activeSort.find((sort) => sort.column === "description_code").direction === "ASC"
                    ? "rotate(180deg)"
                    : "rotate(0deg)",
                transition: "transform 0.3s",
              }}
            />
          </Box>
        </TableCell>
        <TableCell align="start">
            <Box
                sx={{
                    display: "flex",
                    justifyContent: "left",
                    alignItems: "center",
                    color: "var(--priceText)",
                    fontWeight: 700,
                    fontSize: "inherit",
                }}
            >
                Referans Numarası{" "}
            </Box>
        </TableCell>
        <TableCell align="start">
          <Box
            sx={{
              display: "flex",
              justifyContent: "left",
              alignItems: "center",
              color: "var(--priceText)",
              fontWeight: 700,
              fontSize: "inherit",
            }}
          >
            Borç{" "}
            <KeyboardArrowDownOutlinedIcon
              onClick={() => handleMyBillsPageChange("debt_balance")}
              sx={{
                transform:
                  activeSort.find((sort) => sort.column === "debt_balance").direction === "ASC"
                    ? "rotate(180deg)"
                    : "rotate(0deg)",
                transition: "transform 0.3s",
              }}
            />
          </Box>
        </TableCell>
        <TableCell align="start">
          <Box
            sx={{
              display: "flex",
              justifyContent: "left",
              alignItems: "center",
              color: "var(--priceText)",
              fontWeight: 700,
              fontSize: "inherit",
            }}
          >
            Alacak{" "}
            <KeyboardArrowDownOutlinedIcon
              onClick={() => handleMyBillsPageChange("receivable_balance")}
              sx={{
                transform:
                  activeSort.find((sort) => sort.column === "receivable_balance").direction ===
                  "ASC"
                    ? "rotate(180deg)"
                    : "rotate(0deg)",
                transition: "transform 0.3s",
              }}
            />
          </Box>
        </TableCell>
        {/* <TableCell align="start">
          <Box
            sx={{
              display: "flex",
              justifyContent: "left",
              alignItems: "center",
              color: "var(--priceText)",
              fontWeight: 700,
              fontSize: "inherit",
            }}
          >
            Bakiye{" "}
            <KeyboardArrowDownOutlinedIcon
              onClick={() => handleMyBillsPageChange("total_balance")}
              sx={{
                transform:
                  activeSort.find((sort) => sort.column === "total_balance").direction === "ASC"
                    ? "rotate(180deg)"
                    : "rotate(0deg)",
                transition: "transform 0.3s",
              }}
            />
          </Box>
        </TableCell> */}
        <TableCell align="start"></TableCell>
      </TableRow>
    </TableHead>
  );
};

export default MyWalletExtractTableHead;
