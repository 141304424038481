import { useRecoilValue } from "recoil";
import { orderListState } from "../../atoms/orders";
import OrderListItem from "./OrderListItem";
import { NavLink } from "react-router-dom";
import InfoOutlinedIcon from "@mui/icons-material/InfoOutlined";

const OrderList = (props) => {
  const { exchangeRates, setOrderSelectedPackageSystem } = props;
  const orderList = useRecoilValue(orderListState);

  const exchangeRate = (item) => {
    switch (item?.currency) {
      case "USD":
        return exchangeRates?.USD;
      case "EUR":
        return exchangeRates?.EUR;
      case "GBP":
        return exchangeRates?.GBP;
      default:
        return exchangeRates?.USD;
    }
  };

  return (
    <div className="order-list">
      {orderList.length === 0 ? (
        <div className="order-list-none-item">
          <div className="order-list-none-item-info">
            <InfoOutlinedIcon />
            <p>
              Etiket üretebilmeniz için gönderi oluşturmanız gerekmektedir.{" "}
              <NavLink to={"/create-shipment"} reloadDocument>
                Gönderi Oluştur
              </NavLink>{" "}
              sayfasından oluşturabilirsiniz.
            </p>
          </div>
        </div>
      ) : (
        orderList?.map((item) => (
          <OrderListItem
            key={item?.id}
            item={item}
            exchangeRate={exchangeRate(item)}
            setOrderSelectedPackageSystem={setOrderSelectedPackageSystem}
          />
        ))
      )}
    </div>
  );
};

export default OrderList;
