import { TableCell, TableHead, TableRow } from "@mui/material";

const MyWalletExtractDetailTableHead = () => {

  return (
    <TableHead>
      <TableRow>
        <TableCell align="left">Tarih</TableCell>
        <TableCell align="left">AWB</TableCell>
        <TableCell align="left">Ödeme Kanalı</TableCell>
        <TableCell align="left">Ödeme ID</TableCell>
        <TableCell align="left">Açıklama</TableCell>
        <TableCell align="left">Tutar</TableCell>
        <TableCell align="left">Ödeme Açıklaması</TableCell>
      </TableRow>
    </TableHead>
  );
};

export default MyWalletExtractDetailTableHead;
