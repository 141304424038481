import {
  Box,
  Button,
  FormControl,
  createTheme,
  Modal,
  TextField,
  ThemeProvider,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  DialogActions,
} from "@mui/material";
import { useRecoilState, useRecoilValue } from "recoil";
import {
  parcelReturnDemandOpenState,
  selectedShipmentForMyShipmentsState,
  returnReasonState,
} from "../../../atoms/myShipments";
import CancelOutlinedIcon from "@mui/icons-material/CancelOutlined";
import { toast } from "react-toastify";
import axios from "../../../api/axios";
import ParcelReturnIcon from "@mui/icons-material/AssignmentReturn";
import { useState } from "react"; //TODO: Icon bulunacak
import Errors from "../../../utils/Errors";

const theme = createTheme({
  components: {
    MuiModal: {
      styleOverrides: {
        root: {
          padding: "20px",
        },
      },
    },
    MuiOutlinedInput: {
      styleOverrides: {
        root: {
          borderRadius: "8px",
        },
      },
    },
  },
});

const ParcelReturnDemand = () => {
  const selectedShipmentForMyShipments = useRecoilValue(selectedShipmentForMyShipmentsState);
  const [parcelReturnDemandOpen, setParcelReturnDemandOpen] = useRecoilState(
    parcelReturnDemandOpenState
  );
  const [returnReason, setReturnReason] = useRecoilState(returnReasonState);

  const [warring, setWarring] = useState(false);
  const handleCloseWarring = () => {
    setWarring(false);
    setTimeout(() => {
      window.location.reload(true);
    }, 1000);
  };
  const sendReturnDemand = () => {
    const data = { return_reason: returnReason };

    axios
      .post(`parcel-return/demand/add/${selectedShipmentForMyShipments.id}`, data)
      .then((response) => {
        if (response.status === 200) {
          //toast.success(response.data.message, { autoClose: 1000 });
          setWarring(true);
          setReturnReason("");
          handleClose();
        }
        if (response.status === 400) {
          toast.error(response.data.message);
        }
      })
      .catch((error) => Errors(error));
  };

  const handleClose = () => {
    setParcelReturnDemandOpen(false);
  };

  return (
    <ThemeProvider theme={theme}>
      <Modal open={parcelReturnDemandOpen} onClose={handleClose}>
        {selectedShipmentForMyShipments.length !== 0 ? (
          <Box
            sx={{
              backgroundColor: "var(--lighterBg)",
              borderRadius: "var(--inputBorderRadius)",
              padding: "20px",
              outline: 0,
              display: "grid",
              gap: "var(--gap)",
              width: "100%",
              maxWidth: "800px",
              margin: "auto",
              position: "relative",
            }}
            style={{
              maxHeight: "90vh",
              overflowY: "auto",
            }}
          >
            <CancelOutlinedIcon
              sx={{
                position: "absolute",
                right: 1,
                top: 1,
                cursor: "pointer",
                color: "var(--priceText)",
                transition: "all 80ms linear",
                ":hover": {
                  color: "var(--notRed)",
                  transition: "all 80ms linear",
                },
              }}
              onClick={handleClose}
            />
            <h6
              style={{
                display: "flex",
                alignItems: "center",
                gap: "5px",
                color: "var(--priceText)",
                marginBottom: "10px",
              }}
            >
              <ParcelReturnIcon />
              İade Talebi Oluştur
            </h6>
            <Box sx={{ display: "grid", gap: "var(--gap)" }}>
              <FormControl className="parcel-return-demand" size="small" sx={{ minWidth: 180 }}>
                <TextField
                  value={returnReason}
                  onChange={(e) => setReturnReason(e.target.value)}
                  sx={{
                    fontSize: "14px",
                    display: "flex",
                    minHeight: "40px",
                    marginBottom: "20px",
                  }}
                  label="İade Sebebiniz"
                  autoFocus
                  onFocus={(e) => {
                    let descValue = e.target.value;
                    e.target.value = "";
                    e.target.value = descValue;
                  }}
                  multiline
                  rows={3}
                />
                <Button
                  onClick={sendReturnDemand}
                  sx={{
                    justifySelf: "flex-end",
                    backgroundColor: "var(--info)",
                    color: "var(--lighterBg)",
                    textTransform: "none",
                    transition: "all 150ms linear",
                    borderRadius: "var(--buttonBorderRadius)",
                    padding: "7px 16px",
                    ":hover": {
                      backgroundColor: "var(--blueBrand)",
                      transition: "all 150ms linear",
                    },
                  }}
                >
                  Oluştur
                </Button>
              </FormControl>
            </Box>
          </Box>
        ) : (
          <Box
            sx={{
              backgroundColor: "var(--lighterBg)",
              borderRadius: "var(--inputBorderRadius)",
              padding: "20px",
              outline: 0,
              display: "grid",
              gap: "var(--gap)",
              width: "100%",
              maxWidth: "1100px",
              margin: "auto",
              position: "relative",
            }}
            style={{
              maxHeight: "90vh",
              overflowY: "auto",
            }}
          >
            <CancelOutlinedIcon
              sx={{
                position: "absolute",
                right: 1,
                top: 1,
                color: "var(--priceText)",
                cursor: "pointer",
                transition: "all 80ms linear",
                ":hover": {
                  color: "var(--notRed)",
                  transition: "all 80ms linear",
                },
              }}
              onClick={handleClose}
            />
            Sistem daha kendine gelemedi, sakin ol kardeşim.
          </Box>
        )}
      </Modal>
      <Dialog open={warring} onClose={handleCloseWarring}>
        <DialogTitle sx={{ color: "red" }}>{"Uyarı"}</DialogTitle>
        <DialogContent>
          <DialogContentText>
            İade talebiniz alınmıştır. İade talebiniz onaylandığında iadeler sekmesinden takip
            edebilirsiniz. İade talebinizi iptal etmek isterseniz Destek Hattıyla iletişime
            geçebilirsiniz.
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCloseWarring} color="primary">
            Okudum,Anladım.
          </Button>
        </DialogActions>
      </Dialog>
    </ThemeProvider>
  );
};

export default ParcelReturnDemand;
