import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
} from "@mui/material";
import { useCallback } from "react";
import { useRecoilState } from "recoil";
import {
  activeStepState,
  overPayDesignState,
  overPayOpenState,
  overPayState,
} from "../../../atoms/createShipment";

const ProformaOverPay = () => {
  const [overPayOpen, setOverPayOpen] = useRecoilState(overPayOpenState);
  const [, setOverPay] = useRecoilState(overPayState);
  const [, setOverPayDesign] = useRecoilState(overPayDesignState);
  const [, setActiveStep] = useRecoilState(activeStepState);

  const handleClose = useCallback(() => {
    setOverPayOpen(false);
    setOverPay(true);
  }, [setOverPayOpen, setOverPay]);

  const handleNavigate = useCallback(() => {
    setOverPayOpen(false);
    setOverPay(false);
    setOverPayDesign(true);
    setActiveStep(0);
  }, [setOverPayOpen, setOverPay, setActiveStep, setOverPayDesign]);

  return (
    <Dialog
      open={overPayOpen}
      onClose={handleClose}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
    >
      <DialogContent>
        <DialogContentText id="alert-dialog-description">
          Seçilen tedarikçi için hesaplanıdığınız ilk belirlediğiniz mal
          bedelinden farklı olamaz. İlk aşamaya dönün veya proformadaki toplam
          tutarı ilk aşamadaki belirlediğiniz mal bedeline göre yeniden
          düzenleyin.
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button className="iyzi-button" onClick={handleNavigate}>
          İlk Aşamaya Dönüp Tutarı Düzenle
        </Button>
        <Button className="iyzi-button" onClick={handleClose}>
          Burada Düzenle
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default ProformaOverPay;
