import { useCallback } from "react";
import DeleteIcon from "@mui/icons-material/Delete";
import { useState } from "react";
import {
  Button,
  Checkbox,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  ThemeProvider,
  createTheme,
} from "@mui/material";
import axios from "../../../api/axios";
import { toast } from "react-toastify";
import { iyzicoCardsState } from "../../../atoms/membership";
import { useRecoilState } from "recoil";
import CardImgChange from "../../../utils/CardImgChange";
import RadioButtonCheckedOutlinedIcon from "@mui/icons-material/RadioButtonCheckedOutlined";
import RadioButtonUncheckedOutlinedIcon from "@mui/icons-material/RadioButtonUncheckedOutlined";
import Errors from "../../../utils/Errors";
import CardDeleteDialog from "./CardDeleteDialog";

const theme = createTheme({
  components: {
    MuiPaper: {
      styleOverrides: {
        root: {
          borderRadius: "10px",
        },
      },
    },
  },
});

const IyzicoCard = ({ card, setGarantiCards, cardId }) => {
  const [deleteLoading, setDeleteLoading] = useState(false);
  const [defaultLoading, setDefaultLoading] = useState(false);
  const [iyzicoCards, setIyzicoCards] = useRecoilState(iyzicoCardsState);

  const [isDefault, setIsDefault] = useState(false);
  const [cardDeleteDialog, setCardDeleteDialog] = useState(false);

  const handleDelete = useCallback(() => {
    if (card?.default === 1) {
      setIsDefault(true);
      return;
    } else {
      setDeleteLoading(true);
      axios
        .post(`profile/remove-card`, {
          from: "IYZICO",
          card_token: card.card_token,
        })
        .then(() => {
          toast.success("Kart Başarıyla Silindi", { autoClose: 500 });
          setIyzicoCards(iyzicoCards.filter((iyzicoCard) => iyzicoCard !== card));
          setDeleteLoading(false);
        })
        .catch((error) => {
          Errors(error);
          setDeleteLoading(false);
        });
    }
  }, [iyzicoCards, setIyzicoCards, card]);

  const handleDeleteDialog = () => {
    setCardDeleteDialog(true);
  };

  const getCards = useCallback(async () => {
    await axios
      .get(`profile/get-cards`)
      .then((response) => {
        setIyzicoCards(response.data.data?.iyzico?.items);
        setGarantiCards(response.data.data?.garanti?.items);
      })
      .catch((error) => Errors(error))
      .finally(() => setDefaultLoading(false));
  }, [setGarantiCards, setIyzicoCards]);

  const handleClose = () => {
    setIsDefault(false);
  };

  const handleDefault = useCallback(async () => {
    setDefaultLoading(true);
    await axios
      .post(`profile/set-card-default`, {
        from: "IYZICO",
        card_token: card.card_token,
      })
      .then((response) => {
        toast.success(response.data.message);
        getCards();
      })
      .catch((error) => Errors(error));
  }, [card, getCards]);

  return (
    card && (
      <ThemeProvider theme={theme}>
        <CardDeleteDialog
          cardDeleteDialog={cardDeleteDialog}
          setCardDeleteDialog={setCardDeleteDialog}
          handleDelete={handleDelete}
        />
        <Dialog
          open={isDefault}
          onClose={handleClose}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
        >
          <DialogContent>
            <DialogContentText id="alert-dialog-description">
              Varsayılan kartınızı silemezsiniz. Eğer bu kartı silmek istiyorsanız, başka bir kartı
              varsayılan kart olarak seçmelisiniz.
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button onClick={handleClose} className="iyzi-button">
              Anladım
            </Button>
          </DialogActions>
        </Dialog>
        <div className="card-info-display">
          {deleteLoading ? (
            <div className="card-delete">
              <CircularProgress
                sx={{
                  height: "21px !important",
                  width: "21px !important",
                  color: "var(--redBrand)",
                }}
              />
            </div>
          ) : (
            <div className="card-delete">
              <DeleteIcon
                sx={{ opacity: card?.default === 1 ? 0.4 : 1 }}
                onClick={handleDeleteDialog}
                className="card-delete-icon"
              />
            </div>
          )}
          <div className="card-top">
            {defaultLoading ? (
              <div
                style={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  width: "39px",
                }}
              >
                <CircularProgress
                  sx={{
                    height: "21px !important",
                    width: "21px !important",
                    marginTop: "6px",
                    color: "var(--redBrand)",
                  }}
                />
              </div>
            ) : (
              <Checkbox
                onChange={handleDefault}
                checked={card?.default === 1}
                icon={<RadioButtonUncheckedOutlinedIcon />}
                checkedIcon={<RadioButtonCheckedOutlinedIcon />}
              />
            )}
            {CardImgChange(card.card_pan, "iyzicoCard")}
          </div>
          <div className="card-bottom">
            <div style={{ fontSize: "13px" }}>{card.card_pan}</div>
            <div style={{ display: "flex", gap: "var(--gap3x)" }}>
              <span style={{ fontSize: "13px" }}>** / **</span>
            </div>
          </div>
        </div>
      </ThemeProvider>
    )
  );
};

export default IyzicoCard;
