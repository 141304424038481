import countries from "../../../json/countriesDetailed.json";

const DefaultAddress = ({ address }) => {
  return (
    <div className="bill-address-wrapper">
      {address.address_description && (
        <div className="address-medium-text">
          <span>Başlık: </span>
          {address.address_description}
        </div>
      )}
      <div className="address-small-text">
        <span>Kontak: </span>
        {address.contact_name}
      </div>
      <div>
        {(address.address1 || address.address2 || address.address3) && (
          <div className="address-small-text">
            <span>Adres Satırı: </span>
            {address.address1} {address.address2} {address.address3}
          </div>
        )}
        {address?.postal_code && (
          <div className="address-small-text">
            <span>Posta Kodu: </span>
            {address?.postal_code}
          </div>
        )}
        {(address?.town || address?.city || address.country) && (
          <div className="address-small-text">
            <span>Adres: </span>
            {address.town} {"/"} {address.city} {"/"}{" "}
            {
              countries?.find((e) => e?.iso_code === address?.country)
                ?.country_name_tr
            }
          </div>
        )}
        {address?.phone && (
          <div className="address-small-text">
            <span>Telefon: </span>
            {address.phone}
          </div>
        )}
      </div>
    </div>
  );
};

export default DefaultAddress;
