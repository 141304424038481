import { Button } from "@mui/material";
import Footer from "../components/beforeLogin/Footer";
import Header from "../components/beforeLogin/Header";
import { DynamicTitle } from "../utils/DynamicTitle";
// import PageTransition from "../utils/PageTransition";
import IyziTeamHeros from "../components/home/IyziTeamHeros";
import GetFinish from "../components/home/GetFinish";
import { useNavigate } from "react-router-dom";

const IyziTeam = () => {
  DynamicTitle("iyziTeam | iyziShip");

  const navigate = useNavigate();

  const handleNavigateContact = () => {
    navigate("/iletisim");
  };

  return (
    <>
      {/* <PageTransition> */}
        <Header />
        <main className="iyzi-main">
          <section className="main-iyziteam">
            <br />
            <h1>iyziTeam ile Tanışın</h1>
            <h2>
              E-İhracatın Geleceğini Şekillendiriyor, Sizinle Birlikte Büyüyoruz
            </h2>
            <article className="iyziteam-article">
              <p>
                Birlikte büyümek, birlikte başarmak adına iyziTeam olarak; ileri
                teknolojimiz, sektörel bilgimiz, uzun yıllara dayanan tecrübemiz
                ve süper güçlerimiz ile her adımda yanınızdayız. E-ihracatta
                sadece bir ekip değil, sizinle beraber bir vizyonun parçası
                oluyoruz!
              </p>
            </article>
            <figure>
              <img src="/images/heros/heros.png" alt="iyziTeam" />
            </figure>
            <article className="iyziteam-article">
              <p className="sub-text">
                Her birimiz, özel güçlerimizle e-ihracat sektöründe fark
                yaratmaya hazırız! Siz değerli müşterilerimiz için sadece bir
                hizmet sağlayıcı değil, aynı zamanda bir kurtarıcı, bir
                destekçi, kısacası gerçek bir süper kahraman ekibi olmayı
                hedefliyoruz. Hızla bilgilendirerek, kolay erişim sunarak ve her
                daim çözüm odaklı yaklaşarak, sizi lojistik dünyasındaki
                kötülüklerden ve zorluklardan koruyoruz.{" "}
              </p>
              <p className="sub-text">
                Ancak bu, bizim için sadece bir süper görev değil! Her bir
                adımda, e-ihracatın ulusal ve global ölçekte gelişimine katkıda
                bulunmanın sorumluluğunu hissediyoruz. Bu sorumluluğu, sadece
                siz değerli müşterilerimizin beklentilerini aşmak için değil,
                aynı zamanda ülkemizin e-ihracatta hak ettiği yere gelmesi için
                de taşıyoruz.
              </p>
              <p className="sub-text">
                Sevgiyle, özveriyle ve özenle çalışıyoruz. Çünkü biliyoruz ki,
                e-ihracatta attığımız her adım, sadece bir teslimat değil, milli
                bir amacın da parçasıdır. iyziTeam olarak, sizinle birlikte
                e-ihracatın parlak geleceğini inşa etmek için varız. Her gün,
                daha iyisi için çabalıyor, yenilikleri takip ediyor ve sizlere
                en iyi hizmeti sunabilmek için var gücümüzle çalışıyoruz.
              </p>
            </article>
            <div className="iyziteam-info">
              <p>
                iyziTeam’e katılarak global e-ticareti herkes için
                kolaylaştırmaya var mısın?
              </p>
              <Button
                onClick={handleNavigateContact}
                className="iyzi-button-white"
              >
                Hemen Başvur
              </Button>
            </div>
            <h5>
              E-ticaret evrenindeki süper kahramanlarınız ile tanışın,
              karşınızda iyziTeam!
            </h5>
            <IyziTeamHeros />
          </section>
          <GetFinish />
        </main>
        <Footer />
      {/* </PageTransition> */}
    </>
  );
};

export default IyziTeam;
