import { useRecoilValue, useSetRecoilState } from "recoil";
import { totalCwState } from "../../../atoms/createShipment";
import { dimensionModalState, registeredDimensionsState } from "../../../atoms/createShipment";
import {
  CircularProgress,
  FormControl,
  IconButton,
  InputAdornment,
  InputLabel,
  MenuItem,
  Select,
  TextField,
  ThemeProvider,
  createTheme,
} from "@mui/material";
import { useCallback, useState, useEffect } from "react";
import HighlightOffOutlinedIcon from "@mui/icons-material/HighlightOffOutlined";
import CheckCircleOutlineOutlinedIcon from "@mui/icons-material/CheckCircleOutlineOutlined";
import DeleteIcon from "@mui/icons-material/Delete";

const theme = createTheme({
  components: {
    MuiOutlinedInput: {
      styleOverrides: {
        notchedOutline: {
          borderWidth: "2px",
          borderColor: "#1976d2",
        },
        input: {
          color: "#1976d2",
        },
      },
    },
    MuiInputLabel: {
      styleOverrides: {
        root: {
          color: "#1976d2",
        },
      },
    },
  },
});

const CreateDimension = ({
  id,
  dimension,
  setDimensions,
  handleInputChange,
  handleDimensionDelete,
  handleDimensionClear,
  dimensionLoading,
  dimensions,
}) => {
  const [dimensionFast, setDimensionFast] = useState();

  const registeredDimensions = useRecoilValue(registeredDimensionsState);

  const setDimensionModal = useSetRecoilState(dimensionModalState);
  const setTotalCw = useSetRecoilState(totalCwState);

  const handleDimensionModal = useCallback(() => {
    setDimensionModal((prevState) => ({ ...prevState, status: true }));
  }, [setDimensionModal]);

  const handleDimension = useCallback(
    (key, width, length, height, gross) => {
      setDimensions((prevDimensions) => {
        const updatedDimension = {
          width: width,
          length: length,
          height: height,
          gross: gross,
          qty: 1,
          id: key,
        };

        if (JSON.stringify(prevDimensions[key]) !== JSON.stringify(updatedDimension)) {
          const updatedDimensions = [...prevDimensions];
          updatedDimensions[key] = updatedDimension;
          return updatedDimensions;
        }

        return prevDimensions;
      });
    },
    [setDimensions]
  );

  useEffect(() => {
    const calculatedCw =
      ((dimension.width * dimension.length * dimension.height) / 5000) * dimension.qty >
      dimension.gross * dimension.qty
        ? (
            ((dimension.width * dimension.length * dimension.height) / 5000) *
            dimension.qty
          ).toFixed(2)
        : Math.round(dimension.gross * dimension.qty).toFixed(2);

    setTotalCw((prevTotalCw) => prevTotalCw + parseFloat(calculatedCw));

    // Return a cleanup function to remove the calculatedCw from the totalCw state
    return () => {
      setTotalCw((prevTotalCw) => prevTotalCw - parseFloat(calculatedCw));
    };
  }, [dimension, setTotalCw]);

  return (
    <div className="form-dimensions-content-container">
      <div className="form-dimensions-content">
        <div className="form-dimensions-content-header">
          <div className="form-dimensions-content-header-title">
            <p style={{ fontWeight: 700, color: "var(--btnBgBlue)" }}>
              {id + 1}. Kap/Paket Ölçüleri
            </p>
          </div>
          <IconButton
            type="button"
            disabled={dimensions.length < 2}
            sx={{ ":disabled": { opacity: 0.3 }, backgroundColor: "var(--pastelRed)" }}
            className="dimension-delete"
            onClick={() => handleDimensionDelete(dimension.id)}
          >
            <DeleteIcon />
          </IconButton>
        </div>
      </div>
      <div className="form-dimensions-container">
        <div style={{ display: "flex", justifyContent: "space-between" }}>
          {dimensionLoading ? (
            <div className="dimension-loading">
              <CircularProgress />
            </div>
          ) : (
            <ThemeProvider theme={theme}>
              <FormControl fullWidth size="small">
                <InputLabel id="select-document">Hızlı Ölçü Seç</InputLabel>
                <Select
                  labelId="select-document"
                  id={`select-simple-document${id}`}
                  value={dimensionFast}
                  onChange={(e) => setDimensionFast(e.target.value)}
                  label="Hızlı Ölçü Seç"
                >
                  <MenuItem
                    onClick={handleDimensionModal}
                    sx={{
                      textDecoration: "underline",
                      color: "var(--btnBgBlue)",
                    }}
                  >
                    Kayıtlı ölçü ekle
                  </MenuItem>
                  <MenuItem onClick={() => handleDimensionClear(id)} value={""}>
                    <em>Temizle</em>
                  </MenuItem>
                  {registeredDimensions.map((dimension) => (
                    <MenuItem
                      key={dimension.id}
                      id={dimension.id}
                      value={dimension.name}
                      onClick={() =>
                        handleDimension(
                          id,
                          dimension.width,
                          dimension.length,
                          dimension.height,
                          dimension.gross,
                          dimension.qty
                        )
                      }
                      data-en={dimension.length}
                      data-boy={dimension.width}
                      data-yuk={dimension.height}
                      data-kg={Number(dimension.gross).toFixed(2)}
                      data-qty={dimension.qty}
                      data-vw={(
                        ((dimension.length * dimension.width * dimension.height) / 5000) *
                        dimension.gross
                      ).toFixed(2)}
                      data-ds={(
                        ((dimension.length * dimension.width * dimension.height) / 3000) *
                        dimension.gross
                      ).toFixed(2)}
                      data-gw={(dimension.qty * dimension.gross).toFixed(2)}
                      data-cw={
                        ((dimension.length * dimension.width * dimension.height) / 5000) *
                          dimension.gross >
                        dimension.qty * dimension.gross
                          ? ((dimension.length * dimension.width * dimension.height) / 5000) *
                            dimension.gross
                          : dimension.qty * dimension.gross
                      }
                    >
                      {dimension.name
                        ? `${dimension.name} (${dimension.length}cm x ${dimension.width}cm x ${
                            dimension.height
                          }cm x ${Number(dimension.gross).toFixed(2)}kg x ${dimension.qty})`
                        : `Boş Boyut (${dimension.length}cm x ${dimension.width}cm x ${
                            dimension.height
                          }cm x ${Number(dimension.gross).toFixed(0)} x ${dimension.qty}) adet`}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </ThemeProvider>
          )}
        </div>
        <TextField
          label="Genişlik (cm)"
          size="small"
          name="width"
          value={dimension.width}
          onChange={(e) => handleInputChange(e, id)}
          error={dimension.width.length === 0}
          InputProps={{
            endAdornment:
              dimension.width.length === 0 ? (
                <InputAdornment position="end">
                  <HighlightOffOutlinedIcon sx={{ color: "var(--error)" }} />
                </InputAdornment>
              ) : (
                <InputAdornment position="end">
                  <CheckCircleOutlineOutlinedIcon sx={{ color: "var(--success)" }} />
                </InputAdornment>
              ),
          }}
          required
        />
        <TextField
          label="Uzunluk (cm)"
          size="small"
          name="length"
          value={dimension.length}
          onChange={(e) => handleInputChange(e, id)}
          error={dimension.length.length === 0}
          InputProps={{
            endAdornment:
              dimension.length.length === 0 ? (
                <InputAdornment position="end">
                  <HighlightOffOutlinedIcon sx={{ color: "var(--error)" }} />
                </InputAdornment>
              ) : (
                <InputAdornment position="end">
                  <CheckCircleOutlineOutlinedIcon sx={{ color: "var(--success)" }} />
                </InputAdornment>
              ),
          }}
          required
        />
        <TextField
          label="Yükseklik (cm)"
          size="small"
          name="height"
          value={dimension.height}
          onChange={(e) => handleInputChange(e, id)}
          error={dimension.height.length === 0}
          InputProps={{
            endAdornment:
              dimension.height.length === 0 ? (
                <InputAdornment position="end">
                  <HighlightOffOutlinedIcon sx={{ color: "var(--error)" }} />
                </InputAdornment>
              ) : (
                <InputAdornment position="end">
                  <CheckCircleOutlineOutlinedIcon sx={{ color: "var(--success)" }} />
                </InputAdornment>
              ),
          }}
          required
        />
        <TextField
          label="Kilogram"
          size="small"
          name="gross"
          value={dimension.gross}
          onChange={(e) => handleInputChange(e, id)}
          error={dimension.gross.length === 0}
          InputProps={{
            endAdornment:
              dimension.gross.length === 0 ? (
                <InputAdornment position="end">
                  <HighlightOffOutlinedIcon sx={{ color: "var(--error)" }} />
                </InputAdornment>
              ) : (
                <InputAdornment position="end">
                  <CheckCircleOutlineOutlinedIcon sx={{ color: "var(--success)" }} />
                </InputAdornment>
              ),
          }}
          required
        />
        <TextField
          label="Kap"
          size="small"
          disabled
          defaultValue={1}
          // value={dimension.qty}
          // onChange={(e) => handleInputChange(e, id)}
          name="qty"
          error={dimension.qty.length === 0}
          InputProps={{
            endAdornment:
              dimension.qty.length === 0 ? (
                <InputAdornment position="end">
                  <HighlightOffOutlinedIcon sx={{ color: "var(--error)" }} />
                </InputAdornment>
              ) : (
                <InputAdornment position="end">
                  <CheckCircleOutlineOutlinedIcon sx={{ color: "var(--success)" }} />
                </InputAdornment>
              ),
          }}
          fullWidth
          required
        />
      </div>
      <div className="form-dimensions-weight">
        <div>
          <span style={{ fontSize: "13px" }}>
            <b style={{ fontSize: "inherit" }}>Brüt Ağırlık :</b>&nbsp;
          </span>
          <span style={{ fontSize: "13px" }}>
            {Number(dimension.gross * dimension.qty).toFixed(2)} KG
          </span>
        </div>
        <div>
          <span style={{ fontSize: "13px" }}>
            <b style={{ fontSize: "inherit" }}>Hacim / Desi :</b>&nbsp;
          </span>
          <span style={{ fontSize: "13px" }}>
            {Number(
              ((Math.ceil(Number(dimension.width)) *
                Math.ceil(Number(dimension.length)) *
                Math.ceil(Number(dimension.height))) /
                5000) *
                dimension.qty
            ).toFixed(2)}{" "}
            KG
          </span>
        </div>
      </div>
    </div>
  );
};

export default CreateDimension;
