import { Button } from "@mui/material";
import Footer from "../components/beforeLogin/Footer";
import Header from "../components/beforeLogin/Header";
import FrequentQuestions from "../components/home/FrequentQuestions";
import GetFinish from "../components/home/GetFinish";
import { DynamicTitle } from "../utils/DynamicTitle";
// import PageTransition from "../utils/PageTransition";

const SSS = () => {
  DynamicTitle("Sık Sorulan Sorular | iyziShip");

  return (
    <>
      {/* <PageTransition> */}
        <Header />
        <main className="iyzi-main">
          <FrequentQuestions />
          <section className="sss-contact-wrapper">
            <div className="sss-contact">
              <p>Aradığınızı bulamadınız mı?</p>
              <Button className="iyzi-button-bg">
                Bizimle İletişime Geçin
              </Button>
            </div>
          </section>
          <GetFinish />
        </main>
        <Footer />
      {/* </PageTransition> */}
    </>
  );
};

export default SSS;
