import { Table, TableBody, TableContainer } from "@mui/material";
import MyRefundTableHead from "./MyRefundTableHead";
import MyRefundTableBody from "./MyRefundTableBody";
import { useRecoilValue } from "recoil";
import { walletRefundDataState } from "../../../atoms/myWallet";

const MyRefundTable = () => {
  const walletRefundData = useRecoilValue(walletRefundDataState);
  
  return (
    <TableContainer
      sx={{
        backgroundColor: "var(--lighterBg)",
        borderRadius: "var(--inputBorderRadius)",
        boxShadow: "0px 2px 10px rgba(0, 0, 0, 0.1)",
      }}
    >
      <Table>
        <MyRefundTableHead />
        <TableBody>
          {walletRefundData.map((item, key) => (
            <MyRefundTableBody key={key} item={item} />
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
};

export default MyRefundTable;
