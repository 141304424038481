import "../style/createShipment/CreateShipment.css";
import Box from "@mui/material/Box";
import PropTypes from "prop-types";
import Stepper from "@mui/material/Stepper";
import Step from "@mui/material/Step";
import StepLabel from "@mui/material/StepLabel";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import { DynamicTitle } from "../utils/DynamicTitle";
import { useRecoilState, useRecoilValue } from "recoil";
import NotificationBar from "../components/sidebar/NotificationBar";
import { useEffect } from "react";
import ShipmentInfo from "../components/createShipment/ShipmentInfo";
import ShipmentDimension from "../components/createShipment/ShipmentDimension";
import ShipmentPrice from "../components/createShipment/ShipmentPrice";
import ShipmentAddress from "../components/createShipment/ShipmentAddress";
import ShipmentProforma from "../components/createShipment/ShipmentProforma";
import ShipmentPayment from "../components/createShipment/ShipmentPayment";
import {
  activeStepState,
  isShipmentPriceCalculationState,
  shipmentDocumentState,
  startShipmentReceiverAnimationState,
  shipmentHideState,
} from "../atoms/createShipment";
import { createTheme, ThemeProvider } from "@mui/material";
import Inventory2OutlinedIcon from "@mui/icons-material/Inventory2Outlined";
import StraightenOutlinedIcon from "@mui/icons-material/StraightenOutlined";
import LocalOfferOutlinedIcon from "@mui/icons-material/LocalOfferOutlined";
import BusinessOutlinedIcon from "@mui/icons-material/BusinessOutlined";
import ArticleOutlinedIcon from "@mui/icons-material/ArticleOutlined";
import PaymentOutlinedIcon from "@mui/icons-material/PaymentOutlined";
import styled from "@emotion/styled";
import { isDefaultBillAddressState } from "../atoms/profileSettings";
import axios from "../api/axios";
import { useCookies } from "react-cookie";
import WrapperStyle from "../utils/WrapperStyle";
// import PageTransition from "../utils/PageTransition";
import DocOrPack from "../components/createShipment/shipmentInfo/DocOrPack";
import CreateShipmentTitle from "../components/createShipment/CreateShipmentTitle";

const theme = createTheme({
  components: {
    MuiStepIcon: {
      styleOverrides: {
        root: {
          "&$completed": {
            color: "green",
          },
        },
      },
    },
    MuiTooltip: {
      styleOverrides: {
        tooltip: {
          fontSize: "14px",
        },
      },
    },
  },
});

const ColorlibStepIconRoot = styled("div")(({ theme, ownerState }) => ({
  backgroundColor: theme.palette.mode === "dark" ? theme.palette.grey[700] : "#ccc",
  zIndex: 1,
  color: "#fff",
  width: 35,
  height: 35,
  display: "flex",
  borderRadius: "50%",
  justifyContent: "center",
  alignItems: "center",
  ...(ownerState.active && {
    backgroundColor: "#2485DA",
    boxShadow: "0 4px 10px 0 rgba(0,0,0,.25)",
    transition: "all 150ms linear",
  }),

  ...(ownerState.completed && {
    backgroundColor: "var(--success)",
    transition: "all 150ms linear",
  }),
}));

const ColorlibStepIcon = (props) => {
  const { active, completed, className } = props;

  const icons = {
    1: <Inventory2OutlinedIcon />,
    2: <StraightenOutlinedIcon />,
    3: <LocalOfferOutlinedIcon />,
    4: <BusinessOutlinedIcon />,
    5: <ArticleOutlinedIcon />,
    6: <PaymentOutlinedIcon />,
  };

  return (
    <ColorlibStepIconRoot ownerState={{ completed, active }} className={className}>
      {icons[String(props.icon)]}
    </ColorlibStepIconRoot>
  );
};

ColorlibStepIcon.propTypes = {
  /**
   * Whether this step is active.
   * @default false
   */
  active: PropTypes.bool,
  className: PropTypes.string,
  /**
   * Mark the step as completed. Is passed to child components.
   * @default false
   */
  completed: PropTypes.bool,
  /**
   * The label displayed in the step icon.
   */
  icon: PropTypes.node,
};

const CreateShipment = () => {
  const [token] = useCookies(["jwt"]);
  const jwt = token.jwt;
  axios.defaults.headers.common["Authorization"] = `Bearer ${jwt}`;

  DynamicTitle("Gönderi Oluştur | iyziShip");

  const shipmentDocument = useRecoilValue(shipmentDocumentState);
  const [, setIsDefaultBillAddress] = useRecoilState(isDefaultBillAddressState);
  const [, setIsShipmentPriceCalculation] = useRecoilState(isShipmentPriceCalculationState);
  const [, setStartShipmentReceiverAnimation] = useRecoilState(startShipmentReceiverAnimationState);
  const [, setShipmentHide] = useRecoilState(shipmentHideState);

  const steps =
    shipmentDocument === "D"
      ? ["Gönderi Bilgileri", "Fiyat", "Adres", "Tamamlandı"]
      : ["Gönderi Bilgileri", "Ölçü", "Fiyat", "Adres", "Proforma", "Tamamlandı"];

  useEffect(() => {
    axios.get("member-address").then((response) => {
      setIsDefaultBillAddress(response.data.data);
    });
  }, [setIsDefaultBillAddress]);

  useEffect(() => {
    setIsShipmentPriceCalculation(false);
  }, [setIsShipmentPriceCalculation]);

  const [activeStep, setActiveStep] = useRecoilState(activeStepState);

  const handleBack = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
    setStartShipmentReceiverAnimation(false);
    setShipmentHide(false);
  };

  const handleBackFirstStep = () => {
    setActiveStep(0);
    setStartShipmentReceiverAnimation(false);
    setShipmentHide(false);
  };

  const handleReset = () => {
    window.location.reload();
  };

  return (
    <ThemeProvider theme={theme}>
      <section className="createShipment">
        <NotificationBar />
        <DocOrPack />
        {/* <PageTransition> */}
          <CreateShipmentTitle />
          <div style={WrapperStyle()}>
            <div className="createShipment-wrapper">
              <Stepper activeStep={activeStep} className="createShipment-stepper">
                {steps.map((label, index) => {
                  const stepProps = {};
                  const labelProps = {};

                  return (
                    <Step
                      sx={{
                        "& .MuiStepLabel-root .Mui-completed": {
                          color: "var(--success)", // circle color (COMPLETED)
                        },
                      }}
                      key={index}
                      {...stepProps}
                    >
                      <StepLabel StepIconComponent={ColorlibStepIcon} {...labelProps}>
                        {label}
                      </StepLabel>
                    </Step>
                  );
                })}
              </Stepper>
              {activeStep === steps.length ? (
                <>
                  <Typography sx={{ mt: 2, mb: 1 }}>Tüm adımlar tamamlandı.</Typography>
                  <Box sx={{ display: "flex", flexDirection: "row", pt: 2 }}>
                    <Box sx={{ flex: "1 1 auto" }} />
                    <Button onClick={handleReset} style={{ textTransform: "none" }}>
                      İşlemi Tamamla
                    </Button>
                  </Box>
                </>
              ) : (
                <>
                  <Box
                    sx={{
                      display: "flex",
                      pt: 2,
                      pb: 1,
                      gap: "var(--gap2x)",
                      width: "100%",
                      maxWidth: "1400px",
                      margin: "0 auto",
                    }}
                  >
                    <Button
                      color="info"
                      style={{
                        textTransform: "none",
                        fontSize: "13px",
                        display: activeStep === 5 ? "none" : "",
                        opacity: activeStep === 0 ? "0.4" : "",
                      }}
                      className="iyzi-button"
                      disabled={activeStep === 0}
                      onClick={handleBackFirstStep}
                    >
                      Başa Dön
                    </Button>
                    <Button
                      color="info"
                      style={{
                        textTransform: "none",
                        fontSize: "13px",
                        display: activeStep === 5 ? "none" : "",
                        opacity: activeStep === 0 ? "0.4" : "",
                      }}
                      className="iyzi-button"
                      disabled={activeStep === 0}
                      onClick={handleBack}
                    >
                      Önceki Adıma Dön
                    </Button>
                  </Box>
                </>
              )}
              {shipmentDocument !== "D" &&
                (activeStep === 0 ? (
                  <ShipmentInfo />
                ) : activeStep === 1 ? (
                  <ShipmentDimension />
                ) : activeStep === 2 ? (
                  <ShipmentPrice />
                ) : activeStep === 3 ? (
                  <ShipmentAddress />
                ) : activeStep === 4 ? (
                  <ShipmentProforma />
                ) : activeStep === 5 ? (
                  <ShipmentPayment />
                ) : (
                  ""
                ))}
              {shipmentDocument === "D" &&
                (activeStep === 0 ? (
                  <ShipmentInfo />
                ) : activeStep === 1 ? (
                  <ShipmentPrice />
                ) : activeStep === 2 ? (
                  <ShipmentAddress />
                ) : activeStep === 3 ? (
                  <ShipmentPayment />
                ) : (
                  ""
                ))}
            </div>
          </div>
        {/* </PageTransition> */}
      </section>
    </ThemeProvider>
  );
};

export default CreateShipment;
