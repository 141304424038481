import {
  Box,
  Button,
  CircularProgress,
  Modal,
  ThemeProvider,
  Typography,
  createTheme,
} from "@mui/material";
import { useRecoilState, useRecoilValue } from "recoil";
import { myWalletDocModalState, selectedArrangementItemState } from "../../../atoms/myWallet";
import Errors from "../../../utils/Errors";
import { useCallback, useState } from "react";
import { useDropzone } from "react-dropzone";
import axios from "../../../api/axios";
import { toast } from "react-toastify";
import uploadIcon from "../../../img/icons/upload_icon.svg";
import FileDownloadOutlinedIcon from "@mui/icons-material/FileDownloadOutlined";
import DowloadPdfView from "../../dowloadPdfView/DowloadPdfView";
import DownloadFile from "../../../utils/DownloadFile";
import GetFile from "../../../utils/GetFile";

const theme = createTheme({
  components: {
    MuiModal: {
      styleOverrides: {
        root: {
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          padding: "20px",
        },
      },
    },
    MuiOutlinedInput: {
      styleOverrides: {
        root: {
          borderRadius: "8px",
        },
      },
    },
  },
});

const MyWalletArrangementDocModal = () => {
  const [myWalletDocModal, setMyWalletDocModal] = useRecoilState(myWalletDocModalState);
  const [sendFiles, setSendFiles] = useState([]);
  const selectedArrangementItem = useRecoilValue(selectedArrangementItemState);

  const [selectedDocuments, setSelectedDocuments] = useState([]);
  const [loading, setLoading] = useState(false);

  const handleClose = useCallback(() => {
    setMyWalletDocModal(false);
  }, [setMyWalletDocModal]);

  const handleDownloadFile = () => {
    DownloadFile({ fileUrl: "documents/Muvafakatname.docx", fileName: "Muvafakatname.docx" });
  };
  const handleDrop = (acceptedFiles) => {
    const newFiles = acceptedFiles.map((file) => ({
      file,
      id: Math.random().toString(),
    }));
    setSendFiles((prevFiles) => [...prevFiles, ...newFiles]);
    setSelectedDocuments((prevFiles) => [...prevFiles, ...newFiles]);
  };
  const handleSetState = (id) => {
    setSendFiles((prevFiles) => prevFiles.filter((file) => file.id !== id));
    setSelectedDocuments((prevFiles) => prevFiles.filter((file) => file.id !== id));
  };

  const { getRootProps, getInputProps } = useDropzone({
    onDrop: handleDrop,
    multiple: true,
  });

  const uploadSelected = useCallback(() => {
    setLoading(true);
    let formData = new FormData();
    Array.from(selectedDocuments).forEach((file, index) => {
      formData.append(`member_consent_file`, file.file);
    });

    axios
      .post(`refund-compensation/update/${selectedArrangementItem.id}`, formData)
      .then((response) => {
        toast.success(response.data.message);
        setLoading(false);
        setTimeout(() => {
          window.location.reload(true);
        }, 1000);
      })
      .catch((error) => {
        Errors(error);
        setLoading(false);
      });
  }, [selectedArrangementItem, selectedDocuments]);

  return (
    <ThemeProvider theme={theme}>
      <Modal open={myWalletDocModal} onClose={handleClose}>
        <Box
          className="my-wallet-arrangement-bill-modal"
          style={{
            maxHeight: "90vh",
            overflowY: "auto",
          }}
        >
          <Typography className="my-wallet-arrangement-bill-modal-headline" variant="h6">
            Muvafakatname Yükle
          </Typography>
          <Box sx={{ display: "flex", justifyContent: "flex-end" }}>
            <Button className="iyzi-button" onClick={handleDownloadFile}>
              <FileDownloadOutlinedIcon /> Örnek Muvafakatname İndir
            </Button>
          </Box>
          <Box className="dropzone-wrapper">
            <Box className="dropzone">
              <div className="dropzone-area" {...getRootProps()}>
                <input {...getInputProps()} />
                <div className="dropzone-area-text">
                  <img src={uploadIcon} alt="Upload Icon" />
                  <span className="dropzone-area-text-headline">
                    Dosyayı sürükleyip bırakın veya{" "}
                    <span style={{ color: "var(--info)" }}>Gözat</span>
                  </span>
                  <span className="dropzone-area-text-sub">Desteklenen Format: PDF</span>
                </div>
              </div>
            </Box>
            <DowloadPdfView sendFiles={sendFiles} handleSetState={handleSetState} />
            <Box
              sx={{
                display: "flex",
                justifyContent: "flex-end",
                gap: "var(--gap)",
                width: "100%",
              }}
            >
              <Button className="iyzi-button" onClick={handleClose}>
                İptal
              </Button>
              {loading ? (
                <div
                  style={{
                    width: "64px",
                    height: "33px",
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                  }}
                >
                  <CircularProgress sx={{ width: "24px!important", height: "24px!important" }} />
                </div>
              ) : (
                <Button className="iyzi-button" onClick={uploadSelected}>
                  Kaydet
                </Button>
              )}
            </Box>
          </Box>
          <Box>
            {selectedDocuments.length !== 0 && (
              <Box
                sx={{
                  color: "var(--priceText)",
                  fontWeight: 700,
                  fontSize: "14px",
                  marginBottom: "20px",
                  marginTop: "20px",
                  paddingLeft: "8px",
                }}
              >
                Yüklenecek Dosya İsmi
              </Box>
            )}
            {selectedDocuments.length !== 0 &&
              selectedDocuments.map((document, key) => (
                <Box
                  sx={{
                    border: "1px solid var(--phoneDigitBg)",
                    marginBottom: "10px",
                    padding: "10px",
                    borderRadius: "var(--wrapperBorderRadius)",
                    fontSize: "13px",
                    cursor: "pointer",
                    color: "var(--priceText)",
                    transition: "all 150ms linear",
                    ":hover": {
                      backgroundColor: "var(--pastelBlue)",
                      transition: "all 150ms linear",
                    },
                  }}
                  key={document.file.id}
                  onClick={() => GetFile(document)}
                >
                  <span
                    style={{
                      fontSize: "13px",
                      color: "var(--priceText)",
                      fontWeight: "700",
                    }}
                  >
                    {key + 1}
                  </span>{" "}
                  - {document.file.name}
                </Box>
              ))}
          </Box>
        </Box>
      </Modal>
    </ThemeProvider>
  );
};

export default MyWalletArrangementDocModal;
