import { Button, Dialog, DialogActions, DialogContent } from "@mui/material";

const WarningDialog = ({ open, setOpen, handleRequest }) => {
  const handleClose = () => {
    setOpen(false);
  };

  const handleSubmit = () => {
    handleRequest();
    handleClose();
  };

  return (
    <Dialog open={open} onClose={handleClose}>
      <DialogContent style={{ display: "flex", flexDirection: "column", gap: "var(--gap2x)" }}>
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
          }}
        >
          <img
            style={{ width: "100px", height: "100px" }}
            src="images/order/important.png"
            alt="Önemli Uyarı"
          />
          <h2 style={{ color: "var(--shipmentRed)", fontSize: "30px" }}>Dikkat!</h2>
        </div>
        Daha önce takip numarası güncellemesi yapılmıştır. Takip numarasını tekrar güncellemek
        istediğinizden emin misiniz?
      </DialogContent>
      <DialogActions>
        <Button onClick={handleClose} className="iyzi-button-gray">
          Hayır
        </Button>
        <Button onClick={handleSubmit} className="iyzi-button-blue">
          Evet
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default WarningDialog;
