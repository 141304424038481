import { Select, MenuItem, FormControl, InputLabel } from "@mui/material";

const DateType = ({ dateType, setDateType }) => {
  return (
    <FormControl fullWidth size="small">
      <InputLabel sx={{ fontSize: "14px" }} id="supplier-select-label">
        Tarih Tipi
      </InputLabel>
      <Select
        label="Tarih Tipi"
        value={dateType}
        onChange={(e) => setDateType(e.target.value)}
      // sx={{ fontSize: "14px", height: "35px" }}
      >
        <MenuItem value="">
          <em>Temizle</em>
        </MenuItem>
        <MenuItem value="receipt_date">
          <em>Sipariş Tarihi</em>
        </MenuItem>
        <MenuItem value="created_at">
          <em>Oluşturma Tarihi</em>
        </MenuItem>
      </Select>
    </FormControl>
  );
};

export default DateType;
