import { Box, Button, CircularProgress, TextField } from "@mui/material";
import { useCallback, useState } from "react";
import { useRecoilState, useSetRecoilState } from "recoil";
import axios from "../../api/axios";
import {
  postalCodeState,
  selectedCityState,
  selectedDistrictState,
  selectedStateState,
} from "../../atoms/courierRegistration";
import districts from "../../json/districts.json";
import Errors from "../../utils/Errors";

const CourierDistrict = () => {
  const [postalCode, setPostalCode] = useRecoilState(postalCodeState);
  const setSelectedDistrict = useSetRecoilState(selectedDistrictState);
  const setSelectedCity = useSetRecoilState(selectedCityState);
  const [selectedState, setSelectedState] = useRecoilState(selectedStateState);

  const [zipLoading, setZipLoading] = useState(false);

  const zipCodeHandler = useCallback(
    async (e) => {
      e.preventDefault();

      setSelectedState("");
      setSelectedDistrict("");
      setSelectedCity("");
      setZipLoading(true);
      await axios
        .post("get-geo-details", {
          postalcode: postalCode,
          country: "TR",
        })
        .then((res) => {
          setZipLoading(false);
          if (res.data.data.postalcodes.length === 0 || res.data.data.postalcodes.length > 1) {
            return;
          } else {
            setSelectedState(res.data.data.postalcodes[0].placeName);
            setSelectedDistrict(
              res.data.data.postalcodes[0].adminName2
                ? res.data.data.postalcodes[0].adminName2
                : res.data.data.postalcodes[0].placeName
            );
            setSelectedCity(res.data.data.postalcodes[0].adminName1);
          }
        })
        .catch((error) => {
          Errors(error);
          setZipLoading(false);
        });
    },
    [postalCode, setSelectedDistrict, setSelectedCity, setSelectedState]
  );

  return (
    <Box className="courier-district">
      <Box className="courier-district-header">
        <h6 className="district-not-service">Hizmet için Posta Kodu Sorgula</h6>
        <form className="district-postal-search" onSubmit={zipCodeHandler}>
          <TextField
            value={postalCode}
            onChange={(e) => setPostalCode(e.target.value)}
            label="Posta Kodu"
            size="small"
            required
          />
          {zipLoading ? (
            <Box
              sx={{
                width: "67px",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <CircularProgress sx={{ width: "20px !important", height: "20px !important" }} />
            </Box>
          ) : (
            <Button type="submit" className="district-postal-code-button">
              Sorgula
            </Button>
          )}
        </form>
        {selectedState ? <Box>Tebrikler 🎉</Box> : <Box>Üzgünüz 😔</Box>}
        <h6 className="district-service">Hizmet verilen Bölgeler</h6>
      </Box>
      <Box className="courier-district-main">
        {districts.map((district, key) => (
          <Button className="district-choose-button" key={key}>
            {district.ilce} / {district.il}
          </Button>
        ))}
      </Box>
    </Box>
  );
};
export default CourierDistrict;
