import { FormControl, TextField } from "@mui/material";
import { DatePicker, LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { bDayState } from "../../../../atoms/membership";
import { useRecoilState } from "recoil";

const ProfileBirthDate = ({ disabled }) => {
  const [bDay, setBDay] = useRecoilState(bDayState);

  return (
    <LocalizationProvider dateAdapter={AdapterDayjs}>
      <FormControl disabled={disabled()} size="small" component="fieldset" fullWidth>
        <DatePicker
          label="Doğum Tarihi"
          disabled={disabled()}
          value={bDay}
          onChange={(newValue) => {
            // Eğer yeni değer geçerli bir tarih değilse, bDay'i boş yap
            if (!newValue || newValue.toString() === "Invalid Date") {
              setBDay("");
            } else {
              // Aksi takdirde, yeni değeri formatlayıp ayarla
              setBDay(newValue.format("YYYY-MM-DD"));
            }
          }}
          renderInput={(params) => <TextField size="small" {...params} required />}
          mask="__-__-____" // Use a mask for date format (YYYY-MM-DD) - oppo
          inputFormat="DD-MM-YYYY" // Set the input format to "YYYY-MM-DD" - oppo
        />
      </FormControl>
    </LocalizationProvider>
  );
};

export default ProfileBirthDate;
