import { Table, TableBody, TableContainer } from "@mui/material";
import SenderAddressTableHead from "./SenderAddressTableHead";
import SenderAddressTableBody from "./SenderAddressTableBody";

const SenderAddressTable = ({item, setAddressID, addressID, address, setSenderName}) => {

  return (
    <TableContainer>
      <Table>
        <SenderAddressTableHead />
        <TableBody>
          {address?.map((address) => (
            <SenderAddressTableBody item={item} addressID={addressID} setAddressID={setAddressID} key={address?.id} address={address} setSenderName={setSenderName} />
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
};

export default SenderAddressTable;
