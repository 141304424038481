import TextField from "@mui/material/TextField";
import {
  Checkbox,
  FormControl,
  InputLabel,
  OutlinedInput,
  InputAdornment,
  IconButton,
  FormHelperText,
  Button,
} from "@mui/material";
import { useRecoilState, useSetRecoilState } from "recoil";
import { registerModalState } from "../../atoms/registerTerms";
import RegisterModal from "./RegisterModal";
import { termsState } from "../../atoms/termsIsAccepted";
import { useState, useCallback, useEffect } from "react";
import axios from "../../api/axios";
import { toast, ToastContainer } from "react-toastify";
import Loading, { loadingState } from "../../atoms/loading";
import { DynamicTitle } from "../../utils/DynamicTitle";
import { Visibility, VisibilityOff } from "@mui/icons-material";
import { validateEmail } from "../../utils/emailValidation";
import Errors from "../../utils/Errors";
import PhoneInput from "react-phone-input-2";

const BASE_URL = "register";

const Register = () => {
  DynamicTitle("Kayıt Ol | iyziShip");

  const setRegisterTerms = useSetRecoilState(registerModalState);
  const [termsIsAccepted, setTermsIsAccepted] = useRecoilState(termsState);
  const [commercialPerm, setCommercialPerm] = useState(false);

  const [loading, setLoading] = useRecoilState(loadingState);

  const [values, setValues] = useState({
    password: "",
    passwordRepeat: "",
    showPassword: false,
    showPasswordRepeat: false,
  });
  const handleChange = (prop) => (e) => {
    setValues({ ...values, [prop]: e.target.value });
  };

  const handleClickShowPassword = () => {
    setValues({
      ...values,
      showPassword: !values.showPassword,
    });
  };

  const handleClickShowPasswordRepeat = () => {
    setValues({
      ...values,
      showPasswordRepeat: !values.showPasswordRepeat,
    });
  };

  const handleMouseDownPassword = (e) => {
    e.preventDefault();
  };

  const [name, setName] = useState("");
  const [surname, setSurname] = useState("");
  const [email, setEmail] = useState("");
  const [pwd, setPwd] = useState("");
  const [pwdRpt, setPwdRpt] = useState("");
  const [pwdRepeatError, setPwdRepeatError] = useState("");
  const [phone, setPhone] = useState("");
  const [phoneIsNotValid, setPhoneIsNotValid] = useState(false);

  const [nameError, setNameError] = useState(false);
  const [surnameError, setSurnameError] = useState(false);
  const [emailError, setEmailError] = useState(false);
  const [pwdError, setPwdError] = useState(false);
  const [isEmailValid, setIsEmailValid] = useState(true);

  const handleRegister = useCallback(
    async (e) => {
      if (e) e.preventDefault();

      if (phone.length < 6) {
        setPhoneIsNotValid(true);
      } else {
        setPhoneIsNotValid(false);
      }

      if (name === "") {
        setNameError(true);
      }

      if (surname === "") {
        setSurnameError(true);
      }

      if (email === "" || !email.includes("@") || !email.includes(".")) {
        setEmailError(true);
      }

      if (values.password === "" || values.password.length < 6) {
        setPwdError(true);
      }

      if (values.password === "" || values.passwordRepeat === "") {
        setPwdRepeatError(true);
      }

      setLoading(true);
      await axios
        .post(BASE_URL, {
          name,
          surname,
          email,
          phone,
          password: values.password,
          password_confirmation: values.passwordRepeat,
          portal: "iyziShip",
          send_mail_approve: commercialPerm ? 1 : 0,
        })
        .then(() => {
          toast.success(
            "Başarılı! Kaydınız alındı. Lütfen kaydınızı tamamlamak için e-posta adresinize gönderilen onay mailini kontrol edin."
          );
          setLoading(false);
          setNameError(false);
          setSurnameError(false);
          setEmailError(false);
          setPwdError(false);
          setPwdError(false);
          setPhoneIsNotValid(false);
          // Formu Temizle
          setName("");
          setSurname("");
          setEmail("");
          setPwd("");
          setPhone("90");
          setPwdRpt("");
          setValues({
            password: "",
            passwordRepeat: "",
            showPassword: false,
            showPasswordRepeat: false,
          });

          // Navigate Login After 5 Sec
          // setTimeout(() => {
          //   navigate("/login");
          // }, [5000]);
        })
        .catch((error) => {
          setLoading(false);
          Errors(error);
        });
    },
    [email, name, surname, values, commercialPerm, phone, setLoading]
  );

  useEffect(() => {
    if (name !== "") {
      setNameError(false);
    }
    if (surname !== "") {
      setSurnameError(false);
    }
    if (email !== "") {
      setEmailError(false);
    }
    if (values.password !== "") {
      setPwdError(false);
    }
    if (values.passwordRepeat !== "") {
      setPwdError(false);
    }
  }, [name, surname, email, pwd, pwdRpt, values.password, values.passwordRepeat]);

  const handleKeyDown = (e) => {
    if (e.key === "Tab" && !e.shiftKey) {
      e.preventDefault();
      document.getElementById("outlined-adornment-passwordRepeat").focus();
    }
  };

  const handleEmailChange = (e) => {
    const newEmail = e.target.value;

    setEmail(newEmail);

    if (!newEmail) {
      setIsEmailValid(false);
    } else {
      const isValidEmail = validateEmail(newEmail);
      setIsEmailValid(isValidEmail);
    }
  };

  const handleRegisterTerms = () => {
    if (!termsIsAccepted) {
      setRegisterTerms(true);
    } else {
      setTermsIsAccepted(false);
    }
  };

  const handleCommercialPerm = () => {
    setCommercialPerm(!commercialPerm);
  };

  const disabled = !termsIsAccepted;

  useEffect(() => {
    setEmailError(!isEmailValid);
  }, [isEmailValid]);

  return (
    <div className="register-wrapper">
      <RegisterModal />
      <form onSubmit={handleRegister} className="register">
        <ToastContainer />
        <div className="register-item">
          <TextField
            fullWidth
            value={name}
            onChange={(e) => setName(e.target.value.replace(/[0-9<>/*?\-=():;`,.@!^+%/&]/g, ""))}
            error={nameError}
            helperText={nameError && "İsim alanı boş bırakılamaz."}
            inputMode="text"
            id="outlined-register-name"
            label="Adınız"
            variant="outlined"
            required
            size="small"
          />
          <TextField
            fullWidth
            value={surname}
            onChange={(e) => setSurname(e.target.value.replace(/[0-9<>/*?\-=():;`,.@!^+%/&]/g, ""))}
            error={surnameError}
            helperText={surnameError && "Soyisim alanı boş bırakılamaz."}
            inputMode="text"
            id="outlined-register-surname"
            label="Soyadınız"
            variant="outlined"
            required
            size="small"
          />
        </div>
        <div className="register-item">
          <TextField
            fullWidth
            value={email}
            onChange={handleEmailChange}
            error={emailError}
            helperText={emailError && "Geçerli bir email giriniz."}
            autoComplete="on"
            inputMode="email"
            type="email"
            id="outlined-register-email"
            label="Email Adresiniz"
            variant="outlined"
            required
            size="small"
          />
          <FormControl size="small" fullWidth>
            <PhoneInput
              country={"tr"}
              value={phone}
              onChange={(e) => setPhone(e)}
              onEnterKeyPress={handleRegister}
              autoComplete="off"
              isValid={(value, country) => {
                if (value.match(/12345/)) {
                  return "Invalid value: " + value + ", " + country.name;
                } else if (value.match(/1234/)) {
                  return false;
                } else {
                  return true;
                }
              }}
              inputProps={{
                name: "phone",
                autoFocus: true,
                required: true,
                id: "ind-phone-input",
                style: {
                  height: "100%",
                  width: "100%",
                  minHeight: "37px",
                  backgroundColor: "transparent",
                  maxHeight: "37px",
                  borderColor: phoneIsNotValid ? "var(--error)" : "var(--inputBorderColor)",
                },
              }}
            />
            {phoneIsNotValid && (
              <FormHelperText sx={{ color: "var(--error)" }}>
                Telefon numaranızı giriniz.
              </FormHelperText>
            )}
          </FormControl>
        </div>
        <div className="register-item">
          <FormControl fullWidth variant="outlined" size="small">
            <InputLabel htmlFor="outlined-adornment-password">Şifre</InputLabel>
            <OutlinedInput
              id="outlined-adornment-password"
              type={values.showPassword ? "text" : "password"}
              value={values.password}
              autoComplete="off"
              onChange={handleChange("password")}
              onKeyDown={handleKeyDown}
              required
              fullWidth
              error={pwdError}
              endAdornment={
                <InputAdornment position="end">
                  <IconButton
                    aria-label="toggle password visibility"
                    onClick={handleClickShowPassword}
                    onMouseDown={handleMouseDownPassword}
                    edge="end"
                  >
                    {values.showPassword ? <VisibilityOff /> : <Visibility />}
                  </IconButton>
                </InputAdornment>
              }
              label="Password"
            />
            {pwdError && (
              <FormHelperText sx={{ color: "var(--error)", fontSize: "13px" }}>
                Şifre 6 haneden az olamaz!
              </FormHelperText>
            )}
          </FormControl>
          <FormControl fullWidth variant="outlined" size="small">
            <InputLabel htmlFor="outlined-adornment-passwordRepeat">Şifre Tekrarı</InputLabel>
            <OutlinedInput
              id="outlined-adornment-passwordRepeat"
              type={values.showPasswordRepeat ? "text" : "password"}
              value={values.passwordRepeat}
              autoComplete="off"
              onChange={handleChange("passwordRepeat")}
              onKeyDown={handleKeyDown}
              required
              error={pwdRepeatError}
              fullWidth
              endAdornment={
                <InputAdornment position="end">
                  <IconButton
                    aria-label="toggle passwordRepeat visibility"
                    onClick={handleClickShowPasswordRepeat}
                    onMouseDown={handleMouseDownPassword}
                    edge="end"
                  >
                    {values.showPassword ? <VisibilityOff /> : <Visibility />}
                  </IconButton>
                </InputAdornment>
              }
              label="Şifre Tekrarı"
            />
            {pwdRepeatError && (
              <FormHelperText sx={{ color: "var(--error)", fontSize: "13px" }}>
                Şifre Tekrarı, Şifre ile aynı olmak zorundadır!
              </FormHelperText>
            )}
          </FormControl>
        </div>
        <div style={{ display: "flex", flexDirection: "column", gap: "4px" }}>
          <div
            onClick={handleRegisterTerms}
            style={{ display: "flex", alignItems: "center", cursor: "pointer" }}
          >
            <Checkbox checked={termsIsAccepted} />
            <span style={{ color: "var(--darkerTextColor)" }}>
              <span
                style={{ color: "var(--shipmentBlue)" }}
                title="İptal ve İadeler"
                target="_blank"
              >
                Kullanıcı Sözleşmesi
              </span>
              'ni okudum ve onaylıyorum
            </span>
          </div>
          <div
            onClick={handleCommercialPerm}
            style={{ display: "flex", alignItems: "center", cursor: "pointer" }}
          >
            <Checkbox checked={commercialPerm} />
            <span style={{ color: "var(--darkerTextColor)" }}>
              Kampanyalar ve duyurulardan haberdar olabilmem için kişisel verilerimin işlenmesini ve
              tarafıma elektronik ileti gönderilmesini kabul ediyorum.
            </span>
          </div>
        </div>
        {loading ? (
          <Loading />
        ) : (
          <Button
            sx={{ ":disabled": { opacity: 0.4 }, fontSize: "14px" }}
            type="submit"
            disabled={disabled}
            className="iyzi-button-sea"
          >
            Kaydı Tamamla
          </Button>
        )}
      </form>
    </div>
  );
};

export default Register;
