import { atom } from "recoil";

export const returnShipmentListState = atom({
  key: "returnShipmentList",
  default: [],
});

export const returnShipmentListLoadingState = atom({
  key: "returnShipmentListLoading",
  default: false,
});

export const returnShipmentListLimitState = atom({
  key: "returnShipmentListLimit",
  default: 10,
});

export const returnShipmentListOrderDirectionState = atom({
  key: "returnShipmentOrderDirectionPage",
  default: "DESC",
});
export const currentPageReturnState = atom({
  key: "currentPageReturn",
  default: 1,
});

export const returnListArrayLengthState = atom({
  key: "returnListArrayLength",
  default: 0,
});

export const returnTotalPagesState = atom({
  key: "returnTotalPages",
  default: 0,
});

export const parcelReturnOpenState = atom({
  key: "parcelReturnOpen",
  default: false,
});

export const returnShipmentPriceInformationOpenState = atom({
  key: "returnShipmentPriceInformationOpen",
  default: { status: false, modalType: "" }
});

export const abroadPriceInformationOpenState = atom({
  key: "abroadPriceInformationOpen",
  default: false,
});

export const selectedParcelReturnState = atom({
  key: "selectedParcelReturn",
  default: {},
});

export const loadingReturnBtnState = atom({
  key: "loadingReturnBtn",
  default: false,
});

export const deliveryTypeState = atom({
  key: "deliveryType",
  default: "",
});

export const arrivalCostTryState = atom({
  key: "arrivalCostTry",
  default: 0,
});

export const returnCostTryState = atom({
  key: "returnCostTry",
  default: 0,
});

export const returnShipmentInformationOpenState = atom({
  key: "returnShipmentInformationOpen",
  default: { status: false, modalType: "" },
});

export const returnShipmentConfirmationOpenState = atom({
  key: "returnShipmentConfirmationOpen",
  default: { status: false, modalType: "" },
});

export const returnShipmentPaymentOpenState = atom({
  key: "returnShipmentPaymentOpen",
  default: false,
});

export const selectedPaymentReturnShipmentState = atom({
  key: "selectedPaymentReturnShipment",
  default: {},
});

export const deductionCostTryState = atom({
  key: "deductionCostTry",
  default: 0,
});

export const deductionKdvCostTryState = atom({
  key: "deductionKdvCostTry",
  default: 0,
});

export const returnBasicSearchErrorState = atom({
  key: "returnBasicSearchError",
  default: false,
});

export const returnBasicSearchState = atom({
  key: "returnBasicSearch",
  default: "",
});

export const returnDetailedStatusState = atom({
  key: "returnDetailedStatus",
  default: 0,
});

export const returnErrorSetState = atom({
  key: "returnErrorSet",
  default: true,
});

export const returnShipmentListCurrentPageState = atom({
  key: "returnShipmentCurrentListPage",
  default: 1,
});

export const returnShipmentsListArrayLengthState = atom({
  key: "returnShipmentsListArrayLength",
  default: [],
});

export const showReturnShipmentInformationState = atom({
  key: "showReturnShipmentInformation",
  default: true,
});