import { Autocomplete, Box, Button, TextField } from "@mui/material";
import { useState } from "react";
import axios from "../../api/axios";
import { useCallback } from "react";
import Errors from "../../utils/Errors";
import CountriesTurkishSorted from "../../utils/CountriesTurkishSorted";

const HomePriceForm = ({ setShowPrice, setPrice }) => {
  const [departedCountry, setDepartedCountry] = useState("TR");
  const [arrivalCountry, setArrivalCountry] = useState("");
  const [postalCode, setPostalCode] = useState("");
  const [dimensions, setDimensions] = useState({
    width: "",
    length: "",
    height: "",
    gross: "",
    qty: "1",
    id: 0,
  });

  const handleInputChange = useCallback((e) => {
    let fvalue = e.target.value.replace(/,/g, ".");
    let newValue;
    if (e.target.name === "qty") {
      newValue = fvalue.replace(/[^0-9]/g, "");
    } else {
      newValue = fvalue.replace(/[^0-9.]|(?<=\..*)\.|^0{2,}(?=[.])/g, "");
      const regex = /^\d+(\.\d{0,2})?$/;
      if (newValue.startsWith(".") || (!regex.test(newValue) && newValue !== "")) {
        return;
      }
      if (e.target.name === "gross") {
        newValue = newValue.replace(/^0{2,}/, "0");
        if (Number(fvalue) > 999) {
          newValue = "999";
        }
      }
    }
    setDimensions((prev) => ({ ...prev, [e.target.name]: newValue }));
  }, []);

  const handleSubmitPrice = (e) => {
    e.preventDefault();
    setShowPrice(false);
    const data = {
      origin_country_code: departedCountry,
      country_code: arrivalCountry,
      document_type: "N",
      product_value: "100",
      product_value_currency: "USD",
      postal_code: postalCode,
      dimensions: [
        {
          width: dimensions.width,
          length: dimensions.length,
          height: dimensions.height,
          gross: dimensions.gross,
          qty: dimensions.qty,
          id: 0,
        },
      ],
      total_qty: 1,
      domestic_supplier_name: "Getir",
    };
    axios
      .post("get-parcel-price", data)
      .then((response) => {
        setShowPrice(true);
        setPrice(response.data.data);
      })
      .catch((error) => Errors(error));
  };

  const isDisabledFunc = () => {
    return (
      !postalCode ||
      !dimensions.width ||
      !dimensions.length ||
      !dimensions.height ||
      !dimensions.gross ||
      !dimensions.qty
    );
  };

  return (
    <form onSubmit={handleSubmitPrice}>
      <Autocomplete
        id="departed-country-typing-select"
        fullWidth
        size="small"
        options={CountriesTurkishSorted()}
        value={
          CountriesTurkishSorted().find((option) => option.iso_code === departedCountry) || null
        }
        disabled={true}
        onChange={(e, val) => {
          if (val) {
            setDepartedCountry(val.iso_code);
          } else {
            setDepartedCountry("");
          }
        }}
        autoHighlight
        openOnFocus={false}
        getOptionLabel={(option) => `${option.country_name_tr}, ${option.country_name_en}`}
        renderOption={(props, option) => (
          <Box component="li" sx={{ "& > img": { mr: 2, flexShrink: 0 } }} {...props}>
            <img
              loading="lazy"
              width="20"
              src={`https://flagcdn.com/w20/${option.iso_code.toLowerCase()}.png`}
              srcSet={`https://flagcdn.com/w40/${option.iso_code.toLowerCase()}.png 2x`}
              alt=""
            />
            {option.country_name_tr}, {option.country_name_en}
          </Box>
        )}
        renderInput={(params) => (
          <TextField
            {...params}
            label="Çıkış Ülkesi Seçiniz"
            required
            name="departed-unique-country-field"
            inputProps={{
              ...params.inputProps,
              autoComplete: "off", // disable autocomplete and autofill
            }}
          />
        )}
      />
      <Autocomplete
        id="arrival-country-typing-select"
        fullWidth
        size="small"
        options={CountriesTurkishSorted()}
        value={
          CountriesTurkishSorted().find((option) => option.iso_code === arrivalCountry) || null
        }
        onChange={(e, val) => {
          if (val) {
            setArrivalCountry(val.iso_code);
            setPostalCode("");
          } else {
            setArrivalCountry("");
          }
        }}
        autoHighlight
        openOnFocus={false}
        getOptionLabel={(option) => `${option.country_name_tr}, ${option.country_name_en}`}
        renderOption={(props, option) => (
          <Box component="li" sx={{ "& > img": { mr: 2, flexShrink: 0 } }} {...props}>
            <img
              loading="lazy"
              width="20"
              src={`https://flagcdn.com/w20/${option.iso_code.toLowerCase()}.png`}
              srcSet={`https://flagcdn.com/w40/${option.iso_code.toLowerCase()}.png 2x`}
              alt=""
            />
            {option.country_name_tr}, {option.country_name_en}
          </Box>
        )}
        renderInput={(params) => (
          <TextField
            {...params}
            label="Varış Ülkesi Seçiniz"
            required
            name="arrival-unique-country-field"
            inputProps={{
              ...params.inputProps,
              autoComplete: "off", // disable autocomplete and autofill
            }}
          />
        )}
      />
      <TextField
        label="Varış Ülkesi Posta Kodu"
        size="small"
        name="postal_code"
        value={postalCode}
        onChange={(e) => setPostalCode(e.target.value)}
        required
      />
      <Box
        sx={{
          display: "flex",
          gap: "20px",
        }}
      >
        <TextField
          label="Genişlik (cm)"
          size="small"
          name="width"
          value={dimensions.width}
          onChange={handleInputChange}
          required
          sx={{
            width: "50%",
          }}
        />
        <TextField
          label="Uzunluk (cm)"
          size="small"
          name="length"
          value={dimensions.length}
          onChange={handleInputChange}
          required
          sx={{
            width: "50%",
          }}
        />
      </Box>
      <Box
        sx={{
          display: "flex",
          gap: "20px",
        }}
      >
        <TextField
          label="Yükseklik (cm)"
          size="small"
          name="height"
          value={dimensions.height}
          onChange={handleInputChange}
          required
          sx={{
            width: "50%",
          }}
        />
        <TextField
          label="Kilogram"
          size="small"
          name="gross"
          value={dimensions.gross}
          onChange={handleInputChange}
          required
          sx={{
            width: "50%",
          }}
        />
      </Box>

      <Box
        sx={{
          textAlign: "center",
        }}
      >
        <Button
          className="iyzi-button-blue"
          disabled={isDisabledFunc()}
          sx={{ ":disabled": { opacity: 0.4 }, width: "170px" }}
          type="submit"
        >
          Fiyat Al
        </Button>
      </Box>
    </form>
  );
};

export default HomePriceForm;
