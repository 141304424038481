import { Pagination } from "@mui/material";
import { useSetRecoilState } from "recoil";
import {
  profileReceiverAddressState,
  profileSenderAddressState,
  receiverCurrPageState,
  receiverTotalPageState,
  senderCurrPageState,
  senderTotalPageState,
} from "../../../atoms/membership";
import { useCallback } from "react";
import axios from "../../../api/axios";
import Errors from "../../../utils/Errors";

const AddressPagination = ({ type, totalPage, currPage }) => {
  const setSenderCurrPage = useSetRecoilState(senderCurrPageState);
  const setReceiverCurrPage = useSetRecoilState(receiverCurrPageState);
  const setSenderTotalPage = useSetRecoilState(senderTotalPageState);
  const setReceiverTotalPage = useSetRecoilState(receiverTotalPageState);
  const setProfileSenderAddress = useSetRecoilState(profileSenderAddressState);
  const setProfileReceiverAddress = useSetRecoilState(
    profileReceiverAddressState
  );

  const handlePageChange = useCallback(
    async (e, pageValue) => {
      if (type === 4) {
        setSenderCurrPage(pageValue);
      } else if (type === 5) {
        setReceiverCurrPage(pageValue);
      }

      await axios
        .get("member-address", {
          params: {
            address_type: type,
            limit: type === 4 || type === 5 ? 20 : 4,
            page: pageValue,
          },
        })
        .then((response) => {
          if (type === 4) {
            setSenderTotalPage(response.data.totalPages);
            setProfileSenderAddress(response.data.data);
          } else if (type === 5) {
            setReceiverTotalPage(response.data.totalPages);
            setProfileReceiverAddress(response.data.data);
          }
        })
        .catch((error) => Errors(error));
    },
    [
      setSenderCurrPage,
      setReceiverCurrPage,
      type,
      setSenderTotalPage,
      setProfileSenderAddress,
      setReceiverTotalPage,
      setProfileReceiverAddress,
    ]
  );

  return (
    <Pagination
      count={totalPage}
      page={currPage}
      size="small"
      onChange={handlePageChange}
      showFirstButton
      showLastButton
    />
  );
};

export default AddressPagination;
