import React from "react";
import { useRecoilState } from "recoil";
import SpringCardIn from "../registeredCards/SpringCardIn";
import {
  Autocomplete,
  Box,
  Button,
  CircularProgress,
  createTheme,
  InputAdornment,
  TextField,
  ThemeProvider,
} from "@mui/material";
import { ibanListArrayState, startIbanAnimationState } from "../../../atoms/profileSettings";
import { useCallback, useState } from "react";
import axios from "../../../api/axios";
import { useCookies } from "react-cookie";
import { toast } from "react-toastify";
import HighlightOffOutlinedIcon from "@mui/icons-material/HighlightOffOutlined";
import banks from "../../../json/banks.json";
import toTurkishUpperCase from "../../../utils/toTurkishUpperCase";
import Errors from "../../../utils/Errors";

const theme = createTheme({
  components: {
    MuiSelect: {
      styleOverrides: {
        outlined: {
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
        },
      },
    },
    MuiOutlinedInput: {
      styleOverrides: {
        root: {
          borderRadius: "10px",
        },
      },
    },
    MuiPaper: {
      styleOverrides: {
        root: {
          maxHeight: "300px !important",
        },
      },
    },
    MuiInputBase: {
      styleOverrides: {
        input: {
          display: "flex",
          alignItems: "center",
          gap: "var(--gap)",
        },
      },
    },
  },
});

const CreateIban = () => {
  const [startIbanAnimation, setStartIbanAnimation] = useRecoilState(startIbanAnimationState);
  const [ibanListArray, setIbanListArray] = useRecoilState(ibanListArrayState);
  const [loading, setLoading] = useState(false);

  const [addIbanForm, setAddIbanForm] = useState({
    name: "",
    surname: "",
    iban: "",
    bank_name: "",
  });

  const updatedIbanForm = {
    name: addIbanForm.name,
    surname: addIbanForm.surname,
    iban: addIbanForm.iban,
    bank_name: addIbanForm.bank_name,
  };

  const [token] = useCookies(["jwt"]);
  const jwt = token?.jwt;
  axios.defaults.headers.common["Authorization"] = `Bearer ${jwt}`;

  const isDisabled = useCallback(() => {
    if (
      addIbanForm.name === "" ||
      addIbanForm.surname === "" ||
      addIbanForm.iban === "" ||
      addIbanForm.iban.length < 32 ||
      addIbanForm.bank_name === ""
    ) {
      return true;
    } else return false;
  }, [addIbanForm.name, addIbanForm.surname, addIbanForm.iban, addIbanForm.bank_name]);

  const handleClose = useCallback(() => {
    setStartIbanAnimation(false);
    setAddIbanForm({
      name: "",
      surname: "",
      iban: "TR",
      bank_name: "",
    });
  }, [setStartIbanAnimation]);

  const addIban = () => {
    return axios.post("/member-iban/add", updatedIbanForm, {}).catch((error) => Errors(error));
  };

  const handleIbanChange = (input) => {
    const numbersOnly = input.replace(/[^0-9]/g, "");
    let formatted = numbersOnly.length > 0 ? "TR" : " ";

    for (let i = 0; i < numbersOnly.length; i++) {
      if (i === 2 || i === 6 || i === 10 || i === 14 || i === 18 || i === 22 || i === 26) {
        formatted += " ";
      }
      formatted += numbersOnly[i];
    }

    formatted = formatted.replace(/\s+/g, " ").trim();

    setAddIbanForm((prevState) => ({ ...prevState, iban: formatted }));
  };

  const handleAddIban = (e) => {
    e.preventDefault();
    setLoading(true);
    addIban(updatedIbanForm, jwt)
      .then((response) => {
        setLoading(false);
        toast.success(response.data.message, { autoClose: 500 });
        setIbanListArray([response.data.data, ...ibanListArray]);
        setStartIbanAnimation(false);
        setAddIbanForm({ name: "", surname: "", iban: "TR", bank_name: "" });
      })
      .catch((error) => {
        setLoading(false);
        Errors(error);
        setStartIbanAnimation(false);
      });
  };

  return (
    <>
      {startIbanAnimation && (
        <SpringCardIn>
          <ThemeProvider theme={theme}>
            <TextField
              label="Ad"
              size="small"
              variant="outlined"
              fullWidth
              autoComplete="off"
              margin="normal"
              value={addIbanForm.name}
              onChange={(e) =>
                setAddIbanForm((prevState) => ({
                  ...prevState,
                  name: toTurkishUpperCase(e.target.value),
                }))
              }
              required
              inputProps={{ minLength: 3 }}
              sx={{
                width: "49%",
                marginRight: "2%",
              }}
            />

            <TextField
              label="Soyad"
              size="small"
              variant="outlined"
              fullWidth
              autoComplete="off"
              margin="normal"
              value={addIbanForm.surname.toUpperCase()}
              onChange={(e) =>
                setAddIbanForm((prevState) => ({
                  ...prevState,
                  surname: toTurkishUpperCase(e.target.value),
                }))
              }
              required
              inputProps={{ minLength: 2 }}
              sx={{
                width: "49%",
              }}
            />

            <TextField
              label="IBAN"
              size="small"
              variant="outlined"
              fullWidth
              autoComplete="off"
              margin="normal"
              value={addIbanForm.iban}
              onChange={(e) => handleIbanChange(e.target.value)}
              // maks 11 karakter
              inputProps={{ maxLength: 32 }}
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    <HighlightOffOutlinedIcon
                      sx={{
                        cursor: "pointer",
                        borderRadius: "50%",
                        transition: "all 150ms linear",
                        padding: "1px",

                        ":hover": {
                          backgroundColor: "#e9e9e9",
                          transition: "all 150ms linear",
                        },
                      }}
                      onClick={() => setAddIbanForm({ ...addIbanForm, iban: "TR" })}
                    />
                  </InputAdornment>
                ),
              }}
              required
            />
            <Autocomplete
              id="bank-typing-select"
              fullWidth
              sx={{ marginTop: "var(--gap2x)" }}
              size="small"
              options={banks}
              value={banks.find((option) => option.name === addIbanForm.bank_name) || null}
              onChange={(e, val) => {
                if (val) {
                  setAddIbanForm({ ...addIbanForm, bank_name: val.name });
                } else return;
              }}
              autoHighlight
              openOnFocus={false}
              getOptionLabel={(option) => option.name}
              renderOption={(props, option) => (
                <Box component="li" sx={{ "& > img": { mr: 2, flexShrink: 0 } }} {...props}>
                  {option.name}
                </Box>
              )}
              renderInput={(params) => (
                <TextField
                  {...params}
                  label="Banka Seçiniz"
                  required
                  name="unique-bank-field"
                  value={addIbanForm.bank_name}
                  inputProps={{
                    ...params.inputProps,
                    autoComplete: "off", // disable autocomplete and autofill
                  }}
                />
              )}
            />
            <div
              className="shipment-dimension-modal-form-buttons"
              style={{
                gap: "var(--gap2x)",
                display: "flex",
                justifyContent: "flex-end",
                width: "100%",
                marginTop: "var(--gap2x)",
              }}
            >
              <Button onClick={handleClose} type="button" className="iyzi-button">
                Vazgeç
              </Button>
              {loading ? (
                <div
                  style={{
                    width: "64px",
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                  }}
                >
                  <CircularProgress
                    sx={{
                      width: "21px !important",
                      height: "21px !important",
                    }}
                  />
                </div>
              ) : (
                <Button
                  disabled={isDisabled()}
                  className="iyzi-button"
                  onClick={handleAddIban}
                  sx={{
                    ":disabled": {
                      opacity: 0.4,
                    },
                  }}
                >
                  Ekle
                </Button>
              )}
            </div>
          </ThemeProvider>
        </SpringCardIn>
      )}
    </>
  );
};

export default CreateIban;
