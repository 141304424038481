import { useRecoilValue } from "recoil";
import { orderListCheckedListState, orderListState } from "../../atoms/orders";

const OrderCount = () => {
  const orderList = useRecoilValue(orderListState);
  const orderListCheckedList = useRecoilValue(orderListCheckedListState);

  return (
    <div className="order-count">
      <div className="order-count-item">
        <span className="order-count-item-headline">Toplam Gönderi Sayısı: </span>
        <span className="order-count-item-subline">{orderList?.length}</span>
      </div>
      <div className="order-count-item">
        <span className="order-count-item-headline">Seçilen Gönderi Sayısı: </span>
        <span className="order-count-item-subline">{orderListCheckedList?.length}</span>
      </div>
    </div>
  );
};

export default OrderCount;
