import {
  Box,
  Button,
  CircularProgress,
  Dialog,
  DialogContent,
  DialogContentText,
  DialogActions,
} from "@mui/material";
import { useCallback, useState } from "react";
import { useRecoilState } from "recoil";
import axios from "../../api/axios";
import { courierListState } from "../../atoms/courierRegistration";
import Errors from "../../utils/Errors";
import FormatDateHour from "../../utils/FormatDateHour";
import FormatDateTR from "../../utils/FormatDateTR";

const CourierListItem = ({ item }) => {
  const [loading, setLoading] = useState(false);
  const [, setCourierList] = useRecoilState(courierListState);

  const handleCancelCourierRegistration = useCallback(async () => {
    setLoading(true);
    await axios
      .post(`booking/update/${item.id}`, {
        status: 5,
      })
      .then((response) => {
        setCourierList([response.data.data]);
        window.location.reload();
      })
      .catch((error) => {
        setLoading(false);
        Errors(error);
      })
      .finally(() => {
        setLoading(false);
      });
  }, [item, setCourierList]);

  const [parcelInfo, setParcelInfo] = useState([]);

  const getParcel = useCallback(async () => {
    await axios
      .get(`/booking`)
      .then((response) => {
        setParcelInfo(response.data.data);
      })
      .catch((error) => Errors(error))
      .finally(() => setLoading(false));
  }, []);

  const filteredParcelInfo = (id) => {
    const MatchedData = parcelInfo?.filter((order, index) => order.id === id);
    const WillBeDeliveredParcels =
      MatchedData[0]?.will_be_delivered_parcels?.map((item, index) => (
        <div
          key={index}
          style={{
            border: " 1px solid var(--inputBorderColor)",
            borderRadius: "var(--inputBorderRadius)",
            padding: "var(--gap)",
            marginTop: "5px",
            display: " grid",
            gridTemplateColumns: " repeat(auto-fill, minmax(220px, 1fr))",
          }}
        >
          <div style={{ fontWeight: "700" }}>{item?.parcel_ref_no}</div>
          <div>Ücretlendirilen Ağırlık : {item?.cw}</div>
          <div>Brüt Ağırlık : {item?.gw}</div>
          <div>Hacim Desi: {item?.vw}</div>
          <div>Adet : {item?.qty}</div>
        </div>
      ));
    return WillBeDeliveredParcels;
  };

  const [info, setInfo] = useState(false);

  const handleOpenInfo = useCallback(
    (text) => {
      getParcel();
      setInfo(true);
    },
    [getParcel]
  );

  return (
    <>
      <Box className="courier-list-item">
        <Box className="courier-list-item-detail">
          <Box className="delivery-top">
            <Box className="delivery-code">
              Teslimat Kodu:{" "}
              <span className="delivery-code-span">{item.booking_code}</span>
            </Box>
            <Box className="registration-date">
              Oluşturma Tarihi:{" "}
              <span className="registration-date-span">
                {FormatDateHour(item.created_at)}
              </span>
            </Box>
          </Box>
          <Box className="delivery-address">
            Teslimat Adresi:{" "}
            <span className="delivery-address-span">
              {item?.get_address?.address1}{" "}
              {item?.get_address?.address2 &&
                ` - ${item?.get_address?.address2}`}
              {item?.get_address?.address3 &&
                ` - ${item?.get_address?.address3}`}
            </span>
          </Box>
          <Box className="delivery-top">
            <Box className="registration-date">
              Toplama Yapacak Kişi:{" "}
              <span className="registration-date-span">{item.submitter}</span>
            </Box>
            <Box className="registration-date">
              Telefon Numarası:{" "}
              <span className="registration-date-span">
                {item.phone.slice(1, 5)} {item.phone.slice(5, 8)}{" "}
                {item.phone.slice(8, 10)} {item.phone.slice(10, 12)}
              </span>
            </Box>
          </Box>
          <Box className="delivery-top">
            <Box className="registration-date">
              Toplama Tarihi:{" "}
              <span className="registration-date-span">
                {FormatDateTR(
                  item.status < 3 ? item.booking_date : item.updated_at
                )}
              </span>
            </Box>
          </Box>
          <Button
            onClick={() => handleOpenInfo()}
            className="iyzi-button-blue"
          >
            Gönderi Bilgisi
          </Button>
        </Box>
        <Box className="courier-list-item-confirm">
          <Box
            className={
              Number(item.status) === 1
                ? "delivery-status active"
                : Number(item.status) === 0
                ? "delivery-status waiting"
                : Number(item.status) === 2
                ? "delivery-status on-the-way"
                : "delivery-status closed"
            }
          >
            {Number(item.status) === 1
              ? "Kurye Kaydınız Onaylandı"
              : Number(item.status) === 0
              ? "Kurye Kaydınızın Onaylanması Bekleniyor"
              : Number(item.status) === 2
              ? "Kuryemiz Adresinize Doğru Yolda"
              : Number(item.status) === 3
              ? "Gönderileriniz Teslim Alındı"
              : Number(item.status) === 5
              ? "Kurye Kaydınız İptal Edildi"
              : ""}
          </Box>
          {Number(item.status) === 1 && (
            <Box
              sx={{
                display: "flex",
                gap: "5px",
                fontSize: "13px",
                color: "var(--priceText)",
                fontWeight: 700,
              }}
            >
              Toplama Tarihi:
              <span style={{ fontSize: "13px", fontWeight: 400 }}>
                <span className="registration-date-span">
                  {FormatDateTR(
                    item.status < 3 ? item.booking_date : item.updated_at
                  )}
                </span>
              </span>
            </Box>
          )}
          {loading ? (
            <Box
              sx={{
                width: "148px",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <CircularProgress
                sx={{ width: "24px !important", height: "24px !important" }}
              />
            </Box>
          ) : Number(item.status) === 0 || Number(item.status) === 1 ? (
            <Button
              onClick={handleCancelCourierRegistration}
              className="iyzi-button-red"
            >
              Kurye Kaydını İptal Et
            </Button>
          ) : null}
        </Box>
      </Box>
      <Dialog
        open={info}
        onClose={(e) => setInfo(false)}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <img
          style={{ width: "24px", margin: " 10px auto 0" }}
          src="/images/logo/logo_white_mini.svg"
          alt="IyziShip Icon"
        />
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            {filteredParcelInfo(item.id)}
          </DialogContentText>
        </DialogContent>
        <DialogActions sx={{ dispay: "flex", gap: "var(--gap)" }}>
          <Button
            sx={{
              textTransform: "none",
              fontSize: "13px",
              color: "var(--priceText)",
              borderRadius: "var(--buttonBorderRadius)",
              backgroundColor: "var(--lighterBg)",
              border: "1px solid var(--inputBorderColor)",
              ":hover": {
                backgroundColor: "var(--inputBorderColor)",
              },
            }}
            fullWidth
            onClick={() => setInfo(false)}
          >
            İptal
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};

export default CourierListItem;
