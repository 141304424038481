import { Box, Button, createTheme, InputAdornment, TextField, ThemeProvider } from "@mui/material";
import SearchOutlinedIcon from "@mui/icons-material/SearchOutlined";
import { useCallback, useEffect, useState } from "react";
import axios from "../../../api/axios";
import { useRecoilState } from "recoil";
import { iyziBalanceDataState } from "../../../atoms/myWallet";
import ReactDatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import FormatDate from "../../../utils/FormatDate";
import DownloadBase64Pdf from "../../../utils/DownloadBase64Pdf";
import Errors from "../../../utils/Errors";

const theme = createTheme({
  components: {
    MuiOutlinedInput: {
      styleOverrides: {
        root: {
          borderRadius: "10px",
        },
      },
    },
  },
});

const MyWalletSearch = () => {
  const [searchParam, setSearchParam] = useState("");
  const [, setIyziBalanceData] = useRecoilState(iyziBalanceDataState);
  const [startDate, setStartDate] = useState(new Date());
  const [endDate, setEndDate] = useState(new Date());

  const handleSearch = useCallback(() => {
    axios
      .get(`wallet/list`, {
        params: {
          limit: 500,
          page: 1,
          order_direction: "DESC",
          search: searchParam,
        },
      })
      .then((res) => {
        setIyziBalanceData(res.data.data);
      })
      .catch((error) => Errors(error));
  }, [setIyziBalanceData, searchParam]);

  const handleClear = useCallback(() => {
    setSearchParam("");
    axios
      .get(`wallet/list`, {
        params: {
          limit: 500,
          page: 1,
          order_direction: "DESC",
        },
      })
      .then((res) => {
        setIyziBalanceData(res.data.data);
      })
      .catch((error) => Errors(error));
  }, [setIyziBalanceData]);

  const handleExport = useCallback(() => {
    axios
      .get(`wallet/list`, {
        params: {
          limit: 500,
          page: 1,
          order_direction: "DESC",
          export: 1,
          start_date: FormatDate(startDate),
          end_date: FormatDate(endDate),
        },
      })
      .then((res) => {
        DownloadBase64Pdf(
          res.data.base64,
          "IyziBakiyem",
          "finance",
          FormatDate(startDate),
          FormatDate(endDate)
        );
      })
      .catch((error) => Errors(error));
  }, [startDate, endDate]);

  useEffect(() => {
    // Eğer başlangıç tarihi bitiş tarihinden sonrasına atarsa bitiş tarihini tekrar düzenlememiz gerekiyor.
    if (startDate.getTime() > endDate.getTime()) {
      setEndDate(new Date(startDate.getTime() + 1000 * 60 * 60 * 24));
    }
  }, [endDate, startDate]);

  return (
    <ThemeProvider theme={theme}>
      <Box
        sx={{
          backgroundColor: "var(--lighterBg)",
          padding: "20px",
          borderRadius: "var(--inputBorderRadius)",
          boxShadow: "0px 2px 10px rgba(0, 0, 0, 0.1)",
          display: "grid",
          gap: "var(--gap2x)",
        }}
      >
        <div
          style={{
            display: "flex",
            gap: "var(--gap)",
          }}
        >
          <TextField
            size="small"
            fullWidth
            label="Arama"
            placeholder="Konşimento Numarası, Açıklama Kodu, Tarih(YYYY-AA-GG)"
            value={searchParam}
            onChange={(e) => setSearchParam(e.target.value)}
            onKeyDown={(e) =>
              e.key === "Enter" ? handleSearch() : e.key === "Escape" ? handleClear() : ""
            }
            autoComplete="off"
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <SearchOutlinedIcon />
                </InputAdornment>
              ),
            }}
          />
          <Button onClick={handleSearch} className="iyzi-button">
            Ara
          </Button>
        </div>
        <div
          style={{
            display: "flex",
            justifyContent: "flex-end",
            gap: "var(--gap)",
            height: "51px",
          }}
        >
          <div className="reactpicker-wrapper" style={{ maxWidth: 180 }}>
            <label htmlFor="start-date">Başlangıç Tarihi</label>
            <ReactDatePicker
              id="start-date"
              selected={startDate}
              onChange={(date) => setStartDate(date)}
              selectsStart
              startDate={startDate}
              endDate={endDate}
              dateFormat="yyyy-MM-dd"
              showIcon
              monthsShown={2}
            />
          </div>
          <div className="reactpicker-wrapper" style={{ maxWidth: 180 }}>
            <label htmlFor="end-date">Bitiş Tarihi</label>
            <ReactDatePicker
              selected={endDate}
              onChange={(date) => setEndDate(date)}
              selectsEnd
              startDate={startDate}
              endDate={endDate}
              minDate={startDate}
              dateFormat="yyyy-MM-dd"
              showIcon
              monthsShown={2}
            />
          </div>
          <Button onClick={handleExport} sx={{ minWidth: "90px" }} className="iyzi-button">
            Dışarı Aktar
          </Button>
        </div>
      </Box>
    </ThemeProvider>
  );
};

export default MyWalletSearch;
