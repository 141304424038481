import { useCallback, useState } from "react";
import { Button, CircularProgress } from "@mui/material";
import PhotoUpload from "./PhotoUpload";
import ProfileOptions from "./profileInfo/ProfileOptions";
import ProfileName from "./profileInfo/ProfileName";
import ProfileSurname from "./profileInfo/ProfileSurname";
import ProfileBirthDate from "./profileInfo/ProfileBirthDate";
import ProfileMail from "./profileInfo/ProfileMail";
import ProfileIdendityNumber from "./profileInfo/ProfileIdendityNumber";
import ProfilePhone from "./profileInfo/ProfilePhone";
import ProfileSettingsStyle from "../../../style/styleJs/ProfileSettingsStyle";
import PersonOutlineOutlinedIcon from "@mui/icons-material/PersonOutlineOutlined";
import EditOutlinedIcon from "@mui/icons-material/EditOutlined";
import CheckOutlinedIcon from "@mui/icons-material/CheckOutlined";
import CloseOutlinedIcon from "@mui/icons-material/CloseOutlined";
import axios from "../../../api/axios";
import ProfileCountry from "./profileInfo/ProfileCountry";
import Errors from "../../../utils/Errors";
import { toast } from "react-toastify";
import { useSetRecoilState, useRecoilState, useRecoilValue } from "recoil";
import {
  bDayState,
  emailState,
  mailPermissionState,
  memberStatusState,
  nameState,
  phoneState,
  phoneVerifiedState,
  smsPermissionState,
  surnameState,
  tckState,
  tckTypeState,
  timerSmsModalState,
} from "../../../atoms/membership";
import { saveUser } from "../../../utils/User";
import DifferentMailDialog from "./profileInfo/DifferentMailDialog";

const ProfileInfo = () => {
  const memberStatus = useRecoilValue(memberStatusState);
  const isProfileCompleted = Number(memberStatus) === 2 ? true : false;

  const setTimerSmsModal = useSetRecoilState(timerSmsModalState);
  const setPhoneVerified = useSetRecoilState(phoneVerifiedState);

  const [name, setName] = useRecoilState(nameState);
  const [surname, setSurname] = useRecoilState(surnameState);
  const [bDay, setBDay] = useRecoilState(bDayState);
  const [phone, setPhone] = useRecoilState(phoneState);
  const [email, setEmail] = useRecoilState(emailState);
  const [tckType, setTckType] = useRecoilState(tckTypeState);
  const [tck, setTck] = useRecoilState(tckState);
  const [smsPermission, setSmsPermission] = useRecoilState(smsPermissionState);
  const [mailPermission, setMailPermission] = useRecoilState(mailPermissionState);

  const [loading, setLoading] = useState(false);
  const [editPerm, setEditPerm] = useState(false);
  const [differentMailDialog, setDifferentMailDialog] = useState(false);

  const user = JSON.parse(localStorage.getItem("user"));
  const isMainUser = user?.user?.is_main_user;
  const mailAddress = user?.user?.email;

  //* Error */
  const [phoneIsNotValid, setPhoneIsNotValid] = useState(false);

  const handleDialog = (e) => {
    if (e) e.preventDefault();

    setDifferentMailDialog(true);
  };

  const handleEdit = () => {
    setEditPerm(true);
  };

  const handleEditAbort = useCallback(() => {
    setEditPerm(false);
    setName(user?.user?.name);
    setSurname(user?.user?.surname);
    setBDay(user?.user?.birth_date);
    setPhone(user?.user?.phone);
    setEmail(user?.user?.email);
    setTckType(user?.user?.user_member?.identity_type);
    setTck(user?.user?.identity_no);
    setSmsPermission(user?.user?.send_sms_approve === "1" ? true : false);
    setMailPermission(user?.user?.send_mail_approve === "1" ? true : false);
    setPhoneVerified(user?.user?.phone_verified);
  }, [
    user,
    setName,
    setSurname,
    setBDay,
    setPhone,
    setEmail,
    setTckType,
    setTck,
    setSmsPermission,
    setMailPermission,
    setPhoneVerified,
  ]);

  const handleSubmit = (e) => {
    if (e) e.preventDefault();

    if (phone.length < 6) {
      setPhoneIsNotValid(true);
    } else {
      setPhoneIsNotValid(false);
      setLoading(true);
      axios
        .post(`profile/update`, {
          name,
          surname,
          birth_date: bDay,
          phone,
          email,
          identity_type: tckType,
          identity_no: tck,
          send_sms_approve: smsPermission ? "1" : "0",
          send_mail_approve: mailPermission ? "1" : "0",
        })
        .then((response) => {
          saveUser(response);

          setName(response.data.data?.name);
          setSurname(response.data.data?.surname);
          setBDay(response.data.data?.birth_date);
          setPhone(response.data.data?.phone);
          setEmail(response.data.data?.email);
          setTckType(response.data.data?.user_member?.identity_type);
          setTck(response.data.data?.identity_no);
          setSmsPermission(response.data.data?.send_sms_approve === "1" ? true : false);
          setMailPermission(response.data.data?.send_mail_approve === "1" ? true : false);
          setPhoneVerified(response.data.data.phone_verified);

          if (response.data.resp_sms && response.data.resp_sms?.status === 200) {
            toast.success(response.data.resp_sms.message);
            setTimerSmsModal(true);
          } else {
            toast.success(response.data.message);
          }

          setEditPerm(false);
        })
        .catch((error) => {
          Errors(error);
          handleEditAbort();
        })
        .finally(() => setLoading(false));
    }
  };

  const disabled = () => {
    if (editPerm) {
      return false;
    } else return true;
  };

  const disabledPermanent = () => {
    if (isProfileCompleted || !editPerm) {
      return true;
    } else return false;
  };

  const handleSubmitChoise = (e) => {
    if (e) e.preventDefault();

    if (phone.length < 6) {
      setPhoneIsNotValid(true);
    } else {
      if (mailAddress !== email) {
        handleDialog();
      } else {
        handleSubmit();
      }
    }
  };

  return (
    <section style={ProfileSettingsStyle()} className="general-info">
      <DifferentMailDialog
        open={differentMailDialog}
        setOpen={setDifferentMailDialog}
        submit={handleSubmit}
      />
      <div className="profile-card-wrapper">
        <div className="profile-card-header">
          <PersonOutlineOutlinedIcon className="profile-card-header-icon" />
          <span>Kullanıcı Bilgileri</span>
        </div>
      </div>
      <div className="general-info-collapse">
        <div className="profile-user-information">
          <div className="profile-user-information-perms">
            <PhotoUpload />
            <ProfileOptions disabled={disabled} />
          </div>
          <form onSubmit={handleSubmitChoise} className="profile-user-inputs-form">
            <div className="profile-user-inputs">
              <ProfileName disabled={disabledPermanent} />
              <ProfileSurname disabled={disabledPermanent} />
              <ProfileBirthDate disabled={disabledPermanent} />
              <ProfileMail disabled={disabled} />
              <ProfileCountry disabled={disabledPermanent} />
              <ProfileIdendityNumber disabled={disabledPermanent} />
              <ProfilePhone
                disabled={disabled}
                handleSubmit={handleSubmit}
                edit={editPerm}
                valid={phoneIsNotValid}
                setValid={setPhoneIsNotValid}
              />
            </div>
            {editPerm && (
              <div className="profile-user-button">
                <Button type="button" onClick={handleEditAbort} className="iyzi-button-blue">
                  <CloseOutlinedIcon /> <span>İptal</span>
                </Button>
                {loading ? (
                  <div
                    style={{
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "center",
                      width: "83px",
                      height: "36px",
                    }}
                  >
                    <CircularProgress sx={{ width: "21px!important", height: "21px!important" }} />
                  </div>
                ) : (
                  <Button type="submit" className="iyzi-button-blue">
                    <CheckOutlinedIcon /> <span>Kaydet</span>
                  </Button>
                )}
              </div>
            )}
          </form>
          {!editPerm && isMainUser && (
            <div className="profile-user-button">
              <Button onClick={handleEdit} className="iyzi-button-blue">
                <EditOutlinedIcon /> <span>Düzenle</span>
              </Button>
            </div>
          )}
        </div>
      </div>
    </section>
  );
};

export default ProfileInfo;
